import React from 'react';
import styled from '@emotion/styled';

import SidebarNavMenu from '<components>/NumbrzMenus/SidebarNavMenu';

const Container = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;

  & > div {
    margin-bottom: 14px;
  }

  & > div:last-child {
    margin-bottom: 0;
  }
`;

export default function JobGroupSidebar({ baseURL }) {
  const options = [
    {
      text: 'Overview',
      key: 'overview',
      to: `${baseURL}/overview`,
    },
    {
      text: `Configuration`,
      key: 'configuration',
      to: `${baseURL}/configuration`,
    },
    {
      text: `Status`,
      key: 'status',
      to: `${baseURL}/status`,
    },
    {
      text: `Run History`,
      key: 'run-history',
      to: `${baseURL}/run-history`,
    },
  ];

  return (
    <Container>
      <SidebarNavMenu options={options} />
    </Container>
  );
}
