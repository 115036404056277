import gql from 'graphql-tag';

import {
  FlowV2Fragment,
  FlowListItemFragmentNew,
  IssueFragment,
  FlowStageV2Fragment,
  FuncSummaryFragment,
  FlowTableDefFragment,
  TestCaseFragment,
  StageLogFragment,
  SchemaFragment,
} from './fragments';

export const CreateFunction = gql`
  mutation CreateFunction($func: FunctionV3Input!) {
    createFuncV3(func: $func) {
      ID
      name
      description
    }
  }
`;

export const GetFlow = gql`
  query GetFlow($flowID: CommonID!) {
    flow(flowID: $flowID) {
      flow {
        ...FlowV2Fragment
      }
      issues {
        ...IssueFragment
      }
      environment {
        ...SchemaFragment
      }
    }
  }
  ${FlowV2Fragment}
  ${IssueFragment}
  ${SchemaFragment}
`;

export const GetFlows = gql`
  query GetFlows($containerID: CommonID, $formatVersion: Int) {
    flows(containerID: $containerID, formatVersion: $formatVersion) {
      ...FlowV2Fragment
    }
  }

  ${FlowV2Fragment}
`;

export const ListFlows = gql`
  query ListFlows($modelID: CommonID, $formatVersion: Int) {
    listFlows(modelID: $modelID, formatVersion: $formatVersion) {
      ...FlowListItemFragmentNew
    }
  }

  ${FlowListItemFragmentNew}
`;

export const UpdateFlow = gql`
  mutation UpdateFlow($input: UpdateFlowInput!) {
    updateFlow(input: $input) {
      flow {
        ...FlowV2Fragment
      }
      issues {
        ...IssueFragment
      }
    }
  }
  ${FlowV2Fragment}
  ${IssueFragment}
`;

export const UpdateFlowIncludeRefs = gql`
  mutation UpdateFlowIncludeRefs(
    $flowID: CommonID!
    $includeFlowRefs: [CommonID!]
  ) {
    updateFlowIncludeRefs(flowID: $flowID, includeFlowRefs: $includeFlowRefs) {
      flow {
        ...FlowV2Fragment
      }
      issues {
        ...IssueFragment
      }
    }
  }
  ${FlowV2Fragment}
  ${IssueFragment}
`;

export const AddOrUpdateFlowUserVar = gql`
  mutation AddOrUpdateFlowUserVar($flowID: CommonID!, $userVar: EnvVarInput!) {
    addOrUpdateFlowUserVar(flowID: $flowID, userVar: $userVar) {
      flow {
        ...FlowV2Fragment
      }
      issues {
        ...IssueFragment
      }
    }
  }
  ${FlowV2Fragment}
  ${IssueFragment}
`;

export const RemoveFlowUserVar = gql`
  mutation RemoveFlowUserVar($flowID: CommonID!, $userVarKey: String!) {
    removeFlowUserVar(flowID: $flowID, userVarKey: $userVarKey) {
      flow {
        ...FlowV2Fragment
      }
      issues {
        ...IssueFragment
      }
    }
  }
  ${FlowV2Fragment}
  ${IssueFragment}
`;

export const AddFlowTable = gql`
  mutation AddFlowTable($flowID: CommonID!, $table: FlowTableDefInput!) {
    addFlowTableDef(flowID: $flowID, table: $table) {
      flow {
        ...FlowV2Fragment
      }
      issues {
        ...IssueFragment
      }
    }
  }

  ${FlowV2Fragment}
  ${IssueFragment}
`;

export const RemoveFlowTable = gql`
  mutation RemoveFlowTable($flowID: CommonID!, $tableID: ID!) {
    removeFlowTableDef(flowID: $flowID, tableID: $tableID) {
      flow {
        ...FlowV2Fragment
      }
      issues {
        ...IssueFragment
      }
    }
  }

  ${FlowV2Fragment}
  ${IssueFragment}
`;

export const UpdateFlowTable = gql`
  mutation UpdateFlowTable(
    $flowID: CommonID!
    $tableID: ID!
    $updates: FlowTableDefInput!
  ) {
    updateFlowTableDef(flowID: $flowID, tableID: $tableID, updates: $updates) {
      ...FlowTableDefFragment
    }
  }

  ${FlowTableDefFragment}
`;

export const AddStage = gql`
  mutation AddStage($flowID: CommonID!, $input: FlowStageV2Input!) {
    addStageV2(flowID: $flowID, input: $input) {
      flow {
        ...FlowV2Fragment
      }
      issues {
        ...IssueFragment
      }
    }
  }

  ${FlowV2Fragment}
  ${IssueFragment}
`;

export const IncludeStage = gql`
  mutation IncludeStage($flowID: CommonID!, $includeStageID: CommonID!) {
    includeStageV2(flowID: $flowID, includeStageID: $includeStageID) {
      flow {
        ...FlowV2Fragment
      }
      issues {
        ...IssueFragment
      }
    }
  }

  ${FlowV2Fragment}
  ${IssueFragment}
`;

export const IncludeAllStages = gql`
  mutation IncludeAllStages($flowID: CommonID!) {
    includeStagesV2(flowID: $flowID) {
      flow {
        ...FlowV2Fragment
      }
      issues {
        ...IssueFragment
      }
    }
  }

  ${FlowV2Fragment}
  ${IssueFragment}
`;

export const RemoveStage = gql`
  mutation RemoveStage($flowID: CommonID!, $ID: CommonID!) {
    removeStageV2(flowID: $flowID, ID: $ID) {
      flow {
        ...FlowV2Fragment
      }
      issues {
        ...IssueFragment
      }
    }
  }

  ${FlowV2Fragment}
  ${IssueFragment}
`;

export const RemoveIncludedStage = gql`
  mutation RemoveIncludedStage($flowID: CommonID!, $ID: CommonID!) {
    removeIncludedStageV2(flowID: $flowID, ID: $ID) {
      flow {
        ...FlowV2Fragment
      }
      issues {
        ...IssueFragment
      }
    }
  }

  ${FlowV2Fragment}
  ${IssueFragment}
`;

export const RemoveIncludedStages = gql`
  mutation RemoveIncludedStages($flowID: CommonID!) {
    removeAllIncludedStagesV2(flowID: $flowID) {
      flow {
        ...FlowV2Fragment
      }
      issues {
        ...IssueFragment
      }
    }
  }

  ${FlowV2Fragment}
  ${IssueFragment}
`;

export const GetStage = gql`
  query GetStage($ID: CommonID!, $ownerID: CommonID!, $projectID: CommonID) {
    flowStageV2(ID: $ID) {
      ...FlowStageV2Fragment
    }
    functionsV3(filter: { ownerID: $ownerID, projectID: $projectID }) {
      ...FuncSummaryFragment
    }
  }
  ${FlowStageV2Fragment}
  ${FuncSummaryFragment}
`;

export const UpdateStage = gql`
  mutation UpdateStage($ID: CommonID!, $updates: FlowStageV2Input!) {
    updateStageV2(ID: $ID, updates: $updates) {
      ...FlowStageV2Fragment
    }
  }

  ${FlowStageV2Fragment}
`;

export const UpdateStageInput = gql`
  mutation UpdateStageInput(
    $ID: CommonID!
    $tableID: ID
    $mode: InputSourceMode!
  ) {
    updateStageInputV2(ID: $ID, input: { tableID: $tableID, mode: $mode }) {
      ...FlowStageV2Fragment
    }
  }

  ${FlowStageV2Fragment}
`;

export const AddStep = gql`
  mutation AddStep(
    $stageID: CommonID!
    $index: Int!
    $type: StepTypeV2!
    $step: AddStepV2Input!
  ) {
    addStepV2(stageID: $stageID, index: $index, type: $type, step: $step) {
      ...FlowStageV2Fragment
    }
  }

  ${FlowStageV2Fragment}
`;

export const RemoveStep = gql`
  mutation RemoveStep($stageID: CommonID!, $key: ID!) {
    removeStepV2(stageID: $stageID, key: $key) {
      ...FlowStageV2Fragment
    }
  }

  ${FlowStageV2Fragment}
`;

export const MoveStep = gql`
  mutation MoveStep($stageID: CommonID!, $key: ID!, $move: Int!) {
    moveStepV2(stageID: $stageID, key: $key, move: $move) {
      ...FlowStageV2Fragment
    }
  }

  ${FlowStageV2Fragment}
`;

export const UpdateStep = gql`
  mutation UpdateStep($stageID: CommonID!, $key: ID!, $updates: StepV2Input!) {
    updateStepV2(stageID: $stageID, key: $key, updates: $updates) {
      ...FlowStageV2Fragment
    }
  }

  ${FlowStageV2Fragment}
`;

export const UpdateAggregateStep = gql`
  mutation UpdateAggregateStep(
    $stageID: CommonID!
    $key: ID!
    $updates: AggregateStepV2Input!
  ) {
    updateAggregateStepV2(stageID: $stageID, key: $key, updates: $updates) {
      ...FlowStageV2Fragment
    }
  }

  ${FlowStageV2Fragment}
`;

export const UpdateCombineStep = gql`
  mutation UpdateCombineStep(
    $stageID: CommonID!
    $key: ID!
    $updates: CombineStepV2Input!
  ) {
    updateCombineStepV2(stageID: $stageID, key: $key, updates: $updates) {
      ...FlowStageV2Fragment
    }
  }

  ${FlowStageV2Fragment}
`;

export const UpdateExecStep = gql`
  mutation UpdateExecStep(
    $stageID: CommonID!
    $key: ID!
    $updates: ExecStepV2Input!
  ) {
    updateExecStepV2(stageID: $stageID, key: $key, updates: $updates) {
      ...FlowStageV2Fragment
    }
  }

  ${FlowStageV2Fragment}
`;

export const UpdateFillStep = gql`
  mutation UpdateFillStep(
    $stageID: CommonID!
    $key: ID!
    $updates: FillStepV2Input!
  ) {
    updateFillStepV2(stageID: $stageID, key: $key, updates: $updates) {
      ...FlowStageV2Fragment
    }
  }

  ${FlowStageV2Fragment}
`;

export const UpdateFilterStep = gql`
  mutation UpdateFilterStep(
    $stageID: CommonID!
    $key: ID!
    $updates: FilterStepV2Input!
  ) {
    updateFilterStepV2(stageID: $stageID, key: $key, updates: $updates) {
      ...FlowStageV2Fragment
    }
  }

  ${FlowStageV2Fragment}
`;

export const UpdateGroupAndSortStep = gql`
  mutation UpdateGroupAndSortStep(
    $stageID: CommonID!
    $key: ID!
    $updates: GroupAndSortStepV2Input!
  ) {
    updateGroupAndSortStepV2(stageID: $stageID, key: $key, updates: $updates) {
      ...FlowStageV2Fragment
    }
  }

  ${FlowStageV2Fragment}
`;

export const UpdateOutputStep = gql`
  mutation UpdateOutputStep(
    $stageID: CommonID!
    $key: ID!
    $updates: OutputStepV2Input!
  ) {
    updateOutputStepV2(stageID: $stageID, key: $key, updates: $updates) {
      ...FlowStageV2Fragment
    }
  }

  ${FlowStageV2Fragment}
`;

export const UpdateSliceStep = gql`
  mutation UpdateSliceStep(
    $stageID: CommonID!
    $key: ID!
    $updates: SliceStepV2Input!
  ) {
    updateSliceStepV2(stageID: $stageID, key: $key, updates: $updates) {
      ...FlowStageV2Fragment
    }
  }

  ${FlowStageV2Fragment}
`;

export const AddTestCase = gql`
  mutation AddTestCase($input: AddTestCaseInput!) {
    addTestCase(input: $input) {
      flow {
        ...FlowV2Fragment
      }
      test {
        ...TestCaseFragment
      }
    }
  }
  ${TestCaseFragment}

  ${FlowV2Fragment}
`;

export const CloneTestCase = gql`
  mutation CloneTestCase($flowID: CommonID!, $testCaseKey: String!) {
    cloneTestCase(flowID: $flowID, testCaseKey: $testCaseKey) {
      flow {
        ...FlowV2Fragment
      }
      test {
        ...TestCaseFragment
      }
    }
  }
  ${TestCaseFragment}
  ${FlowV2Fragment}
`;

export const RemoveTestCase = gql`
  mutation RemoveTestCase($flowID: CommonID!, $testCaseKey: String!) {
    removeTestCase(flowID: $flowID, testCaseKey: $testCaseKey) {
      flow {
        ...FlowV2Fragment
      }
    }
  }
  ${FlowV2Fragment}
`;

export const UpdateTestCase = gql`
  mutation UpdateTestCase($input: UpdateTestCaseInput!) {
    updateTestCase(input: $input) {
      flow {
        ...FlowV2Fragment
      }
      issues {
        ...IssueFragment
      }
    }
  }
  ${FlowV2Fragment}
  ${IssueFragment}
`;

export const TestFlow = gql`
  mutation TestFlow($flowID: CommonID!, $testCaseKey: String!) {
    testFlow(flowID: $flowID, testCaseKey: $testCaseKey) {
      finalStatus {
        runID
        flowID
        jobID
        name
        comment
        externalResultURL
        started
        ended
        elapsed
        status
        errMsg
        stageDetails {
          configID
          errMsg
        }
      }
      stageLogs {
        ...StageLogFragment
      }
    }
  }
  ${StageLogFragment}
`;
