import React, { useState, useCallback } from 'react';
import fp from 'lodash/fp';

import { InternalDataLink } from '<sections>/jobs/styles';

import Button from '<src>/components/Button';
import { Message, Icon } from 'semantic-ui-react';
import { ConfigTable } from '../../styles';
import { TablePlusBtn, CellContent, CellInput } from '../styles';
import UnderlinedHeader from '<components>/UnderlinedHeader';

import { parseUserValue, formatCell } from '<sections>/functions/Cell/util';
import { getFormattingError, isValid } from '../utils';
import { NumbrzTooltip } from '<src>/components/NumbrzButtons';
import TestInputCell from '../TestInputCell';
import { FlexColumnContainer } from '<src>/components/NumbrzPageComponents';

const MAX_TEST_INPUTS = 15;

function InputRow({ elements, onAddRow, testDataInvalid, setTestDataInvalid }) {
  const [rowData, setRowData] = useState([]);
  const [adding, setAdding] = useState(false);

  const inputValue = useCallback(
    (key) => {
      const input = rowData.find((i) => i.key === key);
      return input ? formatCell(input, { ms: true }) : null;
    },
    [rowData]
  );

  const handleInputChange = useCallback(
    (col, value) => {
      const newRowData = [...rowData];
      let inputIdx = newRowData.findIndex((i) => i.key === col.key);

      const parsedVal = parseUserValue(value, { ms: true });
      const newInput = {
        key: col.key,
        ...parsedVal,
      };

      if (inputIdx < 0) {
        if (parsedVal.value !== '') newRowData.push(newInput);
      } else {
        parsedVal.value === ''
          ? newRowData.splice(inputIdx, 1)
          : (newRowData[inputIdx] = newInput);
      }
      setRowData(newRowData);

      // update invalid input array
      if (!isValid(value, col) && !testDataInvalid.includes(col.key)) {
        setTestDataInvalid([...testDataInvalid, col.key]);
      }
      // if valid, update invalid data array
      if (isValid(value, col) && testDataInvalid.includes(col.key)) {
        setTestDataInvalid([...testDataInvalid].filter((d) => d !== col.key));
      }
    },
    [rowData, testDataInvalid, setTestDataInvalid]
  );

  const handleAddRow = useCallback(async () => {
    setAdding(true);
    onAddRow(rowData);
    setRowData([]);
    setAdding(false);
  }, [rowData, onAddRow]);

  return (
    <tr>
      <td style={{ backgroundColor: '#F6F8F9' }}> </td>
      {elements.map((col, idx) => (
        <td
          key={idx}
          className={testDataInvalid.includes(col.key) && 'warning'}
        >
          <CellContent>
            <CellInput
              singleClick
              placeholder={col.type === 'DateTime' ? 'MM/DD/YY' : null}
              value={inputValue(col.key)}
              onSubmit={(e) => handleInputChange(col, e.value)}
            />
            {testDataInvalid.includes(col.key) && (
              <NumbrzTooltip
                position="top center"
                header={''}
                trigger={<Icon name="warning circle" />}
                wide="very"
                content={getFormattingError(col.type)}
              />
            )}
          </CellContent>
        </td>
      ))}
      <td>
        <TablePlusBtn
          disabled={adding || testDataInvalid.length > 0}
          onClick={handleAddRow}
        />
      </td>
    </tr>
  );
}

export default function TestDataInput({
  baseURL,
  table,
  updateInput,
  testInput,
}) {
  const inputRecords = fp.getOr([], 'records', testInput);
  const [testDataInvalid, setTestDataInvalid] = useState([]);

  const handleAddRow = useCallback(
    (rowData) => {
      updateInput({
        tableDefID: testInput ? testInput.tableDefID : table.ID,
        records: [...inputRecords, { fields: rowData }],
      });
    },
    [inputRecords, testInput, updateInput, table.ID]
  );

  const updateCellValue = useCallback(
    async (rowIdx, col, newValue) => {
      const newRecords = [...inputRecords];
      const newRow = { fields: [...newRecords[rowIdx].fields] };
      const inputIdx = newRow.fields.findIndex((f) => f.key === col.key);
      const parsedVal = parseUserValue(newValue, { ms: true });
      const newInput = {
        key: col.key,
        ...parsedVal,
      };
      if (inputIdx < 0) {
        newRow.fields.push(newInput);
      } else {
        newValue === ''
          ? newRow.fields.splice(inputIdx, 1)
          : (newRow.fields[inputIdx] = newInput);
      }
      newRecords[rowIdx] = newRow;

      await updateInput({
        tableDefID: testInput ? testInput.tableDefID : table.ID,
        records: [...newRecords],
      });
    },
    [table.ID, inputRecords, updateInput, testInput]
  );

  const handleRemoveRow = useCallback(
    async (rowIdx) => {
      await updateInput({
        ...testInput,
        records: inputRecords.filter((r, idx) => idx !== rowIdx),
      });
    },
    [inputRecords, testInput, updateInput]
  );

  return (
    <FlexColumnContainer
      alignItems="flex-start"
      width="100%"
      style={{ overflowX: 'auto', margin: '15px 0 10px 0' }}
    >
      <UnderlinedHeader margin="0 0 5px 0" padding="0 0 2px 0">
        <InternalDataLink
          bold
          fontSize="12px"
          to={`${baseURL}/job-tables/${table.ID}`}
        >
          {table.name}
        </InternalDataLink>
      </UnderlinedHeader>

      <ConfigTable margin="0 0 10px 0" style={{ width: '100%' }}>
        <thead>
          <tr>
            <th style={{ width: '15px' }} />
            {table.schema.elements.map((col, hdrIdx) => (
              <th key={hdrIdx}>{col.label}</th>
            ))}
            <th style={{ width: '40px' }} />
          </tr>
        </thead>
        <tbody>
          <tr />
          {inputRecords.map((row, yIdx) => (
            <tr key={yIdx}>
              <td style={{ backgroundColor: '#F6F8F9' }}>{yIdx + 1}</td>
              {table.schema.elements.map((sEl, xIdx) => {
                const testValueObj = row.fields.find((f) => f.key === sEl.key);
                return (
                  <TestInputCell
                    key={xIdx}
                    col={sEl}
                    rowIdx={yIdx}
                    value={testValueObj}
                    onUpdateCell={updateCellValue}
                  />
                );
              })}
              <td>
                <Button.TableDeleteBtn onClick={(e) => handleRemoveRow(yIdx)} />
              </td>
            </tr>
          ))}
          {(inputRecords.length === 0 ||
            inputRecords.length < MAX_TEST_INPUTS) && (
            <InputRow
              elements={table.schema.elements}
              onAddRow={handleAddRow}
              testDataInvalid={testDataInvalid}
              setTestDataInvalid={setTestDataInvalid}
            />
          )}
        </tbody>
      </ConfigTable>

      {inputRecords.length === MAX_TEST_INPUTS && (
        <Message info>
          <p>Max number of test records reached.</p>
        </Message>
      )}
    </FlexColumnContainer>
  );
}
