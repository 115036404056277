import React, { useState, useMemo, useCallback } from 'react';
import { useQuery } from '<src>/apollo/client';

import { Dropdown } from 'semantic-ui-react';
import Dialog from '<components>/Dialog';
import Button from '<components>/Button';
import { GetProjects } from '<sections>/projects/queries';
import { DropdownWrapperWithLabel } from '<components>/NumbrzPageComponents';

export function TargetPicker({
  projects = [],
  targetProjectID,
  setTargetProjectID,
  defaultProject,
}) {
  const options = useMemo(
    () =>
      projects.length > 0
        ? projects.map((p, idx) => ({
            key: idx,
            value: p.ID,
            text: p.name,
          }))
        : [
            {
              value: defaultProject.ID,
              text: defaultProject.name,
            },
          ],
    [projects, defaultProject]
  );
  return (
    <DropdownWrapperWithLabel className="ui labeled" name="flow">
      <div className="ui label">Target Model</div>
      <Dropdown
        closeOnBlur
        options={options}
        onChange={(e, { value }) => setTargetProjectID(value)}
        placeholder={`Select Model`}
        value={targetProjectID}
      />
    </DropdownWrapperWithLabel>
  );
}

export default function CloneFunctionDialog({
  functionID,
  cloneFunction,
  defaultProject = {},
  visible,
  onClose,
}) {
  const [targetProjectID, setTargetProjectID] = useState(defaultProject.ID);

  const { data: { projects = [] } = {} } = useQuery(GetProjects);

  const onCloneFunction = useCallback(() => {
    cloneFunction(functionID, targetProjectID);
    onClose();
  }, [functionID, targetProjectID, cloneFunction, onClose]);

  return (
    <Dialog visible={visible} onRequestClose={onClose}>
      <Dialog.Header>
        <Dialog.Headline>Clone Function</Dialog.Headline>
        <Button.DialogClose onClick={onClose} />
      </Dialog.Header>
      <Dialog.Body>
        <TargetPicker
          projects={projects}
          defaultProject={defaultProject}
          targetProjectID={targetProjectID}
          setTargetProjectID={setTargetProjectID}
        />
      </Dialog.Body>
      <Dialog.Footer>
        <Button onClick={onCloneFunction}>Clone</Button>
      </Dialog.Footer>
    </Dialog>
  );
}
