import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { Dropdown, Input } from 'semantic-ui-react';

import DetailRadio from '<components>/DetailRadio';
import UnderlinedHeader from '<components>/UnderlinedHeader';
import { DropdownWrapperWithLabel } from '<components>/NumbrzPageComponents';

const StyledInput = styled(Input)`
  width: 100%;
  & div.label {
    width: 25%;
  }
`;

export default function JobDetails({
  onSubmit,
  allowAutomap,
  creating,
  mappingMode,
  name,
  onChange,
  flows,
  flowID,
}) {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSubmit();
    }
  };

  const flowOptions = flows.map((flow) => {
    return { text: flow.name, value: flow.ID, key: flow.ID };
  });

  return (
    <div>
      <DropdownWrapperWithLabel className="ui labeled" name="flow">
        <div className="ui label">Flow</div>
        <Dropdown
          closeOnBlur
          options={flowOptions}
          onChange={(e, data) => onChange('flowID', data.value)}
          placeholder={`Select Flow`}
          value={flowID}
        />
      </DropdownWrapperWithLabel>
      <StyledInput
        label="Job Name"
        name="name"
        onChange={({ target: { name, value } }) => onChange(name, value)}
        onKeyDown={handleKeyDown}
        disabled={creating}
        value={name}
      />
      {allowAutomap && (
        <Fragment>
          <UnderlinedHeader>Job Data</UnderlinedHeader>
          <DetailRadio
            name="mappingMode"
            value="auto"
            selectedValue={mappingMode}
            label="Automatically configure data mappings"
            onChange={(mappingMode) => onChange('mappingMode', mappingMode)}
          >
            We will attempt to map the inputs and outputs of the job to data you
            specify.
          </DetailRadio>
          <DetailRadio
            name="mappingMode"
            value="manual"
            selectedValue={mappingMode}
            label="Manually configure data mappings"
            onChange={(mappingMode) => onChange('mappingMode', mappingMode)}
          >
            Configure the data mappings for this job manually.
          </DetailRadio>
        </Fragment>
      )}
    </div>
  );
}
