import { gql } from '@apollo/client';

import { FunctionChangesFragment } from './fragments';
import useEditFunction, { optimisticFunctionChanges } from './useEditFunction';

const UpdateDefaultValue = gql`
  mutation UpdateDefaultValue(
    $funcID: CommonID!
    $templateID: ID!
    $dataType: CellType!
    $defaultValue: Any
  ) {
    updateDefaultValueV3(
      funcID: $funcID
      templateID: $templateID
      dataType: $dataType
      defaultValue: $defaultValue
    ) {
      ...FunctionChangesFragment
    }
  }
  ${FunctionChangesFragment}
`;

export default function useUpdateDefaultValue() {
  const updateDefaultValue = useEditFunction(
    UpdateDefaultValue,
    'updateDefaultValueV3'
  );

  return (template, { dataType, value: defaultValue }) => {
    dataType = dataType !== undefined ? dataType : template.dataType;
    return updateDefaultValue({
      variables: {
        funcID: template.funcID,
        templateID: template.ID,
        dataType,
        defaultValue,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateDefaultValueV3: optimisticFunctionChanges({
          funcID: template.funcID,
          templatesChanged: [
            {
              __typename: 'CellTemplate',
              ...template,
              dataType,
              defaultValue:
                defaultValue !== undefined
                  ? defaultValue
                  : template.defaultValue,
            },
          ],
        }),
      },
    });
  };
}
