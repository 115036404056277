export const calcLabels = {
  Sum: 'Sum',
  Avg: 'Average',
  Min: 'Minimum',
  Max: 'Maximum',
  Cnt: 'Count',
  Median: 'Median',
  Entropy: 'Entropy',
  IQR: 'Interquartile Range',
  GMean: 'Geometric mean',
  HMean: 'Harmonic mean',
  Midhinge: 'Midhinge',
  Quartile1: 'Quartile 1',
  Quartile2: 'Quartile 2',
  Quartile3: 'Quartile 3',
  StdDev: 'Standard Deviation',
  Trimean: 'Tri-mean',
  Variance: 'Variance',
};

export const calcOptions = [
  { key: 'Sum', value: 'Sum', text: calcLabels.Sum },
  { key: 'Avg', value: 'Avg', text: calcLabels.Avg },
  { key: 'Min', value: 'Min', text: calcLabels.Min },
  { key: 'Max', value: 'Max', text: calcLabels.Max },
  { key: 'Cnt', value: 'Cnt', text: calcLabels.Cnt },
  { key: 'Median', value: 'Median', text: calcLabels.Median },
  { key: 'Entropy', value: 'Entropy', text: calcLabels.Entropy },
  { key: 'IQR', value: 'IQR', text: calcLabels.IQL },
  { key: 'GMean', value: 'GMean', text: calcLabels.GMean },
  { key: 'HMean', value: 'HMean', text: calcLabels.HMean },
  { key: 'Midhinge', value: 'Midhinge', text: calcLabels.Midhinge },
  { key: 'Quartile1', value: 'Quartile1', text: calcLabels.Quartile1 },
  { key: 'Quartile2', value: 'Quartile2', text: calcLabels.Quartile2 },
  { key: 'Quartile3', value: 'Quartile3', text: calcLabels.Quartile3 },
  { key: 'StdDev', value: 'StdDev', text: calcLabels.StdDev },
  { key: 'Trimean', value: 'Trimean', text: calcLabels.Trimean },
  { key: 'Variance', value: 'Variance', text: calcLabels.Variance },
];
