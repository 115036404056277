/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx, css } from '@emotion/react';

import { PureComponent } from 'react';
import T from 'prop-types';

import withFilteredProps from '<components>/hoc/filteredProps';
import withTag from '<components>/hoc/tag';

const icon = css({
  display: 'inline-flex',
  alignSelf: 'center',

  'button&': {
    cursor: 'pointer',
    minHeight: '1em',
    outline: 'none',
    verticalAlign: 'baseline',
    background: 'transparent',
    border: 'none',
    margin: '0',
    padding: '0',
    lineHeight: '1em',
  },

  '& > svg': {
    fill: 'currentColor',
    color: 'inherit',
  },
});

const iconBaseline = css({
  transform: 'translateY(1px)',
});

const iconInverse = css({
  color: '#fff',
});

@withFilteredProps([
  'adjust',
  'adjust',
  'as',
  'children',
  'inverse',
  'size',
  'titleId',
  'title',
  'viewBox',
])
@withTag('span')
class IconBase extends PureComponent {
  static propTypes = {
    adjust: T.oneOf([false, 'baseline']),
    as: T.oneOf(['span', 'button', 'div']),
    children: T.node.isRequired,
    className: T.string,
    inverse: T.bool.isRequired,
    size: T.oneOfType([T.number, T.string]).isRequired,
    titleId: T.string.isRequired,
    title: T.string.isRequired,
    viewBox: T.string,
  };

  render() {
    const {
      as: Tag,
      children,
      size,
      titleId,
      title,
      viewBox,
      filteredProps,
      adjust,
      inverse,
    } = this.props;

    const width = size;
    const height = size;

    return (
      <Tag
        width={width}
        height={height}
        {...filteredProps}
        css={[
          icon,
          ...(adjust === 'baseline' ? [iconBaseline] : []),
          ...(inverse ? [iconInverse] : []),
        ]}
      >
        <svg
          aria-labelledby={titleId}
          preserveAspectRatio="xMidYMid meet"
          xmlns="http://www.w3.org/2000/svg"
          viewBox={viewBox}
          width={width}
          height={height}
        >
          <title id={titleId}>{title}</title>
          {children}
        </svg>
      </Tag>
    );
  }
}

IconBase.defaultProps = {
  adjust: 'baseline',
  inverse: false,
  titleId: 'icon-title',
  size: '1em',
  viewBox: '0 0 24 24',
};

export default IconBase;
