import React, { useCallback, useEffect, useMemo, useState } from 'react';
import keyBy from 'lodash/keyBy';

import Button from '<src>/components/Button';
import FieldPicker from '../../FieldPicker';

export default function AddRow({ elements, toElements, onAddRow }) {
  const [row, setRow] = useState([]);
  const colTypes = useMemo(() => {
    const elementsByKey = keyBy(elements, (e) => e.key);
    return toElements.map((e, colIdx) => {
      if (e.type && e.type !== 'Unspecified') return [e.type];

      const element = row[colIdx] ? elementsByKey[row[colIdx]] : null;
      if (element) return [element.type];

      return undefined;
    });
  }, [elements, row, toElements]);

  useEffect(() => {
    setRow(toElements.map(() => null));
  }, [toElements]);

  const onSetColumnKey = useCallback(
    (colIdx, key) =>
      setRow((row) => row.map((k, idx) => (idx === colIdx ? key : k))),
    []
  );

  return (
    <tr>
      {row.map((key, colIdx) => (
        <td key={colIdx}>
          <FieldPicker.Dropdown
            elements={elements}
            selected={key}
            onChange={(e, { selected }) => onSetColumnKey(colIdx, selected)}
            restrictTypes={colTypes[colIdx]}
          />
        </td>
      ))}
      <td className="btn">
        <Button.TableAddBtn
          disabled={row.every((k) => !k)}
          title="Add Output Row"
          onClick={(e) => {
            onAddRow(e, { row });
            setRow(toElements.map(() => null));
          }}
        />
      </td>
    </tr>
  );
}
