import fp from 'lodash/fp';
import React, { PureComponent } from 'react';
import T from 'prop-types';
import classnames from 'classnames';

import withTag from '<components>/hoc/tag';
import withClassName from '<components>/hoc/className';
import withDefaultProps from '<components>/hoc/defaultProps';
import withFilteredProps from '<components>/hoc/filteredProps';

import styledTable from '../styles';

@withTag('button')
@withClassName((props) =>
  classnames({
    [styledTable.control.action.base]: true,
    [styledTable.control.action.text]: props.variant === 'text',
  })
)
@withFilteredProps(['variant'])
@withDefaultProps({ onMouseDown: fp.noop })
export default class ControlAction extends PureComponent {
  static propTypes = {
    variant: T.oneOf(['text']),
  };

  handleMouseDown = (event) => {
    // Prevent focus on click
    event.preventDefault();
    this.props.onMouseDown(event);
  };

  render() {
    const { as: Tag, filteredProps } = this.props;
    return <Tag {...filteredProps} onMouseDown={this.handleMouseDown} />;
  }
}
