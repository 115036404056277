import { gql } from '@apollo/client';

import { FunctionChangesFragment } from './fragments';
import useEditFunction from './useEditFunction';

const UpdateCellFormat = gql`
  mutation UpdateCellFormat(
    $funcID: CommonID!
    $cellID: ID!
    $format: CellFormatInput!
  ) {
    updateCellFormatV3(funcID: $funcID, cellID: $cellID, format: $format) {
      ...FunctionChangesFragment
    }
  }
  ${FunctionChangesFragment}
`;

export default function useUpdateCellFormat() {
  const updateCellFormat = useEditFunction(
    UpdateCellFormat,
    'updateCellFormatV3'
  );
  return (cell, format) =>
    updateCellFormat({
      variables: {
        funcID: cell.funcID,
        cellID: cell.ID,
        format,
      },
    });
}
