import React from 'react';

import ValueOrElement from '../../ValueOrElement';

import FieldUnit from './FieldUnit';

export default function FieldValue({ elements, field }) {
  return (
    <span>
      <ValueOrElement value={field.amount} elements={elements} />
      <FieldUnit field={field} />
    </span>
  );
}
