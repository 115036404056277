import React from 'react';

import { ConnectActions, ListPicker } from './styles';

export default function OwnerStep({ accounts, ownerID, onSetOwnerID }) {
  return (
    <div>
      <p>Select the account into which to deploy the model.</p>
      <ConnectActions>
        <ListPicker
          value={ownerID}
          onChange={(e) => onSetOwnerID(e.target.value)}
          size={Math.min(accounts.length, 10)}
        >
          <option value={undefined}>Select account</option>
          {accounts.map((account) => (
            <option key={account.ID} value={account.ID}>
              {account.display}
            </option>
          ))}
        </ListPicker>
      </ConnectActions>
    </div>
  );
}
