import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

import { StyledMenu } from '../SidebarMenu';

export { SidebarSeparator } from '../SidebarMenu';

export default function SidebarNavMenu({ options, height, minHeight }) {
  return (
    <StyledMenu
      pointing
      secondary
      vertical
      height={height}
      minHeight={minHeight}
    >
      {options.map((item, idx) => (
        <NavLink key={item.key} to={item.to}>
          <Menu.Item>
            {item.text}
            {item.additionalContent ? item.additionalContent : null}
          </Menu.Item>
        </NavLink>
      ))}
    </StyledMenu>
  );
}
