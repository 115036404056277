import React from 'react';

import Dialog from '<components>/Dialog';
import Button from '<components>/Button';

import AddJobForm from '../components/AddJobForm';

export default function AddJobDialog({
  projectID,
  sourceProjectID = projectID,
  extraJobProps = {},
  allowAutomap = true,
  onDialogClose,
  visible,
  headline = 'Create a job',
  modelsPath = '/models',
  jobsPath = '/jobs',
}) {
  return (
    <Dialog visible={visible} onRequestClose={onDialogClose}>
      <Dialog.Header>
        <Dialog.Headline>{headline}</Dialog.Headline>
        <Button.DialogClose onClick={onDialogClose} />
      </Dialog.Header>
      <AddJobForm
        dialog
        onJobCreated={onDialogClose}
        onDialogClose={onDialogClose}
        projectID={projectID}
        sourceProjectID={sourceProjectID}
        extraJobProps={extraJobProps}
        allowAutomap={allowAutomap}
      />
    </Dialog>
  );
}
