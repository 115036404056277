import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useHistory } from 'react-router-dom';

const PROD_DOMAINS = ['app.numbrz.com', 'app.numbrz.org', 'app.debug'];
const IS_PROD = PROD_DOMAINS.includes(window.location.hostname);

export { withAuthenticationRequired } from '@auth0/auth0-react';

/**
 * Mapping of host to client ID. We use a different Auth0 domain and client ID
 * in production from what we use in dev/staging. Production hosts are
 * whitelisted, while any other host is assumed to be a dev/staging host.
 */
const DEV_AUTH0_ID = '3zg2Cr5t7Wk93YjeAf7Mo3L12wbZKXbb';
const PROD_AUTH0_ID = 'aWdyfXistGXTZLcZVOREGUdNq4fVji9d';

const DEV_AUTH0_DOMAIN = 'numbrz-dev.auth0.com';
const PROD_AUTH0_DOMAIN = 'numbrz.auth0.com';

const DEV_DB_CONNECTION = 'Username-Password-Authentication';
const PROD_DB_CONNECTION = 'Username-Password-Authentication';

const DEV_API_AUDIENCE = 'https://numbrz.dev/client-api';
const PROD_API_AUDIENCE = 'https://app.numbrz.com/client-api';

const AUTH0_DOMAIN = IS_PROD ? PROD_AUTH0_DOMAIN : DEV_AUTH0_DOMAIN;
const AUTH0_CLIENT_ID = IS_PROD ? PROD_AUTH0_ID : DEV_AUTH0_ID;
const DB_CONNECTION = IS_PROD ? PROD_DB_CONNECTION : DEV_DB_CONNECTION;
const API_AUDIENCE = IS_PROD ? PROD_API_AUDIENCE : DEV_API_AUDIENCE;

export function AuthProvider(props) {
  const history = useHistory();
  return (
    <Auth0Provider
      {...props}
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={{
        scope: 'https://numbrz.com/roles openid email',
        audience: API_AUDIENCE,
        redirect_uri: `${window.location.origin}/auth_callback`,
      }}
      onRedirectCallback={(appState) => {
        history.replace(appState.returnTo);
      }}
    />
  );
}

export function useAuth() {
  const {
    isAuthenticated,
    isLoading,
    error,
    user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();

  return {
    isAuthenticated,
    isLoading,
    error,
    user,
    getToken: getAccessTokenSilently,
    getIDToken: getIdTokenClaims,
    login: () => loginWithRedirect({ numbrz_show_signup: false }),
    logout: () => logout({ returnTo: `${window.location.origin}/` }),
    resetPassword: async () => {
      if (!user) return false;
      const resp = await fetch(
        `https:${AUTH0_DOMAIN}/dbconnections/change_password`,
        {
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
          body: JSON.stringify({
            client_id: AUTH0_CLIENT_ID,
            email: user.email,
            connection: DB_CONNECTION,
          }),
        }
      );
      return resp.ok;
    },
  };
}
