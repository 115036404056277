import React, { useContext } from 'react';
import styled from '@emotion/styled';

import { Selection } from '../contexts';
import { styles as cardStyles } from '../Card';
import AnimatedResize from '../AnimatedResize';
import CellSettingsPane from '../CellSettings';
import useGetCachedItem from '../gql/useGetCachedItem';
import UnderlinedHeader from '<src>/components/UnderlinedHeader';

const StyledSettings = styled('div')`
  ${cardStyles}

  & > * {
    margin-bottom: 8px;
  }

  margin-bottom: 6px;
`;

function CellSettings({ api, widget }) {
  const { activeItems } = useContext(Selection);

  const item =
    activeItems.WIDGET && activeItems.WIDGET.ID === widget.ID
      ? activeItems.CELL
      : null;
  const cell = useGetCachedItem(widget.funcID, item);

  return (
    <div>
      <UnderlinedHeader uppercase>Cell Settings</UnderlinedHeader>
      <CellSettingsPane
        cell={cell}
        editable
        setType={api.updateCellType}
        setFormat={api.updateCellFormat}
      />
    </div>
  );
}

function WidgetSettings({ api, widget, func, parent }) {
  if (!func.Settings) return null;

  return (
    <div>
      <UnderlinedHeader uppercase>Widget Settings</UnderlinedHeader>
      <func.Settings api={api} widget={widget} parent={parent} />
    </div>
  );
}

export default function Settings({
  api,
  func,
  widget,
  expanded,
  parent,
  widthRef,
}) {
  return (
    <AnimatedResize expanded={expanded}>
      <StyledSettings>
        <WidgetSettings api={api} widget={widget} func={func} parent={parent} />
        {api.updateCellType ? <CellSettings api={api} widget={widget} /> : null}
      </StyledSettings>
    </AnimatedResize>
  );
}
