/** @jsx jsx */
/** @jsxRuntime classic */

import styled from '@emotion/styled';
import { jsx, css } from '@emotion/react';
import * as colors from '<components>/colors';
import { Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Button from '<src>/components/Button';

import {
  StepDropdownWrapper,
  StepToolBarMenu,
} from '<src>/sections/flows/flow-testing/styles';

export const container = css({
  width: '60%',
  margin: '20px auto',
  padding: '0 20px',
  display: 'flex',
  flexDirection: 'column',
});

export const Line = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 100%;

  margin: 0 auto 40px auto;
  padding: 0px 0px 0px 0px;

  &:before {
    display: flex;
    justify-content: center;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    border-right: 4px dotted ${colors.gray3};
    z-index: -1;
  }
`;

export const JobStatusSummaryContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`;

export const JobStatusSummaryHeader = styled('h4')`
  color: ${colors.gray1b} !important;
  padding-bottom: 8px;
`;

export const StatusSummaryOp = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 10px;
  margin-bottom: 10px;
  span {
    color: ${colors.gray1b};
    font-weight: bold;
    font-size: 1.1em;
  }
`;

export const StageSummaryContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props) =>
    props.collapsed ? 'none' : `1px solid ${colors.gray_border}`};
  padding: 7px 5px;
`;

export const ServiceLabel = styled('span')`
  color: ${colors.gray1} !important;
  font-weight: normal !important;
  font-size: 1em !important;
`;

export const StageSummaryToolbar = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    margin: -10px 0 0 30px;
  }
  a {
    margin: 0;
  }
  h4 {
    font-size: 13px;
    margin: 0;
    padding: 0;
  }
`;

export const BlockSubheader = styled('div')`
  width: 100%;
  font-size: 10px;
  color: ${colors.gray1};
  span {
    margin-left: 5px;
    font-size: 11px;
  }
`;

export const StatusSummaryOps = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 10px 15px;
  ${(props) => props.collapsed && { display: 'none' }};
`;

export const StageToolbar = styled('div')`
  display: flex;
  justify-content: space-around;
  margin-top: -10px;
  align-items: center;
  i {
    margin: 0 5px 0 0;

    height: unset;
  }
  span {
    margin-left: 10px;
  }
  button {
    margin-left: 10px;
  }
`;

export const StageContainer = styled('div')`
  background-color: white;

  ${(props) =>
    !props.last && { borderBottom: `1px solid ${colors.gray_border}` }};

  width: 100%;
  display: flex;
  flex-direction: column;
  h4 {
    margin: 0 0 5px 0;
    padding: 0 0 5px 10px;
  }
`;

export const statusBanner = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 20,
  color: colors.gray3,
  borderRadius: 4,
  marginRight: 30,
});

export const completed = css({
  [`&.${statusBanner}`]: {
    backgroundColor: 'transparent',
  },
});

export const running = css({
  [`&.${statusBanner}`]: {
    backgroundColor: 'transparent',
  },
  paddingTop: 40,
});

export const failed = css({
  [`&.${statusBanner}`]: {
    backgroundColor: colors.red3,
  },
  padding: 15,
  marginTop: 3,
});

export const Summary = styled('div')`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ProgressBarContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    margin: 0 5px;
    width: 220px;
    text-align: center;
    span,
    time {
      font-size: 1em;
    }
  }
`;

export const diminished = css({
  color: '#e0e0e0',
  fontSize: 20,
});

export const summaryList = css({
  listStyle: 'none',
  margin: 0,
  paddingLeft: 0,
  display: 'flex',

  '& li': {
    marginRight: 20,
  },
});

export const summaryListLabel = css({
  color: '#444',
  fontSize: 11,
  fontWeight: 'bold',
  textTransform: 'uppercase',
  marginRight: '5px',
});

export const StepList = styled('div')`
  ${(props) => props.collapsed && { display: 'none' }};
  list-style: none;
  margin: 0;
  padding: 15px 0;
  table {
    width: 100%;
    td a {
      font-size: 12px;
    }
  }
`;

export const StyledText = styled('span')`
  color: ${(props) => (props.textColor ? props.textColor : colors.gray1)};
  text-transform: uppercase;
  padding-right: 5px;
  font-size: 12px;
`;

export const CancelButton = (props) => (
  <Button
    {...props}
    css={[
      css`
        font-size: 10px;
        height: unset;
        border-radius: 4px;
        padding: 4px 5px;

        text-transform: uppercase;
        svg {
          font-weight: normal;
        }
        :disabled {
          border: none;
          cursor: not-allowed;
        }
      `,
    ]}
  />
);

export const StyledLoader = styled(Loader)`
  margin-right: 15px;
  ::after {
    border-color: ${(props) =>
      `${
        props.borderColor ? props.borderColor : '#6aa5ff'
      } transparent transparent !important`};
  }
`;

export const StyledLink = styled(Link)`
  font-weight: bold;
  font-size: 13px;
  color: ${colors.gray1b};
  :hover {
    color: ${colors.blue1};
  }
`;

export const StyledH3 = styled('h3')`
  font-weight: bold;
  font-size: 13px;
  color: ${colors.gray1b};
`;

export const errorList = css({
  listStyle: 'none',
  margin: 0,
  paddingLeft: 0,

  '& li': {
    display: 'flex',
    borderRadius: 2,
    backgroundColor: colors.gray4,
    marginBottom: 10,
    padding: 10,
    border: '0 solid red',
    borderLeftWidth: 6,
  },
});

export const reviewComments = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px dotted ${colors.gray3};
  padding: 10px 5px;
  .headerLabel {
    font-weight: bold;
    padding-right: 5px;
  }
`;

export const StatusContainer = styled('div')`
  margin: 10px 10px 15px 10px;
  display: flex;
  flex-direction: column;
`;

export const StatusHeader = styled('div')`
  padding: 5px;
  margin-bottom: 10px;
  display: flex;
  border-bottom: 1px solid ${colors.gray_border};
  font-weight: bold;
  font-size: 13px;
  align-items: end;
  justify-content: space-between;

  i {
    margin-left: 2px;
    cursor: pointer;
  }
`;

export const SectionHdrBtn = styled('button')`
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-start;
  background: transparent;
  font-weight: bold;
  font-size: 11px;
  text-transform: uppercase;
  color: ${colors.gray1e};
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  cursor: pointer;
`;

export const StatusToolbar = styled('div')`
  display: flex;
  align-items: center;
  button {
    margin-left: 3px;
    color: ${colors.gray1};
    letter-spacing: 0.5px;
    &.active {
      background: ${colors.yellow4};
      font-weight: bold;
    }
  }
`;

export const ToggleBtn = styled('button')`
  outline: none;
  border: none;
  box-shadow: none;
  font-size: 11px;
  color: ${colors.gray1};
  cursor: pointer;
  border-radius: 4px;
  padding: 4px 6px;
  background: transparent;
  ${(props) =>
    props.active && {
      background: colors.yellow7,
      border: `1px solid ${colors.gray_border}`,
      ':hover': {
        background: colors.yellow4,
      },
    }}
`;

export const ToolbarSeparator = styled('span')`
  width: 1px;
  height: 15px;
  background: #e0e0e0;
  margin: 0 3px;
`;

export const StatusChiclet = styled('span')`
  height: fit-content;
  min-height: 20px;
  font-size: 13px;
  padding: 4px 8px;
  border-radius: 50px;
  font-weight: normal !important;
  color: ${(props) => (props.textColor ? props.textColor : colors.gray1)};

  background: ${(props) =>
    `${props.backgroundColor ? props.backgroundColor : 'transparent'}`};
  display: inline-flex;
  align-items: center;
  margin-left: ${(props) => (props.marginRight ? props.marginRight : '10px')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '10px')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
  div.loader {
    margin-left: 7px !important;
  }
`;

export const PopupWrapper = styled(StepDropdownWrapper)`
  z-index: unset;
`;

export const PopupContent = styled(StepToolBarMenu)`
  top: 10px;
  left: -320%;
  ul {
    list-style-type: disc !important;
    text-align: left;
  }
  li {
    color: ${colors.red1c};
    margin: 5px 0;
  }
`;

export const StageHdr = styled('div')`
  display: flex;

  span {
    margin-left: 10px;
    font-size: 12px !important;
    min-height: 18px;
  }
`;
