import React, { Fragment, useContext } from 'react';

import { Plus, Trash } from '<components>/Icons';

import { Selection } from '../../contexts';
import Cell, { StyledHeaderCell } from '../../Cell';
import RangeLabel from '../../Widget/RangeLabel';
import Table from '../../Table';

import useAppearTrigger from '../../useAppearTrigger';

function ColumnControl({ api, widget, colIdx, setAppearTrigger }) {
  const actions = [];
  if (api.deleteColumn && widget.columns.length > 1) {
    actions.push(
      <Table.Action key="delete">
        <Trash
          onClick={() => api.deleteColumn(widget, widget.columns[colIdx])}
        />
      </Table.Action>
    );
  }
  if (api.addColumn) {
    actions.push(
      <Table.Action
        key="add"
        onClick={() => {
          const beforeID = widget.columns[colIdx + 1]
            ? widget.columns[colIdx + 1].ID
            : null;
          api.addColumn(widget, {
            beforeID,
            correlationID: setAppearTrigger('edit'),
          });
        }}
      >
        <Plus />
      </Table.Action>
    );
  }
  return (
    <Table.ColumnControl
      draggable={widget.columns.length > 1}
      tabIndex={actions.length > 0 ? 0 : undefined}
    >
      {actions}
    </Table.ColumnControl>
  );
}

function Header({ api, widget }) {
  const [appearTrigger, setAppearTrigger] = useAppearTrigger();
  return (
    <>
      <Table.Columns>
        <Table.ControlColumn />
        {widget.columns.map((col) => (
          <Table.Column key={col.ID} />
        ))}
        {widget.config.showTotals ? <Table.Column /> : null}
      </Table.Columns>
      <Table.Head>
        <Table.ControlRow>
          <Table.ColumnControl />
          <Table.ColumnControl />
          {widget.columns.map((col, colIdx) => (
            <ColumnControl
              key={col.ID}
              api={api}
              widget={widget}
              colIdx={colIdx}
              setAppearTrigger={setAppearTrigger}
            />
          ))}
          {widget.config.showTotals ? <Table.ColumnControl /> : null}
        </Table.ControlRow>
        <Table.Row>
          <Table.RowControl />
          <StyledHeaderCell />
          {widget.columns.map((col, colIdx) => (
            <RangeLabel
              key={col.ID}
              widget={widget}
              range={col}
              appearTrigger={appearTrigger}
              onUpdate={api.updateColumn}
            />
          ))}
          {widget.config.showTotals ? (
            <RangeLabel
              widget={widget}
              range={widget.config.totals}
              appearTrigger={appearTrigger}
              onUpdate={(_, __, { label }) =>
                api.updateColumn(widget, widget.config.totals, { label })
              }
            />
          ) : null}
        </Table.Row>
      </Table.Head>
    </>
  );
}

function RowControl({ api, widget, rowIdx, setAppearTrigger }) {
  if (rowIdx === widget.rows.length - 1) {
    return <Table.RowControl />;
  }

  const actions = [];
  if (api.deleteRow && widget.rows.length > 2) {
    actions.push(
      <Table.Action
        key="delete"
        onClick={() => api.deleteRow(widget, widget.rows[rowIdx])}
      >
        <Trash />
      </Table.Action>
    );
  }
  if (api.addRow) {
    actions.push(
      <Table.Action
        key="add"
        onClick={() => {
          const beforeID = widget.rows[rowIdx + 1].ID;
          api.addRow(widget, {
            beforeID,
            correlationID: setAppearTrigger('edit'),
          });
        }}
      >
        <Plus />
      </Table.Action>
    );
  }
  const draggable = api.moveRow && widget.rows.length > 2;

  return (
    <Table.RowControl
      draggable={draggable}
      tabIndex={actions.length > 0 ? 0 : undefined}
    >
      {actions}
    </Table.RowControl>
  );
}

export default function ArithmeticBody({
  api,
  active,
  widget,
  dispatch,
  expanded,
  showNewRow,
}) {
  const { select } = useContext(Selection);
  const [appearTrigger, setAppearTrigger] = useAppearTrigger();

  const columns = [...widget.columns];
  if (widget.config.showTotals) {
    columns.push(widget.config.totals);
  }
  return (
    <Table active={active}>
      <Header
        api={api}
        widget={widget}
        onLink={api.updateCellLink}
        onUpdate={api.updateCellValue}
      />
      <Table.Body>
        {widget.rows.map((row, rowIdx) => (
          <Fragment key={row.ID}>
            {/* {rowIdx === widget.rows.length - 1 ? (
              <NewRow
                widget={widget}
                show={showNewRow}
                columns={columns}
                onAdd={(from, toIdx) =>
                  dispatch({
                    type: 'add-row',
                    after: widget.rows.length - 1,
                    from,
                    toIdx,
                  })
                }
              />
            ) : null} */}
            <Table.Row ord={rowIdx}>
              <RowControl
                api={api}
                widget={widget}
                rowIdx={rowIdx}
                setAppearTrigger={setAppearTrigger}
              />
              <RangeLabel
                widget={widget}
                appearTrigger={appearTrigger}
                range={row}
                select={select}
                onUpdate={api.updateRow}
              />
              {row.cells.map((cell) => {
                const isOutput = rowIdx === widget.rows.length - 1;
                return (
                  <Cell
                    key={cell.ID}
                    cell={cell}
                    isInput={!isOutput}
                    isOutput={isOutput}
                    isEditable={!isOutput}
                    isSource={isOutput}
                    isTarget={!isOutput}
                    onLink={api.updateCellLink}
                    onUpdate={api.updateCellValue}
                  />
                );
              })}
              {widget.config.showTotals ? (
                <Cell
                  key={widget.config.totals.cells[rowIdx].ID}
                  cell={widget.config.totals.cells[rowIdx]}
                  isSeparated
                  isInput={false}
                  isOutput
                  isEditable={false}
                  isSource
                  isTarget={false}
                  onLink={api.updateCellLink}
                  onUpdate={api.updateCellValue}
                />
              ) : null}
            </Table.Row>
          </Fragment>
        ))}
      </Table.Body>
    </Table>
  );
}
