import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Dropdown, Icon, Ref } from 'semantic-ui-react';

import shortid from '<src>/utils/shortid';

import * as colors from '<components>/colors';
import Button from '<src>/components/Button';

import { ConfigTable, DropdownItemDesc, InputsWrapper } from '../../styles';
import { ConfigField } from '<src>/components/NumbrzVerticalEditor';
import ElementLabel from '../../ElementLabel';
import FieldPicker from '../../FieldPicker';
import ValuePicker from '../../ValuePicker';

const ConfigFieldInput = ConfigField.withComponent('input');

const calcHelp = {
  RunningTot: {
    text: 'Running Total',
    content: 'Output the running total of a field within the current group',
    elementText: (e) => (
      <span>
        Running total of <ElementLabel elementKey={e && e.key} element={e} />
      </span>
    ),
    defaultLabel: (e) => (e ? `Running total of ${e.label}` : ''),
  },
  NewGroup: {
    text: 'First in Group',
    content: (
      <span>
        Output <code>true</code> to the first record in each group
      </span>
    ),
    elementText: () => (
      <span>
        <code>true</code> when the record is the first in its group
      </span>
    ),
    defaultLabel: () => 'First in group',
  },
  Previous: {
    text: 'Previous Value',
    content: 'Output the previous value of a field',
    elementText: (e) => (
      <span>
        Previous value of <ElementLabel elementKey={e && e.key} element={e} />
      </span>
    ),
    defaultLabel: (e) => (e ? `Previous value of ${e.label}` : ''),
  },
  ChangePrev: {
    text: 'Delta',
    content:
      'Output the difference between the current and previous values of a field',
    elementText: (e) => (
      <span>
        Difference between the current and previous values of{' '}
        <ElementLabel elementKey={e && e.key} element={e} />
      </span>
    ),
    defaultLabel: (e) => (e ? `Delta of ${e.label}` : ''),
  },
  PIncrement: {
    text: 'Proportional Increase',
    content:
      'Within each group, value is calculated by multiplying the previous value by the amount specified, then adding the result to the previous value.',
    elementText: (e, field, elements) => {
      let PILbl = field.param1;
      let PIEl;
      if (field.param1 && field.param1.includes('#$REF$#_')) {
        PIEl = elements.find(
          (e) => e.key === field.param1.replace('#$REF$#_', '')
        );
        if (PIEl) PILbl = PIEl.label;
      }

      return (
        <span>
          {`Sum of the previous `}
          <ElementLabel elementKey={e && e.key} element={e} />
          {` and the product of the previous `}
          <ElementLabel elementKey={e && e.key} element={e} />
          {` and  `}
          <b>
            {PIEl ? (
              <ElementLabel elementKey={PIEl && PIEl.key} element={PIEl} />
            ) : (
              PILbl
            )}
          </b>
        </span>
      );
    },
    defaultLabel: (e) => (e ? `Proportional increase of ${e.label}` : ''),
  },
};

const calcOptions = Object.entries(calcHelp).map(
  ([key, { content, text }]) => ({
    key,
    value: key,
    text,
    content: (
      <div>
        <div>{text}</div>
        <DropdownItemDesc>{content}</DropdownItemDesc>
      </div>
    ),
  })
);

function isValid({ label, extType, fromElementKey, PIAmt }) {
  if (!label) return false;
  if (!extType) return false;
  if (extType !== 'NewGroup' && !fromElementKey) return false;
  if (extType === 'PIncrement') {
    if (!PIAmt) return false;
    if (
      PIAmt &&
      !PIAmt.includes('#$REF$#') &&
      isNaN(PIAmt) &&
      isNaN(parseFloat(PIAmt))
    )
      return false;
  }

  return true;
}

function sourceTypes(extType) {
  switch (extType) {
    case 'RunningTot':
    case 'PreviousChange':
    case 'PIncrement':
      return ['Number'];

    default:
      return undefined;
  }
}

function destType(extType, element) {
  switch (extType) {
    case 'NewGroup':
      return 'Boolean';
    case 'PreviousChange':
    case 'PIncrement':
      return 'Number';

    default:
      return element.type;
  }
}

function AddCalc({ elements, fields, onAdd: onAddProp }) {
  const [label, setLabel] = useState('');
  const labelRef = useRef();
  const [extType, setExtType] = useState();
  const extTypeRef = useRef();
  const [fromElementKey, setFromElementKey] = useState();
  const fromEltRef = useRef();
  const piEltRef = useRef();

  const [PIAmt, setPIAmt] = useState();

  const onAdd = useCallback(
    (e, { label, extType, fromElementKey, param1 }) => {
      if (isValid({ label, extType, fromElementKey, PIAmt: param1 })) {
        onAddProp(e, {
          extType,
          fromElementKey,
          toElement: {
            key: shortid(),
            label,
            type: destType(
              extType,
              elements.find((e) => e.key === fromElementKey)
            ),
          },
          param1,
        });
        setLabel('');
        setExtType(null);
        setFromElementKey(null);
        labelRef.current.focus();
      } else if (!label) {
        labelRef.current.focus();
      } else if (!extType) {
        extTypeRef.current.focus();
      } else if (extType !== 'NewGroup' && !fromElementKey) {
        fromEltRef.current.focus();
      }
    },
    [elements, onAddProp]
  );

  const newLabel = useCallback(
    ({ extType: newType, fromElementKey: newFrom, PIAmt }) => {
      const elt = elements.find((e) => e.key === fromElementKey);
      const newElt = elements.find((e) => e.key === newFrom);
      if (
        !label ||
        (extType && label === calcHelp[extType].defaultLabel(elt, PIAmt))
      ) {
        if (newType) {
          return calcHelp[newType].defaultLabel(newElt);
        }
        return '';
      }
      return label;
    },
    [elements, extType, fromElementKey, label]
  );

  const options = useMemo(
    () =>
      fields.findIndex((f) => f.extType === 'NewGroup') !== -1
        ? calcOptions.filter((o) => o.value !== 'NewGroup')
        : calcOptions,
    [fields]
  );

  return (
    <tr>
      <td>
        <ConfigFieldInput
          ref={labelRef}
          value={label}
          placeholder="New Field"
          onFocus={(e) => {
            e.target.placeholder = '';
          }}
          onBlur={(e) => {
            e.target.placeholder = 'New Field';
          }}
          onChange={(e) => setLabel(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onAdd(e, {
                label,
                extType,
                fromElementKey,
                ...(PIAmt && { param1: PIAmt }),
              });
            }
          }}
        />
      </td>
      <td colSpan={!extType || extType === 'NewGroup' ? 2 : 1}>
        <Ref innerRef={extTypeRef}>
          <Dropdown
            placeholder="Select Calculation"
            selectOnBlur={false}
            selectOnNavigation={false}
            options={options}
            value={extType}
            onChange={(e, { value: extType }) => {
              const label = newLabel({ extType, fromElementKey: null });
              setLabel(label);
              setExtType(extType);
              setFromElementKey(null);
            }}
          />
        </Ref>
      </td>
      {extType && extType !== 'NewGroup' ? (
        <td>
          <InputsWrapper>
            <FieldPicker.Dropdown
              ref={fromEltRef}
              elements={elements}
              selected={fromElementKey}
              placeholder="Select Field"
              restrictTypes={sourceTypes(extType)}
              onChange={(e, { selected: fromElementKey }) => {
                const label = newLabel({ extType, fromElementKey });
                setLabel(label);
                setFromElementKey(fromElementKey);
                if (e.type === 'keydown' && e.key === 'Enter') {
                  onAdd(e, {
                    label,
                    extType,
                    fromElementKey,
                    ...(PIAmt && { param1: PIAmt }),
                  });
                }
              }}
            />
            {extType && extType === 'PIncrement' ? (
              <ValuePicker
                ref={piEltRef}
                elements={elements}
                value={PIAmt}
                placeholder="Amount"
                restrictTypes={sourceTypes(extType)}
                onChange={(e, { value: input }) => {
                  setPIAmt(input);
                  if (e.type === 'keydown' && e.key === 'Enter') {
                    onAdd(e, {
                      label,
                      extType,
                      fromElementKey,
                      ...(PIAmt && { param1: PIAmt }),
                    });
                  }
                }}
              />
            ) : null}
          </InputsWrapper>
        </td>
      ) : null}

      <td className="btn">
        <Button.IconBtn
          icon={<Icon name="plus" title="Add Calculated Field" />}
          disabled={!isValid({ label, extType, fromElementKey, PIAmt })}
          onClick={(e) =>
            onAdd(e, {
              label,
              extType,
              fromElementKey,
              ...(PIAmt && { param1: PIAmt }),
            })
          }
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onAdd(e, {
                label,
                extType,
                fromElementKey,
                ...(PIAmt && { param1: PIAmt }),
              });
            }
          }}
          baseColor="transparent"
          contentColor={colors.blue2}
          activeColor="transparent"
          contrastColor="transparent"
          bgHoverColor={colors.blue5}
        />
      </td>
    </tr>
  );
}

export default function CalculatedFields({
  elements,
  fields,
  onAdd,
  onRemove,
  onChangeFieldLabel,
}) {
  return (
    <ConfigTable>
      <thead>
        <tr>
          <th>Name</th>
          <th colSpan={2}>Calculation</th>
          {onRemove ? <th className="btn" /> : null}
        </tr>
      </thead>
      <tbody>
        {fields.map((field) => (
          <tr key={field.toElement.key}>
            <td>
              <ConfigField
                singleClick
                value={field.toElement.label}
                disabled={!onChangeFieldLabel}
                onSubmit={(e) =>
                  onChangeFieldLabel(e, {
                    key: field.toElement.key,
                    label: e.value,
                  })
                }
              />
            </td>
            <td colSpan={2}>
              {calcHelp[field.extType].elementText(
                elements.find((e) => e.key === field.fromElementKey),
                field.param1 && field,
                field.param1 && elements
              )}
            </td>
            {onRemove ? (
              <td className="btn">
                <Button.IconBtn
                  icon={<Icon name="trash alternate outline" />}
                  onClick={(e) => onRemove(e, field)}
                  baseColor="transparent"
                  contentColor={colors.gray2}
                  activeColor="transparent"
                  contrastColor="transparent"
                  bgHoverColor={colors.gray4}
                />
              </td>
            ) : null}
          </tr>
        ))}
        {onAdd ? (
          <AddCalc elements={elements} fields={fields} onAdd={onAdd} />
        ) : null}
      </tbody>
    </ConfigTable>
  );
}
