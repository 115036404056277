import React, { Fragment } from 'react';

import { sortBy } from '<src>/utils/text';

const steps = [
  {
    title: `Aggregate`,
    doc: (
      <div>
        Records are grouped into "buckets" according to the selected "Group By"
        fields. For each bucket, fields are summarized using the calculations
        you specify.
      </div>
    ),
    typename: `AggregateStepV2`,
  },
  {
    title: `Combine Data`,
    doc: (
      <div>
        Each record received by this step is combined with each record in the
        dataset you specify. An optional set of rules is applied to each
        resulting record. Matching records are sent to the next step.
      </div>
    ),
    typename: `CombineStepV2`,
  },
  {
    title: `Execute Function`,
    doc: (
      <div>
        Each record is passed to the selected function according to the input
        mappings you specify. The function's outputs are available to the next
        steps.
      </div>
    ),
    typename: `ExecStepV2`,
  },
  {
    title: `Fill`,
    doc: (
      <div>
        Each record received by this step is combined with a generated sequence
        of dates or numbers that you specify.
      </div>
    ),
    typename: `FillStepV2`,
  },
  {
    title: `Filter`,
    doc: (
      <div>
        Specify a set of rules that are applied to each record. Matching records
        are sent to the next steps.
      </div>
    ),
    typename: `FilterStepV2`,
  },
  {
    title: `Group And Sort`,
    doc: (
      <div>
        Records are grouped and sorted by fields you specify. For each grouping,
        an optional set of additional fields may be calculated, for example, a
        running total.
      </div>
    ),
    typename: `GroupAndSortStepV2`,
  },
  {
    title: `Output`,
    doc: (
      <Fragment>
        <div>
          Each record is written to the according to the output mappings you
          specify. All records are passed unmodified to the next steps.
        </div>
      </Fragment>
    ),
    typename: `OutputStepV2`,
  },
  {
    title: `Transpose`,
    doc: (
      <div>
        For each input record, multiple output records are generated, containing
        additional fields you define. The values for these new fields are taken
        from existing fields in the input record using mappings you specify.
      </div>
    ),
    typename: `SliceStepV2`,
  },
];

sortBy('title', steps);

const stepsByType = steps.reduce(
  (acc, step) => ({ ...acc, [step.typename]: step }),
  {}
);

export default steps;

export { stepsByType };
