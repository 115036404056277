import React, { useState, Fragment, useRef } from 'react';
import fp from 'lodash/fp';
import { InvoiceContentSeparator, InvoiceAmtContainer } from '../../styles';
import { useElements, useStripe, CardElement } from '@stripe/react-stripe-js';
import {
  Form,
  FormGroup,
  InputGroup,
  StyledSubmitButton,
  ErrorMessage,
  InvoiceProcessingStatus,
  PaymentDropdownContainer,
  InvoiceContainer,
  AlternatePaymentSettings,
  SavingsLbl,
} from '../styles';
import { Loader, Dropdown, Input, Checkbox } from 'semantic-ui-react';
import CardField from '../CardField';
import * as colors from '<components>/colors';

const AlternateIntervalPaymentSettings = ({
  propPrice,
  alternatePrice,
  onChange,
}) => {
  let label;
  if (alternatePrice && alternatePrice.recurring.interval === 'year')
    label = (
      <div>
        Save with annual billing <SavingsLbl>20% off</SavingsLbl>
      </div>
    );
  if (alternatePrice && alternatePrice.recurring.interval === 'month')
    label = <div>Switch to monthly billing</div>;

  return (
    <AlternatePaymentSettings>
      <Checkbox
        toggle
        onChange={(e, data) =>
          onChange(data.checked ? alternatePrice : propPrice)
        }
      />
      {label}
    </AlternatePaymentSettings>
  );
};

const PaymentErrorMessage = ({ children }) => (
  <ErrorMessage role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill={colors.red1c}
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#fff"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </ErrorMessage>
);

const recurringLabel = (interval) => {
  let label = undefined;
  if (interval) {
    if (interval === 'month') label = 'Billed monthly';
    if (interval === 'year') label = 'Billed annually';
  }

  return label;
};

const SubmitButton = ({ processing, error, children, disabled }) => (
  <StyledSubmitButton
    className={`SubmitButton ${error ? 'SubmitButton--error' : ''}`}
    type="submit"
    disabled={processing || disabled}
  >
    {processing ? 'Processing...' : children}
  </StyledSubmitButton>
);

const PlanChangeMessage = (action) => {
  switch (action) {
    case 'create':
      return 'This action creates a new team and a new paid plan subscription.';
    case 'trial':
      return 'This action creates a new team and starts your free 30 day trial.';
    case 'upgrade':
      return 'You are about to upgrade your current plan. Plan upgrades are effective immediately. Current plan fees are pro-rated for the remainder of your current subscription period. New plan fees are charged at the time of the upgrade.';
    case 'downgrade':
      return 'You are about to downgrade your plan. You current plan will stay active until the end of the billing cycle.';
    default:
      return null;
  }
};

const InvoiceForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentMethod = null, setPaymentMethod] = useState();
  const [price, setPrice] = useState(props.price);

  const [
    billingDetails = {
      email: props.userEmail,
      name: props.display || props.userFullName || '',
      teamName: '',
    },
    setBillingDetails,
  ] = useState();
  const [invoiceProcessingStatus, setInvoiceProcessingStatus] = useState({
    status: null,
    message: '',
  });
  const usdPrice = price ? price.unit_amount / 100 : 0;
  const paymentMethodOptions = props.cardPaymentMethods.map((pm) => ({
    key: pm.id,
    value: pm.id,
    text: `Card ending in ${fp.getOr('Not Found', 'card.last4', pm)} ${
      pm.id === props.defaultPaymentMethod ? '(Default)' : ''
    }`,
  }));

  const customerID = useRef(props.stripeCustomer);
  const subscriptionSecret = useRef(null);
  const teamOrg = useRef(
    props.primaryAccount.__typename === 'Org' ? { ...props.primaryAccount } : {}
  );

  const includeFreeTrial = props.plan?.metadata?.freeTrial === 'true';

  const handleSubmit = async (event) => {
    event.preventDefault();
    const cardElement = elements.getElement(CardElement);
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (paymentMethod || cardComplete) {
      setProcessing(true);
    }

    // Numbrz user exists
    if (props.userAccountID) {
      setInvoiceProcessingStatus({
        status: 'running',
        message: 'Setting up subscription details...',
      });

      // CREATE NEW ORG FLOW
      // Personal -> Team Plan
      if (props.createNewOrg) {
        setInvoiceProcessingStatus({
          status: 'running',
          message: 'Creating Team...',
        });

        const orgRes = await props.createOrgAndAddMember({
          variables: {
            input: {
              display: billingDetails.teamName,
              memberID: props.userAccountID,
            },
          },
        });
        const orgID = fp.getOr(undefined, 'data.createOrgAndAddMember', orgRes);
        teamOrg.current.ID = orgID;

        // create a stripe customer for the new team
        const orgStripeCustomer = await props.createStripeCustomer({
          variables: { ID: orgID, email: billingDetails.email },
        });

        teamOrg.current.stripeCustomerID = fp.getOr(
          undefined,
          'data.createStripeCustomer.id',
          orgStripeCustomer
        );
      } else {
        // PERSONAL PLAN CREATION FLOW
        if (!customerID.current) {
          const customer = await props.createStripeCustomer({
            variables: { ID: null, email: billingDetails.email },
          });
          customerID.current = fp.getOr(
            undefined,
            'data.createStripeCustomer.id',
            customer
          );
        }
      }

      //  Create Stripe Subscription
      if (
        customerID.current ||
        (teamOrg.current && teamOrg.current.stripeCustomerID)
      ) {
        await props.setAccountToStripeProduct({
          variables: {
            input: {
              id: props.isOrgSetup ? teamOrg.current.ID : props.userAccountID,
              stripeCustomerID: props.createNewOrg
                ? teamOrg.current.stripeCustomerID
                : customerID.current,
              stripeProductID: props.plan.id,
            },
          },
        });

        if (!subscriptionSecret.current) {
          const newSubscription = await props.createStripeSubscription({
            variables: {
              input: {
                customerID: props.createNewOrg
                  ? teamOrg.current.stripeCustomerID
                  : customerID.current,
                priceID: price.id,
                planID: props.plan.id,
              },
            },
          });
          subscriptionSecret.current = fp.getOr(
            undefined,
            'data.createCustomerSubscription.clientSecret',
            newSubscription
          );
        }
        if (!includeFreeTrial) {
          // step 4 - assign stripe Product to an org account
          setInvoiceProcessingStatus({
            status: 'running',
            message: 'Processing payment information...',
          });
          // step 5 - process payment for subscription

          const paymentDetails = paymentMethod
            ? paymentMethod
            : {
                card: cardElement,
                billing_details: {
                  name: billingDetails.name,
                  phone: billingDetails.phone,
                  email: billingDetails.email,
                },
              };

          // make sure failures are covered
          await stripe.confirmCardPayment(subscriptionSecret.current, {
            payment_method: paymentDetails,
          });
          setInvoiceProcessingStatus({
            status: 'complete',
            message:
              'Success! Your subscription is now active. Please wait for page to refresh...',
          });
        }

        props.onPlanChangeNavigate();
      }
    }
  };

  const handleUpgrade = async (event) => {
    event.preventDefault();
    setInvoiceProcessingStatus({
      status: 'running',
      message: 'Updating subscription, please wait for page to refresh...',
    });
    const paymentMethodID =
      paymentMethod && paymentMethod !== props.defaultPaymentMethod
        ? paymentMethod
        : null;
    props.onPlanChange(props.plan.id, price, paymentMethodID);
  };

  const isSubmitBtnDisabled = () => {
    if (
      includeFreeTrial &&
      billingDetails.teamName &&
      invoiceProcessingStatus.status === null
    )
      return false;

    if (!stripe) return true;
    if (invoiceProcessingStatus.status !== null) return true;
    if (props.createNewOrg && !billingDetails.teamName) return true;
    if (!cardComplete) {
      if (!paymentMethod && !props.defaultPaymentMethod) return true;
    }

    return false;
  };

  return (
    <InvoiceContainer>
      <h2>{props.plan.name}</h2>

      {(props.subscriptionUpdateMethod || props.createNewOrg) && (
        <p>
          {PlanChangeMessage(
            includeFreeTrial
              ? 'trial'
              : props.createNewOrg
                ? 'create'
                : props.subscriptionUpdateMethod
          )}
        </p>
      )}

      <InvoiceContentSeparator />
      <Fragment>
        {!error && invoiceProcessingStatus.status && (
          <InvoiceProcessingStatus>
            <div>
              <Loader active inline="centered" size="tiny" />
              <span>{invoiceProcessingStatus.message}</span>
            </div>
          </InvoiceProcessingStatus>
        )}
        {
          // only show error on issues with card input
          error && !paymentMethod && (
            <PaymentErrorMessage>{error.message}</PaymentErrorMessage>
          )
        }
        <Form onSubmit={props.needNewInvoice ? handleSubmit : handleUpgrade}>
          {!(invoiceProcessingStatus.status === 'complete') && (
            <Fragment>
              {props.needNewInvoice && (
                <InputGroup>
                  {(props.isOrgSetup || props.createNewOrg) && (
                    <Input
                      label="Team Name"
                      id="teamName"
                      placeholder=""
                      required
                      autoComplete="teamName"
                      disabled={!props.createNewOrg}
                      value={
                        props.createNewOrg
                          ? billingDetails.teamName
                          : fp.getOr(undefined, 'display', teamOrg.current)
                      }
                      onChange={(e, data) =>
                        setBillingDetails({
                          ...billingDetails,
                          teamName: data.value,
                        })
                      }
                    />
                  )}
                  {!includeFreeTrial && (
                    <Input
                      label="Name on card"
                      id="name"
                      required
                      autoComplete="name"
                      value={billingDetails.name}
                      onChange={(e, data) =>
                        setBillingDetails({
                          ...billingDetails,
                          name: data.value,
                        })
                      }
                    />
                  )}

                  <Input
                    label="Email"
                    id="email"
                    type="email"
                    required
                    autoComplete="email"
                    value={billingDetails.email}
                    onChange={(e, data) =>
                      setBillingDetails({
                        ...billingDetails,
                        email: data.value,
                      })
                    }
                  />
                </InputGroup>
              )}

              {!includeFreeTrial && !paymentMethod && props.needNewInvoice && (
                <FormGroup>
                  <CardField
                    onChange={(e) => {
                      setError(e.error);
                      setCardComplete(e.complete);
                    }}
                  />
                </FormGroup>
              )}

              {!includeFreeTrial &&
                props.cardPaymentMethods.length > 0 &&
                !props.createNewOrg && (
                  <PaymentDropdownContainer>
                    {props.needNewInvoice && (
                      <p>Or Use An Existing Payment Method</p>
                    )}

                    <Dropdown
                      options={paymentMethodOptions}
                      value={paymentMethod || props.defaultPaymentMethod}
                      onChange={(e, data) => setPaymentMethod(data.value)}
                      placeholder="Select Payment Method"
                    />
                  </PaymentDropdownContainer>
                )}
              <InvoiceContentSeparator />
              {includeFreeTrial && (
                <InvoiceAmtContainer>
                  <h3>Total: Free for 30 days</h3>
                  <span>
                    To retain Launch Promo pricing and benefits, enter credit
                    card info within 30 days to prevent being downgraded.
                  </span>
                </InvoiceAmtContainer>
              )}
              {!includeFreeTrial && (
                <InvoiceAmtContainer>
                  <h3>{`Total: $${usdPrice.toFixed(2)}`}</h3>
                  <span>
                    {recurringLabel(
                      fp.getOr(undefined, 'recurring.interval', price)
                    )}
                  </span>
                  <AlternateIntervalPaymentSettings
                    propPrice={props.price}
                    alternatePrice={props.alternateIntervalPrice}
                    onChange={(newPrice) => setPrice(newPrice)}
                  />
                </InvoiceAmtContainer>
              )}

              <SubmitButton
                processing={processing}
                error={error}
                disabled={isSubmitBtnDisabled()}
              >
                {includeFreeTrial
                  ? 'Start trial'
                  : props.needNewInvoice
                    ? 'Pay'
                    : 'Change Current Plan'}
              </SubmitButton>
            </Fragment>
          )}
        </Form>
      </Fragment>
    </InvoiceContainer>
  );
};

export default InvoiceForm;
