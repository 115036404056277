import * as Sentry from '@sentry/react';
import { ApolloError } from '@apollo/client';

import shortid from '<utils>/shortid';

export const sessionID = shortid();

function isReportable(error) {
  // For now, all errors that make it to the UI are reportable, but any
  // centralized logic we want to add for handling individual errors should
  // go here.
  return true;
}

export function isDisplayable(error) {
  // All uncaught errors are displayed by the ErrorBoundary. However, if
  // an error is reported via a graphql error callback, we may decide not
  // to display it. This allows us to report (to Sentry) or display errors
  // (or both).
  // For now, all errors are displayable.
  return true;
}

// Report an error to sentry, if appropriate
export function reportError(error, { info, displayed } = {}) {
  let component;
  if (info && info.componentStack) {
    component = info.componentStack.split('\n')[1];
    component = component && component.replace(/^\s*in\s*/, '');
  }

  let type = 'Error';
  let detail;
  if (error instanceof ApolloError) {
    if (error.networkError) {
      type = 'GQLNetworkError';
      detail = error.networkError.result && error.networkError.result.errors;
    } else {
      type = 'GQLError';
      detail = error.graphQLErrors;
    }
  } else if (info) {
    const { componentStack, ...rest } = info;
    detail = rest;
  }
  const code = error.extensions?.grpcCode
    ? error.extensions.grpcCode
    : error.code;
  const name = error.extensions?.grpcCodeName
    ? error.extensions.grpcCodeName
    : error.name;

  const tags = {
    type,
    code,
    codeName: name,
    displayed,
    component,
  };
  const contexts = { detail };

  let sentryEvent;
  if (isReportable(error) && !error.reported) {
    sentryEvent = Sentry.captureException(error, {
      tags,
      contexts,
    });
    error.reported = true;
  }

  if (__DEV__ || window.logErrorsToConsole) {
    // eslint-disable-next-line no-console
    console.error('Error:', error, '\ntags:', tags, '\ncontexts', contexts);
  }

  if (displayed) {
    window.Intercom('trackEvent', 'error', {
      url: window.location.href,
      component,
      sentryEvent,
      error_code: code,
      error_name: name,
      error_message: error.message,
    });
  }
}
