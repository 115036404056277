const locale = Intl.NumberFormat().resolvedOptions().locale;

export const compare = (v1, v2) =>
  v1.localeCompare(v2, locale, {
    sensitivity: 'base',
    ignorePunctuation: true,
  });

export const strictCompare = (v1, v2) => v1.localeCompare(v2);

const getField = (field) =>
  typeof field === 'function' ? field : (v) => v[field];

export const compareBy = (field) => (v1, v2) =>
  compare(getField(field)(v1), getField(field)(v2));

export const strictCompareBy = (field) => (v1, v2) =>
  strictCompare(getField(field)(v1), getField(field)(v2));

export const sortBy = (field, arr) => {
  arr.sort(compareBy(field));
};

export const isEmpty = (str) => !str || !str.trim || !str.trim();

export const normalizeLabel = (str = '') =>
  str
    .toLowerCase()
    .replace(
      /(~|\s|`|!|@|#|\$|%|\^|&|\*|\(|\)|{|}|\[|\]|;|:|"|'|<|,|\.|>|\?|\/|\\|\||-|_|\+|=)/g,
      ''
    );

export const urlizeLabel = (str = '') =>
  str
    .toLowerCase()
    .replace(/(\s|-)/g, '-')
    .replace(
      /(~|`|!|@|#|\$|%|\^|&|\*|\(|\)|{|}|\[|\]|;|:|"|'|<|,|\.|>|\?|\/|\\|\||_|\+|=)/g,
      ''
    );
