import React from 'react';
import { PageContent } from '<components>/NumbrzPageComponents';

import Description from '<src>/components/Description';
import AuthPane from '../AuthPane';
import { formatErrorMessage } from '../../../utils';
import ErrorMessages from '<src>/components/ErrorMessages';

export default function TablesetOverview(props) {
  const {
    tablesetSource,
    tableset,
    handleFieldChange,
    credentials = [],
    extTableErr,
  } = props;

  const extErr =
    extTableErr && extTableErr.code
      ? formatErrorMessage(extTableErr)
      : undefined;
  const isNumbrzDB = tablesetSource.type === 'nbz';

  const { comment } = tableset;

  return (
    <PageContent>
      {!isNumbrzDB && extTableErr && (
        <ErrorMessages
          issues={[{ message: extErr, code: 'some code' }]}
          message="Errors"
          noShadow={false}
          margin="0 0 30px 0"
        />
      )}
      <Description
        editable={true}
        content={comment}
        onChange={(comment) => handleFieldChange('comment', comment)}
        rows={4}
        placeholder="No description provided"
      />
      {!isNumbrzDB && (
        <AuthPane
          credentials={credentials}
          tableset={tableset}
          onTablesetChange={handleFieldChange}
        />
      )}
    </PageContent>
  );
}
