import React from 'react';

import { Switch, Route, useRouteMatch } from 'react-router-dom';

import TablesetList from './pages/TablesetList';
import TablesetPage from './pages/TablesetPage';
import QBDisconnectedPage from './pages/QBDisconnectedPage';

export default function DataSection() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/qb-disconnected`}>
        <QBDisconnectedPage />
      </Route>
      <Route path={`${match.url}/:tablesetID`}>
        <TablesetPage />
      </Route>
      <Route>
        <TablesetList />
      </Route>
    </Switch>
  );
}
