import React, { Fragment } from 'react';
import gql from 'graphql-tag';
import styled from '@emotion/styled';

import { useQuery } from '<src>/apollo/client';

import WaveSpinner from '<components>/WaveSpinner';
import * as colors from '<components>/colors';

const QuotasContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const QuotaPolicyWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid ${colors.gray3};
  border-radius: 4px;
`;

const GetAccountProjects = gql`
  query GetAccountProjects($accountID: CommonID!) {
    projects(filter: { ownerID: $accountID }) {
      ID
      name
    }
  }
`;

export default function AccountModels({ accountID }) {
  const { data: { projects } = {}, loading } = useQuery(GetAccountProjects, {
    variables: { accountID },
  });

  return loading ? (
    <WaveSpinner />
  ) : (
    <Fragment>
      <QuotasContainer>
        <h4>Account Models</h4>

        {projects.map((project, idx) => {
          return (
            <QuotaPolicyWrapper key={idx}>
              <h5>{project.name}</h5>
            </QuotaPolicyWrapper>
          );
        })}
      </QuotasContainer>
    </Fragment>
  );
}
