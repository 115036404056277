import styled from '@emotion/styled';
import * as colors from '<components>/colors';
import Dialog from '<components>/Dialog';

export const SectionHdr = styled('h5')`
  margin-bottom: 5px;
  margin-top: 10px;
  font-size: 12px;
  padding-left: 5px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const StyledDialog = styled(Dialog)`
  min-width: 600px;
  min-height: 50%;
`;

export const StyledBody = styled(Dialog.Body)`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  padding: 20px 0 15px 0;
`;

export const ButtonBar = styled(Dialog.Footer)`
  & > button {
    margin-left: 0;
    margin-right: 10px;
  }
  & > button:last-child {
    margin-right: 0;
  }
  display: flex;
`;

export const EmptySelection = styled('div')`
  color: ${colors.gray2};
  font-size: 13px;
  font-family: Lato, 'Helvetica';
  width: 100%;
  text-align: center;
`;
