/** @jsx jsx */
/** @jsxRuntime classic */

import { Fragment, useCallback, useMemo, useState } from 'react';

import { jsx } from '@emotion/react';

import {
  VerticalLine,
  InProgressMsg,
  BlueButton,
} from '<src>/components/NumbrzVerticalEditor';
import Button from '<components>/Button';
import AddBlockDialog from '../components/AddBlockDialog';
import ValidationIssues from '<sections>/flows/ValidationIssues';
import { HashScroll } from 'react-hash-scroll';
import Block from '../components/Block';
import AddJobDialog from '../components/AddJobDialog';
import { Message } from 'semantic-ui-react';
import { FlexColumnContainer } from '<components>/NumbrzPageComponents';

function AddBlock({ beforeBlockKey, onAddBlock, disabled }) {
  const onClick = useMemo(
    () => (onAddBlock ? (e) => onAddBlock(e, { beforeBlockKey }) : null),
    [beforeBlockKey, onAddBlock]
  );
  return onClick && !disabled ? (
    <VerticalLine top={beforeBlockKey ? false : true}>
      <Button.AddStepBtn onClick={onClick} />
    </VerticalLine>
  ) : null;
}

function JobGroupValidationIssues({ issues }) {
  return (
    <ValidationIssues
      issues={issues}
      header="This stage has configuration errors"
    />
  );
}

export default function JobGroupEditor({
  jobGroupState = {},
  baseURL,
  isRunning,
  isCancelling,
  loading,
  history,
  runID,
}) {
  const { block: blockAPI = {} } = jobGroupState.api;
  const { jobGroup = {}, projectNames } = jobGroupState;
  const blocks = jobGroup.blocks || [];

  const [activeBlock, setActiveBlock] = useState();
  const [addBlockAt, setAddBlockAt] = useState(null);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [addJobDialogVisible, setAddJobDialogVisible] = useState(false);

  const [expandedBlocks, setExpandedBlocks] = useState(
    blocks.reduce((acc, block) => ({ ...acc, [block.key]: false }), {})
  );

  const closeDialog = useCallback(() => {
    setAddBlockAt(null);
    setDialogVisible(false);
  }, []);

  const onExpand = useCallback(
    (exp, key) =>
      setExpandedBlocks((expBlocks) => ({ ...expBlocks, [key]: exp })),
    []
  );

  const onAddClicked = useMemo(
    () =>
      blockAPI.onAdd
        ? (e, { beforeBlockKey }) => {
            setAddBlockAt(beforeBlockKey);
            setDialogVisible(true);
          }
        : null,
    [blockAPI.onAdd]
  );

  const controlsDisabled = isRunning || isCancelling;

  return (
    <FlexColumnContainer justifyContent="center" maxWidth="900px" width="100%">
      {controlsDisabled && (
        <InProgressMsg>
          <Message.Header>Job Group Active</Message.Header>

          <p>
            This job group is in progress and the editing controls are disabled.
          </p>
          <BlueButton
            onClick={() => history.push(`${baseURL}/status/${runID.current}`)}
          >
            STATUS
          </BlueButton>
        </InProgressMsg>
      )}

      <JobGroupValidationIssues issues={jobGroup.issues} />

      {blocks.length === 0 && !loading ? (
        <Button
          style={{ margin: '10px auto' }}
          disabled={controlsDisabled}
          onClick={(e) => onAddClicked(e, { beforeBlockKey: null })}
        >
          Add Block
        </Button>
      ) : (
        <AddBlock
          disabled={controlsDisabled}
          beforeBlockKey={null}
          onAddBlock={onAddClicked}
        />
      )}

      {blocks.map((block, idx) => {
        return (
          <Fragment key={block.key}>
            <HashScroll hash={block.key} position="start">
              <Block
                blockIdx={idx + 1}
                api={blockAPI}
                baseURL={baseURL}
                expanded={expandedBlocks[block.key]}
                first={idx === 0}
                last={idx === blocks.length - 1}
                jobGroupID={jobGroup.ID}
                block={block}
                onExpand={onExpand}
                setActiveBlock={() => {
                  setActiveBlock(block);
                  setAddJobDialogVisible(true);
                }}
                projectNames={projectNames}
                isRunning={isRunning}
                disabled={controlsDisabled}
                viewMode="editor"
              />
            </HashScroll>

            {!isRunning && (
              <AddBlock
                disabled={controlsDisabled}
                beforeBlockKey={block.key}
                onAddBlock={onAddClicked}
              />
            )}
          </Fragment>
        );
      })}

      {blockAPI.onAdd ? (
        <AddBlockDialog
          beforeBlockKey={addBlockAt}
          onRequestClose={closeDialog}
          onAddBlock={(e, data) => {
            blockAPI.onAdd(e, { jobGroupID: jobGroup.ID, ...data });
            closeDialog();
          }}
          visible={dialogVisible}
        />
      ) : null}
      {addJobDialogVisible && (
        <AddJobDialog
          onClose={() => setAddJobDialogVisible(false)}
          visible={addJobDialogVisible}
          jobGroupID={jobGroup.ID}
          block={activeBlock}
          updateBlock={blockAPI.onUpdate}
        />
      )}
    </FlexColumnContainer>
  );
}
