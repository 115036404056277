import React, { useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { DropdownLabel, TestDropdownWrapper } from './styles';
import Link from '<components>/Link';

function TestDropdown({ tests = [], activeTest, onSelectTest, baseURL }) {
  const [open, setOpen] = useState(false);
  return (
    <TestDropdownWrapper>
      <DropdownLabel>Test:</DropdownLabel>
      <Dropdown
        placeholder="Select Test"
        closeOnEscape
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onClick={(e) => {
          if (e.target && e.target.href) e.target.blur();
        }}
        trigger={
          activeTest ? (
            <Link to={`${baseURL}/tests/${activeTest.key}`}>
              {activeTest.name}
            </Link>
          ) : undefined
        }
      >
        <Dropdown.Menu style={{ maxHeight: 'initial' }}>
          {tests.map((t, idx) => (
            <Dropdown.Item
              key={idx}
              active={t && activeTest && t.key === activeTest.key}
              onClick={() => {
                onSelectTest(t);
                setOpen(false);
              }}
            >
              {t.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </TestDropdownWrapper>
  );
}

export default TestDropdown;
