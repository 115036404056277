import React, { useState, useEffect, useRef, Fragment } from 'react';
import fp from 'lodash/fp';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import DocumentTitle from 'react-document-title';

import { useQuery, useMutation } from '<src>/apollo/client';

import Button from '<components>/Button';
import MainLayout from '<components>/MainLayout';
import SidebarNavMenu from '<components>/NumbrzMenus/SidebarNavMenu';
import Masthead from '<components>/Masthead';
import WaveSpinner from '<components>/WaveSpinner';
import {
  PageContainer,
  PageMasthead,
  ColumnWrapper,
  FlexRowContainer,
} from '<components>/NumbrzPageComponents';
import {
  SidebarWrapper,
  SidebarHeader,
  SidebarContent,
  FlowContainer,
} from '<components>/NumbrzVerticalEditor';
import ActionMenu from '<components>/ActionMenu';

import RunJobErrorDialog from '<sections>/jobs/components/RunJobErrorDialog';
import { RunJob } from '<sections>/jobs/queries';

import ResizeableSplitView from '<sections>/flows/flow-testing/ResizeableSplitView';
import { TitleField } from '<sections>/flows/styles';

import { GetServiceProject, UpdateServiceProject } from '../../queries';

import ProjectOverview from './ProjectOverview';
import ServiceJobs from './ServiceJobs';
import TemplateJobs from './TemplateJobs';
import DeploySettings from './DeploySettings';
import ProjectReleases from './Releases';
import ProductionJobs from './ProductionJobs';
import ProjectDeployments from './Deployments';
import useCheckReleaseTag from '<sections>/projects/components/Releases/useCheckReleaseTag';

const Title = ({ title, projectName, projectID }) => (
  <DocumentTitle title={`${projectName} - Numbrz`}>
    <Masthead
      title={title}
      breadcrumbs={[
        {
          title: 'Service Models',
          path: `/svc-models`,
          type: 'root',
        },
        {
          title: projectName,
          path: `/svc-models/${projectID}`,
        },
      ]}
      runBtnVisible={false}
      addShadow
    />
  </DocumentTitle>
);

function ProjectMasthead({
  handleInputChange,
  projectName,
  onRun,
  runBtnDisabled,
  externalResultURL,
  sidebarVisible,
  setSidebarVisible,
}) {
  const nameRef = useRef();
  const location = useLocation();

  const canEdit = !!handleInputChange;

  useEffect(() => {
    if (canEdit && location.search === '?new' && nameRef.current) {
      nameRef.current.edit(true);
    }
  }, [canEdit, location.search]);

  return (
    <PageMasthead>
      <h5>
        <FlexRowContainer alignItems="center" justifyContent="flex-start">
          {!sidebarVisible && (
            <Button.SidebarShowBtn
              onClick={() => setSidebarVisible(!sidebarVisible)}
            />
          )}
          <TitleField
            ref={nameRef}
            placeholder="Click to edit"
            singleClick
            value={projectName}
            onSubmit={(e) => handleInputChange('name', e.value)}
          />
          <ActionMenu
            menuIconDark={true}
            menuIconName="angle down"
            menuDirection="right"
            options={[
              {
                value: 'run_job',
                icon: 'play',
                text: 'Run primary job',
                onSelect: onRun,
                disabled: runBtnDisabled,
              },
              ...(externalResultURL
                ? [
                    {
                      value: 'results',
                      text: 'Open external results',
                      icon: 'chart bar outline',
                      isLink: true,
                      href: externalResultURL,
                      onSelect: () => {},
                    },
                  ]
                : []),
            ]}
          />
        </FlexRowContainer>
      </h5>
    </PageMasthead>
  );
}

export default function ServiceProjectPage() {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [runningJob, setRunningJob] = useState(false);
  const [runResult, setRunResult] = useState();

  const sectionMatch = useRouteMatch(`${match.url}/:section`);
  const curSection = sectionMatch ? sectionMatch.params.section : null;
  const [prevSection, setPrevSection] = useState(curSection);
  const [sidebarVisible, setSidebarVisible] = useState(true);

  const { data, loading, refetch } = useQuery(GetServiceProject, {
    variables: { ID: match.params.projectID },
  });

  const [releaseTagValid, onCheckReleaseTag] = useCheckReleaseTag(
    match.params.projectID
  );

  useEffect(() => {
    if (!loading && curSection && curSection !== prevSection) {
      setPrevSection(curSection);
      if (curSection && prevSection) {
        refetch();
      }
    }
  }, [curSection, loading, prevSection, refetch]);

  const [runJob] = useMutation(RunJob);
  const [updateProject] = useMutation(UpdateServiceProject);

  useEffect(() => {
    if (location.search) {
      history.replace(location.pathname);
    }
  }, [history, location]);

  if (loading && !data) {
    return <WaveSpinner />;
  }

  const { project = {} } = data;
  const externalResultURL = fp.getOr(
    null,
    'primaryJobBinding.externalResultURL',
    project
  );

  const handleInputChange = (fieldName, value) => {
    updateProject({
      variables: {
        input: {
          projectID: project.ID,
          [fieldName]: value,
        },
      },
    });
  };

  const runProject = async () => {
    setRunningJob(true);

    const res = await runJob({
      variables: { input: { jobID: project.primaryJobID } },
    });
    setRunResult(res);

    if (fp.getOr(false, 'data.runJob.success', res)) {
      const url = `/svc-models/${project.ID}/tmpl-jobs/${project.primaryJobID}/status/${res.data.runJob.runID}`;

      history.push(url);
    }
  };

  const options = [
    {
      to: `${match.url}/overview`,
      text: 'Overview',
      key: 'overview',
    },
    {
      to: `${match.url}/svc-jobs`,
      text: 'Service Jobs',
      key: 'svc-jobs',
    },
    {
      to: `${match.url}/tmpl-jobs`,
      text: 'Template Jobs',
      key: 'tmpl-jobs',
    },
    {
      to: `${match.url}/prod-jobs`,
      text: 'Production Jobs',
      key: 'prod-jobs',
    },
    {
      to: `${match.url}/data`,
      text: 'Data',
      key: 'data',
    },
    {
      to: `${match.url}/releases`,
      text: 'Releases',
      key: 'releases',
    },
    {
      to: `${match.url}/deployments`,
      text: 'Deployments',
      key: 'deployments',
    },
  ];

  const header = () => {
    const projectName = project.name ? project.name : 'Unnamed Project';

    const routeProps = {
      projectName,
      projectID: match.params.projectID,
      runBtnVisible: false,
    };

    return (
      <Switch>
        {options.map((option) => (
          <Route key={option.key} path={option.to}>
            <Title title={option.text} {...routeProps} />
          </Route>
        ))}
      </Switch>
    );
  };

  const { primaryJobBinding = {} } = project;
  const runBtnDisabled = !primaryJobBinding.runnable;

  const errorDialog = () => {
    const runFailed =
      !!runResult && !fp.getOr(false, 'data.runJob.success', runResult);
    return (
      <RunJobErrorDialog
        visible={runningJob && runFailed}
        runResult={runResult}
        onClose={() => {
          setRunningJob(false);
          setRunResult(null);
        }}
      />
    );
  };

  const body = () => {
    return (
      <PageContainer>
        <ResizeableSplitView
          left={
            <Fragment>
              <SidebarWrapper visible={sidebarVisible}>
                <SidebarHeader>
                  <span />
                  {sidebarVisible && (
                    <Button.SidebarHideBtn
                      title="Show navigation"
                      onClick={() => setSidebarVisible(!sidebarVisible)}
                    />
                  )}
                </SidebarHeader>
                <SidebarContent>
                  <SidebarNavMenu options={options} />
                </SidebarContent>
              </SidebarWrapper>
              <FlowContainer>
                <ProjectMasthead
                  handleInputChange={handleInputChange}
                  projectName={project.name}
                  onRun={runProject}
                  runBtnDisabled={runBtnDisabled || runningJob}
                  externalResultURL={externalResultURL}
                  sidebarVisible={sidebarVisible}
                  setSidebarVisible={setSidebarVisible}
                />
                <ColumnWrapper>
                  <Switch>
                    <Route path={`${match.url}/overview`}>
                      <ProjectOverview
                        project={project}
                        updateProject={updateProject}
                        runProject={runProject}
                      />
                    </Route>
                    <Route path={`${match.url}/svc-jobs`}>
                      <ServiceJobs project={project} />
                    </Route>
                    <Route path={`${match.url}/tmpl-jobs`}>
                      <TemplateJobs project={project} />
                    </Route>
                    <Route path={`${match.url}/prod-jobs`}>
                      <ProductionJobs project={project} />
                    </Route>
                    <Route path={`${match.url}/data`}>
                      <DeploySettings project={project} />
                    </Route>
                    <Route path={`${match.url}/releases`}>
                      <ProjectReleases
                        project={project}
                        releaseTagValid={releaseTagValid}
                        onCheckReleaseTag={onCheckReleaseTag}
                        updateProject={updateProject}
                      />
                    </Route>
                    <Route path={`${match.url}/deployments`}>
                      <ProjectDeployments project={project} />
                    </Route>
                    <Route>
                      <Redirect to={`${match.url}/overview`} />
                    </Route>
                  </Switch>
                </ColumnWrapper>
              </FlowContainer>
            </Fragment>
          }
          right={null}
        ></ResizeableSplitView>

        {errorDialog()}
      </PageContainer>
    );
  };

  return (
    <MainLayout
      overflowY="hidden"
      navigation={false}
      header={header()}
      main={body()}
    />
  );
}
