import React from 'react';

import builtins from '../builtins';
import useBaseWidgetAPI from '../gql/useBaseWidgetAPI';
import { WidgetAPI } from '../contexts';

import Widget from './Widget';

function BaseAPI({ children }) {
  const api = useBaseWidgetAPI();
  return <WidgetAPI.Provider value={api}>{children}</WidgetAPI.Provider>;
}

export default function WidgetOuter({ widget, ...props }) {
  const builtin = builtins[widget.config.type];
  const APIProvider = builtin.EditorAPI ? builtin.EditorAPI : BaseAPI;
  return (
    <APIProvider>
      <Widget widget={widget} {...props} func={builtin} />
    </APIProvider>
  );
}
