import React, { useEffect, useRef, useState } from 'react';
import emailAddrs from 'email-addresses';

import Dialog from '<components>/Dialog';
import Button from '<components>/Button';

import { Input, Message } from 'semantic-ui-react';
import styled from '@emotion/styled';

const StyledDialog = styled(Dialog)`
  min-width: 350px;
  input {
    width: 100%;
  }
`;

function isValidEmail(email) {
  return !!emailAddrs.parseOneAddress(email);
}

export default function InviteMemberDialog({
  inviteMember,
  memberOf,
  onClose,
  visible,
}) {
  const [email, setEmail] = useState('');
  const [processing, setProcessing] = useState(false);
  const [showError, setShowError] = useState(false);
  const emailInput = useRef();

  useEffect(() => {
    if (!visible) {
      setEmail('');
      setProcessing(false);
      setShowError(false);
    } else {
      emailInput.current.focus();
    }
  }, [visible]);

  const handleInviteMember = async () => {
    setProcessing(true);
    await inviteMember({
      variables: {
        input: {
          email,
          memberOf: memberOf,
        },
      },
    });
    onClose();
  };

  return (
    <StyledDialog onRequestClose={onClose} visible={visible ? 1 : 0}>
      <Dialog.Header>
        <Dialog.Headline>Invite Member</Dialog.Headline>
      </Dialog.Header>
      <Dialog.Body>
        {showError && (
          <Message negative>
            <p>Please enter a valid email address.</p>
          </Message>
        )}
        <Input
          ref={emailInput}
          type="text"
          label="Email"
          value={email}
          onChange={(e, data) => {
            setEmail(data.value);
            if (showError) setShowError(false);
          }}
        />
      </Dialog.Body>
      <Dialog.Footer>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={!email}
          onClick={() => {
            if (!isValidEmail(email)) {
              setShowError(true);
            } else {
              handleInviteMember();
            }
          }}
        >
          {processing ? 'Sending Invitation...' : 'Invite'}
        </Button>
      </Dialog.Footer>
    </StyledDialog>
  );
}
