import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import JobPage from '<sections>/jobs/JobPage';

import ProjectPage from './pages/ProjectPage';
import ProjectList from './pages/ProjectListPage';

export default function ServiceSection() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/:projectID/svc-jobs/:jobID`}>
        <JobPage />
      </Route>
      <Route path={`${match.url}/:projectID/tmpl-jobs/:jobID`}>
        <JobPage />
      </Route>
      <Route path={`${match.url}/:projectID/prod-jobs/:jobID`}>
        <JobPage />
      </Route>
      <Route path={`${match.url}/:projectID`}>
        <ProjectPage />
      </Route>
      <Route>
        <ProjectList baseURL={match.url} />
      </Route>
    </Switch>
  );
}
