import React, { forwardRef, useMemo } from 'react';
import styled from '@emotion/styled';

import { stepsByType } from '../stepData';
import Step from '../Step';

import FunctionPicker from './FunctionPicker';
import MappingList from './MappingList';

const Config = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > span {
    margin-right: 8px;
  }
`;

function ExecStep(
  {
    api,
    baseURL,
    flow,
    functions,
    stage,
    stageElements: elements = [],
    step,
    ...props
  },
  parentRef
) {
  const { onChangeExec, onAddFunction: onAddFuncProp } = api;

  const onChange = useMemo(
    () =>
      onChangeExec
        ? (e, changes) => onChangeExec(e, { key: step.key, ...changes })
        : null,
    [onChangeExec, step.key]
  );

  const onAddFunction = useMemo(() => {
    if (!onChange || !onAddFuncProp) {
      return null;
    }
    return async (e, func) => {
      const newFunc = await onAddFuncProp(e, func);
      await onChange(e, { functionID: newFunc.ID });
      return newFunc;
    };
  }, [onAddFuncProp, onChange]);

  const returnTo = {
    location: {
      pathname: `${baseURL}}/stages/${stage.ID}`,
      hash: `${step.key}`,
    },
  };

  return (
    <Step ref={parentRef} step={step} api={api} {...props}>
      <Config>
        <span>Function:</span>
        <FunctionPicker
          baseURL={`/models/${flow.containerID}/functions`}
          disabled={!onChange}
          functions={functions}
          returnTo={returnTo}
          value={step.functionID}
          onAdd={onAddFunction}
          onChange={(e, { value }) => onChange(e, { functionID: value })}
        />
      </Config>
      {step.function ? (
        <div>
          <h5>Inputs</h5>
          <MappingList
            elements={elements}
            func={step.function}
            inputMappings={step.inputMappings}
            onChange={onChange}
          />
        </div>
      ) : null}
    </Step>
  );
}

// eslint-disable-next-line no-func-assign
ExecStep = forwardRef(ExecStep);
ExecStep.displayName = 'ExecStep';
export default ExecStep;

// Avoid circular dependency
stepsByType.ExecStepV2.Component = ExecStep;
