import React, { Fragment } from 'react';

import SidebarNavMenu from '<components>/NumbrzMenus/SidebarNavMenu';
import ErrorChiclet from '<src>/components/ErrorChiclet';

export default function JobSidebar({
  baseURL,
  configIssueCount,
  job,
  project,
}) {
  const dataLabel =
    project && project.type !== 'Service'
      ? 'Data'
      : job.isBase
        ? 'Hosted Data'
        : 'Template Data';
  const options = [
    {
      text: 'Overview',
      key: 'overview',
      to: `${baseURL}/overview`,
    },
    {
      text: 'Variables',
      key: 'variables',
      to: `${baseURL}/variables`,
    },
    {
      text: dataLabel,
      key: 'configuration',
      to: `${baseURL}/configuration`,
      additionalContent:
        configIssueCount > 0 || job.issues.length > 0 ? (
          <ErrorChiclet>{configIssueCount || job.issues.length}</ErrorChiclet>
        ) : null,
    },
    ...(!job.isBase
      ? [
          {
            text: 'Status',
            key: 'status',
            to: `${baseURL}/status`,
          },
          {
            text: 'Recent Runs',
            key: 'recent-runs',
            to: `${baseURL}/recent-runs`,
          },
        ]
      : []),
  ];

  return (
    <Fragment>
      <SidebarNavMenu options={options} />
    </Fragment>
  );
}
