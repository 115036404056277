import React, { forwardRef, useMemo } from 'react';

import shortid from '<src>/utils/shortid';

import { stepsByType } from '../stepData';
import Step from '../Step';

import FillLimit from './FillLimit';
import SeriesList from './SeriesList';

function FillStep(
  { step, api, stageElements: elements = [], ...props },
  parentRef
) {
  const { onChangeFill } = api;
  const { fields, limit } = step;

  const onChange = useMemo(
    () =>
      onChangeFill
        ? (e, changes) => onChangeFill(e, { key: step.key, ...changes })
        : null,
    [onChangeFill, step.key]
  );

  const [onAddSeries, onRemoveSeries, onChangeSeriesLabel] = useMemo(() => {
    if (!onChange) {
      return [null, null, null];
    }

    const onAddSeries = (e, { label, ...field }) =>
      onChange(e, {
        fields: [
          ...fields,
          {
            toElement: {
              key: shortid(),
              type:
                field.__typename === 'DateSequenceV2' ? 'DateTime' : 'Number',
              label,
            },
            ...field,
          },
        ],
      });

    const onRemoveSeries = (e, field) =>
      onChange(e, {
        fields: fields.filter((f) => f.toElement.key !== field.toElement.key),
      });

    const onChangeSeriesLabel = (e, { key, label }) =>
      onChange(e, {
        fields: fields.map((field) => {
          if (field.toElement.key !== key) {
            return field;
          }
          return {
            ...field,
            toElement: {
              ...field.toElement,
              label,
            },
          };
        }),
      });

    return [onAddSeries, onRemoveSeries, onChangeSeriesLabel];
  }, [onChange, fields]);
  return (
    <Step ref={parentRef} step={step} api={api} {...props}>
      <div />
      <div>
        <h5>Series</h5>
        <SeriesList
          elements={elements}
          fields={fields}
          onAddSeries={onAddSeries}
          onRemoveSeries={onRemoveSeries}
          onChangeSeriesLabel={onChangeSeriesLabel}
        />
        {fields.length > 0 ? (
          <>
            <h5>Series Termination</h5>
            <FillLimit
              elements={elements}
              fields={fields}
              limit={limit}
              onChange={onChange}
            />
          </>
        ) : null}
      </div>
    </Step>
  );
}

// eslint-disable-next-line no-func-assign
FillStep = forwardRef(FillStep);
FillStep.displayName = 'FillStep';
export default FillStep;

// Avoid circular dependency
stepsByType.FillStepV2.Component = FillStep;
