import React, { PureComponent } from 'react';
import { getDisplayName, hoistStatics } from 'recompose';
import classnames from 'classnames';

export default (defaultClass = '') => {
  const withClassName = (BaseComponent) => {
    const name = getDisplayName(BaseComponent);

    class WithClassName extends PureComponent {
      render() {
        const { className = '' } = this.props;
        const classes = [
          typeof defaultClass === 'function'
            ? defaultClass(this.props)
            : defaultClass,
          typeof className === 'function' ? className(this.props) : className,
        ].map((s) => (s.toString ? s.toString() : s));

        const finalClassName = classnames(...classes);

        return <BaseComponent {...this.props} className={finalClassName} />;
      }
    }

    WithClassName.BaseComponent = BaseComponent;
    WithClassName.displayName = `withClassName(${name})`;
    return WithClassName;
  };

  return hoistStatics(withClassName);
};
