import React, { useEffect } from 'react';
import { Icon } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

import { useQuery } from '<src>/apollo/client';
import { RoundedContentWrapper } from '<src>/components/NumbrzVerticalEditor';
import { formatISODateString } from '<src>/utils/dates';

import WaveSpinner from '<src>/components/WaveSpinner';
import { StatusTable, DetailsBtn } from '<sections>/jobs/styles';

import { RecentRuns } from '../../../../queries';
import { getStatusChiclet } from '<sections>/job-groups/utils';
import {
  PageContent,
  EmptyListContainer,
} from '<src>/components/NumbrzPageComponents';

export default function RecentRunsPage({ project }) {
  const history = useHistory();
  const {
    data: { recentRuns } = {},
    loading,
    startPolling,
    stopPolling,
  } = useQuery(RecentRuns, {
    variables: { input: { containerID: project.ID } },
  });

  useEffect(() => {
    startPolling(10000);

    return () => {
      stopPolling();
    };
  });

  return loading ? (
    <WaveSpinner />
  ) : (
    <PageContent maxWidth="1000px">
      {!loading && (!recentRuns || recentRuns.length === 0) ? (
        <EmptyListContainer>
          <h4>No job run history available </h4>
        </EmptyListContainer>
      ) : (
        <RoundedContentWrapper>
          <StatusTable style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Status</th>
                <th>Job</th>
                <th>Run By</th>
                <th>Date</th>
                <th>Duration</th>
                <th className="centered">Details</th>
              </tr>
            </thead>
            <tbody>
              {recentRuns.map((r, idx) => {
                const formattedStartedTime = formatISODateString(
                  r.started,
                  true
                );
                return (
                  <tr key={idx}>
                    <td>{getStatusChiclet(r.status)}</td>
                    <td className="longContent">{r.bindingName}</td>
                    <td className="userName">
                      {r.runByName && (
                        <div>
                          <Icon name="user outline" />
                          <span>{r.runByName}</span>
                        </div>
                      )}
                    </td>
                    <td className="date">
                      {formattedStartedTime.includes('undefined') ? null : (
                        <div>
                          <Icon name="clock outline" />
                          <span>{formattedStartedTime}</span>
                        </div>
                      )}
                    </td>
                    <td className="duration">
                      <div>
                        <Icon name="hourglass outline" />
                        <span>{r.elapsed}</span>
                      </div>
                    </td>
                    <td className="centered">
                      <DetailsBtn
                        onClick={() =>
                          history.push(
                            `/models/${project.ID}/jobs/${r.bindingID}/status/${r.runID}`
                          )
                        }
                      >
                        <Icon name="list alternate outline" />
                      </DetailsBtn>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </StatusTable>
        </RoundedContentWrapper>
      )}
    </PageContent>
  );
}
