import styled from '@emotion/styled';

import * as colors from '<components>/colors';
import { inEms } from '<utils>/math';
import { Link } from 'react-router-dom';

const width = inEms(280);
const height = inEms(250);

const StyledCard = styled('div')`
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;
  width: ${width}em;
  height: ${height}em;
  background: ${colors.white};
  border-radius: 8px;
  border: 1px solid ${colors.gray3};

  color: ${colors.black};
`;

const StyledHeader = styled('header')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: ${inEms(12)}em ${inEms(10)}em ${inEms(5)}em ${inEms(14)}em;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  flex-shrink: 0;

  i.icons {
    position: absolute;
    top: -8px;
    right: -8px;
    margin: 0 !important;
    .icon {
      left: 49.5%;
    }
    i {
      margin: 0 !important;
      height: unset !important;
      width: 1em !important;
    }
    i.circle {
      color: ${colors.red1c};
      font-size: 1.3em;
    }
    i.exclamation {
      color: white;
      font-size: 0.7em;
    }
  }
`;

const StyledHeadline = styled('h2')`
  font-weight: bold;
  font-size: 13px;
  line-height: 1;

  overflow: hidden;
  text-overflow: ellipsis;
  color: ${colors.gray1};
  text-decoration: none;

  a > &,
  a & {
    position: relative;
    display: inline-block;
    color: ${colors.gray1};
  }

  a:hover > &,
  a:focus > &,
  a&:hover,
  a&:focus {
    color: ${colors.blue1};
  }
`;

const StyledBody = styled('div')`
  display: block;
  padding: ${inEms(5)}em ${inEms(10)}em ${inEms(5)}em ${inEms(14)}em;

  flex: 1;
  color: ${colors.gray1};
  input: {
    margin-bottom: 10px;
  }
`;

const StyledLinkedBody = styled(StyledBody.withComponent(Link))`
  display: block;
  padding-left: 11px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  p {
    text-overflow: ellipsis;
    width: fit-content;
  }
`;

const StyledFooter = styled('footer')(
  `
  display: flex;
  padding: 5px ${inEms(16)}em ${inEms(12)}em ${inEms(14)}em;
  flex-shrink: 0;
  align-items: center;

`,
  ({ align = 'right', justifyContent }) => ({
    justifyContent: justifyContent
      ? justifyContent
      : align === 'right'
        ? 'flex-end'
        : 'flex-start',
    flexWrap: align === 'right' ? 'reverse' : 'wrap',
  })
);

export const Description = styled('div')`
  color: ${colors.gray2};
  font-size: 12px;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 14px;
`;

const Card = StyledCard;
Card.Header = StyledHeader;
Card.Headline = StyledHeadline;
Card.Body = StyledBody;
Card.LinkedBody = StyledLinkedBody;
Card.Footer = StyledFooter;
Card.Description = Description;

export default Card;
