import { useCallback } from 'react';
import { useLazyQuery, useMutation } from '<src>/apollo/client';
import fp from 'lodash/fp';
import shortid from '<src>/utils/shortid';

import {
  AddFlowTable,
  UpdateFlowTable,
  AddStep,
  GetStage,
  MoveStep,
  RemoveStep,
  UpdateStage,
  UpdateStageInput,
  UpdateStep,
  UpdateAggregateStep,
  UpdateCombineStep,
  UpdateExecStep,
  UpdateFillStep,
  UpdateFilterStep,
  UpdateGroupAndSortStep,
  UpdateOutputStep,
  UpdateSliceStep,
  CreateFunction,
} from './queries';

const changeStep =
  (stageID, mutate) =>
  (e, { key, ...updates }) => {
    mutate({ variables: { stageID, key, updates } });
  };

export default function useStageState(flow) {
  const [fetchStage, { data, error, loading, called }] = useLazyQuery(GetStage);
  const [updateStage] = useMutation(UpdateStage);
  const [updateStageInput] = useMutation(UpdateStageInput);
  const [addStep] = useMutation(AddStep);
  const [moveStep] = useMutation(MoveStep);
  const [removeStep] = useMutation(RemoveStep);
  const [updateStep] = useMutation(UpdateStep);
  const [updateAggregateStep] = useMutation(UpdateAggregateStep);
  const [updateCombineStep] = useMutation(UpdateCombineStep);
  const [updateExecStep] = useMutation(UpdateExecStep);
  const [updateFillStep] = useMutation(UpdateFillStep);
  const [updateFilterStep] = useMutation(UpdateFilterStep);
  const [updateGroupAndSortStep] = useMutation(UpdateGroupAndSortStep);
  const [updateOutputStep] = useMutation(UpdateOutputStep);
  const [updateSliceStep] = useMutation(UpdateSliceStep);
  const [addFlowTable] = useMutation(AddFlowTable);
  const [updateFlowTable] = useMutation(UpdateFlowTable);
  const [addFunction] = useMutation(CreateFunction);

  const { ownerID, containerID: projectID } = flow || {};
  const loadStage = useCallback(
    (stageID) =>
      fetchStage({
        variables: {
          ID: stageID,
          ownerID: ownerID,
          projectID,
        },
      }),
    [fetchStage, ownerID, projectID]
  );

  if (error) {
    throw error;
  }

  if (!flow) {
    return {};
  }

  if (!called || loading || error) {
    return { called, loading, loadStage };
  }

  const state = {
    called,
    functions: data.functionsV3,
    loading,
    loadStage,
    stage: data.flowStageV2,
  };

  const stageID = state.stage.ID;
  const isStageIncluded =
    flow.ID !== fp.getOr(undefined, 'flowStageV2.flowID', data);

  const onAddTable = async (e, table) => {
    const {
      data: { addFlowTableDef },
      error,
    } = await addFlowTable({
      variables: { flowID: flow.ID, table },
    });
    if (error) {
      throw error;
    }
    const saved =
      addFlowTableDef.flow.data[addFlowTableDef.flow.data.length - 1];
    return saved;
  };

  const onAddFunction = async (e, func) => {
    const {
      data: { createFuncV3 },
      error,
    } = await addFunction({
      variables: { func: { ...func, projectID } },
    });
    if (error) {
      throw error;
    }
    return createFuncV3;
  };

  state.api = isStageIncluded
    ? {}
    : {
        onAddTable,
        onChange: (e, { name, description }) =>
          updateStage({
            variables: { ID: stageID, updates: { name, description } },
          }),
        onChangeInput: (e, { tableID }) =>
          updateStageInput({
            variables: {
              ID: stageID,
              tableID,
              mode: tableID ? 'Table' : 'Env',
            },
          }),
        step: {
          onAdd: (e, { index, type, ...step }) =>
            addStep({
              variables: {
                stageID: stageID,
                index,
                type,
                step: { ...step, key: shortid() },
              },
            }),
          onMove: (e, { key, dir }) => {
            if (dir !== 'up' && dir !== 'down') {
              throw new TypeError(`Invalid dir ${dir}`);
            }
            const move = dir === 'up' ? -1 : 1;
            return moveStep({
              variables: { stageID, key, move },
            });
          },
          onRemove: (e, { key }) => removeStep({ variables: { stageID, key } }),
          onChange: changeStep(stageID, updateStep),
          onChangeAggregate: changeStep(stageID, updateAggregateStep),
          onChangeCombine: changeStep(stageID, updateCombineStep),
          onChangeExec: changeStep(stageID, updateExecStep),
          onChangeFill: changeStep(stageID, updateFillStep),
          onChangeFilter: changeStep(stageID, updateFilterStep),
          onChangeGroupAndSort: changeStep(stageID, updateGroupAndSortStep),
          onChangeOutput: changeStep(stageID, updateOutputStep),
          onChangeSlice: changeStep(stageID, updateSliceStep),
          onAddTable,
          onChangeTable: (e, { ID: tableID, ...updates }) =>
            updateFlowTable({
              variables: {
                flowID: flow.ID,
                tableID,
                updates,
              },
            }),
          onAddFunction,
        },
      };
  return state;
}
