import { createContext } from 'react';

export const Cells = createContext({});
export const Expanded = createContext({ expanded: [], setExpanded: () => {} });
export const Links = createContext({});

export const Selection = createContext({
  selection: { type: 'NONE' },
  activeItems: {},
  select: () => {},
  deselect: () => {},
});

export const Select = createContext(() => {});

Selection.NONE = 'NONE';
Selection.CELL = 'CELL';
Select.CELL = 'CELL';
Select.NONE = 'NONE';
