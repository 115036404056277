import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { Icon, Dropdown } from 'semantic-ui-react';
import BrandLogo from '../BrandLogo';
import * as colors from '<components>/colors';
import useFeatures from '<components>/Feature/useFeatures';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { FlexRowContainer } from '<components>/NumbrzPageComponents';

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.95)
  }

  80% {
    opacity: .9;
    transform: scale(1)
  }

  100% {
    opacity: 1;
  }
  `;

const NavSlash = styled('span')`
  font-size: 17px;
  color: ${colors.gray2};
  margin: 0 4px;
  font-family: 'Apple';
`;

const StyledNavigation = styled('div')`
  display: flex;
  align-items: baseline;
  flex-direction: row;
  color: ${colors.gray5};
  margin-right: 15px;
  font-size: 16px;
`;

const BrandingLink = styled(Link)({
  height: '20px',
  display: 'block',
  color: 'inherit',
  marginRight: '5px',
});

const StyledLink = styled(Link)`
  display: block;
  padding: 0.78571429rem 1.14285714rem;
  color: ${colors.gray1};
  font-size: 1rem;
  :hover {
    color: ${colors.gray1};
    background: ${colors.grayDropdownHov};
  }
`;

const BreadcrumbLink = styled(Link)`
  font-size: 13px;
  letter-spacing: 0.7px;

  position: relative;
  display: inline-block;
  color: inherit;
  animation: ${fadeIn} 120ms ease-in both;

  &:after {
    position: absolute;
    content: ' ';
    right: 100%;
    left: 0;
    bottom: -0.1em;
    height: 0.1em;
    background: ${colors.blue1};
    transition: all 250ms ease-in;
  }

  &:hover {
    color: ${colors.white};
    &:after {
      right: 0;
    }
  }
`;

const BreadcrumbDropdown = styled(Dropdown)`
  display: flex !important;
  align-items: center;
  border: none;
  shadow: none;
  outline: none;
  margin: 0;

  border-radius: 20px;
  color: white;

  .menu {
    margin-top: 5px !important;
    left: -300% !important;
  }
  svg {
    width: 19px;
    margin: 0px -3px 0 3px !important;
    :hover {
      color: ${colors.blue1};
    }
  }
  :hover {
    cursor: pointer;
  }
  :focus {
    svg {
      color: ${colors.blue1};
    }
  }
`;

const StyledIcon = styled(Icon)`
  color: ${colors.gray2};

  animation: ${fadeIn} 120ms ease-in both;
`;

const TextContainer = styled('div')`
  font-size: 13px;
  letter-spacing: 0.7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  animation: ${fadeIn} 120ms ease-in both;
`;

const SubTitle = styled('h3')`
  margin: 0;
  font-weight: 300;
  font-size: 0.8em;
  opacity: 0.8;
`;

const dropdownOptions = (breadcrumb, features) => {
  let options = [];
  switch (breadcrumb.type) {
    case 'root':
      options = [
        {
          path: '/models',
          label: 'Models',
        },
        {
          path: '/data',
          label: 'Data',
        },
        {
          path: '/job-groups',
          label: 'Job Groups',
        },
        ...(features.ServiceModels
          ? [
              {
                path: '/svc-models',
                label: 'Service Models',
              },
            ]
          : []),
        {
          path: '/catalog',
          label: 'Catalog',
        },
      ];
      break;
    case 'model':
      const isDeployed = !!breadcrumb.model.deployment;
      options = [
        { path: '/overview', label: 'Overview' },
        { path: '/functions', label: 'Functions' },
        { path: '/flows', label: 'Flows' },
        { path: '/jobs', label: 'Jobs' },
        { path: '/recent-runs', label: 'Recent Runs' },
        ...(!isDeployed && features.ModelVersions
          ? [{ path: '/versions', label: 'Versions' }]
          : []),
        ...(!isDeployed && features.PublishModel
          ? [{ path: '/share', label: 'Share' }]
          : []),
      ].map((link) => ({
        ...link,
        path: `/models/${breadcrumb.model.ID}${link.path}`,
      }));
      break;
    case 'flow':
      options = [
        { path: '/stages', label: 'Stages' },
        { path: '/job-tables', label: 'Job tables' },
        { path: '/variables', label: 'Job variables' },
        { path: '/working-tables', label: 'Working tables' },
        { path: '/tests', label: 'Tests' },
        { path: '/included', label: 'Included flows' },
        { path: '/documentation', label: 'Documentation' },
      ].map((link) => ({
        ...link,
        path: `/models/${breadcrumb.modelID}/flows/${breadcrumb.flowID}${link.path}`,
      }));
      break;
    case 'svc-model':
      options = [
        { path: '/overview', label: 'Overview' },
        { path: '/svc-jobs', label: 'Service Jobs' },
        { path: '/tmpl-jobs', label: 'Template Jobs' },
        { path: '/prod-jobs', label: 'Production Jobs' },
        { path: '/data', label: 'Data' },
        { path: '/releases', label: 'Releases' },
        { path: '/deployments', label: 'Deployments ' },
      ].map((link) => ({
        ...link,
        path: `/svc-models/${breadcrumb.model.ID}${link.path}`,
      }));
      break;
    default:
      break;
  }

  return options;
};

const BreadcrumbButton = ({ breadcrumb, features }) => {
  const options = dropdownOptions(breadcrumb, features);

  return (
    <FlexRowContainer alignItems="center" justifyContent="flex-start">
      <BreadcrumbLink to={breadcrumb.path}>{breadcrumb.title}</BreadcrumbLink>
      <BreadcrumbDropdown
        trigger={<></>}
        icon={<KeyboardArrowDownRoundedIcon />}
      >
        <Dropdown.Menu>
          {options.map((op) => (
            <StyledLink key={op.path} to={op.path}>
              {op.label}
            </StyledLink>
          ))}
        </Dropdown.Menu>
      </BreadcrumbDropdown>
    </FlexRowContainer>
  );
};

const makeBreadcrumbs = ({ breadcrumbs = [] }, features) => {
  if (!breadcrumbs) return null;
  if (breadcrumbs.length === 0) return null;

  if (Array.isArray(breadcrumbs)) {
    return breadcrumbs.map((breadcrumb, index) => {
      return (
        <Fragment key={index}>
          <NavSlash>/</NavSlash>
          {breadcrumb.type ? (
            <BreadcrumbButton breadcrumb={breadcrumb} features={features} />
          ) : (
            <BreadcrumbLink to={breadcrumb.path}>
              {breadcrumb.title}
            </BreadcrumbLink>
          )}
        </Fragment>
      );
    });
  }

  return null;
};

const Navigation = (props) => {
  const { additionalDetails, title } = props;
  const featureFlags = useFeatures();

  return (
    <StyledNavigation>
      <FlexRowContainer alignItems="baseline" justifyContent="flex-start">
        <BrandingLink to="/">
          <BrandLogo />
        </BrandingLink>
        <FlexRowContainer alignItems="center" justifyContent="flex-start">
          {props.activeAccount && (
            <Fragment>
              <StyledIcon name="angle right" style={{ marginTop: '-3px' }} />
              <BreadcrumbLink to="/">{props.activeAccount}</BreadcrumbLink>
            </Fragment>
          )}

          {makeBreadcrumbs(props, featureFlags)}
          {title && (
            <Fragment>
              <NavSlash>/</NavSlash>
              <TextContainer>
                {title}
                {additionalDetails && <SubTitle>{additionalDetails}</SubTitle>}
              </TextContainer>
            </Fragment>
          )}
        </FlexRowContainer>
      </FlexRowContainer>
    </StyledNavigation>
  );
};

export default Navigation;
