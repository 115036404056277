/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from '@emotion/react';
import { useState, useMemo } from 'react';
import moment from 'moment';
import fp from 'lodash/fp';

import { ConfigTable } from '<src>/sections/flows/styles';
import { Icon } from 'semantic-ui-react';
import ErrorPopup from './components/ErrorPopup/ErrorPopup';
import ErrorChiclet from '<components>/ErrorChiclet';
import Button from '<src>/components/Button';
import { formatDurationLabel, formatServiceLbl } from '../utils';
import * as styles from './styles';

export default function ExternalData({ extData = [] }) {
  const [expanded, setExpanded] = useState(true);
  const [mode, setMode] = useState('summary');

  const summaryData = useMemo(() => {
    const groupedData = fp.groupBy('operation', extData);

    Object.keys(groupedData).forEach((op) => {
      const initialValue = {
        records: 0,
        elapsedMillis: 0,
        operation: undefined,
        errors: 0,
      };

      groupedData[op] = groupedData[op].reduce((acc, cV) => {
        acc.label = cV.operation;
        acc.operation = cV.operation;
        acc.elapsedMillis =
          parseInt(acc.elapsedMillis) + parseInt(cV.elapsedMillis);
        acc.records += cV.records;
        acc.errors += cV.errMsg ? 1 : 0;

        return acc;
      }, initialValue);
    });

    return Object.values(groupedData).map((op, idx) => {
      return (
        <tr key={idx}>
          <td>{op.label}</td>
          <td>{formatDurationLabel(moment.utc(op.elapsedMillis))}</td>
          <td>
            {op.operation === 'Truncate'
              ? 'N/A'
              : op.records >= 0
                ? op.records
                : 'N/A'}
          </td>
          <td>
            {op.errors > 0 && (
              <ErrorChiclet actionable={false} centered={true}>
                {op.errors}
              </ErrorChiclet>
            )}
          </td>
        </tr>
      );
    });
  }, [extData]);

  const fullData = useMemo(() => {
    return extData.map((eT, idx) => {
      const duration = moment.duration(eT.elapsedMillis / 1000, 'seconds');

      return (
        <tr key={idx}>
          <td>{eT.operation}</td>
          <td>{`${formatServiceLbl(eT.tableSrc)} - ${eT.tableName}`}</td>
          <td>{formatDurationLabel(moment.utc(duration.asMilliseconds()))}</td>
          <td>{eT.records < 0 ? 'N/A' : eT.records}</td>
          <td style={{ textAlign: 'center' }}>
            {eT.errMsg ? <ErrorPopup errors={[eT.errMsg]} /> : null}
          </td>
        </tr>
      );
    });
  }, [extData]);

  if (extData && extData.length > 0) {
    return (
      <styles.StatusContainer>
        <styles.StatusHeader>
          <styles.SectionHdrBtn onClick={() => setExpanded((e) => !e)}>
            External Data
            <Icon
              name={expanded ? 'caret up' : 'caret down'}
              title={expanded ? 'Hide' : 'Show'}
            />
          </styles.SectionHdrBtn>
          {expanded && (
            <styles.StatusToolbar>
              <Button
                size="small"
                action="control"
                className={mode === 'summary' && 'active'}
                onClick={() => setMode('summary')}
              >
                Summary
              </Button>

              <Button
                size="small"
                action="control"
                className={mode === 'full' && 'active'}
                onClick={() => setMode('full')}
              >
                Full Details
              </Button>
            </styles.StatusToolbar>
          )}
        </styles.StatusHeader>
        {expanded ? (
          <ConfigTable style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Operation</th>
                {mode !== 'summary' && <th>Source</th>}
                <th>Duration</th>
                <th>Records</th>
                <th>Errors</th>
              </tr>
            </thead>
            <tbody>{mode === 'summary' ? summaryData : fullData}</tbody>
          </ConfigTable>
        ) : null}
      </styles.StatusContainer>
    );
  }

  return null;
}
