import React, { useState, useEffect, useMemo, Fragment } from 'react';
import T from 'prop-types';
import { useQuery } from '<src>/apollo/client';

import DocumentTitle from 'react-document-title';
import DeployDialog from '<sections>/projects/components/DeployDialog';
import MainLayout from '<components>/MainLayout';
import Sidebar from '<components>/MainLayout/Sidebar';
import Masthead from '<components>/Masthead';
import CatalogList from './CatalogList';
import WaveSpinner from '<src>/components/WaveSpinner';
import {
  ColumnWrapper,
  FlexRowContainer,
  PageContainer,
} from '<src>/components/NumbrzPageComponents';
import ResizeableSplitView from '<src>/sections/flows/flow-testing/ResizeableSplitView';
import { FlowContainer } from '<src>/components/NumbrzVerticalEditor';
import CatalogListControls from '<sections>/catalog/components/ListControls';
import { GetCatalogProjects } from '../../queries';
import { Tag } from '<sections>/catalog/components/styles';

CatalogListPage.propTypes = {
  location: T.shape({
    search: T.string.isRequired,
  }).isRequired,
  history: T.shape({
    push: T.func.isRequired,
    replace: T.func.isRequired,
  }).isRequired,
};

export const catalogCategories = [
  {
    key: 'service',
    value: 'DeployService',
    label: 'Service Models',
    description:
      'A fully functional, pre-configured model ready to run your data. Includes a companion spreadsheet',
  },
  {
    key: 'template',
    value: 'DeploySource',
    label: 'Template Models',
    description:
      'Provides complete access to the model’s calculations.  Copy and modify to meet your needs',
  },

  {
    key: 'function-library',
    value: 'isLibrary',
    label: 'Function Libraries',
    description:
      'A set of editable functions that you copy to use in any model',
  },
  {
    key: 'coming-soon',
    value: 'preview',
    label: 'Coming Soon',
    description:
      'Models that are in early development and sharing their status before they become available',
  },
  {
    key: 'help-wanted',
    value: 'helpWanted',
    label: 'Help Wanted',
    description:
      'Models actively seeking user input for development or testing. Contribute your expertise to shape the final product',
  },
];

export default function CatalogListPage({ baseURL }) {
  const [deploying, setDeploying] = useState(null);
  const { data: { catalogProjects: projects = [] } = {}, loading } =
    useQuery(GetCatalogProjects);

  const [searchTerm, setSearchTerm] = useState();
  const [filters, setFilters] = useState({
    tags: [],
    type: [],
    category: 'DeployService',
  });
  const [searchPattern, setSearchPattern] = useState();

  const tags = useMemo(() => {
    let tags = [];

    projects.forEach((p) => {
      if (p.tags && p.tags.length > 0) {
        p.tags.forEach((t) => {
          if (!tags.includes(t)) tags.push(t);
        });
      }
      if (!tags.includes(p.category)) tags.push(p.category);
    });

    return tags;
  }, [projects]);

  useEffect(() => {
    if (searchTerm) {
      setSearchPattern(new RegExp(searchTerm, 'ig'));
    }
    if (searchTerm === '') {
      setSearchPattern(undefined);
    }
  }, [searchTerm]);

  const sanitizeSearchTerm = () =>
    searchTerm && searchTerm.trim ? searchTerm.trim() : '';

  const header = () => {
    return <Masthead></Masthead>;
  };

  const body = () => {
    if (loading) {
      return <WaveSpinner />;
    }

    return (
      <PageContainer>
        <ResizeableSplitView
          left={
            <Fragment>
              <Sidebar hideMasthead />
              <FlowContainer>
                <ColumnWrapper>
                  <CatalogListControls
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    filters={filters}
                    setFilters={setFilters}
                    tags={tags}
                    categories={catalogCategories}
                  />
                  {(filters.tags.length > 0 || filters.type.length > 0) && (
                    <FlexRowContainer
                      alignItems="center"
                      justifyContent="flex-start"
                      width="100%"
                    >
                      {filters.tags.map((t) => (
                        <Tag>{t}</Tag>
                      ))}
                      {filters.type.map((t) => (
                        <Tag>{`${t === 'service' ? 'Service Models' : t === 'template' ? 'Template Models' : t}`}</Tag>
                      ))}
                    </FlexRowContainer>
                  )}
                  <CatalogList
                    projects={projects}
                    searchTerm={sanitizeSearchTerm()}
                    searchPattern={searchPattern}
                    onDeploy={(ID) => setDeploying(ID)}
                    filters={filters}
                    categories={catalogCategories}
                  />
                  <DeployDialog
                    visible={!!deploying}
                    projectID={deploying}
                    onRequestClose={() => setDeploying(false)}
                    skipAccounts
                  />
                </ColumnWrapper>
              </FlowContainer>
            </Fragment>
          }
          right={null}
        />
      </PageContainer>
    );
  };

  return (
    <DocumentTitle title="Catalog - Numbrz">
      <MainLayout
        overflowY="hidden"
        navigation={false}
        header={header()}
        main={body()}
      />
    </DocumentTitle>
  );
}
