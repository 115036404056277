import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDrop } from 'react-dnd';
import styled from '@emotion/styled';

import * as colors from '<components>/colors';
import { PageContainer, PageMasthead } from '<components>/NumbrzPageComponents';
import AddWidgetDialog from '../AddWidgetDialog';
import WidgetsPane from '../WidgetsPane';
import Editor from '../Editor';
import ExpandedManager from '../ExpandedManager';
import LinkLines from '../LinkLines';
import ParamSet from '../ParamSet';
import SelectionManagerBase from '../SelectionManager';
import { TitleField } from '<src>/sections/flows/styles';
import Button from '<src>/components/Button';
import useActiveCellLinks from '../useActiveCellLinks';
import { useAddWidget, useUpdateFunction } from '../gql';
import { Selection } from '../contexts';

const Pane = styled('div')`
  height: 100%;
  width: 100%;
  position: relative;
  background: ${colors.gray11};
`;

const SelectionManager = Pane.withComponent(SelectionManagerBase);

const MainContent = styled('div')`
  padding-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 40px;
  background: ${colors.gray11};
`;

const Params = styled('div')`
  position: sticky;
  top: 0;
  height: fit-content;
  & > div {
    margin: 6px;
  }
`;

const Inputs = styled(Params)``;

const Outputs = styled(Params)`
  margin-bottom: 60px;
`;

const FunctionLabel = styled('div')`
  transition: border-color 200ms 25ms ease-in-out;
  border-bottom: 1px solid transparent;
  border-left: 1px solid ${colors.gray_border};
  margin-left: 10px;
  padding-left: 10px;
  font-size: 13px;
  margin-top: 3px;
  & * {
    outline: none;
  }
  &:focus-within,
  &:hover {
    border-bottom-color: ${colors.blue2};
  }
  & .placeholder {
    font-style: italic;
    color: ${colors.gray2};
  }
  & [contenteditable] {
    min-width: 65px;
  }
`;

const FunctionName = styled('h5')`
  font-size: 14px;
`;

function FunctionLogic({ func, showAllLinks }) {
  const addWidget = useAddWidget();
  const activeCellLinks = useActiveCellLinks(func);
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const [from, setFrom] = useState(null);
  const { setAppearTrigger } = useContext(Selection);
  const [, dropRef] = useDrop({
    accept: 'CELL',
    drop: (item, monitor) => {
      if (monitor.didDrop()) return;
      setFrom(item);
      setAddDialogVisible(true);
    },
  });
  const closeDialog = useCallback(() => {
    setFrom(null);
    setAddDialogVisible(false);
  }, []);

  return (
    <Pane ref={dropRef} onDoubleClick={() => setAddDialogVisible(true)}>
      <LinkLines links={activeCellLinks}>
        <MainContent>
          <Inputs>
            <ParamSet
              funcID={func.ID}
              section="inputs"
              params={func.inputs}
              forceActive
            />
          </Inputs>
          <div>
            <WidgetsPane func={func} />
          </div>
          <div>
            <Outputs>
              <ParamSet
                funcID={func.ID}
                section="outputs"
                params={func.outputs}
                forceActive
              />
            </Outputs>
          </div>
        </MainContent>
      </LinkLines>
      <AddWidgetDialog
        visible={addDialogVisible}
        from={from}
        parent={func}
        onClose={closeDialog}
        addWidget={(widget, cells) => {
          addWidget({ funcID: func.ID, widget, cells });
          closeDialog();
        }}
        setAppearTrigger={setAppearTrigger}
      />
      <Button.CreateFunxBtn
        title="Add a widget"
        onClick={() => setAddDialogVisible(true)}
        style={{ bottom: '75px' }}
      >
        Add widget
        <span className="icon">+</span>
      </Button.CreateFunxBtn>
    </Pane>
  );
}

export default function Function({
  appearTrigger = { id: 'NONE' },
  func,
  refetch,
}) {
  const updateFunction = useUpdateFunction();
  const nameRef = useRef();

  useEffect(() => {
    if (
      appearTrigger.id === func.correlationID &&
      appearTrigger.action === 'edit'
    ) {
      nameRef.current.edit(true);
      appearTrigger.done();
    }
  }, [appearTrigger, func.correlationID]);

  return (
    <PageContainer>
      <SelectionManager>
        <ExpandedManager>
          <PageMasthead padding="0 20px 0 40px" justifyContent="flex-start">
            <FunctionName>
              <TitleField
                ref={nameRef}
                singleClick
                value={func.name}
                placeholder="Click to set name"
                onSubmit={({ value: name }) => updateFunction(func, { name })}
              />
            </FunctionName>
            <FunctionLabel>
              <Editor
                singleClick
                value={func.description}
                placeholder="Click to set description"
                onSubmit={({ value: description }) =>
                  updateFunction(func, { description })
                }
              />
            </FunctionLabel>
          </PageMasthead>
          <FunctionLogic func={func} />
          {
            // <SelectionInfo />
          }
        </ExpandedManager>
      </SelectionManager>
    </PageContainer>
  );
}
