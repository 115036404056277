import {
  EditorAPI,
  initialSetupState,
  makeSetupAPI,
  makeUIWidget,
  validate,
} from './api';
import LookupSettings from './LookupSettings';
import Body from './Body';

export default {
  ID: 'BUILTIN:LOOKUP',
  type: 'Lookup',
  builtin: true,
  name: 'Lookup',
  description:
    'Select values from a lookup table by matching rows against input values.',
  itemData: (widget) => ({ lookupTableID: widget.config.lookupTableID }),
  Body,
  Settings: LookupSettings,
  Label: (widget) => 'Lookup',
  EditorAPI,
  initialSetupState,
  makeSetupAPI,
  makeUIWidget,
  validate,
};
