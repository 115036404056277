import React, { useState } from 'react';
import styled from '@emotion/styled';
import * as colors from '<components>/colors';

import { Link } from 'react-router-dom';
import CredentialItem, { NewUserAccount } from './CredentialItem';
import { FlexColumnContainer } from '<components>/NumbrzPageComponents';
import IntegrationDialog from '<sections>/account/pages/Integrations/IntegrationDialog';
import WaveSpinner from '<components>/WaveSpinner';

const PaneContainer = styled('div')`
  ${(props) => props.width && { width: props.width }};
  margin: 20px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  h4 {
    display: flex;
    justify-content: space-between;
    margin: 0 5px 0px 5px;
    font-size: 14px;
    padding-bottom: 4px;
    border-bottom: 1px solid ${colors.gray_border};
    a {
      font-weight: normal;
      font-size: 13px;
    }
    span {
      color: ${colors.gray2};
      font-weight: normal;
      font-size: 12px;
      margin-left: 5px;
    }
  }
`;

const AccountList = styled(FlexColumnContainer)`
  margin: 10px 0;
  overflow-y: auto;
`;

export default function CredentialPickerPane({
  loading,
  credentials = [],
  selectedItem,
  setActiveCred,
  connectorName,
  hideHdr = false,
  width,
}) {
  const [dialogVisible, setDialogVisible] = useState(false);

  return (
    <PaneContainer width={width}>
      {!hideHdr && (
        <h4>
          <div>
            Authentication <span style={{ color: '#cecece' }}>(Required)</span>
          </div>
          <Link to="/account/integrations">Manage Users</Link>{' '}
        </h4>
      )}
      {loading ? (
        <WaveSpinner />
      ) : (
        <AccountList>
          {credentials.map((cred, idx) => (
            <CredentialItem
              key={idx}
              cred={cred}
              selected={selectedItem?.ID === cred.ID}
              onSelect={() => setActiveCred(cred)}
            />
          ))}
          <NewUserAccount
            btnLbl={
              credentials.length === 0
                ? 'Create a new account'
                : 'Use another account'
            }
            onClick={() => setDialogVisible(true)}
          />
        </AccountList>
      )}

      <IntegrationDialog
        setActiveCred={setActiveCred}
        connectorName={connectorName}
        visible={dialogVisible}
        setDialogVisible={setDialogVisible}
        onDialogClose={() => setDialogVisible(false)}
      />
    </PaneContainer>
  );
}
