import React from 'react';

import { NumbrzTooltip, FieldSrcTooltipBtn } from '<components>/NumbrzButtons';

import FormulaText from './FormulaText';

export default function FieldOp({ elements, field }) {
  const opPrefix =
    field.__typename === 'NumberSequenceV2' && field.numberUnit === 'Multiple'
      ? 'Proportional '
      : '';

  return (
    <span>
      <NumbrzTooltip
        wide
        hideOnScroll
        on="click"
        position="top center"
        content={<FormulaText elements={elements} field={field} />}
        trigger={<FieldSrcTooltipBtn name="info circle" />}
      />
      <span>
        {opPrefix}
        {field.op}
      </span>
    </span>
  );
}
