import React, { useCallback } from 'react';
import { useMutation } from '<src>/apollo/client';

import UserVariablesEditor from '<sections>/flows/UserVariablesEditor';

import { UpdateJob } from '../queries';

export default function JobVariables({
  jobID,
  flowID,
  flowName,
  jobVariables = [],
  flowVariables = [],
}) {
  const [updateJob] = useMutation(UpdateJob);

  const onAddUpdateUserVar = useCallback(
    (e, userVar) => {
      const newVars = [...jobVariables];
      const jobVarIdx = jobVariables.findIndex(
        (jV) => jV.key === userVar.toElement.key
      );

      if (jobVarIdx >= 0) {
        newVars[jobVarIdx] = { ...newVars[jobVarIdx] };
        newVars[jobVarIdx].val = userVar.defaultVal;
      } else {
        newVars.push({ key: userVar.toElement.key, val: userVar.defaultVal });
      }

      updateJob({
        variables: {
          input: { ID: jobID, variables: newVars },
        },
      });
    },
    [jobVariables, jobID, updateJob]
  );

  return (
    <UserVariablesEditor
      flowID={flowID}
      userVariables={flowVariables}
      jobVariables={jobVariables}
      onAddUpdateUserVar={onAddUpdateUserVar}
      onRemoveUserVar={() => {}}
      mode="job"
    />
  );
}
