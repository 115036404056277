export { shallowEqual } from 'recompose';
export { default as deepEqual } from 'fast-deep-equal';

export const eatEvent = (event) => {
  event.stopPropagation();
};

export const mergeRefs =
  (refs = []) =>
  (value) =>
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref !== null) {
        ref.current = value;
      }
    });

export function positionCursor(node, selectAll) {
  if (!node) return;

  const selection = window.getSelection();
  const range = document.createRange();
  range.selectNodeContents(node);
  if (!selectAll) {
    range.collapse(false);
  }

  selection.removeAllRanges();
  selection.addRange(range);
}
