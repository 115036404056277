import React from 'react';
import styled from '@emotion/styled';
import { Icon } from 'semantic-ui-react';

import Button from '<src>/components/Button';

const Style = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  button {
    z-index: 1;
  }
`;

export default function ExpandBar({
  label = 'Details',
  expanded = false,
  onClick,
}) {
  return (
    <Style>
      <Button.ToggleContent
        round
        onClick={onClick}
        icon={<Icon name={expanded ? 'angle up' : 'angle down'} />}
      >
        {label}
      </Button.ToggleContent>
    </Style>
  );
}
