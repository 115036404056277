import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import omit from 'lodash/omit';
import classnames from 'classnames';

import useEditorState from './useEditorState';

function positionCursor(node, selectAll) {
  if (!node) return;

  const selection = window.getSelection();
  const range = document.createRange();
  range.selectNodeContents(node);
  if (!selectAll) {
    range.collapse(false);
  }

  selection.removeAllRanges();
  selection.addRange(range);
}

function setText(node, value) {
  if (node) {
    node.innerText = value || '';
  }
}

function Editor(
  {
    canFocus = true,
    disabled = false,
    singleClick = false,
    value,
    placeholder,
    ...props
  },
  parentRef
) {
  const ref = useRef();
  const { editing, focus, select, onCancel, onChange, onEditing, onSubmit } =
    useEditorState(value, props);
  useImperativeHandle(parentRef, () => ({
    dataset: ref.current.dataset,
    edit: (select) => {
      onEditing({ target: ref.current, editing: true, select });
    },
    focus: () => ref.current && ref.current.focus(),
    blur: () => ref.current && ref.current.blur(),
    addEventListener: (...args) =>
      ref.current && ref.current.addEventListener(...args),
    removeEventListener: (...args) =>
      ref.current && ref.current.removeEventListener(...args),
  }));

  useEffect(
    function onValueChange() {
      setText(ref.current, value || placeholder);
    },
    [value, placeholder]
  );

  useEffect(
    function onEndEditing() {
      if (!editing) {
        setText(ref.current, value || placeholder);
      }
    },
    [editing, value, placeholder]
  );

  useEffect(
    function onEditing() {
      if (editing) {
        if (!value && placeholder) {
          setText(ref.current, '');
        }
        positionCursor(ref.current, select);
        ref.current.focus();
      }
    },
    [editing, select, value, placeholder]
  );

  useEffect(
    function onFocus() {
      if (focus) {
        ref.current.focus();
      }
    },
    [focus]
  );

  const editingKeyDown = (e) => {
    switch (e.key) {
      case 'Enter':
        e.stopPropagation();
        e.preventDefault();
        onSubmit({ target: e.target, method: 'enterKey' });
        onEditing({ target: e.target, editing: false, method: 'enterKey' });
        break;
      case 'Escape':
        e.stopPropagation();
        e.preventDefault();
        onCancel({ target: e.target });
        onEditing({ target: e.target, editing: false, method: 'escapeKey' });
        break;
      default:
        break;
    }
  };

  const viewingKeyDown = async (e) => {
    if (e.metaKey && e.key === 'c') {
      const selection = window.getSelection();
      if (selection.isCollapsed) {
        await navigator.clipboard.writeText(value);
      }
      return;
    }

    if (disabled) return;

    if (e.metaKey && e.key === 'v') {
      e.stopPropagation();
      const v = await navigator.clipboard.readText();
      if (v) {
        onSubmit({ target: e.target, method: 'paste' }, v);
      }
    } else if (!e.altKey && !e.metaKey) {
      switch (e.key) {
        case 'Enter':
          e.stopPropagation();
          e.preventDefault();
          positionCursor(false);
          onEditing({ target: e.target, editing: true, select: false });
          break;
        case 'Backspace':
        case 'Delete':
          // intentional passthrough
          e.stopPropagation();
          e.preventDefault();
          onSubmit({ target: e.target, method: 'deleteKey' }, null);
          break;
        default:
          if (e.key.length === 1) {
            e.stopPropagation();
            e.preventDefault();
            if (ref.current) {
              ref.current.innerText = e.key;
            }
            positionCursor(false);
            onEditing({ target: e.target, editing: true, select: false });
            onChange({ target: e.target, value: e.key });
          }
          break;
      }
    }
  };

  const onKeyDown = (e) => {
    if (editing) {
      editingKeyDown(e);
    } else {
      viewingKeyDown(e);
    }
  };

  const onInput = (e) => {
    onChange({ target: e.target, value: e.target.innerText });
  };

  const onBlur = (e) => {
    if (editing) {
      onSubmit({ target: e.target, method: 'blur' });
      onEditing({ target: e.target, editing: false, method: 'blur' });
    }
  };

  const onDoubleClick = (e) => {
    if (!editing && !disabled) {
      e.preventDefault();
      e.stopPropagation();
      onEditing({ target: e.target, editing: true, select: true });
    }
  };

  const onClick = (e) => {
    if (!editing && !disabled && singleClick) {
      e.preventDefault();
      e.stopPropagation();
      onEditing({ target: e.target, editing: true, select: false });
    }
  };

  const passProps = omit(props, [
    'onCancel',
    'onChange',
    'onEditing',
    'onSubmit',
    'startEditing',
    'startFocussed',
    'startSelected',
    'children',
    'className',
  ]);

  const className = classnames(
    props.className,
    !editing && !value && placeholder ? 'placeholder' : null
  );

  return (
    <div
      className={className}
      ref={ref}
      role={disabled ? undefined : 'textbox'}
      tabIndex={canFocus ? 0 : undefined}
      contentEditable={editing}
      onBlur={onBlur}
      onInput={onInput}
      onDoubleClick={onDoubleClick}
      onClick={onClick}
      onKeyDown={onKeyDown}
      {...passProps}
    />
  );
}

// eslint-disable-next-line no-func-assign
Editor = forwardRef(Editor);
Editor.displayName = 'Editor';

export default Editor;
