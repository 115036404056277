import React, { Fragment, useState } from 'react';

import Button from '<src>/components/Button';
import GoogleConnectButton from '<src>/components/GoogleConnectButton';
import * as colors from '<components>/colors';

import { ConnectActions } from './styles';
import CredentialPickerPane from '<src>/sections/data/pages/TablesetList/CredentialPickerPane';
import { Icon } from 'semantic-ui-react';

export default function GoogleSpreadsheetDeployment({
  authed = false,
  isLastStep,
  tablesetDeployment,
  onDoOAuth,
  onUseExisting,
  userCreds = [],
  onSetCreds,
}) {
  const [activeCred, setActiveCred] = useState(undefined);

  if (tablesetDeployment.useExisting) {
    return (
      <div>
        <p>
          The Google Spreadsheet used by this model has been deployed
          previously. Click "{isLastStep ? 'Deploy' : 'Next'}" to use the
          existing data (recommended) or click "Create a New Copy" to use a
          fresh copy for this deployment.
        </p>
        <ConnectActions>
          <Button
            borderColor={colors.gray_border}
            onClick={() => onUseExisting(tablesetDeployment, null)}
          >
            Create a New Copy
          </Button>
        </ConnectActions>
      </div>
    );
  }

  return (
    <div>
      <p>
        This model includes a{' '}
        <a
          rel="noreferrer noopener"
          target="_blank"
          title="Open spreadsheet"
          href={tablesetDeployment.sourceURL}
        >
          Google Spreadsheet
        </a>{' '}
        that contains sample data and useful presentation views.
      </p>

      {userCreds.length > 1 ? (
        <Fragment>
          <p>
            Select user account below to authorize Numbrz to create a copy of
            the spreadsheet in your Google Drive:
          </p>
          <ConnectActions>
            <CredentialPickerPane
              credentials={userCreds}
              selectedItem={undefined}
              setActiveCred={setActiveCred}
              connectorName={'google'}
              width="100%"
            />

            <Button
              onClick={() => {
                if (activeCred) {
                  onSetCreds(tablesetDeployment, {
                    credentialID: activeCred.ID,
                  });
                }
              }}
              disabled={tablesetDeployment.creds || !activeCred}
              iconPosition="right"
              icon={tablesetDeployment.creds ? <Icon name="checkmark" /> : null}
            >
              {tablesetDeployment.creds ? 'Authorized' : 'Authorize'}
            </Button>
          </ConnectActions>
        </Fragment>
      ) : (
        <Fragment>
          <p>
            Click below to authorize Numbrz to create a copy of the spreadsheet
            in your Google Drive:
          </p>
          <ConnectActions>
            <GoogleConnectButton
              onClick={() => onDoOAuth('google')}
              connected={authed}
            />
          </ConnectActions>
        </Fragment>
      )}
    </div>
  );
}
