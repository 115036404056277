/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx } from '@emotion/react';

import styledTable from './styles';

const Head = (props) => <thead {...props} css={styledTable.head} />;

export default Head;
