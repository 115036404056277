import React from 'react';
import fp from 'lodash/fp';
import { Switch, Route, useHistory, useRouteMatch } from 'react-router-dom';
import { useQuery, useMutation } from '<src>/apollo/client';

import { Elements } from '@stripe/react-stripe-js';
import WaveSpinner from '<components>/WaveSpinner';
import BillingPlans from './BillingPlans';
import Invoices from './Invoices';
import PaymentMethods from './PaymentMethods';
import BillingOverview from './Overview';
import NavTabBar from '<components>/NavTabBar';
import Button from '<src>/components/Button';
import { PageWrapper } from './styles';
import NotFound from '<components>/Routes/NotFound';
import getFeatures from '<src>/components/Feature/getFeatures.gql';
import { loadStripe } from '@stripe/stripe-js';
import stripeKey from '<src>/stripeAuth';

import {
  GetCustomerCardPaymentMethods,
  GetCustomerInformation,
  GetProductsAndPrices,
  CancelCustomerSubscription,
  CreateStripeCustomer,
  CreateOrgAndAddMember,
  CreateCustomerSubscription,
  UpdateCustomerSubscription,
  ChangeStripeCustomerNumbrzAccount,
} from '../../queries';
import { SetAccountToStripeProduct } from '<sections>/numbrz-admin/queries';

import { GetViewer } from '<sections>/projects/queries';

const stripePromise = loadStripe(stripeKey);

function getNavItems(match, userType, isUserAdmin, isAccountDeleted) {
  const defaultOptions =
    userType === 'Org'
      ? [
          {
            path: `${match.url}`,
            to: `${match.url}`,
            title: 'Overview',
            isActive: (innerMatch, location) => {
              return location.pathname === match.url;
            },
          },
        ]
      : [];
  return isAccountDeleted
    ? defaultOptions
    : [
        ...defaultOptions,

        ...(isUserAdmin && userType === 'Org'
          ? [
              {
                path: `${match.url}/${
                  userType === 'User' ? 'personal' : 'team'
                }-plans`,
                title: 'Manage Plan',
              },
              { path: `${match.url}/invoices`, title: 'Invoices' },
              {
                path: `${match.url}/payment-methods`,
                title: 'Payment Methods',
              },
            ]
          : []),
        {
          path: `${match.url}/new-org`,
          title: 'Create Team',
          align: 'right',
          className: 'createTeam',
        },
      ];
}

export default function Billing({ isUserAdmin }) {
  const history = useHistory();
  const match = useRouteMatch();
  const { data: featureFlagsData, loading: loadingFeatures } = useQuery(
    getFeatures,
    {
      fetchPolicy: 'cache-first',
    }
  );

  const featureFlags = fp.getOr([], 'viewer.featureFlags', featureFlagsData);

  const {
    data: { viewer } = {},
    loading: loadingViewer,
    refetch: refetchViewer,
  } = useQuery(GetViewer);

  const {
    data: { customerInformation } = {},
    refetch: refetchStripeCustomerInfo,
    loading: loadingCustomerInformation,
  } = useQuery(GetCustomerInformation);

  const {
    data: { customerCardPaymentMethods } = {},
    loading: loadingCustomerCardPaymentMethods,
    refetch: refetchPaymentMethods,
  } = useQuery(GetCustomerCardPaymentMethods);

  const {
    data: { products = [], productPrices = [] } = {},
    loading: loadingProducts,
  } = useQuery(GetProductsAndPrices);

  let activeProductPrices = [];
  if (productPrices && productPrices.length > 0)
    activeProductPrices = productPrices.filter((p) => p.active);

  const [cancelCustomerSubscription] = useMutation(CancelCustomerSubscription);
  const [createStripeCustomer] = useMutation(CreateStripeCustomer);
  const [createOrgAndAddMember] = useMutation(CreateOrgAndAddMember);
  const [createStripeSubscription] = useMutation(CreateCustomerSubscription);
  const [updateCustomerSubscription] = useMutation(UpdateCustomerSubscription);
  const [setAccountToStripeProduct] = useMutation(SetAccountToStripeProduct);
  const [changeStripeCustomerNumbrzAccount] = useMutation(
    ChangeStripeCustomerNumbrzAccount
  );

  if (
    loadingFeatures ||
    loadingViewer ||
    loadingCustomerInformation ||
    loadingCustomerCardPaymentMethods ||
    loadingProducts
  )
    return <WaveSpinner />;

  // if account has been in deleted in stripe, show a message and hide everything else
  const isStripeAccountDeleted = fp.getOr(
    false,
    'deleted',
    customerInformation
  );

  const userType = fp.getOr(
    'User',
    'account.primaryAccount.__typename',
    viewer
  );

  const primaryAccount = fp.getOr({}, 'account.primaryAccount', viewer);
  // default plan for users vs teams
  const defaultPlanID =
    primaryAccount.__typename === 'User'
      ? 'prod_KrAXbMakikg79d'
      : 'prod_KrSdE7i0dZVWgK';
  const activePlanID = fp.getOr(
    undefined,
    'account.primaryAccount.stripeProductID',
    viewer
  );

  const defaultPlan = products.find((p) => p.id === defaultPlanID);
  const activePlan = products.find((p) => p.id === activePlanID);
  const activeSubscription = fp.getOr(
    undefined,
    'subscriptions[0]',
    customerInformation
  );

  /*
  Active Subscription Plan, could be different from Numbrz quotas plan depending on where the customer is in respect to downgrade plan process

  Revisit this once webhooks are implemented to manage stripe plan details
  */
  const activeSubscriptionPlan = fp.getOr(
    undefined,
    'items[0].plan.product',
    activeSubscription
  )
    ? products.find((p) => p.id === activeSubscription.items[0].plan.product)
    : {};
  const defaultPaymentMethod = fp.getOr(
    null,
    'invoiceSettings.defaultPaymentMethod',
    customerInformation
  );

  const stripeCustomerID = fp.getOr(undefined, 'id', customerInformation);

  const stripePlanCancelsAtEndOfCycle = fp.getOr(
    false,
    'cancel_at_period_end',
    activeSubscription
  );

  const stripePlanCancelDate = fp.getOr(
    undefined,
    'current_period_end',
    activeSubscription
  );

  const stripePlanWillChangeAtEndOfCycle =
    primaryAccount.stripeProductID &&
    fp.getOr(undefined, 'items[0].plan.product', activeSubscription)
      ? primaryAccount.stripeProductID !==
        fp.getOr(undefined, 'items[0].plan.product', activeSubscription)
      : false;

  const handlePlanChangeNavigate = () => {
    refetchStripeCustomerInfo();
    refetchPaymentMethods();
    refetchViewer();
    history.push('/account/billing');
  };

  const isAdmin = () => {
    if (viewer) {
      const membership = viewer.account.membership.find(
        (m) => m.account.ID === primaryAccount.ID
      );

      if (membership && membership.roles.includes('Administrator')) return true;
    }

    return false;
  };

  return (
    <Elements stripe={stripePromise}>
      <PageWrapper>
        <NavTabBar
          navigation={getNavItems(
            match,
            userType,
            isUserAdmin,
            isStripeAccountDeleted
          )}
          additionalStyles={`
          width: 100%;
          padding-left: 50px;
        `}
        />
        <Switch>
          <Route path={`${match.url}/new-org`}>
            <BillingPlans
              featureFlags={featureFlags}
              viewer={viewer}
              history={history}
              isUserAdmin={isUserAdmin}
              updateCustomerSubscription={updateCustomerSubscription}
              setAccountToStripeProduct={setAccountToStripeProduct}
              cancelCustomerSubscription={cancelCustomerSubscription}
              createOrgAndAddMember={createOrgAndAddMember}
              createStripeCustomer={createStripeCustomer}
              changeStripeCustomerNumbrzAccount={
                changeStripeCustomerNumbrzAccount
              }
              createStripeSubscription={createStripeSubscription}
              products={products}
              productPrices={activeProductPrices}
              customerCardPaymentMethods={customerCardPaymentMethods}
              activeSubscription={activeSubscription}
              activePlanID={activePlanID}
              primaryAccount={primaryAccount}
              defaultPlanID={defaultPlanID}
              defaultPlan={defaultPlan}
              activePlan={activePlan}
              defaultPaymentMethod={defaultPaymentMethod}
              stripeCustomerID={stripeCustomerID}
              stripePlanCancelsAtEndOfCycle={stripePlanCancelsAtEndOfCycle}
              stripePlanCancelDate={stripePlanCancelDate}
              stripePlanWillChangeAtEndOfCycle={
                stripePlanWillChangeAtEndOfCycle
              }
              activeSubscriptionPlan={activeSubscriptionPlan}
              onPlanChangeNavigate={handlePlanChangeNavigate}
            />
          </Route>
          <Route
            path={[`${match.url}/personal-plans`, `${match.url}/team-plans`]}
          >
            {() =>
              isAdmin() ? (
                <BillingPlans
                  featureFlags={featureFlags}
                  viewer={viewer}
                  history={history}
                  isUserAdmin={isUserAdmin}
                  updateCustomerSubscription={updateCustomerSubscription}
                  setAccountToStripeProduct={setAccountToStripeProduct}
                  cancelCustomerSubscription={cancelCustomerSubscription}
                  createOrgAndAddMember={createOrgAndAddMember}
                  createStripeCustomer={createStripeCustomer}
                  changeStripeCustomerNumbrzAccount={
                    changeStripeCustomerNumbrzAccount
                  }
                  createStripeSubscription={createStripeSubscription}
                  products={products}
                  productPrices={activeProductPrices}
                  customerCardPaymentMethods={customerCardPaymentMethods}
                  activeSubscription={activeSubscription}
                  activePlanID={activePlanID}
                  primaryAccount={primaryAccount}
                  defaultPlanID={defaultPlanID}
                  defaultPlan={defaultPlan}
                  activePlan={activePlan}
                  defaultPaymentMethod={defaultPaymentMethod}
                  stripeCustomerID={stripeCustomerID}
                  stripePlanCancelsAtEndOfCycle={stripePlanCancelsAtEndOfCycle}
                  stripePlanCancelDate={stripePlanCancelDate}
                  stripePlanWillChangeAtEndOfCycle={
                    stripePlanWillChangeAtEndOfCycle
                  }
                  activeSubscriptionPlan={activeSubscriptionPlan}
                  onPlanChangeNavigate={handlePlanChangeNavigate}
                />
              ) : (
                <NotFound />
              )
            }
          </Route>
          {isAdmin() ? (
            <Route path={`${match.url}/invoices`}>
              <Invoices customerInformation={customerInformation} />
            </Route>
          ) : null}
          {isAdmin() ? (
            <Route path={`${match.url}/payment-methods`}>
              <PaymentMethods
                customerInformation={customerInformation}
                customerCardPaymentMethods={customerCardPaymentMethods}
              />
            </Route>
          ) : null}
          <Route path={`${match.url}/`}>
            <BillingOverview
              isUserAdmin={isUserAdmin}
              customerInformation={customerInformation}
              customerCardPaymentMethods={customerCardPaymentMethods}
              isAccountDeleted={isStripeAccountDeleted}
              activeSubscription={activeSubscription}
              primaryAccount={primaryAccount}
              defaultPlan={defaultPlan}
              activePlan={activePlan}
              defaultPaymentMethod={defaultPaymentMethod}
              stripeCustomerID={stripeCustomerID}
              stripePlanCancelsAtEndOfCycle={stripePlanCancelsAtEndOfCycle}
              stripePlanCancelDate={stripePlanCancelDate}
              stripePlanWillChangeAtEndOfCycle={
                stripePlanWillChangeAtEndOfCycle
              }
              activeSubscriptionPlan={activeSubscriptionPlan}
              productPrices={activeProductPrices}
              products={products}
              featureFlags={featureFlags}
            />
          </Route>
        </Switch>
        <Button.Intercom
          title="Have Questions?"
          onClick={(e) => {
            e.preventDefault();

            window.Intercom('show');
          }}
        />
      </PageWrapper>
    </Elements>
  );
}
