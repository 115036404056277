import React from 'react';
import { Dropdown } from 'semantic-ui-react';

import { opLabels } from './api';

export default function ArithmeticLabel({ api, widget }) {
  if (!api.updateOp) {
    return opLabels[widget.config.op];
  }

  const options = [
    { value: 'Add', text: 'Sum' },
    { value: 'Subtract', text: 'Subtraction' },
    { value: 'Multiply', text: 'Multiplication' },
    { value: 'Divide', text: 'Division' },
  ];

  return (
    <Dropdown
      options={options}
      value={widget.config.op}
      onChange={(e, data) => api.updateOp(widget, data.value)}
    />
  );
}
