import styled from '@emotion/styled';
import * as colors from '<components>/colors';

const TypeAheadDropDown = styled('div')`
  position: relative;
  width: 100%;
  padding: 6px 6px;
  border: 1px solid ${colors.gray_border};
  border-radius: 15px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: ${colors.gray1};
  input {
    width: 100%;
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.73);

    box-sizing: border-box;
    outline: none;
  }
  &.active {
    border: 1px solid ${colors.gray1};
    svg,
    input {
      color: ${colors.gray1};
    }
  }
  ul::before {
    content: '';
  }
  ul {
    width: 100%;
    position: absolute;
    top: 29px;
    left: 0;
    background: white;
    border-radius: 6px;
    list-style-type: none;
    text-align: left;
    margin: 0;
    padding: 0;
    border: 1px solid ${colors.gray_border};
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
  li {
    padding: 5px 10px;
    cursor: pointer;
  }

  li:hover {
    background: ${colors.active_blue};
    text-decoration: none;
  }
  svg {
    font-size: 20px;
  }
`;

export default TypeAheadDropDown;
