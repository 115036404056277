import React from 'react';
import styled from '@emotion/styled';

import Button from '<components>/Button';
import Dialog from '<components>/Dialog';
import { StyledScrollbar } from '<src>/components/NumbrzPageComponents';
import { tableLabel } from '../../TablesetPage/util';
import ConnectTablesPane from '../ConnectTablesPane';

const StyledDialog = styled(Dialog)`
  width: 80%;
  height: 80%;
`;

const StyledBody = styled(Dialog.Body)`
  overflow: auto;
  margin-bottom: 20px;
  ${StyledScrollbar}
`;

const StyledFooter = styled(Dialog.Footer)`
  border-top: none;
`;

export default function ConnectTablesDialog({
  visible,
  onClose,
  tablesetState,
}) {
  const { tableset } = tablesetState;
  const { ID } = tableset;

  const tablesHeader = tableLabel(tableset.source, {
    plural: true,
    titleCase: true,
  });

  return (
    <StyledDialog visible={!!visible} onRequestClose={onClose}>
      <Dialog.Header>
        <Dialog.Headline>{`Connect ${tablesHeader}`}</Dialog.Headline>
        <Button.DialogClose onClick={onClose} />
      </Dialog.Header>
      <StyledBody>
        <ConnectTablesPane tablesetID={ID} />
      </StyledBody>
      <StyledFooter>
        <Button id="create" onClick={onClose}>
          Close
        </Button>
      </StyledFooter>
    </StyledDialog>
  );
}
