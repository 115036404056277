import { compose } from 'recompose';
import withTag, { Component } from '<components>/hoc/tag';
import withClassName from '<components>/hoc/className';
import withDisplayName from '<components>/hoc/displayName';

import styledTable from '../styles';

export default compose(
  withTag('div'),
  withClassName(styledTable.control.actions),
  withDisplayName('ControlActions')
)(Component);
