import React from 'react';
import { getDisplayName, hoistStatics } from 'recompose';

import { useAuth } from '<src>/auth';

const withUserEmail = (BaseComponent) => {
  const name = getDisplayName(BaseComponent);

  function WithUserEmail(props) {
    const { user } = useAuth();
    const userEmail = user ? user.email : null;
    return <BaseComponent {...props} userEmail={userEmail} />;
  }

  WithUserEmail.BaseComponent = BaseComponent;
  WithUserEmail.displayName = `withUserEmail(${name})`;

  return WithUserEmail;
};

export default hoistStatics(withUserEmail);
