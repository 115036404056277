import styled from '@emotion/styled';

import * as colors from '<components>/colors';
import Card from '<components>/Card';

const ignoreSsrWarning =
  '/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */';

export const ConfigList = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const ConfigItem = styled('div')`
  width: 100%;
  display: flex;
  flex-direction;
  align-items: baseline;
  padding: 2px 2px 2px 9px;
  border: 1px solid ${colors.gray3};
  &:not(:first-child)${ignoreSsrWarning} {
    border-top: 0px solid transparent;
  }
`;

export const AddConfigItem = styled(ConfigItem)`
  border-color: ${colors.blue4};
  border-radius: 0.28571429rem;
  margin-bottom: 3px;
`;

const StepCard = styled(Card)`
  width: 100%;
  height: fit-content;
  padding: 0;
  ${(props) => props.zIndex && 'z-index: 1'};
  ${(props) => props.isInput && `border: 1px solid ${colors.blue6}`};
  ${(props) => props.isOutput && `border: 1px solid ${colors.green3}`};
  ${(props) => props.isError && `border: 1px solid ${colors.red1d}`};
  ${(props) => props.isYellow && `border: 1px solid ${colors.yellow3}`};
  ${(props) => props.margin && `margin: ${props.margin}`};
`;
export { StepCard as Card };

export const Header = styled('div')`
  display: flex;
  & > :first-child${ignoreSsrWarning} {
    flex: 1;
  }
  color: ${colors.gray1};
  padding: ${(props) =>
    props.padding ? props.padding : '10px 10px 10px 15px'};
  background: ${colors.gray5};

  ${(props) =>
    props.isInput &&
    `background: ${colors.blue7}; border-bottom: 1px solid ${colors.blue6};`};
  ${(props) =>
    props.isOutput &&
    `background: ${colors.green4}; border-bottom: 1px solid ${colors.green3};`};
  ${(props) =>
    props.isYellow && {
      background: colors.yellow7,
      borderBottom: `1px solid ${colors.yellow3}`,
    }};
  ${(props) => props.isError && `background: ${colors.red5}; `};
  align-items: center;
  h3,
  h4,
  h5 {
    margin: 0;
    padding: 0;
    font-size: 13px;
  }
  border-bottom: ${(props) => {
    let color = colors.gray_border;
    if (props.isYellow) color = colors.yellow3;
    if (props.isInput) color = colors.blue6;
    if (props.isOutput) color = colors.green3;
    return props.last ? 'none' : `1px solid ${color}`;
  }};
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

export const Title = styled('div')`
  display: flex;
  flex-direction: column;
  h3,
  h4,
  h5 {
    margin: 0;
    padding: 0;
    font-size: 13px;
  }
`;

export const Subtitle = styled('div')`
  font-size: 0.9em;
  font-weight: 700;
  color: ${colors.gray2};
`;

export const Body = styled('div')`
  & > :first-child${ignoreSsrWarning} {
    margin-top: 0;
  }
  & > :last-child {
    margin-bottom: 0;
  }
  padding: 10px 15px;
`;

export const Toolbar = styled('div')`
  height: 20px;
  display: flex;
  align-items: baseline;
  margin-left: 15px;
`;
