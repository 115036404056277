import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import T from 'prop-types';
import FocusTrap from 'focus-trap-react';

import {
  Overlay,
  Container,
  Body,
  Buttons,
  Header,
  Headline,
  Footer,
} from './styles';

export default class Dialog extends Component {
  static Footer = Footer;
  static Body = Body;
  static Header = Header;
  static Headline = Headline;
  static Buttons = Buttons;

  static defaultProps = {
    root: document.body,
    visible: true,
  };

  static propTypes = {
    children: T.node.isRequired,
    onRequestClose: T.func.isRequired,
    root: T.instanceOf(Element),
  };

  state = {
    shouldScroll: false,
  };

  constructor(props) {
    super(props);
    this.portal = document.createElement('div');
  }

  bindRef = (el) => (this.el = el);

  componentWillMount() {
    this.layout();
    window.addEventListener('resize', this.layout);
    this.mountPortal();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.layout);
    this.portal && this.unmountPortal();
    cancelAnimationFrame(this.RAF_ID);
  }

  layout = () => {
    if (!this.el) return;

    const rect = this.el.getBoundingClientRect();
    const shouldScroll = rect.height >= window.innerHeight;

    if (shouldScroll !== this.state.shouldScroll) {
      this.setState({ shouldScroll });
    }

    this.RAF_ID = requestAnimationFrame(this.layout);
  };

  mountPortal = () => {
    this.props.root && this.props.root.appendChild(this.portal);
  };

  unmountPortal = () => {
    // this.props.root && this.props.root.removeChild(this.portal);
  };

  componentDidUpdate() {
    this.layout();
  }

  render() {
    const { children, onRequestClose, visible, ...rest } = this.props;
    delete rest.root;

    const OverlayComp = this.props.noOverlay ? Fragment : Overlay;

    if (!visible) return null;

    const trapOptions = {
      clickOutsideDeactivates: true,
      initialFocus: () => this.el,
      onDeactivate: onRequestClose,
    };

    return createPortal(
      <OverlayComp>
        <FocusTrap focusTrapOptions={trapOptions}>
          <Container
            ref={this.bindRef}
            scrolling={this.state.shouldScroll}
            {...rest}
          >
            {children}
          </Container>
        </FocusTrap>
      </OverlayComp>,
      this.portal
    );
  }
}
