import React from 'react';
import { Form } from 'semantic-ui-react';
import styled from '@emotion/styled';

import * as colors from '<components>/colors';
import FieldEditor from '<components>/FieldEditor';

const DelimLabel = styled('label')`
  display: inline-block;
  margin-right: 8px;
`;

const DelimEditor = styled(FieldEditor)`
  display: inline-block;
  transition: border-color 200ms 25ms ease-in-out;
  border-bottom: 1px solid transparent;
  outline: none;
  min-width: 30px;
  &:focus-within,
  &:hover {
    border-bottom-color: ${colors.blue2};
  }
  &.placeholder {
    font-style: italic;
  }
`;

export default function JoinSettings({ api, widget }) {
  const { delim, omitEmpty } = widget.config;

  return (
    <Form>
      <Form.Field>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <DelimLabel>Delimiter</DelimLabel>
        <DelimEditor
          singleClick
          value={delim}
          placeholder="None"
          onSubmit={({ value: delim }) =>
            api.updateJoinConfig(widget, { delim })
          }
        />
      </Form.Field>
      <Form.Checkbox
        checked={omitEmpty}
        onChange={() => {
          api.updateJoinConfig(widget, { omitEmpty: !omitEmpty });
        }}
        label="Omit Empty Values"
      />
    </Form>
  );
}
