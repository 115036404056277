import { gql } from '@apollo/client';

import { FunctionChangesFragment } from './fragments';
import useEditFunction, { optimisticFunctionChanges } from './useEditFunction';

const UpdateWidgetRow = gql`
  mutation UpdateWidgetRow(
    $funcID: CommonID!
    $widgetID: ID!
    $rowID: ID!
    $label: String
  ) {
    updateWidgetRowV3(
      funcID: $funcID
      widgetID: $widgetID
      rowID: $rowID
      label: $label
    ) {
      ...FunctionChangesFragment
    }
  }

  ${FunctionChangesFragment}
`;

export default function useUpdateWidgetRow() {
  const addRow = useEditFunction(UpdateWidgetRow, 'updateWidgetRowV3');
  return (widget, row, { label }) =>
    addRow({
      variables: {
        funcID: widget.funcID,
        widgetID: widget.ID,
        rowID: row.ID,
        label,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateWidgetRowV3: optimisticFunctionChanges({
          funcID: widget.funcID,
          templatesChanged: [
            {
              __typename: 'CellTemplate',
              ...row.normTemplate,
              label: label !== undefined ? label : row.normTemplate.label,
            },
          ],
        }),
      },
    });
}
