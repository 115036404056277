/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from '@emotion/react';

import { useState, useMemo } from 'react';
import T from 'prop-types';
import fp from 'lodash/fp';

import ErrorMessages from '<components>/ErrorMessages';
import { Icon } from 'semantic-ui-react';
import RunJobErrorDialog from '<sections>/jobs/components/RunJobErrorDialog';
import { RoundedContentWrapper } from '<src>/components/NumbrzVerticalEditor';
import { EmptyListContainer } from '<src>/components/NumbrzPageComponents';

import JobProgress from './Progress';
import JobTiming from './JobTiming';
import ExternalData from './ExternalData';
import StageStatus from './StageStatus';
import * as styles from './styles';
import WaveSpinner from '<src>/components/WaveSpinner';

Status.propTypes = {
  error: T.any,
  loading: T.bool.isRequired,
  status: T.string,
  startPolling: T.func.isRequired,
  stopPolling: T.func.isRequired,
  cancelJob: T.func.isRequired,
};

export default function Status({
  status,
  stageDetails = [],
  project,
  flowID,
  timing,
  extData,
  loading,
  started,
  ended,
  errMsg,
  externalResultURL,
}) {
  const [expanded, setExpanded] = useState(false);

  const flowBindingName = fp.getOr(null, '[0].bindingName', stageDetails);

  const stageStatuses = useMemo(() => {
    return stageDetails.map((stageStatus, idx) => (
      <StageStatus
        key={idx}
        idx={idx}
        project={project}
        flowID={flowID}
        stageStatus={stageStatus}
        last={idx === stageDetails.length - 1}
      />
    ));
  }, [flowID, project, stageDetails]);

  return loading ? (
    <WaveSpinner />
  ) : !status ? (
    <EmptyListContainer>
      <h4>No status found </h4>
    </EmptyListContainer>
  ) : (
    <RoundedContentWrapper maxWidth="800px">
      <RunJobErrorDialog
        visible={false}
        runResult={undefined}
        onClose={() => {}}
      />

      <JobProgress
        status={status}
        flowBindingName={flowBindingName}
        externalResultURL={externalResultURL}
        started={started}
        ended={ended}
        stageDetails={stageDetails}
      />

      {errMsg && <ErrorMessages issues={[{ message: errMsg }]} />}

      <ExternalData extData={extData} />
      <JobTiming timing={timing} />

      {status && (
        <styles.StatusContainer>
          <styles.StatusHeader>
            <styles.SectionHdrBtn onClick={() => setExpanded((e) => !e)}>
              Stages
              <Icon
                name={expanded ? 'caret up' : 'caret down'}
                title={expanded ? 'Hide' : 'Show'}
              />
            </styles.SectionHdrBtn>
          </styles.StatusHeader>

          {expanded && stageStatuses}
        </styles.StatusContainer>
      )}
    </RoundedContentWrapper>
  );
}
