import { useContext } from 'react';

import { Selection } from './contexts';

const EMPTY = [];

export default function useActiveResultLinks() {
  const { activeItems } = useContext(Selection);

  let activeLinkVals = [];

  if (activeItems && activeItems.CELL) {
    const toID = activeItems.CELL.ID;
    const fromID = activeItems.CELL.sourceCellID;

    activeLinkVals = [
      {
        ID: `${fromID}-${toID}`,
        source: {
          ID: fromID,
          type: 'CELL',
        },
        target: {
          ID: toID,
          type: 'CELL',
        },
        status: 'OK',
      },
    ];
  }

  if (activeLinkVals.length === 0) return EMPTY;

  return activeLinkVals;
}
