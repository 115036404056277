import React, { PureComponent } from 'react';
import T from 'prop-types';
import { Icon } from 'semantic-ui-react';
import styled from '@emotion/styled';

import Button from '<src>/components/Button';

import * as colors from '<components>/colors';

const Container = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 3px 3px 3px 10px;
  margin-bottom: 3px;
  border: 1px solid ${colors.gray3};
  border-radius: 0.28571429rem;
  background-color: white;
  ${(props) => props.additionalStyles && props.additionalStyles};
`;

const StyledInput = styled('input', {
  shouldForwardProp: (prop) => prop !== 'innerRef',
})`
  background: white;
  flex: 3;
  border: none !important;
  margin: 0 10px 0 0 !important;
  padding: 0 !important;
  &:focus {
    border-bottom: 1px solid ${colors.blue4} !important;
    border-radius: 0 !important;
    outline: none;
  }
  &::placeholder {
    color: ${colors.gray2};
  }
`;

export default class TagInput extends PureComponent {
  static propTypes = {
    addTag: T.func.isRequired,
    defaultValue: T.string,
  };

  static defaultProps = { defaultValue: undefined };

  state = { label: '' };

  get isValid() {
    return this.state.label;
  }

  handleAddTag = () => {
    if (!this.isValid) return;
    const { label } = this.state;
    this.setState({ label: '' }, async () => {
      await this.props.addTag(label);
    });
  };

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      this.handleAddTag(() => this.textRef.current.focus());
    }
  };

  textRef = React.createRef();
  buttonRef = React.createRef();

  render() {
    return (
      <Container additionalStyles={this.props.additionalStyles}>
        <StyledInput
          ref={this.textRef}
          value={this.state.label}
          onChange={(e) => this.setState({ label: e.target.value })}
          placeholder="Add Tag"
          onKeyDown={this.handleKeyDown}
        />

        <Button.IconBtn
          ref={this.buttonRef}
          icon={<Icon name="plus" title="Add Tag" />}
          disabled={!this.isValid}
          onClick={() => this.handleAddTag()}
          onKeyDown={this.handleKeyDown}
          baseColor="transparent"
          contentColor={colors.gray2b}
          activeColor={colors.gray7}
          contrastColor="transparent"
          bgHoverColor={colors.gray7}
        />
      </Container>
    );
  }
}
