import { Icon } from 'semantic-ui-react';
import styled from '@emotion/styled';
import * as colors from '<components>/colors';

export const MenuSidebar = styled('div')`
  position: fixed;
  height: 100%;
  right: 0;
  top: 60px;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 40%;
  min-width: 300px;
  max-width: 500px;
  background-color: ${colors.gray1b};
  border-left: 1px solid ${colors.gray2b};
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  z-index: 100;
  color: white;
`;

export const ContentWrapper = styled('div')`
  display: block;
  height: 100%;
  margin-top: 10px;
  padding-bottom: 60px;
  overflow-y: auto;
`;

export const ListWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 15px 30px 20px 30px;
  h4 {
    margin: 0 0 10px 0;
    padding: 0 0 0 15px;
    font-size: 0.9em;
  }
`;

export const ItemList = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding-right: 5px;

  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${colors.gray2b};
  }
`;

export const ListItem = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  padding: 10px 15px;
  cursor: pointer;
  background-color: ${colors.gray2b};
  border-radius: 2px;
  color: white;
`;

export const ItemTitle = styled('span')`
  font-size: 1em;
  font-weight: bold;
`;

export const CurrentTitle = styled('span')`
  font-size: 1em;
  font-weight: bold;
  margin: 20px auto 0 auto;
`;

export const ItemDescription = styled('span')`
  font-size: 0.9em;
`;

export const ArticleContainer = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 30px 80px 10px;

  overflow-y: auto;
  .intercom-container {
    text-align: center;
  }
  img {
    max-width: 100%;
  }
  p {
    font-size: 1em;
    margin: 5px;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 1em;
  }
  ::-webkit-scrollbar {
    width: 6px;
  }
  a.intercom-h2b-button {
    font-size: 0.8em;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border: 1px solid ${colors.gray2b};
    border-radius: 4px;
    color: white;
    margin: 5px;
    text-transform: uppercase;
    :hover {
      background: ${colors.gray2b};
    }
  }
`;

export const Navbar = styled('div')`
  min-height: 45px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
  border-bottom: 1px solid ${colors.gray2b};
  border-top: 1px solid ${colors.gray2b};
`;

export const SearchBarWrapper = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  margin-top: 20px;
  input {
    border-radius: 45px;
  }
`;

export const CloseBtn = styled(Icon)`
  cursor: pointer;
  height: unset !important;
`;

export const BackBtn = styled('div')`
  cursor: pointer;
  display: flex;
`;

export const Footer = styled('div')`
  position: sticky;
  min-height: 50px;
  bottom: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${colors.gray2b};
  background-color: ${colors.gray1b};
  font-size: 1em;
`;

export const TeamChatBtn = styled('button')`
  background: none;
  outline: none;
  border: none;
  padding: 0;
  margin: 0 0 0 5px;
  color: ${colors.blue1};
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Highlighted = styled('span')`
  position: relative;
  display: inline-block;

  &:after {
    position: absolute;
    content: ' ';
    left: 0;
    right: 0;
    bottom: -2px;
    height: 2px;
    background: ${colors.blue1};
    transition: all 250ms ease-in;
  }
`;
