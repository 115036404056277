import React, { forwardRef, useMemo, useState } from 'react';

import { compare } from '<src>/utils/text';
import { useGoAndReturnTo } from '<components>/LinkAndReturnTo';
import SectionedDropdown from '<components>/SectionedDropdown';
import AddNameDialog from '<components>/AddNameDialog';

const toOpt = (baseURL) => (f) => ({
  ...f,
  value: f.ID,
  text: f.name,
  location: baseURL ? `${baseURL}/${f.ID}` : undefined,
});

function FunctionPicker(
  {
    baseURL,
    functions = [],
    placeholder = 'Select Function',
    returnTo,
    value,
    onAdd: onAddProp,
    onChange,
    ...props
  },
  parentRef
) {
  const goAndReturnTo = useGoAndReturnTo();
  const [dialogVisible, setDialogVisible] = useState(false);
  const toOptResolved = useMemo(() => toOpt(baseURL), [baseURL]);
  const options = useMemo(
    () =>
      functions.map(toOptResolved).sort((f1, f2) => compare(f1.name, f2.name)),
    [toOptResolved, functions]
  );
  const onAdd = useMemo(() => {
    if (!onAddProp) return null;
    return () => setDialogVisible(true);
  }, [onAddProp]);
  const doAdd = useMemo(() => {
    if (!onAddProp) return null;
    return async (name, e) => {
      const func = await onAddProp(e, { name });
      const opt = toOptResolved(func);
      goAndReturnTo(opt.location, returnTo);
    };
  }, [goAndReturnTo, onAddProp, returnTo, toOptResolved]);

  return (
    <>
      <SectionedDropdown
        ref={parentRef}
        placeholder={placeholder}
        returnTo={returnTo}
        rootOptions={options}
        value={value}
        onAdd={onAdd}
        itemLabel="Function"
        onChange={onChange}
        {...props}
      />
      <AddNameDialog
        objType="Function"
        onClose={() => setDialogVisible(false)}
        onCreate={doAdd}
        visible={dialogVisible}
      />
    </>
  );
}

// eslint-disable-next-line no-func-assign
FunctionPicker = forwardRef(FunctionPicker);
FunctionPicker.displayName = 'FunctionPicker';
export default FunctionPicker;
