import React from 'react';

export default function LinkLine({
  sourcePos,
  targetPos,
  slackX = 1.5,
  slackY = 0.5,
  linePull = 60,
  distScale = 10,
  isError,
}) {
  const startPoint = {
    x: sourcePos.x + sourcePos.width,
    y: sourcePos.y + sourcePos.height / 2,
  };

  const endPoint = {
    x: targetPos.x,
    y: targetPos.y + targetPos.height / 2,
  };

  const distX = endPoint.x - startPoint.x || 1; // Avoid 0
  const distXAbs = Math.abs(distX);
  const dirX = Math.sign(distX);

  const distY = endPoint.y - startPoint.y || 1; // Avoid 0
  const distYAbs = Math.abs(distY);
  const dirY = Math.sign(distY);

  let yInvert = 1;
  let yExpand = 0;

  if (distYAbs < 30) {
    if (dirX === 1) {
      slackX = 0;
      slackY = 0;
    } else {
      yExpand = linePull / 2;
      yInvert = -1;
    }
  } else if (dirX === -1) {
    yExpand = distXAbs / distScale;
  }

  const deltaX = linePull * slackX + distXAbs / distScale;
  const deltaY = linePull * slackY + yExpand;

  const hx1 = startPoint.x + deltaX;
  const hy1 = startPoint.y + deltaY * dirY * yInvert;

  const hx2 = endPoint.x - deltaX;
  const hy2 = endPoint.y - deltaY * dirY;

  return (
    <g className={isError ? 'error' : undefined}>
      <path
        markerStart={`url(${isError ? '#dot-error' : '#dot'})`}
        markerEnd={`url(${isError ? '#arrow-error' : '#arrow'})`}
        d={`
          M ${startPoint.x} ${startPoint.y}
          C ${hx1} ${hy1} ${hx2} ${hy2} ${endPoint.x} ${endPoint.y}
        `}
      />
    </g>
  );
}
