import React, { useEffect, useRef, useState } from 'react';
import Dialog from '<components>/Dialog';
import Button from '<components>/Button';
import TextInput from '<components>/TextInput';
import JobsBlockPane from '../JobsBlockPane';

import styled from '@emotion/styled';

const StyledDialog = styled(Dialog)`
  width: 75%;
  max-width: 800px;
  min-width: 400px;
`;

const StyledHeadline = styled(Dialog.Headline)`
  padding-left: 10px;
`;

const StyledTextInput = styled(TextInput)`
  width: 100%;
  margin: 10px 5px;
`;

export default function AddBlockDialog({
  onRequestClose,
  beforeBlockKey,
  onAddBlock,
  visible,
}) {
  const [name, setName] = useState('New Block');
  const [blockJobs, setBlockJobs] = useState([]);

  const titleInput = useRef();

  useEffect(() => {
    if (!visible) {
      setName('New Block');
    } else {
      titleInput.current.focus();
      titleInput.current.select();
    }
  }, [visible]);

  const onAdd = (e) => {
    if (!isValid()) return;
    onAddBlock(e, {
      beforeBlockKey,
      block: {
        name: name.trim(),
        addJobs: blockJobs,
      },
    });
  };

  const isValid = () => name && name.trim();

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (isValid()) return onAdd(e);
    }
  };

  return (
    <StyledDialog onRequestClose={onRequestClose} visible={visible}>
      <Dialog.Header>
        <StyledHeadline>Add block</StyledHeadline>
      </Dialog.Header>
      <Dialog.Body>
        <StyledTextInput
          ref={titleInput}
          name="name"
          onChange={(e) => setName(e.target.value)}
          value={name}
          placeholder="Block Title"
          onKeyDown={handleKeyDown}
        />
        <JobsBlockPane
          blockJobs={blockJobs}
          onChange={(selectedJobs) => setBlockJobs(selectedJobs)}
        />
      </Dialog.Body>
      <Dialog.Footer>
        <Button onClick={onRequestClose}>Cancel</Button>
        <Button onClick={onAdd}>Add</Button>
      </Dialog.Footer>
    </StyledDialog>
  );
}
