import React, { useCallback, useState } from 'react';

import { Selection, Select } from './contexts';

const noneItem = { type: Selection.NONE };

function itemsEqual(item1 = noneItem, item2 = noneItem) {
  return item1.ID === item2.ID && item1.type === item2.type;
}

function itemFromElement(el) {
  if (el) {
    const { id: ID, type } = el.dataset;
    if (type) {
      return { type, ID, target: el };
    }
  }
  return { ...noneItem, target: el };
}

function addParentItems(item, activeItems) {
  if (item.type !== Selection.NONE && !activeItems[item.type]) {
    activeItems[item.type] = item;
  }
  if (item.target) {
    addParentItems(itemFromElement(item.target.parentElement), activeItems);
  }
}

export default function SelectionManager(props) {
  const [{ activeItems }, setState] = useState({
    selection: { ...noneItem },
    activeItems: {},
  });

  const select = useCallback((newItem) => {
    return setState((state) => {
      if (itemsEqual(newItem, state.selection)) {
        return state;
      }

      const newActiveItems = {};
      addParentItems(newItem, newActiveItems);
      const change =
        Object.keys(newActiveItems).length === 0 ||
        !Object.keys(newActiveItems).every((type) =>
          itemsEqual(newActiveItems[type], state.activeItems[type])
        );
      if (change) {
        return { selection: newItem, activeItems: newActiveItems };
      }

      return state;
    });
  }, []);

  return (
    <Select.Provider value={select}>
      <Selection.Provider
        value={{
          activeItems,
        }}
      >
        {/* Watch for bubbled-up mouseup events */}
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div {...props} />
      </Selection.Provider>
    </Select.Provider>
  );
}
