import { gql } from '@apollo/client';

import { FunctionChangesFragment } from './fragments';
import useEditFunction, { optimisticFunctionChanges } from './useEditFunction';

const UpdateCellType = gql`
  mutation UpdateCellType(
    $funcID: CommonID!
    $cellID: ID!
    $coerceTo: CellType!
    $value: Any
    $dataType: CellType
  ) {
    updateCellTypeV3(
      funcID: $funcID
      cellID: $cellID
      coerceTo: $coerceTo
      value: $value
      dataType: $dataType
    ) {
      ...FunctionChangesFragment
    }
  }
  ${FunctionChangesFragment}
`;

export default function useUpdateCellType() {
  const updateCellType = useEditFunction(UpdateCellType, 'updateCellTypeV3');
  return (cell, { coerceTo, value, dataType }) =>
    updateCellType({
      variables: {
        funcID: cell.funcID,
        cellID: cell.ID,
        coerceTo,
        value,
        dataType,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateCellTypeV3: optimisticFunctionChanges({
          funcID: cell.funcID,
          cellsChanged: [
            {
              __typename: 'CellV3',
              ...cell,
              coerceTo,
              dataType: dataType ? dataType : cell.dataType,
              value: value !== undefined ? value : cell.value,
            },
          ],
        }),
      },
    });
}
