import fp from 'lodash/fp';
import React, { PureComponent, useContext } from 'react';
import T from 'prop-types';
import { getDisplayName, hoistStatics } from 'recompose';

import ErrorPage from '<src>/pages/ErrorPage';
import { reportError, isDisplayable } from '<src>/errors';

import DefaultErrorMessage from './ErrorMessage';

const ErrorContext = React.createContext(() => {});

export default class ErrorBoundary extends PureComponent {
  static defaultProps = {
    ErrorMessage: DefaultErrorMessage,
    getBaseProps: fp.noop,
    onError: fp.noop,
  };

  static propTypes = {
    ErrorComponent: T.any,
    children: T.oneOfType([T.node, T.func]),
    getBaseProps: T.func,
    onError: T.func,
  };

  state = { error: null };

  componentDidCatch(error, info) {
    this.reportError(error, { info, forceDisplay: true });
  }

  reportError = (error, { info, forceDisplay } = {}) => {
    let displayed = false;
    if (forceDisplay || isDisplayable(error)) {
      displayed = true;
      this.setState({ error });
    }
    reportError(error, { info, displayed });
  };

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <ErrorPage
          error={error}
          onClear={() => this.setState({ error: null })}
          {...this.props}
        />
      );
    }

    return (
      <ErrorContext.Provider value={this.reportError}>
        {typeof children === 'function' ? children() : children}
      </ErrorContext.Provider>
    );
  }
}

export const WithReportError = ErrorContext.Consumer;

export const withReportError = hoistStatics((BaseComp) => {
  const name = getDisplayName(BaseComp);

  class WithReportError extends PureComponent {
    render() {
      if (this.props.reportError) {
        return <BaseComp {...this.props} />;
      }
      return (
        <ErrorContext.Consumer>
          {(reportError) => (
            <BaseComp {...this.props} reportError={reportError} />
          )}
        </ErrorContext.Consumer>
      );
    }
  }

  WithReportError.BaseComponent = BaseComp;
  WithReportError.displayName = `withReportError(${name})`;

  return WithReportError;
});

export function useReportError() {
  return useContext(ErrorContext);
}
