import styled from '@emotion/styled';
import * as colors from '<components>/colors';
import { IconRunButton } from '<components>/NumbrzButtons';
import Button from '<src>/components/Button';
import Table from '<components>/Table';
import { TagItem } from '<components>/TagList';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export const InvoiceTable = styled(Table)`
  outline: none;
  box-shadow: none;
  border: none;
  padding-left: 5px;
`;

export const InvoiceHeaderRow = styled(Table.Row)`
  border: none;
  th.centered {
    text-align: center;
  }
  th {
    border: none;
    border-bottom: 1px solid ${colors.gray3};
    border-right: none !important;
    text-align: left;
    padding: 5px 10px 5px 0px;
  }
`;

export const InvoiceUpcomingRow = styled(Table.Row)`
  border: none;
  td.centered {
    text-align: center;
  }
  td {
    border: none;
    border-right: none !important;
    border-bottom: none !important;
    padding: 5px 10px 5px 0px;
  }
`;
export const InvoiceRow = styled(Table.Row)`
  border: none;
  border-bottom: 1px solid ${colors.gray3};
  td.centered {
    text-align: center;
  }
  td {
    border: none;
    border-bottom: 1px solid ${colors.gray3};
    border-right: none !important;
    padding: 5px 10px 5px 0px;
    background: white !important;
    text-align: left;
    i {
      font-size: 1.1em;
    }
    a {
      color: ${colors.blue1};
    }
    span {
      color: ${colors.green1};
    }
    span.error {
      color: ${colors.red1c};
    }
  }
`;

export const PageWrapper = styled('div')`
  width: 100%;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '1200px')};
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
`;

export const BillingWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px auto;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  padding-left: 50px;
`;

export const PaidPlanWrapper = styled('div')`
  width: 100%;
  background: ${colors.gray1b};
  color: white;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  border-radius: 4px;
  h2 {
    padding: 10px 20px;
    margin: 0;
    border-bottom: 1px solid white;
    font-size: 1em;
  }
`;

export const PlanGroupHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid white;
  padding: 15px 25px;
  span {
    font-size: 1.4em;
    font-weight: bold;
  }
  button {
    margin-top: 0;
  }
`;

export const PlanWrapperContent = styled('div')`
  display: flex;
  padding: 0px 0px 0px 10px;
`;

export const PackageDescription = styled('div')`
  flex: 2;
  padding: 15px;
  h2 {
    border-bottom: none;
    font-size: 1.3em;
    margin: 0;
    padding: 0;
  }
  h4 {
    font-size: 0.9em;
    margin: 0;
    padding: 0;
  }
`;

export const PlanList = styled('ul')`
  margin: 10px;
  padding: 0;
  list-style: none;
  li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    i {
      color: ${colors.blue1};
      margin-right: 5px;
    }
  }
`;

export const PlanBreakdown = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-left: 1px solid white;
  color: white;
  padding: 15px 15px 25px 15px;
  h2 {
    font-size: 1.5em;
    padding: 0;
    margin: 10px 0 0px 0;
    text-align: center;
    border-bottom: none;
  }
  span {
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 5px;
    i {
      color: ${colors.green1};
    }
  }
  div {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
  }
`;

export const ColWrapper = styled('div')`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
`;

export const Col = styled('div')`
  width: 49%;
  padding: 25px 30px;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${colors.gray3};
  background: white;
  border-radius: 4px;
  margin: 5px 0;
  h2 {
    font-weight: normal;
    font-size: 1.4em;
    padding: 10px 0;
    margin: 0;
  }
  button {
    text-transform: none;
  }
`;

export const ColFooter = styled('div')`
  button {
    margin-right: 10px;
  }
`;

export const StyledH2 = styled('h2')`
  font-weight: normal;
  font-size: 1.4em;
  padding: 10px 0 10px 20px;
  margin: 0;
  color: ${colors.gray1b};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const StyledH3 = styled('h3')`
  font-family: 'Helvetica Neue';
  font-weight: normal;
  font-size: 1.2em;
  padding: 10px 0 10px 20px;
  margin: 0;
  color: ${colors.gray1};
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    line-height: 2em;
    font-size: 0.5em;
  }
`;

export const Container = styled('div')`
  font-family: Kraftig, sans-serif;
  display: flex;
  flex-direction: column;
  width: 100%;

  border-radius: 4px;
  padding: 25px 30px;

  h2 {
    font-weight: normal;
    font-size: 1.4em;
    padding: 10px 0;
    margin: 0;
  }
  button {
    text-transform: none;
  }
  border: 1px solid ${colors.gray3};
  background: white;

  table {
    width: 100%;
  }
`;

export const TopContainer = styled(Container)`
  margin-top: 10px;
`;

export const ContainerFooter = styled('div')`
  margin-top: 20px;
  button {
    margin-right: 10px;
  }
`;

export const LatestInvoiceContainer = styled(Container)`
  margin: 10px 0 5px 0;
  min-height: unset;
`;

export const ProjectUsageContainer = styled('div')`
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 6px;

  background-color: ${colors.blue5};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const PaymentMethodList = styled('div')`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${colors.gray3};
  margin: 10px 0 20px 0;
`;

export const PaymentMethodListitem = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid ${colors.gray3};
`;
export const CardInfo = styled('div')`
  font-size: 1.1em;
  color: ${colors.gray1};
  display: flex;
  align-items: center;
  i {
    margin-right: 10px;
  }
  span {
    padding-left: 7px;
  }
`;

export const StyledIconButton = styled(IconRunButton)`
  margin-left: 5px;
`;

export const CardToolbar = styled('div')`
  display: flex;
  align-items: center;
  button {
    padding-left: 0;
  }
  button.expanded {
    padding-left: 6px;
  }
`;

export const DefaulLbl = styled('div')`
  font-size: 14px;
  color: ${colors.green2};
  letter-spacing: 1px;
  font-weight: 500;
  font-family: Lato, 'Helvetica';
  margin-left: 20px;
  text-transform: uppercase;
`;

export const ContentDivider = styled('div')`
  height: 30px;
`;

export const SubscriptionContainer = styled('div')`
  display: flex;
  justify-content: space-between;
`;
export const SubscriptionToolbar = styled('div')`
  display: flex;
  justify-content: space-between;
`;
export const SubscriptionDetails = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const PlanDescription = styled('p')`
  font-size: 1.1em;
`;

export const PlanDetailsList = styled('ul')`
  margin: 0;
  padding-left: 20px;
  li {
    font-size: 1.1em;
    margin: 5px 0;
  }
`;

export const InvoiceContentSeparator = styled('div')`
  border-top: 1px dotted ${colors.gray3};
  width: 100%;
  margin: 15px 0 20px 0;
`;

export const PlanStatusLabel = styled('div')`
  text-transform: capitalize;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 6px;

  font-size: 0.6em;
  border-radius: 4px;
  font-family: Lato, 'Helvetica';
  background-color: ${colors.red1b};
  color: white;

  i {
    font-size: 1.1em;
    height: unset;
  }
  ${(props) =>
    props.status === 'active' && {
      backgroundColor: colors.green2,
      marginLeft: '10px',
    }};

  ${(props) =>
    props.status === 'neutral' && {
      backgroundColor: colors.gray2,
      marginTop: '10px',
    }};
`;

export const StatusHeader = styled('h2')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
  }
  button {
    font-size: 0.6em;
  }
`;

export const ActivePlanHeader = styled('h2')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  div {
    display: flex;
  }
`;

export const PlanToolbar = styled('div')`
  display: flex;
  align-items: center;
  button {
    margin-right: 15px;
  }
`;

export const CancelButton = styled(Button)`
  height: 40px;
  margin-top: 20px;
  padding: 10px 30px;
  text-transform: none;
  background-color: ${colors.red1b};
  color: white;
  border: none;
  font-size: 1.1em;
  :hover {
    background-color: ${colors.red2b};
  }
`;

export const NewOrgMessage = styled('p')`
  color: ${colors.gray1b};
  font-size: 1.3em;
  text-align: center;
  margin: 20px 40px;

  button {
    outline: none;
    border: none;
    color: ${colors.blue1};
    background-color: transparent;
    padding: 0;
    :hover {
      cursor: pointer;
      color: ${colors.blue2};
      text-decoration: underline;
    }
  }
`;

export const InvoiceAmtContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 5px;
  h3 {
    margin: 0 0 5px 0px;
    padding: 0;
  }
`;

export const StatusDescription = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin: 15px 0;

  color: ${colors.red1b};
  border: 1px solid ${colors.red1b};
  border-radius: 4px;
  a {
    color: ${colors.blue1};
    font-size: 1.1em;
  }
  a:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const UnderlineHeader = styled('h2')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 45px;
  margin: 0 0 30px 0;
  padding: 5px 0px 0 50px;
  font-size: 14px;
  font-weight: bold;
  border-bottom: 1px solid ${colors.orange1};
  span {
    margin-top: 11px;
  }
`;

export const ExpirationChiclet = styled('div')`
  display: flex;
  flex-direction: row !important;
  color: ${colors.gray3};
  font-size: 1.1em;
  padding: 10px 0;
`;

export const ExpLabel = styled('span')``;

export const SettingsWrapper = styled('div')`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;

export const HorizontalCheckboxGroup = styled('div')`
  display: flex;
  .field {
    margin-right: 15px;
  }
  label {
    color: ${colors.gray1b} !important;
  }
  label:after {
    background-color: ${colors.gray1b} !important;
  }
`;

export const PlanPriceSubHdr = styled('span')`
  font-size: 13px;
  margin: 0 auto 10px auto !important;
`;

export const UserList = styled('div')`
  display: flex;
  flex-direction: column;
`;
export const UserContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 0 0px;
  margin-bottom: 10px;
  button {
    height: min-content;
  }
`;

export const Tag = styled(TagItem)`
  padding: 0px 7px 0 9px;
  margin: 0 5px;
  font-size: 11px;
`;

export const InputContent = styled('div')`
  & * {
    line-height: 1em !important;
  }
  max-height: ${(props) => `${props.maxLines}em`};
  overflow: hidden;
  word-break: break-word;
`;

export const TextArea = styled('textarea')`
  width: 100%;
  min-height: 60px;
  resize: vertical;
  border: 1px solid ${colors.gray_border};
  border-radius: 5px;
  padding: 5px;
  margin: 0;
  &:focus {
    outline: none;
    box-shadow: 0 0 1px 2px ${colors.blue3};
  }
`;

export const AppTabMenu = styled(Tabs)`
  padding-left: 70px;
  margin-top: 10px;
  min-height: unset;
  .MuiTabs-indicator {
    display: none;
  }
`;

export const AppTab = styled(Tab)`
  text-transform: none;
  min-height: unset;
  padding: 7px;
  &.Mui-selected {
    color: white;
    background: ${colors.orange1};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
`;

export const EmptyMsg = styled('div')`
  margin-left: 5px;
  font-size: 14px;
  color: ${colors.gray1};
`;
