import React from 'react';

import { isEmpty } from '<src>/utils/text';
import { StyledTextInput } from '<src>/sections/data/styles';

function isValidSheetURL(url) {
  if (isEmpty(url)) return false;
  if (url.startsWith(' ')) return false;
  return true;
}

export default function ExcelSpreadsheetPane({
  children: renderBody,
  onInputChange,
  connectorParams,
  inputDisabled = false,
}) {
  const body = (
    <StyledTextInput
      placeholder="Excel Sharing Link (Required)"
      name="webURL"
      onChange={onInputChange}
      disabled={inputDisabled}
      value={connectorParams.webURL}
    />
  );

  return renderBody(body, !isValidSheetURL(connectorParams.webURL));
}
