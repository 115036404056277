import React from 'react';
import update from 'immutability-helper';

import useArithWidgetAPI from '../../gql/useArithWidgetAPI';

import { WidgetAPI } from '../../contexts';

export const opLabels = {
  Add: 'Add',
  Subtract: 'Subtract',
  Multiply: 'Multiply',
  Divide: 'Divide',
};

const opRowLabels = {
  Add: 'Sum',
  Subtract: 'Difference',
  Multiply: 'Product',
  Divide: 'Quotient',
};

const rangeCells = (range, cells) => range.cellIDs.map((id) => cells[id]);

export function EditorAPI({ children }) {
  const api = useArithWidgetAPI();
  return <WidgetAPI.Provider value={api}>{children}</WidgetAPI.Provider>;
}

export function initialSetupState(op) {
  return {
    cells: {
      row1C: { ID: 'row1C', value: null },
      row2C: { ID: 'row2C', value: null },
      resC: { ID: 'resC', value: null },
      tot1: { ID: 'tot1', value: null },
      tot2: { ID: 'tot2', value: null },
      tot3: { ID: 'tot3', value: null },
    },
    widget: {
      name: opLabels[op],
      type: 'Arithmetic',
      columns: [
        {
          ID: 'col1',
          cellIDs: ['row1C', 'row2C'],
          template: { ID: 'col1', label: 'Column 1' },
        },
      ],
      rows: [
        {
          ID: 'row1',
          cellIDs: ['row1C'],
          template: { ID: 'row1', label: 'Row 1' },
        },
        {
          ID: 'row2',
          cellIDs: ['row2C'],
          template: { ID: 'row2', label: 'Row 2' },
        },
        {
          ID: 'res',
          cellIDs: ['resC'],
          template: { ID: 'res', label: opRowLabels[op] },
        },
      ],
      arithConfig: {
        op,
        showTotals: false,
        totals: {
          ID: 'tot',
          cellIDs: ['tot1', 'tot2', 'tot3'],
          template: { ID: 'tot', label: 'Total' },
        },
      },
    },
  };
}

export function makeUIWidget(widget, cells) {
  const config = update(widget.arithConfig, {
    type: { $set: widget.arithConfig.op },
    totals: {
      cells: { $set: rangeCells(widget.arithConfig.totals, cells) },
      normTemplate: { $set: widget.arithConfig.totals.template },
    },
  });
  return {
    ...widget,
    config,
  };
}

export function makeSetupAPI(state, baseAPI, setWidget) {
  return {
    updateShowTotals: (_, showTotals) =>
      setWidget((widget) =>
        update(widget, {
          arithConfig: { showTotals: { $set: showTotals } },
        })
      ),
  };
}
