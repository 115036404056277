import React, { useState, Fragment, useMemo } from 'react';
import { Dropdown, Message, Loader } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import {
  StageDropdownWrapper,
  DropdownLabel,
  TestContent,
  TestResultContainer,
  NoResultWrapper,
  SelectTestWrapper,
} from '../styles';
import Button from '<src>/components/Button';

import SelectionManagerBase from '../SelectionManager';
import WaveSpinner from '<src>/components/WaveSpinner';
import TestDropdown from '../TestDropdown';
import StageResultOutput from '../StageResultOutput';
import Link from '<src>/components/Link';
import { FlexRowContainer } from '<components>/NumbrzPageComponents';

const SelectionManager =
  TestResultContainer.withComponent(SelectionManagerBase);

function StageDropdown({
  baseURL,
  stages = [],
  activeStage: activeStageProp = {},
}) {
  const [open, setOpen] = useState(false);
  const [activeStage = activeStageProp, setActiveStage] = useState();

  return (
    <StageDropdownWrapper>
      <DropdownLabel>Stage:</DropdownLabel>
      <Dropdown
        direction="right"
        placeholder="Select Stage"
        closeOnEscape
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        text={activeStage ? activeStage.name : null}
      >
        <Dropdown.Menu style={{ maxHeight: 'initial' }}>
          {stages.map((s, idx) => (
            <Dropdown.Item
              key={idx}
              active={s.ID === activeStage.ID}
              onClick={() => {
                setActiveStage(s);
                setOpen(false);
              }}
            >
              <NavLink to={`${baseURL}/stages/${s.ID}`}>{s.name}</NavLink>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </StageDropdownWrapper>
  );
}

function NoResult() {
  return (
    <NoResultWrapper>Get started by running one of the tests.</NoResultWrapper>
  );
}

export function StageStatus({ stageStatus, testStatus }) {
  return (stageStatus && stageStatus.errMsg) || testStatus ? (
    <Message
      error
      style={{ minHeight: 'unset', marginBottom: 0, marginTop: '20px' }}
    >
      {testStatus && <p>{testStatus}</p>}
      {stageStatus && stageStatus.errMsg && <p>{stageStatus.errMsg}</p>}
    </Message>
  ) : null;
}

export default function TestResults({
  baseURL,
  flowID,
  flowStages,
  jobVariables = [],
  activeStage,
  tests,
  flowTestState,
  flowTables = [],
}) {
  const flowRunnable = useMemo(() => {
    let runnable = true;
    flowStages.forEach((s) => {
      if (!s.runnable) runnable = false;
    });
    return runnable;
  }, [flowStages]);

  const {
    activeTest,
    setActiveTest,
    runningTest,
    runTest,
    testStatus,
    testStageStatus,
    testResults,
  } = flowTestState;

  const stageElements = useMemo(() => {
    let els = {};

    jobVariables.forEach((v) => {
      if (!els[v.key]) els[v.key] = v.label;
    });

    if (activeStage && activeStage.steps)
      activeStage.steps.forEach((s) => {
        s.inputSchema.elements.forEach((iEl) => {
          if (!els[iEl.key]) els[iEl.key] = iEl.label;
        });
      });

    return els;
  }, [activeStage, jobVariables]);

  const stageResult = useMemo(() => {
    return activeStage && testResults.length > 0
      ? testResults.find((r) => r.stageID === activeStage.ID)
      : undefined;
  }, [activeStage, testResults]);

  const stageStatus = activeStage
    ? testStageStatus.find((s) => s.configID === activeStage.ID)
    : undefined;

  const testRunnable = activeTest ? activeTest.warnings.length === 0 : false;

  return (
    <SelectionManager style={{ height: '100%' }}>
      <TestContent>
        {tests.length === 0 ? (
          <NoResultWrapper>
            <p style={{ margin: 0 }}>
              {' '}
              No tests found.
              <Link
                blue
                bold={false}
                internal
                to={`${baseURL}/tests`}
                style={{ display: 'inline' }}
              >
                Get started with tests
              </Link>
            </p>
          </NoResultWrapper>
        ) : (
          <SelectTestWrapper>
            <div className="dropdown_wrapper">
              <FlexRowContainer
                alignItems="center"
                justifyContent="flex-start"
                style={{ flexWrap: 'wrap' }}
              >
                <TestDropdown
                  tests={tests}
                  activeTest={activeTest}
                  onSelectTest={setActiveTest}
                  baseURL={baseURL}
                />
                {!runningTest && testResults && testResults.length > 0 && (
                  <StageDropdown
                    baseURL={baseURL}
                    flowID={flowID}
                    stages={flowStages}
                    activeStage={activeStage}
                  />
                )}
              </FlexRowContainer>

              <Button.Run
                size="small"
                disabled={
                  runningTest || !activeTest || !flowRunnable || !testRunnable
                }
                onClick={() => runTest(activeTest.key)}
                title={!flowRunnable && 'Flow is not runnable'}
              >
                {runningTest ? <Loader active inline size="mini" /> : 'Run'}
              </Button.Run>
            </div>
          </SelectTestWrapper>
        )}

        {runningTest ? (
          <WaveSpinner />
        ) : !testResults && !runningTest ? (
          <NoResult />
        ) : (
          <Fragment>
            <StageStatus stageStatus={stageStatus} testStatus={testStatus} />

            <StageResultOutput
              baseURL={baseURL}
              flowID={flowID}
              stageResult={stageResult}
              activeStage={activeStage}
              stageElements={stageElements}
              jobVariables={jobVariables}
              flowTables={flowTables}
            />
          </Fragment>
        )}
      </TestContent>
    </SelectionManager>
  );
}
