import React, { forwardRef, useMemo } from 'react';

import { compare } from '<src>/utils/text';
import SectionedDropdown from '<components>/SectionedDropdown';

const toOpt = (baseURL) => (f) => ({
  ...f,
  value: f.ID,
  text: f.name,
  location: baseURL ? `${baseURL}/${f.ID}` : undefined,
});

function JobTablePicker(
  {
    baseURL,
    tables = [],
    placeholder = 'Model data',
    returnTo,
    value,
    onChange,
    ...props
  },
  parentRef
) {
  const options = useMemo(
    () =>
      tables.map(toOpt(baseURL)).sort((t1, t2) => compare(t1.name, t2.name)),
    [baseURL, tables]
  );

  return (
    <SectionedDropdown
      ref={parentRef}
      placeholder={placeholder}
      returnTo={returnTo}
      rootOptions={options}
      value={value}
      onChange={onChange}
      alwaysShowTriggerText={true}
      {...props}
      inputHeight="32px"
    />
  );
}

// eslint-disable-next-line no-func-assign
JobTablePicker = forwardRef(JobTablePicker);
JobTablePicker.displayName = 'JobTablePicker';
export default JobTablePicker;
