import React, { Fragment } from 'react';
import { Message } from 'semantic-ui-react';
import styled from '@emotion/styled';
import Excel_Share from '<src>/assets/Excel_Share.png';

const ErrorMessage = styled(Message)`
  margin-bottom: 0px !important;
`;
const StyledH5 = styled('h5')`
  font-size: 1.1em;
  font-weight: normal;
  margin: 10px 0 15px 0;
`;
const SplitContainer = styled('div')`
  display: flex;
  min-width: 500px;
`;

const LeftContent = styled('div')`
  flex: 8;
  display: flex;
  flex-direction: column;
`;

const RightContent = styled('div')`
  flex: 4;
`;

const StepGraphic = styled('img')`
  max-height: 200px;
`;

const ExcelAccessAgent = (props) => {
  const { error } = props;

  return (
    <Fragment>
      {error && (
        <ErrorMessage error>
          <div>
            <b>Error connecting Excel Workbook</b>: {error}
          </div>
        </ErrorMessage>
      )}
      <SplitContainer>
        <LeftContent>
          <StyledH5>
            To connect to an Excel Workbook, please share it and paste the share
            link below.
          </StyledH5>
        </LeftContent>
        <RightContent>
          <StepGraphic src={Excel_Share} />
        </RightContent>
      </SplitContainer>
    </Fragment>
  );
};

export default ExcelAccessAgent;
