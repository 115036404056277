import { gql } from '@apollo/client';

import { FunctionChangesFragment } from './fragments';
import useEditFunction from './useEditFunction';

const UpdateValueSource = gql`
  mutation UpdateValueSource(
    $funcID: CommonID!
    $templateID: ID!
    $sourceID: ID
    $sourceType: LinkSourceType
  ) {
    updateValueSourceV3(
      funcID: $funcID
      templateID: $templateID
      sourceID: $sourceID
      sourceType: $sourceType
    ) {
      ...FunctionChangesFragment
    }
  }
  ${FunctionChangesFragment}
`;

export default function useUpdateValueSource() {
  const updateValueSource = useEditFunction(
    UpdateValueSource,
    'updateValueSourceV3'
  );

  return (template, { sourceID, sourceType }) =>
    updateValueSource({
      variables: {
        funcID: template.funcID,
        templateID: template.ID,
        sourceID,
        sourceType,
      },
    });
}
