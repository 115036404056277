import React, { Fragment } from 'react';

import {
  SectionHdr,
  SectionSubhdr,
  Separator,
  TwoColSection,
  ContentHdr,
} from '<sections>/onboarding/styles';
import OnboardingVideos from '../videos';
import WelcomeScreen, { HelpReminder } from '../components/WelcomeScreen';

export default function ModelWelcomeScreen({ onHide, onVideoClick }) {
  const videos = OnboardingVideos.model;

  return (
    <WelcomeScreen onHide={onHide} sectionLabel={'model'}>
      <SectionHdr>
        <span className="underline">Welcome</span> to Numbrz
      </SectionHdr>
      <SectionSubhdr>Build, automate, collaborate.</SectionSubhdr>
      <TwoColSection>
        <div className="col">
          To get started, watch the overview of the Numbrz platform.
        </div>
        <div className="video"></div>
      </TwoColSection>
      <Separator />
      <HelpReminder />
      <Separator />
      <ContentHdr>
        <span className="underline">Feature</span> Overview Videos
      </ContentHdr>

      {videos
        .filter((v) => v.section === 'overview')
        .map((v, idx) => (
          <TwoColSection key={idx} onClick={(e) => onVideoClick(v)}>
            <div className="col">{v.title}</div>
            <div className="video">{v.thumbnail}</div>
          </TwoColSection>
        ))}
      {videos.filter((v) => v.section === 'concepts').length > 0 && (
        <Fragment>
          <Separator />
          <ContentHdr>
            <span className="underline">Key</span> Concepts
          </ContentHdr>
          {videos
            .filter((v) => v.section === 'concepts')
            .map((v, idx) => (
              <TwoColSection key={idx} onClick={(e) => onVideoClick(v)}>
                <div className="col">{v.title}</div>
                <div className="video">{v.thumbnail}</div>
              </TwoColSection>
            ))}
        </Fragment>
      )}
    </WelcomeScreen>
  );
}
