import {
  isValid as isValidDate,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  parseISO as parseDate,
  subDays,
  subHours,
  subMinutes,
  subSeconds,
} from 'date-fns';

import React from 'react';
import T from 'prop-types';
import TimeAgo from 'react-timeago';

import { getCurrentLocale } from '<src>/utils/navigator';

function formatter(value, unit, suffix, startDate, nextFormatter, now) {
  let endDate = now();
  let timePoints = [];

  const days = differenceInDays(endDate, startDate);
  if (days > 0) {
    timePoints.push(`${days}d`);
    endDate = subDays(endDate, days);
  }

  const hours = differenceInHours(endDate, startDate);
  if (hours > 0) {
    timePoints.push(`${hours}h`);
    endDate = subHours(endDate, hours);
  }

  const minutes = differenceInMinutes(endDate, startDate);
  timePoints.push(`${minutes}m`);
  if (minutes > 0) endDate = subMinutes(endDate, minutes);

  const seconds = differenceInSeconds(endDate, startDate);
  timePoints.push(`${seconds}s`);
  if (seconds > 0) endDate = subSeconds(endDate, seconds);

  return timePoints.map((num) => num).join(' ');
}

const DurationLabel = ({ start, end }) => {
  const endDate = !!end && isValidDate(parseDate(end)) ? parseDate(end) : null;
  const startDate =
    !!start && isValidDate(parseDate(start)) ? parseDate(start) : null;

  return !startDate ? (
    '-'
  ) : (
    <TimeAgo
      component="span"
      date={startDate}
      now={() => endDate || new Date()}
      formatter={formatter}
      live={!endDate}
    />
  );
};

DurationLabel.propTypes = {
  end: T.oneOfType([T.instanceOf(Date), T.number, T.string]),
  start: T.oneOfType([T.instanceOf(Date), T.number, T.string]),
};

DurationLabel.defaultProps = {
  locale: getCurrentLocale(),
  titleFormat: 'YYYY-MM-DDTHH:mm:ssZ',
};

export default DurationLabel;
