import React, { Fragment, useMemo } from 'react';
import { Icon } from 'semantic-ui-react';

import * as colors from '<components>/colors';
import Button from '<src>/components/Button';
import ElementLabel from '../ElementLabel';
import ValueOrElement from '../ValueOrElement';

import AddCondition from './AddCondition';
import AndSeparator from './AndSeparator';
import OrSeparator from './OrSeparator';
import { CondTable, Op } from './styles';
import { opLabels } from './ops';

function useConditionAPI(conditions, onChange, readOnly) {
  return useMemo(() => {
    if (!onChange || readOnly) {
      return [null, null, null];
    }

    const onAddAndCondition = (e, { orIdx, ...newCond }) => {
      const orCond = conditions[orIdx];
      const newConditions = [...conditions];
      newConditions[orIdx] = { ...orCond, and: [...orCond.and, newCond] };
      onChange(e, { conditions: newConditions });
    };

    const onAddOrCondition = (e, newCond) => {
      const newConditions = [...conditions, { and: [newCond] }];
      onChange(e, { conditions: newConditions });
    };

    const onRemoveCondition = (e, { orIdx, andIdx }) => {
      const orCond = conditions[orIdx];
      const andConds = orCond.and;
      const newAndConds = andConds
        .slice(0, andIdx)
        .concat(andConds.slice(andIdx + 1));
      let newConditions = [...conditions];
      if (newAndConds.length > 0) {
        newConditions[orIdx] = { ...orCond, and: newAndConds };
      } else {
        newConditions.splice(orIdx, 1);
      }
      onChange(e, { conditions: newConditions });
    };

    return [onAddAndCondition, onAddOrCondition, onRemoveCondition];
  }, [conditions, onChange, readOnly]);
}

export default function Conditions({
  elements,
  vElements = elements,
  conditions,
  onChange,
  flatPicker,
  vFlatPicker = flatPicker,
  wide = false,
  readOnly,
}) {
  const [onAddAndCondition, onAddOrCondition, onRemoveCondition] =
    useConditionAPI(conditions, onChange, readOnly);
  return (
    <CondTable wide={wide}>
      <tbody>
        {conditions.map((orCond, orIdx) => (
          <Fragment key={orIdx}>
            {orIdx !== 0 ? (
              <tr>
                <td className="sep" colSpan="4">
                  <OrSeparator />
                </td>
              </tr>
            ) : null}

            {orCond.and.map((andCond, andIdx) => {
              const element = elements.find(
                (e) => e.key === andCond.elementKey
              );
              return (
                <Fragment key={andIdx}>
                  {andIdx !== 0 ? (
                    <tr>
                      <td className="sep" colSpan="4">
                        <AndSeparator />
                      </td>
                    </tr>
                  ) : null}
                  <tr className={andCond.elementKey && !element ? 'error' : ''}>
                    <td className="field">
                      <ElementLabel
                        elementKey={andCond.elementKey}
                        element={element}
                      />
                    </td>
                    <td>
                      <Op op={andCond.op}>{opLabels[andCond.op]}</Op>
                    </td>
                    <td className="field">
                      <ValueOrElement
                        value={andCond.input}
                        elements={vElements}
                      />
                    </td>
                    {onRemoveCondition ? (
                      <td className="btn">
                        <Button.IconBtn
                          icon={<Icon name="trash alternate outline" />}
                          baseColor="transparent"
                          contentColor={colors.gray2}
                          activeColor="transparent"
                          contrastColor="transparent"
                          bgHoverColor={colors.gray4}
                          onClick={(e) =>
                            onRemoveCondition(e, { andIdx, orIdx })
                          }
                        />
                      </td>
                    ) : null}
                  </tr>
                </Fragment>
              );
            })}
            {onAddAndCondition ? (
              <>
                {orCond.and.length > 0 ? (
                  <tr>
                    <td className="sep" colSpan="4">
                      <AndSeparator />
                    </td>
                  </tr>
                ) : null}
                <AddCondition
                  key={orCond.and.length}
                  elements={elements}
                  vElements={vElements}
                  onAdd={(e, data) => onAddAndCondition(e, { ...data, orIdx })}
                  flatPicker={flatPicker}
                  vFlatPicker={vFlatPicker}
                />
              </>
            ) : null}
          </Fragment>
        ))}
        {onAddOrCondition ? (
          <>
            {conditions.length !== 0 ? (
              <tr>
                <td className="sep" colSpan="4">
                  <OrSeparator />
                </td>
              </tr>
            ) : null}

            <AddCondition
              key={conditions.length}
              elements={elements}
              vElements={vElements}
              onAdd={onAddOrCondition}
              flatPicker={flatPicker}
              vFlatPicker={vFlatPicker}
            />
          </>
        ) : null}
      </tbody>
    </CondTable>
  );
}
