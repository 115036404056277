import { gql } from '@apollo/client';

import { FunctionChangesFragment } from './fragments';
import useEditFunction, { optimisticFunctionChanges } from './useEditFunction';

const UpdateFunction = gql`
  mutation UpdateFunction(
    $funcID: CommonID!
    $name: String
    $description: String
  ) {
    updateFuncV3(funcID: $funcID, name: $name, description: $description) {
      ...FunctionChangesFragment
    }
  }
  ${FunctionChangesFragment}
`;

export default function useUpdateFunction() {
  const updateFunc = useEditFunction(UpdateFunction, 'updateFuncV3');

  return (func, { name, description }) =>
    updateFunc({
      variables: { funcID: func.ID, name, description },
      optimisticResponse: {
        __typename: 'Mutation',
        updateFuncV3: optimisticFunctionChanges({
          funcID: func.ID,
          funcChanged: {
            __typename: 'FunctionV3',
            ...func,
            name: name !== undefined ? name : func.name,
            description:
              description !== undefined ? description : func.description,
          },
        }),
      },
    });
}
