import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import styled from '@emotion/styled';
import { Dropdown, Ref } from 'semantic-ui-react';

import { formatCell, parseDateValue } from '<sections>/functions/Cell/util';

import FieldPicker from '../FieldPicker';
import { ConfigField as BaseConfigField } from '<src>/components/NumbrzVerticalEditor';
import { FlexRowContainer } from '<components>/NumbrzPageComponents';
import { ElementSource } from '../ElementLabel';

const ConfigField = styled(BaseConfigField)`
  display: inline-block;
  width: initial;
`;

export const fieldPrefix = '#$REF$#_';

function ValuePicker(
  {
    disabled,
    elements = [],
    fluid = true,
    placeholder = 'Value',
    restrictTypes,
    value,
    onChange,
    flat,
  },
  parentRef
) {
  const [open, setOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const ddRef = useRef();
  const fvRef = useRef();

  const valEl = value
    ? elements.find((e) => e.key === value.replace(fieldPrefix, ''))
    : null;

  useEffect(() => {
    if (editing) {
      fvRef.current.edit(true);
    }
  }, [editing]);

  const selected = useMemo(
    () =>
      value && value.startsWith(fieldPrefix)
        ? value.substring(fieldPrefix.length)
        : null,
    [value]
  );
  const selectedElt = useMemo(
    () => (selected ? elements.find((e) => e.key === selected) : null),
    [selected, elements]
  );

  const onFocus = useCallback(() => {
    if (editing) {
      return;
    }
    if (value && !value.startsWith(fieldPrefix)) {
      fvRef.current.edit(true);
    } else {
      ddRef.current.focus();
    }
  }, [editing, value]);
  const onBlur = useCallback(() => {
    if (selected) {
      ddRef.current.blur();
    } else {
      fvRef.current.blur();
    }
  }, [selected]);

  const onSubmitValue = useCallback(
    (e) => {
      let value = e.value;
      const parsed = parseDateValue(e.value);
      if (!parsed.error) {
        value = formatCell(parsed);
      }
      onChange(e, { value });
    },
    [onChange]
  );

  useImperativeHandle(parentRef, () => ({
    focus: onFocus,
    blur: onBlur,
  }));

  return (
    <FlexRowContainer alignItems="center" justifyContent="flex-start">
      {valEl && <ElementSource element={valEl} />}
      <Ref innerRef={ddRef}>
        <Dropdown
          disabled={disabled}
          closeOnEscape
          fluid={fluid}
          open={open && !editing}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          trigger={
            !editing && selectedElt ? (
              selectedElt.label
            ) : (
              <ConfigField
                ref={fvRef}
                disabled={disabled}
                singleClick
                placeholder={placeholder}
                value={value && value.startsWith(fieldPrefix) ? '' : value}
                onSubmit={onSubmitValue}
                onEditing={({ editing }) => setEditing(editing)}
                onFocus={(e) => e.stopPropagation()}
              />
            )
          }
        >
          <Dropdown.Menu>
            <FieldPicker
              elements={elements}
              multiSelect={false}
              noneLabel="Fixed Value"
              restrictTypes={restrictTypes}
              selected={!editing ? selected : null}
              onChange={(e, { selected }) => {
                if (selected) {
                  onChange(e, {
                    value: `${fieldPrefix}${selected}`,
                  });
                } else {
                  setEditing(true);
                }
              }}
              flat={flat}
            />
          </Dropdown.Menu>
        </Dropdown>
      </Ref>
    </FlexRowContainer>
  );
}

// eslint-disable-next-line no-func-assign
ValuePicker = forwardRef(ValuePicker);
ValuePicker.displayName = 'ValuePicker';

export default ValuePicker;
