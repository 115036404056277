import React, { useState, Fragment } from 'react';
import { useMutation } from '<src>/apollo/client';

import fp from 'lodash/fp';

import {
  GetCustomerCardPaymentMethods,
  SetDefaultPaymentMethod,
  AddPaymentMethod,
  DeletePaymentMethod,
  UpdatePaymentMethod,
} from '../../../queries';

import {
  BillingWrapper,
  TopContainer,
  ContainerFooter,
  PaymentMethodList,
  PaymentMethodListitem,
  CardInfo,
  StyledIconButton,
  CardToolbar,
  DefaulLbl,
} from '../styles';
import DeleteButton from '<components>/DeleteButtonWithConfirm';
import Button from '<src>/components/Button';

import { Icon } from 'semantic-ui-react';
import DefaultPaymentDialog from '../components/DefaultPaymentDialog';
import NewPaymentDialog from '../components/NewPaymentDialog';

function fetchCardIconName(cardBrand) {
  switch (cardBrand) {
    case 'visa' || 'Visa':
      return 'cc visa';
    case 'amex' || 'American Express':
      return 'cc amex';
    case 'discover' || 'Discover':
      return 'cc discover';
    default:
      return 'credit card outline';
  }
}

function CardPaymentMethods(
  paymentMethods,
  defaultPaymentMethod,
  customerID,
  openUpdatePaymentDialog,
  deletePaymentMethod
) {
  return paymentMethods.map((p) => (
    <PaymentMethodListitem key={p.id}>
      <CardInfo>
        <Icon name={fetchCardIconName(p.card.brand)} size="large" />
        {`${p.card.brand.toUpperCase()} ending in ${p.card.last4}`}
        {p.id === defaultPaymentMethod && <DefaulLbl>Default</DefaulLbl>}
      </CardInfo>
      <CardToolbar>
        {p.id !== defaultPaymentMethod && (
          <DeleteButton
            onClick={async () =>
              await deletePaymentMethod({
                variables: {
                  input: {
                    paymentID: p.id,
                    customerID,
                  },
                },
              })
            }
          />
        )}
        <StyledIconButton
          name="cog"
          onClick={() => openUpdatePaymentDialog(p)}
        />
      </CardToolbar>
    </PaymentMethodListitem>
  ));
}

export default function PaymentMethods({
  customerInformation,
  customerCardPaymentMethods,
}) {
  const [setDefaultPaymentMethod] = useMutation(SetDefaultPaymentMethod);
  const [addCardPaymentMethod] = useMutation(AddPaymentMethod, {
    update: (cache, { data: { addPaymentMethod } }) => {
      const { customerCardPaymentMethods } = cache.readQuery({
        query: GetCustomerCardPaymentMethods,
      });

      const { ...pmToAdd } = addPaymentMethod;

      cache.writeQuery({
        query: GetCustomerCardPaymentMethods,
        data: {
          customerCardPaymentMethods: customerCardPaymentMethods.concat([
            pmToAdd,
          ]),
        },
      });
    },
  });
  const [deletePaymentMethod] = useMutation(DeletePaymentMethod, {
    update: (cache, { data: { deletePaymentMethod } }) => {
      const { customerCardPaymentMethods } = cache.readQuery({
        query: GetCustomerCardPaymentMethods,
      });

      cache.writeQuery({
        query: GetCustomerCardPaymentMethods,
        data: {
          customerCardPaymentMethods: customerCardPaymentMethods.filter(
            (pm) => pm.id !== deletePaymentMethod.paymentID
          ),
        },
      });
    },
  });
  const [updateCardPaymentMethod] = useMutation(UpdatePaymentMethod, {
    update: (cache, { data: { updatePaymentMethod } }) => {
      const { customerCardPaymentMethods } = cache.readQuery({
        query: GetCustomerCardPaymentMethods,
      });

      let pmToUpdate = customerCardPaymentMethods.find(
        (pm) => pm.id === updatePaymentMethod.id
      );
      const { ...updatedPM } = updatePaymentMethod;
      if (pmToUpdate) pmToUpdate = updatedPM;
      cache.writeQuery({
        query: GetCustomerCardPaymentMethods,
        data: {
          customerCardPaymentMethods: customerCardPaymentMethods,
        },
      });
    },
  });

  const defaultPaymentMethod = fp.getOr(
    null,
    'invoiceSettings.defaultPaymentMethod',
    customerInformation
  );
  const customerID = fp.getOr(undefined, 'id', customerInformation);

  const [paymentDialogVisible, setPaymentDialogVisible] = useState(false);
  const [updatePaymentDialogVisible, setUpdatePaymentDialogVisible] =
    useState(false);
  const [activePaymentMethod, setActivePaymentMethod] = useState(null);
  const [defaultPaymentDialogVisible, setDefaultPaymentDialogVisible] =
    useState(false);

  function handleOpenUpdatePaymentDialog(payment) {
    setActivePaymentMethod(payment);
    setUpdatePaymentDialogVisible(true);
  }

  return (
    <Fragment>
      <BillingWrapper>
        {!customerID ? (
          <p>Please subscribe to a paid plan to manage your payment methods.</p>
        ) : (
          <TopContainer>
            <PaymentMethodList>
              {CardPaymentMethods(
                customerCardPaymentMethods,
                defaultPaymentMethod,
                customerID,
                handleOpenUpdatePaymentDialog,
                deletePaymentMethod
              )}
            </PaymentMethodList>
            {customerCardPaymentMethods.length === 0 && (
              <p>No payment methods found.</p>
            )}
            <ContainerFooter>
              <Button onClick={() => setPaymentDialogVisible(true)}>
                Add Payment Method
              </Button>
              {customerCardPaymentMethods.length > 0 && (
                <Button onClick={() => setDefaultPaymentDialogVisible(true)}>
                  Set Default
                </Button>
              )}
            </ContainerFooter>
          </TopContainer>
        )}
      </BillingWrapper>
      <DefaultPaymentDialog
        customerID={customerID}
        visible={defaultPaymentDialogVisible}
        paymentMethods={customerCardPaymentMethods}
        onDialogClose={() => setDefaultPaymentDialogVisible(false)}
        setDefaultPaymentMethod={setDefaultPaymentMethod}
      />
      <NewPaymentDialog
        customerID={customerID}
        visible={paymentDialogVisible}
        onDialogClose={() => setPaymentDialogVisible(false)}
        addPaymentMethod={addCardPaymentMethod}
      />
      <NewPaymentDialog
        mode="update"
        visible={updatePaymentDialogVisible}
        onDialogClose={() => setUpdatePaymentDialogVisible(false)}
        customerID={customerID}
        payment={activePaymentMethod}
        updatePaymentMethod={updateCardPaymentMethod}
      />
    </Fragment>
  );
}
