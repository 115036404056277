import { gql } from '@apollo/client';

import { FunctionChangesFragment } from './fragments';
import useEditFunction from './useEditFunction';

const AddWidgetRow = gql`
  mutation AddWidgetRow(
    $funcID: CommonID!
    $widgetID: ID!
    $beforeID: ID
    $correlationID: ID
    $sourceID: ID
    $sourceType: LinkSourceType
  ) {
    addWidgetRowV3(
      funcID: $funcID
      widgetID: $widgetID
      beforeID: $beforeID
      correlationID: $correlationID
      sourceID: $sourceID
      sourceType: $sourceType
    ) {
      ...FunctionChangesFragment
    }
  }

  ${FunctionChangesFragment}
`;

export default function useAddWidgetRow() {
  const addRow = useEditFunction(AddWidgetRow, 'addWidgetRowV3');
  return (widget, { beforeID, correlationID, sourceID, sourceType }) =>
    addRow({
      variables: {
        funcID: widget.funcID,
        widgetID: widget.ID,
        beforeID,
        correlationID,
        sourceID,
        sourceType,
      },
    });
}
