import React, { PureComponent } from 'react';
import classnames from 'classnames';

import withClassName from '<components>/hoc/className';
import withDefaultProps from '<components>/hoc/defaultProps';
import withFilteredProps from '<components>/hoc/filteredProps';
import withDisplayName from '<components>/hoc/displayName';

import styledTable from '../styles';

@withDefaultProps({
  as: 'div',
})
@withClassName(classnames(styledTable.control.dot, styledTable.control.corner))
@withFilteredProps(['children', 'as'])
@withDisplayName('ControlCorner')
export default class Corner extends PureComponent {
  render() {
    const { as: Tag, children, filteredProps } = this.props;
    return (
      <Tag {...filteredProps}>
        <div className="outline" />
        {children}
      </Tag>
    );
  }
}
