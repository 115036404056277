import React, { PureComponent } from 'react';
import T from 'prop-types';
import styled from '@emotion/styled';
import * as dimensions from '<components>/dimensions';

const StyledCenteredLayout = styled('div')`
  position: relative;
  width: 100%;
  margin: 0 auto;
  transition: all 125ms ease-in;

  @media (min-width: ${dimensions.centered}) {
    width: ${({ unbounded }) => (unbounded ? '100%' : dimensions.centered)};
  }
`;

export default class CenteredLayout extends PureComponent {
  static defaultProps = {
    unbounded: false,
  };

  static propTypes = {
    unbounded: T.bool.isRequired,
    children: T.oneOfType([T.func, T.node]),
    className: T.string,
  };

  render() {
    const { children, ...rest } = this.props;
    return (
      <StyledCenteredLayout {...rest}>
        {(typeof children === 'function' && children(rest)) || children}
      </StyledCenteredLayout>
    );
  }
}
