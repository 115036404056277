import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import Menu from './Menu';
import { Layout, StyledScrollbar } from '<components>/NumbrzPageComponents';

const Content = styled('div')`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  overflow-y: ${({ overflowY }) => overflowY};
  ${StyledScrollbar};
`;

const Main = styled('div')`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  flex: 1;
  overflow: auto;
  overflow-y: ${({ overflowY }) => (overflowY ? overflowY : 'hidden')};
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : 'transparent'};
  ${StyledScrollbar};
`;

MainLayout.defaultProps = {
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  overflowY: 'auto',
};

function MainLayout({
  main: mainProp,
  header: headerProp,
  alignItems,
  justifyContent,
  overflowY,
  navigation,
  hideMenu,
  bgColor,
}) {
  const main = () => {
    let computedNavigation = navigation;
    if (navigation === undefined) {
      computedNavigation = [
        { path: '/models', title: 'Models', exact: true },
        { path: '/data', title: 'Data', exact: true },
        { path: '/job-groups', title: 'Job Groups', exact: true },
        { path: '/catalog', title: 'Catalog', exact: true },
      ];
    }

    return (
      <Content overflowY={overflowY}>
        <Fragment>
          {!hideMenu && (
            <Menu leftPadding="38px" navigation={computedNavigation} />
          )}
          <Main
            overflowY={overflowY}
            alignItems={alignItems}
            justifyContent={justifyContent}
            bgColor={bgColor}
          >
            {typeof mainProp === 'function' ? mainProp(mainProp) : mainProp}
          </Main>
        </Fragment>
      </Content>
    );
  };

  const header = () => {
    return typeof headerProp === 'function'
      ? headerProp(headerProp)
      : headerProp;
  };

  return (
    <Layout>
      {header()}
      {main()}
    </Layout>
  );
}

export default MainLayout;
export { Menu };
