import React, { PureComponent } from 'react';
import { getDisplayName, hoistStatics } from 'recompose';

export default (props = {}) => {
  const withDefaultProps = (BaseComponent) => {
    const name = getDisplayName(BaseComponent);
    const defaultProps = Object.assign(
      {},
      BaseComponent.defaultProps || {},
      props
    );
    class WithDefaultProps extends PureComponent {
      static defaultProps = defaultProps;
      render() {
        return <BaseComponent {...this.props} />;
      }
    }
    WithDefaultProps.displayName = `withDefaultProps${name}`;

    return WithDefaultProps;
  };

  return hoistStatics(withDefaultProps);
};
