import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function useIntercomNavUpdates() {
  const location = useLocation();

  useEffect(() => {
    window.Intercom('update', {
      last_request_at: parseInt(new Date().getTime() / 1000), // round to seconds
    });
  }, [location.pathname]);
}
