import Card from '<components>/Card';
import { dialog } from '<components>/z-index';
import styled from '@emotion/styled';
import * as colors from '<components>/colors';

const contentPadding = '20px';

const StyledHeader = styled(Card.Header)`
  border-bottom: none;
  padding-left: 0;
  white-space: wrap;

  h1 {
    font-size: 1.4em;
    font-weight: bold;
    color: ${colors.gray1};
    margin-bottom: 0;
  }
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-direction: row;
`;
const StyledFooter = styled(Card.Footer)`
  border-top: none;
  button {
    margin-left: 10px;
  }
`;
const StyledBody = styled(Card.Body)`
  padding: 20px 0;
  & + & {
    padding-top: 0;
  }
`;
const StyledButtons = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
export const Footer = StyledFooter;
export const Header = StyledHeader;
export const Body = StyledBody;
export const Buttons = StyledButtons;

export const Headline = styled(Card.Headline.withComponent('h1'))`
  font-weight: 300;
  font-size: 1.5em;
  white-space: wrap;
  overflow: visible;
  text-overflow: none;
`;

export const Container = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'scrolling',
})(({ scrolling, padding }) => ({
  position: scrolling ? 'static' : 'fixed',
  alignItems: 'stretch',
  justifyContent: 'flex-start',
  width: 'fit-content',

  height: 'auto',
  left: '50%',
  top: '50%',
  transform: scrolling ? 'translate(0, 0)' : 'translate(-50%, -50%)',
  margin: scrolling ? '40px auto' : 0,
  padding: padding ? padding : '25px',
}));

export const Overlay = styled('div')({
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100vw',
  height: '100vh',
  zIndex: dialog,
  background: 'rgba(0, 0, 0, 0.5)',
  padding: contentPadding,
  overflow: 'auto',
  backdropFilter: 'blur(2px)',
});
