import React, { PureComponent } from 'react';

import styled from '@emotion/styled';
import * as colors from '<components>/colors';

import Dialog from '<components>/Dialog';
import Button from '<src>/components/Button';
import { Dropdown } from 'semantic-ui-react';

const ignoreSsrWarning =
  '/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */';

const DialogContent = styled('div')`
  display: flex;
  flex-direction: column;
`;

const StyledFooter = styled(Dialog.Footer)`
  button:first-child${ignoreSsrWarning} {
    margin-right: 5px;
  }
`;

const StyledDropdown = styled(Dropdown)`
  border: 1px solid ${colors.gray3};
  border-radius: 0.28571429rem;
  padding: 10px;
`;

export default class DefaultPaymentDialog extends PureComponent {
  state = {
    paymentID: null,
  };

  handleSaveDefaultPayment = async () => {
    if (this.state.paymentID) {
      await this.props.setDefaultPaymentMethod({
        variables: {
          input: {
            customerID: this.props.customerID,
            paymentID: this.state.paymentID,
          },
        },
      });
    }

    this.props.onDialogClose();
  };

  handleDropdownChange = (e, data) => {
    this.setState({ paymentID: data.value });
  };

  get dropdownOptions() {
    const { paymentMethods } = this.props;
    let options = [];

    if (paymentMethods && paymentMethods.length > 0) {
      options = paymentMethods.map((pm) => ({
        text: `${pm.card.brand.toUpperCase()} ending in ${pm.card.last4}`,
        value: pm.id,
        key: pm.id,
      }));
    }
    return [
      {
        text: 'None',
        value: null,
        key: 'none',
      },
      ...options,
    ];
  }

  render() {
    return (
      <Dialog
        visible={this.props.visible ? 1 : 0}
        onRequestClose={this.props.onDialogClose}
      >
        <Dialog.Header>
          <Dialog.Headline>Set default payment method</Dialog.Headline>
        </Dialog.Header>
        <Dialog.Body>
          <DialogContent>
            <StyledDropdown
              closeOnBlur
              value={this.props.defaultPaymentID || this.state.paymentID}
              options={this.dropdownOptions}
              onChange={this.handleDropdownChange}
              placeholder="Select Payment Method"
            />
          </DialogContent>
        </Dialog.Body>
        <StyledFooter>
          <Button onClick={() => this.props.onDialogClose()}>Cancel</Button>
          <Button onClick={this.handleSaveDefaultPayment}>Save</Button>
        </StyledFooter>
      </Dialog>
    );
  }
}
