import React, { useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { DropdownWrapper } from '<components>/NumbrzPageComponents';
import styled from '@emotion/styled';

import { useQuery } from '<src>/apollo/client';

import { GetProducts } from '<src>/sections/account/queries';

import * as colors from '<components>/colors';

const StyledDropdownWrapper = styled(DropdownWrapper)`
  background-color: transparent;
  margin-bottom: 15px;
  margin-top: 10px;
  border-radius: 5px !important;
  border: 1px solid ${colors.gray3};
`;

function StripeProductDropdown({ value, onChange }) {
  const { data: { products } = {}, loading } = useQuery(GetProducts);

  const [productValue, setProductValue] = useState(null);

  let options = [{ key: 'none', value: null, text: 'None' }];
  if (products && products.length > 0) {
    options = options.concat(
      products.map((p) => ({
        key: p.id,
        value: p.id,
        text: p.name,
      }))
    );
  }
  return loading ? null : (
    <StyledDropdownWrapper>
      <Dropdown
        options={options}
        value={productValue || value}
        onChange={(e, data) => {
          setProductValue(data.value);
          onChange(data.value);
        }}
        placeholder="Select Plan"
      />
    </StyledDropdownWrapper>
  );
}

export default StripeProductDropdown;
