import React, { useState, useContext, Fragment } from 'react';
import orderBy from 'lodash/orderBy';
import fp from 'lodash/fp';

import { useHistory } from 'react-router-dom';
import Button from '<src>/components/Button';
import WaveSpinner from '<components>/WaveSpinner';

import FunctionWelcomeScreen from '<sections>/onboarding/welcome-screens/FunctionWelcomeScreen';
import FlowWelcomeScreen from '<sections>/onboarding/welcome-screens/FlowsWelcomeScreen';
import JobsWelcomeScreen from '<sections>/onboarding/welcome-screens/JobsWelcomeScreen';
import { UserPreferencesContext } from '<components>/UserPreferences';
import VideoPopup from '<sections>/onboarding/components/VideoPopup';
import NumbrzEntityList from '<components>/NumbrzEntityList';
import CloneFunctionDialog from '../CloneFunctionDialog';
import RunJobErrorDialog from '<sections>/jobs/components/RunJobErrorDialog';

export default function List({
  project,
  AddDialog,
  refetch,
  cloneItem,
  removeItem,
  readOnly,
  runJob,
  loading,
  items = [],
  objType,
}) {
  const history = useHistory();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [cloneDialogVisible, setCloneDialogVisible] = useState(false);
  const [videoPopupVisible, setVideoPopupVisible] = useState(false);
  const [activeVideo, setActiveVideo] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [runResult, setRunResult] = useState();

  const { onboardingPrefs = {}, showHideWelcomeScreen } = useContext(
    UserPreferencesContext
  );
  const { welcomeScreens = {} } = onboardingPrefs;
  const showWelcomeScreen = welcomeScreens[objType];

  const runFailed =
    !!runResult && !fp.getOr(false, 'data.runJob.success', runResult);

  const onAddClick = () => {
    if (AddDialog) {
      setDialogVisible(true);
    }
  };

  const hideAddDialog = (refetchList) => {
    if (refetchList && refetch) refetch();
    setDialogVisible(false);
  };

  const getWelcomeScreenComp = (objType) => {
    switch (objType) {
      case 'function':
        return FunctionWelcomeScreen;
      case 'flow':
        return FlowWelcomeScreen;
      case 'job':
        return JobsWelcomeScreen;
      default:
        return null;
    }
  };

  const getWelcomeScreen = (objType) => {
    const Comp = getWelcomeScreenComp(objType);
    return (
      <Comp
        onHide={showHideWelcomeScreen}
        onVideoClick={(video) => {
          setActiveVideo(video);
          setVideoPopupVisible(true);
        }}
      />
    );
  };

  const welcomeScreen = () => (
    <Fragment>
      {getWelcomeScreen(objType)}
      <VideoPopup
        onClose={() => {
          setVideoPopupVisible(false);
          setActiveVideo(null);
        }}
        visible={videoPopupVisible}
        video={activeVideo}
      />
    </Fragment>
  );

  if (loading) {
    return <WaveSpinner />;
  }

  return (
    <Fragment>
      {showWelcomeScreen && !loading && items.length === 0 ? (
        welcomeScreen()
      ) : (
        <NumbrzEntityList
          items={orderBy(items, ['name'], ['asc'])}
          entityType={objType}
          onDeleteItem={(ID) => removeItem(ID)}
          onCloneItem={(e, ID) => {
            if (objType === 'function') {
              setActiveItem(ID);
              setCloneDialogVisible(true);
            } else {
              cloneItem(ID);
            }
          }}
          onRunItem={runJob}
          readOnly={readOnly}
          setRunResult={setRunResult}
        />
      )}

      {!readOnly && AddDialog ? (
        <AddDialog
          projectID={project.ID}
          project={project}
          visible={dialogVisible}
          onDialogClose={hideAddDialog}
          searchItems={items}
          history={history}
        />
      ) : null}
      {!readOnly && AddDialog ? (
        <Button.ListPageCreateBtn onClick={onAddClick} title="New Resource">
          {objType ? `Create a ${objType}` : 'Create'}
          <span className="icon">+</span>
        </Button.ListPageCreateBtn>
      ) : null}

      <CloneFunctionDialog
        functionID={activeItem}
        defaultProject={project}
        cloneFunction={cloneItem}
        visible={cloneDialogVisible}
        onClose={() => {
          setActiveItem(null);
          setCloneDialogVisible(false);
        }}
      />
      <RunJobErrorDialog
        visible={runFailed}
        runResult={runResult}
        onClose={() => {
          setRunResult(null);
        }}
      />
    </Fragment>
  );
}
