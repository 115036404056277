import React, { PureComponent } from 'react';
import styled from '@emotion/styled';
import { Switch, Route, Redirect, matchPath } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import gql from 'graphql-tag';

import { useQuery } from '<src>/apollo/client';

import MainLayout from '<components>/MainLayout';
import Masthead from '<components>/Masthead';
import SearchBar from '<components>/SearchBar';
import NotFound from '<components>/Routes/NotFound';

import ManageAccounts from './pages/ManageAccounts';
import AccountAdmin from './pages/AccountAdmin';

const Content = styled('div')`
  width: 100%;
  padding: 35px 45px;

  & textarea {
    width: 100%;
    min-height: 120px;
    margin-bottom: 15px;
  }

  & table {
    width: 100%;
    margin-bottom: 15px;
  }
`;

const GetAccountName = gql`
  query GetAccountName($ID: CommonID!) {
    account(ID: $ID) {
      ID
      display
    }
  }
`;

const AccountTitle = ({ rootURL, accountID }) => {
  const { data: { account } = {}, loading } = useQuery(GetAccountName, {
    variables: { ID: accountID },
  });

  const title = !loading && account ? account.display : '';

  return (
    <BreadcrumbTitle
      rootURL={rootURL}
      title={title}
      extraBreadcrumbs={[
        {
          title: 'Users & Orgs',
          path: `${rootURL}/accounts`,
        },
      ]}
    />
  );
};

const BreadcrumbTitle = ({ rootURL, title, extraBreadcrumbs = [] }) => (
  <DocumentTitle title={`${title} - Numbrz`}>
    <Masthead
      title={title}
      breadcrumbs={[
        { title: 'Administration Tools', path: rootURL },
        ...extraBreadcrumbs,
      ]}
    />
  </DocumentTitle>
);

const SearchTitle = ({ title, searchTerm, onSearchChange }) => (
  <DocumentTitle title={`${title} - Numbrz`}>
    <Masthead>
      <SearchBar
        placeholder="Search"
        value={searchTerm}
        onChange={onSearchChange}
        darkMode
      />
    </Masthead>
  </DocumentTitle>
);

export default class NumbrzAdminSection extends PureComponent {
  state = { searchTerm: '' };

  onSearchChange = (term) => this.setState({ searchTerm: term });

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ searchTerm: '' });
    }
  }

  get header() {
    const { match } = this.props;
    return (
      <Switch>
        <Route exact path={`${match.url}/accounts`}>
          {() => (
            <SearchTitle
              title="Users & Orgs"
              searchTerm={this.state.searchTerm}
              onSearchChange={this.onSearchChange}
            />
          )}
        </Route>
        <Route exact path={`${match.url}/accounts/:accountID`}>
          {(props) => (
            <AccountTitle
              rootURL={match.url}
              accountID={props.match.params.accountID}
            />
          )}
        </Route>
        <Route component={NotFound} />
      </Switch>
    );
  }

  get body() {
    const { match } = this.props;
    return (
      <Content>
        <Switch>
          <Route exact path={match.url}>
            {() => <Redirect to={`${match.url}/accounts`} />}
          </Route>
          <Route exact path={`${match.url}/accounts`}>
            {(props) => (
              <ManageAccounts searchTerm={this.state.searchTerm} {...props} />
            )}
          </Route>
          <Route
            exact
            path={`${match.url}/accounts/:accountID`}
            component={AccountAdmin}
          />
          <Route component={NotFound} />
        </Switch>
      </Content>
    );
  }

  subRouteMatches(route) {
    const { location, match } = this.props;
    return matchPath(location.pathname, {
      path: `${match.url}${route}`,
      exact: true,
    });
  }

  render() {
    return (
      <MainLayout navigation={false} header={this.header} main={this.body} />
    );
  }
}
