import { useQuery, useMutation } from '<src>/apollo/client';
import fp from 'lodash/fp';

import {
  GetJobGroup,
  AddBlock,
  RemoveBlock,
  UpdateBlock,
  ReviewBlock,
  UpdateJobGroup,
  StartJobGroup,
  CancelJobGroup,
} from '../queries';
import { GetProjects } from '<sections>/projects/queries';

export default function useJobGroupState(jobGroupID, history, runID) {
  const {
    data: { jobGroup } = {},
    error,
    loading: loadingJobGroup,
  } = useQuery(GetJobGroup, {
    variables: { ID: jobGroupID },
  });

  const { data: { projects = [] } = {}, loading: loadingProjects } = useQuery(
    GetProjects,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    }
  );

  const projectNames = () => {
    let names = {};
    if (!loadingProjects && projects.length > 0)
      projects.forEach((p) => (names[p.ID] = p.name));
    return names;
  };

  const [addBlock] = useMutation(AddBlock);
  const [removeBlock] = useMutation(RemoveBlock);
  const [updateBlock] = useMutation(UpdateBlock);
  const [reviewBlock] = useMutation(ReviewBlock);
  const [updateJobGroup] = useMutation(UpdateJobGroup);
  const [runJobGroup] = useMutation(StartJobGroup);
  const [cancelJobGroup] = useMutation(CancelJobGroup);

  if (error) {
    throw error;
  }

  const handleRunGroup = async (startBlockKey) => {
    const res = await runJobGroup({ variables: { jobGroupID, startBlockKey } });
    const runStateID = fp.getOr(undefined, 'data.startJobGroup', res);

    history.push(`/job-groups/${jobGroupID}/status/${runStateID}`);
  };

  const state = {
    jobGroup,
    error,
    loading: loadingJobGroup || loadingProjects,
    handleRunGroup,
    projectNames: projectNames(),
  };

  state.api = {
    onChange: (e, { description, name, externalResultURL }) =>
      updateJobGroup({
        variables: {
          ID: jobGroup.ID,
          input: { description, name, externalResultURL },
        },
      }),
    onRun: (e, { jobGroupID }) => runJobGroup({ variables: { jobGroupID } }),
    onCancel: (e, { stateID }) => cancelJobGroup({ variables: { stateID } }),
    block: {
      onAdd: (e, { jobGroupID, beforeBlockKey, block }) =>
        addBlock({
          variables: {
            jobGroupID,
            beforeBlockKey,
            input: block,
          },
        }),
      onRemove: (e, { jobGroupID, blockKey }) =>
        removeBlock({
          variables: {
            jobGroupID,
            blockKey,
          },
        }),
      onUpdate: (e, { jobGroupID, blockKey, block }) =>
        updateBlock({
          variables: {
            jobGroupID,
            blockKey,
            input: block,
          },
        }),
      onReview: (e, { stateID, blockKey, status, comment }) =>
        reviewBlock({
          variables: {
            input: {
              stateID,
              blockKey,
              status,
              comment,
            },
          },
        }),
    },
  };
  return state;
}
