export function tableLabel(source, { plural = false, titleCase = false } = {}) {
  let label;
  switch (source.__typename) {
    case 'GoogleSpreadsheet':
    case 'GoogleSheet':
      // fallthrough is intentional
      label = 'Sheet';
      break;
    default:
      label = 'Table';
      break;
  }

  if (titleCase) {
    label = `${label[0].toUpperCase()}${label.substring(1)}`;
  }

  if (plural) {
    label = `${label}s`;
  }

  return label;
}

export function tablesetLabel(source) {
  switch (source.__typename) {
    case 'GoogleSpreadsheet':
      return 'Google Spreadsheet';
    case 'ExcelSpreadsheet':
      return 'Excel Workbook';
    case 'SnowflakeDatabase':
      return 'Snowflake Database';
    case 'DropboxFolder':
      return 'File Folder';
    case 'QuickbooksOnline':
      return 'QuickBooks Online';
    default:
      return 'Database';
  }
}

export function hasExternalTables(source = {}) {
  if (source.__typename === 'NumbrzDatabase') {
    return false;
  }
  return true;
}

export function getTablesetNavItems(source, baseURL) {
  const tablesHeader = tableLabel(source, {
    plural: true,
    titleCase: true,
  });
  const navOptions = [
    {
      text: tablesHeader,
      to: `${baseURL}/tables`,
      key: 'tables',
    },
    {
      text: `Settings`,
      to: `${baseURL}/settings`,
      key: 'settings',
    },
  ];

  return navOptions;
}
