import { gql } from '@apollo/client';

import { FunctionChangesFragment } from './fragments';
import useEditFunction, { optimisticFunctionChanges } from './useEditFunction';
import useBaseWidgetAPI from './useBaseWidgetAPI';

const UpdateJoinConfig = gql`
  mutation UpdateJoinConfig(
    $funcID: CommonID!
    $widgetID: ID!
    $delim: String
    $omitEmpty: Boolean
  ) {
    updateJoinConfigV3(
      funcID: $funcID
      widgetID: $widgetID
      delim: $delim
      omitEmpty: $omitEmpty
    ) {
      ...FunctionChangesFragment
    }
  }
  ${FunctionChangesFragment}
`;

export default function useJoinAPI() {
  const updateJoinConfig = useEditFunction(
    UpdateJoinConfig,
    'updateJoinConfigV3'
  );

  return {
    ...useBaseWidgetAPI(),
    updateJoinConfig: (widget, { delim, omitEmpty }) =>
      updateJoinConfig({
        variables: {
          funcID: widget.funcID,
          widgetID: widget.ID,
          delim,
          omitEmpty,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateJoinConfigV3: optimisticFunctionChanges({
            funcID: widget.funcID,
            widgetsChanged: [
              {
                __typename: 'WidgetV3',
                ...widget,
                config: {
                  ...widget.config,
                  delim,
                  omitEmpty,
                },
              },
            ],
          }),
        },
      }),
  };
}
