import React, { useState } from 'react';
import fp from 'lodash/fp';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';

import { useMutation } from '<src>/apollo/client';

import Button from '<src>/components/Button';
import styled from '@emotion/styled';

const ACCEPT_INVITATION = gql`
  mutation AcceptInvitation($token: String!) {
    acceptInvitation(token: $token) {
      success
    }
  }
`;

const Container = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  button {
    margin-top: 50px;
  }
`;

export default function MemberInvitation() {
  const { token } = useParams();
  const [invitationAccepted, setInvitationAccepted] = useState(false);
  const [acceptInvitation] = useMutation(ACCEPT_INVITATION);

  const handleAcceptInvitation = async () => {
    const res = await acceptInvitation({
      variables: { token },
    });

    if (fp.getOr(undefined, 'data.acceptInvitation.success', res)) {
      setInvitationAccepted(true);
    }
  };

  return (
    <Container>
      <Button
        action="run"
        onClick={handleAcceptInvitation}
        disabled={invitationAccepted}
      >
        {invitationAccepted ? 'Invitation Accepted' : 'Accept Invitation'}
      </Button>
    </Container>
  );
}
