import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form, Input, Message, Radio } from 'semantic-ui-react';

import Button from '<components>/Button';
import Dialog from '<components>/Dialog';
import { MarkdownEditor } from '<components>/EditableMarkdown';

export default function AddReleaseDialog({
  project,
  visible,
  releaseTagValid,
  onClose,
  onCreate,
  onPublishPreview,
  onCheckReleaseTag = () => {},
}) {
  const tagRef = useRef();
  const [releaseTag, setReleaseTag] = useState('');
  const [releaseNotes, setReleaseNotes] = useState('');
  const [error, setError] = useState(null);
  const [creating, setCreating] = useState(false);
  const [publishMode, setPublishMode] = useState(
    project.type === 'Service'
      ? 'release'
      : !project?.catalog?.currentReleaseID
        ? 'preview'
        : 'release'
  );

  const isServiceModel = project.type === 'Service';
  const currentReleaseID = project?.catalog?.currentReleaseID;
  const isInPreview = project?.catalog?.preview;

  const dialogHdr = publishMode === 'release' ? 'Create a release' : 'Publish';
  const btnLbl = publishMode === 'release' ? 'Create' : 'Publish';

  useEffect(() => {
    if (!visible) {
      setReleaseTag('');
      setReleaseNotes('');
      setError(null);
      setCreating(false);
    } else {
      publishMode !== 'preview' && tagRef.current.focus();
    }
  }, [visible, publishMode]);

  useEffect(() => {
    onCheckReleaseTag(releaseTag);
  }, [onCheckReleaseTag, releaseTag]);

  const doCreateRelease = useCallback(async () => {
    setCreating(true);
    const { errors } = await onCreate({
      releaseTag,
      releaseNotes,
      promote: true,
    });
    setCreating(false);
    if (errors && errors.length > 0) {
      setError(errors[0].message);
    } else {
      onClose();
    }
  }, [onClose, onCreate, releaseNotes, releaseTag]);

  const isValid = !error && releaseTag && releaseNotes && releaseTagValid;

  return (
    <Dialog
      onRequestClose={onClose}
      visible={visible}
      style={{ minWidth: '650px' }}
    >
      <Dialog.Header>
        <Dialog.Headline>{dialogHdr}</Dialog.Headline>
        <Button.DialogClose onClick={onClose} />
      </Dialog.Header>
      <Dialog.Body style={{ minWidth: '450px' }}>
        {isInPreview && publishMode === 'preview' && (
          <Message info>
            This model is currently published in the Coming Soon section.
          </Message>
        )}
        {!currentReleaseID && !isServiceModel && (
          <Form style={{ marginBottom: '10px' }}>
            <Form.Field>
              Choose how you would like to publish this model:
            </Form.Field>
            <Form.Field>
              <Radio
                label="Publish to catalog in the Coming Soon section"
                name="publishMode"
                value="preview"
                checked={publishMode === 'preview'}
                onChange={() => setPublishMode('preview')}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label="Publish to catalog by creating a release"
                name="publishMode"
                value="release"
                checked={publishMode === 'release'}
                onChange={() => setPublishMode('release')}
              />
            </Form.Field>
          </Form>
        )}
        {error ? (
          <Message error>{error}</Message>
        ) : publishMode === 'preview' ? null : (
          <>
            {releaseTagValid === false ? (
              <Message error>
                The release tag {releaseTag} has previously been published
              </Message>
            ) : null}
            <Form>
              <Form.Field>
                <label htmlFor="releaseTag">Release Tag (required)</label>
                <Input
                  id="releaseTag"
                  ref={tagRef}
                  value={releaseTag}
                  onChange={(e) => setReleaseTag(e.target.value)}
                />
              </Form.Field>
              <Form.Field>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label>Release Notes (required)</label>
                <MarkdownEditor
                  content={releaseNotes}
                  onChange={(e) => setReleaseNotes(e.target.value)}
                />
              </Form.Field>
            </Form>
          </>
        )}
      </Dialog.Body>
      <Dialog.Buttons>
        <Button
          disabled={
            publishMode === 'preview' && !isInPreview
              ? false
              : !isValid || creating
          }
          onClick={
            publishMode === 'preview' ? onPublishPreview : doCreateRelease
          }
          action="deploy"
        >
          {btnLbl}
        </Button>
      </Dialog.Buttons>
    </Dialog>
  );
}
