import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';

import * as colors from '<components>/colors';
import Button from '<src>/components/Button';

import { ConfigTable } from '../styles';
import ElementLabel from '../ElementLabel';
import FieldPicker from '../FieldPicker';

function AddElement({ addTitle = 'Add', elements, onAdd }) {
  const [selected, setSelected] = useState();
  return (
    <tr>
      <td>
        <FieldPicker.Dropdown
          elements={elements}
          selected={selected}
          showTypeFilter
          onChange={(e, { selected }) => setSelected(selected)}
        />
      </td>
      <td className="btn">
        <Button.IconBtn
          icon={<Icon name="plus" title={addTitle} />}
          disabled={!selected}
          onClick={async (e) => {
            await onAdd(e, selected);
            setSelected(null);
          }}
          baseColor="transparent"
          contentColor={colors.blue2}
          activeColor="transparent"
          contrastColor="transparent"
          bgHoverColor={colors.blue5}
        />
      </td>
    </tr>
  );
}

export default function ElementSelection({
  addTitle,
  elements,
  selected,
  onAdd,
  onRemove,
}) {
  return (
    <ConfigTable className="list">
      <tbody>
        {selected.map((key) => {
          const element = elements.find((e) => e.key === key);
          return (
            <tr key={key}>
              <td>
                <ElementLabel elementKey={key} element={element} />
              </td>
              {onRemove ? (
                <td className="btn">
                  <Button.IconBtn
                    icon={<Icon name="trash alternate outline" />}
                    onClick={(e) => onRemove(e, key)}
                    baseColor="transparent"
                    contentColor={colors.gray2}
                    activeColor="transparent"
                    contrastColor="transparent"
                    bgHoverColor={colors.gray4}
                  />
                </td>
              ) : null}
            </tr>
          );
        })}
        {onAdd ? (
          <AddElement addTitle={addTitle} elements={elements} onAdd={onAdd} />
        ) : null}
      </tbody>
    </ConfigTable>
  );
}
