import React, { useRef, useEffect } from 'react';
import {
  StepDropdownWrapper,
  StepToolbarBtn,
} from '<src>/sections/flows/flow-testing/styles';
import SortRoundedIcon from '@mui/icons-material/SortRounded';
import {
  TextIconBtn,
  PopupMenu,
  MenuHdr,
  ToggleContainer,
  PopupSwitch,
} from '../styles';
import { Icon } from 'semantic-ui-react';

/* Sort Opts:
    inputsFirst: true | false
*/

export default function SortMenu({ visible, setVisible, sort, setSort }) {
  const menuRef = useRef();

  useEffect(() => {
    const menuEl = visible ? menuRef.current : undefined;
    if (menuEl) menuEl.focus();
  }, [visible]);

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') setVisible(false);
  };

  return (
    <StepDropdownWrapper>
      <TextIconBtn
        iconSize="19px"
        iconMargin="0 4px 1px 0"
        padding="2px 8px 2px 6px"
        onClick={() => setVisible(!visible)}
        onMouseDown={(e) => e.preventDefault()}
        expanded={visible}
      >
        <SortRoundedIcon />
        Sort
      </TextIconBtn>

      <PopupMenu
        ref={menuRef}
        tabIndex="-1"
        onBlur={(e) => {
          setVisible(false);
        }}
        visible={visible}
        onKeyDown={handleKeyDown}
      >
        <MenuHdr>
          <span>Sort</span>
          <StepToolbarBtn onClick={() => setVisible(false)}>
            <Icon name="close" />
          </StepToolbarBtn>
        </MenuHdr>
        <ToggleContainer>
          {`Inputs > Outputs`}
          <PopupSwitch
            checked={sort.inputsFirst}
            onClick={(e) => {
              e.preventDefault();
              setSort({ ...sort, inputsFirst: !sort.inputsFirst });
            }}
            onMouseDown={(e) => e.preventDefault()}
          />
        </ToggleContainer>
      </PopupMenu>
    </StepDropdownWrapper>
  );
}
