import moment from 'moment';
import fp from 'lodash/fp';
import { formatDataSourceLabel } from '<sections>/data/utils';

export function findUsageTypes(tbMapping) {
  let usageTypes = {
    inputs: 0,
    outputs: 0,
    filters: 0,
    eraseData: 0,
    eraseWithCriteria: 0,
    skip: 0,
    errors: 0,
  };
  tbMapping.stages.forEach((stage) => {
    if (stage.issues && stage.issues.length > 0)
      usageTypes.errors = stage.issues.length;

    if (stage.input) {
      usageTypes.inputs++;
      if (stage.input.selectWhere && stage.input.selectWhere.length > 0)
        usageTypes.filters++;
    }

    if (stage.steps && stage.steps.length > 0) {
      stage.steps.forEach((step) => {
        if (step.input) {
          usageTypes.inputs++;
          if (step.input.selectWhere && step.input.selectWhere.length > 0)
            usageTypes.filters++;
        }
        if (step.output) {
          usageTypes.outputs++;
          if (step.output.suspended) usageTypes.skip++;
          if (step.output.beforeWrite && step.output.beforeWrite.delete)
            usageTypes.eraseData++;
          if (
            step.output.beforeWrite &&
            step.output.beforeWrite.delete &&
            step.output.beforeWrite.delete.where.length > 0
          )
            usageTypes.eraseWithCriteria++;
        }
      });
    }
  });

  return usageTypes;
}

export function getTableUsage(table = {}) {
  let usageTypes = {
    inputs: 0,
    outputs: 0,
    eraseData: false,
    eraseWithCriteria: 0,
  };
  const eraseData = fp.getOr(
    undefined,
    'onExport.beforeWrite.delete.where',
    table
  );
  if (table.isInput) {
    usageTypes.inputs++;
  }
  if (table.isOutput) {
    usageTypes.outputs++;
    if (eraseData) {
      usageTypes.eraseData = true;
      if (eraseData.length === 0) {
        usageTypes.eraseWithCriteria = 0;
      }
      if (eraseData.length > 0) {
        usageTypes.eraseWithCriteria = eraseData.length;
      }
    }
  }

  return usageTypes;
}

export function formatDurationLabel(duration) {
  let formattedDate = [];
  const date = moment(duration).toObject();
  if (date.days > 0) formattedDate.push(`${date.days}d`);
  if (date.hours > 0) formattedDate.push(`${date.hours}h`);
  if (date.minutes >= 0) formattedDate.push(`${date.minutes}m`);
  if (date.seconds >= 0) formattedDate.push(`${date.seconds}s`);
  return formattedDate.join(' ');
}

export function formatServiceLbl(source) {
  switch (source) {
    case 'UserTable':
      return 'Numbrz Table';
    case 'SQLServer':
      return 'SQL Server';
    case 'Quickbooks':
      return 'QuickBooks Online';
    default:
      return formatDataSourceLabel(source);
  }
}

export function sortMappingsByDataSource(a, b) {
  if (a.dataset.name && b.dataset.name) {
    if (a.dataset.name < b.dataset.name) return -1;
    if (a.dataset.name > b.dataset.name) return 1;
  }

  return 0;
}
