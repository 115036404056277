import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';

import * as colors from '<components>/colors';
import Button from '<src>/components/Button';

import Card from '../../Card';
import { StyledHeaderCell } from '../../Cell';
import Table from '../../Table';

const ignoreSsrWarning =
  '/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */';

const LookupTableName = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  & > * {
    margin-left: 6px;
  }
  & > :first-child${ignoreSsrWarning} {
    margin-left: 0;
  }
`;

const Pane = styled('div')`
  width: 100%;
  margin-bottom: 6px;
`;

const Match = styled('div')`
  display: flex;
  flex-direction: row;
  padding: 3px;
  // width: 225px;

  &:hover {
    background-color: ${(props) => (props.checked ? null : colors.gray3)};
  }
`;

const CardList = styled('ul')`
  list-style: none;
  padding: 0;
  & li {
    margin-top: 11px;
  }
  & li:first-child${ignoreSsrWarning} {
    margin-top: 0;
  }
`;

const LookupTableCard = styled(Card)`
  cursor: pointer;
  &:hover {
    box-shadow: ${colors.blue2} 0px 0px 6px 0px;
  }
`;

const InputDiv = styled('div')`
  display: flex;
  align-items: center;
`;

const LabelDiv = styled('div')`
  padding-left: 8px;
`;

const Name = styled('div')`
  color: ${colors.gray1};
  font-size: 12px;
  font-weight: bold;
`;

const Desc = styled('div')`
  color: ${colors.gray1};
  font-size: 11px;
`;

function MatchChoice({
  widgetID,
  mode,
  selectedMode,
  label,
  children,
  onChange,
  readOnly,
}) {
  const checked = mode === selectedMode;

  return (
    <Match checked={checked}>
      <InputDiv>
        <input
          type="radio"
          name="match"
          value={mode}
          id={`${widgetID}-${mode}`}
          checked={checked}
          disabled={readOnly}
          onChange={(event) => onChange(event.target.value)}
        />
      </InputDiv>
      <LabelDiv>
        <label htmlFor={`${widgetID}-${mode}`}>
          <Name>{label}</Name>
          <Desc>{children}</Desc>
        </label>
      </LabelDiv>
    </Match>
  );
}

function LookupModeSetting({ api, widget }) {
  if (!api.updateMode) {
    return null;
  }
  const matchColumn = widget.config.columns.find(
    (col) => col.mode !== 'Result'
  );
  return (
    <>
      <h4>Lookup Mode</h4>
      <MatchChoice
        widgetID={widget.ID}
        mode="Closest"
        selectedMode={matchColumn.mode}
        label="Closest Match"
        onChange={() => api.updateMode(widget, { mode: 'Closest' })}
      >
        Use the row with the largest lookup value without being larger than the
        input value
      </MatchChoice>
      <MatchChoice
        widgetID={widget.ID}
        mode="Exact"
        selectedMode={matchColumn.mode}
        label="Exact Match"
        onChange={() => api.updateMode(widget, { mode: 'Exact' })}
      >
        Use the first row whose lookup value is equal to the input value
      </MatchChoice>
    </>
  );
}

function CurrentLookupTable({ widget, allowChange, onShowTablePicker }) {
  if (!widget.config.lookupTable) return null;
  return (
    <>
      <h4>Lookup Data</h4>
      <LookupTableName>
        {widget.config.lookupTable.name}
        {allowChange ? (
          <Button.Small onClick={() => onShowTablePicker(true)}>
            Change
          </Button.Small>
        ) : null}
      </LookupTableName>
    </>
  );
}

function LookupTable({ table, onClick }) {
  return (
    <LookupTableCard onClick={onClick}>
      <Card.Header>
        <h4>{table.name}</h4>
      </Card.Header>
      <Table>
        <Table.Head>
          <Table.Row>
            {table.columns.map((col) => (
              <StyledHeaderCell key={col.ID}>
                <div>{col.label}</div>
              </StyledHeaderCell>
            ))}
          </Table.Row>
        </Table.Head>
      </Table>
    </LookupTableCard>
  );
}

function LookupTableList({ tables, onSelect, onCancel }) {
  const sortedTables = useMemo(() => {
    const st = [...tables];
    st.sort((t1, t2) =>
      t1.name.localeCompare(t2.name, {
        ignorePunctuation: true,
        sensitivity: 'base',
      })
    );
    return st;
  }, [tables]);
  return (
    <>
      <h4>
        <div>Select lookup table</div>
        {onCancel ? (
          <Button.Small onClick={onCancel}>Cancel</Button.Small>
        ) : null}
      </h4>
      <CardList>
        {sortedTables.map((table) => (
          <li key={table.ID}>
            <LookupTable table={table} onClick={() => onSelect(table.ID)} />
          </li>
        ))}
      </CardList>
    </>
  );
}

function LookupTableSetting({
  api,
  widget,
  parent,
  showTablePicker,
  onShowTablePicker,
}) {
  if (api.setLookupTable && (showTablePicker || !widget.config.lookupTable)) {
    return (
      <LookupTableList
        tables={parent.data}
        onCancel={
          widget.config.lookupTable ? () => onShowTablePicker(false) : null
        }
        onSelect={(tableID) => {
          onShowTablePicker(false);
          api.setLookupTable(widget, tableID);
        }}
      />
    );
  }

  return (
    <CurrentLookupTable
      widget={widget}
      allowChange={!!api.setLookupTable}
      onShowTablePicker={onShowTablePicker}
    />
  );
}

export default function LookupSettings({ api, widget, parent }) {
  const [showTablePicker, setShowTablePicker] = useState(false);
  return (
    <Pane>
      <LookupTableSetting
        api={api}
        widget={widget}
        parent={parent}
        showTablePicker={showTablePicker}
        onShowTablePicker={(show) => setShowTablePicker(show)}
      />
      {!showTablePicker && widget.config.lookupTable ? (
        <LookupModeSetting api={api} widget={widget} />
      ) : null}
    </Pane>
  );
}
