import React, { useState, useMemo, useCallback } from 'react';
import { useQuery } from '<src>/apollo/client';
import WaveSpinner from '<src>/components/WaveSpinner';
import { Accordion, Checkbox, Icon } from 'semantic-ui-react';

import { GetJobs } from '../../queries';

import {
  StyledBody,
  HeaderContainer,
  StyledAccordion,
  PanelTitle,
  FieldContainer,
  SelectAllContainer,
} from '<sections>/flows/FieldPicker/styles';

import styled from '@emotion/styled';

const StyledHeaderContainer = styled(HeaderContainer)`
  margin: 0 3px 10px 3px;
`;

const StyledContent = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

function FieldGroup({
  expanded: expandedProp,
  flat,
  jobs,
  sourceInfo = {},
  multiSelect,
  selected = [],
  onChange,
}) {
  const [expanded = expandedProp, setExpanded] = useState(false);

  const onSelectElement = useCallback(
    (event, { job, checked }) => {
      if (!multiSelect) {
        if (selected !== job.ID) {
          onChange(event, { selected: job.ID });
        }
        return;
      }

      const currentChecked = selected.includes(job.ID);

      if (checked && !currentChecked) {
        onChange(event, { selected: [...selected, job.ID] });
      } else if (!checked && currentChecked)
        onChange(event, {
          selected: selected.filter((j) => j !== job.ID),
        });
    },
    [multiSelect, selected, onChange]
  );

  return (
    <div>
      {!flat ? (
        <Accordion.Title
          active={expanded}
          onClick={(event) => {
            event.stopPropagation();
            setExpanded(!expanded);
          }}
        >
          <Icon name="dropdown" />
          <PanelTitle>
            <div>{sourceInfo}</div>
          </PanelTitle>
          {multiSelect ? (
            <SelectAll jobs={jobs} selected={selected} onChange={onChange} />
          ) : null}
        </Accordion.Title>
      ) : null}
      <Accordion.Content active={expanded || flat}>
        {jobs.map((job) => {
          const checked = multiSelect
            ? selected.includes(job.ID)
            : selected === job.ID;
          return (
            <FieldContainer
              className="item"
              key={job.ID}
              checked={checked}
              multiSelect={multiSelect}
              onClick={(event) =>
                onSelectElement(event, { checked: !checked, job })
              }
            >
              <span>{job.name}</span>
              <SelectElement
                checked={checked}
                multiSelect={multiSelect}
                onChange={(e, { checked }) =>
                  onSelectElement(e, { checked, job })
                }
              />
            </FieldContainer>
          );
        })}
      </Accordion.Content>
    </div>
  );
}

function SelectAll({ jobs, selected = [], onChange }) {
  const jobIDs = useMemo(() => jobs.map((j) => j.ID), [jobs]);

  const selection = useMemo(
    () => jobIDs.filter((j) => selected.includes(j)),
    [jobIDs, selected]
  );

  const checked = selection.length > 0;
  const indeterminate = checked && selection.length < jobs.length;

  const onSelectAll = useCallback(
    (e, { checked }) => {
      e.stopPropagation();
      const newSelected = selected.filter((j) => !jobIDs.includes(j));
      if (checked) {
        newSelected.push(...jobIDs);
      }
      onChange(e, { selected: newSelected });
    },
    [jobIDs, selected, onChange]
  );

  return (
    <Checkbox
      checked={checked}
      indeterminate={indeterminate}
      onChange={onSelectAll}
    />
  );
}

function SelectElement({ checked, onChange, multiSelect }) {
  if (multiSelect) {
    return <Checkbox checked={checked} onChange={onChange} />;
  }
  if (checked) {
    return <Icon name="check" />;
  }
  return null;
}

export default function JobsBlockPane({
  blockJobs,
  onSelectJob,
  onUnselectJob,
  onChange,
}) {
  const { data: { jobs = [] } = {}, loading } = useQuery(GetJobs);

  const groupedJobs = useMemo(() => {
    const groups = {};

    jobs
      .filter((j) => !j.isBase)
      .forEach((j) => {
        const sourceKey = j.project.name;
        if (!groups[sourceKey]) {
          groups[sourceKey] = [j];
        } else {
          groups[sourceKey].push(j);
        }
      });
    return groups;
  }, [jobs]);

  return loading ? (
    <WaveSpinner />
  ) : (
    <StyledContent>
      <StyledHeaderContainer>
        <SelectAllContainer>
          <span>Select All </span>
          <SelectAll
            jobs={jobs}
            selected={blockJobs}
            onChange={(e, data) => onChange(data.selected)}
          />
        </SelectAllContainer>
      </StyledHeaderContainer>
      <StyledAccordion exclusive={false}>
        <StyledBody>
          {Object.entries(groupedJobs).map(([key, jobs]) => (
            <FieldGroup
              key={key}
              sourceInfo={key}
              flat={false}
              jobs={jobs}
              multiSelect={true}
              selected={blockJobs}
              onChange={(e, data) => onChange(data.selected)}
              expanded={Object.entries(groupedJobs).length === 1}
            />
          ))}
        </StyledBody>
      </StyledAccordion>
    </StyledContent>
  );
}
