import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown, Input } from 'semantic-ui-react';

import Button from '<components>/Button';
import Dialog from '<components>/Dialog';
import { DropdownWrapperWithLabel } from '<components>/NumbrzPageComponents';
import { GetProjects } from '<sections>/projects/queries';
import { useQuery } from '<src>/apollo/client';
import WaveSpinner from '<components>/WaveSpinner';

function SourcePicker({ projects, sourceID, setSourceID }) {
  const options = useMemo(
    () =>
      projects.map((p) => ({
        value: p.ID,
        text: p.name,
      })),
    [projects]
  );
  return (
    <DropdownWrapperWithLabel className="ui labeled" name="flow">
      <div className="ui label">Source Model</div>
      <Dropdown
        closeOnBlur
        options={options}
        onChange={(e, { value }) => setSourceID(value)}
        placeholder={`Select Model`}
        value={sourceID}
      />
    </DropdownWrapperWithLabel>
  );
}

export default function AddServiceProjectDialog({
  visible,
  onClose,
  onCreate,
}) {
  const [name, setName] = useState('');
  const [sourceID, setSourceID] = useState(null);
  const [creating, setCreating] = useState(false);
  const { data, refetch } = useQuery(GetProjects);

  useEffect(() => {
    if (!visible) {
      setName('');
      setSourceID(null);
      setCreating(false);
    } else {
      refetch();
    }
  }, [refetch, visible]);

  const projects = data ? data.projects : null;

  useEffect(() => {
    if (projects && sourceID) {
      const sourceProject = projects.find((p) => p.ID === sourceID);
      if (sourceProject) {
        setName(sourceProject.name);
      }
    }
  }, [projects, sourceID]);

  const valid = !!(sourceID && name && name.trim && name.trim());

  const onSubmit = async (e) => {
    if (!valid) return;

    setCreating(true);
    await onCreate(name.trim(), e, { sourceProjectID: sourceID });
    setCreating(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSubmit(e);
    }
  };

  return (
    <Dialog visible={visible} onRequestClose={onClose}>
      <Dialog.Header>
        <Dialog.Headline>Create a Service Model</Dialog.Headline>
        <Button.DialogClose onClick={onClose} />
      </Dialog.Header>
      <Dialog.Body style={{ width: 'fit-content' }}>
        {!projects ? (
          <WaveSpinner />
        ) : (
          <>
            <SourcePicker
              projects={projects}
              sourceID={sourceID}
              setSourceID={setSourceID}
            />
            <Input
              label="Model Name"
              name="name"
              onChange={({ target: { value } }) => setName(value)}
              onKeyDown={handleKeyDown}
              disabled={creating}
              value={name}
            />
          </>
        )}
      </Dialog.Body>
      <Dialog.Footer>
        <Button id="create" disabled={creating || !valid} onClick={onSubmit}>
          {creating ? 'Creating...' : 'Create'}
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
}
