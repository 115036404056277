import React, { PureComponent } from 'react';
import styled from '@emotion/styled';
import omit from 'lodash/omit';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-ttems: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  max-height: 100vh;
  width: 100%;
  max-width: 100vw;
  background-color: #fff;
`;

const Title = styled('p')`
  font-weight: normal;
`;

export default class ErrorMessage extends PureComponent {
  static Container = Container;
  static Title = Title;

  render() {
    const filteredProps = omit(this.props, ['baseProps', 'error']);
    return (
      <Container {...filteredProps}>
        <Title>Something Went Wrong</Title>
      </Container>
    );
  }
}
