import React, { forwardRef, useMemo } from 'react';

import Step from '../Step';
import { stepsByType } from '../stepData';

import ElementSelection from '../../ElementSelection';

import CalcList from './CalcList';

function AggregateStep(
  { step, api, stageElements: elements = [], ...props },
  parentRef
) {
  const { onChangeAggregate } = api;
  const { aggregations, groupBy } = step;

  const onChange = useMemo(
    () =>
      onChangeAggregate
        ? (e, changes) => onChangeAggregate(e, { key: step.key, ...changes })
        : null,
    [onChangeAggregate, step.key]
  );

  const [onAddCalc, onRemoveCalc] = useMemo(() => {
    if (!onChange) {
      return [null, null];
    }
    const onAddCalc = (e, { calc, field }) => {
      if (
        aggregations.findIndex(
          (a) => a.op === calc && a.fromElementKey === field
        ) === -1
      ) {
        onChange(e, {
          aggregations: [...aggregations, { op: calc, fromElementKey: field }],
        });
      }
    };
    const onRemoveCalc = (e, calc) => {
      const newAggrs = aggregations.filter(
        (a) => a.op !== calc.op || a.fromElementKey !== calc.fromElementKey
      );
      if (newAggrs.length !== aggregations.length) {
        onChange(e, { aggregations: newAggrs });
      }
    };
    return [onAddCalc, onRemoveCalc];
  }, [aggregations, onChange]);

  const [onAddGroupBy, onRemoveGroupBy] = useMemo(() => {
    if (!onChange) {
      return [null, null];
    }
    const onAddGroupBy = (e, key) => {
      if (!groupBy.includes(key)) {
        onChange(e, { groupBy: [...groupBy, key] });
      }
    };
    const onRemoveGroupBy = (e, key) => {
      if (groupBy.includes(key)) {
        onChange(e, { groupBy: groupBy.filter((g) => g !== key) });
      }
    };
    return [onAddGroupBy, onRemoveGroupBy];
  }, [groupBy, onChange]);

  return (
    <Step ref={parentRef} step={step} api={api} {...props}>
      <div />
      <div>
        <h5>Calculate</h5>
        <div>
          <CalcList
            elements={elements}
            calcs={step.aggregations}
            onAddCalc={onAddCalc}
            onRemoveCalc={onRemoveCalc}
          />
        </div>
        <h5>Group By</h5>
        <div>
          <ElementSelection
            addTitle="Add Group By"
            elements={elements}
            selected={groupBy}
            onRemove={onRemoveGroupBy}
            onAdd={onAddGroupBy}
          />
        </div>
      </div>
    </Step>
  );
}

// eslint-disable-next-line no-func-assign
AggregateStep = forwardRef(AggregateStep);
AggregateStep.displayName = 'AggregateStep';
export default AggregateStep;

// Avoid circular dependency
stepsByType.AggregateStepV2.Component = AggregateStep;
