import React, { useMemo, useRef, useEffect, useCallback } from 'react';
import fp from 'lodash/fp';
import useStepResultState from './useStepResultState';
import TestResultCell from '../TestResults/TestResultCell';
import { StyledRouterLink } from '<src>/sections/jobs/styles';
import { Icon, Radio } from 'semantic-ui-react';
import { RoundChiclet } from '<components>/NumbrzButtons';

import {
  StyledContainer,
  ResultContainer,
  ResultContent,
  CellContent,
  LogItemFlags,
  StyledTag,
  TestResultTable,
  StepHdrToolbar,
  StepToolbarBtn,
  ResultsToolbarBtn,
  ResultDataToolbar,
  StepToolBarMenu,
  StepDropdownWrapper,
  StepToolBarFooter,
  InputLink,
} from '../styles';
import { FlexRowContainer } from '<components>/NumbrzPageComponents';

import * as colors from '<components>/colors';
import { includeCol, getFlagLabel } from '../utils';

const FilterDropdown = ({ stepResultState }) => {
  const menuRef = useRef();

  const { filters, flagDialogVisible, setFlagDialogVisible, setFilters } =
    stepResultState;

  const handleToggleFilter = (filter, checked) => {
    setFilters({ ...filters, [filter]: checked });
  };

  const filterCount = useMemo(() => {
    let count = 0;
    for (const f in filters) {
      if (filters[f]) count++;
    }

    return count;
  }, [filters]);

  const filterLabel =
    filterCount > 0
      ? `${filterCount} Filter${filterCount === 1 ? '' : 's'}`
      : 'Filters';

  useEffect(() => {
    const menuEl = flagDialogVisible ? menuRef.current : undefined;
    if (menuEl) menuEl.focus();
  }, [flagDialogVisible]);

  return (
    <StepDropdownWrapper>
      <ResultsToolbarBtn
        icon={<Icon name="filter" />}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setFlagDialogVisible(true);
        }}
        activeColor={filterCount > 0 ? colors.yellow4 : colors.gray4}
        highlightActive={filterCount > 0}
        smallIcon={true}
      >
        {filterLabel}
      </ResultsToolbarBtn>
      {flagDialogVisible ? (
        <StepToolBarMenu
          ref={menuRef}
          tabIndex="-1"
          onBlur={() => setFlagDialogVisible(false)}
        >
          <h5>
            <span>Filters</span>
            <StepToolbarBtn onClick={() => setFlagDialogVisible(false)}>
              <Icon name="close" />
            </StepToolbarBtn>{' '}
          </h5>
          <ul>
            {Object.keys(filters).map((f, idx) => {
              return (
                <li key={idx}>
                  <Radio
                    toggle
                    checked={filters[f]}
                    onClick={(e, { checked }) => {
                      e.preventDefault();

                      handleToggleFilter(f, checked);
                    }}
                    onMouseDown={(e) => e.preventDefault()}
                  />
                  <span>{getFlagLabel(f)}</span>
                </li>
              );
            })}
          </ul>
        </StepToolBarMenu>
      ) : null}
    </StepDropdownWrapper>
  );
};

const FieldsDropdown = ({ stepResultState, stageElements }) => {
  const menuRef = useRef();

  const {
    hideFields,
    setHideFields,
    fieldsDialogVisible,
    setFieldsDialogVisible,
    defaultFields,
  } = stepResultState;

  const handleToggleField = (field, checked) => {
    setHideFields({ ...hideFields, [field]: checked });
  };

  const handleShowAll = (e) => {
    const newFields = { ...hideFields };

    for (const f in hideFields) {
      newFields[f] = false;
    }

    setHideFields(newFields);
    setFieldsDialogVisible(false);
  };

  const hideCount = useMemo(() => {
    let count = 0;
    for (const f in hideFields) {
      if (hideFields[f]) count++;
    }

    return count;
  }, [hideFields]);

  const hideFieldsLabel =
    hideCount > 0
      ? `${hideCount} Hidden field${hideCount === 1 ? '' : 's'}`
      : 'Hide fields';

  useEffect(() => {
    const menuEl = fieldsDialogVisible ? menuRef.current : undefined;
    if (menuEl) menuEl.focus();
  }, [fieldsDialogVisible]);

  return (
    <StepDropdownWrapper>
      <ResultsToolbarBtn
        icon={<Icon name="eye slash outline" />}
        onClick={(e) => {
          e.preventDefault();
          setFieldsDialogVisible(true);
        }}
        highlightActive={hideCount > 0}
        activeColor={hideCount > 0 ? colors.yellow7 : colors.gray4}
      >
        {hideFieldsLabel}
      </ResultsToolbarBtn>
      {fieldsDialogVisible ? (
        <StepToolBarMenu
          ref={menuRef}
          tabIndex="-1"
          onBlur={(e) => {
            e.preventDefault();
            setFieldsDialogVisible(false);
          }}
        >
          <h5>
            <span>Hide Fields</span>
            <StepToolbarBtn onClick={() => setFieldsDialogVisible(false)}>
              <Icon name="close" />
            </StepToolbarBtn>{' '}
          </h5>
          <ul>
            {Object.keys(hideFields).map((f, idx) => {
              return (
                <li key={idx}>
                  <Radio
                    toggle
                    checked={hideFields[f]}
                    onClick={(e, { checked }) => {
                      e.preventDefault();

                      handleToggleField(f, checked);
                    }}
                    onMouseDown={(e) => e.preventDefault()}
                  />
                  <span>{stageElements[f]}</span>
                </li>
              );
            })}
          </ul>
          <StepToolBarFooter>
            <ResultsToolbarBtn
              onClick={(e) => {
                setHideFields(defaultFields);
                setFieldsDialogVisible(false);
              }}
              onMouseDown={(e) => e.preventDefault()}
            >
              Show Default
            </ResultsToolbarBtn>
            <ResultsToolbarBtn
              onClick={handleShowAll}
              onMouseDown={(e) => e.preventDefault()}
            >
              Show All
            </ResultsToolbarBtn>
          </StepToolBarFooter>
        </StepToolBarMenu>
      ) : null}
    </StepDropdownWrapper>
  );
};

export default function StepResultOutput({
  baseURL,
  stepLog,
  step,
  stageID,
  stepKey,
  stageElements,
  jobVariables,
  flags = [],
  stageStepLogs,
  flowTables,
}) {
  const isInput = stepLog.key === '';
  const isOutput = stepLog.toWrite.length > 0;

  const stepElements = fp.getOr([], 'schema.elements', stepLog);
  const logItems = fp.getOr([], 'logItems', stepLog);
  const builtins = [
    '$modelname',
    '$scenario',
    '$flowname',
    '$jobname',
    '$runby',
    '$runid',
    '$started',
  ];

  const jobVars = jobVariables.map((v) => v.key);

  const stepResultState = useStepResultState({
    flags,
    elements: stepElements,
    stepLog,
    builtins,
    jobVars,
  });
  const { resultsExpanded, setResultsExpanded, filters, hideFields } =
    stepResultState;

  const showFlagCol = useMemo(() => {
    let showCol = true;

    Object.keys(filters).forEach((f) => {
      if (filters[f]) showCol = false;
    });

    return showCol;
  }, [filters]);

  const getElLabel = useCallback(
    (stepEl) => {
      if (stageElements[stepEl.key]) return stageElements[stepEl.key];
      return stepEl.label;
    },
    [stageElements]
  );

  const hasValueFilters = Object.keys(filters).length > 0;

  const stepInputLink = useMemo(() => {
    const tableID = fp.getOr(undefined, 'tableID', step);
    const table = flowTables.find((t) => t.ID === tableID);
    const isInput = fp.getOr(undefined, '__typename', step) === 'InputSourceV2';

    if (table) {
      return (
        <FlexRowContainer
          alignItems="center"
          justifyContent="flex-start"
          margin="0 0 0 5px"
        >
          <InputLink
            internal={true}
            withReturn={true}
            to={`${baseURL}/${
              table.prefSource === 'WorkingTable'
                ? 'working-tables'
                : 'job-tables'
            }/${table.ID}`}
          >
            {table.name}
          </InputLink>
          <RoundChiclet
            backgroundColor={isInput ? colors.blue7 : 'transparent'}
            border={`1px solid ${isInput ? colors.blue6 : colors.green3}`}
            textColor={colors.gray2b}
            marginLeft="0px"
          >
            {table.prefSource === 'WorkingTable'
              ? 'Working table'
              : 'Job table'}
          </RoundChiclet>
        </FlexRowContainer>
      );
    }
    return null;
  }, [baseURL, step, flowTables]);

  return (
    <StyledContainer isInput={isInput} isOutput={isOutput}>
      <StepHdrToolbar isInput={isInput} isOutput={isOutput}>
        <FlexRowContainer
          alignItems={'center'}
          justifyContent="space-between"
          width="100%"
        >
          <StyledRouterLink
            margin="0 "
            to={`${baseURL}/stages/${stageID}#${stepKey}`}
          >
            {stepLog.title}
          </StyledRouterLink>
          {stepInputLink}
        </FlexRowContainer>

        <StepToolbarBtn
          icon={
            <Icon
              name={resultsExpanded ? 'compress' : 'expand'}
              style={{ fontWeight: 'bold', width: '20px' }}
              title={resultsExpanded ? 'Minimize' : 'Expand'}
            />
          }
          onClick={() => setResultsExpanded(!resultsExpanded)}
        />
      </StepHdrToolbar>
      <ResultContent>
        <ResultDataToolbar>
          {hasValueFilters && (
            <FilterDropdown stepResultState={stepResultState} />
          )}
          <FieldsDropdown
            stepResultState={stepResultState}
            stageElements={stageElements}
            jobVariables={jobVariables}
          />
        </ResultDataToolbar>

        <ResultContainer expanded={resultsExpanded}>
          <TestResultTable>
            <thead>
              <tr>
                <th />
                {showFlagCol && flags.length > 0 && <th>Filters</th>}
                {
                  /* output steps will contain toWrite array that includes which
                columns will be outputted and we only want to display those
              */
                  stepElements.map((el, schemaIdx) => {
                    const isOutput = stepLog.key === el.source;
                    if (
                      includeCol(
                        isOutput,
                        stepLog.toWrite,
                        stepLog.inputElementKeys,
                        builtins,
                        schemaIdx,
                        el,
                        hideFields
                      )
                    )
                      return <th key={schemaIdx}>{getElLabel(el)}</th>;

                    return null;
                  })
                }
              </tr>
            </thead>
            <tbody>
              {logItems
                .filter((lI) => {
                  if (hasValueFilters) {
                    let showValue = true;
                    lI.flags.forEach((dF) => {
                      if (filters[dF]) showValue = false;
                    });
                    return showValue;
                  }
                  return true;
                })
                .map((logItem, lIdx) => (
                  <tr key={lIdx}>
                    <td className="rowCnt">{lIdx + 1}</td>
                    {showFlagCol && flags.length > 0 && (
                      <td>
                        <CellContent>
                          {logItem.flags.length > 0 && (
                            <LogItemFlags>
                              {logItem.flags.map((flag, flagIdx) => (
                                <StyledTag key={flagIdx} static="true">
                                  {getFlagLabel(flag)}
                                </StyledTag>
                              ))}
                            </LogItemFlags>
                          )}
                        </CellContent>
                      </td>
                    )}

                    {logItem.data.map((d, dIdx) => {
                      const schemaEl = stepLog.schema.elements[dIdx];
                      const isOutput = stepLog.key === schemaEl.source;

                      if (
                        includeCol(
                          isOutput,
                          stepLog.toWrite,
                          stepLog.inputElementKeys,
                          builtins,
                          dIdx,
                          schemaEl,
                          hideFields
                        )
                      )
                        return (
                          <TestResultCell
                            key={dIdx}
                            value={d}
                            cellType={schemaEl ? schemaEl.type : 'String'}
                            cellIndex={dIdx}
                            currentLog={stepLog}
                            logItemIndex={lIdx}
                            schemaEl={schemaEl}
                            schema={stepLog.schema.elements}
                            stepLogs={stageStepLogs}
                            isOutput={isOutput}
                          />
                        );

                      return null;
                    })}
                  </tr>
                ))}
            </tbody>
          </TestResultTable>
        </ResultContainer>
      </ResultContent>
    </StyledContainer>
  );
}
