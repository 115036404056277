import keyBy from 'lodash/keyBy';

import { normalizeLabel } from '<src>/utils/text';

// Normalize a type from our various type systems onto a single
// vocabulary. Useful primarily for compatibility testing
export function normType(type) {
  switch (type) {
    case 'Any':
    case 'Unknown':
    case 'Unspecified':
      return 'Unknown';

    case 'Decimal':
    case 'Integer':
    case 'Number':
      return 'Number';

    case 'Bool':
    case 'Boolean':
      return 'Boolean';

    case 'Date':
    case 'DateTime':
    case 'DateTimeNTZ':
      return 'DateTime';

    case 'String':
      return 'String';

    default:
      return type;
  }
}

export function datasetsType(type) {
  switch (type) {
    case 'Any':
    case 'Unknown':
    case 'Unspecified':
      return 'Unknown';

    case 'Decimal':
    case 'Number':
      return 'Decimal';

    case 'Integer':
      return 'Integer';

    case 'Bool':
    case 'Boolean':
      return 'Boolean';

    case 'Date':
    case 'DateTime':
    case 'DateTimeNTZ':
      return 'DateTime';

    case 'String':
      return 'String';

    default:
      return type;
  }
}

export function functionsType(type) {
  switch (type) {
    case 'Any':
    case 'Unknown':
    case 'Unspecified':
      return 'Any';

    case 'Decimal':
    case 'Integer':
    case 'Number':
      return 'Number';

    case 'Bool':
    case 'Boolean':
      return 'Bool';

    case 'Date':
    case 'DateTime':
    case 'DateTimeNTZ':
      return 'Date';

    case 'String':
      return 'String';

    default:
      return type;
  }
}

export function modelRunnerType(type) {
  switch (type) {
    case 'Any':
    case 'Unknown':
    case 'Unspecified':
      return 'Unspecified';

    case 'Decimal':
    case 'Integer':
    case 'Number':
      return 'Number';

    case 'Bool':
    case 'Boolean':
      return 'Boolean';

    case 'Date':
    case 'DateTime':
    case 'DateTimeNTZ':
      return 'DateTime';

    case 'String':
      return 'String';

    default:
      return type;
  }
}

// Return true if the two types match for the purpose of auto-mapping
export function typesMatch(type1, type2) {
  const ntype1 = normType(type1);
  const ntype2 = normType(type2);

  if (ntype1 === 'Unknown' || ntype2 === 'Unknown') return false;
  return ntype1 === ntype2;
}

// Return true if the two types are compatible for the purpose of mapping
// This is looser than typesMatch()
export function typesCompatible(type1, type2) {
  const ntype1 = normType(type1);
  const ntype2 = normType(type2);

  if (ntype1 === ntype2) {
    // types are compatible with themselves (obv)
    return true;
  }

  if (!ntype1 || !ntype2) {
    // no type is compatible with all types
    return true;
  }

  if (ntype1 === 'Unknown' || ntype2 === 'Unknown') {
    // unknown/unspecified types are compatible with all types
    return true;
  }

  switch (ntype1) {
    // numbers and dates are compatible
    case 'Number':
      return ntype2 === 'Date';
    case 'Date':
      return ntype2 === 'Number';
    default:
      // all other types are incompatible
      return false;
  }
}

export function automap({
  elements,
  mappings,
  availableTargets,
  mappingSource = (m) => m.fromKey,
  mappingTarget = (m) => m.toKey,
  targetKey = (e) => e.key,
  setTarget = (m, tgt) => ({ ...m, toKey: tgt }),
}) {
  const elementsByKey = keyBy(elements, (e) => e.key);
  return mappings.map((m) => {
    if (
      mappingTarget(m) &&
      availableTargets.find((te) => te.key === mappingTarget(m))
    ) {
      return m;
    }
    const src = mappingSource(m);
    const element = elementsByKey[src];
    const target = element
      ? availableTargets.find(
          (te) =>
            typesMatch(element.type, te.type) &&
            normalizeLabel(element.label) === normalizeLabel(te.label)
        )
      : null;
    if (!target) {
      return m;
    }
    return setTarget(m, targetKey(target));
  });
}
