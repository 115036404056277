import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from '@emotion/styled';
import { Dropdown, Icon, Ref } from 'semantic-ui-react';

import * as colors from '<components>/colors';
import Button from '<src>/components/Button';

import { CompactDropdown } from '<src>/components/NumbrzPageComponents';

import { DropdownItemDesc } from '../../styles';
import { ConfigField as ConfigFieldStyle } from '<src>/components/NumbrzVerticalEditor';
import ValuePicker from '../../ValuePicker';

const ignoreSsrWarning =
  '/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */';

const ConfigField = ConfigFieldStyle.withComponent('input');

const Amount = styled('div')`
  display: flex;
  width: 100%;
  & > * {
    margin-left: 4px;
  }
  & > :first-child${ignoreSsrWarning} {
    flex: 1;
    margin-left: 0;
  }
  & > .dropdown {
    width: 65px;
  }
`;

const typeOptions = [
  {
    key: 'NumberSequenceV2',
    value: 'NumberSequenceV2',
    text: 'Number',
  },
  {
    key: 'DateSequenceV2',
    value: 'DateSequenceV2',
    text: 'Date',
  },
];

const dateOptions = [
  {
    key: 'incr-fixed',
    value: 'incr-fixed',
    text: 'Increment',
    content: (
      <div>
        <div>Increment</div>
        <DropdownItemDesc>
          Generate each value in the series by adding the amount specified to
          the previous value
        </DropdownItemDesc>
      </div>
    ),
  },
  {
    key: 'decr-fixed',
    value: 'decr-fixed',
    text: 'Decrement',
    content: (
      <div>
        <div>Decrement</div>
        <DropdownItemDesc>
          Generate each value in the series by subtracting the amount specified
          from the previous value
        </DropdownItemDesc>
      </div>
    ),
  },
];

const numberOptions = [
  ...dateOptions,
  {
    key: 'incr-mult',
    value: 'incr-mult',
    text: 'Proportional Increment',
    content: (
      <div>
        <div>Proportional Increment</div>
        <DropdownItemDesc>
          Generate each value in the series by multiplying the previous value by
          the amount specified, then adding the result to the previous value
        </DropdownItemDesc>
      </div>
    ),
  },
  {
    key: 'decr-mult',
    value: 'decr-mult',
    text: 'Proportional Decrement',
    content: (
      <div>
        <div>Proportional Decrement</div>
        <DropdownItemDesc>
          Generate each value in the series by multiplying the previous value by
          the amount specified, then subtracting the result from the previous
          value
        </DropdownItemDesc>
      </div>
    ),
  },
];

const unitOptions = [
  { key: 'Seconds', value: 'Seconds', text: 'seconds' },
  { key: 'Minutes', value: 'Minutes', text: 'minutes' },
  { key: 'Hours', value: 'Hours', text: 'hours' },
  { key: 'Days', value: 'Days', text: 'days' },
  { key: 'Months', value: 'Months', text: 'months' },
  { key: 'Years', value: 'Years', text: 'years' },
];

function isValid(label, type, startValue, op, unit, amount) {
  return label && type && startValue && op && unit && amount;
}

export default function AddSeries({ elements, onAdd: onAddProp }) {
  const [label, setLabel] = useState('');
  const labelRef = useRef();
  const [type, setType] = useState('');
  const typeRef = useRef();
  const [startValue, setStartValue] = useState();
  const startValRef = useRef();
  const [op, setOp] = useState('');
  const opRef = useRef();
  const [unit, setUnit] = useState();
  const unitRef = useRef();
  const [amount, setAmount] = useState('');
  const amountRef = useRef();

  const opValue = useMemo(() => {
    if (!op) return null;
    const dir = op === 'Increment' ? 'incr' : 'decr';
    const mode =
      type === 'NumberSequenceV2' && unit === 'Multiple' ? 'mult' : 'fixed';
    return `${dir}-${mode}`;
  }, [op, type, unit]);

  useEffect(() => {
    setStartValue('');
    setOp('');
    setAmount('');
    setUnit('');
    if (type === 'NumberSequenceV2') {
      setUnit('Constant');
    } else {
      setUnit(null);
    }
  }, [type]);

  const onAdd = useCallback(
    (e, { label, type, startValue, op, unit, amount }) => {
      if (isValid(label, type, startValue, op, unit, amount)) {
        const dateUnit = type === 'DateSequenceV2' ? unit : undefined;
        const numberUnit = type === 'NumberSequenceV2' ? unit : undefined;
        onAddProp(e, {
          label,
          __typename: type,
          startValue,
          op,
          dateUnit,
          numberUnit,
          amount,
        });
        setLabel('');
        setType('');
        labelRef.current.focus();
      } else if (!label) {
        labelRef.current.focus();
      } else if (!type) {
        typeRef.current.focus();
      } else if (!startValue) {
        startValRef.current.focus();
      } else if (!op) {
        opRef.current.focus();
      } else if (!unit && unitRef.current) {
        unitRef.current.focus();
      } else if (!amount) {
        amountRef.current.focus();
      }
    },
    [onAddProp]
  );

  const onChangeOp = useCallback(
    (e, { value }) => {
      let newUnit = unit;
      let newOp = op;
      switch (value) {
        case 'incr-fixed':
          newOp = 'Increment';
          if (type === 'NumberSequenceV2') {
            newUnit = 'Constant';
          }
          break;
        case 'decr-fixed':
          newOp = 'Decrement';
          if (type === 'NumberSequenceV2') {
            newUnit = 'Constant';
          }
          break;
        case 'incr-mult':
          if (type === 'NumberSequenceV2') {
            newOp = 'Increment';
            newUnit = 'Multiple';
          }
          break;
        case 'decr-mult':
          if (type === 'NumberSequenceV2') {
            newOp = 'Decrement';
            newUnit = 'Multiple';
          }
          break;
        default:
          break;
      }
      setOp(newOp);
      setUnit(newUnit);

      if (e.type === 'keydown' && e.key === 'Enter') {
        onAdd(e, { label, type, startValue, op: newOp, unit: newUnit, amount });
      }
    },
    [amount, label, onAdd, op, startValue, type, unit]
  );

  return (
    <tr>
      <td>
        <ConfigField
          ref={labelRef}
          value={label}
          placeholder="New Series"
          onFocus={(e) => {
            e.target.placeholder = '';
          }}
          onBlur={(e) => {
            e.target.placeholder = 'New Series';
          }}
          onChange={(e) => setLabel(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onAdd(e, { label, type, startValue, op, unit, amount });
            }
          }}
        />
      </td>
      <td>
        <Ref innerRef={typeRef}>
          <Dropdown
            placeholder="Type"
            selectOnBlur={false}
            selectOnNavigation={false}
            options={typeOptions}
            value={type}
            onChange={(e, { value: type }) => {
              setType(type);
              if (e.type === 'keydown' && e.key === 'Enter') {
                onAdd(e, { label, type, startValue, op, unit, amount });
              }
            }}
          />
        </Ref>
      </td>
      <td>
        <ValuePicker
          ref={startValRef}
          disabled={!type}
          elements={elements}
          placeholder="Start Value"
          value={startValue}
          restrictTypes={[type === 'DateSequenceV2' ? 'DateTime' : 'Number']}
          onChange={(e, { value: startValue }) => {
            setStartValue(startValue);
            if (
              (e.type === 'keydown' && e.key === 'Enter') ||
              e.method === 'enterKey'
            ) {
              onAdd(e, { label, type, startValue, op, unit, amount });
            }
          }}
        />
      </td>
      <td>
        <Ref innerRef={opRef}>
          <Dropdown
            disabled={!type}
            placeholder="Operation"
            selectOnBlur={false}
            selectOnNavigation={false}
            options={type === 'NumberSequenceV2' ? numberOptions : dateOptions}
            value={opValue}
            onChange={onChangeOp}
          />
        </Ref>
      </td>
      <td>
        <Amount>
          <ValuePicker
            ref={amountRef}
            disabled={!type}
            elements={elements}
            fluid={false}
            placeholder="Amount"
            value={amount}
            restrictTypes={['Number']}
            onChange={(e, { value: amount }) => {
              setAmount(amount);
              if (
                (e.type === 'keydown' && e.key === 'Enter') ||
                e.method === 'enterKey'
              ) {
                onAdd(e, { label, type, startValue, op, unit, amount });
              }
            }}
          />
          {type === 'DateSequenceV2' ? (
            <CompactDropdown
              ref={unitRef}
              value={unit}
              options={unitOptions}
              placeholder="Unit"
              onChange={(e, { value: unit }) => {
                setUnit(unit);
                if (e.type === 'keydown' && e.key === 'Enter') {
                  onAdd(e, { label, type, startValue, op, unit, amount });
                }
              }}
            />
          ) : null}
        </Amount>
      </td>
      <td className="btn">
        <Button.IconBtn
          icon={<Icon name="plus" title="Add Schema Element" />}
          disabled={!isValid(label, type, startValue, op, unit, amount)}
          onClick={(e) =>
            onAdd(e, { label, type, startValue, op, unit, amount })
          }
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onAdd(e, { label, type, startValue, op, unit, amount });
            }
          }}
          baseColor="transparent"
          contentColor={colors.blue2}
          activeColor="transparent"
          contrastColor="transparent"
          bgHoverColor={colors.blue5}
        />
      </td>
    </tr>
  );
}
