import React, { PureComponent } from 'react';
import styled from '@emotion/styled';
import * as colors from '<components>/colors';

const StyledBrandLogo = styled('h1')`
  position: relative;
  user-select: none;
  color: ${colors.white};
  fontweight: 900;
  text-transform: uppercase;
  letter-spacing: 1.5;
  font-size: 1.125em;
  line-height: 1.25;
  display: block;
  margin-left: 16px;
  color: ${colors.gray5};
  transition: all 250ms ease-in;

  &:after {
    position: absolute;
    content: ' ';
    right: 3.65em;
    left: 0;
    bottom: -0.1em;
    height: 0.1em;
    background: ${colors.blue1};
    transition: all 250ms ease-in;
  }

  &:hover {
    color: ${colors.white};
    &:after {
      right: 0;
    }
  }
`;

export default class BrandLogo extends PureComponent {
  render() {
    return (
      <StyledBrandLogo {...this.props} key="logo">
        Numbrz
      </StyledBrandLogo>
    );
  }
}
