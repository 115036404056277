import { useContext, useEffect, useRef } from 'react';

import { Expanded } from './contexts';

export default function useExpanded(id, startExpanded) {
  const expRef = useRef(startExpanded);
  const { expanded, setExpanded } = useContext(Expanded);

  useEffect(() => {
    if (expRef.current) {
      expRef.current = false;
      setExpanded((expanded) => {
        if (!expanded.includes(id)) return [...expanded, id];
        return expanded;
      });
    }
  }, [id, setExpanded]);

  const expand = (flag) =>
    setExpanded((expanded) => {
      if (!flag && expanded.includes(id))
        return expanded.filter((e) => e !== id);
      if (flag && !expanded.includes(id)) return [...expanded, id];
      return expanded;
    });

  return [expRef.current || expanded.includes(id), expand];
}
