// eslint-disable-next-line import/no-restricted-paths
import React, { useContext } from 'react';
import { compose } from 'recompose';

import { useMutation } from '<src>/apollo/client';

import withLogout from '<components>/hoc/withLogout';
import withFeatureFlags from '<src>/components/Feature/withFeatureFlags';
import { RegisteredAccount } from '<components>/RegisterAccount';
import { SetPrimaryAccount } from '<sections>/account/queries';

import Unconnected from '../components/Masthead';

const withUser = (BaseComp) => {
  const WithUser = (props) => {
    const user = useContext(RegisteredAccount);
    return <BaseComp {...props} user={user} />;
  };
  return WithUser;
};

const Masthead = (props) => {
  const [setPrimaryAccount] = useMutation(SetPrimaryAccount);
  return <Unconnected {...props} setPrimaryAccount={setPrimaryAccount} />;
};

export default compose(withLogout, withUser, withFeatureFlags)(Masthead);
