import React from 'react';

import { useMutation } from '<src>/apollo/client';

import List from '<sections>/projects/pages/ProjectPage/components/lists/List';
import AddJobDialog from '<sections>/projects/pages/ProjectPage/components/AddJobDialog';
import { DeleteJob } from '<sections>/projects/queries';

import { GetServiceProject } from '../../../queries';

function AddServiceJobDialog({ project, ...props }) {
  return (
    <AddJobDialog
      projectID={project.ID}
      sourceProjectID={project.service.sourceProjectID}
      extraJobProps={{ isBase: true }}
      headline="Add a service job"
      modelsPath="/svc-models"
      jobsPath="/svc-jobs"
      allowAutomap={false}
      {...props}
    />
  );
}

export default function ServiceJobs({ project }) {
  const serviceJobs = project.jobs.filter(
    (job) => job.isBase && !job.serviceReleaseID
  );
  const [deleteJob] = useMutation(DeleteJob);

  const projectID = project.ID;

  const onRemoveItem = (itemID) =>
    deleteJob({
      variables: { jobID: itemID },
      update: (cache, { data }) => {
        const { project } = cache.readQuery({
          query: GetServiceProject,
          variables: { ID: projectID },
        });
        const newProject = {
          ...project,
          jobs: project.jobs.filter((j) => j.ID !== itemID),
        };
        cache.writeQuery({
          query: GetServiceProject,
          variables: { ID: projectID },
          data: { project: newProject },
        });
      },
    });

  return (
    <List
      project={project}
      items={serviceJobs}
      removeItem={onRemoveItem}
      AddDialog={AddServiceJobDialog}
      objType="job"
    />
  );
}
