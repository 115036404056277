import gql from 'graphql-tag';

export const JobGroupListItemFragment = gql`
  fragment JobGroupListItemFragment on JobGroup {
    ID
    ownerID
    name
    description
    externalResultURL
    issues {
      code
      message
    }
    runnable
  }
`;

export const JobGroupBlockFragment = gql`
  fragment JobGroupBlockFragment on JobGroupBlock {
    key
    name
    comment
    jobs {
      ID
      projectID
      project {
        name
      }
      name
      comment
      runnable
      externalResultURL
    }
    requireReview
    reviewComment
    reviewTimeoutSecs
  }
`;

export const GetJobGroups = gql`
  query GetJobGroups {
    jobGroups {
      groups {
        ...JobGroupListItemFragment
      }
    }
  }
  ${JobGroupListItemFragment}
`;

export const GetJobGroup = gql`
  query GetJobGroup($ID: CommonID!) {
    jobGroup(ID: $ID) {
      ...JobGroupListItemFragment
      blocks {
        ...JobGroupBlockFragment
      }
    }
  }
  ${JobGroupListItemFragment}
  ${JobGroupBlockFragment}
`;

export const UpdateJobGroup = gql`
  mutation UpdateJobGroup($ID: CommonID!, $input: JobGroupInput!) {
    updateJobGroup(ID: $ID, input: $input) {
      ...JobGroupListItemFragment
      blocks {
        ...JobGroupBlockFragment
      }
    }
  }
  ${JobGroupListItemFragment}
  ${JobGroupBlockFragment}
`;

export const AddBlock = gql`
  mutation AddBlock(
    $jobGroupID: CommonID!
    $beforeBlockKey: ID
    $input: JobGroupBlockInput!
  ) {
    addJobGroupBlock(
      jobGroupID: $jobGroupID
      beforeBlockKey: $beforeBlockKey
      input: $input
    ) {
      ...JobGroupListItemFragment
      blocks {
        ...JobGroupBlockFragment
      }
    }
  }
  ${JobGroupListItemFragment}
  ${JobGroupBlockFragment}
`;

export const RemoveBlock = gql`
  mutation RemoveBlock($jobGroupID: CommonID!, $blockKey: ID!) {
    removeJobGroupBlock(jobGroupID: $jobGroupID, blockKey: $blockKey) {
      ...JobGroupListItemFragment
      blocks {
        ...JobGroupBlockFragment
      }
    }
  }
  ${JobGroupListItemFragment}
  ${JobGroupBlockFragment}
`;

export const UpdateBlock = gql`
  mutation UpdateBlock(
    $jobGroupID: CommonID!
    $blockKey: ID!
    $input: JobGroupBlockInput!
  ) {
    updateJobGroupBlock(
      jobGroupID: $jobGroupID
      blockKey: $blockKey
      input: $input
    ) {
      ...JobGroupListItemFragment
      blocks {
        ...JobGroupBlockFragment
      }
    }
  }
  ${JobGroupListItemFragment}
  ${JobGroupBlockFragment}
`;

export const GetJobs = gql`
  query GetJobs {
    jobs {
      ID
      isBase
      projectID
      project {
        name
      }
      name
    }
  }
`;

const StatusSummaryFragment = gql`
  fragment StatusSummaryFragment on StatusSummary {
    runID
    runByName
    containerID
    bindingID
    status
    elapsed
    started
    ended
    bindingName
    externalResultURL
  }
`;

const JobGroupStateTransitionFragment = gql`
  fragment JobGroupStateTransitionFragment on JobGroupStateTransition {
    type
    timestamp
    userID
    userName
    desc
  }
`;

const JobGroupBlockStateFragment = gql`
  fragment JobGroupBlockStateFragment on JobGroupBlockState {
    key
    state
    name
    requireReview
    jobs {
      ...StatusSummaryFragment
    }
    transitions {
      ...JobGroupStateTransitionFragment
    }
  }
  ${StatusSummaryFragment}
  ${JobGroupStateTransitionFragment}
`;

export const GetJobGroupState = gql`
  query GetJobGroupState($ID: CommonID!, $stateID: ID) {
    jobGroupState(ID: $ID, stateID: $stateID) {
      ID
      groupID
      userID
      userName
      state
      lastActiveTime
      name
      description
      blocks {
        ...JobGroupBlockStateFragment
      }
      transitions {
        ...JobGroupStateTransitionFragment
      }
    }
  }
  ${JobGroupBlockStateFragment}
  ${JobGroupStateTransitionFragment}
`;

export const StartJobGroup = gql`
  mutation StartJobGroup($jobGroupID: CommonID!, $startBlockKey: ID) {
    startJobGroup(jobGroupID: $jobGroupID, startBlockKey: $startBlockKey)
  }
`;

export const CancelJobGroup = gql`
  mutation CancelJobGroup($stateID: ID!) {
    cancelJobGroup(stateID: $stateID)
  }
`;

export const JobGroupHistory = gql`
  query JobGroupHistory($ID: CommonID!, $activeOnly: Boolean, $pageSize: Int) {
    jobGroupHistory(ID: $ID, activeOnly: $activeOnly, pageSize: $pageSize) {
      ID
      groupID
      userID
      userName
      state
      lastActiveTime
      name
      description
      blocks {
        ...JobGroupBlockStateFragment
      }
      transitions {
        ...JobGroupStateTransitionFragment
      }
    }
  }
  ${JobGroupBlockStateFragment}
  ${JobGroupStateTransitionFragment}
`;

export const JobGroupActive = gql`
  query JobGroupActive($ID: CommonID!) {
    jobGroupActive(ID: $ID) {
      ID
      groupID
    }
  }
`;

export const JobGroupActiveByOwner = gql`
  query JobGroupActive {
    jobGroupActiveByOwner {
      ID
      groupID
    }
  }
`;

export const ReviewBlock = gql`
  mutation ReviewBlock($input: ReviewJobGroupBlockInput!) {
    reviewJobGroupBlock(input: $input) {
      ID
      groupID
      userID
      state
      lastActiveTime
      name
      description
      blocks {
        ...JobGroupBlockStateFragment
      }
      transitions {
        ...JobGroupStateTransitionFragment
      }
    }
  }
  ${JobGroupBlockStateFragment}
  ${JobGroupStateTransitionFragment}
`;
