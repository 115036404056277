import React from 'react';

import Pane from './Pane';
import Dialog from './Dialog';

import { UserData } from './connectors';

const modes = {
  UserData: (props) => <UserData {...props} Comp={Pane} />,
  UserDataUnconnected: Pane,
};

modes.UserData.Dialog = (props) => <UserData {...props} Comp={Dialog} />;

export default modes;
