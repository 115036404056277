import React from 'react';

import Cell from '../Cell';

export default function RangeLabel({
  widget,
  appearTrigger,
  range,
  select = () => {},
  onUpdate = () => {},
}) {
  if (!range || !range.normTemplate) {
    return null;
  }
  const cell = {
    ID: range.ID,
    dataType: 'String',
    value: range.normTemplate.label,
  };
  return (
    <Cell
      as="th"
      appearTrigger={appearTrigger}
      isEditable
      cell={cell}
      parseValue={(value) => ({ dataType: 'String', value })}
      onUpdate={(_, { value }) => onUpdate(widget, range, { label: value })}
      onConfirm={() => {
        select({
          type: 'CELL',
          ID: range.cells[0].ID,
        });
      }}
    />
  );
}
