import React from 'react';

import { useQuery } from '<src>/apollo/client';

import FlowPage from '<sections>/flows/FlowPage';

import WaveSpinner from '<components>/WaveSpinner';

import { GetProjectName } from '../../queries';

export default function ProjectFlowPage({ projectID, flowID, history, match }) {
  const { data, error, loading } = useQuery(GetProjectName, {
    variables: { ID: projectID },
  });

  if (error) {
    throw error;
  }
  if (loading) {
    return <WaveSpinner />;
  }

  const { project } = data;

  return (
    <FlowPage
      baseURL={`/models/${project.ID}`}
      project={project}
      flowID={flowID}
      history={history}
      match={match}
    />
  );
}
