/** @jsxRuntime classic */
/** @jsx jsx */

import { forwardRef } from 'react';
import fp from 'lodash/fp';
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';

import * as colors from '<components>/colors';
import * as resets from '<components>/resets';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';

function StyledLink(
  {
    styleProps: {
      isInternal,
      withReturn,
      isBlue,
      fontWeight,
      isLarge,
      isSmall,
      isRound,
      isButton,
      onlyIcon,
      hasIcon,
      iconOnRight,
      iconOnLeft,
      textTransform,
      justifyContent,
    },
    to,
    returnTo,
    ...rest
  },
  parentRef
) {
  const history = useHistory();

  const anchorHash = fp.getOr(undefined, 'location.hash', returnTo);
  const pathname = fp.getOr(undefined, 'location.pathname', returnTo);
  const Comp = withReturn || isInternal ? RouterLink : 'a';

  return (
    <Comp
      {...rest}
      to={to}
      target={rest.href && '_blank'}
      returnTo={returnTo}
      ref={parentRef}
      onClick={(e) => {
        if (anchorHash && pathname && !(e.ctrlKey || e.metaKey || e.shiftKey)) {
          history.replace({
            pathname: `${pathname}#${anchorHash.replace('#', '')}`,
          });
        }
      }}
      css={css({
        ...resets.button,
        boxSizing: 'border-box',
        display: hasIcon ? 'inline-flex' : 'block',
        alignItems: 'center',
        justifyContent: justifyContent || 'center',
        fontSize: isSmall ? '12px' : isLarge ? '16px' : '13px',
        fontWeight: fontWeight ? fontWeight : 'bold',
        textTransform: textTransform,
        textDecoration: 'none',
        letterSpacing: isSmall ? 0.5 : 1,
        color: isBlue ? colors.blue2 : colors.gray1,
        background: 'transparent',
        borderRadius: isRound ? '50px' : '4px',
        whiteSpace: hasIcon ? 'nowrap' : 'wrap',
        overflowWrap: 'break-word',
        padding: onlyIcon ? '4px 5px 5px 5px' : isButton ? '6px 12px' : '0px',
        borderColor: isBlue ? colors.blue2 : colors.gray1,
        border: isButton ? '1px solid' : 'none',

        cursor: 'pointer',

        transition: 'all 125ms ease-in',
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        i: {
          fontSize: '1em',
          margin: onlyIcon
            ? '0'
            : iconOnRight
              ? '0 0 0 3px'
              : iconOnLeft
                ? '0 3px 0 0'
                : '0',
          lineHeight: 'normal !important',
          paddingLeft: '1px',
          ...(isLarge && { paddingBottom: '1x' }),
        },
        svg: {
          fontSize: '1.1em',
          margin: onlyIcon
            ? '0'
            : iconOnRight
              ? '0 0 0 5px'
              : iconOnLeft
                ? '0 5px 0 0'
                : '0',
        },

        '&:hover': {
          color: colors.blue1,
          borderColor: colors.blue1,
        },
      })}
    />
  );
}
// eslint-disable-next-line no-func-assign
StyledLink = forwardRef(StyledLink);
StyledLink.displayName = 'StyledLink';

/*
Design Spec
- internal: Boolean (uses LinkAndReturnTo component if true)
- secondary: Boolean (used for links that are sublabels or that are nested below the main link)
- size: small/normal/large
- icon:  jsx
- iconPosition: left/right
- content: text or children components
- textTransform: lowercase/uppercase
- justifyContent: flex justifyContent
*/

function Link(props, parentRef) {
  const {
    fontWeight,
    blue = false,
    size = 'normal',
    button = false,
    icon,
    iconPosition = 'right',
    action,
    children: content,
    textTransform,
    justifyContent,
    round,
    disabled,
    disabledStyles,
    ...rest
  } = props;

  const styleProps = {
    isBlue: blue || rest.href,
    isLarge: size === 'large',
    isSmall: size === 'small',
    isNormal: !size || size === 'normal',
    isButton: button,
    isRound: !!round,
    hasIcon: !!icon,
    noIcon: !icon,
    onlyIcon: !!icon && !content,
    hasContent: !!content,
    noContent: !content,
    onlyContent: !!content && !icon,
    iconOnRight: !!icon && iconPosition === 'right',
    iconOnLeft: !!icon && iconPosition === 'left',
    textTransform: textTransform ? textTransform : 'none',
    disabled,
    disabledStyles,
    justifyContent,
    isInternal: rest.to !== undefined,
    withReturn: rest.returnTo !== undefined,
    fontWeight,
  };

  const Component = StyledLink;

  if (styleProps.onlyIcon)
    return (
      <Component
        {...rest}
        ref={parentRef}
        styleProps={styleProps}
        disabled={disabled}
      >
        {icon}
      </Component>
    );

  if (styleProps.iconOnLeft)
    return (
      <Component
        {...rest}
        ref={parentRef}
        styleProps={styleProps}
        disabled={disabled}
      >
        {icon}
        {content}
      </Component>
    );

  if (styleProps.iconOnRight)
    return (
      <Component
        {...rest}
        ref={parentRef}
        styleProps={styleProps}
        disabled={disabled}
      >
        {content}
        {icon}
      </Component>
    );

  if (styleProps.onlyContent)
    return (
      <Component
        {...rest}
        ref={parentRef}
        styleProps={styleProps}
        disabled={disabled}
      >
        {content}
      </Component>
    );

  if (styleProps.noIcon && styleProps.noContent)
    return (
      <Component
        {...rest}
        ref={parentRef}
        styleProps={styleProps}
        disabled={disabled}
      />
    );

  return (
    <Component
      {...rest}
      ref={parentRef}
      styleProps={styleProps}
      disabled={disabled}
    >
      {icon}
      <span>{content}</span>
    </Component>
  );
}
// eslint-disable-next-line no-func-assign
Link = forwardRef(Link);
Link.displayName = 'Link';

function makeVariant(displayName, extraProps) {
  function Variant(props, parentRef) {
    return <Link {...extraProps} {...props} ref={parentRef} />;
  }
  // eslint-disable-next-line no-func-assign
  Variant = forwardRef(Variant);
  Variant.displayName = displayName;
  return Variant;
}

Link.Secondary = makeVariant('Link', {
  fontWeight: 'normal',
  size: 'small',
});
Link.Blue = makeVariant('Link', { blue: true });
Link.External = makeVariant('Link', { blue: true, target: '_blank' });

Link.ExternalResults = (props) => (
  <Link.External
    title="Open external results"
    icon={<OpenInNewRoundedIcon />}
    iconPosition="right"
    fontWeight="normal"
    {...props}
  >
    {props.label ? props.label : 'Results'}
  </Link.External>
);

const StyledBackLink = styled(makeVariant('Link', {}))`
  margin-left: 15px;
  i {
    font-size: 13px;
  }
`;

Link.SidebarBack = (props) => (
  <StyledBackLink
    icon={<Icon name="arrow left" />}
    iconPosition="left"
    {...props}
  />
);

export default Link;
