import React from 'react';
import MainLayout from '<components>/MainLayout';
import DocumentTitle from 'react-document-title';
import BlankMasthead from '<src>/components/Masthead/components/BlankMasthead';
import { PageContainer, Content } from '<components>/NumbrzPageComponents';
import { Message } from 'semantic-ui-react';
import styled from '@emotion/styled';

const LinkContainer = styled('div')`
  display: flex;
  max-width: 50%;
  justify-content: flex-start;
  align-items: baseline;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  span {
    margin: 0 10px;
    font-weight: 700;
  }
  button {
    color: rgb(65, 131, 196);
    border: none;
    background: none;
    cursor: pointer;
    line-height: 1.28571429em;
    font-size: 1.1rem;
    padding: 0;
    margin: 0;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  }
  button:focus {
    outline: none;
  }
  button:hover {
    text-decoration: underline;
  }
  a {
    color: rgb(65, 131, 196);
    text-decoration: none;
    cursor: pointer;
    line-height: 1.28571429em;
    font-size: 1.1rem;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  }
  a:hover {
    text-decoration: underline;
  }
`;

const showIntercom = () =>
  window.Intercom(
    'showNewMessage',
    `An error occurred. Can you provide assistance? `
  );

const ErrorPage = ({ onClear, content }) => {
  return (
    <MainLayout
      hideMenu={true}
      navigation={false}
      header={() => {
        return (
          <DocumentTitle title={`Something went wrong`}>
            <BlankMasthead />
          </DocumentTitle>
        );
      }}
      main={() => {
        return (
          <PageContainer>
            <Content>
              <Message error>
                {content ? (
                  content
                ) : (
                  <h4>Something unexpected happened. We're looking into it.</h4>
                )}

                <LinkContainer>
                  <a href="/" title="Back to Numbrz Home">
                    Numbrz Home
                  </a>
                  <span>OR</span>
                  <button onClick={() => showIntercom()}>Get Help</button>
                  {__DEV__ ? (
                    <>
                      <span>OR</span>
                      <button onClick={onClear}>Dismiss</button>
                    </>
                  ) : null}
                </LinkContainer>
              </Message>
            </Content>
          </PageContainer>
        );
      }}
    />
  );
};
export default ErrorPage;
