import Settings from './Settings';
import Body from './Body';
import {
  EditorAPI,
  initialSetupState,
  makeSetupAPI,
  makeUIWidget,
} from './api';
import Label from './Label';

export const base = {
  ID: 'BUILTIN:ARITHMETIC',
  type: 'Arithmetic',
  builtin: true,
  wizardExclude: true,
  name: 'Arithmetic',
  description:
    'Compute sums, differences, products or quotients of lists of values.',
  makeSetupAPI,
  makeUIWidget,
  EditorAPI,
  Label,
  Settings,
  Body,
};

export const add = {
  ...base,
  ID: 'BUILTIN:ARITHMETIC:ADD',
  name: 'Add',
  type: 'Add',
  description: 'Add values.',
  initialSetupState: initialSetupState('Add'),
  wizardExclude: false,
};

export const subtract = {
  ...base,
  ID: 'BUILTIN:ARITHMETIC:SUB',
  name: 'Subtract',
  type: 'Subtract',
  description: 'Subtract values.',
  initialSetupState: initialSetupState('Subtract'),
  wizardExclude: false,
};

export const multiply = {
  ...base,
  ID: 'BUILTIN:ARITHMETIC:MUL',
  name: 'Multiply',
  type: 'Substract',
  description: 'Multiply values.',
  initialSetupState: initialSetupState('Multiply'),
  wizardExclude: false,
};

export const divide = {
  ...base,
  ID: 'BUILTIN:ARITHMETIC:DIV',
  name: 'Divide',
  type: 'Divide',
  description: 'Divide values.',
  initialSetupState: initialSetupState('Divide'),
  wizardExclude: false,
};
