import { gql } from '@apollo/client';

import { FunctionChangesFragment } from './fragments';
import useEditFunction, { optimisticFunctionChanges } from './useEditFunction';

const UpdateCellValue = gql`
  mutation UpdateCellValue(
    $funcID: CommonID!
    $cellID: ID!
    $dataType: CellType
    $value: Any
  ) {
    updateCellValueV3(
      funcID: $funcID
      cellID: $cellID
      dataType: $dataType
      value: $value
    ) {
      ...FunctionChangesFragment
    }
  }
  ${FunctionChangesFragment}
`;

export default function useUpdateCellValue() {
  const updateCellValue = useEditFunction(UpdateCellValue, 'updateCellValueV3');

  return (cell, { dataType, value }) =>
    updateCellValue({
      variables: {
        funcID: cell.funcID,
        cellID: cell.ID,
        dataType,
        value,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateCellValueV3: optimisticFunctionChanges({
          funcID: cell.funcID,
          cellsChanged: [
            {
              __typename: 'CellV3',
              ...cell,
              dataType: dataType ? dataType : cell.dataType,
              value,
            },
          ],
        }),
      },
    });
}
