import styled from '@emotion/styled';
import * as colors from '<components>/colors';

const ignoreSsrWarning =
  '/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */';

export const InvoiceContainer = styled('div')`
  padding: 20px;
`;

export const InvoiceProcessingStatus = styled('div')`
  @keyframes fade {
    from {
      opacity: 0;
      transform: scale3D(0.95, 0.95, 0.95);
    }
    to {
      opacity: 1;
      transform: scale3D(1, 1, 1);
    }
  }
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20%;
  min-height: 50px;
  width: 100%;
  background: ${colors.gray1b};
  border-radius: 3px;
  color: white;
  font-size: 1em;
  margin-bottom: 15px;
  animation: fade 200ms ease-out;
  div {
    display: flex;
    align-items: center;
    span {
      margin-left: 10px;
    }
  }
`;

export const Form = styled('form')`
  @keyframes fade {
    from {
      opacity: 0;
      transform: scale3D(0.95, 0.95, 0.95);
    }
    to {
      opacity: 1;
      transform: scale3D(1, 1, 1);
    }
  }
  @keyframes fade-out {
    from {
      opacity: 1;
      transform: scale3D(0.95, 0.95, 0.95);
    }
    to {
      opacity: 0;
      transform: scale3D(1, 1, 1);
    }
  }

  margin-bottom: 20px;
  ${(props) =>
    props.hide
      ? 'animation: fade-out 200ms ease-out;'
      : 'animation: fade 200ms ease-out;'};
`;

export const FormGroup = styled('fieldset')`
  margin: 0 0 20px 0;
  padding: 0;
  border-style: none;
  background-color: white;
  border: 1px solid ${colors.gray1b};
  will-change: opacity, transform;

  border-radius: 4px;
`;

export const InputGroup = styled('div')`
  display: flex;
  flex-direction: column;

  div.input {
    height: 42px;

    border-radius: 4px;
    margin: 0 0 10px 0;
    div.label {
      width: 30%;
      display: flex;
      align-items: center;
    }
  }
`;

export const FormRow = styled('div')`
  display: flex;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid ${colors.gray3};
  :first-child${ignoreSsrWarning} {
    border-top: none;
  }
`;
export const FormRowLabel = styled('label')`
  width: 20%;
  min-width: 140px;
  padding: 11px 0;
  color: ${colors.gray1b};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FormRowInput = styled('input')`
  @keyframes void-animation-out {
    0%,
    to {
      opacity: 1;
    }
  }
  @keyframes autofill {
    0%,
    100% {
      background: transparent;
    }
  }
  font-size: 16px;
  width: 100%;
  padding: 11px 15px 11px 0;
  color: ${colors.gray1b};
  background-color: white;
  animation: 1ms void-animation-out;
  ::placeholder {
    color: ${colors.gray3};
  }
  border: none;
  outline: none;
  :hover {
    background: ${colors.blue5};
  }
  :active,
  :focus {
    border: none;
    outline: none;
    border-bottom: 1px solid ${colors.blue3};
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:active,
  :-webkit-autofill:focus {
    background-color: #ffffff !important;
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
  }
`;

export const StyledSubmitButton = styled('button')`
  display: block;
  font-size: 16px;
  width: 100%;
  height: 40px;
  margin: 30px 0 0 0;
  background-color: ${colors.gray1b};
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 0px 0 #ffb9f6;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow;
  :active {
    background-color: ${colors.gray2b};
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
      inset 0 1px 0 #e298d8;
    transform: scale(0.99);
  }
  :disabled {
    cursor: not-allowed;
    background-color: ${colors.gray2b};
    box-shadow: none;
  }
  &.SubmitButton--error {
    transform: translateY(15px);
  }
  &.SubmitButton--error:active {
    transform: scale(0.99) translateY(15px);
  }
`;

export const ErrorContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ErrorMessage = styled('div')`
  color: ${colors.red1c};
  border: 1px solid ${colors.red1c};
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 15px;
  font-size: 13px;
  margin: 0px 0px 20px 20px;
  transform: translateY(-15px);
  opacity: 0;
  animation: fade 150ms ease-out;
  animation-delay: 50ms;
  animation-fill-mode: forwards;
  will-change: opacity, transform;
  svg {
    margin-right: 10px;
  }
`;

export const ResetButton = styled('button')`
  border: 0;
  cursor: pointer;
  background: transparent;
`;

export const CardForm = styled('div')`
  width: 100%;
  padding: 11px 15px;
`;

export const PaymentDropdownContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  .dropdown {
    width: 100%;
    height: 41px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    border: 1px solid ${colors.gray1b};
    border-radius: 4px;
    i {
      border: none;
    }
  }
`;

export const AlternatePaymentSettings = styled('div')`
  display: flex;
  color: ${colors.gray1b};
  align-items: center;
  margin-top: 10px;
  .checkbox {
    margin-right: 10px;
  }
`;

export const SavingsLbl = styled('span')`
  margin: 0 5px;
  padding: 1px 5px;
  background: ${colors.green2};
  border-radius: 10px;
  font-weight: bold;
`;
