import React, {
  useEffect,
  useRef,
  useState,
  Fragment,
  useCallback,
} from 'react';
import fp from 'lodash/fp';
import {
  useLocation,
  useRouteMatch,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Form, Radio } from 'semantic-ui-react';
import ActionMenu from '<components>/ActionMenu';

import { ConfigTable, ConfigurationWrapper } from '../styles';
import Button from '<src>/components/Button';
import { ConfigField } from '<src>/components/NumbrzVerticalEditor';

import ColumnTypePicker from '../ColumnTypePicker';
import ValidationIssues from '../ValidationIssues';
import AddColumn from './AddColumn';
import CopyColumnsDialog from './CopyColumnsDialog';
import useColumnOps from './useColumnOps';
import Conditions from '<sections>/flows/Conditions';
import Description from '<src>/components/Description';
import UnderlinedHeader from '<src>/components/UnderlinedHeader';

const columnTypeOptions = [
  { value: 'String', text: 'String' },
  { value: 'Number', text: 'Number' },
  { value: 'DateTime', text: 'Date' },
  { value: 'Boolean', text: 'Boolean' },
];

export default function TableEditor({
  table,
  dataTables = [],
  dataTablesLoading,
  api = {},
  jobVariables = [],
}) {
  const readOnly = table.isIncluded;
  const location = useLocation();
  const match = useRouteMatch();
  const [showDataPicker, setShowDataPicker] = useState(false);
  const truncate = fp.getOr(false, 'onExport.beforeWrite.delete', table);

  const { onChange } = api;
  const { issues, schema } = table;

  const { onAddColumn, onRemoveColumn, onChangeColumn } = useColumnOps(
    schema,
    onChange
  );

  const nameRef = useRef();
  const canEdit = !!onChange || !readOnly;

  const envElements = schema.elements.concat(jobVariables).map((el) => {
    const newEl = { ...el };

    newEl.sourceInfo =
      el.source === ''
        ? { key: table.name, label: table.name }
        : {
            key: el.source,
            label:
              el.source === '#builtin'
                ? 'Job Details'
                : el.source === '#userenv'
                  ? 'Job Variables'
                  : 'Unidentified Source',
          };
    return newEl;
  });

  const tableImportsOpts = table.onLoad || { selectWhere: [] };
  const tableExportOpts = table.onExport || {
    beforeWrite: { delete: { where: [] } },
  };

  const importFilters = tableImportsOpts.selectWhere;
  const exportFilters = fp.getOr(
    [],
    'beforeWrite.delete.where',
    tableExportOpts
  );

  const [
    importSettings = importFilters.length > 0
      ? 'import_criteria'
      : 'import_all',
    setImportSettings,
  ] = useState();
  const [
    exportSettings = exportFilters.length > 0
      ? 'erase_criteria'
      : truncate
        ? 'erase_all'
        : 'do_not_erase',
    setExportSettings,
  ] = useState();
  const [mRequired = table.required, setMRequired] = useState();

  useEffect(() => {
    if (table.required && !mRequired) setMRequired(true);
    if (!table.required && mRequired) setMRequired(false);
    if (canEdit && location.search === '?new' && nameRef.current) {
      nameRef.current.edit(true);
    }
  }, [canEdit, location.search, location.pathname, table, mRequired]);

  const btnCol = !!onAddColumn || !!onRemoveColumn;

  const onFilterChange = useCallback(
    ({ conditions }, filterType) => {
      let changes;
      if (filterType === 'input') {
        changes = { selectWhere: conditions };
        onChange(null, { onLoad: changes });
      }
      if (filterType !== 'input') {
        changes = {
          beforeWrite: {
            delete: {
              where: conditions,
            },
          },
        };
        onChange(null, { onExport: changes });
      }
    },
    [onChange]
  );

  const onTruncateChange = useCallback(
    (checked) => {
      const changes = {
        beforeWrite: {
          delete: checked ? {} : null,
        },
      };

      onChange(null, { onExport: changes });
    },
    [onChange]
  );

  return (
    <Fragment>
      <ValidationIssues
        issues={issues}
        header="This table has errors"
        margin="0 0 20px 0"
        wide
      />

      <ConfigurationWrapper>
        <Switch>
          <Route path={`${match.url}/documentation`}>
            {() => (
              <Description
                noContainer
                content={table.comment}
                onChange={(comment) => onChange(null, { comment })}
                placeholder="No description provided"
                editable={!readOnly}
                label="documentation"
              />
            )}
          </Route>
          <Route path={`${match.url}/columns`}>
            {() => (
              <Fragment>
                <UnderlinedHeader
                  justifyContent="space-between"
                  margin="0 0 5px 0"
                >
                  <div>COLUMNS</div>
                  {onChange && !readOnly ? (
                    <ActionMenu
                      options={[
                        ActionMenu.copyOption({
                          onSelect: () => setShowDataPicker(true),
                          text: 'Copy columns',
                        }),
                      ]}
                    />
                  ) : null}
                </UnderlinedHeader>
                <ConfigTable style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th className="field">Name</th>
                      <th className="type">Type</th>
                      <th>Note</th>
                      {btnCol ? <th className="btn" /> : null}
                    </tr>
                  </thead>
                  <tbody>
                    {schema.elements.map(({ key, ...element }) => (
                      <tr key={key}>
                        <td>
                          <ConfigField
                            singleClick
                            value={element.label}
                            disabled={!onChangeColumn || readOnly}
                            onSubmit={(e) =>
                              onChangeColumn(null, { key, label: e.value })
                            }
                          />
                        </td>
                        <td className="type">
                          {!!onChangeColumn && !readOnly ? (
                            <ColumnTypePicker
                              type={element.type}
                              columnTypeOptions={columnTypeOptions}
                              onChange={(e, { type }) =>
                                onChangeColumn(e, { key, type })
                              }
                            />
                          ) : (
                            element.type
                          )}
                        </td>
                        <td>
                          <ConfigField
                            singleClick
                            value={element.comment}
                            disabled={!onChangeColumn || readOnly}
                            onSubmit={(e) =>
                              onChangeColumn(null, { key, comment: e.value })
                            }
                          />
                        </td>
                        {btnCol ? (
                          <td className="btn">
                            {onRemoveColumn && !readOnly ? (
                              <Button.TableDeleteBtn
                                onClick={(e) => onRemoveColumn(e, { key })}
                              />
                            ) : null}
                          </td>
                        ) : null}
                      </tr>
                    ))}
                    {onAddColumn && !readOnly ? (
                      <AddColumn
                        onAddColumn={onAddColumn}
                        columnTypeOptions={columnTypeOptions}
                      />
                    ) : null}
                  </tbody>
                </ConfigTable>
              </Fragment>
            )}
          </Route>
          <Route path={`${match.url}/import-settings`}>
            {() => (
              <Fragment>
                <UnderlinedHeader margin="20px 0 5px 0">
                  SETTINGS
                </UnderlinedHeader>
                <Fragment>
                  <Form>
                    <Form.Field>
                      <Radio
                        label="Import all data"
                        name="import_settings"
                        value="import_all"
                        checked={importSettings === 'import_all'}
                        onChange={() => setImportSettings('import_all')}
                        disabled={readOnly}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Radio
                        label="Import data matching conditions"
                        name="import_settings"
                        value="import_criteria"
                        checked={importSettings === 'import_criteria'}
                        onChange={() => setImportSettings('import_criteria')}
                        disabled={readOnly}
                      />
                    </Form.Field>
                  </Form>
                  {importSettings === 'import_criteria' && (
                    <Fragment>
                      <UnderlinedHeader margin="20px 0 5px 0">
                        CONDITIONS
                      </UnderlinedHeader>
                      <Conditions
                        elements={schema.elements}
                        vElements={envElements}
                        conditions={importFilters}
                        onChange={(e, newFilters) =>
                          onFilterChange(newFilters, 'input')
                        }
                        flatPicker={true}
                        vFlatPicker={false}
                        wide
                        readOnly={readOnly}
                      />
                    </Fragment>
                  )}
                </Fragment>
              </Fragment>
            )}
          </Route>
          <Route path={`${match.url}/export-settings`}>
            {() => (
              <Fragment>
                <UnderlinedHeader margin="20px 0 5px 0">
                  SETTINGS
                </UnderlinedHeader>
                <Fragment>
                  <Form>
                    <Form.Field>
                      <Radio
                        label="Do not erase any data"
                        name="export_settings"
                        value="do_not_erase"
                        checked={exportSettings === 'do_not_erase'}
                        onChange={() => {
                          setExportSettings('do_not_erase');
                          onTruncateChange(false);
                        }}
                        disabled={readOnly}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Radio
                        label="Erase all data"
                        name="export_settings"
                        value="erase_all"
                        checked={exportSettings === 'erase_all'}
                        onChange={() => {
                          setExportSettings('erase_all');
                          onTruncateChange(true);
                        }}
                        disabled={readOnly}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Radio
                        label="Erase data matching conditions"
                        name="export_settings"
                        value="erase_criteria"
                        checked={exportSettings === 'erase_criteria'}
                        onChange={() => {
                          setExportSettings('erase_criteria');
                          onTruncateChange(true);
                        }}
                        disabled={readOnly}
                      />
                    </Form.Field>
                  </Form>
                  {exportSettings === 'erase_criteria' && (
                    <Fragment>
                      <UnderlinedHeader margin="20px 0 5px 0">
                        CONDITIONS
                      </UnderlinedHeader>
                      <Conditions
                        elements={schema.elements}
                        vElements={envElements}
                        conditions={exportFilters}
                        onChange={(e, newFilters) =>
                          onFilterChange(newFilters, 'output')
                        }
                        flatPicker={true}
                        vFlatPicker={false}
                        wide
                        readOnly={readOnly}
                      />
                    </Fragment>
                  )}
                </Fragment>
              </Fragment>
            )}
          </Route>
          <Route path={`${match.url}/job-settings`}>
            {() => (
              <Fragment>
                <UnderlinedHeader margin="20px 0 5px 0">
                  JOB MAPPING SETTINGS
                </UnderlinedHeader>
                <Form>
                  <Form.Field>
                    <Radio
                      label="Required"
                      name="mapping_settings"
                      value="required"
                      checked={mRequired}
                      onChange={() => {
                        setMRequired(true);
                        onChange(null, { required: true });
                      }}
                      disabled={readOnly}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label="Optional"
                      name="mapping_settings"
                      value="optional"
                      checked={!mRequired}
                      onChange={() => {
                        setMRequired(false);
                        onChange(null, { required: false });
                      }}
                      disabled={readOnly}
                    />
                  </Form.Field>
                </Form>
              </Fragment>
            )}
          </Route>
          <Route>{() => <Redirect to={`${match.url}/columns`} />}</Route>
        </Switch>
      </ConfigurationWrapper>

      {onChange && !readOnly && showDataPicker ? (
        <CopyColumnsDialog
          visible={showDataPicker}
          table={table}
          dataTables={dataTables}
          loading={dataTablesLoading}
          onClose={() => setShowDataPicker(false)}
          onChange={onChange}
        />
      ) : null}
    </Fragment>
  );
}
