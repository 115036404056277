import React from 'react';

import { Plus, Trash } from '<components>/Icons';

import Cell from '../../Cell';
import Table from '../../Table';
import { RowControl } from '../../Widget/Body';
import RangeLabel from '../../Widget/RangeLabel';

import useAppearTrigger from '../../useAppearTrigger';

function ColumnControl({ api, widget, column, nextColumn, setAppearTrigger }) {
  const actions = [];
  if (api.deleteColumn && widget.columns.length > 1) {
    actions.push(
      <Table.Action
        key="delete"
        onClick={() => api.deleteColumn(widget, column)}
      >
        <Trash />
      </Table.Action>
    );
  }
  if (api.addColumn) {
    actions.push(
      <Table.Action
        key="add"
        onClick={() => {
          const beforeID = nextColumn ? nextColumn.ID : null;
          api.addColumn(widget, {
            beforeID,
            correlationID: setAppearTrigger('edit'),
          });
        }}
      >
        <Plus />
      </Table.Action>
    );
  }

  const draggable = api.moveColumn && widget.columns.length > 1;
  return (
    <Table.ColumnControl draggable={draggable}>{actions}</Table.ColumnControl>
  );
}

function Header({ api, widget, dispatch, expanded, onLink, onUpdate }) {
  const [appearTrigger, setAppearTrigger] = useAppearTrigger();
  return (
    <>
      <Table.Columns>
        <Table.ControlColumn />
        {widget.columns.map((column) => (
          <Table.Column key={column.ID} />
        ))}
      </Table.Columns>
      <Table.Head>
        <Table.ControlRow>
          <Table.ColumnControl />
          {widget.columns.map((column, colIdx) => (
            <ColumnControl
              key={column.ID}
              api={api}
              widget={widget}
              column={column}
              nextColumn={widget.columns[colIdx + 1]}
              setAppearTrigger={setAppearTrigger}
            />
          ))}
        </Table.ControlRow>
        <Table.Row>
          <Table.RowControl />
          {widget.columns.map((column, colIdx) => (
            <RangeLabel
              key={column.ID}
              widget={widget}
              range={column}
              appearTrigger={appearTrigger}
              onUpdate={api.updateColumn}
            />
          ))}
        </Table.Row>
      </Table.Head>
    </>
  );
}

export default function DataWidgetBody({
  api,
  active,
  widget,
  dispatch,
  expanded,
  showNewRow,
}) {
  const [appearTrigger, setAppearTrigger] = useAppearTrigger();

  return (
    <Table active={active}>
      <Header
        api={api}
        widget={widget}
        expanded={expanded}
        onLink={api.updateCellLink}
        onUpdate={api.updateCellValue}
      />
      <Table.Body>
        {widget.rows.map((row, rowIdx) => (
          <Table.Row key={row.ID} ord={rowIdx}>
            <RowControl
              api={api}
              widget={widget}
              row={row}
              nextRow={widget.rows[rowIdx + 1]}
              setAppearTrigger={setAppearTrigger}
            />
            {row.cells.map((cell, colIdx) => (
              <Cell
                key={cell.ID}
                cell={cell}
                appearTrigger={appearTrigger}
                isEditable
                isSource
                isTarget
                onLink={api.updateCellLink}
                onUpdate={api.updateCellValue}
              />
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
