import React, { PureComponent } from 'react';
import { defaultProps, hoistStatics } from 'recompose';

export default (defaultTag, propertyName = 'as') => {
  return hoistStatics(defaultProps({ [propertyName]: defaultTag }));
};

export class Component extends PureComponent {
  render() {
    const { as: Tag, ...props } = this.props;
    return <Tag {...props} />;
  }
}
