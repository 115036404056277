import React, { forwardRef, useMemo } from 'react';
import styled from '@emotion/styled';
import { Checkbox } from 'semantic-ui-react';

import TableDefDropdown from '../../TableDefDropdown';
import Conditions from '../../Conditions';
import Step from '../Step';
import { stepsByType } from '../stepData';

const TablePicker = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > span {
    margin-right: 8px;
  }
`;

const IncludeNoMatch = styled(Checkbox)`
  margin-top: 8px;
`;

function CombineStep(
  {
    api,
    baseURL,
    flow,
    stageElements = [],
    stage,
    step,
    dataTables,
    dataTablesLoading,
    ...props
  },
  parentRef
) {
  const { onChangeCombine, onAddTable: onAddTableProp } = api;
  const elements = useMemo(() => {
    const tableDef = flow.data.find((t) => t.ID === step.input.tableID);
    if (!tableDef) return null;

    const els = stageElements.concat(
      tableDef.schema.elements.map((t) => ({
        ...t,
        key: `${step.key}:${t.key}`,
        source: tableDef.ID,
        sourceInfo: { key: tableDef.ID, label: tableDef.name },
      }))
    );
    return els;
  }, [stageElements, flow.data, step.input.tableID, step.key]);

  const onChange = useMemo(
    () =>
      onChangeCombine
        ? (e, changes) => onChangeCombine(e, { key: step.key, ...changes })
        : null,
    [onChangeCombine, step.key]
  );
  const onAddTable = useMemo(() => {
    if (!onChange || !onAddTableProp) {
      return null;
    }

    return async (e, table) => {
      const newTable = await onAddTableProp(e, table);
      await onChange(e, { input: { tableID: newTable.ID, mode: 'Table' } });
      return newTable;
    };
  }, [onAddTableProp, onChange]);

  const onChangeConditions = useMemo(() => {
    if (!onChange) {
      return null;
    }
    return (e, { conditions: where }) => onChange(e, { where });
  }, [onChange]);

  const returnTo = {
    location: {
      pathname: `${baseURL}/stages/${stage.ID}`,
      hash: `#${step.key}`,
    },
  };

  return (
    <Step ref={parentRef} flow={flow} step={step} api={api} {...props}>
      <TablePicker>
        <span>Source:</span>
        <TableDefDropdown
          baseURL={baseURL}
          disabled={!onChange}
          returnTo={returnTo}
          tableDefs={flow.data}
          value={step.input.tableID}
          onAdd={onAddTable}
          onChange={(e, { value }) =>
            onChange(e, { input: { tableID: value, mode: 'Table' } })
          }
          dataTables={dataTables}
          dataTablesLoading={dataTablesLoading}
        />
      </TablePicker>
      <div>
        <IncludeNoMatch
          checked={step.includeNoMatch}
          disabled={!onChange}
          onChange={(e, { checked }) =>
            onChange(e, { includeNoMatch: checked })
          }
          label={
            step.where.length === 0
              ? 'Include original input when no combinations are generated'
              : 'Include original input when no matching combinations are generated'
          }
        />
        {elements && (onChange || step.where.length > 0) ? (
          <>
            <h5>Filters</h5>
            <Conditions
              elements={elements}
              conditions={step.where}
              onChange={onChangeConditions}
            />
          </>
        ) : null}
      </div>
    </Step>
  );
}

// eslint-disable-next-line no-func-assign
CombineStep = forwardRef(CombineStep);
CombineStep.displayName = 'CombineStep';

// Avoid circular dependency
stepsByType.CombineStepV2.Component = CombineStep;
export default CombineStep;
