export default function sortHelpArticles(arr) {
  return [...arr].sort((a, b) => {
    const aOrderStanza = parseInt(
      a.title.split(' ')[0].split('.').join('').trim()
    );
    const bOrderStanza = parseInt(
      b.title.split(' ')[0].split('.').join('').trim()
    );
    if (!isNaN(aOrderStanza) && !isNaN(bOrderStanza)) {
      return aOrderStanza < bOrderStanza ? -1 : 1;
    }

    return a.title < b.title ? -1 : 1;
  });
}
