import { useEffect, useRef, useState } from 'react';

function buildGraph(inputs, widgets, cells, cellLinks, lookupWidgetsByData) {
  const graph = {
    inputs: [],
    root: ['inputs', ...Object.keys(widgets)],
  };
  const stack = [];
  Object.values(widgets).forEach((w) => {
    stack.push(w.ID);
    graph[w.ID] = [];
  });
  const visited = {};
  stack.push('inputs');
  while (stack.length > 0) {
    const id = stack.pop();
    if (!visited[id]) {
      visited[id] = true;
      const widget =
        id === 'inputs'
          ? {
              ID: id,
              rows: [{ cells: inputs.map((i) => ({ ID: i.ID })) }],
              config: { type: 'Input' },
            }
          : widgets[id];
      if (widget.config.type === 'Data') {
        const lookups = lookupWidgetsByData[widget.config.tableID] || [];
        lookups.forEach((tgtWidgetID) => {
          stack.push(tgtWidgetID);
          if (!graph[id].includes(tgtWidgetID)) {
            graph[id].push(tgtWidgetID);
          }
        });
      }
      graph[id] = graph[id] || [];
      widget.rows.forEach((row) =>
        row.cells.forEach(({ ID: fromID }) =>
          (cellLinks[fromID] || []).forEach((toID) => {
            const tgtWidgetID = cells[toID].widgetID;
            if (tgtWidgetID) {
              graph[tgtWidgetID] = graph[tgtWidgetID] || [];
              stack.push(tgtWidgetID);
              if (id !== tgtWidgetID) {
                if (!graph[id].includes(tgtWidgetID)) {
                  graph[id].push(tgtWidgetID);
                }
              }
            }
          })
        )
      );
    }
  }
  return graph;
}

function buildOrderTopsort(start, graph) {
  const inDegree = {};
  Object.values(graph).forEach((targetIDs) =>
    targetIDs.forEach((targetID) => {
      inDegree[targetID] = (inDegree[targetID] || 0) + 1;
    })
  );
  const numNodes = Object.keys(graph).length;

  // First, sort the graph topologically
  const stack = [];
  const sorted = [];
  while (sorted.length < numNodes) {
    Object.keys(graph).forEach((id) => {
      if (!inDegree[id]) {
        stack.push(id);
      }
    });

    while (stack.length > 0) {
      const id = stack.pop();
      sorted.push(id);
      graph[id].forEach((targetID) => {
        if (inDegree[targetID] > 0) {
          inDegree[targetID]--;
          if (inDegree[targetID] === 0) {
            stack.push(targetID);
          }
        }
      });
    }
    if (sorted.length < numNodes) {
      Object.keys(inDegree).forEach((id) => {
        if (inDegree[id] > 0) inDegree[id]--;
      });
    }
  }

  // Now, find the longest path from the inputs to each node
  const nodes = { [start]: { id: 'nodes', dist: 0 } };
  sorted.forEach((id) => {
    if (nodes[id] && graph[id]) {
      const targetIDs = graph[id];
      const newDist = nodes[id].dist + 1;
      targetIDs.forEach((targetID) => {
        const node = nodes[targetID];
        if (!node) {
          nodes[targetID] = { id: targetID, dist: newDist };
        } else {
          node.dist = Math.max(newDist, node.dist);
        }
      });
    }
  });
  return nodes;
}

export default function useWidgetOrder(func) {
  const [groupOrder, setGroupOrder] = useState([]);
  const graph = useRef({});

  useEffect(() => {
    const newGraph = buildGraph(
      func.inputs,
      func.widgetsByID,
      func.cellsByID,
      func.linksBySource,
      func.lookupWidgetsByData
    );
    graph.current = newGraph;
  }, [func]);

  useEffect(() => {
    const nodes = buildOrderTopsort('root', graph.current);
    const { root, inputs, ...rest } = nodes;
    const order = Object.values(rest).sort((n1, n2) => {
      if (n1.dist !== n2.dist) {
        return n1.dist - n2.dist;
      }
      return n1.id.localeCompare(n2.id);
    });
    let group = { id: 1, nodes: [] };
    const groupOrder = [group];
    order.forEach((node) => {
      if (node.dist !== group.id) {
        group = { id: node.dist, nodes: [] };
        groupOrder.push(group);
      }
      group.nodes.push(node.id);
    });
    if (group.nodes.length !== 0) {
      groupOrder.push({ id: group.id + 1, nodes: [] });
    }
    setGroupOrder(groupOrder);
  }, [func.links]);

  return groupOrder;
}
