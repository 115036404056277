import React, { Fragment } from 'react';
import fp from 'lodash/fp';
import { Dropdown } from 'semantic-ui-react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import * as colors from '<components>/colors';
import Avatar from '<components>/Avatar';
import Feature from '<components>/Feature';
import { StyledScrollbar } from '<components>/NumbrzPageComponents';

const StyledAvatar = styled(Avatar)`
  color: ${colors.gray5};
  background: ${colors.gray4b};
`;

const StyledUserDetail = styled('div')`
  display: flex;
  flex: 0;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  padding-left: 16px;
  border-left: 1px solid ${colors.gray1};
  color: ${colors.gray2};
  text-wrap: none;
  transition: all 125ms ease-in;
`;

const StyledLink = styled(Link)`
  padding: 0.78571429rem 1.14285714rem !important;
  width: 100%;
  display: block;
  color: ${colors.gray1};
  font-size: 1rem;
  :hover {
    background: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.95);
  }
`;

const StyledDropdown = styled(Dropdown)`
  cursor: pointer i {
    margin: 0;
    color: ${colors.gray2};
  }

  i {
    color: ${colors.gray2};
    margin-left: 4px;
    transform: translateY(1px) scale(1.2);
  }

  ${StyledUserDetail}:hover &, &:hover {
    i {
      color: ${colors.blue2};
    }
  }

  &:active {
    i {
      color: ${colors.blue1};
    }
  }
`;

const AccountList = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 250px;
  overflow-y: auto;
  ${StyledScrollbar};

  .item {
    line-height: 1em;
    color: rgba(0, 0, 0, 0.87);
    padding: 0.78571429rem 1.14285714rem !important;
    font-size: 1rem;
    :hover {
      cursor: pointer;
      background: rgba(0, 0, 0, 0.05);
      color: rgba(0, 0, 0, 0.95);
      z-index: 13;
    }
  }
`;

const AccountSwitcher = ({ user, onSwitchAccount }) => {
  const { primaryAccount, membership } = user;

  if (!primaryAccount || !membership || membership.length < 2) {
    return null;
  }

  return (
    <Fragment>
      <Dropdown.Header>Switch Account</Dropdown.Header>
      <AccountList>
        {membership
          .filter(({ account }) => account.ID !== primaryAccount.ID)
          .map(({ account }) => (
            <Dropdown.Item
              key={account.ID}
              onClick={() => onSwitchAccount(account.ID)}
            >
              {account.display}
            </Dropdown.Item>
          ))}
      </AccountList>

      <Dropdown.Divider />
    </Fragment>
  );
};

const UserDetail = (props) => {
  const userName = fp.getOr('', 'user.display', props);
  return (
    <StyledUserDetail>
      <StyledDropdown
        pointing="top right"
        icon={<StyledAvatar userName={userName} />}
      >
        <Dropdown.Menu>
          <Dropdown.Header>{userName}</Dropdown.Header>
          <StyledLink to="/account">Account</StyledLink>
          <Feature name="NumbrzAdmin">
            <StyledLink to="/numbrz-admin">Numbrz Admin</StyledLink>
          </Feature>
          <Dropdown.Divider />
          <AccountSwitcher
            user={props.user}
            onSwitchAccount={props.onSwitchAccount}
          />
          <Dropdown.Item onClick={props.onLogout}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </StyledDropdown>
    </StyledUserDetail>
  );
};

export default UserDetail;
