import React, { PureComponent } from 'react';
import styled from '@emotion/styled';
import { Message } from 'semantic-ui-react';

import Dialog from '<components>/Dialog';
import Button from '<src>/components/Button';
import TextInput from '<components>/TextInput';
import * as colors from '<components>/colors';

const StyledBody = styled(Dialog.Body)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  h4 {
    font-weight: bold;
    font-size: 14px;
    color: ${colors.gray1};
  }
`;

const StyledFooter = styled(Dialog.Footer)`
  button {
    margin-left: 10px;
  }
`;

function validate(value) {
  if (!value || !value.trim || !value.trim()) return false;
  if (value.startsWith(' ')) return false;

  return true;
}

export default class AddOrgDialog extends PureComponent {
  inputRef = React.createRef();

  state = {
    name: '',
    display: '',
  };

  onNameChange = (e) => this.setState({ name: e.target.value });
  onDisplayChange = (e) => this.setState({ display: e.target.value });

  onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      return this.props.onSubmit(this.state);
    }
  };

  isValid() {
    const { name, display } = this.state;
    return validate(name) && validate(display);
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && !prevProps.visible) {
      this.setState({ name: '', display: '' }, () =>
        this.inputRef.current.focus()
      );
    }
  }

  get errorMessage() {
    const { errors } = this.props;
    if (!errors || errors.length === 0) return null;

    return (
      <Message error>
        <p>An error occurred:</p>
        <ul>
          {errors.map((error, idx) => (
            <li key={idx}>{error.message}</li>
          ))}
        </ul>
      </Message>
    );
  }

  render() {
    const { visible, creating, onClose, onSubmit } = this.props;
    return (
      <Dialog visible={visible ? 1 : 0} onRequestClose={onClose}>
        <Dialog.Header>
          <Dialog.Headline>Add Org</Dialog.Headline>
        </Dialog.Header>
        <StyledBody>
          {this.errorMessage}
          <h4>Name (required)</h4>
          <TextInput
            ref={this.inputRef}
            name="org_name"
            onChange={this.onNameChange}
            value={this.state.name}
            onKeyDown={this.onKeyDown}
            placeholder="Name"
          />
          <h4>Display Name (required)</h4>
          <TextInput
            name="org_display"
            onChange={this.onDisplayChange}
            value={this.state.display}
            onKeyDown={this.onKeyDown}
            placeholder="Display Name"
          />
        </StyledBody>
        <StyledFooter>
          <Button disabled={creating} onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={creating || !this.isValid()}
            onClick={() => onSubmit(this.state)}
          >
            {creating ? 'Adding...' : 'Add'}
          </Button>
        </StyledFooter>
      </Dialog>
    );
  }
}
