import React, { Fragment, useCallback, useState } from 'react';
import fp from 'lodash/fp';
import Button from '<src>/components/Button';
import { Icon } from 'semantic-ui-react';
import WaveSpinner from '<src>/components/WaveSpinner';
import { formatErrorMessage } from '<sections>/data/utils';
import {
  FlexColumnContainer,
  StyledScrollbar,
  EmptyListContainer,
  FlexRowContainer,
} from '<components>/NumbrzPageComponents';
import DataTablePane from '<components>/DataTablePane';
import ErrorMessages from '<src>/components/ErrorMessages';

import * as colors from '<components>/colors';
import styled from '@emotion/styled';
import UnderlinedHeader from '<src>/components/UnderlinedHeader';
import dateMacros from '../../TablesetPage/dateMacros';
import {
  StyledLabel,
  StyledDropdown,
  DataSeparator,
} from '../../TablesetPage/styles';
import DatePicker from 'react-datepicker';
import { hasExternalTables } from '../../TablesetPage/util';
import useTablesetState from '../../TablesetPage/api/useTablesetState';

const ContentWrapper = styled('div')`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 10px 10px 10px 5px;
`;

const InputWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid ${colors.gray_border};
  border-radius: 4px;
  margin: 0 5px;
`;

const TableRowWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  width: 100%;
  padding: 3px 0px 3px 3px;
  margin: 2px 0;
  border-radius: 4px;
  color: ${colors.gray1b};
  button {
    margin-left: 5px;
    &.active {
      font-weight: bold;
    }
  }
`;

const TableName = styled('span')`
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  margin-right: 10px;
`;

const ListArea = styled('div')`
  overflow-y: auto;
  width: 100%;

  ${(props) => props.flexShrink && { flexShrink: props.flexShrink }};

  margin-bottom: 20px;
  .list-row {
    border-bottom: 1px solid ${colors.gray_border};
  }
  ${StyledScrollbar};
`;

function renderItemDetail(table, requiresDateInput) {
  return (
    <FlexColumnContainer
      alignItems="flex-start"
      style={{ height: '100%', overflowY: 'auto' }}
    >
      <DataTablePane
        readOnly
        hideHeader
        table={table}
        showTableType
        projectID={null}
        requiresDateInput={requiresDateInput}
      />
    </FlexColumnContainer>
  );
}

function TableRow({
  table,
  onConnect,
  onDisconnect,
  connected,
  isValid,
  requiresDateInput,
}) {
  const [expanded, setExpanded] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [disconnecting, setDisconnecting] = useState(false);
  const [dateMacro, setDateMacro] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const handleDisconnect = useCallback(async () => {
    setDisconnecting(true);
    const source = fp.getOr('', 'source.__typename', table);
    await onDisconnect({
      variables: {
        ID: table.ID,
        source,
      },
    });
    setDisconnecting(false);
  }, [table, onDisconnect]);

  const handleConnect = useCallback(async () => {
    setConnecting(true);
    await onConnect(table, dateMacro, fromDate, toDate);
    setConnecting(false);
  }, [table, dateMacro, fromDate, toDate, onConnect]);

  return (
    <FlexColumnContainer className="list-row" alignItems="center">
      <TableRowWrapper>
        <FlexRowContainer alignItems="center" justifyContent="flex-start">
          <TableName>{table.name}</TableName>
          <DataSeparator />
          <Button
            action="control"
            onClick={() => setExpanded(!expanded)}
            className={expanded && 'active'}
            icon={<Icon name={expanded ? 'angle up' : 'angle down'} />}
            iconPosition="right"
            style={{ padding: '3px 5px', color: colors.gray1 }}
          >
            Details
          </Button>
        </FlexRowContainer>

        <div>
          {connected && (
            <Fragment>
              <Button.Cancel
                onClick={handleDisconnect}
                disabled={disconnecting}
              >
                {disconnecting ? 'Disconnecting...' : 'Disconnect'}
              </Button.Cancel>
            </Fragment>
          )}
          {!connected && (
            <FlexRowContainer alignItems="center" justifyContent="flex-start">
              {requiresDateInput(table) && (
                <InputWrapper>
                  <FlexRowContainer
                    alignItems="center"
                    justifyContent="flex-start"
                    width="100%"
                  >
                    <StyledLabel>
                      <span style={{ width: '150px' }}>Date Range</span>
                      <StyledDropdown
                        options={dateMacros}
                        value={dateMacro}
                        placeholder="None"
                        onChange={(e, data) => {
                          setDateMacro(data.value);
                          setFromDate(null);
                          setToDate(null);
                        }}
                      />
                    </StyledLabel>
                  </FlexRowContainer>

                  {dateMacro === 'custom' && (
                    <FlexRowContainer
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <StyledLabel>
                        <span>Start Date</span>
                        <DatePicker
                          selected={fromDate}
                          onChange={(date) => {
                            setFromDate(date);
                          }}
                          dateFormat="MM/dd/yyyy"
                        />
                      </StyledLabel>
                      <DataSeparator />
                      <StyledLabel>
                        <span>End Date</span>
                        <DatePicker
                          selected={toDate}
                          onChange={(date) => {
                            setToDate(date);
                          }}
                          dateFormat="MM/dd/yyyy"
                        />
                      </StyledLabel>
                    </FlexRowContainer>
                  )}
                </InputWrapper>
              )}
              <Button.Run
                onClick={handleConnect}
                disabled={connecting || !isValid(table, dateMacro, fromDate)}
              >
                {connecting ? 'Connecting...' : 'Connect'}
              </Button.Run>
            </FlexRowContainer>
          )}
        </div>
      </TableRowWrapper>
      {expanded && renderItemDetail(table, requiresDateInput)}
    </FlexColumnContainer>
  );
}

export default function ConnectTablesPane({ tablesetID }) {
  const tablesetState = useTablesetState({ tablesetID });
  const {
    tableset,
    loadingTableset,
    extTables = [],
    extLoading,
    refetchExt,
    tables = [],
    loadingTables,
    tableAPI,
    extTableAPI,
    requiresDateInput,
    extTableErr,
  } = tablesetState;

  const { source: tablesetSrc } = tableset;
  const { onDelete } = tableAPI;
  const { onConnect, isValidNew } = extTableAPI;

  const [showConnected, setShowConnected] = useState(true);

  const extErr =
    extTableErr && extTableErr.code
      ? formatErrorMessage(extTableErr)
      : undefined;

  const emptyListMsg = (
    <EmptyListContainer style={{ flexDirection: 'row' }}>
      <h4>No items found</h4>
    </EmptyListContainer>
  );

  return (
    <Fragment>
      {loadingTableset ? (
        <WaveSpinner />
      ) : (
        <ContentWrapper>
          <FlexColumnContainer justifyContent="center" width="100%">
            <UnderlinedHeader justifyContent="space-between">
              AVAILABLE
              {hasExternalTables(tablesetSrc) && (
                <Button
                  action="control"
                  icon={<Icon name="sync alternate" />}
                  iconPosition="left"
                  onClick={() => refetchExt()}
                  disabled={extLoading}
                >
                  {extLoading ? 'Fetching...' : 'Reload'}
                </Button>
              )}
            </UnderlinedHeader>
            {extTableErr && (
              <ErrorMessages
                issues={[{ message: extErr, code: 'some code' }]}
                message="Errors"
                noShadow={false}
                margin="0"
              />
            )}
            {extTables.length === 0 && !extLoading ? (
              emptyListMsg
            ) : (
              <ListArea>
                {extTables.map((extTable) => {
                  return (
                    <TableRow
                      table={extTable}
                      connected={false}
                      onConnect={onConnect}
                      onDisconnect={onDelete}
                      isValid={isValidNew}
                      requiresDateInput={requiresDateInput}
                    />
                  );
                })}
              </ListArea>
            )}
          </FlexColumnContainer>
          <FlexColumnContainer justifyContent="center" width="100%">
            <UnderlinedHeader justifyContent="space-between">
              CONNECTED
              <Button
                action="control"
                icon={<Icon name={showConnected ? 'angle up' : 'angle down'} />}
                iconPosition="right"
                onClick={() => setShowConnected(!showConnected)}
              >
                {showConnected ? 'Hide' : 'Show'}
              </Button>
            </UnderlinedHeader>
            {loadingTables ? (
              <WaveSpinner />
            ) : (
              showConnected &&
              (tables.length === 0 ? (
                emptyListMsg
              ) : (
                <ListArea>
                  {tables.map((t) => {
                    return (
                      <TableRow
                        table={t}
                        connected={true}
                        onConnect={onConnect}
                        onDisconnect={onDelete}
                        isValid={isValidNew}
                        requiresDateInput={requiresDateInput}
                      />
                    );
                  })}
                </ListArea>
              ))
            )}
          </FlexColumnContainer>
        </ContentWrapper>
      )}
    </Fragment>
  );
}
