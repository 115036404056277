import React, { Fragment, useMemo } from 'react';
import { Message } from 'semantic-ui-react';
import styled from '@emotion/styled';

import { compareBy, urlizeLabel } from '<utils>/text';
import { inEms } from '<utils>/math';

import Link from '<components>/Link';
import Card from '<components>/Card';
import {
  EditableField,
  FlexColumnContainer,
  CardSubhdr,
} from '<components>/NumbrzPageComponents';
import DetailRadio from '<components>/DetailRadio';
import UnderlinedHeader from '<components>/UnderlinedHeader';

import { formatDataSourceLabel } from '<sections>/data/utils';

const compareByName = compareBy('name');

const templateSources = [
  'GoogleSpreadsheet',
  'NumbrzDatabase',
  'QuickbooksOnline',
];

const unsupHostedSources = ['QuickbooksOnline'];

const nodeployKeys = ['QuickbooksOnline'];

const TablesetCard = styled(Card)`
  width: 100%;
  height: fit-content;

  & + & {
    margin-top: 8px;
  }
  padding-bottom: 5px;
  margin-bottom: 12px;
`;

const TablesetBody = styled(Card.Body)`
  padding: 0 ${inEms(10)}em 0 ${inEms(14)}em;
`;

const DeployKeys = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6px;
  grid-auto-rows: auto;
  & > * {
    min-height: 20px;
  }
`;

function TablesetSettings({ tableset, onSetDeployKeys }) {
  const deployMode = !tableset.deployKey ? 'create' : 'useExisting';

  const onSetDeployMode = (mode) => {
    const tablesets = [];
    const tables = [];
    let makeKey = () => null;
    if (mode === 'useExisting') {
      makeKey = (name) => urlizeLabel(name);
    }
    tablesets.push({ ID: tableset.ID, deployKey: makeKey(tableset.name) });
    tableset.tables.forEach((table) => {
      tables.push({ ID: table.ID, deployKey: makeKey(table.name) });
    });
    onSetDeployKeys({ tablesets, tables });
  };

  const showDeployKeys =
    !tableset.isHosted && tableset.isTmplSupported && tableset.showDeployKeys;

  return (
    <TablesetCard>
      <Card.Header>
        <FlexColumnContainer alignItems="flex-start">
          <Link internal to={`/data/${tableset.ID}`}>
            {tableset.name}
          </Link>
          <CardSubhdr>
            {formatDataSourceLabel(tableset.source.__typename)}
          </CardSubhdr>
        </FlexColumnContainer>
      </Card.Header>
      <TablesetBody>
        {tableset.isHosted && tableset.isTmpl ? (
          <Message error>
            This data connection is used as both hosted and template data.
          </Message>
        ) : null}
        {tableset.isTmpl && !tableset.isTmplSupported ? (
          <Message warning>
            This data connection is not supported as template data. The model
            will still deploy, but the user will have to provde this data
            manually.
          </Message>
        ) : null}
        {tableset.isHosted && !tableset.isHostedSupported ? (
          <Message warning>
            This data connection is not recommended as hosted data.
          </Message>
        ) : null}
        {showDeployKeys ? (
          <>
            <UnderlinedHeader>DEPLOY MODE</UnderlinedHeader>
            <DetailRadio
              name={`deployMode-${tableset.ID}`}
              value="create"
              selectedValue={deployMode}
              label="Create a new connection"
              onChange={onSetDeployMode}
            >
              A new copy of this data connection will be created for each
              deployment
            </DetailRadio>
            <DetailRadio
              name={`deployMode-${tableset.ID}`}
              value="useExisting"
              selectedValue={deployMode}
              label="Use existing connection"
              onChange={onSetDeployMode}
            >
              A previously deployed data connection will be used, if one exists;
              otherwise a new connection will be created.
            </DetailRadio>
            {deployMode === 'useExisting' ? (
              <>
                <UnderlinedHeader>DEPLOY KEYS</UnderlinedHeader>
                <DeployKeys>
                  <div>Connection Deploy Key:</div>
                  <EditableField
                    singleClick
                    value={tableset.deployKey}
                    placeholder="No deploy key set"
                    onSubmit={({ value }) =>
                      onSetDeployKeys({
                        tablesets: [
                          {
                            ID: tableset.ID,
                            deployKey: value,
                          },
                        ],
                      })
                    }
                  />
                  {tableset.tables.map((table) => (
                    <Fragment key={table.ID}>
                      <div>
                        <Link
                          internal
                          to={`/data/${tableset.ID}/connected/${table.ID}`}
                        >
                          {table.name}
                        </Link>
                      </div>
                      <EditableField
                        singleClick
                        value={table.deployKey}
                        placeholder="No deploy key set"
                        onSubmit={({ value }) =>
                          onSetDeployKeys({
                            tables: [
                              {
                                ID: table.ID,
                                deployKey: value,
                              },
                            ],
                          })
                        }
                      />
                    </Fragment>
                  ))}
                </DeployKeys>
              </>
            ) : null}
          </>
        ) : (
          <>
            <UnderlinedHeader>TABLES</UnderlinedHeader>
            <DeployKeys>
              {tableset.tables.map((table) => (
                <div key={table.ID} style={{ gridColumn: '1 / 2' }}>
                  <Link
                    internal
                    to={`/data/${tableset.ID}/connected/${table.ID}`}
                  >
                    {table.name}
                  </Link>
                </div>
              ))}
            </DeployKeys>
          </>
        )}
      </TablesetBody>
    </TablesetCard>
  );
}

export default function DataList({ jobs, onSetDeployKeys }) {
  const tablesets = useMemo(() => {
    const tsmap = {};
    const dsmap = {};
    jobs.forEach((job) => {
      job.datamap.forEach((dm) => {
        if (dm.dataset) {
          if (!tsmap[dm.dataset.tableset.ID]) {
            tsmap[dm.dataset.tableset.ID] = {
              ...dm.dataset.tableset,
              tables: [dm.dataset],
              isTmplSupported: templateSources.includes(
                dm.dataset.tableset.source.__typename
              ),
              isHostedSupported: !unsupHostedSources.includes(
                dm.dataset.tableset.source.__typename
              ),
              showDeployKeys: !nodeployKeys.includes(
                dm.dataset.tableset.source.__typename
              ),
              isHosted: false,
              isTmpl: false,
            };
          } else if (!dsmap[dm.dataset.ID]) {
            tsmap[dm.dataset.tableset.ID].tables.push(dm.dataset);
          }
          tsmap[dm.dataset.tableset.ID].isHosted =
            tsmap[dm.dataset.tableset.ID].isHosted || job.isBase;
          tsmap[dm.dataset.tableset.ID].isTmpl =
            tsmap[dm.dataset.tableset.ID].isTmpl || !job.isBase;
          dsmap[dm.dataset.ID] = dm.dataset;
        }
      });
    });
    const tablesets = Object.values(tsmap);
    tablesets.sort(compareByName);
    tablesets.forEach((ts) => ts.tables.sort(compareByName));
    return tablesets;
  }, [jobs]);

  const hostedData = tablesets.filter((ts) => ts.isHosted);
  const tmplData = tablesets.filter((ts) => ts.isTmpl);

  return (
    <>
      {tmplData.length > 0 ? (
        <>
          <UnderlinedHeader>TEMPLATE DATA</UnderlinedHeader>
          {tmplData.map((ts) => (
            <TablesetSettings
              key={ts.ID}
              tableset={ts}
              onSetDeployKeys={onSetDeployKeys}
            />
          ))}
        </>
      ) : null}
      {hostedData.length > 0 ? (
        <>
          <UnderlinedHeader>HOSTED DATA</UnderlinedHeader>
          {hostedData.map((ts) => (
            <TablesetSettings
              key={ts.ID}
              tableset={ts}
              onSetDeployKeys={onSetDeployKeys}
            />
          ))}
        </>
      ) : null}
    </>
  );
}
