import React from 'react';

const OnboardingVideos = {
  function: [
    {
      section: 'overview',
      title: 'Function Overview',
      thumbnail: (
        <img
          alt="Function Overview"
          src="https://cdn.loom.com/sessions/thumbnails/7ac4209acf1a40dea6d65802ded21e97-with-play.gif"
        />
      ),
      video: (
        <iframe
          title="Function Overview "
          src="https://www.loom.com/embed/7ac4209acf1a40dea6d65802ded21e97?sid=a3e363c3-fe71-404c-afa8-e7f343a6426c"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="fullscreen"
        />
      ),
    },
    {
      section: 'build',
      title: 'Create a Function',
      thumbnail: (
        <img
          alt="create a function"
          src="https://cdn.loom.com/sessions/thumbnails/2f7634dc59c8479e9924137e78417311-with-play.gif"
        />
      ),
      video: (
        <iframe
          title="Create a Function "
          src="https://www.loom.com/embed/2f7634dc59c8479e9924137e78417311?sid=9997a211-8e34-4537-9dde-f29e5fd04417"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="fullscreen"
        ></iframe>
      ),
    },
    {
      section: 'build',
      title: 'Widgets: Divide & Date',
      thumbnail: (
        <img
          alt="Widgets: Divide & Date"
          src="https://cdn.loom.com/sessions/thumbnails/197637cc224f46f4b65a5e9b2a8f9290-with-play.gif"
        />
      ),
      video: (
        <iframe
          title="Widgets: Divide & Date "
          src="https://www.loom.com/embed/197637cc224f46f4b65a5e9b2a8f9290?sid=91ca2fca-4fd6-440a-b97f-720d8e28b318"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="fullscreen"
        ></iframe>
      ),
    },
    {
      section: 'build',
      title: 'Widgets: Join Text and Lookup',
      thumbnail: (
        <img
          alt="Widgets: Divide & Date"
          src="https://cdn.loom.com/sessions/thumbnails/197637cc224f46f4b65a5e9b2a8f9290-with-play.gif"
        />
      ),
      video: (
        <iframe
          title="Widgets: Join Text and Lookup "
          src="https://www.loom.com/embed/8d001e8307d34fe1bb5ad1ec5a1e6e3a?sid=763228d1-6895-4c8f-827a-ba360656c11c"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="fullscreen"
        ></iframe>
      ),
    },
    {
      section: 'build',
      title: 'Widgets: Layout on the Canvas',
      thumbnail: (
        <img
          alt="Widgets: Layout on the Canvas"
          src="https://cdn.loom.com/sessions/thumbnails/253705605e794c5283eb261685cf1116-1714164552988-with-play.gif"
        />
      ),
      video: (
        <iframe
          title="Widgets: Layout on the Canvas "
          src="https://www.loom.com/embed/253705605e794c5283eb261685cf1116?sid=1da41702-f2db-43ad-a2b1-8e204f3d71da"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="fullscreen"
        ></iframe>
      ),
    },
    {
      section: 'concepts',
      title: 'Function Data Types',
      thumbnail: (
        <img
          alt="Function Data Types"
          src="https://cdn.loom.com/sessions/thumbnails/f1884211b0954a9cb324b0427d5cf9ff-1714164599380-with-play.gif"
        />
      ),
      video: (
        <iframe
          title="Function Data Types"
          src="https://www.loom.com/embed/f1884211b0954a9cb324b0427d5cf9ff?sid=5bc23c02-9811-4b31-bb5e-a81f055b3eea"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="fullscreen"
        ></iframe>
      ),
    },
    {
      section: 'concepts',
      title: 'Create a Team  ',
      thumbnail: (
        <img
          alt="Create a Team  "
          src="https://cdn.loom.com/sessions/thumbnails/6025dd6ff2ff484eaba37c831b49dc65-1718321384535-with-play.gif"
        />
      ),

      video: (
        <iframe
          title="Create a Team "
          src="https://www.loom.com/embed/6025dd6ff2ff484eaba37c831b49dc65?sid=d996600a-4eec-434e-8ac4-d97f495453d8"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="fullscreen"
        ></iframe>
      ),
    },
  ],
  flow: [
    {
      section: 'overview',
      title: 'Flow Overview',
      thumbnail: (
        <img
          alt="Flow overview"
          src="https://cdn.loom.com/sessions/thumbnails/31d5fb8ffb174321beaf5ea03648c3bc-1715123571494-with-play.gif"
        />
      ),
      video: (
        <iframe
          title="Flow overview"
          src="https://www.loom.com/embed/31d5fb8ffb174321beaf5ea03648c3bc?sid=3d7488cb-6537-4265-bc0c-ce5a6a920f1b"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="fullscreen"
        ></iframe>
      ),
    },
    {
      section: 'build',
      title: 'Build a Stage',
      thumbnail: (
        <img
          alt="Build a Stage"
          src="https://cdn.loom.com/sessions/thumbnails/9681ef11171d429299cc4b548eed0b23-1714164759971-with-play.gif"
        />
      ),
      video: (
        <iframe
          title="Build a Stage"
          src="https://www.loom.com/embed/9681ef11171d429299cc4b548eed0b23?sid=669725e3-1821-42ad-a7c4-931611cd45d3"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="fullscreen"
        ></iframe>
      ),
    },
    {
      section: 'build',
      title: 'Aggregate Step',
      thumbnail: (
        <img
          alt="Aggregate Step"
          src="https://cdn.loom.com/sessions/thumbnails/ac99f9275b2b43d68f79bf27be3a0231-1712103864120-with-play.gif"
        />
      ),
      video: (
        <iframe
          title="What is the aggregate step"
          src="https://www.loom.com/embed/ac99f9275b2b43d68f79bf27be3a0231?sid=ed508294-f0ca-4e80-85d6-8c11ead33316"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="fullscreen"
        ></iframe>
      ),
    },
    {
      section: 'build',
      title: 'Combine Step',
      thumbnail: (
        <img
          alt="Combine Step"
          src="https://cdn.loom.com/sessions/thumbnails/f1072cadd7b24fce83afcf87c1ecddf0-1712104362756-with-play.gif"
        />
      ),
      video: (
        <iframe
          title="Combine Step"
          src="https://www.loom.com/embed/f1072cadd7b24fce83afcf87c1ecddf0?sid=3e1cc23e-c336-4018-8822-9044899cf1f6"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="fullscreen"
        ></iframe>
      ),
    },
    {
      section: 'concepts',
      title: 'Create a Team  ',
      thumbnail: (
        <img
          alt="Create a Team  "
          src="https://cdn.loom.com/sessions/thumbnails/6025dd6ff2ff484eaba37c831b49dc65-1718321384535-with-play.gif"
        />
      ),

      video: (
        <iframe
          title="Create a Team "
          src="https://www.loom.com/embed/6025dd6ff2ff484eaba37c831b49dc65?sid=d996600a-4eec-434e-8ac4-d97f495453d8"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="fullscreen"
        ></iframe>
      ),
    },
  ],
  job: [
    {
      section: 'overview',
      title: 'Job Overview',
      thumbnail: (
        <img
          alt="Job overview"
          src="https://cdn.loom.com/sessions/thumbnails/e931945ba4134826a3430cd26f53aeb4-1716155592254-with-play.gif"
        />
      ),
      video: (
        <iframe
          title="Job Overview"
          src="https://www.loom.com/embed/e931945ba4134826a3430cd26f53aeb4?sid=40061b41-7401-486b-b390-cfb8b442644c"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="fullscreen"
        ></iframe>
      ),
    },
    {
      section: 'build',
      title: 'Create a Job',
      thumbnail: (
        <img
          alt="Create a job"
          src="https://cdn.loom.com/sessions/thumbnails/729d520f882a4e5d9dbdda5105283045-1716138635824-with-play.gif"
        />
      ),
      video: (
        <iframe
          title="Create a job"
          src="https://www.loom.com/embed/729d520f882a4e5d9dbdda5105283045?sid=6529f021-65e0-4913-9601-8f063e187eec"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="fullscreen"
        ></iframe>
      ),
    },
    {
      section: 'build',
      title: 'Running a Job',
      thumbnail: (
        <img
          alt="Running a job"
          src="https://cdn.loom.com/sessions/thumbnails/246528fcebd542ee8c0758ab80a31a2e-1715980070457-with-play.gif"
        />
      ),
      video: (
        <iframe
          title="Running a job"
          src="https://www.loom.com/embed/246528fcebd542ee8c0758ab80a31a2e?sid=0655a28b-ca7d-41ec-abc5-af1189954383"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="fullscreen"
        ></iframe>
      ),
    },
    {
      section: 'concepts',
      title: 'Create a Team  ',
      thumbnail: (
        <img
          alt="Create a Team  "
          src="https://cdn.loom.com/sessions/thumbnails/6025dd6ff2ff484eaba37c831b49dc65-1718321384535-with-play.gif"
        />
      ),

      video: (
        <iframe
          title="Create a Team "
          src="https://www.loom.com/embed/6025dd6ff2ff484eaba37c831b49dc65?sid=d996600a-4eec-434e-8ac4-d97f495453d8"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="fullscreen"
        ></iframe>
      ),
    },
  ],
  model: [
    {
      section: 'overview',
      title: 'Job Overview',
      thumbnail: (
        <img
          alt="Job overview"
          src="https://cdn.loom.com/sessions/thumbnails/e931945ba4134826a3430cd26f53aeb4-1716155592254-with-play.gif"
        />
      ),
      video: (
        <iframe
          title="Job Overview"
          src="https://www.loom.com/embed/e931945ba4134826a3430cd26f53aeb4?sid=40061b41-7401-486b-b390-cfb8b442644c"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="fullscreen"
        ></iframe>
      ),
    },
    {
      section: 'overview',
      title: 'Data Overview',
      thumbnail: (
        <img
          alt="Data types and connectors"
          src="https://cdn.loom.com/sessions/thumbnails/e77162e9146946b1b1891544d739f45c-1714081918551-with-play.gif"
        />
      ),

      video: (
        <iframe
          title="Data Overview"
          src="https://www.loom.com/embed/e77162e9146946b1b1891544d739f45c?sid=7d88826a-66bd-4c1f-9e4e-af7999bcdf38"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="fullscreen"
        ></iframe>
      ),
    },
    {
      section: 'overview',
      title: 'Functions Overview',
      thumbnail: (
        <img
          alt="Functions Overview"
          src="https://cdn.loom.com/sessions/thumbnails/7ac4209acf1a40dea6d65802ded21e97-with-play.gif"
        />
      ),
      video: (
        <iframe
          title="Functions Overview"
          src="https://www.loom.com/embed/7ac4209acf1a40dea6d65802ded21e97?sid=37df25d6-cd21-41e8-82f5-b62f9fe8c82e"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="fullscreen"
        ></iframe>
      ),
    },
    {
      section: 'overview',
      title: 'Flows Overview',
      thumbnail: (
        <img
          alt="Flows Overview"
          src="https://cdn.loom.com/sessions/thumbnails/31d5fb8ffb174321beaf5ea03648c3bc-1715123571494-with-play.gif"
        />
      ),
      video: (
        <iframe
          title="Flows Overview"
          src="https://www.loom.com/embed/31d5fb8ffb174321beaf5ea03648c3bc?sid=cff16e00-bf16-474b-b389-00c1423af5af"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="fullscreen"
        ></iframe>
      ),
    },
    {
      section: 'concepts',
      title: 'Create a Team  ',
      thumbnail: (
        <img
          alt="Create a Team  "
          src="https://cdn.loom.com/sessions/thumbnails/6025dd6ff2ff484eaba37c831b49dc65-1718321384535-with-play.gif"
        />
      ),

      video: (
        <iframe
          title="Create a Team "
          src="https://www.loom.com/embed/6025dd6ff2ff484eaba37c831b49dc65?sid=d996600a-4eec-434e-8ac4-d97f495453d8"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="fullscreen"
        ></iframe>
      ),
    },
  ],
  data: [
    {
      section: 'overview',
      title: 'Data Overview',
      thumbnail: (
        <img
          alt="Data types and connectors"
          src="https://cdn.loom.com/sessions/thumbnails/e77162e9146946b1b1891544d739f45c-1714081918551-with-play.gif"
        />
      ),

      video: (
        <iframe
          title="Data Overview"
          src="https://www.loom.com/embed/e77162e9146946b1b1891544d739f45c?sid=7d88826a-66bd-4c1f-9e4e-af7999bcdf38"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="fullscreen"
        ></iframe>
      ),
    },
    {
      section: 'build',
      title: 'Connect to Google Sheets',
      thumbnail: (
        <img
          alt="Connect to Google Sheets"
          src="https://cdn.loom.com/sessions/thumbnails/989e27412d034774b6c9d4ebf3f6d477-with-play.gif"
        />
      ),
      video: (
        <iframe
          title="Connect to Google Sheets"
          src="https://www.loom.com/embed/989e27412d034774b6c9d4ebf3f6d477?sid=14e36033-52be-44b4-b5bf-d57b909f1f97"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="fullscreen"
        ></iframe>
      ),
    },
    {
      section: 'build',
      title: 'Connect to Excel ',
      thumbnail: (
        <img
          alt="Connect to Excel "
          src="https://cdn.loom.com/sessions/thumbnails/f7df8971671c452f9c688f878d65a15d-1714069805090-with-play.gif"
        />
      ),

      video: (
        <iframe
          title="Connect to Excel"
          src="https://www.loom.com/embed/f7df8971671c452f9c688f878d65a15d?sid=addfedeb-81eb-4872-9ba6-dffbbb64f773"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="fullscreen"
        ></iframe>
      ),
    },
    {
      section: 'build',
      title: 'Connect to QuickBooks Online ',
      thumbnail: (
        <img
          alt="Connect to QuickBooks Online "
          src="https://cdn.loom.com/sessions/thumbnails/d317c3f260e8402cb561332c9b7db30f-1714088290437-with-play.gif"
        />
      ),

      video: (
        <iframe
          title="Connect to QuickBooks Online"
          src="https://www.loom.com/embed/d317c3f260e8402cb561332c9b7db30f?sid=1790f6d2-55d2-44a7-99fd-20c370b09af7"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="fullscreen"
        ></iframe>
      ),
    },
    {
      section: 'concepts',
      title: 'Reordering Columns in Connected Spreadsheets  ',
      thumbnail: (
        <img
          alt="Reordering Columns in Connected Spreadsheets  "
          src="https://cdn.loom.com/sessions/thumbnails/5265344b3c7c4a0f99bcd3ce15b49fbb-1715960350243-with-play.gif"
        />
      ),

      video: (
        <iframe
          title="Reordering Columns in Connected Spreadsheets "
          src="https://www.loom.com/embed/5265344b3c7c4a0f99bcd3ce15b49fbb?sid=5b1238e3-d86f-4ef9-8676-e5318369f539"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="fullscreen"
        ></iframe>
      ),
    },
    {
      section: 'concepts',
      title: 'Create a Team  ',
      thumbnail: (
        <img
          alt="Create a Team  "
          src="https://cdn.loom.com/sessions/thumbnails/6025dd6ff2ff484eaba37c831b49dc65-1718321384535-with-play.gif"
        />
      ),

      video: (
        <iframe
          title="Create a Team "
          src="https://www.loom.com/embed/6025dd6ff2ff484eaba37c831b49dc65?sid=d996600a-4eec-434e-8ac4-d97f495453d8"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="fullscreen"
        ></iframe>
      ),
    },
  ],
};

export default OnboardingVideos;
