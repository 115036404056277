import React, { Fragment, useContext, useState } from 'react';

import {
  ContentWrapper,
  Toolbar,
  ReminderWrapper,
  ShowHelpBtn,
} from '<sections>/onboarding/styles';
import HelpUtilityContext from '<sections>/help-utility/HelpUtilityContext';
import Button from '<components>/Button';

export const HelpReminder = () => {
  const [helpVisible, setHelpVisible] = useContext(HelpUtilityContext);

  return (
    <ReminderWrapper>
      Remember, these videos and more can be found in our{' '}
      <ShowHelpBtn
        onClick={() => setHelpVisible(!helpVisible)}
        action="control"
      >
        help
      </ShowHelpBtn>{' '}
      section
    </ReminderWrapper>
  );
};

export default function WelcomeScreen({ onHide, sectionLabel, children }) {
  return (
    <Fragment>
      <ContentWrapper>
        <Toolbar>
          <Button
            size="small"
            action="control"
            onClick={() => onHide(sectionLabel, 'hide')}
          >
            Don't show this again
          </Button>
        </Toolbar>
        {children}
      </ContentWrapper>
    </Fragment>
  );
}
