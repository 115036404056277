import React from 'react';

import styled from '@emotion/styled';
import * as colors from '<components>/colors';

import TextInput from '<components>/TextInput';

const FormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const StyledTextInput = styled(TextInput)`
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${colors.gray3};
  margin: 10px 0;
`;

const AddressForm = (props) => (
  <FormContainer>
    <StyledTextInput
      name="addressLine1"
      placeholder="Address line 1"
      onChange={props.onChange}
      value={props.addressLine1}
    />
    <StyledTextInput
      name="addressLine2"
      placeholder="Address line 2"
      onChange={props.onChange}
      value={props.addressLine2}
    />
    <StyledTextInput
      name="city"
      placeholder="City"
      onChange={props.onChange}
      value={props.city}
    />
    <StyledTextInput
      name="state"
      placeholder="State"
      onChange={props.onChange}
      value={props.state}
    />
    <StyledTextInput
      name="zipCode"
      placeholder="ZIP code"
      onChange={props.onChange}
      value={props.zipCode}
    />
  </FormContainer>
);

export default AddressForm;
