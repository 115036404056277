/** @jsx jsx */
/** @jsxRuntime classic */
import {
  StyledScrollbar,
  FlexColumnContainer,
} from '<components>/NumbrzPageComponents';
import FieldEditor from '<components>/FieldEditor';
import Button from '<src>/components/Button';
import { Loader, Message } from 'semantic-ui-react';
import { ConfigTable } from '<sections>/flows/styles';

import Card from '<components>/Card';

import styled from '@emotion/styled';
import { jsx, css } from '@emotion/react';

import * as colors from '<components>/colors';
import { getStatusCodeColor } from '<sections>/job-groups/utils';
import EditableMarkdown from '../EditableMarkdown';
import { SidebarSeparator } from '<components>/NumbrzMenus/SidebarNavMenu';

const ignoreSsrWarning =
  '/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */';

export const Content = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-y: hidden;

  & > .left {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: ${(props) => (props.compressedSidebar ? 'unset' : '320px')};
    height: 100%;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 2em 1.5em 2em 3em;
  }

  & > .right {
    display: flex;
    flex-direction: column;
    flex: 5;
    height: 100%;
    position: relative;
    overflow-x: hidden;
  }
`;

export const ConfigField = styled(FieldEditor)`
  width: 100%;
  outline: none;
  border: none;
  transition: border-color 125ms ease-in-out;
  border-bottom: 1px solid transparent;
  margin: ${({ margin }) => (margin ? `${margin}` : '0 0 0 0')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '13px')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 'normal')};
  letter-spacing: 0.2px;
  line-height: normal;
  letter-spacing: 0.5px;
  input& {
    padding: 1px 0;
  }
  &.placeholder,
  input&::placeholder {
    font-weight: normal;
    color: ${colors.gray2};
  }
  &:hover {
    cursor: text;
    border-bottom-color: ${({ disabled }) =>
      !disabled ? colors.blue4 : undefined};
  }
  &:focus {
    border-bottom-color: ${({ disabled }) =>
      !disabled ? colors.blue2 : undefined};
  }
  &:empty:after {
    content: '\\00a0';
  }
`;

export const TitleField = styled(FieldEditor)`
  outline: none;
  transition: border-color 225ms ease-in-out;
  border-bottom: 1px solid transparent;
  &.placeholder {
    font-style: italic;
    font-weight: normal;
  }
  &:focus,
  &:hover {
    border-bottom-color: ${colors.blue2};
  }
  &:empty:after {
    content: '\\00a0';
  }
  ${(props) => props.darkTheme && { color: 'white' }}
`;

export const Placeholder = styled('div')`
  margin: 20px 0 0 0;
`;

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: ${(props) => (props.width ? props.width : '100%')};
  padding: ${(props) => (props.padding ? props.padding : '2em 2em 2em 2em')};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};

  & > div:last-child {
    margin-bottom: 0;
  }
  overflow-y: auto;
  overflow-x: hidden;
  ${(props) =>
    props.darkTheme && {
      background: colors.gray1b,
    }}
`;

export const FlowContainer = styled(Container)`
  position: ${(props) => (props.position ? props.position : 'relative')};
  padding: 0;
  background: ${colors.gray11};
`;
export const FlowContent = styled(Container)`
  margin-right: 5px;

  ${StyledScrollbar}
  h2 {
    line-height: unset !important;
  }
`;

export const ListWrapper = styled(Container)`
  ${StyledScrollbar}
  h2 {
    line-height: unset !important;
  }
`;

export const FlowDescription = styled('div')`
  width: 100%;
  background: ${colors.yellow7};
  padding: 15px;
  border-radius: 8px;
  border: 1px solid ${colors.gray_border};
  margin: ${(props) => (props.margin ? props.margin : '0 0 20px 0')};
  ${(props) =>
    props.noContainer && {
      padding: 0,
      border: 'none',
      background: 'transparent',
    }}
`;

export const InfoContainer = styled(`div`)`
  background: ${colors.yellow7};
  padding: 15px;
  border-radius: 8px;
  border: 1px solid ${colors.gray_border};
  margin: ${(props) => (props.margin ? props.margin : '0')};
`;

export const JobGroupPageContent = styled('div')`
  display: flex;
  flex-direction: column;

  height: 100%;
  max-width: 1000px;
  min-width: 70%;
  padding: 3em 4em;
  margin: 0 auto;
  h2 {
    margin-bottom: 20px;
    cursor: default;
  }
  ${StyledScrollbar}
  overflow-y: auto;
`;

export const VerticalLine = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${(props) => (props.top ? '0px 0 10px 0' : '10px 0')};
  ${(props) => props.isRunning && { paddingBottom: '0' }}
  z-index: 0;

  &:last-child {
    justify-content: flex-end;
    padding-bottom: 0;
  }

  &:before {
    display: flex;
    justify-content: center;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background: ${colors.gray_border};
    z-index: 0;
  }
`;

export const TablePicker = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > span {
    margin-right: 8px;
  }
`;

export const ConfigList = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const ConfigItem = styled('div')`
  width: 100%;
  display: flex;
  flex-direction;
  align-items: baseline;
  padding: 2px 2px 2px 9px;
  border: 1px solid ${colors.gray3};
  &:not(:first-child)${ignoreSsrWarning} {
    border-top: 0px solid transparent;
  }
`;

export const AddConfigItem = styled(ConfigItem)`
  border-color: ${colors.blue4};
  border-radius: 0.28571429rem;
  margin-bottom: 3px;
`;

const StepCard = styled(Card)`
  width: 100%;
  height: fit-content;
  padding: 12px;
  ${(props) => props.zIndex && 'z-index: 1'};
  border: 1px solid ${colors.gray3};

  ${(props) =>
    props.statusColor && { border: `5px solid ${props.statusColor}` }}
`;
export { StepCard as Card };

export const JobGroupCard = styled(StepCard)`
  padding: 0;
  ${(props) =>
    (props.viewMode === 'status' ||
      (props.viewMode === 'editor' && props.isRunning)) &&
    !props.first && { marginTop: '20px' }}
  ${(props) => props.margin && { margin: props.margin }};
`;

export const JobGroupContent = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

export const GroupStatusCard = styled(StepCard)`
  z-index: 100;
  margin-bottom: 20px;
  padding-top: 5px;
  border: 1px solid ${colors.gray3};
`;

export const GroupStatusSummary = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 20px;
  div {
    display: flex;
    align-items: center;
    margin-top: 5px;
    span {
      display: flex;
      align-items: baseline;
      margin-right: 10px;
      font-weight: bold;
      font-size: 13px;
      i {
        font-size: 13px;
      }
    }
  }
  div.separator {
    width: 50%;
    margin: 0 0 7px 0;

    border-bottom: 1px dotted ${colors.gray3};
  }
`;

export const BlockFooter = styled('div')`
  border: 1px solid ${colors.gray_border};
  border-radius: 8px !important;
  padding: 5px;
  margin: 0 15px 10px 15px;
  display: flex;
`;

export const Header = styled('div')`
  display: flex;
  & > :first-child${ignoreSsrWarning} {
    flex: 1;
  }
  align-items: center;
`;

export const BlockHeader = styled(Header)`
  padding: 10px 10px 10px 20px;
  border-bottom: 1px solid ${colors.gray_border};
  background: ${colors.gray5};
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

export const Title = styled('div')`
  display: flex;
  flex-direction: column;
  & > h3,
  & > h4 {
    margin-bottom: 0 !important;
  }
`;

export const Subtitle = styled('div')`
  font-size: 12px;
  letter-spacing: 0.5px;
  color: ${colors.gray2};
`;

export const Body = styled('div')`
  & > * {
    margin: 4px 0;
  }
  & > :first-child${ignoreSsrWarning} {
    margin-top: 0;
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;

export const Toolbar = styled('div')`
  display: flex;
  align-items: center;
  margin-left: 15px;
`;

export const JobGroupBlockContent = styled('div')`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 20px;

  h4 {
    width: 100%;
    font-size: 1.1em;
    font-weight: bold;
    border-bottom: 1px dotted ${colors.yellow1b};
    margin: 10px 0 5px 0;
    text-align: center;
    padding-bottom: 5px;
  }
  table {
    width: 100%;
    margin: 5px 0 10px 0px;
  }
`;

export const SettingsWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 2px;
`;

export const ReviewSettings = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & label {
    font-size: 13px !important;
    font-weight: bold;
    color: rgb(51, 51, 51) !important;
    margin-right: 20px;
  }
  .dropdown {
    font-size: 13px !important;
    margin-right: 10px;
  }
  button {
    margin-top: 0;
    background-color: transparent;
    text-transform: none;
    font-size: 13px;
  }
  span.label {
    font-size: 13px;
    font-weight: bold;
  }
  div {
    display: flex;
    align-items: center;
  }
  h4 {
    margin-top: 10px !important;
  }
`;

export const CommentMarkdown = styled(EditableMarkdown)`
  h4 {
    span {
      font-size: 13px;
    }
  }
`;

export const SettingsSeparator = styled('div')`
  width: 100%;
  margin: 5px auto 0px auto;
  padding: 0 0 5px 0;
  border-bottom: 1px solid ${colors.gray_border};
`;

export const ReviewCommentInput = styled(ConfigField)`
  padding: 5px;
`;

export const CancelButton = (props) => (
  <Button
    {...props}
    css={css`
      font-weight: bold;
      border-radius: 4px;
      text-transform: none;
      svg {
        font-size: 0.9em;
        font-weight: normal;
      }
      z-index: 200;
    `}
  />
);

export const ReviewButton = (props) => (
  <Button
    {...props}
    baseColor={colors.green2}
    activeColor={colors.green1}
    css={css`
      font-weight: bold;
    `}
  />
);

const YellowButton = (props) => (
  <Button
    {...props}
    baseColor={colors.yellow1b}
    activeColor={colors.yellow1}
    contentColor={colors.gray1b}
  />
);

export const BlueButton = (props) => (
  <Button
    {...props}
    baseColor={colors.blue1}
    activeColor={colors.blue2}
    contentColor="white"
  />
);

export const ActiveStatusMessage = styled(Message)`
  background: white;
  .header,
  p {
    color: ${colors.gray1b};
  }
  border: 1px solid ${colors.blue1};
  box-shadow: none;
`;

export const FinishToolbar = styled('div')`
  position: absolute;
  bottom: 40px;
  right: 40px;
  display: flex;
  justify-content: space-around;
  margin: 20px auto 0px auto;
`;

export const FinishButton = styled(YellowButton)`
  min-height: 34px;
  font-weight: bold;
`;

export const ResultsButton = styled('a')`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  min-height: 34px;
  margin: 0 0 0 10px;
  padding: 0 18px;
  text-transform: uppercase;
  border-radius: 4px;
  color: white;
  background: ${colors.blue1};
  letter-spacing: 1px;
  :hover {
    background: ${colors.blue2};
    color: white;
  }
  z-index: 200;
`;

// Recent Runs
export const HistoryContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 5px;
  overflow-y: auto;
  ${StyledScrollbar}
`;

export const HistoryItemWrapper = styled('div')`
  width: 100%;
  margin-bottom: 10px;
`;

export const HistoryItemLine = styled('div')`
  height: 1px;
  border-top: 1px solid ${colors.gray2b};
  position: relative;
  top: 17px;
  z-index: 1;
`;

export const HistoryItem = styled('div')`
  width: 97%;
  position: relative;
  display: flex;
  justify-content: space-around;
  padding: 0.4em 1.2em;
  border-radius: 25px;
  border: ${(props) =>
    `1px solid ${
      props.status ? getStatusCodeColor(props.status) : colors.gray1b
    }`};
  background: transparent;

  color: ${colors.gray2b};
  :hover {
    cursor: pointer;
  }
  span {
    font-weight: bold;
  }
  z-index: 2;
  margin-bottom: 10px;
`;

export const HistoryItemSeparator = styled('div')`
  width: 98%;
  border-bottom: 1px dotted ${colors.blue2};
  margin: 0px auto 10px auto;
`;

export const Status = styled('span')`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-left: 3em;
  span {
    margin-left: 0.5em;
  }
  color: ${colors.gray2b};
`;

export const HistoryStatusLabel = styled(Status)`
  width: 160px;
  justify-content: flex-end;
`;

export const StyledLoader = styled(Loader)`
  ::after {
    border-color: #6aa5ff transparent transparent !important;
  }
  color: ${colors.gray2};
`;

export const StartDropdownWrapper = styled('div')`
  display: flex;
  align-items: baseline;
  width: fit-content;
  span {
    margin-right: 5px;
    font-weight: bold;
    text-transform: none;
    font-size: 13px;
    letter-spacing: 0.5px;
  }
  padding: 2px 10px;
  border-radius: 4px;
  background: white;
  border: 1px solid ${colors.gray3};
  margin: ${(props) => (props.margin ? props.margin : '0 0 0 0')};
`;

export const EditorHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    margin: 0 15px 0 0;
    flex: 2;
  }
  h2.darkTheme {
    color: white;
  }
  margin-bottom: 20px;
  padding-right: 10px;
`;

export const InProgressMsg = styled(Message)`
  width: 100%;
  margin-bottom: 20px !important;
  background: white !important;
  box-shadow: none !important;
  padding: 20px 30px;
  border: 1px solid ${colors.blue1};
  text-align: center;
  .header,
  p {
    color: ${colors.gray1b};
  }
  button {
    font-size: 0.85em;
    padding: 4px 10px;
    height: 28px;
  }
`;

export const RecentRunsList = styled('ul')`
  list-style-type: none;
  width: 100%;
  margin: 0;
  padding: 0;
`;

export const RecentRunListItem = styled('li')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 15px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid ${colors.gray3};
  margin-bottom: 1em;
  color: ${colors.gray2b};
  :hover {
    cursor: pointer;
  }
  span {
    font-weight: bold;
  }
`;

export const BlockName = styled('h4')`
  font-weight: bold;
  color: ${colors.gray1b};
  margin: 0 0 2px 10px;
`;

export const JobsTable = styled(ConfigTable)`
  td {
    padding: 5px 8px;
  }
`;

export const FlowTestTable = styled(ConfigTable)`
  width: 100%;
  margin: ${(props) => (props.margin ? props.margin : '10px 0')};
  th {
    background: ${colors.gray11};
    padding: 5px 8px;
  }
  td {
    padding: 5px 8px;
    &.error {
      background: ${colors.red1d};
    }
    &.warning {
      background: ${colors.yellow4};
    }
    &.active {
      background: ${colors.blue1};
    }
    &.highlightSource {
      background: ${colors.yellow4};
      box-shadow: inset 0 0 0 2px ${colors.yellow1b};
    }
    :focus {
      background: ${colors.blue4};
      box-shadow: inset 0 0 0 2px ${colors.blue2};
    }
  }

  input {
    background: transparent;
  }

  th {
    white-space: nowrap;
    padding: 4px 8px;
  }
`;

export const BlockSubheader = styled('div')`
  width: 100%;
  display: flex;
  span {
    width: 100%;
    font-size: 11px;
    color: ${colors.gray1};
  }
`;

export const BlockSubheaderInput = styled(ConfigField)`
  color: ${colors.gray2};
  margin-bottom: 0;
`;

export const SidebarWrapper = styled('div')`
  ${(props) =>
    !props.visible && props.visible !== undefined && 'display: none;'}
  border-right: 1px solid ${colors.gray_border};
  height: 100%;
`;

export const SidebarContent = styled('div')`
  width: 300px;
  height: 100%;
  padding: 0px 10px 20px 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  & > div:last-child {
    margin-bottom: 0;
  }
`;

export const FooterContainer = styled('div')`
  padding: 10px 0px 20px 0px;
  width: 260px;
  min-height: 140px;
  position: absolute;
  bottom: 0;
  background: white;
  z-index: 99;
`;

export const FooterContent = styled(FlexColumnContainer)`
  padding: 10px 0px 0 0;
`;

export const SidebarHeader = styled('div')`
  width: 100%;
  min-height: 45px;
  border-bottom: 1px solid ${colors.gray_border};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 5px;
  button {
    padding: 5px;
  }
`;

export const Separator = styled(SidebarSeparator)`
  width: 100%;
`;

export const RoundedContentWrapper = styled('div')`
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '100%')};
  ${(props) => props.minHeight && { minHeight: props.minHeight }};
  background: ${(props) => (props.background ? props.background : 'white')};
  border: ${(props) =>
    props.border ? props.border : `1px solid ${colors.gray_border}`};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '8px'};
  border-color: ${(props) =>
    props.borderColor ? props.borderColor : colors.gray_border};
  padding: ${(props) => (props.padding ? props.padding : '20px')};
  margin: ${(props) => (props.margin ? props.margin : '15px 0')};
  ${(props) => props.maxWidth && { maxWidth: props.maxWidth }};
  ${(props) => props.minWidth && { minWidth: props.minWidth }};
  overflow: ${(props) => (props.hideOverflow ? 'unset' : 'auto')};
  ${StyledScrollbar};
`;
