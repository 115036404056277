/** @jsx jsx */
/** @jsxRuntime classic */

import styled from '@emotion/styled';
import { jsx, css } from '@emotion/react';
import Button from '<src>/components/Button';

import * as colors from '<components>/colors';
import * as zIndex from '<components>/z-index';

export const StyledMasthead = styled('div')`
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 35px 15px 25px;
  box-sizing: border-box;
  position: relative;
  z-index: 1000;
  color: white;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 1;
  background: ${colors.gray1b};

  z-index: ${zIndex.header};
  ${(props) =>
    props.addShadow && { 'box-shadow': 'inset 0px -2px 3px #161f2c;' }};
`;
export const StyledBlankMasthead = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 35px;
  min-height: 60px;
  background: ${colors.gray1b};
  color: ${colors.white};
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 1;
`;

export const CenterContent = styled('div')`
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;

  input {
    border-radius: 45px;
    padding-top: 6px;
    padding-bottom: 6px;

    &:active {
      border-color: ${colors.blue1};
      box-shadow: inset 0 0 1px ${colors.blue1};
    }
  }

  button {
    margin-right: 10px;
  }
`;

export const StyledButton = (props) => (
  <Button
    {...props}
    css={css`
      font-size: 0.7em;
      pointer-events: auto !important;
      border-radius: 4px;
      padding: 6px 8px;
      border: none;
      height: unset;
    `}
  />
);
