import React, { forwardRef, useEffect, useRef } from 'react';

import { Plus, Trash } from '<components>/Icons';

import Cell, { StyledCell, StyledHeaderCell } from '../../Cell';
import Table from '../../Table';
import RangeLabel from '../../Widget/RangeLabel';

import Rule from './Rule';
import useAppearTrigger from '../../useAppearTrigger';

function ColumnControl({ api, widget, row, rowIdx, setAppearTrigger }) {
  const actions = [];
  if (api.deleteRow && widget.rows.length > 1) {
    actions.push(
      <Table.Action key="delete" onClick={() => api.deleteRow(widget, row)}>
        <Trash />
      </Table.Action>
    );
  }
  if (api.addRow) {
    actions.push(
      <Table.Action
        key="add"
        onClick={() => {
          const beforeID = widget.rows[rowIdx + 1]
            ? widget.rows[rowIdx + 1].ID
            : null;
          api.addRow(widget, {
            beforeID,
            correlationID: setAppearTrigger('edit'),
          });
        }}
      >
        <Plus />
      </Table.Action>
    );
  }
  const draggable = api.moveRow && widget.rows.length > 1;
  return (
    <Table.ColumnControl
      draggable={draggable}
      tabIndex={actions.length > 0 ? 0 : undefined}
    >
      {actions}
    </Table.ColumnControl>
  );
}

function Header({ api, widget, onLink, onUpdate }) {
  const [appearTrigger, setAppearTrigger] = useAppearTrigger();
  return (
    <>
      <Table.Columns>
        <Table.ControlColumn />
        <Table.Column />
        <Table.ControlColumn />
        <Table.Column />
        <Table.Column />
        <Table.Column />
        <Table.Column />
        {widget.rows.map((row) => (
          <Table.Column key={row.ID} />
        ))}
      </Table.Columns>
      <Table.Head>
        <Table.ControlRow>
          <Table.ColumnControl />
          <Table.ColumnControl colSpan={6} />
          {widget.rows.map((row, rowIdx) => (
            <ColumnControl
              key={row.ID}
              api={api}
              widget={widget}
              row={row}
              rowIdx={rowIdx}
              setAppearTrigger={setAppearTrigger}
            />
          ))}
        </Table.ControlRow>
        <Table.Row idx={0}>
          <Table.RowControl />
          <StyledHeaderCell colSpan={6} />
          {widget.rows.map((row) => (
            <RangeLabel
              key={row.ID}
              widget={widget}
              range={row}
              appearTrigger={appearTrigger}
              onUpdate={api.updateRow}
            />
          ))}
        </Table.Row>
      </Table.Head>
    </>
  );
}

function InputRow({
  api,
  appearTrigger,
  setAppearTrigger,
  widget,
  colIdx,
  rowIdx,
  inputOpts,
}) {
  const labelRef = useRef();
  const valueRef = useRef();
  const col = widget.config.inputs[colIdx];

  useEffect(() => {
    if (
      col.correlationID === appearTrigger.id &&
      appearTrigger.action === 'edit'
    ) {
      labelRef.current.edit(true);
    }
  }, [col.correlationID, appearTrigger.id, appearTrigger.action]);

  const actions = [];
  if (api.deleteInput && inputOpts.length > 1) {
    actions.push(
      <Table.Action key="delete" onClick={() => api.deleteInput(widget, col)}>
        <Trash />
      </Table.Action>
    );
  }
  if (api.addInput) {
    actions.push(
      <Table.Action
        key="add"
        onClick={() => {
          const beforeID = inputOpts[colIdx + 1]
            ? inputOpts[colIdx + 1].value
            : null;
          api.addInput(widget, {
            beforeID,
            correlationID: setAppearTrigger('edit'),
          });
        }}
      >
        <Plus />
      </Table.Action>
    );
  }
  const draggable = api.moveInput && inputOpts.length > 1;

  return (
    <Table.Row ord={rowIdx}>
      <Table.RowControl
        draggable={draggable}
        tabIndex={actions.length > 0 ? 0 : undefined}
      >
        {actions}
      </Table.RowControl>
      <Cell
        ref={labelRef}
        colSpan={6}
        as="th"
        cell={{
          ID: col.ID,
          widgetID: widget.ID,
          funcID: widget.funcID,
          value: col.label,
          dataType: 'string',
        }}
        isEditable
        onUpdate={(_, { value: label }) => {
          api.updateInput(widget, col, { label });
          if (col.correlationID === appearTrigger.id) {
            appearTrigger.done();
          }
        }}
        onConfirm={() => valueRef.current.edit()}
        onCancel={() => {
          if (col.correlationID === appearTrigger.id) {
            api.removeInput({ widgetID: widget.ID, columnID: col.ID });
            appearTrigger.done();
          }
        }}
        onBlur={() => {
          if (col.correlationID === appearTrigger.id) {
            appearTrigger.done();
          }
        }}
      />
      {widget.rows.map((row, idx) => {
        const cell = row.cells[colIdx];
        return (
          <Cell
            key={cell.ID}
            ref={valueRef}
            cell={cell}
            firstOutput={idx === 0}
            isEditable
            isInput
            isTarget
            onLink={api.updateCellLink}
            onUpdate={api.updateCellValue}
            // onConfirm={() =>
            //   api.addInput &&
            //   api.addInput({
            //     widgetID: widget.ID,
            //     afterColumnID: col.ID,
            //     correlationID: setAppearTrigger('edit'),
            //   })
            // }
          />
        );
      })}
    </Table.Row>
  );
}

const DefaultOutputLabelCell = forwardRef(({ api, widget, ord }, parentRef) => (
  <Cell
    ref={parentRef}
    as="th"
    isEditable
    cell={{
      ID: `defout-${widget.ID}`,
      funcID: widget.funcID,
      widgetID: widget.ID,
      dataType: 'String',
      value: widget.config.defaultOutputLabel,
    }}
    onUpdate={(_, { value: label }) =>
      api.updateDefaultOutputLabel(widget, label)
    }
    ord={ord}
  />
));

const DefaultOutputCell = forwardRef(({ api, widget }, parentRef) => (
  <Cell
    ref={parentRef}
    isEditable
    isTarget
    cell={widget.config.defaultOutput}
    onLink={api.updateCellLink}
    onUpdate={api.updateCellValue}
  />
));

function OutputCells({ widget }) {
  return widget.rows.map((row, idx) => {
    const cellIdx = widget.config.inputs.length + widget.config.rules.length;
    const cell = row.cells[cellIdx];
    return (
      <Cell
        key={cell.ID}
        cell={cell}
        isOutput
        isSource
        firstOutput={idx === 0}
        ord={cellIdx + 1}
      />
    );
  });
}

export default function SwitchBody({
  active,
  appearTrigger = { id: 'NONE' },
  setAppearTrigger,
  widget,
  api,
}) {
  const resultCol = widget.config.output;
  const inputOpts = widget.config.inputs.map((col) => ({
    text: col.label,
    value: col.ID,
  }));

  const numHeaders = widget.rows.length === 1 ? 0 : 1;
  const numInputs = inputOpts.length;
  const numRules = widget.config.rules.length;
  const numConditions = widget.config.rules.flatMap(
    (rule) => rule.conditions
  ).length;
  const prevConditions = widget.config.rules.reduce((acc, rules, idx) => {
    if (idx === 0) {
      return [0];
    }
    return [
      ...acc,
      acc[idx - 1] + widget.config.rules[idx - 1].conditions.length + 1,
    ];
  }, []);
  const numRows = numHeaders + numInputs + numRules + numConditions;

  return (
    <Table active={active}>
      <Header api={api} widget={widget} />

      <Table.Body>
        {inputOpts.map((input, idx) => (
          <InputRow
            key={input.value}
            appearTrigger={appearTrigger}
            setAppearTrigger={setAppearTrigger}
            api={api}
            widget={widget}
            colIdx={idx}
            rowIdx={numHeaders + idx}
            inputOpts={inputOpts}
          />
        ))}
        {widget.config.rules.map((rule, idx) => (
          <Rule
            key={rule.ID}
            api={api}
            appearTrigger={appearTrigger}
            setAppearTrigger={setAppearTrigger}
            rule={rule}
            ruleIdx={idx}
            rowIdx={numHeaders + numInputs + prevConditions[idx]}
            numInputs={inputOpts.length}
            widget={widget}
            inputOpts={inputOpts}
          />
        ))}
        <Table.Row ord={numRows}>
          <Table.RowControl ord={widget.config.rules.length + numInputs} />
          <DefaultOutputLabelCell
            api={api}
            widget={widget}
            ord={widget.config.rules.length + numInputs}
          />
          <StyledCell colSpan={4}>
            <em>Else Output</em>
          </StyledCell>
          <DefaultOutputCell api={api} widget={widget} />
          <StyledCell
            ord={widget.config.rules.length + numInputs}
            firstOutput
            isOutput
            colSpan={widget.rows.length}
            style={{ textAlign: 'center' }}
          >
            <span>TRUE</span>
          </StyledCell>
        </Table.Row>
        <Table.Row ord={widget.config.rules.length + numInputs + 1}>
          <Table.RowControl />
          <Cell
            colSpan={6}
            as="th"
            cell={{
              ID: resultCol.ID,
              funcID: widget.funcID,
              widgetID: widget.ID,
              dataType: 'String',
              value: resultCol.label,
            }}
            isEditable
            parseValue={(value) => ({ dataType: 'String', value })}
            onUpdate={(_, { value: label }) =>
              api.updateOutput(widget, { label })
            }
          />
          <OutputCells widget={widget} numInputs={numInputs} />
        </Table.Row>
      </Table.Body>
    </Table>
  );
}

export function SwitchWidgetEditor({ api, widget, active }) {
  const [appearTrigger, setAppearTrigger] = useAppearTrigger();
  return (
    <SwitchBody
      widget={widget}
      active={active}
      appearTrigger={appearTrigger}
      setAppearTrigger={setAppearTrigger}
      api={api}
    />
  );
}
