import React, { PureComponent } from 'react';
import memoize from 'lodash/memoize';
import fp from 'lodash/fp';

import DataTablePane from '<components>/DataTablePane';
import NestedPickerPane from '<components>/NestedPickerPane_V2';
import WaveSpinner from '<components>/WaveSpinner';
import { FlexColumnContainer } from '<src>/components/NumbrzPageComponents';

export const makeOptions = memoize((tables, filterSettings) =>
  tables
    .filter((t) => {
      if (filterSettings && filterSettings === 'jobOutput') {
        return t.features.insert === true;
      }
      return true;
    })
    .map((table) => ({
      value: table.ID,
      name: table.name,
      detail: table,
      parent: table.tableset
        ? {
            value: table.tableset.ID,
            name: table.tableset.name,
            detail: table.tableset,
          }
        : null,
    }))
);

const makeRecommendedOpts = memoize((options, mappings = []) => {
  const mappedSources = fp.uniq(
    mappings.map((m) => fp.getOr(undefined, 'dataset.tableset.ID', m))
  );

  return mappedSources.length === 0
    ? []
    : options.filter((o) => mappedSources.includes(o.parent.value));
});

export default class DataPickerPane extends PureComponent {
  static defaultProps = {
    initialSelection: null,
    onSelect: () => {},
  };

  state = {
    searchTerm: '',
    searchPattern: null,
    selectedID: null,
  };

  componentDidMount() {
    this.setState({ selectedID: this.props.initialSelection });
  }

  componentDidUpdate(prevProps) {
    if (!this.props.visible && prevProps.visible) {
      this.setState({
        searchTerm: '',
        searchPattern: null,
        selectedID: this.props.initialSelection,
      });
    }
  }

  render() {
    const {
      dialog,
      loading,
      selectedID,
      tables,
      onSelect,
      projectID,
      filterSettings,
      dataMappings = [],
    } = this.props;

    if (loading) return <WaveSpinner />;

    const options = makeOptions(tables, filterSettings);
    const recommendedOpts =
      dataMappings.length > 0 ? makeRecommendedOpts(options, dataMappings) : [];

    return (
      <NestedPickerPane
        pickerMode="dataTables"
        dialog={dialog}
        options={options}
        recommendedOptions={recommendedOpts}
        value={selectedID}
        onChange={(selectedID) => onSelect(selectedID)}
        renderListItem={(item, isSubItem) => <div>{item.name}</div>}
        renderItemDetail={(item) =>
          item ? (
            <FlexColumnContainer
              alignItems="flex-start"
              style={{ height: '100%', overflowY: 'auto' }}
            >
              <DataTablePane
                readOnly
                hideHeader
                table={item.detail}
                showTableType
                projectID={projectID}
              />
            </FlexColumnContainer>
          ) : null
        }
      />
    );
  }
}
