/** @jsx jsx */
/** @jsxRuntime classic */

import { css, keyframes } from '@emotion/react';

import * as vars from './vars';

const fadeIn = keyframes({
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
});

const table = {};
table.base = css({
  '--tableWidth': 'auto',
  '--tableHeight': 'auto',
  tableLayout: 'auto',
  emptyCells: 'show',
  borderSpacing: 0,
  border: 'none',
  fontSize: 12,
  transition: `all ${vars.transitionSpeed} ${vars.transitionTimingFunction}`,
  '& :focus': {
    outline: 'none',
  },
});

table.active = css({
  boxShadow: '1px 1px 3px 0 rgba(0,0,0,0.1)',
  transition: `box-shadow 200ms 25ms ease-in-out`,
});

// Place holders
table.head = `__head`;
table.body = `__body`;
table.row = `__row`;

{
  const diagonalStripeBg = {
    background:
      'repeating-linear-gradient(45deg, white, white 3px, #f7f7f7 3px, #f7f7f7 5px)',
  };

  const cellStates = {
    variable: {
      backgroundColor: vars.yellow2,
    },

    result: {
      backgroundColor: vars.green4,
    },

    active: {
      backgroundColor: vars.blue4,
      boxShadow: `inset 0 0 0 1px ${vars.blue2}`,
    },

    editing: {
      boxShadow: `inset 0 0 0 1px ${vars.blue3}`,
      backgroundColor: vars.cellPrimaryBackgroundColor,
    },

    dropHovered: {
      backgroundColor: vars.yellow1,
    },

    linkSource: {
      ...diagonalStripeBg,
      boxShadow: `inset 0 0 0 1px ${vars.green1}`,
    },

    inactiveLinkSource: {
      boxShadow: `inset 0 0 0 1px ${vars.green1}`,
    },

    linkTarget: {
      ...diagonalStripeBg,
      boxShadow: `inset 0 0 0 1px ${vars.orange1}`,
    },

    inactiveLinkTarget: {
      boxShadow: `inset 0 0 0 1px ${vars.orange1}`,
    },

    headerCell: {
      fontWeight: 'bold',
    },
  };

  table.cell = {
    base: css({
      ...vars.cellBorder,
      position: 'relative',
      fontSize: 'inherit',
      padding: vars.cellPadding.map((v) => [v, 'px'].join('')).join(' '),
      margin: '0',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      transition: `all ${vars.transitionSpeed} ${vars.transitionTimingFunction}`,
      backgroundColor: vars.cellPrimaryBackgroundColor,
      boxShadow: '0 0 0 1px transparent',

      [`.${table.row}:nth-of-type(even) &`]: {
        backgroundColor: vars.cellAlternateBackgroundColor,
      },

      '&:empty:after': { content: '"\\00a0"' },

      [`.${table.row} &:focus`]: {
        outline: 'none',
        boxShadow: `inset 0 0 0 1px ${vars.blue2}`,
      },

      [`th&, &.${table.head}`]: {
        fontWeight: 'bold',
      },

      ...Object.entries(cellStates).reduce(
        (styles, [key, style]) => ({
          ...styles,
          [`.${table.row} &.${key}`]: style,
        }),
        {}
      ),
    }),

    editable: {
      cursor: 'pointer',
    },

    draggable: {
      cursor: 'pointer',
    },
  };

  Object.keys(cellStates).forEach(
    (className) => (table.cell[className] = className)
  );
}

table.control = {};
table.control.base = css({
  ...vars.cellBorder,
  position: 'relative',
  backgroundColor: vars.cellAlternateBackgroundColor,
  borderColor: 'transparent',
  opacity: 0,
  transition: `all ${vars.transitionSpeed} ${vars.transitionTimingFunction}`,
  pointerEvents: 'none',
  visibility: 'none',
  userSelect: 'none',

  '&:focus': {
    outline: 'none',
    backgroundColor: vars.gray3,
  },

  [`.${table.active} &`]: {
    borderColor: vars.borderColor,
    opacity: 1,
    pointerEvents: 'auto',
    visibility: 'visible',
  },
});

table.control.vertical = css({
  height: '100%',
  width: vars.controlSize,
  borderRightColor: vars.borderColor,
});

table.control.horizontal = css({
  height: vars.controlSize,
  width: 'auto',
  borderBottomColor: vars.borderColor,
});

table.control.extendedDropTarget = css({
  '&:after': {
    zIndex: 1,
  },
  [`&.${table.control.vertical}:after`]: {
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: 'var(--tableWidth)',
  },
  [`&.${table.control.horizontal}:after`]: {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 'var(--tableHeight)',
  },
});

table.control.target = {
  base: css({
    position: 'absolute',
    display: 'flex',
    top: 1,
    right: 1,
    bottom: 1,
    left: 1,
    justifyContent: 'center',
    alignItems: 'center',
  }),
};

table.control.target.handle = css({
  position: 'absolute',
  display: 'block',
});

table.control.target.insertLine = css({
  position: 'absolute',
  background: vars.blue3,
  display: 'none',
  pointerEvents: 'none',
  animation: `${fadeIn} ${vars.transitionSpeed}`,
  zIndex: 2,

  '&:after, &:before': {
    content: '""',
    position: 'absolute',
    height: 0,
    width: 0,
    border: `${vars.dotSize}px solid transparent`,
  },

  [`.${table.control.horizontal} &`]: {
    top: -2,
    width: 2,
    height: 'var(--tableHeight)',

    '&:before': {
      left: -vars.dotSize + 1,
      top: 0,
      borderTopColor: vars.blue3,
    },

    '&:after': {
      left: -vars.dotSize + 1,
      bottom: 0,
      borderBottomColor: vars.blue3,
    },
  },

  [`.${table.control.horizontal}:last-child &:before,
    .${table.control.horizontal}:last-child &:after`]: {
    borderRight: '0',
  },

  [`.${table.control.vertical} &`]: {
    left: -2,
    height: 2,
    width: 'var(--tableWidth)',

    '&:before': {
      top: -vars.dotSize + 1,
      left: 0,
      borderLeftColor: vars.blue3,
    },

    '&:after': {
      top: -vars.dotSize + 1,
      right: 0,
      borderRightColor: vars.blue3,
    },

    'tr:last-child &:before, tr:last-child &:after': {
      borderBottom: 0,
    },
  },
});

table.control.dropHovered = {};
table.control.dropHovered.base = css({
  [`& .${table.control.target.insertLine}`]: {
    display: 'block',
  },
});

table.control.dropHovered.before = css({
  [`&.${table.control.vertical} .${table.control.target.insertLine}`]: {
    top: -2,
  },

  [`&.${table.control.horizontal} .${table.control.target.insertLine}`]: {
    left: -2,
  },
});

table.control.dropHovered.after = css({
  [`&.${table.control.vertical} .${table.control.target.insertLine}`]: {
    bottom: -2,
  },

  [`&.${table.control.horizontal} .${table.control.target.insertLine}`]: {
    right: -2,
  },
});

table.control.draggable = css({
  [`& .${table.control.target.base}`]: {
    cursor: 'grab',
    transition: `all ${vars.transitionSpeed} ${vars.transitionTimingFunction}`,
    userSelect: 'none',
  },

  [`&.${table.control.horizontal} .${table.control.target.handle}`]: {
    width: vars.controlHandleDimensions[1],
    height: vars.controlHandleDimensions[0],
    borderTop: vars.controlHandleBorder,
    borderBottom: vars.controlHandleBorder,
  },

  [`&.${table.control.vertical} .${table.control.target.handle}`]: {
    width: vars.controlHandleDimensions[0],
    height: vars.controlHandleDimensions[1],
    borderLeft: vars.controlHandleBorder,
    borderRight: vars.controlHandleBorder,
  },

  [`& .${table.control.target.base}:hover`]: {
    backgroundColor: vars.gray3,
  },

  [`& .${table.control.target.base}:active`]: {
    cursor: 'grabbing',
  },
});

table.control.divider = {};
table.control.divider.base = css({
  position: 'absolute',
  zIndex: 1,
  pointerEvents: 'none',

  [`.${table.control.horizontal} &`]: {
    top: -1,
    width: 2,
    height: 'var(--tableHeight)',
  },

  [`.${table.control.vertical} &`]: {
    left: -1,
    width: 'var(--tableWidth)',
    height: 2,
  },
});

table.control.divider.before = css({
  [`.${table.control.horizontal} &`]: {
    left: -1,
  },
  [`.${table.control.vertical} &`]: {
    top: -1,
  },
});

table.control.divider.after = css({
  [`.${table.control.horizontal} &`]: {
    right: -1,
  },
  [`.${table.control.vertical} &`]: {
    bottom: -1,
  },
});

table.control.dot = css({
  position: 'absolute',
  width: vars.dotTargetSize,
  height: vars.dotTargetSize,
  transition: `all ${vars.transitionSpeed} ${vars.transitionTimingFunction}`,
  cursor: 'pointer',

  '&:focus': {
    outline: 'none',
  },

  '&:after': {
    content: '""',
    position: 'absolute',
    left: '50%',
    top: '50%',
    display: 'block',
    width: vars.dotSize,
    height: vars.dotSize,
    borderRadius: '100%',
    backgroundColor: vars.dotColor,
    transform: 'translate(-50%, -50%)',
    transition: `all ${vars.transitionSpeed} ${vars.transitionTimingFunction}`,
  },

  [`
    .${table.control.divider.base}:hover &:after,
    .${table.control.divider.base}:focus-within &:after
  `]: {
    backgroundColor: vars.blue3,
  },

  [`.${table.control.horizontal} &`]: {
    top: -vars.dotTargetSize - vars.dotOffset,
    height: vars.dotTargetSize,
    transform: 'translateX(-50%)',
  },

  [`.${table.control.vertical} &`]: {
    left: -vars.dotTargetSize - vars.dotOffset,
    width: vars.dotTargetSize,
    transform: 'translateY(-50%)',
  },

  [`.${table.base}:not(.${table.active}) .${table.control.horizontal} &`]: {
    transform: `translate(-50%, ${vars.dotOffset * 2 - 1}px)`,
  },

  [`.${table.base}:not(.${table.active}) .${table.control.vertical} &`]: {
    transform: `translate(${vars.dotOffset * 2 - 1}px, -50%)`,
  },
});

table.control.corner = css({
  display: 'block',
  zIndex: 2,

  '& .outline': {
    position: 'absolute',
    left: vars.dotTargetSize + vars.dotOffset,
    top: vars.dotTargetSize + vars.dotOffset,
    width: `var(--tableWidth)`,
    height: `var(--tableHeight)`,
    border: `2px solid ${vars.blue3}`,
    pointerEvents: 'none',
    opacity: 0,
    transition: `all ${vars.transitionSpeed} ${vars.transitionTimingFunction}`,
  },

  '&:before': {
    content: '""',
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: vars.dotSize + vars.dotRingOffset + vars.dotRingWidth,
    height: vars.dotSize + vars.dotRingOffset + vars.dotRingWidth,
    border: `${vars.dotRingWidth}px solid ${vars.dotColor}`,
    borderRadius: '100%',
    transform: 'translate(-50%, -50%)',
    transition: `all ${vars.transitionSpeed} ${vars.transitionTimingFunction}`,
  },

  '&:after': {
    width: vars.dotSize - vars.dotRingOffset + vars.dotRingWidth,
    height: vars.dotSize - vars.dotRingOffset + vars.dotRingWidth,
  },

  '&:hover, &:focus-within': {
    '&:after': {
      backgroundColor: vars.blue3,
    },
    '&:before': {
      borderColor: vars.blue3,
    },
    '& .outline': {
      opacity: 1,
    },
  },

  [`&.${table.control.dot}`]: {
    left: -vars.dotOffset - vars.dotTargetSize / 2 - 1,
    top: -vars.dotTargetSize - vars.dotOffset - 1,
  },

  [`.${table.base}:not(.${table.active}) &.${table.control.dot}`]: {
    transform: `translate(${vars.dotOffset + vars.dotTargetSize / 2 - 3}px, ${
      vars.dotOffset + vars.dotTargetSize / 2 + 6
    }px)`,
  },
});

table.control.divider.inner = css({
  pointerEvents: 'auto',
});

table.control.divider.line = css({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: vars.blue3,
  opacity: 0,
  transition: `all ${vars.transitionSpeed} ${vars.transitionTimingFunction}`,

  '&:after, &:before': {
    content: '""',
    position: 'absolute',
    height: 0,
    width: 0,
    border: `${vars.dotSize}px solid transparent`,
  },

  [`.${table.control.horizontal} &`]: {
    '&:before': {
      left: -vars.dotSize + 1,
      top: 0,
      borderTopColor: vars.blue3,
    },

    '&:after': {
      left: -vars.dotSize + 1,
      bottom: 0,
      borderBottomColor: vars.blue3,
    },
  },

  [`.${table.control.horizontal}:last-child .${table.control.divider.after} &`]:
    {
      '&:before, &:after': {
        borderRight: '0',
      },
    },

  [`.${table.control.vertical} &`]: {
    '&:before': {
      top: -vars.dotSize + 1,
      left: 0,
      borderLeftColor: vars.blue3,
    },

    '&:after': {
      top: -vars.dotSize + 1,
      right: 0,
      borderRightColor: vars.blue3,
    },
  },

  [`tr:last-child .${table.control.divider.after} &`]: {
    '&:before, &:after': {
      borderBottom: 0,
    },
  },

  [`
    .${table.control.divider.inner}:hover + &,
    .${table.control.divider.inner}:focus-within + &
  `]: {
    opacity: 1,
  },
});

table.control.actions = css({
  position: 'absolute',
  display: 'inline-block',
  padding: '5px 7px',
  opacity: 0,
  background: vars.textColor,
  borderRadius: '4px',
  color: vars.gray5,
  fontWeight: 'normal',
  cursor: 'pointer',
  transition: `all ${vars.transitionSpeed} ${vars.transitionTimingFunction}`,
  lineHeight: 1,
  whiteSpace: 'nowrap',
  pointerEvents: 'none',
  zIndex: 2,

  '&:before, &:after': {
    content: '""',
    position: 'absolute',
  },

  [`.${table.control.horizontal} &`]: {
    top: -vars.actionsArrowSize - vars.actionsArrowOffset,
    left: '50%',
    transform: 'translate(-50%, -100%)',

    '&:before, &:after': {
      top: '100%',
    },

    '&:before': {
      left: '50%',
      width: '100%',
      height: vars.actionsArrowSize + vars.actionsArrowOffset,
      maxWidth: 80,
      transform: 'translateX(-50%)',
    },

    '&:after': {
      left: '50%',
      height: 0,
      width: 0,
      border: `${vars.actionsArrowSize}px solid transparent`,
      borderTopColor: vars.gray1,
      transform: 'translateX(-50%)',
    },
  },

  [`.${table.control.vertical} &`]: {
    top: '50%',
    left: -vars.actionsArrowSize - vars.actionsArrowOffset,
    transform: 'translate(-100%, -50%)',

    '&:before, &:after': {
      left: '100%',
    },

    '&:before': {
      top: '50%',
      height: '100%',
      width: vars.actionsArrowSize + vars.actionsArrowOffset,
      maxHeight: 80,
      transform: 'translateY(-50%)',
    },

    '&:after': {
      top: '50%',
      height: 0,
      width: 0,
      border: `${vars.actionsArrowSize}px solid transparent`,
      borderLeftColor: vars.gray1,
      transform: 'translateY(-50%)',
    },
  },

  [`.${table.control.horizontal} .${table.control.divider.base} &`]: {
    top:
      -vars.actionsArrowSize -
      vars.actionsArrowOffset -
      vars.dotTargetSize / 2 -
      vars.dotOffset,

    '&:before': {
      height:
        vars.actionsArrowSize +
        vars.dotTargetSize / 2 +
        vars.actionsArrowOffset +
        vars.dotOffset,
    },
  },

  [`.${table.control.vertical} .${table.control.divider.base} &`]: {
    left:
      -vars.actionsArrowSize -
      vars.actionsArrowOffset -
      vars.dotTargetSize / 2 -
      vars.dotOffset,

    '&:before': {
      width:
        vars.actionsArrowSize +
        vars.dotTargetSize / 2 +
        vars.actionsArrowOffset +
        vars.dotOffset,
    },
  },

  [`.${table.control.horizontal} .${table.control.corner} &`]: {
    top: -vars.actionsArrowOffset,
  },

  [`
    .${table.active} &:hover,
    .${table.active} &:focus-within,
    .${table.active} .${table.control.divider.base}:hover &,
    .${table.active} .${table.control.corner}:hover &,
    .${table.active} .${table.control.target.base}:hover:not(:active) ~ &
  `]: {
    pointerEvents: 'auto',
    opacity: 1,
  },
});

table.control.action = {};
table.control.action.base = css({
  ...vars.resetButton,
  cursor: 'pointer',
  opacity: 0.75,

  '&:focus, &:hover': {
    outline: 'none',
    opacity: 1,
  },

  '& + &': {
    marginLeft: 5,
  },
});

table.control.action.text = css({
  border: '1px solid #fff',
  borderRadius: 2,
  fontSize: 10,
  lineHeight: '10px',
  padding: 5,
  textTransform: 'uppercase',
});

table.control.dragPreviewLayer = css({
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 1000, // TODO: create a global layers(z-index) vars file
  left: 0,
  top: 0,
  width: '100vw',
  height: '100vh',
});

table.control.dragPreview = css({
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 1001, // TODO: create a global layers(z-index) vars file
  backgroundColor: vars.blue2,
  opacity: 0.2,
});

export default table;
