import React from 'react';
import { ChicletWrapper } from '../../styles';
import {
  InputChiclet,
  OutputChiclet,
  GrayChiclet,
  ErrorChiclet,
} from '<components>/NumbrzButtons';

export default function UsageChiclets({
  usageTypes,
  mappingRequired,
  mapping,
  hideType = false,
}) {
  return (
    <ChicletWrapper>
      {!mapping &&
        mappingRequired !== undefined &&
        mappingRequired === true && <ErrorChiclet>Required</ErrorChiclet>}
      {usageTypes.filters > 0 && <GrayChiclet>Input Filter</GrayChiclet>}
      {usageTypes.eraseData && usageTypes.eraseWithCriteria === 0 && (
        <GrayChiclet>Data erased</GrayChiclet>
      )}
      {usageTypes.eraseWithCriteria > 0 && (
        <GrayChiclet>Data erased w/conditions</GrayChiclet>
      )}
      {usageTypes.skip > 0 && <GrayChiclet>Skip</GrayChiclet>}

      {usageTypes.inputs > 0 && !hideType && (
        <InputChiclet marginRight="5px">Input</InputChiclet>
      )}
      {usageTypes.outputs > 0 && !hideType && (
        <OutputChiclet>Output</OutputChiclet>
      )}
    </ChicletWrapper>
  );
}
