import { useLocation } from 'react-router-dom';

const responseFields = ['code', 'realmId'];

export default function Callback({ serviceType }) {
  const location = useLocation();
  const relayStateStr = sessionStorage.getItem('oauth.relay_state');
  sessionStorage.removeItem('oauth.relay_state');
  const relayState = relayStateStr ? JSON.parse(relayStateStr) : {};
  const params = new URLSearchParams(location.search);

  if (params.get('state') !== relayState.nonce) {
    throw new Error('State mismatch - possible replay attack');
  }

  const code = params.get('code');

  if (code) {
    const response = {};
    responseFields.forEach((k) => {
      if (params.has(k)) {
        response[k] = params.get(k);
      }
    });

    window.localStorage.setItem('oauth.response', JSON.stringify(response));
  }

  return null;
}
