/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx } from '@emotion/react';

import { compose } from 'recompose';
import withDisplayName from '<components>/hoc/displayName';

import styledTable from './styles';

const Row = (props) => <tr {...props} css={styledTable.row} />;

export default compose(withDisplayName('TableRow'))(Row);
