import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import * as colors from '<components>/colors';

const ignoreSsrWarning =
  '/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */';

const bg = {
  data: colors.white,
  dataStripe: colors.gray4,
  dataHighlight: colors.blue4,
  error: colors.red1d,
  errorStripe: colors.red2b,
  input: colors.blue4,
  inputStripe: colors.blue3b,
  output: colors.green4,
  outputStripe: colors.green3,
  dropHover: colors.yellow1,
  header: colors.blue9,
  headerStripe: colors.blue8,
};

const boxShadow = `inset 0 0 0 2px ${colors.blue2}`;

const dataBackground = ({
  isError,
  isOver,
  isOutput,
  isInput,
  isStripe,
  ord,
}) => {
  if (ord !== undefined) {
    isStripe = ord % 2 === 1;
  }
  if (isOver) return bg.dropHover;

  if (isError) return isStripe ? bg.errorStripe : bg.error;
  if (isInput) return isStripe ? bg.inputStripe : bg.input;
  if (isOutput) return isStripe ? bg.outputStripe : bg.output;

  return isStripe ? bg.dataStripe : bg.data;
};
const headerBackground = ({ isStripe, ord }) => {
  if (ord !== undefined) {
    isStripe = ord % 2 === 1;
  }
  return isStripe ? bg.headerStripe : bg.header;
};
const backgroundStyle = (props) => css`
  td& {
    background-color: ${dataBackground(props)};
  }
  tr.stripe td& {
    background-color: ${dataBackground({ isStripe: true, ...props })};
  }
  th& {
    background-color: ${headerBackground(props)};
  }
  tr.stripe th& {
    background-color: ${headerBackground({ isStripe: true, ...props })};
  }
`;
const styleProps = [
  'draggable',
  'isHighlight',
  'isInput',
  'isOutput',
  'isOver',
  'isSeparated',
  'selected',
  'ord',
];

const td = 225;
const tf = `ease-in-out`;
const collapseTransitions = css`
  transition: all ${td}ms ${tf};
  table.collapse & {
    border-width: 0;
    border-left-color: transparent;
    border-top-color: transparent;
    background-color: transparent;
  }
`;

const STable = styled('table')`
  font-size: 12px;
  width: 100%;

  table-layout: auto;
  empty-cells: show;

  border-collapse: collapse;
  border-spacing: 0;
`;

function Table({ active, ...props }) {
  const collapse = active ? undefined : 'collapse';
  return <STable className={collapse} {...props} />;
}

const Columns = styled('colgroup')``;
const Body = styled('tbody')``;
const Head = styled('thead')``;

const Column = styled('col')``;
const ControlColumn = styled(Column)`
  ${collapseTransitions};
  width: 12px;
  table.collapse & {
    width: 0;
  }
`;

const Row = function TableRow({ ord = 0, ...props }) {
  const stripe = ord % 2 === 1 ? 'stripe' : undefined;
  return <tr className={stripe} {...props} />;
};
const ControlRow = styled(Row)`
  ${collapseTransitions};
  height: 12px;
  table.collapse & {
    height: 0;
  }
`;

const Control = styled('th', {
  shouldForwardProp: (prop) => !styleProps.includes(prop),
})`
  position: relative;
  ${collapseTransitions};
  padding: 0;
  cursor: ${({ draggable }) => (draggable ? 'grab' : undefined)};
  vertical-align: middle;

  ${(props) => backgroundStyle(props)};
  border: 1px solid ${colors.gray3};
  min-width: 12px;

  outline: none;
`;
const DControl = Control.withComponent('td');

const ActionButton = styled('button')`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  text-align: inherit;
  line-height: normal;
  appearance: none;
  outline: none;
  opacity: 75%;

  &:focus,
  &:hover {
    opacity: 100%;
  }
  & + & {
    margin-top: 5px;
  }
  ${ControlRow} & + & {
    margin-top: 0;
    margin-left: 5px;
  }
`;
function Action({ onClick = () => {}, ...props }) {
  return (
    <ActionButton
      onMouseDown={(e) => {
        e.preventDefault();
      }}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      {...props}
    />
  );
}
const Actions = styled('div')`
  position: absolute;
  z-index: 2;
  line-height: 1;
  font-weight: normal;
  white-space: nowrap;
  background: ${colors.gray1};
  color: ${colors.gray5};
  border-radius: 4px;

  &: before, &:after {
    content: '';
    position: absolute;
  }

  cursor: pointer;
  pointer-events: none;
  transition: opacity ${td}ms ${tf};
  opacity: 0;
  ${Control}:hover &, ${Control}:focus-within &, ${DControl}:hover &, ${DControl}:focus-within & {
    opacity: 100%;
    pointer-events: auto;
  }

  display: flex;
  flex-direction: column;
  padding: 5px 7px;
  top: 50%;
  left: -7px;
  transform: translate(-100%, -50%);

  &:before, &:after {
    left: 100%;
  }
  &:before {
    top: 50%;
    height: 100%;
    width: 7px;
    max-height: 80px;
    transform: translateY(-50%);
  }
  &:after {
    top: 50%;
    height: 0;
    width: 0;
    border: 6px solid transparent;
    border-left-color: ${colors.gray1};
    transform: translateY(-50%);
  }

  ${ControlRow} & {
    flex-direction: row;
    top: -7px;
    left: 50%;
    transform: translate(-50%, -100%);
  }
  ${ControlRow} &:before, ${ControlRow} &:after {
    left: 0;
    top: 100%;
  }
  ${ControlRow} &:before {
    left: 50%;
    width: 100%;
    height: 7px;
    max-width: 80px;
    transform: translateX(-50%);
  }
  ${ControlRow} &:after {
    left: 50%;
    height: 0;
    width: 0;
    border: 6px solid transparent;
    border-top-color: ${colors.gray1};
    transform: translateX(-50%);
  }
`;
const GrabControl = styled('div')`
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all ${td / 2}ms ${tf};
  table.collapse & {
    border-width: 0;
  }

  width: 3px;
  height: 8px;
  border-left: 1px solid ${colors.gray2};
  border-right: 1px solid ${colors.gray2};
  table.collapse & {
    width: 0px;
  }

  ${ControlRow} & {
    width: 8px;
    height: 3px;
    border-left: none;
    border-right: none;
    border-top: 1px solid ${colors.gray2};
    border-bottom: 1px solid ${colors.gray2};
  }
  table.collapse ${ControlRow} & {
    width: 8px;
    height: 0px;
  }
`;
function RowControl({ children, draggable, ...props }) {
  children = React.Children.toArray(children).filter((c) => !!c);
  return (
    <Control scope="row" draggable={draggable} {...props}>
      {children.length > 0 ? (
        <>
          <Actions>{children}</Actions>
          <GrabControl />
        </>
      ) : null}
    </Control>
  );
}

function DataControl({ children, draggable, ...props }) {
  children = React.Children.toArray(children).filter((c) => !!c);
  return (
    <DControl scope="row" draggable={draggable} {...props}>
      {children.length > 0 ? (
        <>
          <Actions>{children}</Actions>
          <GrabControl />
        </>
      ) : null}
    </DControl>
  );
}

function ColumnControl({ children, draggable, ...props }) {
  children = React.Children.toArray(children).filter((c) => !!c);
  return (
    <Control scope="col" draggable={draggable} {...props}>
      {children.length > 0 ? (
        <>
          <Actions>{children}</Actions>
          <GrabControl />
        </>
      ) : null}
    </Control>
  );
}

const Data = styled('td', {
  shouldForwardProp: (prop) => !styleProps.includes(prop),
})`
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  &:empty:after {
    content: '\\00a0';
  }

  ${(props) => backgroundStyle(props)};

  border-width: 1px;
  border-color: ${colors.gray3};
  border-style: solid;
  border-left-style: ${({ isSeparated }) => (isSeparated ? 'double' : 'solid')};
  border: 1px solid ${colors.gray3};
  ${Control} + &, ${DControl} + & {
    border-left: none;
  }
  ${Control}:first-child${ignoreSsrWarning} + & {
    border-left: 1px solid ${colors.gray3};
  }

  outline: none;
  box-shadow: ${({ selected }) => (selected ? boxShadow : undefined)};
  th& {
    font-weight: bold;
    white-space: normal;
  }
  tbody th& {
    text-align: left;
  }
`;

const Header = Data.withComponent('th');

Table.Columns = Columns;
Table.Head = Head;
Table.Body = Body;

Table.Column = Column;
Table.ControlColumn = ControlColumn;
Table.Row = Row;
Table.ControlRow = ControlRow;

Table.Header = Header;
Table.Data = Data;

Table.Control = Control;
Table.ColumnControl = ColumnControl;
Table.RowControl = RowControl;
Table.DataControl = DataControl;
Table.Action = Action;

export default Table;
