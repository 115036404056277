import styled from '@emotion/styled';
import * as colors from '<components>/colors';
import Button from '<src>/components/Button';
import { FlexRowContainer } from '<components>/NumbrzPageComponents';

export const Toolbar = styled(FlexRowContainer)`
  width: 100%;
  justify-content: flex-end;
  padding: 5px 20px;
  button {
  }
`;

export const ListTypeButton = styled(Button)`
  margin-left: 10px;
  padding: 7px;
  border-radius: 45px;
  :hover,
  :active,
  :focus {
    background-color: ${colors.gray7};
  }
  svg {
    font-size: 16px;
  }
  &.active {
    background-color: ${colors.gray7};
  }
`;

export const RowListWrapper = styled('div')`
  flex: 1 1 auto;
  min-width: 0;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
`;

export const FlatListRow = styled('div')`
  width: 100%;
  column-gap: 15px;
  display: grid;
  align-items: center;
  grid-template-columns: minmax(170px, 400px) 1fr 1fr 30px;
  text-align: left;
  margin: 0;
  padding: 7px;
  div.name {
    font-weight: bold;
    cursor: pointer;
  }
  &.model-row {
    grid-template-columns: minmax(170px, 1000px) 60px 40px 20px;
  }
  &.job-group-row {
    grid-template-columns: minmax(170px, 1000px) 60px 50px 60px 20px;
  }
  &.job-row {
    grid-template-columns: minmax(170px, 1000px) 60px 50px 60px 20px;
  }
  &.function-row {
    grid-template-columns: minmax(170px, 1000px) 20px;
  }
  &.flow-row {
    grid-template-columns: minmax(170px, 1000px) 20px;
  }
  &.data-row {
    grid-template-columns: minmax(170px, 1000px) minmax(60px, 170px) 60px 50px 20px;
  }
  &.data-table-row {
    grid-template-columns: minmax(170px, 1000px) 50px 20px;
  }

  div.sub-hdr {
    font-size: 12px;
    color: ${colors.gray1};
  }
`;

export const FlatListHdrRow = styled(FlatListRow)`
  border-bottom: 1px solid ${colors.gray_border};
  margin-bottom: 5px;

  div {
    color: ${colors.gray1};
  }
`;

export const FlatListRowSelectable = styled(FlatListRow)`
  &:hover {
    background-color: ${colors.gray7};
  }
  border-radius: 6px;
`;

export const PrimaryLabel = styled('span')`
  color: ${colors.gray2};
  font-weight: 200;
  font-size: 0.8em;
`;
