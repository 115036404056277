import React, { useState, Fragment, useEffect } from 'react';
import gql from 'graphql-tag';
import fp from 'lodash/fp';
import orderBy from 'lodash/orderBy';

import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '<src>/apollo/client';
import { GetJobGroups, StartJobGroup, JobGroupActiveByOwner } from '../queries';
import DocumentTitle from 'react-document-title';
import MainLayout from '<components>/MainLayout';
import Masthead from '<components>/Masthead';
import Sidebar from '<components>/MainLayout/Sidebar';

import ResizeableSplitView from '<src>/sections/flows/flow-testing/ResizeableSplitView';

import {
  PageContainer,
  ColumnWrapper,
} from '<components>/NumbrzPageComponents';
import { FlowContainer } from '<src>/components/NumbrzVerticalEditor';
import Button from '<src>/components/Button';
import AddNameDialog from '<src>/components/AddNameDialog';
import NumbrzEntityList from '<components>/NumbrzEntityList';

const CreateJobGroup = gql`
  mutation createJobGroup($input: JobGroupInput!) {
    createJobGroup(input: $input) {
      ID
      name
      description
    }
  }
`;

const DeleteJobGroup = gql`
  mutation deleteJobGroup($ID: CommonID!) {
    deleteJobGroup(ID: $ID)
  }
`;

export default function JobGroupListPage({ baseURL }) {
  const history = useHistory();
  const [createDialogVisible, setCreateDialogVisible] = useState(false);

  const { data: { jobGroups } = {}, loading } = useQuery(GetJobGroups);
  const {
    data: { jobGroupActiveByOwner: activeGroups = [] } = {},
    stopPolling,
    startPolling,
  } = useQuery(JobGroupActiveByOwner, {
    variables: {},
  });

  const [createJobGroup] = useMutation(CreateJobGroup);
  const [deleteJobGroup] = useMutation(DeleteJobGroup, {
    update: (cache, { data: { deleteJobGroup: ID } }) => {
      const { jobGroups = {} } = cache.readQuery({
        query: GetJobGroups,
      });

      const newJobGroups = fp
        .getOr([], 'groups', jobGroups)
        .filter((jG) => jG.ID !== ID);
      cache.writeQuery({
        query: GetJobGroups,
        data: {
          jobGroups: {
            ...jobGroups,
            groups: newJobGroups,
          },
        },
      });
    },
  });
  const handleCreateJobGroup = async (name) => {
    const res = await createJobGroup({
      variables: { input: { name } },
    });

    const jobGroup = fp.getOr(undefined, 'data.createJobGroup', res);
    if (jobGroup) {
      history.push(`/job-groups/${jobGroup.ID}`);
    }
  };

  const handleDeleteItem = async (ID) => {
    await deleteJobGroup({ variables: { ID } });
  };

  const [runJobGroup] = useMutation(StartJobGroup);

  const groups = loading ? [] : jobGroups.groups;

  useEffect(() => {
    activeGroups.length > 0 ? startPolling(2000) : stopPolling();

    return () => {
      stopPolling();
    };
  }, [activeGroups, startPolling, stopPolling]);

  return (
    <DocumentTitle title="Job Groups - Numbrz">
      <MainLayout
        overflowY="hidden"
        navigation={false}
        header={<Masthead />}
        main={
          <PageContainer>
            <ResizeableSplitView
              left={
                <Fragment>
                  <Sidebar hideMasthead />
                  <FlowContainer>
                    <ColumnWrapper>
                      <NumbrzEntityList
                        loading={loading}
                        entityType={'job-group'}
                        items={orderBy(groups, ['name'], ['asc'])}
                        onDeleteItem={handleDeleteItem}
                        onRunItem={runJobGroup}
                        itemsRunning={activeGroups}
                      />
                    </ColumnWrapper>
                    <Button.ListPageCreateBtn
                      onClick={() => setCreateDialogVisible(true)}
                    >
                      Create a job group
                      <span className="icon">+</span>
                    </Button.ListPageCreateBtn>
                    <AddNameDialog
                      objType="Job group"
                      onClose={() => setCreateDialogVisible(false)}
                      onCreate={handleCreateJobGroup}
                      visible={createDialogVisible}
                    />
                  </FlowContainer>
                </Fragment>
              }
              right={null}
            />
          </PageContainer>
        }
      />
    </DocumentTitle>
  );
}
