import React, { useMemo, Fragment } from 'react';
import { Switch, Route, useHistory, useRouteMatch } from 'react-router-dom';

import ErrorChiclet from '<src>/components/ErrorChiclet';
import SidebarNavMenu from '<components>/NumbrzMenus/SidebarNavMenu';

import {
  SidebarWrapper,
  SidebarHeader,
  SidebarContent,
  Separator,
} from '<src>/components/NumbrzVerticalEditor';
import { NumbrzTooltip } from '<src>/components/NumbrzButtons';
import { Icon } from 'semantic-ui-react';
import Button from '<src>/components/Button';
import FlowItemList from '../FlowItemList';
import { TablesetCounter } from '<src>/sections/jobs/styles';
import SidebarFooter from './Footer';

function renderStage(item, idx) {
  return (
    <>
      <TablesetCounter fontSize="14px">{idx + 1}</TablesetCounter>
      <h4>{item.name}</h4>
    </>
  );
}

export default function FlowSidebar({
  flow,
  features = {},
  api,
  flowSettings = {},
  setFlowSetting,
}) {
  const {
    onAddStage: baseAddStage,
    onRemoveStage,
    onRemoveIncludedStage,
    onMoveStage,
  } = api;

  const match = useRouteMatch();
  const history = useHistory();
  const leftSidebar =
    flowSettings && typeof flowSettings.leftSidebar !== 'undefined'
      ? flowSettings.leftSidebar
      : true;

  const stageHasErrors = useMemo(() => {
    let hasErrors = false;
    flow.stagesV2.forEach((s) => {
      if (!s.runnable) hasErrors = true;
    });
    return hasErrors;
  }, [flow.stagesV2]);
  const testHasErrors = useMemo(() => {
    let hasErrors = false;
    flow.tests.forEach((t) => {
      if (t.warnings.length > 0) hasErrors = true;
    });
    return hasErrors;
  }, [flow.tests]);

  const [jtHaveErrors, wtHaveErrors] = useMemo(() => {
    let jtErrors = false;
    let wtErrors = false;
    flow.data
      .filter((t) => t.prefSource === 'WorkingTable')
      .forEach((t) => {
        if (t.issues.length > 0) wtErrors = true;
      });
    flow.data
      .filter((t) => t.prefSource !== 'WorkingTable')
      .forEach((t) => {
        if (t.issues.length > 0) jtErrors = true;
      });
    return [jtErrors, wtErrors];
  }, [flow.data]);

  const flowNavOpts = [
    {
      text: `Stages`,
      key: 'stages',
      to: `${match.url}/stages`,
      additionalContent: stageHasErrors && (
        <NumbrzTooltip
          position="top"
          content="Stages contain issues"
          trigger={
            <span className="text">
              <ErrorChiclet className="exclamation" sidebar>
                <Icon name="exclamation" />
              </ErrorChiclet>
            </span>
          }
        />
      ),
    },

    {
      text: `Job tables`,
      key: 'jobTables',
      to: `${match.url}/job-tables`,
      additionalContent: jtHaveErrors && (
        <NumbrzTooltip
          position="top"
          content="Job tables contain issues"
          trigger={
            <span className="text">
              <ErrorChiclet className="exclamation" sidebar>
                <Icon name="exclamation" />
              </ErrorChiclet>
            </span>
          }
        />
      ),
    },

    {
      text: `Job variables`,
      key: 'variables',
      to: `${match.url}/variables`,
    },

    {
      text: `Working tables`,
      key: 'workingTables',
      to: `${match.url}/working-tables`,
      additionalContent: wtHaveErrors && (
        <NumbrzTooltip
          position="top"
          content="Working tables contain issues"
          trigger={
            <span className="text">
              <ErrorChiclet className="exclamation" sidebar>
                <Icon name="exclamation" />
              </ErrorChiclet>
            </span>
          }
        />
      ),
    },

    {
      text: `Tests`,
      key: 'flowTesting',
      to: `${match.url}/tests`,
      additionalContent: testHasErrors && (
        <NumbrzTooltip
          position="top"
          content="Tests contain issues"
          trigger={
            <span className="text">
              <ErrorChiclet className="exclamation" sidebar>
                <Icon name="exclamation" />
              </ErrorChiclet>
            </span>
          }
        />
      ),
    },
    ...(features.FlowInclude && flow.formatVersion >= 4
      ? [
          {
            text: `Included flows`,
            key: 'flowInclude',
            to: `${match.url}/included`,
          },
        ]
      : []),
    {
      text: 'Documentation',
      key: 'documentation',
      to: `${match.url}/documentation`,
    },
  ];

  const stages = useMemo(
    () =>
      flow.stagesV2.map((s) => {
        const stageRef = flow.stages.find((sR) => sR.stageID === s.ID);
        return {
          valid: s.runnable,
          ...s,
          isIncluded: stageRef.isIncluded,
          includedFrom: stageRef.includedFrom,
        };
      }),
    [flow.stagesV2, flow.stages]
  );

  const onAddStage = useMemo(() => {
    if (!baseAddStage) {
      return null;
    }
    return async (e) => {
      const newStage = await baseAddStage(e);
      if (newStage) {
        setFlowSetting(flow.ID, 'navActiveStage', newStage.ID);
        history.push(`${match.url}/stages/${newStage.ID}`);
      }
    };
  }, [flow.ID, match.url, history, flow.ID]);

  return (
    <SidebarWrapper visible={leftSidebar}>
      <SidebarHeader>
        <span />
        <Button.SidebarHideBtn
          onClick={() => setFlowSetting(flow.ID, 'leftSidebar', false)}
          title="Hide navigation"
        />
      </SidebarHeader>
      <SidebarContent>
        <Switch>
          <Route path={`${match.url}/stages`}>
            {() => (
              <Fragment>
                <SidebarNavMenu options={flowNavOpts} minHeight="190px" />
                <Separator />
                <FlowItemList
                  flowID={flow.ID}
                  baseURL={`${match.url}/stages`}
                  items={stages}
                  label="Stage"
                  renderItem={renderStage}
                  onRemoveItem={onRemoveStage}
                  onRemoveIncludedStage={onRemoveIncludedStage}
                  onMoveItem={onMoveStage}
                  setFlowSetting={setFlowSetting}
                  flowSettings={flowSettings}
                  api={api}
                  flow={flow}
                />
                <SidebarFooter
                  label="a stage"
                  onAddItem={onAddStage}
                  objType="Stage"
                />
              </Fragment>
            )}
          </Route>
          <Route path={`${match.url}/job-tables/:tableID`}>
            {({ match: innerMatch }) => {
              const { tableID } = innerMatch.params;

              const tablePath = `${match.url}/job-tables/${tableID}`;
              return (
                <SidebarNavMenu
                  options={[
                    {
                      text: `Columns`,
                      key: 'columns',
                      to: `${tablePath}/columns`,
                    },
                    {
                      text: `Import settings`,
                      key: 'import-settings',
                      to: `${tablePath}/import-settings`,
                    },
                    {
                      text: `Export settings`,
                      key: 'export-settings',
                      to: `${tablePath}/export-settings`,
                    },
                    {
                      text: `Job settings`,
                      key: 'job-settings',
                      to: `${tablePath}/job-settings`,
                    },
                    {
                      text: `Documentation`,
                      key: 'documentation',
                      to: `${tablePath}/documentation`,
                    },
                  ]}
                />
              );
            }}
          </Route>
          <Route path={`${match.url}/job-tables`}>
            {() => <SidebarNavMenu options={flowNavOpts} />}
          </Route>
          <Route path={`${match.url}/working-tables/:tableID`}>
            {({ match: innerMatch }) => {
              const { tableID } = innerMatch.params;

              const tablePath = `${match.url}/working-tables/${tableID}`;
              return (
                <SidebarNavMenu
                  options={[
                    {
                      text: `Columns`,
                      key: 'columns',
                      to: `${tablePath}/columns`,
                    },
                    {
                      text: `Documentation`,
                      key: 'documentation',
                      to: `${tablePath}/documentation`,
                    },
                  ]}
                />
              );
            }}
          </Route>
          <Route path={`${match.url}/working-tables`}>
            {() => <SidebarNavMenu options={flowNavOpts} />}
          </Route>

          <Route path={`${match.url}/tests/:testCaseKey`}>
            {({ match: innerMatch }) => {
              const { testCaseKey } = innerMatch.params;
              const testPath = `${match.url}/tests/${testCaseKey}`;
              return (
                <SidebarNavMenu
                  options={[
                    {
                      text: `Configuration`,
                      key: 'configuration',
                      to: `${testPath}`,
                    },
                  ]}
                />
              );
            }}
          </Route>

          <Route path={`${match.url}/tests`}>
            {() => <SidebarNavMenu options={flowNavOpts} />}
          </Route>
          <Route path={`${match.url}/variables`}>
            {() => <SidebarNavMenu options={flowNavOpts} />}
          </Route>
          <Route path={`${match.url}/documentation`}>
            {() => <SidebarNavMenu options={flowNavOpts} />}
          </Route>

          <Route path={`${match.url}/included/:flowID`}>
            {({ match: innerMatch }) => {
              const { flowID } = innerMatch.params;
              const includePath = `${match.url}/included/${flowID}`;
              return (
                <SidebarNavMenu
                  options={[
                    {
                      text: `Included stages`,
                      key: 'stages',
                      to: `${includePath}`,
                    },
                  ]}
                />
              );
            }}
          </Route>

          <Route path={`${match.url}/included`}>
            {() => <SidebarNavMenu options={flowNavOpts} />}
          </Route>
        </Switch>
      </SidebarContent>
    </SidebarWrapper>
  );
}
