import React from 'react';
import { Rating } from '@mui/material';
import { toHumanString } from 'human-readable-numbers';

import * as styles from '../styles';

function StatItem({ title, value, footer, small = false }) {
  return (
    <styles.Stat small={small}>
      <div>
        <div className="title">{title}</div>
        <div className="value">{value}</div>
        <div className="footer">{footer}</div>
      </div>
    </styles.Stat>
  );
}

function RatingStat({ ratings, small = false }) {
  return (
    <styles.Stat small={small}>
      <div>
        <div className="title">{`${toHumanString(ratings.count)} Ratings`}</div>
        <div className="value">{ratings.avg}</div>
        <div className="footer">
          <Rating
            size={small ? 'small' : undefined}
            value={ratings.avg}
            readOnly
          />
        </div>
      </div>
    </styles.Stat>
  );
}

export default function CatalogStats({
  catalogProject,
  small = false,
  center = false,
}) {
  const { stats } = catalogProject;
  if (!stats) {
    return null;
  }

  return (
    <styles.Stats center={center}>
      {stats.runs !== undefined ? (
        <StatItem
          small={small}
          title="Model Runs"
          value={toHumanString(catalogProject.stats.runs)}
        />
      ) : null}
      {stats.ratings !== undefined ? (
        <RatingStat ratings={stats.ratings} small={small} />
      ) : null}
      {stats.rank !== undefined ? (
        <StatItem
          small={small}
          title="Chart"
          value={`#${catalogProject.stats.rank}`}
          footer={catalogProject.category}
        />
      ) : null}
    </styles.Stats>
  );
}
