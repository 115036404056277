import React from 'react';
import { isEmpty } from '<src>/utils/text';
import { StyledTextInput } from '<src>/sections/data/styles';

function isValidPath(filePath) {
  if (isEmpty(filePath)) return false;
  if (filePath.startsWith(' ')) return false;
  return true;
}

export default function DropboxPane({
  children: renderBody,
  onInputChange,
  connectorParams,
  inputDisabled = false,
}) {
  const body = (
    <StyledTextInput
      placeholder="Dropbox Folder URL"
      name="filePath"
      onChange={onInputChange}
      disabled={inputDisabled}
      value={connectorParams.spreadsheetURL}
    />
  );

  return renderBody(body, !isValidPath(connectorParams.filePath));
}
