import { gql } from '@apollo/client';

import { FunctionChangesFragment } from './fragments';
import useEditFunction, { optimisticFunctionChanges } from './useEditFunction';

const UpdateWidget = gql`
  mutation UpdateWidget($funcID: CommonID!, $widgetID: ID!, $name: String) {
    updateWidgetV3(funcID: $funcID, widgetID: $widgetID, name: $name) {
      ...FunctionChangesFragment
    }
  }
  ${FunctionChangesFragment}
`;

export default function useUpdateWidget() {
  const updateWidget = useEditFunction(UpdateWidget, 'updateWidgetV3');

  return (widget, { name }) =>
    updateWidget({
      variables: { funcID: widget.funcID, widgetID: widget.ID, name },
      optimisticResponse: {
        __typename: 'Mutation',
        updateWidgetV3: optimisticFunctionChanges({
          funcID: widget.funcID,
          widgetsChanged: [
            {
              __typename: 'WidgetV3',
              ...widget,
              name: name !== undefined ? name : widget.name,
              config: {
                ...widget.config,
              },
            },
          ],
        }),
      },
    });
}
