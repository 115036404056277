import React, { Fragment } from 'react';
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import fp from 'lodash/fp';
import * as colors from '<components>/colors';

import { ActiveStatusButton } from '<src>/components/NumbrzButtons';
import Button from '<src>/components/Button';
import {
  PaidPlanWrapper,
  PackageDescription,
  PlanList,
  PlanWrapperContent,
  PlanBreakdown,
  PlanGroupHeader,
  ExpirationChiclet,
  ExpLabel,
} from '../../styles';

export default function PaidPlan({
  createNewOrg,
  groupName,
  plans,
  planPriceLabel,
  productPrices,
  paymentInterval,
  isCurrentPlan,
  activePlanID,
  defaultPlanID,
  setNewPlan,
  setChangePlanDialogVisible,
  stripePlanCancelsAtEndOfCycle,
  stripePlanCancelDate,
  stripePlanWillChangeAtEndOfCycle,
  stripePlanWillChangeDate,
  activeSubscriptionPlan,
  currentActivePlan,
}) {
  const planGroupDescription = (groupName) => {
    switch (groupName) {
      case 'Personal':
        return (
          <Fragment>
            <PackageDescription>
              <h2>Better Models to Scale your Work</h2>
              <PlanList>
                <li>
                  <Icon size="small" name="check" />
                  Data connector for Google Sheets
                </li>
                <li>
                  <Icon size="small" name="check" />
                  Data connector for Snowflake
                </li>
                <li>
                  <Icon size="small" name="check" />
                  Data connector for Excel on OneDrive
                </li>
                <li>
                  <Icon size="small" name="check" />
                  Data connector for Dropbox CSV
                </li>
                <li>
                  <Icon size="small" name="check" />
                  Data connector for QuickBooks Online
                </li>
                <li>
                  <Icon size="small" name="check" />
                  OAuth Authentication
                </li>
                <li>
                  <Icon size="small" name="check" />
                  Platform Data Storage
                </li>

                <li>
                  <Icon size="small" name="check" />
                  Slack Channel Support
                </li>
              </PlanList>
            </PackageDescription>
          </Fragment>
        );
      case 'Team':
        return (
          <Fragment>
            <PackageDescription>
              <h2>Build Better Models with Anyone</h2>
              <PlanList>
                <li>
                  <Icon size="small" name="check" />
                  Data connector for Google Sheets
                </li>
                <li>
                  <Icon size="small" name="check" />
                  Data connector for Snowflake
                </li>
                <li>
                  <Icon size="small" name="check" />
                  Data connector for Excel on OneDrive
                </li>
                <li>
                  <Icon size="small" name="check" />
                  Data connector for QuickBooks Online
                </li>
                <li>
                  <Icon size="small" name="check" />
                  Data connector for Dropbox CSV
                </li>
                <li>
                  <Icon size="small" name="check" />
                  OAuth Authentication
                </li>
                <li>
                  <Icon size="small" name="check" />
                  Model Collaboration
                </li>
                <li>
                  <Icon size="small" name="check" />
                  Platform Data Storage
                </li>

                <li>
                  <Icon size="small" name="check" />
                  Slack Channel Support
                </li>
                <li>
                  <Icon size="small" name="check" />
                  Job Groups
                </li>
              </PlanList>
            </PackageDescription>
          </Fragment>
        );
      case 'Enterprise':
        return (
          <Fragment>
            <PackageDescription>
              <h2>Connected Models at Scale</h2>

              <PlanList>
                <li>
                  <Icon size="small" name="check" />
                  OAuth Authentication
                </li>
                <li>
                  <Icon size="small" name="check" />
                  Model Collaboration
                </li>
                <li>
                  <Icon size="small" name="check" />
                  Platform Data Storage
                </li>

                <li>
                  <Icon size="small" name="check" />
                  Slack Channel Support
                </li>
                <li>
                  <Icon size="small" name="check" />
                  Live Web Session Support
                </li>
                <li>
                  <Icon size="small" name="check" />
                  Job Groups
                </li>
              </PlanList>
            </PackageDescription>
          </Fragment>
        );
      default:
        return null;
    }
  };

  const showCancelsLabel = (plan) => {
    return (
      isCurrentPlan(
        fp.getOr(undefined, 'id', activeSubscriptionPlan) || activePlanID,
        plan
      ) && stripePlanCancelsAtEndOfCycle
    );
  };

  const showActiveLabel = (plan) => {
    return (
      !createNewOrg &&
      stripePlanWillChangeAtEndOfCycle &&
      !stripePlanCancelsAtEndOfCycle &&
      plan.id === activeSubscriptionPlan.id
    );
  };

  const showCurrentBtn = (plan) => {
    return isCurrentPlan(
      fp.getOr(undefined, 'id', currentActivePlan) ||
        fp.getOr(undefined, 'id', activeSubscriptionPlan),
      plan
    );
  };

  const showChooseBtn = (plan) => {
    if (defaultPlanID === plan.id) return false;
    return (
      createNewOrg ||
      plan.id !== defaultPlanID ||
      plan.id !== fp.getOr(undefined, 'id', activeSubscriptionPlan)
    );
  };

  return (
    <PaidPlanWrapper>
      <PlanGroupHeader>
        <span>{plans[0].metadata.groupHeader}</span>
      </PlanGroupHeader>
      <PlanWrapperContent>
        {planGroupDescription(groupName)}
        {plans
          .sort(
            (a, b) =>
              parseInt(a.metadata['package-display-order']) -
              parseInt(b.metadata['package-display-order'])
          )
          .map((plan, idx) => {
            return productPrices.find((p) => p.product === plan.id) ? (
              <PlanBreakdown key={idx}>
                <div>
                  <h2>{plan.name}</h2>
                  {planPriceLabel(productPrices, plan.id)}

                  {plan.metadata.additionalFieldTop && (
                    <span>
                      <Icon size="small" name="check" />
                      {plan.metadata.additionalFieldTop}
                    </span>
                  )}
                  <span>
                    <Icon size="small" name="check" />
                    {parseInt(plan.metadata.maxActive).toLocaleString()} Active
                    Jobs per month
                  </span>

                  <span>
                    <Icon size="small" name="check" />
                    {parseInt(plan.metadata.maxOutput).toLocaleString()} Max Row
                    Outputs
                  </span>
                  {plan.metadata.maxUsers && (
                    <span>
                      <Icon size="small" name="check" />
                      {parseInt(plan.metadata.maxUsers).toLocaleString()} Max
                      Users
                    </span>
                  )}
                  {plan.metadata.additionalField1 && (
                    <span>
                      <Icon size="small" name="check" />
                      {plan.metadata.additionalField1}
                    </span>
                  )}
                  {plan.metadata.additionalField2 && (
                    <span>
                      <Icon size="small" name="check" />
                      {plan.metadata.additionalField2}
                    </span>
                  )}
                  {plan.metadata.additionalField3 && (
                    <span>
                      <Icon size="small" name="check" />
                      {plan.metadata.additionalField3}
                    </span>
                  )}
                  {plan.metadata.additionalField4 && (
                    <span>
                      <Icon size="small" name="check" />
                      {plan.metadata.additionalField4}
                    </span>
                  )}
                </div>
                {showCancelsLabel(plan) && (
                  <ExpirationChiclet>
                    <Icon name="clock" />
                    <ExpLabel>
                      {' '}
                      Cancels{' '}
                      {moment.unix(stripePlanCancelDate).format('MMM DD')}
                    </ExpLabel>
                  </ExpirationChiclet>
                )}

                {showActiveLabel(plan) && (
                  <ExpirationChiclet>
                    <Icon name="clock" />
                    <ExpLabel>
                      Active{' '}
                      {moment.unix(stripePlanWillChangeDate).format('MMM DD')}
                    </ExpLabel>
                  </ExpirationChiclet>
                )}

                {showCurrentBtn(plan) ? (
                  <ActiveStatusButton disabled onClick={() => {}}>
                    Current Plan
                  </ActiveStatusButton>
                ) : showChooseBtn(plan) ? (
                  <Button
                    bgHoverColor={colors.blue1}
                    contentColor="white"
                    onClick={() => {
                      setNewPlan(plan);
                      setChangePlanDialogVisible(true);
                    }}
                  >
                    Choose Plan
                  </Button>
                ) : null}
              </PlanBreakdown>
            ) : null;
          })}
      </PlanWrapperContent>
    </PaidPlanWrapper>
  );
}
