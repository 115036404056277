import React from 'react';
import { LibraryListItem, FunxCardContainer } from '<sections>/catalog/styles';
import { DataSeparator } from '<sections>/data/pages/TablesetPage/styles';
import {
  FlexColumnContainer,
  FlexRowContainer,
} from '<components>/NumbrzPageComponents';
import { Title, Card } from '<src>/sections/flows/Step/styles';
import GrainRoundedIcon from '@mui/icons-material/GrainRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

import {
  StyledBody,
  CardHeader,
  EmptyMsg,
  MappingHeader,
  DataNameContainer,
  TableCounterLbl,
  LinkContainer,
} from '<sections>/jobs/styles';

function renderItemDetail(fx) {
  return (
    <FlexColumnContainer alignItems="flex-start" width="100%">
      {fx.description && <div className="funx-desc">{fx.description}</div>}
      <FlexRowContainer
        alignItems="flex-start"
        justifyContent="space-around"
        padding="5px 0 10px 0"
        width="100%"
      >
        <FunxCardContainer>
          <Card isInput margin="0">
            <CardHeader isInput>
              <Title>
                <h5>Inputs</h5>
              </Title>
            </CardHeader>
            <StyledBody>
              {fx.inputs?.length === 0 ? (
                <EmptyMsg>No inputs</EmptyMsg>
              ) : (
                fx.inputs.map((input, idx) => (
                  <MappingHeader
                    isLast={idx === fx.inputs.length - 1}
                    isInput={true}
                    isOutput={false}
                  >
                    <LinkContainer>
                      <DataNameContainer>
                        <TableCounterLbl isInput={true} isOutput={false}>
                          {idx + 1}
                        </TableCounterLbl>
                        <span className="input-output-lbl">{input.label}</span>
                        <DataSeparator />
                        <span className="data-type">{input.dataType}</span>
                      </DataNameContainer>
                    </LinkContainer>
                  </MappingHeader>
                ))
              )}
            </StyledBody>
          </Card>
        </FunxCardContainer>
        <ArrowForwardRoundedIcon className="arrow" />
        <GrainRoundedIcon />
        <ArrowForwardRoundedIcon className="arrow" />
        <FunxCardContainer>
          <Card isOutput margin="0">
            <CardHeader isOutput>
              <Title>
                <h5>Outputs</h5>
              </Title>
            </CardHeader>
            <StyledBody>
              {fx.outputs?.length === 0 ? (
                <EmptyMsg>No inputs</EmptyMsg>
              ) : (
                fx.outputs.map((output, idx) => (
                  <MappingHeader
                    isLast={idx === fx.outputs.length - 1}
                    isInput={false}
                    isOutput={true}
                  >
                    <LinkContainer>
                      <DataNameContainer>
                        <TableCounterLbl isInput={false} isOutput={true}>
                          {idx + 1}
                        </TableCounterLbl>
                        <span className="input-output-lbl">{output.label}</span>
                        <DataSeparator />
                        <span className="data-type">{output.dataType}</span>
                      </DataNameContainer>
                    </LinkContainer>
                  </MappingHeader>
                ))
              )}
            </StyledBody>
          </Card>
        </FunxCardContainer>
      </FlexRowContainer>
    </FlexColumnContainer>
  );
}

export default function FunctionListItem({ fx }) {
  return (
    <LibraryListItem expanded={true}>
      <FlexRowContainer
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <FlexRowContainer
          alignItems="center"
          justifyContent="flex-start"
          width="100%"
        ></FlexRowContainer>
      </FlexRowContainer>

      {renderItemDetail(fx)}
    </LibraryListItem>
  );
}
