import React from 'react';

import { useMutation } from '<src>/apollo/client';

import List from '<sections>/projects/pages/ProjectPage/components/lists/List';
import { RunJob } from '<sections>/jobs/queries';

export default function TemplateJobs({ project }) {
  const [runJob] = useMutation(RunJob);
  const jobs = project.jobs.filter((job) => !job.isBase);
  return <List project={project} items={jobs} runJob={runJob} objType="job" />;
}
