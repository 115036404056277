/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from '@emotion/react';
import fp from 'lodash/fp';
import { useState, useMemo } from 'react';
import moment from 'moment';

import { Icon } from 'semantic-ui-react';
import { ConfigTable } from '<src>/sections/flows/styles';
import { formatDurationLabel } from '../utils';
import Button from '<components>/Button';
import * as styles from './styles';

const getOpStatusLabel = (op) => {
  switch (op) {
    case 'DataRead':
      return 'Reading Data';
    case 'Execute':
      return 'Executing';
    case 'DataWrite':
      return 'Writing Data';
    case 'DataDelete':
      return 'Deleting Data';
    default:
      return 'Generic Action';
  }
};

const getOpServiceLabel = (service) => {
  switch (service) {
    case 'GoogleSheets':
      return 'Google Sheets';
    case 'Snowflake':
      return 'Snowflake';
    case 'SQLServer':
      return 'SQL Server';
    case 'WorkingTable':
      return 'Working Table';
    case 'NumbrzFunction':
      return 'Numbrz Function';
    case 'NumbrzSequence':
      return 'Numbrz Sequence';
    case 'MicrosoftExcel':
      return 'Microsoft Excel';
    case 'File':
      return 'File';
    case 'Quickbooks':
      return 'QuickBooks Online';
    case 'UserTable':
      return 'Numbrz Table';
    case 'NumbrzCombine':
      return 'Numbrz Combine';

    default:
      return '';
  }
};

export default function JobTiming({ timing = [] }) {
  const [expanded, setExpanded] = useState(true);
  const [mode, setMode] = useState('summary');

  const fullData = useMemo(() => {
    return timing.map((t, idx) => {
      const duration = moment.duration(t.total / 1000, 'seconds');
      return (
        <tr key={idx}>
          <td>{getOpStatusLabel(t.call)}</td>
          <td>{getOpServiceLabel(t.service)}</td>
          <td>{formatDurationLabel(moment.utc(duration.asMilliseconds()))}</td>
          <td>{t.count}</td>
        </tr>
      );
    });
  }, [timing]);

  const summaryData = useMemo(() => {
    const groupedData = fp.groupBy('call', timing);

    Object.keys(groupedData).forEach((op) => {
      const initialValue = {
        records: 0,
        total: 0,
        elapsedMillis: 0,
        service: undefined,
      };

      groupedData[op] = groupedData[op].reduce((acc, cV) => {
        acc.label = getOpStatusLabel(cV.call);
        acc.elapsedMillis += cV.total;
        if (cV.count > 0) acc.records += cV.count;

        return acc;
      }, initialValue);
    });

    return Object.values(groupedData).map((op, idx) => {
      return (
        <tr key={idx}>
          <td>{op.label}</td>
          <td>{formatDurationLabel(moment.utc(op.elapsedMillis))}</td>
          <td>{op.records >= 0 ? op.records : 'N/A'}</td>
        </tr>
      );
    });
  }, [timing]);

  if (timing && timing.length > 0) {
    return (
      <styles.StatusContainer>
        <styles.StatusHeader>
          <styles.SectionHdrBtn onClick={() => setExpanded((e) => !e)}>
            Internal Data
            <Icon
              name={expanded ? 'caret up' : 'caret down'}
              title={expanded ? 'Hide' : 'Show'}
            />
          </styles.SectionHdrBtn>
          {expanded && (
            <styles.StatusToolbar>
              <Button
                size="small"
                action="control"
                className={mode === 'summary' && 'active'}
                onClick={() => setMode('summary')}
              >
                Summary
              </Button>

              <Button
                size="small"
                action="control"
                className={mode === 'full' && 'active'}
                onClick={() => setMode('full')}
              >
                Full Details
              </Button>
            </styles.StatusToolbar>
          )}
        </styles.StatusHeader>
        {expanded ? (
          <ConfigTable style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Operation</th>
                {mode !== 'summary' && <th>Service</th>}
                <th>Duration</th>
                <th>Records</th>
              </tr>
            </thead>
            <tbody>{mode === 'summary' ? summaryData : fullData}</tbody>
          </ConfigTable>
        ) : null}
      </styles.StatusContainer>
    );
  }

  return null;
}
