import { HttpLink } from '@apollo/client';
import * as Sentry from '@sentry/react';

import shortid from '<utils>/shortid';
import { sessionID } from '<src>/errors';

import { HTTP_URI } from '../config';

function startBreadcrumb({ start, ...data }) {
  Sentry.addBreadcrumb({
    type: 'info',
    category: 'started',
    message: 'GQL request started',
    timestamp: start / 1000,
    data,
  });
}

function finishBreadcrumb({ start, level = 'info', ...data }) {
  const finish = Date.now();
  Sentry.addBreadcrumb({
    type: 'info',
    level,
    category: 'finished',
    message: 'GQL request finished',
    timestamp: finish / 1000,
    data: {
      duration: finish - start,
      ...data,
    },
  });
}

const customFetch = (uri, options) => {
  if (navigator && !navigator.onLine) {
    throw new TypeError('network offline');
  }
  const requestID = shortid();
  const start = Date.now();
  startBreadcrumb({ start, requestID });
  Sentry.setTag('requestID', requestID);

  options.headers.accept = 'application/json';
  options.headers['x-numbrz-sessionid'] = sessionID;
  options.headers['x-numbrz-requestid'] = requestID;

  return fetch(uri, options)
    .then((r) => {
      finishBreadcrumb({ start, requestID });
      return r;
    })
    .catch((err) => {
      finishBreadcrumb({ start, requestID, level: 'error' });
      throw err;
    })
    .finally(() => {
      Sentry.setTag('requestID', requestID);
    });
};

export default function createNetworkLink() {
  return new HttpLink({ uri: HTTP_URI, fetch: customFetch });
}
