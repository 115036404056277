import React, { useContext } from 'react';
import { useQuery } from '<src>/apollo/client';

import getFeatures from './getFeatures.gql';

export const Context = React.createContext({});

export function Provider(props) {
  const { data, loading, refetch } = useQuery(getFeatures, {
    fetchPolicy: 'cache-first',
  });

  const features = { loading, refetch };
  if (data && data.viewer && data.viewer.featureFlags) {
    data.viewer.featureFlags.forEach((ff) => {
      features[ff.name] = ff.enabled;
    });
  }

  return <Context.Provider {...props} value={features} />;
}

export default function Feature({ children, name, negate }) {
  const { error, loading, ...features } = useContext(Context);
  let enabled = error || loading ? false : features[name];
  if (negate) enabled = !enabled;

  if (typeof children === 'function') {
    return children(enabled);
  }

  if (!enabled) return null;
  return children;
}
