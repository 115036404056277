import React, { useState } from 'react';
import { Message } from 'semantic-ui-react';
import AnimatedShowMore from 'react-animated-show-more';
import ReactMarkdown from 'react-markdown';

import Button from '<components>/Button';

function ShowMore({ isOpen }) {
  return <Button.Small>{isOpen ? 'Show Less' : 'ShowMore'}</Button.Small>;
}

export default function UpgradeAvailable({ project, onUpgrade }) {
  const [deploying, setDeploying] = useState(false);

  if (!project.deployment) {
    return null;
  }
  const {
    upgradeAvailable,
    type: deployType,
    fromCatalog,
  } = project.deployment;

  const canUpgrade =
    deployType === 'DeployService' && fromCatalog && !!upgradeAvailable;
  if (!canUpgrade) {
    return null;
  }

  const doUpgrade = async () => {
    setDeploying(true);
    await onUpgrade();
    setDeploying(false);
  };

  return (
    <Message info>
      <h3>Version {upgradeAvailable.releaseTag} is available </h3>
      <Button.Run size="small" onClick={doUpgrade} disabled={deploying}>
        {!deploying ? 'Upgrade' : 'Upgrading...'}
      </Button.Run>
      <h4>What's New</h4>
      <AnimatedShowMore height={80} speed={100} toggle={ShowMore}>
        <ReactMarkdown>{upgradeAvailable.releaseNotes}</ReactMarkdown>
      </AnimatedShowMore>
    </Message>
  );
}
