import { useContext, useEffect, useRef } from 'react';

import { Select } from './contexts';

export default function useSelectable(item = ({ type: Select.NONE } = {})) {
  const ref = useRef();
  const select = useContext(Select);

  useEffect(() => {
    const target = ref.current;
    const l = () => select(item);
    const bl = () => select({ type: Select.NONE });
    target.addEventListener('focus', l);
    target.addEventListener('blur', bl);
    return () => {
      target.removeEventListener('focus', l);
      target.removeEventListener('blur', bl);
    };
  }, [select, item]);

  return [ref];
}

useSelectable.CELL = Select.CELL;
