import styled from '@emotion/styled';
import * as colors from '<components>/colors';
import Button from '<components>/Button';
import Dialog from '<components>/Dialog';

export const ContentWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 50px 50px 50px;
  max-width: 700px;
  margin: 0px auto 60px auto;
  border-radius: 12px;
  background-color: white;
`;

export const TwoColSection = styled('div')`
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  img {
    height: 150px;
  }
  :hover {
    cursor: pointer;
  }
  div.col {
    flex: 1;
    width: 50%;
  }
  div.video {
    position: relative;

    height: 100%;
    background: ${colors.gray_border};
  }
`;

export const ReminderWrapper = styled('div')`
  width: 100%;
  padding: 10px 15px;

  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;

  border-radius: 4px;
  background: ${colors.active_blue};
`;

export const SectionHdr = styled('h1')`
  .underline {
    border-bottom: 4px solid #fdb800;
  }
`;

export const Toolbar = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

export const ContentHdr = SectionHdr.withComponent('h3');

export const SectionSubhdr = styled('p')`
  margin: 10px 0;
  padding: 0;
`;

export const Separator = styled('span')`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${colors.gray_border};
  margin: 20px 0;
`;

export const ShowHelpBtn = styled(Button)`
  font-weight: bold;
  color: ${colors.blue2};
  padding: 0 3px;
  margin: 0;
  :hover {
    background: transparent;
    color: ${colors.blue1};
  }
`;

export const VideoDialog = styled(Dialog)`
  height: 60%;
  width: 60%;
`;

export const VideoDialogBody = styled(Dialog.Body)`
  iframe {
    height: 100%;
    width: 100%;
  }
  :focus {
    outline: none;
  }
`;
