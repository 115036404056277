/* eslint-disable @numbrz/apollo/hook-import */

// This file contains wrappers for standard GraphQL hooks to standardize
// on Numbrz conventions

import { useEffect } from 'react';
import {
  useQuery as baseUseQuery,
  useLazyQuery as baseUseLazyQuery,
  useMutation as baseUseMutation,
} from '@apollo/client';
import { loadDevMessages } from '@apollo/client/dev';

if (__DEV__) {
  loadDevMessages();
}

function handleResult(result, options) {
  const { errorPolicy } = options;
  if (errorPolicy && errorPolicy !== 'throw') {
    return result;
  }

  if (result.error) {
    throw result.error;
  }

  return result;
}

function fullOptions(options, defaults) {
  return Object.assign({}, defaults, options);
}

function effectiveOptions(options) {
  if (options.errorPolicy === 'throw') {
    return { ...options, errorPolicy: 'all' };
  }
  return options;
}

function makeHook(baseHook, defaultOptions) {
  return (query, options) => {
    options = fullOptions(options, defaultOptions);
    return handleResult(baseHook(query, effectiveOptions(options)), options);
  };
}

const defaultQueryOpts = {
  // partialRefetch: true,
};
export const useQuery = makeHook(baseUseQuery, defaultQueryOpts);
export const useLazyQuery = makeHook(baseUseLazyQuery, defaultQueryOpts);

const defaultMutationOpts = {};
export const useMutation = makeHook(baseUseMutation, defaultMutationOpts);

function useFetchMore(data, getCursor, fetchMore) {
  const cursor = data ? getCursor(data) : null;

  useEffect(() => {
    if (cursor) {
      fetchMore({ variables: { cursor } });
    }
  }, [cursor, fetchMore]);

  return cursor;
}

export function usePagedQuery(
  query,
  options,
  getCursor = (data) => data.cursor
) {
  const { fetchMore, loading, ...result } = useQuery(query, options);
  const cursor = useFetchMore(result.data, getCursor, fetchMore);
  return { ...result, loading: loading || !!cursor };
}

export function usePagedLazyQuery(
  query,
  options,
  getCursor = (data) => data.cursor
) {
  const [fetch, { fetchMore, loading, ...result }] = useLazyQuery(
    query,
    options
  );
  const cursor = useFetchMore(result.data, getCursor, fetchMore);
  return [fetch, { ...result, loading: loading || !!cursor }];
}
