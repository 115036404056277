import React, { PureComponent } from 'react';
import { getDisplayName, hoistStatics } from 'recompose';

export default (extraProps = {}) => {
  const withExtraProps = BaseComponent => {
    const name = getDisplayName(BaseComponent);

    class WithExtraProps extends PureComponent {
      render() {
        let finalProps = extraProps;
        if (typeof extraProps === 'function') {
          finalProps = extraProps(this.props);
        }
        return <BaseComponent {...this.props} {...finalProps} />;
      }
    }

    WithExtraProps.BaseComponent = BaseComponent;
    WithExtraProps.displayName = `withExtraProps(${name})`;

    return WithExtraProps;
  };

  return hoistStatics(withExtraProps);
};
