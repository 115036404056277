import React, { Fragment, useEffect } from 'react';

import {
  JobGroupBlockContent,
  ReviewButton,
} from '<components>/NumbrzVerticalEditor';

import { CollapseButton } from '<components>/NumbrzButtons';
import { getStatusChiclet } from '../../utils';
import DurationLabel from '<sections>/jobs/JobStatus/DurationLabel';
import * as styles from '<sections>/jobs/JobStatus/styles';
import JobList from '../JobList';

const noop = () => {};

function getBlockStart(transitions = []) {
  const tStart = transitions.find((t) => t.type === 'BlockStarted');
  if (tStart) return tStart.timestamp;

  return undefined;
}

function getBlockEnd(transitions = []) {
  const tEnd = transitions.find((t) =>
    ['BlockCompleted', 'BlockCancelled', 'BlockFailed'].includes(t.type)
  );
  if (tEnd) return tEnd.timestamp;

  return undefined;
}

function getReviewStatus(transitions = []) {
  const reviewStatus = transitions.find((t) =>
    ['ReviewApproved', 'ReviewDenied'].includes(t.type)
  );

  if (reviewStatus) return getStatusChiclet(reviewStatus.type);

  return null;
}

function getReviewComment(transitions = []) {
  const reviewStatus = transitions.find((t) =>
    ['ReviewApproved', 'ReviewDenied'].includes(t.type)
  );

  if (reviewStatus && reviewStatus.desc)
    return (
      <styles.reviewComments>
        <span className="headerLabel">Review Comments:</span>
        <p>
          {reviewStatus.desc} - <b>{reviewStatus.userName}</b>
        </p>
      </styles.reviewComments>
    );

  return null;
}
export default function BlockStatus({
  blockIdx,
  block,
  jobGroupID,
  expanded,
  onExpand = noop,
  projectNames,
  blockRunStatus,
  first,
  onReviewBlock,
  isRunning,
  disabled,
  viewMode,
  last,
}) {
  const blockRunState = blockRunStatus ? blockRunStatus.state : undefined;
  const transitions = blockRunStatus ? blockRunStatus.transitions : [];

  useEffect(() => {
    if (
      blockRunState &&
      ['Active', 'BlockFailed', 'WaitingReview'].includes(blockRunState)
    )
      onExpand(true, block.key);
    if (blockRunState && ['Complete'].includes(blockRunState))
      onExpand(false, block.key);
  }, [blockRunState, onExpand, block.key]);

  return (
    <styles.StageContainer>
      <styles.StageSummaryContainer collapsed={!expanded}>
        <styles.StageSummaryToolbar>
          <div>
            <h4>{block.name}</h4>
            <styles.BlockSubheader>
              {`Block ${blockIdx} | Duration: `}
              <DurationLabel
                start={getBlockStart(transitions)}
                end={getBlockEnd(transitions)}
              />
            </styles.BlockSubheader>
          </div>
        </styles.StageSummaryToolbar>
        <styles.StageToolbar>
          {getReviewStatus(transitions)}
          {getStatusChiclet(blockRunState, isRunning)}

          <CollapseButton
            label="Block"
            onClick={() => onExpand(!expanded, block.key)}
            collapsed={!expanded}
          />
        </styles.StageToolbar>
      </styles.StageSummaryContainer>

      {expanded && (
        <Fragment>
          <JobGroupBlockContent>
            {block.jobs.length > 0 && (
              <JobList
                jobs={block.jobs}
                projectNames={projectNames}
                blockRunStatus={blockRunStatus}
                isRunning={isRunning}
                viewMode={viewMode}
              />
            )}

            {isRunning && blockRunState === 'WaitingReview' && (
              <ReviewButton
                style={{ marginTop: '20px' }}
                onClick={() => onReviewBlock(block)}
              >
                Review
              </ReviewButton>
            )}
          </JobGroupBlockContent>
          {getReviewComment(transitions)}
        </Fragment>
      )}
    </styles.StageContainer>
  );
}
