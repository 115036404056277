import React, { Fragment } from 'react';

import { isEmpty } from '<src>/utils/text';
import { StyledTextInput } from '<src>/sections/data/styles';

function isValid(project, dataset, activeCredential, serviceAcct) {
  if (isEmpty(project) || isEmpty(dataset)) return false;

  return true;
}

export default function BigQueryPane({
  children: renderBody,
  onInputChange,
  connectorParams,
  inputDisabled = false,
}) {
  const body = (
    <Fragment>
      <StyledTextInput
        //eslint-disable-next-line
        autoFocus={true}
        placeholder="The GCP Project name"
        name="project"
        onChange={onInputChange}
        disabled={inputDisabled}
        value={connectorParams.project}
      />
      <StyledTextInput
        //eslint-disable-next-line
        autoFocus={true}
        placeholder="The BigQuery Dataset name"
        name="dataset"
        onChange={onInputChange}
        disabled={inputDisabled}
        value={connectorParams.dataset}
        style={{ marginTop: '10px' }}
      />
    </Fragment>
  );

  return renderBody(
    body,
    !isValid(connectorParams.project, connectorParams.dataset)
  );
}
