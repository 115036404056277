import React from 'react';

import * as colors from '<components>/colors';

export default function FieldUnit({ field }) {
  if (field.__typename !== 'DateSequenceV2') {
    return null;
  }

  let unit = field.dateUnit.toLowerCase();
  if (field.amount === '1') {
    unit = unit.substring(0, unit.length - 1);
  }

  return (
    <span style={{ color: colors.gray2, paddingLeft: '.5em' }}>{unit}</span>
  );
}
