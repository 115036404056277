import { base, add, subtract, multiply, divide } from './arithmetic';
import data from './data';
import { dateCompose, dateExpand } from './date';
import lookup from './lookup';
import switchBI from './switch';
import join from './join';

export default {
  [base.type]: base,
  [add.type]: add,
  [subtract.type]: subtract,
  [multiply.type]: multiply,
  [divide.type]: divide,
  [data.type]: data,
  [dateCompose.type]: dateCompose,
  [dateExpand.type]: dateExpand,
  [lookup.type]: lookup,
  [switchBI.type]: switchBI,
  [join.type]: join,
};
