import React from 'react';
import update from 'immutability-helper';

import useSwitchWidgetAPI from '../../gql/useSwitchWidgetAPI';
import { WidgetAPI } from '../../contexts';

export function EditorAPI({ children }) {
  const api = useSwitchWidgetAPI();
  return <WidgetAPI.Provider value={api}>{children}</WidgetAPI.Provider>;
}

export const initialSetupState = {
  cells: {
    in1: { ID: 'in1', value: null },
    rule1res: { ID: 'rule1res', value: null },
    rule1out: { ID: 'rule1out', value: null },
    cond1test: { ID: 'cond1test', value: null },
    out1: { ID: 'out1', value: null },
    defout: { ID: 'defout', value: null },
  },
  widget: {
    name: 'Switch',
    type: 'Switch',
    columns: [],
    rows: [
      {
        ID: 'row1',
        cellIDs: ['in1', 'rule1res', 'out1'],
        template: { ID: 'row1', label: 'Column 1' },
      },
    ],
    switchConfig: {
      inputs: [
        { ID: 'var1', label: 'Variable 1', dataType: 'Any', section: 'Input' },
      ],
      output: {
        ID: 'out',
        label: 'Result',
        dataType: 'Any',
        section: 'Output',
      },
      defaultOutputID: 'defout',
      defaultOutputLabel: 'Default',
      rules: [
        {
          label: 'Rule 1',
          outputValueID: 'rule1out',
          conditions: [
            {
              inputID: 'var1',
              op: 'Lt',
              testValueID: 'cond1test',
            },
          ],
        },
      ],
    },
  },
};

function columnUpdates({ label, comment, dataType }) {
  const updates = {};
  if (label !== undefined) {
    updates.label = { $set: label };
  }
  if (comment !== undefined) {
    updates.comment = { $set: comment };
  }
  if (dataType) {
    updates.dataType = { $set: dataType };
  }
  return updates;
}

export function makeSetupAPI(state, baseAPI, setWidget, parent) {
  return {
    updateRule: (_, { ruleID, label }) =>
      setWidget((widget) =>
        update(widget, {
          switchConfig: {
            rules: {
              [ruleID]: {
                label: { $set: label },
              },
            },
          },
        })
      ),
    updateCondition: (_, { ruleID, condID, inputID, op }) =>
      setWidget((widget) => {
        const updates = {};
        if (inputID) {
          updates.inputID = { $set: inputID };
        }
        if (op) {
          updates.op = { $set: op };
        }
        return update(widget, {
          switchConfig: {
            rules: {
              [ruleID]: {
                conditions: {
                  [condID]: updates,
                },
              },
            },
          },
        });
      }),
    updateInput: (_, input, updates) =>
      setWidget((widget) =>
        update(widget, {
          switchConfig: {
            inputs: {
              [widget.switchConfig.inputs.findIndex((c) => c.ID === input.ID)]:
                columnUpdates(updates),
            },
          },
        })
      ),
    updateOutput: (_, updates) =>
      setWidget((widget) =>
        update(widget, {
          switchConfig: {
            output: columnUpdates(updates),
          },
        })
      ),
  };
}

function ruleColumns(widget) {
  return widget.switchConfig.rules.map((rule) => ({
    ID: rule.ID,
    label: rule.Label,
  }));
}

export function makeUIWidget(widget, cells, parent) {
  const config = update(widget.switchConfig, {
    type: { $set: 'Switch' },
    defaultOutput: { $set: cells[widget.switchConfig.defaultOutputID] },
    columns: {
      $set: [
        ...widget.switchConfig.inputs,
        ...ruleColumns(widget),
        widget.switchConfig.output,
      ],
    },
    rules: {
      $set: widget.switchConfig.rules.map((rule, idx) => ({
        ...rule,
        ID: idx,
        outputValue: cells[rule.outputValueID],
        conditions: rule.conditions.map((cond, idx) => ({
          ...cond,
          ID: idx,
          testValue: cells[cond.testValueID],
        })),
      })),
    },
  });
  return {
    ...widget,
    config,
  };
}
