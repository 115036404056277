import React, { useRef } from 'react';
import { mergeRefs } from '<src>/utils/react';

import { formatCell } from '<sections>/functions/Cell/util';
import { CellContent, StyledCell } from '../styles';
import useSelectable from '../useSelectable';

const TestResultCell = ({
  schemaEl,
  cellIndex,
  logItemIndex,
  currentLog,
  schema,
  stepLogs,
  cellType,
  value,
  isOutput,
  isInput,
}) => {
  // SELECTION LOGIC ---------------------------------------

  let cellID;
  const sourceLogKey =
    schemaEl && schemaEl.source.includes('#') && schemaEl.source === '#input'
      ? ''
      : schemaEl.source;
  const sourceStepLog = stepLogs.find((sL) => sL.key === sourceLogKey);

  if (sourceStepLog && sourceStepLog.key !== currentLog.key)
    cellID = `${sourceStepLog.key}_${logItemIndex}_${schemaEl.key}_${cellIndex}`;

  const sourceCellID = cellID;

  const [selectionRef] = useSelectable({
    type: useSelectable.CELL,
    ID: `${currentLog.key}_${logItemIndex}_${schemaEl.key}_${cellIndex}`,
    sourceCellID,
  });
  const cellEl = useRef(null);

  // END oF SELECTIOn LOGIC --------------------------------
  const handleOnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const sourceCell = document.getElementById(sourceCellID);

    if (sourceCell) sourceCell.classList.add('highlightSource');
  };

  const handleOnBlur = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const sourceCell = document.getElementById(sourceCellID);

    if (sourceCell) sourceCell.classList.remove('highlightSource');
  };

  return (
    <StyledCell
      tabIndex={0}
      id={`${currentLog.key}_${logItemIndex}_${schemaEl.key}_${cellIndex}`}
      data-id={`${currentLog.key}_${logItemIndex}_${schemaEl.key}_${cellIndex}`}
      data-type="CELL"
      ref={mergeRefs([cellEl, selectionRef])}
      onClick={handleOnClick}
      onBlur={handleOnBlur}
      isOutput={isOutput}
      isInput={isInput}
    >
      <CellContent alignRight={(cellType === 'Number').toString()}>
        <span>
          {formatCell(
            {
              dataType: cellType,
              value,
            },
            { ms: true }
          )}
        </span>
      </CellContent>
    </StyledCell>
  );
};

export default TestResultCell;
