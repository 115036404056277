import React from 'react';
import { Message } from 'semantic-ui-react';

import { PageContent } from '<components>/NumbrzPageComponents';

import DataList from './DataList';

export default function ProjectDeploySettings({ jobs, onSetDeployKeys }) {
  if (jobs.length === 0) {
    return <Message info>This model has no jobs defined</Message>;
  }

  return (
    <PageContent>
      <DataList jobs={jobs} onSetDeployKeys={onSetDeployKeys} />
    </PageContent>
  );
}
