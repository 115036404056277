import React, { createRef, useEffect, useState } from 'react';
import { RightPane } from './styles';
import { subscribe, unsubscribe } from '<components>/events';

export default function RightPanel({
  children,
  parentRef,
  rightWidth,
  setRightWidth,
  rightPanelOpen,
  rightPanelExpanded,
}) {
  const [oldRightPanelWidth, setOldRightPanelWidth] = useState();
  const rightRef = createRef();

  useEffect(() => {
    subscribe('enterFullScreenTestMode', () => {
      if (rightRef && rightRef.current) {
        setOldRightPanelWidth(
          parseInt(rightRef.current.style.width.replace('px', ''), 10)
        );
      }
    });
    subscribe('exitFullScreenTestMode', () => {
      if (rightRef && rightRef.current) setRightWidth(oldRightPanelWidth);
    });

    return () => {
      unsubscribe('enterFullScreenTestMode');
      unsubscribe('exitFullScreenTestMode');
    };
  }, [rightWidth, rightRef, setRightWidth, oldRightPanelWidth]);

  return rightPanelOpen ? (
    <RightPane
      style={{ width: rightPanelExpanded ? '100%' : rightWidth }}
      ref={rightRef}
    >
      {children}
    </RightPane>
  ) : null;
}
