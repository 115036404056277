import React from 'react';
import BrandLogo from '../BrandLogo';
import { StyledBlankMasthead } from '../styledcomponents';

function BlankMasthead(props) {
  return (
    <StyledBlankMasthead>
      <a href="/" title="Numbrz Home">
        <BrandLogo />
      </a>
    </StyledBlankMasthead>
  );
}

export default BlankMasthead;
