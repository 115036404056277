import React, { useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import ReactMarkdown from 'react-markdown';
import { Message } from 'semantic-ui-react';

import Cell, { StyledHeaderCell } from '../Cell';
import LinkLines from '../LinkLines';
import Table from '../Table';
import Widget from '../Widget/Widget';
import { Selection } from '../contexts';

const Style = styled('div')`
  margin: 3px 0;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
`;

const WidgetArea = styled('div')`
  width: fit-content;
  margin: auto;
`;

const LinkArea = styled('div')`
  width: fit-content;
  margin-bottom: 12px;
`;

function LinkSource({ from }) {
  if (!from) return null;
  return (
    <LinkArea>
      <Table>
        <Table.Body>
          <Table.Row>
            <StyledHeaderCell>
              <div>{from.label || 'Link Source'}</div>
            </StyledHeaderCell>
            <Cell isSource cell={from} />
          </Table.Row>
        </Table.Body>
      </Table>
    </LinkArea>
  );
}

export default function FunctionDetail({
  cells = [],
  from,
  func,
  widget,
  parent,
  validationError,
}) {
  const { selection } = useContext(Selection);
  const fromUnique = useMemo(
    () =>
      from
        ? {
            ...from,
            ID: `${from.ID}-cw`,
          }
        : null,
    [from]
  ); // uniquify the source link ID for rendering

  const links = useMemo(() => {
    const links = cells
      .filter((cell) => !!cell.link)
      .map((cell) => ({
        ID: cell.ID,
        source: fromUnique,
        target: { ...cell, type: 'CELL' },
      }));
    if (fromUnique && selection.ID === fromUnique.ID) {
      return links;
    } else if (links[0] && selection.ID === links[0].ID) {
      return links;
    }
    return [];
  }, [fromUnique, cells, selection]);

  if (!widget) {
    return (
      <Style>
        <WidgetArea>
          <h3>Select a widget type on the left</h3>
        </WidgetArea>
      </Style>
    );
  }
  return (
    <LinkLines links={links}>
      <Style>
        <ReactMarkdown>{func.description}</ReactMarkdown>
        {validationError && (
          <Message warning>{validationError.message}</Message>
        )}
        <LinkSource from={fromUnique} />
        <WidgetArea>
          <Widget
            widget={widget}
            func={func}
            parent={parent}
            forceExpanded
            forceActive
            hideType
          />
        </WidgetArea>
      </Style>
    </LinkLines>
  );
}
