import { createContext } from 'react';

export const Cells = createContext({});
export const Expanded = createContext({ expanded: [], setExpanded: () => {} });
export const FunctionDispatch = createContext(() => {});
export const Functions = createContext({});
export const GlobalDispatch = createContext(() => {});
export const Links = createContext({});

export const Selection = createContext({
  selection: { type: 'NONE' },
  activeItems: {},
  select: () => {},
});
Selection.NONE = 'NONE';
Selection.CELL = 'CELL';
Selection.CELL_TEMPLATE = 'CELL_TEMPLATE';
Selection.PARAMS = 'PARAMS';
Selection.WIDGET = 'WIDGET';

export const WidgetAPI = createContext({});
