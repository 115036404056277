import React, { useState, useMemo } from 'react';
import fp from 'lodash/fp';
import {
  FlexRowContainer,
  FlexColumnContainer,
} from '<components>/NumbrzPageComponents';
import { formatDataSourceLabel } from '<sections>/data/utils';
import {
  StyledBody,
  TextIconBtn,
  JTMappingContainer,
  MappingHdr,
  JobTableLink,
  MappingSubhdr,
  CardHeader,
  DatasetContainer,
  EmptyMsg,
  CardContainer,
  TablesetCounter,
} from '../../styles';
import { Icon } from 'semantic-ui-react';
import FlowTableMappings from './FlowTableMappings';
import { getTableUsage, sortMappingsByDataSource } from '../../utils';

import { Title, Card } from '<src>/sections/flows/Step/styles';

import EastRoundedIcon from '@mui/icons-material/EastRounded';
import WestRoundedIcon from '@mui/icons-material/WestRounded';

export default function TablesetMappings({
  jobID,
  flowID,
  projectID,
  tablesetIdx,
  numOfTablesets,
  tablesetName,
  mappings = [],
  jobTables = [],
}) {
  const [expanded, setExpanded] = useState(true);

  const tableSrc = fp.getOr(
    null,
    'dataset.tableset.source.__typename',
    mappings[0]
  );

  const inputs = useMemo(() => {
    return mappings
      .filter((m) => {
        const jobTable = jobTables.find((jT) => jT.ID === m.tableID);
        if (jobTable) {
          const usageTypes = getTableUsage(jobTable);
          const isInput = usageTypes.inputs > 0;
          return isInput;
        }
        return false;
      })
      .sort(sortMappingsByDataSource);
  }, [jobTables, mappings]);

  const outputs = useMemo(() => {
    return mappings
      .filter((m) => {
        const jobTable = jobTables.find((jT) => jT.ID === m.tableID);
        if (jobTable) {
          const usageTypes = getTableUsage(jobTable);
          const isOutput = usageTypes.outputs > 0;
          return isOutput;
        }
        return false;
      })
      .sort(sortMappingsByDataSource);
  }, [jobTables, mappings]);

  return (
    <JTMappingContainer last={tablesetIdx === numOfTablesets - 1}>
      <DatasetContainer collapsed={!expanded}>
        <FlexRowContainer alignItems="center" justifyContent="flex-start">
          <TablesetCounter>{tablesetIdx + 1}</TablesetCounter>
          <FlexColumnContainer alignItems="flex-start">
            <MappingHdr>
              <JobTableLink
                to={`/data/${fp.getOr(
                  undefined,
                  'dataset.tableset.ID',
                  mappings[0]
                )}/tables`}
              >
                {tablesetName}
              </JobTableLink>
            </MappingHdr>
            <MappingSubhdr>{formatDataSourceLabel(tableSrc)}</MappingSubhdr>
          </FlexColumnContainer>
        </FlexRowContainer>

        <TextIconBtn
          margin="0 1px 0 0"
          onClick={() => setExpanded((e) => !e)}
          expanded={expanded}
        >
          Details
          <Icon name={expanded ? 'angle up' : 'angle down'} />
        </TextIconBtn>
      </DatasetContainer>

      {expanded && (
        <FlexRowContainer
          alignItems="flex-start"
          justifyContent="space-around"
          padding="5px 0 10px 0"
          flexWrap
        >
          <CardContainer>
            <Card isInput margin="0">
              <CardHeader isInput>
                <Title>
                  <h5>Inputs</h5>
                </Title>
              </CardHeader>
              <StyledBody>
                {inputs.length === 0 && <EmptyMsg>No inputs</EmptyMsg>}
                {inputs.map((mapping, idx) => {
                  const jobTable = jobTables.find(
                    (jT) => jT.ID === mapping.tableID
                  );

                  return (
                    <FlowTableMappings
                      key={idx}
                      index={idx}
                      mapping={mapping}
                      jobTable={jobTable}
                      jobID={jobID}
                      flowID={flowID}
                      projectID={projectID}
                      isLast={idx === inputs.length - 1}
                      separatorIcon={<EastRoundedIcon />}
                    />
                  );
                })}
              </StyledBody>
            </Card>
          </CardContainer>
          <CardContainer>
            <Card isOutput margin="0">
              <CardHeader isOutput>
                <Title>
                  <h5>Outputs</h5>
                </Title>
              </CardHeader>
              <StyledBody>
                {outputs.length === 0 && <EmptyMsg>No outputs</EmptyMsg>}
                {outputs.map((mapping, idx) => {
                  const jobTable = jobTables.find(
                    (jT) => jT.ID === mapping.tableID
                  );

                  return (
                    <FlowTableMappings
                      key={idx}
                      index={idx}
                      mapping={mapping}
                      jobTable={jobTable}
                      flowID={flowID}
                      jobID={jobID}
                      projectID={projectID}
                      isLast={idx === outputs.length - 1}
                      separatorIcon={<WestRoundedIcon />}
                    />
                  );
                })}
              </StyledBody>
            </Card>
          </CardContainer>
        </FlexRowContainer>
      )}
    </JTMappingContainer>
  );
}
