import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Icon } from 'semantic-ui-react';
import keyBy from 'lodash/keyBy';

import { automap as baseAutomap } from '<src>/utils/data-types';
import shortid from '<src>/utils/shortid';

import * as colors from '<components>/colors';
import Button from '<src>/components/Button';

import { ConfigTable } from '../../styles';
import ElementLabel from '../../ElementLabel';
import FieldPicker from '../../FieldPicker';

const Style = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  & table {
    width: fit-content;
  }
  div.buttons {
    margin: 8px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    button {
      text-transform: none;
    }
    & > * {
      margin: 0 4px;
    }
  }
`;

export function automap(elements, mappings, columns) {
  return baseAutomap({
    elements,
    mappings,
    availableTargets: columns,
    mappingSource: (m) => m.fromElementKey,
    mappingTarget: (m) => m.toFieldKey,
    setTarget: (m, tgt) => ({ ...m, toFieldKey: tgt }),
  });
}

function useMappingsAPI(onChange, onChangeTable, elements, mappings, table) {
  return useMemo(() => {
    if (!onChange) {
      return {};
    }
    const onAddOutputFields = (e, { selected }) =>
      onChange(e, {
        mappings: automap(
          elements,
          [...mappings, ...selected.map((s) => ({ fromElementKey: s }))],
          table.schema.elements
        ),
      });
    const onChangeMapping = (e, { fromElementKey, toFieldKey }) =>
      onChange(e, {
        mappings: mappings.map((m) => {
          if (m.fromElementKey === fromElementKey) {
            return { fromElementKey, toFieldKey };
          }
          return m;
        }),
      });
    const onDeleteMapping = (e, { fromElementKey }) =>
      onChange(e, {
        mappings: mappings.filter((m) => m.fromElementKey !== fromElementKey),
      });
    const onAddTableColumns = async (e, { elements }) => {
      const newColumns = [];
      const elMappings = [];
      const colNameCounts = table.schema.elements.reduce(
        (acc, el) => ({ ...acc, [el.label]: 0 }),
        {}
      );

      elements.forEach((e) => {
        if (!e) {
          return;
        }
        const key = shortid();
        let label = e.label;
        if (label in colNameCounts) {
          while (label in colNameCounts) {
            colNameCounts[e.label]++;
            label = `${e.label} (${colNameCounts[e.label]})`;
          }
        } else {
          colNameCounts[e.label] = 0;
        }

        newColumns.push({
          key,
          label,
          type: e.type,
        });
        elMappings.push({ fromElementKey: e.key, toFieldKey: key });
      });

      await onChangeTable(e, {
        ID: table.ID,
        schema: {
          ...table.schema,
          elements: [...table.schema.elements, ...newColumns],
        },
      });

      const newMappings = mappings.map((m) => {
        const em = elMappings.find(
          (e) => e.fromElementKey === m.fromElementKey
        );
        if (em) return em;
        return m;
      });

      onChange(e, { mappings: newMappings });
    };

    return {
      onAddOutputFields,
      onAddTableColumns,
      onChangeMapping,
      onDeleteMapping,
    };
  }, [elements, mappings, onChange, onChangeTable, table.ID, table.schema]);
}

function SelectedColumn({
  elements,
  fromElement,
  toFieldKey,
  onChangeMapping,
  onAddTableColumns,
}) {
  if (!fromElement || !onChangeMapping) {
    if (!toFieldKey) {
      return <em>not set</em>;
    }
    const el = elements.find((e) => e.key === toFieldKey);
    if (el) {
      return <span>{el.label}</span>;
    }
    return <em>column not found</em>;
  }
  return (
    <FieldPicker.Dropdown
      elements={elements}
      flat
      selected={toFieldKey}
      showTypeFilter
      initialTypeFilter={fromElement.type}
      onChange={(e, { selected: toFieldKey }) =>
        onChangeMapping(e, { fromElementKey: fromElement.key, toFieldKey })
      }
      addFieldLabel="Add Column"
      onAddField={(e) =>
        onAddTableColumns(e, {
          elements: [fromElement],
        })
      }
    />
  );
}

export default function FieldMappings({
  elements,
  mappings,
  table,
  onChange,
  onChangeTable,
}) {
  const [addVisible, setAddVisible] = useState(false);

  const elementsByKey = useMemo(
    () => keyBy(elements, (e) => e.key),
    [elements]
  );

  const unMappedOutputs = useMemo(
    () =>
      mappings
        .filter(
          (m) =>
            !m.toFieldKey ||
            !table.schema.elements.find((e) => e.key === m.toFieldKey)
        )
        .map((m) => elementsByKey[m.fromElementKey]),
    [elementsByKey, mappings, table.schema.elements]
  );

  const availableElements = useMemo(
    () =>
      elements.filter((e) => !mappings.find((m) => m.fromElementKey === e.key)),
    [elements, mappings]
  );

  const {
    onAddOutputFields,
    onAddTableColumns,
    onChangeMapping,
    onDeleteMapping,
  } = useMappingsAPI(onChange, onChangeTable, elements, mappings, table);

  return (
    <Style>
      {onChange ? (
        <div className="buttons">
          <Button action="add" onClick={() => setAddVisible(true)}>
            Add output fields
          </Button>
          <Button
            action="add"
            disabled={unMappedOutputs.length === 0}
            onClick={(e) => onAddTableColumns(e, { elements: unMappedOutputs })}
          >
            Add missing columns
          </Button>
        </div>
      ) : null}
      {mappings.length > 0 ? (
        <ConfigTable>
          <thead>
            <tr>
              <th style={{ minWidth: '160px' }}>Stage Field</th>
              <th style={{ minWidth: '160px' }}>Table Column</th>
              {onChange ? <th className="btn" /> : null}
            </tr>
          </thead>
          <tbody>
            {mappings.map(({ fromElementKey, toFieldKey }) => {
              const fromElement = elements.find(
                (e) => e.key === fromElementKey
              );
              return (
                <tr
                  key={fromElementKey}
                  className={!fromElement ? 'error' : undefined}
                >
                  <td>
                    <ElementLabel
                      elementKey={fromElementKey}
                      element={fromElement}
                    />
                  </td>
                  <td>
                    <SelectedColumn
                      elements={table.schema.elements}
                      fromElement={fromElement}
                      toFieldKey={toFieldKey}
                      onChangeMapping={onChangeMapping}
                      onAddTableColumns={onAddTableColumns}
                    />
                  </td>
                  {onChange ? (
                    <td className="btn">
                      <Button.IconBtn
                        icon={<Icon name="trash alternate outline" />}
                        baseColor="transparent"
                        contentColor={colors.gray2}
                        activeColor="transparent"
                        contrastColor="transparent"
                        bgHoverColor={colors.gray4}
                        onClick={(e) => onDeleteMapping(e, { fromElementKey })}
                      />
                    </td>
                  ) : null}
                </tr>
              );
            })}
          </tbody>
        </ConfigTable>
      ) : null}
      <FieldPicker.Dialog
        header="Add fields to stage output"
        visible={addVisible}
        onRequestClose={() => setAddVisible(false)}
        elements={availableElements}
        showTypeFilter
        selectLabel="Add fields"
        onSelect={(e, { selected }) => {
          onAddOutputFields(e, { selected });
          setAddVisible(false);
        }}
        initialTypeFilter={undefined}
      />
    </Style>
  );
}
