import { useMemo } from 'react';

export default function useColumnOps(schema, onChange) {
  return useMemo(() => {
    if (!onChange) {
      return {};
    }
    const onAddColumn = (e, col) =>
      onChange(e, {
        schema: { ...schema, elements: [...schema.elements, col] },
      });
    const onRemoveColumn = (e, { key }) =>
      onChange(e, {
        schema: {
          ...schema,
          elements: schema.elements.filter((e) => e.key !== key),
        },
      });
    const onChangeColumn = (e, { key, ...changes }) => {
      const idx = schema.elements.findIndex((elt) => elt.key === key);
      if (idx < 0) return;

      const newElements = [...schema.elements];
      newElements[idx] = { ...newElements[idx], ...changes };
      onChange(e, { schema: { ...schema, elements: newElements } });
    };

    return { onAddColumn, onRemoveColumn, onChangeColumn };
  }, [schema, onChange]);
}
