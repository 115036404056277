import React from 'react';

import styled from '@emotion/styled';
import * as colors from '<components>/colors';

import TextInput from '<components>/TextInput';

const FormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const CardContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 10px 0;
`;

const StyledTextInput = styled(TextInput)`
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${colors.gray3};
`;

const CardPaymentForm = (props) => (
  <FormContainer>
    <StyledTextInput
      name="name"
      value={props.name}
      onChange={props.onChange}
      placeholder="Cardholder Name"
    />
    <CardContainer>
      <StyledTextInput
        name="cardNumber"
        placeholder="Card Number"
        onChange={props.onChange}
        value={props.cardNumber}
      />
      <div>
        <StyledTextInput
          name="expDate"
          placeholder="MM/YY"
          onChange={props.onChange}
          value={props.expDate}
        />
        <StyledTextInput
          name="cvc"
          placeholder="CVC"
          onChange={props.onChange}
          value={props.cvc}
        />
      </div>
    </CardContainer>
  </FormContainer>
);
export default CardPaymentForm;
