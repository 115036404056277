import React, { useState, useCallback } from 'react';
import Dialog from '<components>/Dialog';
import Button from '<components>/Button';
import JobsBlockPane from '../JobsBlockPane';
import styled from '@emotion/styled';

const StyledHeadline = styled(Dialog.Headline)`
  padding-left: 10px;
`;

const StyledDialog = styled(Dialog)`
  width: 75%;
  max-width: 800px;
  min-width: 400px;
`;

export default function AddJobDialog({
  onClose,
  jobGroupID,
  block,
  visible,
  updateBlock,
}) {
  const [blockJobs, setBlockJobs] = useState(block.jobs.map((j) => j.ID));

  const handleUpdateBlock = useCallback(async () => {
    const payload = {
      jobGroupID,
      blockKey: block.key,
      block: {
        jobs: blockJobs,
      },
    };
    await updateBlock(null, payload);
    onClose();
  }, [updateBlock, onClose, block.key, blockJobs, jobGroupID]);

  return (
    <StyledDialog onRequestClose={onClose} visible={visible}>
      <Dialog.Header>
        <StyledHeadline>Add a job</StyledHeadline>
      </Dialog.Header>
      <Dialog.Body>
        <JobsBlockPane
          blockJobs={blockJobs}
          onChange={(selectedJobs) => setBlockJobs(selectedJobs)}
        />
      </Dialog.Body>
      <Dialog.Footer>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleUpdateBlock}>Add</Button>
      </Dialog.Footer>
    </StyledDialog>
  );
}
