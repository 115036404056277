import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import JobGroupList from './JobGroupListPage';
import JobGroupEditor from './JobGroupPage';
import JobGroupStatus from './JobGroupStatus';

export default function JobGroupsPage() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/:jobGroupID`}>
        <JobGroupEditor baseURL={match.url} />
      </Route>
      <Route path={`${match.url}/:jobGroupID/configuration`}>
        <JobGroupEditor baseURL={match.url} />
      </Route>
      <Route path={`${match.url}/:jobGroupID/status/:statusID`}>
        <JobGroupStatus baseURL={match.url} />
      </Route>
      <Route>
        <JobGroupList baseURL={match.url} />
      </Route>
    </Switch>
  );
}
