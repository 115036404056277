import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import * as colors from '<components>/colors';

import LinkLine from './LinkLine';

const Wrapper = styled('div')`
  position: relative;
`;

const Overlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  pointer-events: none;

  & svg {
    height: 100%;
    width: 100%;
    fill: none;

    & path {
      stroke: ${colors.blue2};
      stroke-width: 2;
    }
    & .error path {
      stroke: ${colors.red1};
    }

    & polygon {
      fill: ${colors.blue2};
    }
    & .error polygon {
      fill: ${colors.red1};
    }

    & circle {
      fill: ${colors.blue2};
    }
    & .error circle {
      fill: ${colors.red1};
    }
  }
`;

function getItemPosition(item) {
  const el = document.querySelector(
    `[data-type="${item.type}"][data-id="${item.ID}"]`
  );
  if (!el) return null;
  return el.getBoundingClientRect();
}

function updateLinkPositions(parent, links, set, yAdjustment = 0) {
  if (!parent) return;
  if (!links) return;

  const linkPositions = [];

  const parentPos = parent.getBoundingClientRect();
  links.forEach((link) => {
    const sourcePos = getItemPosition(link.source);
    const targetPos = getItemPosition(link.target);

    if (!sourcePos || !targetPos) return;

    linkPositions.push({
      ...link,
      sourcePos: {
        x: sourcePos.x - parentPos.x,
        y: sourcePos.y - parentPos.y + yAdjustment,
        width: sourcePos.width,
        height: sourcePos.height,
      },
      targetPos: {
        x: targetPos.x - parentPos.x,
        y: targetPos.y - parentPos.y + yAdjustment,
        width: targetPos.width,
        height: targetPos.height,
      },
    });
  });

  set(linkPositions);
}

export default function LinkLines({
  children,
  links,
  slackX,
  slackY,
  yAdjustment,
}) {
  const ref = useRef();
  const linksRef = useRef(links);

  const [linkPositions, setLinkPositions] = useState([]);

  useEffect(() => {
    linksRef.current = links;
    updateLinkPositions(ref.current, links, setLinkPositions, yAdjustment);
  }, [links, yAdjustment]);

  useEffect(() => {
    let afHandle;
    let transCount = 0;

    const doUpdate = () =>
      requestAnimationFrame(() =>
        updateLinkPositions(
          ref.current,
          linksRef.current,
          setLinkPositions,
          yAdjustment
        )
      );

    const startLoop = () => {
      transCount++;
      const loop = () => {
        updateLinkPositions(
          ref.current,
          linksRef.current,
          setLinkPositions,
          yAdjustment
        );
        if (transCount > 0) {
          afHandle = requestAnimationFrame(loop);
        }
      };
      if (transCount === 1) {
        afHandle = requestAnimationFrame(loop);
      }
    };

    const endLoop = () => {
      transCount--;
    };

    window.addEventListener('scroll', doUpdate, true);
    window.addEventListener('transitionstart', startLoop, true);
    window.addEventListener('transitionend', endLoop, true);
    window.addEventListener('transitioncancel', endLoop, true);

    return () => {
      window.removeEventListener('scroll', doUpdate);
      window.removeEventListener('transitionstart', startLoop);
      window.removeEventListener('transitionend', endLoop);
      window.removeEventListener('transitioncancel', endLoop);
      cancelAnimationFrame(afHandle);
    };
  }, [yAdjustment]);

  return (
    <Wrapper>
      <div ref={ref}>{children}</div>
      <Overlay>
        <svg>
          <defs>
            <marker
              id="arrow"
              markerWidth="10"
              markerHeight="10"
              refX="3"
              refY="2"
              orient="auto-start-reverse"
            >
              <polygon points="0 0, 3 2, 0 4" />
            </marker>
            <marker
              id="arrow-error"
              className="error"
              markerWidth="10"
              markerHeight="10"
              refX="3"
              refY="2"
              orient="auto-start-reverse"
            >
              <polygon points="0 0, 3 2, 0 4" />
            </marker>
            <marker
              id="dot"
              viewBox="0 0 10 10"
              refX="4"
              refY="4"
              markerWidth="4"
              markerHeight="4"
            >
              <circle cx="4" cy="4" r="4" />
            </marker>
            <marker
              id="dot-error"
              className="error"
              viewBox="0 0 10 10"
              refX="4"
              refY="4"
              markerWidth="4"
              markerHeight="4"
            >
              <circle cx="4" cy="4" r="4" />
            </marker>
          </defs>
          {linkPositions.map(({ ID, sourcePos, targetPos, status }) => (
            <LinkLine
              key={ID}
              sourcePos={sourcePos}
              targetPos={targetPos}
              slackX={slackX}
              slackY={slackY}
              isError={status === 'InvalidUpstream'}
            />
          ))}
        </svg>
      </Overlay>
    </Wrapper>
  );
}
