import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useMutation, useQuery } from '<src>/apollo/client';

import Dialog from '<components>/Dialog';
import Button from '<src>/components/Button';

import { DeployLibraryContent, GetProjects } from '<sections>/projects/queries';

import WaveSpinner from '<components>/WaveSpinner';
import FunctionListItem from './FunctionListItem';
import { TargetPicker } from '<sections>/projects/pages/ProjectPage/components/CloneFunctionDialog';
import { FlexColumnContainer } from '<components>/NumbrzPageComponents';

export default function LibraryDialog({
  visible,
  onClose,
  catalogProjectID,
  libraryName = 'Library',
  libFunctions,
  activeFxID,
  loading,
}) {
  const [deployContent] = useMutation(DeployLibraryContent);
  const [deploying, setDeploying] = useState(false);
  const [targetModelID, setTargetModelID] = useState('add_new');

  const { data: { projects = [] } = {}, loading: loadingProjects } =
    useQuery(GetProjects);

  // functions can only be deployed to project of type Standard
  const standardProjects = useMemo(
    () => [
      {
        name: libraryName,
        ID: 'add_new',
      },
      ...projects.filter(
        (p) => p.type === 'Standard' && p.ID !== catalogProjectID
      ),
    ],
    [projects, catalogProjectID, libraryName]
  );

  useEffect(() => {
    if (targetModelID === 'add_new' && standardProjects.length > 0) {
      const existingFunxLib = standardProjects.find(
        (p) => p.name === libraryName && p.ID !== 'add_new'
      );

      if (existingFunxLib) setTargetModelID(existingFunxLib.ID);
    }
  }, [targetModelID, standardProjects, libraryName]);

  const activeFx = useMemo(
    () => libFunctions.find((f) => f.ID === activeFxID),
    [activeFxID, libFunctions]
  );

  const handleOnClose = useCallback(() => {
    setTargetModelID('add_new');
    onClose();
  }, [onClose]);

  const deployFunctions = useCallback(
    async (fx, deployAll = false) => {
      let payload = {
        projectID: catalogProjectID,
        targetID: targetModelID === 'add_new' ? null : targetModelID,
        ...(targetModelID === 'add_new' && { addTargetModel: true }),
        ...(targetModelID === 'add_new' && { targetModelName: libraryName }),
      };

      setDeploying(true);
      if (deployAll) {
        payload.functions = libFunctions.map((fx) => fx.ID);
        await deployContent({ variables: { input: payload } });
      } else if (fx) {
        payload.functions = [fx.ID];
        await deployContent({ variables: { input: payload } });
      }
      setDeploying(false);
      handleOnClose();
    },
    [
      catalogProjectID,
      deployContent,
      libFunctions,
      targetModelID,
      libraryName,
      handleOnClose,
    ]
  );

  return (
    <Dialog
      visible={visible}
      onRequestClose={handleOnClose}
      style={{ maxWidth: 'initial', minWidth: '50%' }}
    >
      <Dialog.Header>
        <Dialog.Headline>
          {activeFx ? activeFx.name : libraryName}
        </Dialog.Headline>
        <Button.DialogClose onClick={handleOnClose} />
      </Dialog.Header>
      <Dialog.Body>
        {loading || loadingProjects ? (
          <WaveSpinner />
        ) : libFunctions.length > 0 ? (
          <FlexColumnContainer width="100%">
            <TargetPicker
              projects={standardProjects}
              targetProjectID={targetModelID}
              setTargetProjectID={setTargetModelID}
            />
            {activeFx && <FunctionListItem fx={activeFx} />}
          </FlexColumnContainer>
        ) : (
          'No items found'
        )}
      </Dialog.Body>
      <Dialog.Footer>
        {activeFx ? (
          <Button.Run
            onClick={() => deployFunctions(activeFx)}
            disabled={deploying}
            title={'Import'}
          >
            {deploying ? 'Importing...' : 'Import'}
          </Button.Run>
        ) : (
          <Button.Run
            onClick={() => deployFunctions(null, true)}
            disabled={deploying}
          >
            {deploying ? 'Importing All...' : 'Import All'}
          </Button.Run>
        )}
      </Dialog.Footer>
    </Dialog>
  );
}
