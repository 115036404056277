import React from 'react';

import TablesPane from '../TablesPane';

export default function ConnectedTables({
  tablesetID,
  history,
  match,
  location,
  tablesetSource,
  tablesetState,
  setConnectDialogVisible,
}) {
  const { tables, loadingTables, tableAPI } = tablesetState;

  return (
    <TablesPane
      tableAPI={tableAPI}
      selectedNavItem="tables"
      tablesetSource={tablesetSource}
      tablesetState={tablesetState}
      tablesetID={tablesetID}
      loading={loadingTables}
      tables={tables}
      history={history}
      match={match}
      location={location}
      setConnectDialogVisible={setConnectDialogVisible}
    />
  );
}
