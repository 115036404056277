import React, { forwardRef, useMemo } from 'react';
import styled from '@emotion/styled';
import keyBy from 'lodash/keyBy';

import { ConfigTable } from '../../styles';
import Button from '<src>/components/Button';
import ElementLabel from '../../ElementLabel';
import FieldPicker from '../../FieldPicker';
import { stepsByType } from '../stepData';
import Step from '../Step';

import AddRow from './AddRow';
import FieldHeader from './FieldHeader';
import { useFieldAPI, useRowAPI } from './hooks';

const Style = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  & table {
    width: fit-content;
  }
  div.buttons {
    margin: 8px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    button {
      text-transform: none;
    }
    & > * {
      margin: 0 4px;
    }
  }
`;

function restrictTypes(toElements, colIdx) {
  return toElements[colIdx].type && toElements[colIdx].type !== 'Unspecified'
    ? [toElements[colIdx].type]
    : undefined;
}

function SliceStep(
  { step, api, stageElements: elements = [], ...props },
  parentRef
) {
  const { onChangeSlice } = api;
  const { toElements, fromKeys } = step;

  const onChange = useMemo(
    () =>
      onChangeSlice
        ? (e, changes) => onChangeSlice(e, { key: step.key, ...changes })
        : null,
    [onChangeSlice, step.key]
  );

  const elementsByKey = useMemo(
    () => keyBy(elements, (e) => e.key),
    [elements]
  );

  const [onAddField, onChangeField, onDeleteField] = useFieldAPI(
    fromKeys,
    toElements,
    onChange
  );

  const [onAddRow, onDeleteRow, onSetCellKey] = useRowAPI(
    elements,
    fromKeys,
    toElements,
    onChange
  );

  return (
    <Step ref={parentRef} step={step} api={api} {...props}>
      <div />
      <Style>
        <ConfigTable>
          <thead>
            <tr>
              {toElements.map((e) => (
                <FieldHeader
                  key={e.key}
                  element={e}
                  onlyField={toElements.length === 1}
                  onChangeField={onChangeField}
                  onDeleteField={onDeleteField}
                />
              ))}
              {onChange ? (
                <th className="btn">
                  <Button.TableAddBtn
                    title="Add Output Field"
                    onClick={onAddField}
                  />
                </th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {fromKeys.map((row, rowIdx) => (
              <tr key={rowIdx}>
                {row.map((key, colIdx) => (
                  <td key={colIdx}>
                    <ElementLabel.Source element={elementsByKey[key]} />
                    {onChange ? (
                      <FieldPicker.Dropdown
                        disabled={!onChange}
                        selected={key}
                        elements={elements}
                        restrictTypes={restrictTypes(toElements, colIdx)}
                        onChange={(e, { selected }) =>
                          onSetCellKey(e, {
                            rowIdx,
                            colIdx,
                            key: selected || '',
                          })
                        }
                      />
                    ) : (
                      <ElementLabel.Text
                        elementKey={key}
                        element={elementsByKey[key]}
                      />
                    )}
                  </td>
                ))}
                {onChange ? (
                  <td className="btn">
                    <Button.TableDeleteBtn
                      title="Delete Output Record"
                      onClick={(e) => onDeleteRow(e, { rowIdx })}
                    />
                  </td>
                ) : null}
              </tr>
            ))}
            {onChange ? (
              <AddRow
                elements={elements}
                toElements={toElements}
                onAddRow={onAddRow}
              />
            ) : null}
          </tbody>
        </ConfigTable>
      </Style>
    </Step>
  );
}

// eslint-disable-next-line no-func-assign
SliceStep = forwardRef(SliceStep);
SliceStep.displayName = 'SliceStep';
export default SliceStep;

// Avoid circular dependency
stepsByType.SliceStepV2.Component = SliceStep;
