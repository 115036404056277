import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import * as Sentry from '@sentry/react';

import { useMutation } from '<src>/apollo/client';
import { useAuth, withAuthenticationRequired } from '<src>/auth';

export const RegisteredAccount = React.createContext(null);

const RegisterAccountMutation = gql`
  mutation RegisterAccount($input: GetOrRegisterInput!) {
    registerAccount(input: $input) {
      ID
      display
      externalID
      externalIDHash
      fullName
      primaryAccount {
        ID
        display
      }
      membership {
        account {
          ID
          display
        }
        roles
      }
    }
  }
`;

function RegisterAccount({ children }) {
  const [registerAccount] = useMutation(RegisterAccountMutation);
  const [user, setUser] = useState(null);
  const { getIDToken, user: authUser } = useAuth();

  useEffect(() => {
    async function fetchUser() {
      const token = await getIDToken();
      if (!authUser) throw new Error('Not logged in!');

      const {
        data: { registerAccount: regUser },
      } = await registerAccount({
        variables: {
          input: {
            externalID: `${token.iss}${authUser.sub}`,
            emailAddress: authUser.email,
            fullName: authUser['https://numbrz.com/name'],
          },
        },
      });
      setUser(regUser);
      Sentry.setUser({
        id: regUser.ID,
        externalID: regUser.externalID,
      });
      Sentry.setTag('accountID', regUser.primaryAccount.ID);
    }

    fetchUser();
  }, [authUser, getIDToken, registerAccount]);

  return (
    <RegisteredAccount.Provider value={user}>
      {children}
    </RegisteredAccount.Provider>
  );
}

export default withAuthenticationRequired(RegisterAccount);
