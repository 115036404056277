/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx, css } from '@emotion/react';

import styled from '@emotion/styled';

import * as colors from '<components>/colors';
import BaseCard from '<components>/Card';
import FieldEditor from '<components>/FieldEditor';
import { InvertedButton } from '<components>/NumbrzButtons';

const ignoreSsrWarning =
  '/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */';

export const PageContainer = styled('div')`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;

export const ContentWrapper = styled('div')`
  display: flex;
  height: 100%;
`;

export const TestSidebar = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Card = styled(BaseCard)`
  width: 100%;
  height: fit-content;
  padding: 12px;
  margin-top: 12px;
  ${(props) => props.zIndex && 'z-index: 1'};
  box-shadow:
    rgb(60 66 87 / 12%) 0px 2px 4px 0px,
    rgb(0 0 0 / 12%) 0px 2px 3px 0px;
`;

export const ConfigurationWrapper = styled('div')`
  width: 100%;
  max-width: ${(props) => props.maxWidth && props.maxWidth};
  background: white;
  border-radius: 8px;
  border: 1px solid ${colors.gray_border};
  padding: 15px;
  margin: ${(props) => props.margin && props.margin};

  .form {
    margin: 10px 10px 0 10px;
    .field {
      margin-bottom: 10px;
    }
    .checkbox {
      font-size: 13px;
    }
    .ui.radio.checkbox input:checked ~ label:after {
      background: ${colors.gray1};
    }
  }
`;

export const ConfigTable = styled('table')`
  font-size: 12px;
  margin: ${(props) => (props.margin ? props.margin : '0 auto')};
  &.list {
    font-size: 14px;
  }
  table-layout: auto;
  empty-cells: show;
  border-spacing: 0;
  border-collapse: collapse;
  border: none;
  & :focus {
    outline: none;
  }

  tr {
    position: relative;
  }

  td,
  th {

    text-align: left;
    padding: 8px;
    border: 1px solid ${colors.gray3};
  }
  th {
    background: ${colors.gray11};
    padding: 5px 8px;
    white-space: nowrap;
  }

  td.longContent {
    max-width: 150px;
  }
  td.userName {
    width: 105px;
  }
  td.date {
    width: 170px;
  }
  td.duration {
    80px;
  }

  td.warning {
    background-color: ${colors.yellow7}
  }
  td.error {
    background-color: ${colors.red5};
    i {
      color: ${colors.red1c}
    }
  .rowCnt {
    background-color: ${colors.gray11} !important;
  }


  }

  td.input {
    i {
      font-size: 1.3em !important;
      margin-left: 10px;
      cursor: pointer;
    }
  }


  &.list td,
  &.list th {
    border-right-color: transparent;
    border-left-color: transparent;
  }
  &.list
    td:first-child${ignoreSsrWarning},
    &.list
    th:first-child${ignoreSsrWarning} {
    border-left-color: ${colors.gray3};
  }
  &.list td:last-child,
  &.list th:last-child {
    border-right-color: ${colors.gray3};
  }

  td.type,
  th.type {
    white-space: nowrap;
  }
  td.result {
    display: flex;
    align-items: center;
  }

  td.centered {
    text-align: center;
  }

  td.sep,
  th.sep {
    border-top-color: transparent;
    border-right-color: transparent !important;
    border-left-color: transparent !important;
    padding: 3px;
  }
  td.btn,
  th.btn {
    padding: 2px;
    text-align:center;
  }
  td > .ui.fluid.dropdown > .dropdown.icon {
    line-height: inherit;
  }

  .error {
    color: #9f3a38;
    background-color: #fff6f6;
  }
  tr.error td,
  tr.error th {
    background-color: #fff6f6;
  }
  td.compact {
    padding: 3px 8px;
  }
`;

export const ConfigField = styled(FieldEditor)`
  width: 100%;
  outline: none;
  border: none;
  transition: border-color 125ms ease-in-out;
  border-bottom: 1px solid transparent;
  input& {
    padding: 1px 0;
  }
  &.placeholder,
  input&::placeholder {
    font-weight: normal;
    color: rgba(191, 191, 191, 0.87);
  }
  &:hover {
    cursor: text;
    border-bottom-color: ${({ disabled }) =>
      !disabled ? colors.blue4 : undefined};
  }
  &:focus {
    border-bottom-color: ${({ disabled }) =>
      !disabled ? colors.blue2 : undefined};
  }
  &:empty:after {
    content: '\\00a0';
  }
`;

export const TitleField = styled(FieldEditor)`
  outline: none;
  transition: border-color 225ms ease-in-out;
  border-bottom: 1px solid transparent;
  ${(props) => props.maxTitleWidth && { maxWidth: props.maxTitleWidth }};
  &.placeholder {
    font-style: italic;
    font-weight: normal;
    color: ${colors.gray2};
  }
  &:focus,
  &:hover {
    border-bottom-color: ${colors.blue2};
  }
  &:empty:after {
    content: '\\00a0';
  }
`;

export const SectionHeader = styled('h4')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  color: ${colors.gray1b};
  padding-left: 10px;
  margin-bottom: 10px;
  label {
    font-size: 14px;
  }
  span.red {
    margin-right: 5px;
    color: ${colors.red1c};
  }
`;

export const HdrButton = (props) => (
  <InvertedButton
    {...props}
    css={css`
      font-size: 0.8em;
      height: fit-content;
      padding: 5px 12px;
    `}
  />
);

export const DropdownItemDesc = styled('div')`
  margin-top: 0.4rem;
  color: ${colors.gray1};
  font-size: 0.9rem;
  max-width: 300px;
  white-space: normal;
`;

export const Label = styled('span')`
  margin-right: 10px;
  color: ${colors.gray1b};
  font-weight: bold;
`;

export const InputsWrapper = styled('div')`
  display: flex;
  align-items: center;
  .dropdown:nth-child(1),
  .dropdown:nth-child(2) {
    width: unset !important;
    margin-right: 15px;
    display: flex !important;
    align-items: baseline;
  }
`;

// text icons
export const PlusTextIcon = styled('span')`
  content: '+';
  font-size: 20px;
  font-weight: 200;
  margin-top: -1px;
  margin-right: 5px;
  font-family: 'Helvetica Neue', sans-serif;
`;
