import React, { useState } from 'react';

import { Icon } from 'semantic-ui-react';
import styled from '@emotion/styled';
import * as colors from '<components>/colors';

const ButtonContainer = styled('button')`
  display: flex;
  align-items: baseline;
  padding: 3px 5px;
  background-color: transparent;
  transition:
    all 400ms ease-in-out,
    background-color 125ms ease-in;

  overflow: hidden;
  outline: none;
  font-family: Lato, Helvetica;
  font-weight: normal;

  border: 1px solid transparent;
  cursor: pointer;
  border-radius: 4px;
  color: white;
  :hover {
    background-color: ${(props) =>
      props.bgHoverColor ? props.bgHoverColor : colors.yellow4};
    i, svg {
      ${(props) => props.textHoverColor && { color: props.textHoverColor }}
    }
  }
  i, svg {
    margin-right: 0px;
    margin-left: 1px;
    font-size: 15px;
    color: ${colors.gray2};
  }
  svg {
    font-size: 20px;
  }

  &.expanded {
    display: flex;
    align-items:baseline;
    width: max-content;
    border: 1px solid ${colors.red1c} !important;
    background-color: ${colors.red1c} !important;

    color: white;
    i, svg {
      color: white !important;
    }
    outline: none;
  }

  span.visible {
    opacity: 1;
    visibility: visible;
    position: relative;
    width: auto;

    transition:
      visibility 0s,
      width: 1s linear,
      opacity 0.5s linear;
  }
`;

const ButtonText = styled('span')`
  opacity: 0;
  visibility: hidden;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  position: absolute;
  top: 0px;
`;

export default function DeleteButtonWithConfirm({
  onClick,
  label,
  bgHoverColor,
  textHoverColor,
  fontSize,
  icon,
  title,
}) {
  const [expanded = false, setExpanded] = useState();

  const handleOnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (expanded) {
      onClick(e);
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  };

  const handleOnBlur = () => {
    if (expanded) setExpanded(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') handleOnBlur();
  };

  return (
    <ButtonContainer
      title={title}
      className={expanded ? 'expanded' : ''}
      expanded={expanded}
      onClick={handleOnClick}
      onBlur={handleOnBlur}
      onKeyDown={handleKeyDown}
      bgHoverColor={bgHoverColor}
      textHoverColor={textHoverColor}
      fontSize={fontSize}
      contentColor="white"
    >
      {icon ? icon : <Icon name="trash alternate outline" />}

      <ButtonText className={expanded ? 'visible' : ''} fontSize={fontSize}>
        {label ? label : 'Delete'}
      </ButtonText>
    </ButtonContainer>
  );
}
