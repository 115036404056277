import { ApolloClient } from '@apollo/client';

import createCache from './cache';
import createLinks from './links';

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

export default function createClient({ getToken, logout }) {
  const cache = createCache();
  const link = createLinks({ cache, getToken, logout });

  return new ApolloClient({
    cache,
    defaultOptions,
    link,
    connectToDevTools: !!__DEV__,
  });
}
