import React from 'react';
import fp from 'lodash/fp';
import Masthead from '<components>/Masthead';
import { Switch, Route } from 'react-router-dom';

function makeBreadcrumbs(project, flow, additional) {
  let breadcrumbs = [];
  if (project && flow) {
    breadcrumbs.push(
      { title: 'Models', path: '/models', type: 'root' },
      { title: project.name, path: `/models/${project.ID}` }
    );

    breadcrumbs.push(
      {
        title: 'Flows',
        path: `/models/${project.ID}/flows`,
        type: 'model',
        model: project,
      },
      {
        title: flow.name,
        path: `/models/${project.ID}/flows/${flow.ID}`,
      }
    );
  }

  breadcrumbs.push(...additional);

  return breadcrumbs;
}

function getPathTitle(path) {
  switch (path) {
    case 'stages':
      return 'Stages';
    case 'job-tables':
      return 'Job tables';
    case 'working-tables':
      return 'Working tables';
    case 'variables':
      return 'Job variables';
    case 'tests':
      return 'Tests';
    case 'included':
      return 'Included flows';
    case 'documentation':
      return 'Documentation';
    default:
      return 'Stages';
  }
}

export default function FlowPageMasthead({ project, flow, matchURL, loading }) {
  if (loading)
    return (
      <Masthead
        title={undefined}
        breadcrumbs={[
          {
            title: 'Loading...',
          },
        ]}
        runBtnVisible={false}
        addShadow={false}
      />
    );
  return (
    <Switch>
      <Route path={`${matchURL}/stages/:stageID`}>
        {({ match }) => {
          const stageID = fp.getOr(undefined, 'params.stageID', match);
          const stage =
            flow.stagesV2 && flow.stagesV2.find((s) => s.ID === stageID);

          const additionalCrumbs = [
            {
              title: 'Stages',
              type: 'flow',
              flowID: flow.ID,
              modelID: project.ID,
              path: `/models/${project.ID}/flows/${flow.ID}/stages`,
            },
            { title: stage && stage.name },
          ];
          return (
            <Masthead
              title={undefined}
              breadcrumbs={makeBreadcrumbs(project, flow, additionalCrumbs)}
              runBtnVisible={false}
              addShadow={false}
            />
          );
        }}
      </Route>
      <Route
        path={[
          `${matchURL}/job-tables/:tableID`,
          `${matchURL}/working-tables/:tableID`,
        ]}
      >
        {({ match }) => {
          const tableID = fp.getOr(undefined, 'params.tableID', match);
          const table = flow.data && flow.data.find((s) => s.ID === tableID);
          const title = table
            ? table.prefSource === 'WorkingTable'
              ? 'Working tables'
              : 'Job tables'
            : 'Tables';

          const additionalCrumbs = [
            {
              title,
              type: 'flow',
              flowID: flow.ID,
              modelID: project.ID,
              path: `/models/${project.ID}/flows/${flow.ID}/${table.prefSource === 'WorkingTable' ? 'working-tables' : 'job-tables'}`,
            },
            { title: table && table.name },
          ];
          return (
            <Masthead
              title={undefined}
              breadcrumbs={makeBreadcrumbs(project, flow, additionalCrumbs)}
              runBtnVisible={false}
              addShadow={false}
            />
          );
        }}
      </Route>
      <Route path={`${matchURL}/tests/:testID`}>
        {({ match }) => {
          const testID = fp.getOr(undefined, 'params.testID', match);
          const stage = flow.tests && flow.tests.find((t) => t.key === testID);

          const additionalCrumbs = [
            {
              title: 'Tests',
              type: 'flow',
              flowID: flow.ID,
              modelID: project.ID,
              path: `/models/${project.ID}/flows/${flow.ID}/tests`,
            },
            { title: stage && stage.name },
          ];
          return (
            <Masthead
              title={undefined}
              breadcrumbs={makeBreadcrumbs(project, flow, additionalCrumbs)}
              runBtnVisible={false}
              addShadow={false}
            />
          );
        }}
      </Route>
      <Route path={`${matchURL}/included/:includedID`}>
        {({ match }) => {
          const flowID = fp.getOr(undefined, 'params.includedID', match);
          const includedFlow =
            flow.includes && flow.includes.find((i) => i.flowID === flowID);

          const additionalCrumbs = [
            {
              title: 'Included flows',
              type: 'flow',
              flowID: flow.ID,
              modelID: project.ID,
              path: `/models/${project.ID}/flows/${flow.ID}/included`,
            },
            { title: includedFlow && includedFlow.flowName },
          ];
          return (
            <Masthead
              title={undefined}
              breadcrumbs={makeBreadcrumbs(project, flow, additionalCrumbs)}
              runBtnVisible={false}
              addShadow={false}
            />
          );
        }}
      </Route>
      <Route
        path={[
          `${matchURL}/stages`,
          `${matchURL}/job-tables`,
          `${matchURL}/working-tables`,
          `${matchURL}/variables`,
          `${matchURL}/tests`,
          `${matchURL}/included`,
          `${matchURL}/documentation`,
        ]}
      >
        {({ match }) => {
          const path = match.path.split('/').slice(-1)[0];
          const title = getPathTitle(path);

          const additionalCrumbs = [
            {
              title,
              type: 'flow',
              flowID: flow.ID,
              modelID: project.ID,
              path: match.path,
            },
          ];
          return (
            <Masthead
              title={undefined}
              breadcrumbs={makeBreadcrumbs(project, flow, additionalCrumbs)}
              runBtnVisible={false}
              addShadow={false}
            />
          );
        }}
      </Route>
    </Switch>
  );
}
