import React from 'react';

import {
  SidebarWrapper,
  SidebarHeader,
  SidebarContent,
} from '<src>/components/NumbrzVerticalEditor';
import SidebarNavMenu from '<components>/NumbrzMenus/SidebarNavMenu';
import useFeatures from '<src>/components/Feature/useFeatures';

export default function Sidebar({ footer, hideMasthead }) {
  const features = useFeatures();
  const options = [
    {
      text: 'Models',
      key: 'models',
      to: `/models`,
    },
    {
      text: 'Data',
      key: 'data',
      to: `/data`,
    },
    {
      text: 'Job Groups',
      key: 'job-groups',
      to: `/job-groups`,
    },
    ...(features.ServiceModels
      ? [
          {
            text: 'Service Models',
            key: 'svc-models',
            to: `/svc-models`,
          },
        ]
      : []),
    {
      text: 'Catalog',
      key: 'catalog',
      to: `/catalog`,
    },
  ];

  return (
    <SidebarWrapper>
      {!hideMasthead && <SidebarHeader></SidebarHeader>}
      <SidebarContent style={hideMasthead && { paddingTop: '30px' }}>
        <SidebarNavMenu options={options} />
        {footer}
      </SidebarContent>
    </SidebarWrapper>
  );
}
