import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Dropdown } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';

import * as colors from '<components>/colors';
import { DropdownItemDesc } from '../../styles';
import ElementLabel from '../../ElementLabel';
import ValuePicker, { fieldPrefix } from '../../ValuePicker';

import { NumbrzTooltip, FieldSrcTooltipBtn } from '<components>/NumbrzButtons';
import { FlexRowContainer } from '<components>/NumbrzPageComponents';

const ignoreSsrWarning =
  '/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */';

const Style = styled('div')`
  & > div {
    margin-top: 8px;
  }
  & > div:first-child${ignoreSsrWarning} {
    margin-top: 0;
  }
`;

const Label = styled('div')`
  display: inline-block;
  font-weight: bold;
  color: ${colors.gray1};
  min-width: 115px;
  margin-right: 8px;
`;

const limitTypeHelp = {
  Count: {
    content: 'Generate a specified number of values',
    text: 'Count',
  },
  EndValue: {
    content: 'Stop generating values when a series reaches a specified value',
    text: 'Limit Value',
  },
};

const limitOptions = Object.entries(limitTypeHelp).map(
  ([key, { content, text }]) => ({
    key,
    value: key,
    text,
    content: (
      <div>
        <div>{text}</div>
        <DropdownItemDesc>{content}</DropdownItemDesc>
      </div>
    ),
  })
);

function LimitTypeText({ limitType }) {
  if (!limitType) {
    return <em>not set</em>;
  }

  return (
    <span>
      <NumbrzTooltip
        wide
        hideOnScroll
        on="click"
        position="top center"
        content={limitTypeHelp[limitType].content}
        trigger={<FieldSrcTooltipBtn name="info circle" />}
      />
      {limitTypeHelp[limitType].text}
    </span>
  );
}

function LimitValueText({ elements, limitValue }) {
  if (!limitValue) {
    return <em>not set</em>;
  }

  if (limitValue.startsWith(fieldPrefix)) {
    const key = limitValue.substring(fieldPrefix.length);
    const element = elements.find((el) => el.key === key);
    return <ElementLabel elementKey={key} element={element} />;
  }

  return <span>{limitValue}</span>;
}

function LimitValuePicker({ elements, onChange, limitValue, limitField }) {
  return (
    <ValuePicker
      fluid={false}
      disabled={!limitField}
      elements={elements}
      placeholder="Value"
      value={limitValue}
      restrictTypes={[limitField ? limitField.toElement.type : 'Number']}
      onChange={onChange}
    />
  );
}

function LimitElementText({ fields, limitElementKey }) {
  if (!limitElementKey) {
    return <em>not set</em>;
  }

  const field = fields.find((f) => f.toElement.key === limitElementKey);
  if (!field) {
    return <em>series not found</em>;
  }
  return <span>{field.toElement.label}</span>;
}

export default function FillLimit({
  elements,
  fields,
  limit: limitProp,
  onChange: onChangeProp,
}) {
  const [limit, setLimit] = useState(limitProp);
  const fieldOptions = useMemo(
    () =>
      fields.map((f) => ({
        key: f.toElement.key,
        value: f.toElement.key,
        text: f.toElement.label,
      })),
    [fields]
  );

  useEffect(() => {
    setLimit(limitProp);
  }, [limitProp]);

  const onChange = useCallback(
    (e, changes) => {
      setLimit((prevLimit) => {
        const limit = changes.type
          ? { type: changes.type }
          : { ...prevLimit, ...changes };

        let valid = true;
        if (!limit.type) {
          valid = false;
        }
        if (isEmpty(limit.value)) {
          valid = false;
        }
        if (limit.type === 'EndValue' && isEmpty(limit.elementKey)) {
          valid = false;
        }

        if (valid) {
          onChangeProp(e, { limit });
        }
        return limit;
      });
    },
    [onChangeProp]
  );

  return (
    <Style>
      <div>
        <Label>End condition:</Label>
        {!!onChangeProp ? (
          <Dropdown
            placeholder="End Condition"
            selectOnBlur={false}
            selectOnNavigation={false}
            value={limit.type}
            options={limitOptions}
            onChange={(e, { value: type }) => onChange(e, { type })}
          />
        ) : (
          <LimitTypeText limitType={limit.type} />
        )}
      </div>
      {limit.type === 'Count' ? (
        <FlexRowContainer alignItems="center" justifyContent="flex-start">
          <Label>Number of values:</Label>
          {!!onChangeProp ? (
            <ValuePicker
              fluid={false}
              elements={elements}
              placeholder="Count"
              value={limit.value}
              restrictTypes={['Number']}
              onChange={(e, { value }) => onChange(e, { value })}
            />
          ) : (
            <LimitValueText elements={elements} limitValue={limit.value} />
          )}
        </FlexRowContainer>
      ) : null}
      {limit.type === 'EndValue' ? (
        <>
          <div>
            <Label>Series:</Label>
            {!!onChangeProp ? (
              <Dropdown
                value={limit.elementKey}
                options={fieldOptions}
                placeholder="Limit Series"
                onChange={(e, { value: elementKey }) =>
                  onChange(e, { elementKey })
                }
              />
            ) : (
              <LimitElementText
                fields={fields}
                limitElementKey={limit.elementKey}
              />
            )}
          </div>
          <FlexRowContainer alignItems="center" justifyContent="flex-start">
            <Label>Value:</Label>
            {!!onChangeProp ? (
              <LimitValuePicker
                elements={elements}
                limitValue={limit.value}
                limitField={
                  limit.elementKey
                    ? fields.find((f) => f.toElement.key === limit.elementKey)
                    : null
                }
                onChange={(e, { value }) => onChange(e, { value })}
              />
            ) : (
              <LimitValueText elements={elements} limitValue={limit.value} />
            )}
          </FlexRowContainer>
        </>
      ) : null}
    </Style>
  );
}
