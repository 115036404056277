import React, { useState } from 'react';
import { useMutation, useQuery } from '<src>/apollo/client';

import { DeleteProject } from '<sections>/projects/queries';
import { GetProjectDeployments, UpgradeDeployment } from '../../../queries';
import WaveSpinner from '<components>/WaveSpinner';
import Deployments from './Deployments';
import DeployDialog from '<sections>/projects/components/DeployDialog';

export default function ProjectDeployments({ project }) {
  const [deployVisible, setDeployVisible] = useState(false);
  const [deleteProject] = useMutation(DeleteProject);
  const [upgradeDeployment] = useMutation(UpgradeDeployment);
  const variables = { projectID: project.ID };

  const { data, loading } = useQuery(GetProjectDeployments, {
    variables,
  });

  const onDelete = (ID) =>
    deleteProject({
      variables: { ID },
      update: (cache, { data: { removeProject: ID } }) => {
        const { projectDeployments } = cache.readQuery({
          query: GetProjectDeployments,
          variables,
        });
        const newDepls = projectDeployments.filter((p) => p.ID !== ID);

        cache.writeQuery({
          query: GetProjectDeployments,
          variables,
          data: { projectDeployments: newDepls },
        });
      },
    });

  const onUpgrade = (ID) => upgradeDeployment({ variables: { ID } });

  if (loading) {
    return <WaveSpinner />;
  }

  return (
    <>
      <Deployments
        project={project}
        deployments={data.projectDeployments}
        onDeploy={() => setDeployVisible(true)}
        onDelete={onDelete}
        onUpgrade={onUpgrade}
      />
      <DeployDialog
        visible={deployVisible}
        onRequestClose={() => setDeployVisible(false)}
        projectID={project.ID}
        isManaged
      />
    </>
  );
}
