import React, { useState } from 'react';

import { Expanded } from './contexts';

export default function ExpandedManager({ children }) {
  const [expanded, setExpanded] = useState([]);

  return (
    <Expanded.Provider value={{ expanded, setExpanded }}>
      {children}
    </Expanded.Provider>
  );
}
