import React from 'react';
import styled from '@emotion/styled';

import * as colors from '<components>/colors';
import { RoundChiclet } from '<src>/components/NumbrzButtons';
import { Loader } from 'semantic-ui-react';

const StyledLoader = styled(Loader)`
  margin-right: 15px;
  ::after {
    border-color: ${(props) =>
      `${
        props.borderColor ? props.borderColor : '#6aa5ff'
      } transparent transparent !important`};
  }
`;

export const getStatusCodeColor = (status) => {
  switch (status) {
    case 'Active':
    case 'BlockStarted':
    case 'GroupStarted':
    case 'JobStarted':
    case 'Running':
      return colors.blue3;
    case 'Failed':
    case 'Canceled':
    case 'Cancelled':
    case 'BlockFailed':
    case 'ReviewDenied':
    case 'GroupCancelled':
    case 'GroupFailed':
    case 'JobFailed':
    case 'JobCancelled':
    case 'InputsFailed':
    case 'NotRunning':
    case 'CancelledByUser':
      return colors.red3;
    case 'Pending':
    case 'WaitingReview':
    case 'PausedForReview':
    case 'ReviewApproved':
    case 'Queued':
      return colors.yellow1b;
    case 'Complete':
    case 'Completed':
    case 'BlockCompleted':
    case 'GroupCompleted':
    case 'JobCompleted':
      return colors.green3;
    case 'Skipped':
      return colors.gray3;
    default:
      return colors.yellow1b;
  }
};

export const getStatusCodeTextColor = (status) => {
  switch (status) {
    case 'Active':
    case 'BlockStarted':
    case 'GroupStarted':
    case 'JobStarted':
    case 'Running':
      return colors.gray2b;
    case 'Complete':
    case 'Completed':
    case 'BlockCompleted':
    case 'GroupCompleted':
    case 'JobCompleted':
      return colors.green1b;
    case 'Failed':
    case 'Canceled':
    case 'Cancelled':
    case 'BlockFailed':
    case 'ReviewDenied':
    case 'GroupCancelled':
    case 'GroupFailed':
    case 'JobFailed':
    case 'JobCancelled':
    case 'InputsFailed':
    case 'NotRunning':
    case 'CancelledByUser':
      return 'white';
    case 'PausedForReview':
    case 'Queued':
      return colors.gray2b;

    case 'ReviewApproved':
    case 'WaitingReview':
    case 'Pending':
      return colors.orange1b;
    case 'Skipped':
      return colors.gray2b;
    default:
      return colors.gray2b;
  }
};

export const isStatusActive = (status) =>
  ['WaitingReview', 'Active', 'Running'].includes(status);

export const getFormattedStatus = (status) => {
  let formattedStatus = status;
  if (status === 'WaitingReview') formattedStatus = 'AwaitingReview';
  return formattedStatus ? formattedStatus.split(/(?=[A-Z])/).join(' ') : 'N/A';
};

export const getStatusChiclet = (
  status,
  isRunning = false,
  marginRight = false
) => {
  return (
    <RoundChiclet
      backgroundColor={getStatusCodeColor(status)}
      textColor={getStatusCodeTextColor(status)}
      borderColor="transparent"
      marginRight={marginRight ? '5px' : '0px'}
    >
      {getFormattedStatus(status) || 'Pending'}
      {isStatusActive(status) && (
        <StyledLoader
          borderColor={getStatusCodeTextColor(status)}
          active
          inline="centered"
          size="tiny"
        />
      )}
    </RoundChiclet>
  );
};
