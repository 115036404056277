import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import styled from '@emotion/styled';

const Style = styled('div')`
  display: flex;
  flex-direction: column;

  & > div {
    margin-top: 8px;
  }
  .checkbox {
    padding: 0px 5px 5px 5px;
  }
`;
export default function OutputSettings({ suspended, onChange }) {
  return (
    <Style>
      <Checkbox
        checked={suspended}
        disabled={!onChange}
        label="Skip this output during job execution"
        onChange={(e, { checked: suspended }) => onChange(e, { suspended })}
      />
    </Style>
  );
}
