import { useApolloClient } from '@apollo/client';

import { Selection } from '../contexts';

import {
  CellFragment,
  CellTemplateFragment,
  WidgetFragment,
} from './fragments';

const gqlTypeMap = {
  [Selection.CELL]: 'CellV3',
  [Selection.CELL_TEMPLATE]: 'CellTemplate',
  [Selection.WIDGET]: 'WidgetV3',
};

const gqlFragmentMap = {
  [Selection.CELL]: CellFragment,
  [Selection.CELL_TEMPLATE]: CellTemplateFragment,
  [Selection.WIDGET]: WidgetFragment,
};

export default function useGetCachedItem(funcID, item) {
  const client = useApolloClient();

  if (!item) return null;

  const gqlType = gqlTypeMap[item.type];
  const fragment = gqlFragmentMap[item.type];
  const fragmentName = fragment.definitions[0].name.value;

  if (!gqlType) return null;

  const id = `${gqlType}:${funcID}:${item.ID}`;

  const obj = client.readFragment({ fragment, id, fragmentName }, true);

  return obj;
}
