import React from 'react';
import Dialog from '<components>/Dialog';
import Button from '<components>/Button';

export default function GooglePreFlightDialog({ visible, onClose, onSubmit }) {
  return (
    <Dialog visible={visible} onRequestClose={onClose}>
      <Dialog.Header>
        <Dialog.Headline>We need to connect to Google Sheets</Dialog.Headline>
        <Button.DialogClose onClick={onClose} />
      </Dialog.Header>
      <Dialog.Body>
        <h5>To read your data and output your analysis to G-Sheets:</h5>
        <p>
          make sure select the checkbox for Numbrz to “see, edit, create, and
          delete your Google Sheets spreadsheets.”
        </p>

        <h5>Full transparency:</h5>
        <ol type="1">
          <li>
            Numbrz can only see the G-Sheets URLs you give us, we do not browse
            your spreadsheets.{' '}
          </li>
          <li>
            We will edit and create G-Sheets only as part of your analysis.
          </li>
          <li>
            We will not delete any G-sheets, we don’t have the functionality to
            do so.
          </li>
        </ol>
        <p>
          If you have any questions about our Google Sheets Connector, contact{' '}
          <a href="mailto:support@numbrz.com">support@numbrz.com</a>.{' '}
        </p>
      </Dialog.Body>
      <Dialog.Footer>
        <Button.Create onClick={onSubmit}>Next</Button.Create>
      </Dialog.Footer>
    </Dialog>
  );
}
