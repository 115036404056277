import React, { PureComponent } from 'react';
import classnames from 'classnames';

import withClassName from '<components>/hoc/className';
import withDefaultProps from '<components>/hoc/defaultProps';
import withFilteredProps from '<components>/hoc/filteredProps';
import withDisplayName from '<components>/hoc/displayName';

import ControlActions from './Actions';

import styledTable from '../styles';

@withDefaultProps({
  before: false,
  after: false,
  as: 'div',
})
@withClassName(({ before, after }) =>
  classnames(styledTable.control.divider.base, {
    [styledTable.control.divider.before]: before,
    [styledTable.control.divider.after]: after,
  })
)
@withFilteredProps(['before', 'after', 'children', 'as'])
@withDisplayName('ControlDivider')
export default class Divider extends PureComponent {
  render() {
    const { as: Tag, children, filteredProps } = this.props;

    return (
      <Tag {...filteredProps}>
        <div className={styledTable.control.divider.inner}>
          <div className={styledTable.control.dot} />
          <ControlActions children={children} />
        </div>

        <div className={styledTable.control.divider.line} />
      </Tag>
    );
  }
}
