import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { Dropdown, Ref } from 'semantic-ui-react';

import Cell from '../../Cell';

import { DropdownCell } from './styles';

const switchOps = [
  { text: '=', value: 'Eq' },
  { text: '!=', value: 'Neq' },
  { text: '>', value: 'Gt' },
  { text: '>=', value: 'Gte' },
  { text: '<', value: 'Lt' },
  { text: '<=', value: 'Lte' },
];

const CondTestCell = forwardRef(
  (
    { widget, cond, rule, onAddCondition, onLink, onConfirm, onUpdate },
    parentRef
  ) => (
    <Cell
      ref={parentRef}
      isEditable
      isTarget
      cell={cond.testValue}
      onConfirm={onConfirm}
      onLink={onLink}
      onUpdate={onUpdate}
    />
  )
);

function Condition(
  { api, appearTrigger, widget, inputOpts, rule, cond },
  parentRef
) {
  const inputRef = useRef();
  const opRef = useRef();
  const testRef = useRef();
  useImperativeHandle(parentRef, () => ({
    edit: () => {
      setTimeout(() => {
        inputRef.current.focus();
      });
    },
  }));
  useEffect(() => {
    if (appearTrigger.id === cond.correlationID) {
      setTimeout(() => inputRef.current.focus());
    }
  }, [appearTrigger, cond]);
  return (
    <>
      <DropdownCell className={!cond.inputID ? 'error' : undefined}>
        <Ref innerRef={inputRef}>
          <Dropdown
            placeholder="Select"
            value={cond.inputID}
            options={inputOpts}
            selectOnNavigation={false}
            onChange={(e, data) => {
              if (inputOpts.find((input) => input.value === data.value)) {
                api.updateCondition(widget, {
                  ruleID: rule.ID,
                  condID: cond.ID,
                  inputID: data.value,
                });
              }
            }}
            onClose={(e) => {
              if (e && e.type === 'keydown' && e.key === 'Enter') {
                setTimeout(() => opRef.current.focus());
              }
              if (appearTrigger.id === cond.correlationID) {
                if (e && e.type === 'keydown' && e.key === 'Escape') {
                  api.deleteCondition(widget, {
                    ruleID: rule.ID,
                    condID: cond.ID,
                  });
                }
                appearTrigger.done();
              }
            }}
          />
        </Ref>
      </DropdownCell>
      <DropdownCell>
        <Ref innerRef={opRef}>
          <Dropdown
            value={cond.op}
            options={switchOps}
            selectOnNavigation={false}
            onChange={(e, data) =>
              api.updateCondition(widget, {
                ruleID: rule.ID,
                condID: cond.ID,
                op: data.value,
              })
            }
            onClose={(e) => {
              if (e && e.type === 'keydown' && e.key === 'Enter') {
                testRef.current.edit(true);
              }
            }}
          />
        </Ref>
      </DropdownCell>
      <CondTestCell
        ref={testRef}
        widget={widget}
        cond={cond}
        rule={rule}
        onLink={api.updateCellLink}
        // onConfirm={() =>
        //   api.addCondition(widget, {
        //     ruleID: rule.ID,
        //     correlationID: setAppearTrigger('edit-label'),
        //   })
        // }
        onUpdate={api.updateCellValue}
      />
    </>
  );
}
// eslint-disable-next-line no-func-assign
Condition = forwardRef(Condition);
Condition.displayName = 'Condition';

export default Condition;
