import styled from '@emotion/styled';

import * as colors from '<components>/colors';
import Card from '<components>/Card';
import { Header, Body } from '<sections>/flows/Step/styles';

import { Dropdown, Icon } from 'semantic-ui-react';
import { SidebarSeparator } from '<components>/NumbrzMenus/SidebarNavMenu';
import { ConfigTable } from '<src>/sections/flows/styles';
import { ConfigField } from '<src>/components/NumbrzVerticalEditor';
import Switch from '@mui/material/Switch';

import {
  Content,
  ContentColumnLayout,
  FlexColumnContainer,
  FlexRowContainer,
  StyledScrollbar,
} from '<components>/NumbrzPageComponents';
import Link from '<src>/components/Link';
import {
  LinkButton,
  LabelButton,
  LabelBtnStyles,
} from '<components>/NumbrzButtons';
import DeleteButtonWithConfirm from '<src>/components/DeleteButtonWithConfirm';
import ButtonGroup from '@mui/material/ButtonGroup';
import TurnRightRoundedIcon from '@mui/icons-material/TurnRightRounded';
import SwapVertRoundedIcon from '@mui/icons-material/SwapVertRounded';

export const TableMapperHeader = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  h3 {
    margin-bottom: 0;
  }
  span {
    font-size: 11px;
    max-width: 60%;
    margin-left: 5px;
  }
`;

export const MappingHeader = styled(Header)`
  width: 100%;
  background: transparent;
  padding: 0px 3px 0px 0;
  margin: 0 !important;
  ${(props) => props.isInput && { borderColor: colors.blue6 }};
  ${(props) => props.isOutput && { borderColor: colors.green3 }};
  ${(props) => props.isLast && { borderBottom: 'none' }};

  ${(props) =>
    props.expanded &&
    `
  border-bottom: none;
  padding-bottom: 0px;
  `}
`;

export const CardHeader = styled(Header)`
  padding-left: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const MappingBody = styled(Body)`
  border-bottom: ${(props) =>
    props.isLast ? 'none' : `1px solid ${colors.gray_border}`};
  padding: 10px 15px 10px 15px;
  margin: 0 auto;
`;

export const MappingContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 5px 0 0px 0 !important;
  ${(props) => props.isLast && { marginBottom: '0 !important' }};
`;

export const LinkedMapperHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DetailHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 5px;
  button {
    margin-left: 10px;
  }
`;

export const UsageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UsageItemWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  background: ${colors.gray7};
  padding: 10px;
  border-radius: 2px;

  td.sep {
    background: transparent;
  }
`;

export const UsageItemHeader = styled('div')`
  width: 100%;
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  h4 {
    margin-bottom: 0;
  }
`;

export const TableMapperWrapper = styled('div')`
  width: auto;
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow-x: auto;

  ${StyledScrollbar};
`;

export const MissingDataWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;

export const FlowTableWrapper = styled('div')`
  display: flex;
  padding: 10px;
`;

export const ColumnWrapper = styled('div')`
  width: 100%;
  ${(props) => props.maxWidth && { maxWidth: props.maxWidth }};
  min-width: 450px;
  ${(props) => props.height && { height: props.height }};
  ${(props) => props.maxHeight && { maxHeight: props.maxHeight }};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => (props.padding ? props.padding : '10px')};
  ${(props) => props.marginBottom && { 'margin-bottom': props.marginBottom }};

  overflow-y: auto;
  ${StyledScrollbar};
`;

export const JobConfigurationWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const StyledCard = styled(Card)`
  width: 100%;
  height: fit-content;
  padding: 1em;
  margin-bottom: 20px;
`;

export const StyledDropdown = styled(Dropdown)`
  font-size: 12px;

  & .menu > .item {
    font-size: 12px !important;
  }
`;

export const StyledContentColumnLayout = styled(ContentColumnLayout)`
  height: 100%;
`;
export const StyledContent = styled(Content)`
  padding: 2em 4em 6em 0em;
  margin-right: 2px;
  overflow-y: auto;
  ${StyledScrollbar};

  & > * {
    max-width: 900px;
  }
`;

export const ConfigWrapper = styled('div')`
  padding: 0;
  border-radius: 2px;
  ${StyledScrollbar};
`;

export const StyledLinkButton = styled(LinkButton)`
  font-size: 11px;
  color: ${colors.gray2};
  display: flex;
  align-items: baseline;
  i {
    font-size: 1.3em;
  }
  :hover {
    color: ${colors.blue1};
  }
`;

const StyledLink = styled('a')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: ${colors.gray2};
  margin: 0;
  i {
    font-size: 1.3em;
    margin: 0;
  }
  :hover {
    color: ${colors.blue2};
    cursor: pointer;
  }
`;

export const OverviewStyledLink = styled(Link)`
  color: ${colors.blue3};
  :hover {
    color: ${colors.blue3};
  }
  border: 1px solid ${colors.blue3};
  border-radius: 4px;
  padding: 2px 5px;
  margin-left: 10px;
  font-size: 0.9em;
`;

export const SourceLink = styled(StyledLink)`
  border: 1px solid ${colors.blue2};
  font-size: 12px;
  padding: 1px 8px;
  border-radius: 4px;
  color: ${colors.blue2};
  :hover {
    background-color: ${colors.blue2};
    color: white;
  }
  letter-spacing: 1px;
`;

export const SourceHref = styled('a')`
  i {
    color: ${colors.blue2};
    margin-left: 10px;
    font-size: 16px;
  }
`;

export const LinkContainer = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const DataNameContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 4px;
  white-space: nowrap;
`;

export const StyledRouterLink = styled(Link)`
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}em` : '12px')};
  padding: 3px 5px;
  margin: ${(props) => (props.margin ? props.margin : '0')};
  font-weight: bold;
  color: ${colors.gray1b};
  :hover {
    color: ${colors.blue1};
  }
`;

export const InternalDataLink = styled(Link)`
  ${(props) => props.fontSize && { fontSize: props.fontSize }};
  ${(props) => props.height && { height: props.height }};
  color: ${(props) => (props.textColor ? props.textColor : colors.gray1b)};
  letter-spacing: 0.5px;
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  border-radius: 4px;
  padding: ${(props) => (props.padding ? props.padding : '0')};
  margin: ${(props) => (props.margin ? props.margin : '0')};
  :hover {
    color: ${colors.blue1};
    text-decoration: none;
  }
  display: flex;
  align-items: center;
  svg {
    font-size: 16px;
    margin: ${(props) => (props.noText ? '0' : '0 3px 0 -1px')};
  }
`;

export const InternalDataLinkBtn = styled(InternalDataLink)`
  ${LabelBtnStyles}
  border: none;
  padding: ${(props) => (props.padding ? props.padding : '5px 6px')};
  margin: ${(props) => (props.margin ? props.margin : '0')};
  font-size: 12px;
  :hover {
    background-color: ${colors.yellow4} !important;
    color: ${colors.gray1} !important;
  }
  color: ${colors.gray2};
  :hover {
    text-decoration: none;
  }
`;

export const FlowLabel = styled('span')`
  margin-right: 10px;
  color: ${colors.gray1b};
  font-weight: bold;
`;

export const Subheader = styled('span')`
  font-size: 0.8em;
  color: ${colors.gray2};
`;

export const UnlinkBtn = styled(LabelButton)`
  padding: 4px 6px;
  font-size: 0.7em !important;
`;

export const OverviewLinkBtn = styled(OverviewStyledLink)`
  position: relative;
  right: -20px;
`;

export const SidebarContainer = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  padding: 2em 0 0 2em;

  & > div:last-child {
    margin-bottom: 0;
  }
  .menu {
    .item {
      display: flex !important;
      align-items: baseline;
    }
  }
`;

export const HdrToolbar = styled('div')`
  display: flex;
  align-items: center;
  .loader {
    font-size: 1em;
    margin-right: 5px !important;
    top: 2px !important;
  }
  a {
    margin-left: 5px;
  }
`;

export const SyncBtn = styled(Icon)`
  font-size: 1.1em !important;
  margin-right: 5px !important;
  color: ${colors.gray2};
  :hover {
    cursor: pointer;
  }
`;

export const MapBtn = styled(LabelButton)`
  white-space: nowrap;
  text-transform: none;
  font-size: 11px;
  background: ${colors.yellow4};
  border: none;
  color: ${colors.gray1};
  padding: 5px 6px;
  margin: ${(props) => props.margin && props.margin};
  :hover {
    background-color: ${colors.yellow4};
    color: ${colors.gray1};
  }
  i {
    margin: 0 0 0 2px;
  }
  ${(props) =>
    props.expanded &&
    `
    background: white;
    border: 1px solid ${colors.yellow3b};
    :hover {
      background: white;
    }
  `}
  ${(props) =>
    props.expanded &&
    props.isInput &&
    `
  background: white;
  border: 1px solid ${colors.blue3};
`}
  ${(props) =>
    props.expanded &&
    props.isOutput &&
    `
background: white;
border: 1px solid ${colors.green2};
`}
`;

export const TextIconBtn = styled(LabelButton)`
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-transform: none;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  background: ${(props) => (props.bgColor ? props.bgColor : 'transparent')};
  color: ${colors.gray2};
  border: none;
  padding: ${(props) => (props.padding ? props.padding : '5px 6px')};
  margin: ${(props) => (props.margin ? props.margin : '0')};
  :hover {
    background-color: ${colors.yellow4};
    color: ${colors.gray1};
  }
  svg {
    font-size: ${(props) => (props.iconSize ? props.iconSize : '18px')};
    margin: ${(props) => (props.iconMargin ? props.iconMargin : '0 4px 0px 0')};
  }
  i {
    margin-top: 1px;
    margin-right: 0px;
  }
  ${(props) =>
    props.expanded &&
    `
    background-color: ${colors.yellow4};
    color: ${colors.gray1};

    }
  `}
  ${(props) =>
    props.deleteMode &&
    `
  background-color: ${colors.red1c};
  color: white;
  :hover {
    background-color: ${colors.red1c};
  color: white;
  }
  `}
`;

export const TablesetHdr = styled('h3')`
  display: flex;
  padding: 15px;
  margin: 0 10px 0 10px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : colors.yellow4};
  color: ${(props) => (props.textColor ? props.textColor : colors.gray1)};
  box-shadow: 0 8px 6px -10px ${colors.gray4};
  i {
    color: ${(props) => (props.textColor ? props.textColor : colors.gray1)};
    margin-right: 10px;
  }
  img {
    margin-right: 7px;
  }
`;

export const ListItemSeparator = styled('div')`
  height: 1px;
  margin: 5px 10px;
  background-color: ${colors.gray3};
`;

export const ErrorChiclet = styled('div')`
  display: flex;
  width: ${(props) => (props.wide ? 'fit-content' : '20px')};
  font-size: 0.85em;
  background: ${colors.red1c};
  border-radius: 40px;
  margin-left: 5px;
  padding: ${(props) => (props.wide ? '0 5px 0 8px' : '20px')};
  color: white;
  text-align: center;
  ${(props) => props.actionable && { cursor: 'pointer' }};
  ${(props) => props.centered && { margin: '0 auto' }};
  i {
    font-size: 14px;
    margin-left: 4px;
  }
`;

export const ExpandBar = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

export const StyledSidebarSeparator = styled(SidebarSeparator)`
  width: 100%;
  margin-bottom: 0;
`;

export const ChicletWrapper = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 10px;
`;

export const StatusTable = styled(ConfigTable)`
  tbody:before {
    content: '@';
    display: block;
    line-height: 10px;
    text-indent: -99999px;
  }
  td {
    padding: 2px 5px 5px 5px;
  }

  th,
  td {
    border: none;
  }
  td.centered,
  th.centered {
    text-align: center;
  }
`;

export const DetailsBtn = styled(LabelButton)`
  i {
    margin: 0 !important;
    font-size: 15px;
  }
  border: none;
  color: ${colors.gray1};
  :hover {
    border: none;
    color: ${colors.gray1};
    background: ${colors.yellow5};
  }
`;

export const DetailsLink = styled(Link)`
  i {
    margin: 0 !important;
    font-size: 15px;
  }
  border: none;
  border-radius: 4px;
  padding: 4px 6px;
  color: ${colors.gray1};
  :hover {
    border: none;
    color: ${colors.gray1};
    background: ${colors.yellow5};
  }
`;

export const StatusInfo = styled('div')`
  display: flex;

  div {
    margin-left: 3em;
    display: flex;
    align-items: center;
    i {
      margin: 0 5px 0 0;
      font-size: 1.1em;
      height: unset;
    }
  }
`;

export const UnmappedItem = styled(FlexRowContainer)`
  padding: 6px 5px 9px 10px;
  border-bottom: 1px solid ${colors.gray_border};
  ${(props) => props.last && { borderBottom: `none` }};
`;

export const FlowMappingWrapper = styled(FlexColumnContainer)`
  width: 100%;
  padding: 0px;
  margin: 0;
`;

export const DataSourceListContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid ${colors.gray_border};
`;

export const DataSourceList = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  padding-left: 5px;
  border: 1px solid ${colors.gray_border};
`;

export const ColumnsMenu = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: absolute;
  top: 100px;
  left: 50%;
  min-width: 250px;
  min-height: 220px;

  z-index: 999;
  background: white;
  border: 2px solid ${colors.gray3};
  border-radius: 4px;
  color: ${colors.gray1d};
  h5 {
    font-size: 13px;
    letter-spacing: 0.3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid ${colors.gray3};
    margin-bottom: 10px;
    padding-bottom: 10px;
    color: ${colors.gray2};
    button {
      height: unset;
      padding: 0;
    }
  }
`;

export const MappingSubhdr = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 11px;
  color: ${colors.gray1b};

  line-height: 14px;
  a {
    font-weight: normal;
    padding-left: 0;
  }
`;

export const MappingHdr = styled('div')`
  height: 17px;
  display: flex;
  align-items: center;
  b {
    font-size: 13px;
  }
  a {
    padding: 0;
  }
`;

export const InternalIconLink = styled(Link)`
  padding: 0;
  margin: 0 0 0 5px;
  color: ${colors.gray1b};
`;

export const SrcTypeLbl = styled('span')`
  font-size: 11px;
  margin-right: 5px;
`;

export const ExternalIconLink = styled('a')`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0 0 0 0;
  color: ${colors.gray1b};
  :hover {
    color: ${colors.blue1};
  }
  svg {
    font-size: 16px;
  }
`;

export const MappingToolbar = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    margin: -10px 0 0 30px;
  }

  h4 {
    font-size: 13px;
    margin: 0 0 2px 0px;
    padding: 0;
  }
`;

export const LinkSeparator = styled('span')`
  margin: 0 2px;
`;

export const JobTableLink = styled(Link)`
  font-weight: bold;
  font-size: 13px;
  color: ${colors.gray1b};
  :hover {
    color: ${colors.blue1};
  }
  margin: 1px 5px 0 1px;
`;

export const StlyedDeleteBtn = styled(DeleteButtonWithConfirm)`
  :hover {
    background: ${colors.yellow4};
  }
`;

export const DataHeader = styled('div')`
  padding: 5px 5px 3px 5px;

  display: flex;
  border-bottom: 2px solid ${colors.gray_border};
  font-weight: bold;
  font-size: 13px;
  align-items: center;
  justify-content: space-between;

  i {
    margin-left: 2px;
    cursor: pointer;
  }
`;

export const HeaderLbl = styled('span')`
  height: 15px;
  text-transform: uppercase;
  font-size: 11px;
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
`;

export const PopupMenu = styled('div')`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  padding: ${(props) => (props.padding ? props.padding : '5px 10px 10px 10px')};
  position: absolute;
  top: ${(props) => (props.top ? `${props.top}px` : '25px')};
  right: ${(props) => (props.right ? `${props.right}px` : '0px')};
  width: max-content;
  min-width: ${(props) => (props.minWidth ? props.minWidth : '250px')};
  min-height: ${(props) => (props.minHeight ? props.minHeight : '150px')};

  z-index: 999;
  background: white;
  border: 2px solid ${colors.gray3};
  border-radius: 4px;
  color: ${colors.gray1d};
  :focus {
    outline: none;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      display: flex;
      align-items: center;
    }
  }

  .ui.toggle.checkbox {
    width: unset;
    label {
      padding-top: 0;
      padding-right: 3rem;
    }
    label:before {
      width: 22px;
      height: 11.5px;
      margin-top: 5px;
      background-color: ${colors.gray2} !important;
    }
    label:after {
      margin-top: 5px;
      left: 3px;
      width: 8px;
      height: 8px;
      top: 1.5px;
    }
    input {
      width: unset;
      height: unset;
    }
    input:checked ~ label:after {
      left: 11px;
    }
  }
`;

export const PopupListItem = styled('li')`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  padding: ${(props) => (props.padding ? props.padding : '3px 5px 3px 6px')};
  :hover {
    cursor: pointer;
    background: ${colors.blue4};
  }
  a {
    color: ${colors.gray2};
    :hover {
      color: ${colors.gray2};
    }
  }
`;

export const MenuHdr = styled('h5')`
  font-size: 13px !important;
  letter-spacing: 0.3px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  border-bottom: 2px solid ${colors.gray3};
  margin-bottom: 10px !important;
  margin-top: 5px !important;
  padding-bottom: 5px;
  color: ${colors.gray2};
  button {
    height: unset;
    padding: 0;
  }
`;

export const MenuSectionHdr = styled(MenuHdr)`
  font-size: 11px !important;
  padding-bottom: 2px;
  margin-bottom: 5px !important;
`;

export const ToggleContainer = styled(MenuHdr)`
  margin-top: 0 !important;
  padding-left: 2px;
  font-size: 12px !important;
  border-bottom: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FilterBtnGroup = styled(ButtonGroup)`
  border: 2px solid ${colors.gray_border};
  width: 100%;
  button {
    flex-grow: 1;
    text-transform: none;
    background: transparent;
    color: ${colors.gray2};
    padding: 1px 6px;
    border-color: ${colors.gray_border} !important;
    :hover {
      background: ${colors.yellow4};
      color: ${colors.gray1};
    }
  }
  button.active {
    background: ${colors.yellow4};
    color: ${colors.gray1};
  }
`;

export const DataSourceContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  margin: 0px 0 10px 0;
  background: ${colors.yellow4};
  color: ${colors.gray1};
  border-radius: 4px;
  svg {
    font-size: 16px;
    margin-right: 3px;
    margin-left: -1px;
  }
  font-size: 11px;
`;

export const DataSrcDropdown = styled(Dropdown)`
  display: flex !important;
  align-items: center !important;
  width: 100%;
  border: 2px solid ${colors.gray_border};
  margin: 0;
  border-radius: 4px;
  padding: 2px 4px 2px 10px;
  .text {
    font-size: 11px;
  }
  div.menu {
    .item {
      font-size: 10px !important;
      display: flex !important;
      align-items: center !important;
    }
  }
`;

export const SearchBarContainer = styled('div')`
  border-left: 1px solid ${colors.gray_border};
  height: 16px;
  margin-left: 10px;
  padding-left: 5px;
  border-bottom: 1px solid white;
  span {
    top: -1px;
    left: 1px;
    svg {
      height: unset;
      width: unset;
      font-size: 18px;
    }
  }
  button {
    top: 10px;
    left: -1px;
    :hover {
      color: ${colors.gray2};
    }
    svg {
      height: 14px;
      width: 14px;
      fill: ${colors.gray2};
      :hover {
        fill: ${colors.gray1b};
      }
    }
  }
  input {
    height: 20px;
    border: none;
    border-radius: 0;
    padding: 0 10px 0px 20px;
    margin-top: -2px;
    :focus {
      border-bottom: 1px solid ${colors.blue3};
    }
  }
`;

export const JTMappingContainer = styled('div')`
  background-color: white;

  ${(props) =>
    !props.last && { borderBottom: `1px solid ${colors.gray_border}` }};

  ${(props) => props.error && { backgroundColor: colors.red5 }};

  width: 100%;
  display: flex;
  flex-direction: column;

  h3 {
    color: ${colors.gray1};
    margin: 0;
    padding-left: 10px;
  }
  h4 {
    margin: 0 0 5px 0;
    padding: 0 0 5px 10px;
  }
`;

export const InputIcon = styled(TurnRightRoundedIcon)`
  transform: scaleY(-1);
  font-size: 18px;
`;

export const OutputIcon = styled(TurnRightRoundedIcon)`
  transform: scaleY(-1) scaleX(-1) rotate(90deg);
  font-size: 18px;
  margin-top: -1px;
`;

export const InputOutputIcon = styled(SwapVertRoundedIcon)`
  font-size: 18px;
`;

export const JTlbl = styled('span')`
  font-weight: bold;
  margin-left: 0 !important;
  letter-spacing: 0.4px;
`;

export const JobTableSrcInfo = styled(FlexRowContainer)`
  color: ${colors.gray1};
  align-items: center;
  justify-content: flex-start;
  svg {
    font-size: 18px;
    margin-right: 3px;
    margin-left: -1px;
  }
  ${(props) => props.padding && { padding: props.padding }}
`;

export const MapInputStyles = styled(ConfigField)`
  background: transparent;
  height: 26px;
  padding-top: 3px !important;
  ::placeholder {
    color: ${colors.gray2} !important;
    letter-spacing: 0.5px;
    font-family: 'Lato', 'Helvetica';
  }
`;

export const TableCellSeparator = styled('span')`
  height: 16px;
  margin: 0 8px 0 0;
  border-right: 1px solid ${colors.gray_border};
`;
export const InputLabel = styled('span')`
  color: ${colors.gray2};
  white-space: nowrap;
  margin-right: 5px;
  height: 20px;
  letter-spacing: 0.5px;
  font-family: 'Lato', 'Helvetica';
`;

export const MapTableCell = styled('td')`
  i {
    margin-top: -3px;
  }
`;

export const DataTypeLbl = styled('span')`
  letter-spacing: 0.1px;
`;

export const PopupSwitch = styled(Switch)(() => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: colors.blue3,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: 'width 200',
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: colors.gray2,
    boxSizing: 'border-box',
  },
}));

export const DatasetHdr = styled(FlexRowContainer)`
  justify-content: flex-start;
  align-items: center;
  a {
    font-size: 13px;
    color: ${colors.gray1b};
    :hover {
      background: transparent;
    }
  }
  span {
    font-size: 11px;
  }
`;

export const StyledBody = styled(Body)`
  padding: 0px 0 0 0;
  margin: 0;
  a {
    font-size: 12px;
    :hover {
      background: transparent;
    }
  }
`;

export const LinkLbl = styled('span')`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  color: ${colors.gray2};
  letter-spacing: 0.5px;
  font-family: Lato, 'Helvetica';
  white-space: nowrap;
`;

export const DatasetContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 7px 0px 5px 0px;
`;

export const EmptyMsg = styled(LinkLbl)`
  display: block;
  padding: 5px 10px;
`;

export const CardContainer = styled('div')`
  flex-grow: 1;
  padding: 5px;

  min-width: 50%;
`;

export const TablesetCounter = styled('div')`
  display: flex;
  align-items: center;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  font-weight: bold;
  margin-right: 9px;
  padding: 3px 10px 3px 0px;
  border-right: 1px solid ${colors.gray_border};
  letter-spacing: 0.3px;
  font-family: Lato, 'Helvetica';
`;

export const TableCounterLbl = styled(TablesetCounter)`
  min-width: 25px;
  text-align: center;
  font-size: 12px;
  margin-right: 3px;
  margin-left: 3px;
  font-weight: normal;
  padding: 5px 6px 5px 5px;
  background: transparent;
  color: ${colors.gray1b};
  border-right: 1px solid ${colors.gray_border};

  border-radius: 0;
  ${(props) => props.isInput && { borderRight: `1px solid ${colors.blue7}` }};
  ${(props) => props.isOutput && { borderColor: colors.green3 }};
`;

export const DataLinkErrChiclet = styled(InternalDataLink)`
  font-size: 12px;
  padding: 0px 8px;
  border-radius: 50px;
  background: ${colors.red1c};
  color: white;
  :hover {
    background: ${colors.red1c} !important;
    color: white;
  }
`;
