import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Input } from 'semantic-ui-react';
import styled from '@emotion/styled';

import * as colors from '<components>/colors';
import UnderlinedHeader from '<components>/UnderlinedHeader';
import { TextIconBtn } from '<src>/sections/jobs/styles';
import { FlexRowContainer } from '../NumbrzPageComponents';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import { NumbrzTooltip } from '../NumbrzButtons';
import Button from '../Button';
import Link from '<components>/Link';

const StyledPlaceholder = styled('p')`
  font-style: italic;
  color: ${colors.gray2};
  padding: 0 5px 0 0;
`;

const HelpIcon = styled(HelpOutlineRoundedIcon)`
  font-size: 16px;
  margin-left: 3px;
  :hover {
    cursor: pointer;
  }
`;

export default function ExternalResultURL({
  entity = 'job',
  externalResultURL = '',
  onChange,
}) {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(externalResultURL);
  const inputRef = useRef();
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
      inputRef.current.select();
    } else {
      setValue(externalResultURL);
    }
  }, [editing, externalResultURL]);

  const onKeyDown = (e) => {
    switch (e.key) {
      case 'Enter':
        e.stopPropagation();
        e.preventDefault();
        onChange(e, { externalResultURL: value });
        setEditing(false);
        break;
      case 'Escape':
        e.stopPropagation();
        e.preventDefault();
        setEditing(false);
        break;
      default:
        break;
    }
  };

  return (
    <Fragment>
      <UnderlinedHeader justifyContent="space-between">
        <FlexRowContainer justifyContent="flex-start" alignItems="center">
          <span>External Results Location</span>
          <NumbrzTooltip
            trigger={<HelpIcon />}
            wide
            content={
              'Use the external result location to provide a link to the data that is updated by this job. This location will be provided when the job is run.'
            }
            position="top left"
          />
        </FlexRowContainer>
        <UnderlinedHeader.Controls>
          {editing ? (
            <>
              <TextIconBtn onClick={() => setEditing(false)}>
                Cancel
              </TextIconBtn>
              <TextIconBtn
                onClick={(e) => {
                  onChange(e, { externalResultURL: value });
                  setEditing(false);
                }}
              >
                Save
              </TextIconBtn>
            </>
          ) : (
            <Button.EditBtn
              onClick={() => setEditing(true)}
              title={externalResultURL ? 'Edit' : 'Set'}
            />
          )}
        </UnderlinedHeader.Controls>
      </UnderlinedHeader>

      <div style={{ display: editing ? 'block' : 'none' }}>
        <Input
          ref={inputRef}
          fluid
          placeholer={`Enter ${entity} result URL`}
          value={value}
          onChange={(e, { value }) => setValue(value)}
          onKeyDown={onKeyDown}
        />
      </div>
      <div
        style={{
          display: editing ? 'none' : 'block',
          padding: '0 5px 0 0',
          fontSize: '12px',
        }}
      >
        {externalResultURL ? (
          <Link
            blue
            bold={false}
            size="small"
            href={externalResultURL}
            style={{ padding: 0 }}
          >
            {externalResultURL}
          </Link>
        ) : (
          <StyledPlaceholder>
            No external result location provided
          </StyledPlaceholder>
        )}
      </div>
    </Fragment>
  );
}
