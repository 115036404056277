import React, { useMemo, useCallback } from 'react';
import { RowListWrapper } from './styles';
import CardItem from './CardItem';
import {
  FlexColumnContainer,
  CardList,
} from '<components>/NumbrzPageComponents';

import { HdrRow, Row } from './utils';

export default function List({
  items,
  entityType,
  baseURL,
  onRunItem,
  setRunResult,
  onDeleteItem,
  onCloneItem,
  history,
  itemsRunning = [],
  displayType = 'card',
}) {
  const handleDeleteItem = useCallback(
    (e, item) => {
      e.preventDefault();
      e.stopPropagation();

      if (
        ['function', 'job', 'flow', 'job-group', 'data', 'model'].includes(
          entityType
        )
      ) {
        onDeleteItem(item.ID);
      } else {
        onDeleteItem(e, item);
      }
    },
    [onDeleteItem, entityType]
  );

  const handleCloneItem = useCallback(
    (e, item) => {
      if (['function', 'job', 'flow'].includes(entityType)) {
        onCloneItem(e, item.ID);
      } else {
        onCloneItem(e, item);
      }
    },
    [onCloneItem, entityType]
  );

  const listItems = useMemo(() => {
    const ListItemComp = displayType === 'card' ? CardItem : Row;

    return items.map((item, idx) => (
      <ListItemComp
        key={idx}
        item={item}
        entityType={entityType}
        baseURL={baseURL}
        handleRunItem={onRunItem}
        setRunResult={setRunResult}
        handleDeleteItem={handleDeleteItem}
        handleCloneItem={handleCloneItem}
        excludeDelete={!onDeleteItem}
        excludeClone={!onCloneItem}
        history={history}
        itemsRunning={itemsRunning}
      />
    ));
  }, [
    entityType,
    baseURL,
    items,
    handleDeleteItem,
    handleCloneItem,
    onCloneItem,
    onDeleteItem,
    history,
    onRunItem,
    itemsRunning,
    displayType,
    setRunResult,
  ]);

  const headerRow = useMemo(() => HdrRow(entityType), [entityType]);

  return displayType === 'card' ? (
    <CardList>{listItems}</CardList>
  ) : (
    <RowListWrapper role="rowgroup">
      <FlexColumnContainer width="100%">
        {headerRow}
        {listItems}
      </FlexColumnContainer>
    </RowListWrapper>
  );
}
