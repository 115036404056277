/* 
this reference guide helps us pre-fill the search bar
of the help utility based on the url path that the user is on
*/

const articleRefGuide = [
  {
    articleID: '6137219',
    collectionID: '',
    keywords: ['data', 'connected', 'available'],
  },
  {
    articleID: '6137218',
    collectionID: '',
    keywords: ['data', 'connected', 'available'],
  },
  {
    articleID: '6137213',
    collectionID: '',
    keywords: ['data', 'connected', 'available'],
  },
  {
    articleID: '6136806',
    collectionID: '',
    keywords: ['jobs'],
  },
  {
    articleID: '6136804',
    collectionID: '',
    keywords: ['status'],
  },
  {
    articleID: '6136798',
    collectionID: '',
    keywords: ['jobs'],
  },
  {
    articleID: '6136794',
    collectionID: '',
    keywords: ['jobs'],
  },
  {
    articleID: '6136791',
    collectionID: '',
    keywords: ['stages'],
  },
  {
    articleID: '6136790',
    collectionID: '',
    keywords: ['stages'],
  },
  {
    articleID: '6136691',
    collectionID: '',
    keywords: ['stages'],
  },
  {
    articleID: '6136684',
    collectionID: '',
    keywords: ['stages'],
  },
  {
    articleID: '6136679',
    collectionID: '',
    keywords: ['stages', 'flows'],
  },
  {
    articleID: '6136652',
    collectionID: '',
    keywords: ['models'],
  },
  {
    articleID: '6136642',
    collectionID: '',
    keywords: ['jobs'],
  },
  {
    articleID: '6136629',
    collectionID: '',
    keywords: ['flows'],
  },
  {
    articleID: '6136388',
    collectionID: '',
    keywords: ['functions'],
  },
  {
    articleID: '6134582',
    collectionID: '',
    keywords: [
      'models',
      'data',
      'catalog',
      'jobs',
      'flows',
      'functions',
      'connected',
      'available',
      'overview',
    ],
  },
  {
    articleID: '6122450',
    collectionID: '',
    keywords: ['data', 'connected', 'available'],
  },
  {
    articleID: '6122446',
    collectionID: '',
    keywords: ['data', 'connected', 'available'],
  },
  {
    articleID: '6119019',
    collectionID: '',
    keywords: ['account'],
  },
  {
    articleID: '6116788',
    collectionID: '',
    keywords: ['models'],
  },
  {
    articleID: '6110758',
    collectionID: '',
    keywords: ['data', 'connected', 'available'],
  },
  {
    articleID: '6097991',
    collectionID: '',
    keywords: ['models'],
  },
  {
    articleID: '6097979',
    collectionID: '',
    keywords: ['models'],
  },
  {
    articleID: '6070479',
    collectionID: '',
    keywords: ['data', 'connected', 'available'],
  },
  {
    articleID: '6063286',
    collectionID: '',
    keywords: ['template'],
  },
  {
    articleID: '6051171',
    collectionID: '',
    keywords: ['job'],
  },
  {
    articleID: '6047787',
    collectionID: '',
    keywords: ['models'],
  },
];

export default articleRefGuide;
