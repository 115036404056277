import React, { useMemo } from 'react';
import orderBy from 'lodash/orderBy';
import styled from '@emotion/styled';

import {
  ListContent,
  CardList,
  EmptyListContainer,
} from '<components>/NumbrzPageComponents';
import CatalogItem from './CatalogItem';

const List = styled(CardList)`
  grid-template-columns: repeat(auto-fill, 348px);
`;

function haveCommonItems(arr1, arr2) {
  const set1 = new Set(arr1);
  const commonItems = arr2.filter((item) => set1.has(item));
  return commonItems.length > 0;
}

function applySearchAndFilters(filters = {}, searchPattern, items = []) {
  let newItems = [...items];
  // filter by category first
  if (filters.category) {
    newItems = newItems.filter(
      ({ type, isLibrary, currentReleaseID, helpWanted }) => {
        let filter = false;
        if (type === filters.category && currentReleaseID && !isLibrary)
          return true;
        if (isLibrary && filters.category === 'isLibrary') return true;
        if (helpWanted && filters.category === 'helpWanted') return true;
        if (!currentReleaseID && filters.category === 'preview') return true;

        return filter;
      }
    );
  }
  if (searchPattern) {
    newItems = orderBy(items, ['name'], ['asc']).filter(
      ({ name, tagLine, description, tags = [] }) => {
        let filter = false;
        if (
          searchPattern.test(name) ||
          searchPattern.test(tagLine) ||
          searchPattern.test(description)
        )
          filter = true;

        tags.forEach((t) => {
          if (searchPattern.test(t)) filter = true;
        });

        return filter;
      }
    );
  }

  if (filters.tags && filters.tags.length > 0) {
    newItems = newItems.filter(
      ({ tags, category }) =>
        haveCommonItems(filters.tags, tags) ||
        haveCommonItems(filters.tags, [category])
    );
  }
  if (filters.type && filters.type.length > 0) {
    if (filters.type.includes('service') && filters.type.includes('template'))
      return newItems;

    if (filters.type.includes('service'))
      newItems = newItems.filter(({ type }) => type === 'DeployService');
    if (filters.type.includes('template'))
      newItems = newItems.filter(({ type }) => type === 'DeploySource');
  }
  return newItems;
}

function InnerList({ projects, searchTerm, searchPattern, onDeploy }) {
  return (
    <List>
      {projects.map((project) => (
        <CatalogItem
          catalogProject={project}
          searchTerm={searchTerm}
          searchPattern={searchPattern}
          onDeploy={onDeploy}
        />
      ))}
    </List>
  );
}

export default function CatalogList({
  projects = [],
  searchTerm,
  searchPattern,
  filters,
  categories = [],
  onDeploy,
}) {
  const filtered = useMemo(
    () => applySearchAndFilters(filters, searchPattern, projects),
    [filters, projects, searchPattern]
  );

  const category = categories.find((c) => c.value === filters.category);

  if (filtered.length === 0) {
    return (
      <ListContent>
        <EmptyListContainer>
          <h4 style={{ marginTop: '20px' }}>No items found</h4>
        </EmptyListContainer>
      </ListContent>
    );
  }

  return (
    <ListContent>
      {category && (
        <p style={{ margin: '0px 0 20px 10px', fontSize: '18px' }}>
          {category.description}
        </p>
      )}

      <InnerList
        projects={filtered}
        searchTerm={searchTerm}
        searchPattern={searchPattern}
        onDeploy={onDeploy}
      />
    </ListContent>
  );
}
