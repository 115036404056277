import React, { useContext } from 'react';

import { Plus, Trash } from '<components>/Icons';

import { Selection } from '../contexts';
import Cell, { StyledHeaderCell } from '../Cell';
import ColumnSettings from '../ColumnSettings';
import Table from '../Table';

import useAppearTrigger from '../useAppearTrigger';

import { cellType, isFirstOutput } from './util';
import RangeLabel from './RangeLabel';

function Header({ widget, dispatch, expanded, onLink, onUpdate }) {
  return (
    <>
      <Table.Columns>
        <Table.ControlColumn />
        {widget.columns.map((col) => (
          <Table.Column key={col.ID} />
        ))}
      </Table.Columns>
      <Table.Head>
        <Table.Row>
          <Table.RowControl />
          <StyledHeaderCell />
          {widget.columns.map((column, colIdx) => (
            <ColumnSettings
              key={column.ID}
              disabled
              firstOutput={isFirstOutput(widget, colIdx)}
              column={column.normTemplate}
              expanded={expanded}
            />
          ))}
        </Table.Row>
      </Table.Head>
    </>
  );
}

export function RowControl({
  api,
  widget,
  func,
  row,
  nextRow,
  setAppearTrigger,
}) {
  const actions = [];
  if (api.deleteRow && widget.rows.length > 1) {
    actions.push(
      <Table.Action key="delete" onClick={() => api.deleteRow(widget, row)}>
        <Trash />
      </Table.Action>
    );
  }
  if (api.addRow) {
    actions.push(
      <Table.Action
        key="add"
        onClick={() => {
          const beforeID = nextRow ? nextRow.ID : null;
          api.addRow(widget, {
            beforeID,
            correlationID: setAppearTrigger('edit'),
          });
        }}
      >
        <Plus />
      </Table.Action>
    );
  }
  const draggable = api.moveRow && widget.rows.length > 1;
  return (
    <Table.RowControl
      draggable={draggable}
      tabIndex={actions.length > 0 ? 0 : undefined}
    >
      {actions}
    </Table.RowControl>
  );
}

export function BodyCell({
  widget,
  func,
  cell,
  colIdx,
  rowIdx,
  onLink,
  onUpdate,
}) {
  const type = cellType(widget, func, colIdx, rowIdx);
  const isInput = type === 'Input';
  const isOutput = type === 'Output';
  return (
    <Cell
      cell={cell}
      isSeparated={isFirstOutput(widget, colIdx)}
      isInput={isInput}
      isOutput={isOutput}
      isEditable={!isOutput}
      isSource={!isInput}
      isTarget={!isOutput}
      onLink={onLink}
      onUpdate={onUpdate}
    />
  );
}

export default function WidgetBody({
  api,
  active,
  func,
  widget,
  dispatch,
  expanded,
  showNewRow,
}) {
  const [appearTrigger, setAppearTrigger] = useAppearTrigger();
  const { select } = useContext(Selection);

  return (
    <Table active={active}>
      <Header
        widget={widget}
        onLink={api.updateCellLink}
        onUpdate={api.updateCellValue}
      />
      <Table.Body>
        {widget.rows.map((row, rowIdx) => (
          <Table.Row key={row.ID} ord={rowIdx}>
            <RowControl
              api={api}
              widget={widget}
              row={row}
              nextRow={widget.rows[rowIdx + 1]}
              setAppearTrigger={setAppearTrigger}
            />
            <RangeLabel
              widget={widget}
              range={row}
              select={select}
              appearTrigger={appearTrigger}
              onUpdate={api.updateRow}
            />
            {row.cells.map((cell, colIdx) => (
              <BodyCell
                key={cell.ID}
                func={func}
                widget={widget}
                cell={cell}
                colIdx={colIdx}
                rowIdx={rowIdx}
                onLink={api.updateCellLink}
                onUpdate={api.updateCellValue}
              />
            ))}
          </Table.Row>
        ))}
        {/* <NewRow
          key="newRow"
          widget={widget}
          show={showNewRow}
          onAdd={(from, toIdx) =>
            dispatch({
              type: 'add-row',
              after: widget.rows.length - 1,
              from,
              toIdx,
            })
          }
        /> */}
      </Table.Body>
    </Table>
  );
}
