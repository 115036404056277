import React, { useRef, useState } from 'react';
import { formatCell } from '<sections>/functions/Cell/util';
import { FlexRowContainer } from '<src>/components/NumbrzPageComponents';
import { MapInputStyles, TableCellSeparator, InputLabel } from '../styles';

const MapInput = MapInputStyles.withComponent('input');

export default function UserValInput({ onKeyDown, value: valueProp }) {
  const [val, setVal] = useState(
    valueProp.inputOverride
      ? formatCell(valueProp.inputOverride, { ms: true })
      : undefined
  );
  const valRef = useRef();

  return (
    <FlexRowContainer alignItems="center" justifyContent="flex-start">
      <TableCellSeparator />
      {val && <InputLabel>Fixed value:</InputLabel>}
      <MapInput
        ref={valRef}
        value={val}
        placeholder="Enter value"
        onFocus={(e) => {
          e.target.placeholder = '';
        }}
        onBlur={(e) => {
          e.target.placeholder = 'Enter value';
        }}
        onChange={(e) => setVal(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && val) onKeyDown(val);
        }}
      />
    </FlexRowContainer>
  );
}
