import React from 'react';

import Dialog from '<components>/Dialog';
import InvoiceForm from '../InvoiceForm';
import Button from '<src>/components/Button';
import styled from '@emotion/styled';

const StyledDialog = styled(Dialog)`
  min-width: 300px;
  min-height: 300px;
  width: 50%;
`;

const StyledDialogHeadline = styled(Dialog.Headline)`
  text-transform: capitalize;
`;

const ChangePlanDialog = (props) => {
  const userAccountID = props.user.ID;
  const userFullName = props.user.display;

  const newPlanPrice = props.newPlan
    ? props.prices.find(
        (p) =>
          p.product === props.newPlan.id &&
          p.recurring.interval === props.paymentInterval
      )
    : undefined;

  // find a plan for alternate payment interval
  // if passed interval is monthly, then alternate is yearly and vice versa
  let alternateInterval = props.paymentInterval === 'month' ? 'year' : 'month';
  let alternatePrice;
  if (props.newPlan)
    alternatePrice = props.prices.find(
      (p) =>
        p.product === props.newPlan.id &&
        p.recurring.interval === alternateInterval
    );

  return (
    <StyledDialog onRequestClose={props.onClose} visible={props.visible}>
      <Dialog.Header>
        <StyledDialogHeadline>
          {props.createNewOrg
            ? 'Create New Team'
            : `${props.subscriptionUpdateMethod} Plan`}
        </StyledDialogHeadline>
        <Button.DialogClose onClick={() => props.onClose()} />
      </Dialog.Header>
      <Dialog.Body>
        <InvoiceForm
          featureFlags={props.featureFlags}
          stripeCustomer={props.stripeCustomer}
          userAccountID={userAccountID}
          userFullName={userFullName}
          primaryAccount={props.primaryAccount}
          plan={props.newPlan}
          price={newPlanPrice}
          alternateIntervalPrice={alternatePrice}
          createOrgAndAddMember={props.createOrgAndAddMember}
          createStripeCustomer={props.createStripeCustomer}
          createStripeSubscription={props.createStripeSubscription}
          setAccountToStripeProduct={props.setAccountToStripeProduct}
          changeStripeCustomerNumbrzAccount={
            props.changeStripeCustomerNumbrzAccount
          }
          history={props.history}
          cardPaymentMethods={props.customerCardPaymentMethods}
          defaultPaymentMethod={props.defaultPaymentMethod}
          needNewInvoice={props.needNewInvoice}
          onPlanChange={props.onPlanChange}
          isOrgSetup={props.isOrgSetup}
          createNewOrg={props.createNewOrg}
          onPlanChangeNavigate={props.onPlanChangeNavigate}
          onClose={props.onClose}
          subscriptionUpdateMethod={props.subscriptionUpdateMethod}
        />
      </Dialog.Body>
    </StyledDialog>
  );
};

export default ChangePlanDialog;
