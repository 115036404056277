import gql from 'graphql-tag';

export const SetAccountToStripeProduct = gql`
  mutation SetAccountToStripeProduct($input: SetStripeAccountInput!) {
    setAccountToStripeProduct(input: $input) {
      success
    }
  }
`;

export const ClearStripeCustomer = gql`
  mutation ClearStripeCustomer($accountID: CommonID!) {
    clearStripeCustomer(accountID: $accountID) {
      success
    }
  }
`;

export const GetAccount = gql`
  query GetAccount($ID: CommonID!) {
    account(ID: $ID) {
      ID
      name
      display
      features {
        name
        accountSetting
        defaultSetting
      }
      ... on User {
        externalID
        stripeProductID
        stripeCustomerID
        primaryAccount {
          ID
          display
        }
        membership {
          ID
          account {
            ID
            display
          }
          roles
        }
      }
      ... on Org {
        stripeProductID
        stripeCustomerID
        members {
          ID
          account {
            ID
            display
          }
          roles
        }
      }
    }
  }
`;

export const GetAccounts = gql`
  query GetAccounts {
    accounts {
      accounts {
        ID
        display
      }
    }
  }
`;

export const AddMemberOf = gql`
  mutation AddMemberOf($input: UpdateMemberOfInput!) {
    addMemberOf(input: $input) {
      success
    }
  }
`;

export const RemoveMemberOf = gql`
  mutation AddMemberOf($input: UpdateMemberOfInput!) {
    removeMemberOf(input: $input) {
      success
    }
  }
`;

export const AddAdmin = gql`
  mutation AddAdmin($input: UpdateMemberOfInput!) {
    addAdmin(input: $input) {
      success
    }
  }
`;

export const RemoveAdmin = gql`
  mutation AddAdmin($input: UpdateMemberOfInput!) {
    removeAdmin(input: $input) {
      success
    }
  }
`;

export const SetPrimaryAccount = gql`
  mutation SetPrimaryAccount($input: UpdateMemberOfInput!) {
    setPrimaryAccount(input: $input) {
      success
    }
  }
`;

export const DeleteAccount = gql`
  mutation DeleteAccount($ID: CommonID!) {
    deleteAccount(ID: $ID)
  }
`;

export const SetFeature = gql`
  mutation SetFeature(
    $accountID: CommonID!
    $feature: String!
    $setting: Boolean
  ) {
    setAccountFeature(
      input: { accountID: $accountID, feature: $feature, setting: $setting }
    ) {
      ID
      name
      display
      features {
        name
        accountSetting
        defaultSetting
      }
    }
  }
`;

export const CreateOrg = gql`
  mutation CreateOrg($input: CreateOrgInput!) {
    createOrg(input: $input)
  }
`;
