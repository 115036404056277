/** @jsx jsx */
/** @jsxRuntime classic */

import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { jsx } from '@emotion/react';

import { HashScroll } from 'react-hash-scroll';
import { useLocation } from 'react-router-dom';

import { NumbrzTooltip, FieldSrcTooltipBtn } from '<components>/NumbrzButtons';
import Description from '<src>/components/Description';
import Button from '<src>/components/Button';
import AddStepDialog from '../AddStepDialog';
import Step from '../Step';
import { Header, Body, Card } from '../Step/styles';
import TableDefDropdown from '../TableDefDropdown';
import ValidationIssues from '../ValidationIssues';

import {
  VerticalLine,
  TablePicker,
} from '<src>/components/NumbrzVerticalEditor';

const varsInputOpts = [
  {
    value: '__env__',
    text: 'Job Variables',
    triggerText: (
      <span>
        Job Variables{' '}
        <NumbrzTooltip
          wide
          hideOnScroll
          on="click"
          position="top center"
          content="The stage will process a single input record containing job variable values."
          trigger={<FieldSrcTooltipBtn name="info circle" />}
        />
      </span>
    ),
  },
];

function AddStep({ index, onAddStep }) {
  const onClick = useMemo(
    () => (onAddStep ? (e) => onAddStep(e, { index }) : null),
    [index, onAddStep]
  );
  return (
    <VerticalLine>
      {onClick ? <Button.AddStepBtn onClick={onClick} /> : null}
    </VerticalLine>
  );
}

function StageValidationIssues({ stage }) {
  const issues = useMemo(() => {
    if (stage.issues.find((issue) => issue.class === 'InputClass')) {
      return [
        {
          class: 'JobClass',
          code: 'InvalidInput',
          message: 'Stage Input has validation issues',
          args: [],
        },
        ...stage.issues,
      ];
    }
    return stage.issues;
  }, [stage.issues]);
  return (
    <ValidationIssues
      issues={issues}
      issueClass="JobClass"
      header="This stage has configuration errors"
      margin="0 0 30px 0"
    />
  );
}

function InputValidationIssues({ stage }) {
  const issues = useMemo(
    () => stage.issues.filter((i) => i.class === 'InputClass'),
    [stage.issues]
  );
  return (
    <ValidationIssues
      issues={issues}
      header="Stage input has configuration errors"
    />
  );
}

export default function StageEditor({
  api = {},
  baseURL,
  flow,
  functions,
  stage,
  dataTables,
  dataTablesLoading,
}) {
  const { onAddTable, onChange, onChangeInput, step: stepAPI = {} } = api;

  const [addStepAt, setAddStepAt] = useState(null);
  const [expandedSteps, setExpandedSteps] = useState(
    stage.steps.reduce((acc, step) => ({ ...acc, [step.key]: false }), {})
  );
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const key = location.hash.slice(1);
      setExpandedSteps((expSteps) => {
        if (expSteps[key] !== undefined) {
          return { ...expSteps, [key]: true };
        }
        return expSteps;
      });
    }
  }, [location.search, location.pathname, location.hash]);

  const onAddClicked = useMemo(
    () => (stepAPI.onAdd ? (e, { index }) => setAddStepAt(index) : null),
    [stepAPI.onAdd]
  );
  const closeDialog = useCallback(() => setAddStepAt(null), []);
  const onExpand = useCallback(
    (exp, key) => setExpandedSteps((expSteps) => ({ ...expSteps, [key]: exp })),
    []
  );

  const onAddInputTable = useMemo(() => {
    if (!onAddTable || !onChangeInput) {
      return null;
    }

    return async (e, table) => {
      const name =
        table.prefSource === 'WorkingTable'
          ? 'New Working Table'
          : 'New Input Table';
      const newTable = await onAddTable(e, { name, ...table });
      await onChangeInput(e, { tableID: newTable.ID });
      return newTable;
    };
  }, [onAddTable, onChangeInput]);

  const navProps = baseURL
    ? {
        baseURL,
        returnTo: {
          location: {
            pathname: `${baseURL}/stages/${stage.ID}`,
            hash: '#INPUT',
          },
        },
      }
    : {};

  let tableID = null;
  if (stage.input) {
    if (stage.input.mode === 'Env') {
      tableID = '__env__';
    } else {
      tableID = stage.input.tableID;
    }
  }

  return (
    <Fragment>
      <StageValidationIssues stage={stage} />
      <Description
        hideIfEmpty
        content={stage.description}
        onChange={(description) => onChange(null, { description })}
        placeholder="No description provided"
        editable={!!onChange && !stage.isIncluded}
        margin="0 0 10px 0"
      />

      <HashScroll hash="INPUT" position="start">
        <Card isInput={true} style={{ marginTop: 20 }}>
          <Header isInput={true}>
            <h5>Stage Input</h5>
          </Header>
          <Body>
            <InputValidationIssues stage={stage} />
            <TablePicker>
              <span>Source:</span>
              <TableDefDropdown
                rootOptions={varsInputOpts}
                disabled={!onChangeInput}
                {...navProps}
                tableDefs={flow.data}
                value={tableID}
                onChange={(e, { value }) =>
                  onChangeInput(e, {
                    tableID: value !== '__env__' ? value : null,
                  })
                }
                onAdd={onAddInputTable}
                dataTables={dataTables}
                dataTablesLoading={dataTablesLoading}
              />
            </TablePicker>
          </Body>
        </Card>
      </HashScroll>
      <AddStep index={0} onAddStep={onAddClicked} />
      {stage.steps.map((step, idx) => {
        const { elements } = step.inputSchema;

        return (
          <Fragment key={step.key}>
            <HashScroll hash={step.key} position="start">
              <Step
                api={stepAPI}
                baseURL={baseURL}
                expanded={expandedSteps[step.key]}
                flow={flow}
                stageElements={elements}
                first={idx === 0}
                functions={functions}
                last={idx === stage.steps.length - 1}
                stage={stage}
                step={step}
                onExpand={onExpand}
                dataTables={dataTables}
                dataTablesLoading={dataTablesLoading}
              />
            </HashScroll>
            <AddStep index={idx + 1} onAddStep={onAddClicked} />
          </Fragment>
        );
      })}

      {stepAPI.onAdd ? (
        <AddStepDialog
          atIndex={addStepAt}
          onRequestClose={closeDialog}
          onAddStep={(e, data) => {
            stepAPI.onAdd(e, data);
            closeDialog();
          }}
        />
      ) : null}
    </Fragment>
  );
}
