/* eslint-disable no-unused-vars */
import React, { forwardRef } from 'react';

import { stepsByType } from './stepData';
import GenericStep from './Step';

// register step components
import AggregateStep from './AggregateStep';
import CombineStep from './CombineStep';
import ExecStep from './ExecStep';
import FillStep from './FillStep';
import FilterStep from './FilterStep';
import GroupAndSortStep from './GroupAndSortStep';
import OutputStep from './OutputStep';
import SliceStep from './SliceStep';

export { GenericStep };

function StepWrapper({ step, ...props }, parentRef) {
  let Component = GenericStep;
  if (stepsByType[step.__typename] && stepsByType[step.__typename].Component) {
    Component = stepsByType[step.__typename].Component;
  }
  return <Component ref={parentRef} step={step} {...props} />;
}

// eslint-disable-next-line no-func-assign
StepWrapper = forwardRef(StepWrapper);
StepWrapper.displayName = 'StepWrapper';
export default StepWrapper;
