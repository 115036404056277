import React from 'react';
import styled from '@emotion/styled';

import { fieldPrefix } from '../../ValuePicker';

import FieldUnit from './FieldUnit';

const Style = styled('code')`
  font-size: 0.9em;
`;

export default function FormulaText({ elements, field }) {
  let amount = field.amount;
  if (amount.startsWith(fieldPrefix)) {
    amount = amount.substring(fieldPrefix.length);
    const elt = elements.find((e) => e.key === amount);
    if (elt) {
      amount = elt.label;
    }
  }

  const op = field.op === 'Increment' ? '+' : '-';

  if (
    field.__typename === 'NumberSequenceV2' &&
    field.numberUnit === 'Multiple'
  ) {
    amount = `(previous * ${amount})`;
  } else if (field.__typename === 'DateSequenceV2') {
    amount = (
      <span>
        ({amount}
        <FieldUnit field={field} />)
      </span>
    );
  }

  return (
    <Style>
      next = previous {op} {amount}
    </Style>
  );
}
