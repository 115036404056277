import React, { Fragment, useState, useContext } from 'react';
import fp from 'lodash/fp';
import orderBy from 'lodash/orderBy';

import { useHistory, useRouteMatch } from 'react-router-dom';
import DocumentTitle from 'react-document-title';

import { useMutation, useQuery } from '<src>/apollo/client';
import Masthead from '<components>/Masthead';
import MainLayout from '<components>/MainLayout';
import Sidebar from '<components>/MainLayout/Sidebar';
import { FlowContainer } from '<src>/components/NumbrzVerticalEditor';
import Button from '<src>/components/Button';
import {
  PageContainer,
  ColumnWrapper,
} from '<components>/NumbrzPageComponents';

import { DeleteTableset, GetTablesets } from '../../queries';

import AddTablesetDialog from './AddTablesetDialog';
import ResizeableSplitView from '<src>/sections/flows/flow-testing/ResizeableSplitView';
import DataWelcomeScreen from '<sections>/onboarding/welcome-screens/DataWelcomeScreen';
import { UserPreferencesContext } from '<components>/UserPreferences';
import VideoPopup from '<sections>/onboarding/components/VideoPopup';
import NumbrzEntityList from '<components>/NumbrzEntityList';

export default function TablesetListPage() {
  const history = useHistory();
  const match = useRouteMatch();
  const [videoPopupVisible, setVideoPopupVisible] = useState(false);
  const [activeVideo, setActiveVideo] = useState(null);

  const { onboardingPrefs, showHideWelcomeScreen } = useContext(
    UserPreferencesContext
  );
  const { welcomeScreens } = onboardingPrefs;
  const showWelcomeScreen = welcomeScreens.data;

  const {
    data: { tablesets: { tablesets = [] } } = { tablesets: [] },
    loading,
  } = useQuery(GetTablesets);

  const [deleteTableset] = useMutation(DeleteTableset, {
    update: (cache, { data: { deleteTableset: ID } }) => {
      const { tablesets: { tablesets = [] } = { tablesets: [] } } =
        cache.readQuery({ query: GetTablesets });
      const newTablesets = tablesets.filter((ts) => ts.ID !== ID);

      cache.writeQuery({
        query: GetTablesets,
        data: {
          tablesets: {
            __typename: 'GetTablesetsResult',
            tablesets: newTablesets,
          },
        },
      });
    },
  });

  const [addDialogVisible, setAddDialogVisible] = useState(false);

  const welcomeScreen = () => (
    <Fragment>
      <DataWelcomeScreen
        onHide={() => showHideWelcomeScreen('data', 'hide')}
        onVideoClick={(video) => {
          setActiveVideo(video);
          setVideoPopupVisible(true);
        }}
      />
      <VideoPopup
        onClose={() => {
          setVideoPopupVisible(false);
          setActiveVideo(null);
        }}
        visible={videoPopupVisible}
        video={activeVideo}
      />
    </Fragment>
  );

  const activeTab = fp.getOr(undefined, 'params.activeTab', match);
  if (activeTab) setAddDialogVisible(true);

  return (
    <DocumentTitle title="Data - Numbrz">
      <MainLayout
        overflowY="hidden"
        navigation={false}
        header={<Masthead />}
        main={
          <PageContainer>
            <ResizeableSplitView
              left={
                <Fragment>
                  <Sidebar hideMasthead />
                  <FlowContainer>
                    <ColumnWrapper>
                      {showWelcomeScreen &&
                      !loading &&
                      tablesets.length === 0 ? (
                        welcomeScreen()
                      ) : (
                        <NumbrzEntityList
                          loading={loading}
                          items={orderBy(tablesets, ['name'], ['asc'])}
                          entityType="data"
                          onDeleteItem={(ID) =>
                            deleteTableset({
                              variables: { ID },
                            })
                          }
                        />
                      )}

                      <Button.ListPageCreateBtn
                        onClick={() => setAddDialogVisible(true)}
                        title="Connect data"
                      >
                        Connect data
                        <span className="icon">+</span>
                      </Button.ListPageCreateBtn>
                      <AddTablesetDialog
                        openTableset={(tablesetID, src) =>
                          history.push(
                            `/data/${tablesetID}/${
                              src !== 'nbz' ? 'connected' : null
                            }`
                          )
                        }
                        visible={addDialogVisible}
                        {...fp.getOr({}, 'params', match)}
                        onDialogClose={() => setAddDialogVisible(false)}
                      />
                    </ColumnWrapper>
                  </FlowContainer>
                </Fragment>
              }
              right={null}
            />
          </PageContainer>
        }
      />
    </DocumentTitle>
  );
}
