import React, { useRef, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import * as colors from '<components>/colors';
import { StepDropdownWrapper } from '<src>/sections/flows/flow-testing/styles';
import { TextIconBtn, PopupMenu } from '../styles';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import { StyledScrollbar } from '<src>/components/NumbrzPageComponents';

const DropdownMenu = styled(PopupMenu)`
  padding: 0;
  min-height: 100px;
  min-width: 150px;
  max-height: 200px;
  overflow-y: auto;
  ${StyledScrollbar};
`;

const DropdownBtn = styled(TextIconBtn)`
  :hover {
    background: transparent;
  }
  ${(props) => props.hasValue && { color: colors.gray2b }};
`;

const DropdownItem = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  ${(props) =>
    !props.isLast && { borderBottom: `1px solid ${colors.gray_border}` }};
  svg {
    font-size: 17px;
    margin-left: 10px;
  }
  :hover {
    cursor: pointer;
    background: ${colors.yellow7};
  }
`;

export default function MappingDropdown({
  visible,
  setVisible,
  opts,
  value,
  onChange,
  syncCols,
  syncing,
  mapping,
  placeholder = 'Select',
}) {
  const menuRef = useRef();

  useEffect(() => {
    const menuEl = visible ? menuRef.current : undefined;
    if (menuEl) menuEl.focus();
  }, [visible]);

  const valObj = useMemo(
    () => opts.find((c) => c.value === value),
    [opts, value]
  );

  return (
    <StepDropdownWrapper>
      <DropdownBtn onClick={() => setVisible(!visible)} hasValue={valObj}>
        {valObj ? valObj.text : placeholder}
        <ArrowDropDownOutlinedIcon />
      </DropdownBtn>
      <DropdownMenu
        ref={menuRef}
        tabIndex="-1"
        onBlur={(e) => {
          setVisible(false);
        }}
        onKeyDown={(e) => {
          e.preventDefault();
          if (e.key === 'Escape') setVisible(false);
        }}
        visible={visible}
        minWidth="unset"
      >
        <DropdownItem
          isLast={false}
          onMouseDown={(e) => e.preventDefault()}
          onClick={(e) => {
            e.preventDefault();
            syncCols();
          }}
        >
          <b>{syncing ? 'Refreshing...' : 'Refresh column list'}</b>
          <CachedRoundedIcon />
        </DropdownItem>

        {opts.map((op, idx) => (
          <DropdownItem
            isLast={idx === opts.length - 1}
            key={idx}
            onClick={() => {
              onChange(op.value);
              setVisible(false);
            }}
          >
            {op.text}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </StepDropdownWrapper>
  );
}
