/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx } from '@emotion/react';
import { useState } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { AuthProvider, useAuth } from './auth';
import createApolloClient from './apollo';
import RegisterAccount, {
  RegisteredAccount,
} from '<components>/RegisterAccount';
import Routes from '<components>/Routes';
import ErrorBoundary from '<components>/ErrorBoundary';
import { Provider as FeaturesProvider } from '<components>/Feature';
import WaveSpinner from '<components>/WaveSpinner';
import HelpSidebar from '<sections>/help-utility';
import { HelpUtilityProvider } from '<sections>/help-utility/HelpUtilityContext';
import { UserPreferencesProvider } from './components/UserPreferences';
import IntercomMessenger from '<components>/IntercomMessenger';

import OAuthSection from '<sections>/oauth';

import { useIntercomNavUpdates, useVersionCheck } from '<src>/utils/hooks';

import SystemAlerts from './components/SystemAlerts';
import './App.css';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

function MainApp() {
  useIntercomNavUpdates();
  useVersionCheck();
  const [helpVisible = false, setHelpVisible] = useState();

  return (
    <FeaturesProvider>
      <UserPreferencesProvider>
        <HelpUtilityProvider value={[helpVisible, setHelpVisible]}>
          <DndProvider backend={HTML5Backend}>
            <HelpSidebar
              visible={helpVisible ? 1 : 0}
              onClose={() => setHelpVisible(false)}
            />
            <Routes />
          </DndProvider>
        </HelpUtilityProvider>
      </UserPreferencesProvider>
    </FeaturesProvider>
  );
}

function AuthedApp({ children }) {
  const { getToken, logout } = useAuth();
  const apolloClient = createApolloClient({ getToken, logout });
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}

function App({ apolloClient }) {
  return (
    <Router>
      <AuthProvider>
        <AuthedApp>
          <RegisterAccount>
            <IntercomMessenger>
              <RegisteredAccount.Consumer>
                {(user) => {
                  if (!user) {
                    return <WaveSpinner center={0} />;
                  }
                  return (
                    <div>
                      <SystemAlerts>
                        <ErrorBoundary>
                          {() => (
                            <Switch>
                              <Route path="/oauth">
                                <OAuthSection />
                              </Route>
                              <Route>
                                <MainApp />
                              </Route>
                            </Switch>
                          )}
                        </ErrorBoundary>
                      </SystemAlerts>
                    </div>
                  );
                }}
              </RegisteredAccount.Consumer>
            </IntercomMessenger>
          </RegisterAccount>
        </AuthedApp>
      </AuthProvider>
    </Router>
  );
}

export default hot(module)(App);
