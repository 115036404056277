import React, { PureComponent } from 'react';
import JobTablePicker from '<src>/sections/jobs/components/JobTablePicker';
import UsageChiclets from '<src>/sections/jobs/components/UsageChiclets';
import Button from '<src>/components/Button';

import Dialog from '<components>/Dialog';

import Pane from '../Pane';
import { getTableUsage } from '<src>/sections/jobs/utils';
import { FlexRowContainer } from '<src>/components/NumbrzPageComponents';
import { StyledDialog, StyledBody, ButtonBar, SectionHdr } from '../styles';

const toOpt = (table) => {
  const usageTypes = getTableUsage(table);

  return {
    ...table,
    value: table.ID,
    text: table.name,
    triggerText: (
      <FlexRowContainer justifyContent="flex-start">
        {table.name} {` `}
        <UsageChiclets
          usageTypes={usageTypes}
          mappingRequired={table.required}
          showInputs={true}
          showOutputs={true}
        />
      </FlexRowContainer>
    ),
  };
};

export default class DataPickerDialog extends PureComponent {
  static defaultProps = {
    initialSelection: null,
    onSelect: () => {},
    title: 'Select Data',
  };

  state = {
    searchTerm: '',
    searchPattern: null,
    selectedID: null,
    selectedFlowTableID: null,
  };

  componentDidMount() {
    this.setState({ selectedID: this.props.initialSelection });
  }

  componentDidUpdate(prevProps) {
    if (!this.props.visible && prevProps.visible) {
      this.setState({
        searchTerm: '',
        searchPattern: null,
        selectedID: this.props.initialSelection,
      });
    }
  }

  render() {
    const {
      loading,
      tables,
      flowTables,
      requireFlowTable,
      onClose,
      onSelect,
      visible,
      title,
      projectID,
      filterSettings,
      selectLabel = 'Select',
      dataMappings = [],
    } = this.props;
    const { selectedID, selectedFlowTableID } = this.state;

    const flowTableOpts = requireFlowTable
      ? flowTables.map((fT) => toOpt(fT))
      : [];

    return (
      <StyledDialog visible={visible ? 1 : 0} onRequestClose={onClose}>
        <Dialog.Header>
          <Dialog.Headline>{title}</Dialog.Headline>
          <Button.DialogClose onClick={onClose} />
        </Dialog.Header>
        {requireFlowTable && (
          <StyledBody>
            <SectionHdr>Step 1: Select model data</SectionHdr>
            <JobTablePicker
              tables={flowTableOpts}
              value={selectedFlowTableID}
              onChange={(e, { value }) =>
                this.setState({ selectedFlowTableID: value })
              }
            />
          </StyledBody>
        )}
        <StyledBody>
          {requireFlowTable && (
            <SectionHdr>Step 2: Select data source</SectionHdr>
          )}
          <Pane
            dialog
            loading={loading}
            tables={tables}
            dataMappings={dataMappings}
            filterSettings={filterSettings}
            selectedID={selectedID}
            projectID={projectID}
            onSelect={(selectedID) => this.setState({ selectedID })}
          />
        </StyledBody>
        <ButtonBar>
          <Button
            disabled={
              requireFlowTable
                ? !(selectedID && selectedFlowTableID)
                : !selectedID
            }
            onClick={() => onSelect(selectedID, selectedFlowTableID)}
          >
            {selectLabel}
          </Button>
        </ButtonBar>
      </StyledDialog>
    );
  }
}
