import { gql } from '@apollo/client';

import { FunctionChangesFragment } from './fragments';
import useEditFunction, { optimisticFunctionChanges } from './useEditFunction';

const UpdateCellTemplate = gql`
  mutation UpdateCellTemplate(
    $funcID: CommonID!
    $templateID: ID!
    $label: String
    $comment: String
    $coerceTo: CellType
    $defaultValue: Any
    $dataType: CellType
  ) {
    updateCellTemplateV3(
      funcID: $funcID
      templateID: $templateID
      label: $label
      coerceTo: $coerceTo
      comment: $comment
      defaultValue: $defaultValue
      dataType: $dataType
    ) {
      ...FunctionChangesFragment
    }
  }
  ${FunctionChangesFragment}
`;

export default function useUpdateCellTemplate() {
  const updateCellTemplate = useEditFunction(
    UpdateCellTemplate,
    'updateCellTemplateV3'
  );

  return (template, { label, comment, coerceTo, value, dataType }) => {
    return updateCellTemplate({
      variables: {
        funcID: template.funcID,
        templateID: template.ID,
        label,
        comment,
        coerceTo,
        defaultValue: value,
        dataType,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateCellTemplateV3: optimisticFunctionChanges({
          funcID: template.funcID,
          templatesChanged: [
            {
              __typename: 'CellTemplate',
              ...template,
              label: label !== undefined ? label : template.label,
              comment: comment !== undefined ? comment : template.comment,
              restrictTypes:
                coerceTo !== undefined ? [coerceTo] : template.restrictTypes,
              defaultValue: value !== undefined ? value : template.defaultValue,
              dataType: dataType !== undefined ? dataType : template.dataType,
            },
          ],
        }),
      },
    });
  };
}
