import React, { Fragment } from 'react';

import EditableMarkdown from '<components>/EditableMarkdown';
import ExternalResultURL from '<components>/ExternalResultURL';

export default function JobGroupOverview({ jobGroup = {}, onChange }) {
  return (
    <Fragment>
      <EditableMarkdown
        hideIfEmpty
        content={jobGroup.description}
        editable={!!onChange}
        onSave={(description) => onChange(null, { description })}
        placeholder="No description provided"
        noContainer
      />
      <ExternalResultURL
        entity="job group"
        externalResultURL={jobGroup.externalResultURL}
        onChange={(e, { externalResultURL }) =>
          onChange(e, { externalResultURL })
        }
      />
    </Fragment>
  );
}
