/** @jsx jsx */
/** @jsxRuntime classic */
import { useMemo, useCallback } from 'react';
import { jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { Dropdown } from 'semantic-ui-react';

import UnderlinedHeader from '<components>/UnderlinedHeader';
import Description from '<src>/components/Description';
import { RoundedContentWrapper } from '<src>/components/NumbrzVerticalEditor';
import Link from '<src>/components/Link';
import Button from '<src>/components/Button';
import { FlexRowContainer } from '<src>/components/NumbrzPageComponents';
import UpgradeAvailable from './UpgradeAvailable';

// need this for semantic-ui dropdown hidden content issue
const ContentWrapper = styled(RoundedContentWrapper)`
  overflow: visible;
`;

const StyledDropdown = styled(Dropdown)`
  padding-right: 12px;
`;

export default function ProjectOverview({
  jobs = [],
  project = {},
  updateProjectInfo,
  upgradeProject,
  handleRunProject,
}) {
  const handleDescriptionChange = useCallback(
    async (description) => {
      if (description !== project.description) {
        await updateProjectInfo({
          variables: {
            input: {
              projectID: project.ID,
              description,
            },
          },
        });
      }
    },
    [project.ID, updateProjectInfo, project.description]
  );

  const handlePrimaryJobBindingChange = useCallback(
    async (e, data) => {
      if (data.value !== project.primaryJobID)
        await updateProjectInfo({
          variables: {
            input: {
              projectID: project.ID,
              primaryJobBindingID: data.value,
            },
          },
        });
    },
    [updateProjectInfo, project.ID, project.primaryJobID]
  );

  const jobDropdownOptions = useMemo(() => {
    const defaultOps = { text: 'None', value: null, key: 'None' };

    const jobOptions = jobs.map((s) => ({
      text: s.name,
      value: s.ID,
      key: s.ID,
    }));

    return [defaultOps, ...jobOptions];
  }, [jobs]);

  const primaryJob = project.primaryJobBinding;

  return (
    <ContentWrapper>
      <UpgradeAvailable project={project} onUpgrade={upgradeProject} />
      <Description
        content={project.description}
        onChange={handleDescriptionChange}
        placeholder="No description provided"
        label="description"
        noContainer
        editable
      />
      <UnderlinedHeader justifyContent="space-between">
        PRIMARY JOB
        {primaryJob && primaryJob.externalResultURL && (
          <Link.ExternalResults
            href={primaryJob.externalResultURL}
            size="small"
          />
        )}
      </UnderlinedHeader>

      <FlexRowContainer alignItems="center" justifyContent="flex-start">
        <StyledDropdown
          closeOnBlur
          value={project.primaryJobID}
          onChange={handlePrimaryJobBindingChange}
          options={jobDropdownOptions}
          placeholder="Select Job"
        />
        {project.primaryJobID && (
          <Button.Run size="small" onClick={handleRunProject}>
            Run
          </Button.Run>
        )}
      </FlexRowContainer>
    </ContentWrapper>
  );
}
