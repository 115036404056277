import React, { useState } from 'react';
import fp from 'lodash/fp';
import { useQuery, useMutation } from '<src>/apollo/client';
import {
  BillingWrapper,
  Container,
  PageWrapper,
  UnderlineHeader,
} from '../Billing/styles';
import DeleteWithConfirm from '<components>/DeleteButtonWithConfirm';
import WaveSpinner from '<src>/components/WaveSpinner';
import { LabelButton, SquareChiclet } from '<components>/NumbrzButtons';
import InviteMemberDialog from './components/InviteMemberDialog';
import { Icon, Progress } from 'semantic-ui-react';
import styled from '@emotion/styled';
import * as colors from '<components>/colors';

import {
  GET_ORG,
  GET_ORG_INVITATIONS,
  INVITE_MEMBER,
  REMOVE_MEMBER,
  GetProductsAndPrices,
} from '../../queries';

const TeamContainer = styled('div')`
  display: flex;
  margin: 0;
  padding: 5px 0;
  justify-content: space-between;
  align-items: center;
  ${(props) => !props.last && `border-bottom: 1px solid ${colors.gray3};`};
  ${(props) => props.last && `margin-bottom: 20px`};
`;

const TeamInfoContainer = styled('div')`
  display: flex;
  align-items: baseline;
  i {
    font-size: 1.2em;
    margin-right: 10px;
  }
  span {
    margin-right: 10px;
  }
`;

const ButtonGroup = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledProgress = styled(Progress)`
  margin-top: 20px;
`;

export default function TeamMembers({ orgID, activePlanID, isUserAdmin }) {
  const { data: { products } = {}, loading: loadingProducts } =
    useQuery(GetProductsAndPrices);
  const { data: { account: org } = {}, loading } = useQuery(GET_ORG, {
    variables: { ID: orgID },
  });
  const {
    data: { orgInvitations } = {},
    loading: loadingInvitations,
    refetch: refetchInvitations,
  } = useQuery(GET_ORG_INVITATIONS, { variables: { ID: orgID } });
  const [inviteMember] = useMutation(INVITE_MEMBER);
  const [removeTeamMember] = useMutation(REMOVE_MEMBER, {
    update: (cache, { data: { removeMemberOf } }) => {
      const { account } = cache.readQuery({
        query: GET_ORG,
        variables: { ID: orgID },
      });
      cache.writeQuery({
        query: GET_ORG,
        variables: { ID: orgID },
        data: {
          account: {
            ...account,
            members: account.members.filter(
              (m) => m.account.ID !== removeMemberOf.memberID
            ),
          },
        },
      });
    },
  });

  const [showInviteDialog, setShowInviteDialog] = useState(false);
  if (loading || loadingProducts || loadingInvitations) return <WaveSpinner />;
  const activePlan = products.find((p) => p.id === activePlanID);

  const maxUsers = parseInt(fp.getOr(0, 'metadata.maxUsers', activePlan), 10);
  const currentUsers =
    fp.getOr([], 'members', org).length + orgInvitations.length;

  const handleRemoveMember = async (memberID, memberOf) => {
    await removeTeamMember({
      variables: {
        input: {
          memberID,
          memberOf,
        },
      },
    });
  };

  const handleInviteMember = async (data) => {
    await inviteMember(data);
    refetchInvitations();
  };

  return (
    <PageWrapper>
      <UnderlineHeader style={{ height: '49px' }}>
        <span />
        <LabelButton onClick={() => setShowInviteDialog(true)}>
          Invite Member
        </LabelButton>
      </UnderlineHeader>
      <BillingWrapper>
        <Container>
          <h2>Active Members</h2>
          {org.members.map((m, idx) => (
            <TeamContainer key={m.ID} last={idx === org.members.length - 1}>
              <TeamInfoContainer>
                <Icon name="user outline" />
                <span>{m.account.display}</span>
                {m.roles.includes('Administrator') && (
                  <SquareChiclet>Administrator</SquareChiclet>
                )}
              </TeamInfoContainer>
              <ButtonGroup>
                {!m.roles.includes('Administrator') && isUserAdmin && (
                  <DeleteWithConfirm
                    onClick={() => handleRemoveMember(m.account.ID, org.ID)}
                  />
                )}
              </ButtonGroup>
            </TeamContainer>
          ))}

          <h2>Pending Invitations</h2>
          {orgInvitations.length === 0 && <p>No pending invitations found.</p>}

          {orgInvitations &&
            orgInvitations.map((invite, idx) => (
              <TeamContainer
                key={invite.ID}
                last={idx === orgInvitations.length - 1}
              >
                <TeamInfoContainer>
                  <Icon name="envelope outline" />
                  <span>{invite.email}</span>
                </TeamInfoContainer>
              </TeamContainer>
            ))}
          <StyledProgress
            value={currentUsers}
            total={maxUsers}
            progress="ratio"
          />
        </Container>
      </BillingWrapper>
      <InviteMemberDialog
        visible={showInviteDialog}
        onClose={() => setShowInviteDialog(false)}
        memberOf={org.ID}
        inviteMember={handleInviteMember}
      />
    </PageWrapper>
  );
}
