import React, { forwardRef, useMemo } from 'react';

import shortid from '<src>/utils/shortid';

import { stepsByType } from '../stepData';
import Step from '../Step';

import CalculatedFields from './CalculatedFields';
import SortFields from './SortFields';

function GroupAndSortStep(
  { step, api, stageElements: elements = [], ...props },
  parentRef
) {
  const { onChangeGroupAndSort } = api;
  const { extFields, group, sort } = step;

  const onChange = useMemo(
    () =>
      onChangeGroupAndSort
        ? (e, changes) => onChangeGroupAndSort(e, { key: step.key, ...changes })
        : null,
    [onChangeGroupAndSort, step.key]
  );

  const [onAddGroupBy, onChangeGroupBy, onRemoveGroupKey] = useMemo(() => {
    if (!onChange) {
      return [null, null];
    }
    const onAddGroupBy = (e, key, sortDir = 'Ascending') => {
      const idx = group.findIndex((g) => g.fromElementKey === key);
      if (idx === -1) {
        onChange(e, { group: [...group, { fromElementKey: key, sortDir }] });
      } else {
        onChange(e, {
          group: [
            ...group.slice(0, idx),
            { fromElementKey: key, sortDir },
            ...group.slice(idx + 1),
          ],
        });
      }
    };
    const onChangeGroupBy = (e, key, sortDir) =>
      onChange(e, {
        group: group.map((g) => {
          if (g.fromElementKey === key) {
            return { ...g, sortDir };
          }
          return g;
        }),
      });
    const onRemoveGroupKey = (e, key) => {
      if (group.findIndex((g) => g.fromElementKey === key) !== -1) {
        onChange(e, {
          group: group.filter((g) => g.fromElementKey !== key),
        });
      }
    };
    return [onAddGroupBy, onChangeGroupBy, onRemoveGroupKey];
  }, [group, onChange]);

  const [onAddSortBy, onChangeSortBy, onRemoveSortKey] = useMemo(() => {
    if (!onChange) {
      return [null, null];
    }
    const onAddSortBy = (e, key, sortDir = 'Ascending') => {
      const idx = sort.findIndex((g) => g.fromElementKey === key);
      if (idx === -1) {
        onChange(e, { sort: [...sort, { fromElementKey: key, sortDir }] });
      } else {
        onChange(e, {
          sort: [
            ...sort.slice(0, idx),
            { fromElementKey: key, sortDir },
            ...sort.slice(idx + 1),
          ],
        });
      }
    };
    const onChangeSortBy = (e, key, sortDir) =>
      onChange(e, {
        sort: sort.map((g) => {
          if (g.fromElementKey === key) {
            return { ...g, sortDir };
          }
          return g;
        }),
      });
    const onRemoveSortKey = (e, key) => {
      if (sort.findIndex((g) => g.fromElementKey === key) !== -1) {
        onChange(e, {
          sort: sort.filter((g) => g.fromElementKey !== key),
        });
      }
    };
    return [onAddSortBy, onChangeSortBy, onRemoveSortKey];
  }, [sort, onChange]);

  const [onAddExtField, onRemoveExtField, onChangeExtFieldLabel] =
    useMemo(() => {
      if (!onChange) {
        return [null, null, null];
      }

      const onAddExtField = (e, { label, ...field }) => {
        return onChange(e, {
          extFields: [
            ...extFields,
            {
              toElement: {
                key: shortid(),
                type: field.type === 'Date' ? 'DateTime' : field.type,
                label,
              },
              ...field,
            },
          ],
        });
      };

      const onRemoveExtField = (e, field) =>
        onChange(e, {
          extFields: extFields.filter(
            (f) => f.toElement.key !== field.toElement.key
          ),
        });

      const onChangeExtFieldLabel = (e, { key, label }) =>
        onChange(e, {
          extFields: extFields.map((field) => {
            if (field.toElement.key !== key) {
              return field;
            }
            return {
              ...field,
              toElement: {
                ...field.toElement,
                label,
              },
            };
          }),
        });

      return [onAddExtField, onRemoveExtField, onChangeExtFieldLabel];
    }, [onChange, extFields]);

  return (
    <Step ref={parentRef} step={step} api={api} {...props}>
      <div />
      <div>
        <h5>Group By</h5>
        <SortFields
          addTitle="Add Group By"
          elements={elements}
          fields={group}
          onRemove={onRemoveGroupKey}
          onAdd={onAddGroupBy}
          onChange={onChangeGroupBy}
        />
        <h5>Sort By</h5>
        <SortFields
          addTitle="Add Sort By"
          elements={elements}
          fields={sort}
          onRemove={onRemoveSortKey}
          onAdd={onAddSortBy}
          onChange={onChangeSortBy}
        />
        <h5>Calculated Fields</h5>
        <CalculatedFields
          elements={elements}
          fields={extFields}
          onAdd={onAddExtField}
          onRemove={onRemoveExtField}
          onChangeFieldLabel={onChangeExtFieldLabel}
        />
      </div>
    </Step>
  );
}

// eslint-disable-next-line no-func-assign
GroupAndSortStep = forwardRef(GroupAndSortStep);
GroupAndSortStep.displayName = 'GroupAndSortStep';
export default GroupAndSortStep;

// Avoid circular dependency
stepsByType.GroupAndSortStepV2.Component = GroupAndSortStep;
