import { gql } from '@apollo/client';

import { FunctionChangesFragment } from './fragments';
import useEditFunction, { optimisticFunctionChanges } from './useEditFunction';

const UpdateWidgetColumn = gql`
  mutation UpdateWidgetColumn(
    $funcID: CommonID!
    $widgetID: ID!
    $columnID: ID!
    $label: String
    $comment: String
    $dataType: CellType
  ) {
    updateWidgetColumnV3(
      funcID: $funcID
      widgetID: $widgetID
      columnID: $columnID
      label: $label
      comment: $comment
      dataType: $dataType
    ) {
      ...FunctionChangesFragment
    }
  }

  ${FunctionChangesFragment}
`;

export default function useUpdateWidgetColumn() {
  const updateColumn = useEditFunction(
    UpdateWidgetColumn,
    'updateWidgetColumnV3'
  );
  return (widget, column, { label, comment, dataType }) =>
    updateColumn({
      variables: {
        funcID: widget.funcID,
        widgetID: widget.ID,
        columnID: column.ID,
        label,
        comment,
        dataType,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateWidgetColumnV3: optimisticFunctionChanges({
          funcID: widget.funcID,
          templatesChanged: [
            {
              __typename: 'CellTemplate',
              ...column.normTemplate,
              comment:
                comment !== undefined ? comment : column.normTemplate.comment,
              dataType: dataType ? dataType : column.normTemplate.dataType,
              label: label !== undefined ? label : column.normTemplate.label,
            },
          ],
        }),
      },
    });
}
