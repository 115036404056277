import React, { Fragment, useState, useMemo } from 'react';
import fp from 'lodash/fp';

import DocumentTitle from 'react-document-title';
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import MainLayout from '<components>/MainLayout';
import Masthead from '<components>/Masthead';
import WaveSpinner from '<components>/WaveSpinner';
import ConnectedTables from './ConnectedTables';
import TablesetOverview from './TablesetOverview';
import useTablesetState from './api/useTablesetState';
import ConnectTablesDialog from '../TablesetList/ConnectTablesDialog';
import ResizeableSplitView from '<src>/sections/flows/flow-testing/ResizeableSplitView';
import {
  SidebarWrapper,
  SidebarHeader,
  SidebarContent,
  FlowContainer,
} from '<src>/components/NumbrzVerticalEditor';
import Link from '<src>/components/Link';
import Button from '<src>/components/Button';
import { tableLabel, tablesetLabel, getTablesetNavItems } from './util';
import { formatDataSourceLabel } from '<sections>/data/utils';
import { TitleField } from '<src>/sections/flows/styles';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';

import SidebarNavMenu from '<components>/NumbrzMenus/SidebarNavMenu';
import {
  CardSubhdr,
  ColumnWrapper,
  FlexRowContainer,
  PageMasthead,
  Separator,
} from '<src>/components/NumbrzPageComponents';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';

function TablesetBody({ tablesetState, loading }) {
  const [connectDialogVisible, setConnectDialogVisible] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const match = useRouteMatch();
  const {
    tableset,
    onTablesetFieldChange,
    tablesetType,
    credentials,
    loadingCredentials,
    extTableErr,
    tables,
    loadingTables,
    setActiveTableID,
    tableAPI,
  } = tablesetState;

  const { ID, source } = tableset;
  const { onUpdate } = tableAPI;

  const { name } = tableset;
  const sourceURL =
    fp.getOr(undefined, 'source.shareURL', tableset) ||
    fp.getOr(undefined, 'source.url', tableset);

  const SidebarToggleBtn = sidebarVisible
    ? Button.SidebarHideBtn
    : Button.SidebarShowBtn;

  const TablesetTypeSubhdr = useMemo(
    () => (
      <CardSubhdr style={{ width: 'unset', fontWeight: 'normal' }}>
        {formatDataSourceLabel(
          tableset.source?.__typename,
          tableset.connector?.name
        )}
      </CardSubhdr>
    ),
    [tableset.source?.__typename, tableset.connector?.name]
  );

  return (
    <ResizeableSplitView
      right={null}
      left={
        <Fragment>
          <SidebarWrapper visible={sidebarVisible}>
            <SidebarHeader
              justifyContent="space-between"
              padding="0 5px 0 15px"
            >
              <span />

              {sidebarVisible && (
                <SidebarToggleBtn
                  title="Hide navigation"
                  onClick={() => setSidebarVisible(!sidebarVisible)}
                />
              )}
            </SidebarHeader>
            <SidebarContent>
              <Switch>
                <Route exact path={`${match.url}/tables/:tableID`}>
                  {({ match: innerMatch }) => {
                    return (
                      <SidebarNavMenu
                        options={[
                          {
                            text: 'Details',
                            to: `${match.url}`,
                            key: 'details',
                          },
                        ]}
                      />
                    );
                  }}
                </Route>
                <Route path={[`${match.url}/tables`, `${match.url}/settings`]}>
                  {() => (
                    <SidebarNavMenu
                      options={getTablesetNavItems(source, match.url)}
                    />
                  )}
                </Route>
              </Switch>
            </SidebarContent>
          </SidebarWrapper>
          <FlowContainer>
            <PageMasthead justifyContent="flex-start">
              {!sidebarVisible && (
                <SidebarToggleBtn
                  title="Show navigation"
                  onClick={() => setSidebarVisible(!sidebarVisible)}
                />
              )}
              <Switch>
                <Route exact path={`${match.url}/tables/:tableID`}>
                  {({ match: innerMatch, history }) => {
                    const selectedTable = tables.find(
                      (table) =>
                        table.ID ===
                        decodeURIComponent(innerMatch.params.tableID)
                    );

                    if (selectedTable) {
                      if (selectedTable.ID) setActiveTableID(selectedTable.ID);
                    }

                    const isGoogle = source.__typename === 'GoogleSpreadsheet';
                    const isExcel = source.__typename === 'ExcelSpreadsheet';
                    const tableSourceURL = isGoogle
                      ? selectedTable && selectedTable.sourceURL
                      : isExcel
                        ? sourceURL
                        : undefined;

                    return (
                      selectedTable && (
                        <FlexRowContainer
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <h5>
                            <TitleField
                              placeholder="Click to edit"
                              singleClick
                              value={selectedTable.name}
                              onSubmit={(e) => {
                                onUpdate('name', e.value, selectedTable);
                                history.replace({
                                  search: '',
                                });
                              }}
                            />
                            <Separator />
                            {TablesetTypeSubhdr}
                          </h5>
                          {(isGoogle || isExcel) && tableSourceURL && (
                            <Link
                              blue={true}
                              fontWeight="normal"
                              icon={<LaunchOutlinedIcon />}
                              href={tableSourceURL}
                            >
                              Source
                            </Link>
                          )}
                        </FlexRowContainer>
                      )
                    );
                  }}
                </Route>
                <Route path={`${match.url}/tables`}>
                  {() =>
                    loading || loadingTables ? null : (
                      <FlexRowContainer
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <h5>
                          <TitleField
                            ref={undefined}
                            placeholder="Click to edit"
                            singleClick
                            value={name}
                            onSubmit={(e) =>
                              onTablesetFieldChange('name', e.value)
                            }
                          />
                          <Separator />
                          {TablesetTypeSubhdr}
                        </h5>
                        {sourceURL && (
                          <Link
                            fontWeight="normal"
                            href={sourceURL}
                            label={tablesetLabel(tableset.source)}
                            icon={<OpenInNewRoundedIcon />}
                            iconPosition="right"
                          >
                            Source
                          </Link>
                        )}
                      </FlexRowContainer>
                    )
                  }
                </Route>

                <Route path={`${match.url}/settings`}>
                  {() => {
                    return (
                      <FlexRowContainer
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <h5>
                          <TitleField
                            ref={undefined}
                            placeholder="Click to edit"
                            singleClick
                            value={name}
                            onSubmit={(e) =>
                              onTablesetFieldChange('name', e.value)
                            }
                          />
                          <Separator />
                          {TablesetTypeSubhdr}
                        </h5>
                        {sourceURL && (
                          <Link
                            fontWeight="normal"
                            href={sourceURL}
                            label={tablesetLabel(tableset.source)}
                            icon={<OpenInNewRoundedIcon />}
                            iconPosition="right"
                          >
                            Source
                          </Link>
                        )}
                      </FlexRowContainer>
                    );
                  }}
                </Route>
              </Switch>
            </PageMasthead>
            <ColumnWrapper padding="3em 4em">
              <Switch>
                <Route path={`${match.url}/settings`}>
                  {(props) => (
                    <TablesetOverview
                      tablesetSource={source}
                      tableset={tableset}
                      sourceType={tablesetType}
                      selectedNavItem="settings"
                      handleFieldChange={onTablesetFieldChange}
                      credentials={credentials}
                      loadingCredentials={loadingCredentials}
                      extTableErr={extTableErr}
                      {...props}
                    />
                  )}
                </Route>
                <Route path={`${match.url}/tables`}>
                  {(props) => (
                    <ConnectedTables
                      tablesetSource={source}
                      tablesetID={ID}
                      selectedNavItem="tables"
                      tablesetState={tablesetState}
                      setConnectDialogVisible={setConnectDialogVisible}
                      handleFieldChange={onTablesetFieldChange}
                      {...props}
                    />
                  )}
                </Route>

                <Route>{() => <Redirect to={`${match.url}/tables`} />}</Route>
              </Switch>
            </ColumnWrapper>
          </FlowContainer>
          <ConnectTablesDialog
            tablesetState={tablesetState}
            visible={connectDialogVisible}
            onClose={() => setConnectDialogVisible(false)}
          />
        </Fragment>
      }
    ></ResizeableSplitView>
  );
}

const Title = ({ title, breadcrumbs = [] }) => (
  <DocumentTitle title={`${title} - Numbrz`}>
    <Masthead title={title} breadcrumbs={breadcrumbs} addShadow />
  </DocumentTitle>
);

export default function TablesetPage() {
  const params = useParams();
  const match = useRouteMatch();

  const tablesetState = useTablesetState({
    tablesetID: params.tablesetID,
  });
  const {
    tableset,
    loadingTableset,
    tables = [],
    loadingTables,
  } = tablesetState;

  const tablesHeader = loadingTableset
    ? 'Data'
    : tableLabel(tableset.source, {
        plural: true,
        titleCase: true,
      });

  const header = useMemo(() => {
    const name = !loadingTableset && tableset.name ? tableset.name : 'Data';

    const breadcrumbs = loadingTableset
      ? []
      : [
          { title: 'Data', path: '/data', type: 'root' },
          {
            title: name,
            path: `data/${loadingTableset ? '' : tableset.ID}`,
          },
        ];
    return (
      <Switch>
        <Route path={`${match.url}/tables/:tableID`}>
          {({ match }) => {
            const tableID = fp.getOr(undefined, 'params.tableID', match);

            const table = tables.find(
              (table) => table.ID === decodeURIComponent(tableID)
            );

            const innerBrdcrumbs = [
              ...breadcrumbs,
              {
                title: tablesHeader,
                path: `data/${tableset.ID}/tables`,
              },
            ];

            return (
              <Title
                title={loadingTables ? 'Loading...' : table && table.name}
                breadcrumbs={innerBrdcrumbs}
              />
            );
          }}
        </Route>
        <Route path={`${match.url}/settings`}>
          <Title title="Settings" breadcrumbs={breadcrumbs} />
        </Route>
        <Route path={`${match.url}/tables`}>
          <Title title={tablesHeader} breadcrumbs={breadcrumbs} />
        </Route>
      </Switch>
    );
  }, [
    tablesHeader,
    match.url,
    loadingTableset,
    tableset.ID,
    tableset.name,
    tables,
    loadingTables,
  ]);

  return (
    <MainLayout
      navigation={false}
      overflowY={'hidden'}
      header={header}
      main={
        loadingTableset ? (
          <WaveSpinner />
        ) : (
          <TablesetBody
            tablesetState={tablesetState}
            loading={loadingTableset}
          />
        )
      }
    />
  );
}
