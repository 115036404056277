import React, { useCallback } from 'react';
import fp from 'lodash/fp';
import { useHistory } from 'react-router-dom';

import { useMutation } from '<src>/apollo/client';

import AddNameDialog from '<src>/components/AddNameDialog';

import { CreateFlowV2 } from '../../../queries';

export default function AddFlowDialog({ projectID, visible, onDialogClose }) {
  const history = useHistory();
  const [createFlowV2] = useMutation(CreateFlowV2);

  const doCreate = useCallback(
    async (name) => {
      const res = await createFlowV2({
        variables: {
          input: {
            name: name,
            containerID: projectID,
          },
        },
      });

      const flow = fp.getOr(undefined, 'data.createFlow.flow', res);
      if (flow) history.push(`/models/${projectID}/flows/${flow.ID}`);
    },
    [createFlowV2, history, projectID]
  );

  return (
    <AddNameDialog
      objType="Flow"
      visible={visible}
      onClose={onDialogClose}
      onCreate={doCreate}
    />
  );
}
