import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import Button from '<components>/Button';
import Dialog from '<components>/Dialog';
import TextInput from '<components>/TextInput';

const StyledTextInput = styled(TextInput)`
  margin-bottom: 1em;
  width: 100%;
  min-width: 400px;
`;

const StyledFooter = styled(Dialog.Footer)`
  border-top: none;
`;

export default function AddNameDialog({ objType, visible, onClose, onCreate }) {
  const [name, setName] = useState(`New ${objType}`);
  const [creating, setCreating] = useState(false);
  const inputRef = useRef();

  useEffect(() => setName(`New ${objType}`), [objType]);
  useEffect(() => {
    if (!visible) {
      setCreating(false);
      setName(`New ${objType}`);
    } else {
      setTimeout(() => {
        inputRef.current.focus();
        inputRef.current.select();
      }, 0);
    }
  }, [objType, visible]);

  const isValid = () => name && name.trim();

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (isValid()) return handleSubmit(e);
    }
  };

  const handleSubmit = async (e) => {
    if (!isValid()) return;

    if (isValid()) {
      setCreating(true);
      await onCreate(name.trim(), e);
      onClose();
    }
  };

  return (
    <Dialog visible={!!visible} onRequestClose={onClose}>
      <Dialog.Header>
        <Dialog.Headline>Create a {objType}</Dialog.Headline>
        <Button.DialogClose onClick={onClose} />
      </Dialog.Header>
      <Dialog.Body>
        <StyledTextInput
          ref={inputRef}
          placeholder={`${objType} name (Required)`}
          name="name"
          onChange={({ target: { name, value } }) => setName(value)}
          disabled={creating}
          onKeyDown={handleKeyDown}
          value={name}
        />
      </Dialog.Body>
      <StyledFooter>
        <Button
          id="create"
          onClick={handleSubmit}
          disabled={creating || !isValid()}
        >
          {creating ? 'Creating...' : `Create`}
        </Button>
      </StyledFooter>
    </Dialog>
  );
}
