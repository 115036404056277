import React, { useCallback, useState } from 'react';
import { useOAuth } from '<sections>/oauth';

export default function ExcelSpreadsheetPane({
  children: renderBody,
  createCredential,
  credential,
}) {
  const [creating, setCreating] = useState(false);

  const createNewCredential = useCallback(
    async ({ code, scope, redirectURL } = {}) => {
      setCreating(true);

      await createCredential({
        variables: {
          input: {
            ...(!credential && { name: `Microsoft` }),
            ...(credential && { ID: credential.ID }),
            provider: {
              providerName: 'microsoft',
              credName: 'oauthCode',
              credParams: {
                code,
                scope,
                redirectURL,
              },
            },
          },
        },
      });
      setCreating(false);
    },
    [createCredential, credential]
  );

  const [doOAuth, popupVisible] = useOAuth('microsoft', createNewCredential);

  const body = <></>;

  return renderBody(body, doOAuth, popupVisible || creating);
}
