import { gql } from '@apollo/client';

export const CellFormatFragment = gql`
  fragment CellFormatFragment on CellFormat {
    number {
      type
      decimals
    }
  }
`;

export const CellTemplateFragment = gql`
  fragment CellTemplateFragment on CellTemplate {
    ID
    funcID
    correlationID
    label
    comment
    dataType
    defaultValue
    defaultFormat {
      ...CellFormatFragment
    }
    section
    link {
      sourceID
      sourceType
    }
    restrictTypes
    status
  }
  ${CellFormatFragment}
`;

export const CellFragment = gql`
  fragment CellFragment on CellV3 {
    ID
    funcID
    widgetID
    correlationID
    dataType
    coerceTo
    value
    isConfig
    link {
      sourceID
      sourceType
    }
    format {
      ...CellFormatFragment
    }
    status
    layoutTemplateID
    template @client {
      ...CellTemplateFragment
    }
  }
  ${CellTemplateFragment}
`;

export const CellRangeFragment = gql`
  fragment CellRangeFragment on CellRange {
    ID
    funcID
    correlationID
    cellIDs
    templateID
    template {
      ...CellTemplateFragment
    }
    normTemplate @client {
      ...CellTemplateFragment
    }
    cells @client {
      ...CellFragment
    }
  }
`;

export const FunctionDataFragment = gql`
  fragment FunctionDataFragment on FunctionData {
    ID
    funcID
    name
    columns {
      ...CellTemplateFragment
    }
  }
`;

export const WidgetFragment = gql`
  fragment WidgetFragment on WidgetV3 {
    ID
    funcID
    correlationID
    name
    rows {
      ...CellRangeFragment
    }
    columns {
      ...CellRangeFragment
    }
    config {
      type
      ... on DataV3Config {
        tableID
      }
      ... on ArithmeticV3Config {
        op
        showTotals
        totals {
          ...CellRangeFragment
        }
      }
      ... on JoinV3Config {
        delim
        omitEmpty
      }
      ... on LookupV3Config {
        funcID
        lookupTableID
        lookupTable @client {
          ...FunctionDataFragment
        }
        columns {
          ID
          lookupColumnID
          mode
          correlationID
        }
      }
      ... on SwitchV3Config {
        funcID
        defaultOutputID
        defaultOutputLabel
        defaultOutput @client {
          ...CellFragment
        }
        inputs {
          ...CellTemplateFragment
        }
        output {
          ...CellTemplateFragment
        }
        rules {
          ID
          correlationID
          funcID
          label
          conditions {
            ID
            correlationID
            funcID
            inputID
            op
            testValueID
            testValue @client {
              ...CellFragment
            }
          }
          outputValueID
          outputValue @client {
            ...CellFragment
          }
        }
      }
    }
  }
  ${CellTemplateFragment}
  ${CellFragment}
  ${CellRangeFragment}
  ${FunctionDataFragment}
`;

export const FunctionChangesFragment = gql`
  fragment FunctionChangesFragment on FunctionChanges {
    funcID
    cellsAdded {
      ...CellFragment
    }
    cellsChanged {
      ...CellFragment
    }
    cellsDeleted
    dataAdded {
      ...FunctionDataFragment
    }
    dataChanged {
      ...FunctionDataFragment
    }
    dataDeleted
    funcChanged {
      ID
      name
      description
      inputs {
        ...CellTemplateFragment
      }
      outputs {
        ...CellTemplateFragment
      }
    }
    templatesChanged {
      ...CellTemplateFragment
    }
    widgetsAdded {
      ...WidgetFragment
    }
    widgetsChanged {
      ...WidgetFragment
    }
    widgetsDeleted
  }
  ${CellTemplateFragment}
  ${CellFragment}
  ${FunctionDataFragment}
  ${WidgetFragment}
`;
