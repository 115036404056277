import React, { useEffect } from 'react';
import { Icon } from 'semantic-ui-react';
import { formatISODateString } from '<src>/utils/dates';

import { RoundedContentWrapper } from '<src>/components/NumbrzVerticalEditor';
import { EmptyListContainer } from '<src>/components/NumbrzPageComponents';
import WaveSpinner from '<src>/components/WaveSpinner';
import { getStatusChiclet } from '../utils';
import { StatusTable, DetailsBtn } from '<src>/sections/jobs/styles';

export default function JobGroupHistoryPage({
  baseURL,
  jobGroupRunState,
  jobGroupRunning,
  history,
}) {
  const {
    runHistory,
    loadRunHistory,
    loadingRunHistory,
    startRunHistoryPolling,
    stopRunHistoryPolling,
    calledRunHistory,
    calledStatus,
  } = jobGroupRunState;

  useEffect(() => {
    if (
      (!calledRunHistory && !loadingRunHistory) ||
      (calledStatus && runHistory.length === 0 && !loadingRunHistory)
    )
      loadRunHistory();

    jobGroupRunning ? startRunHistoryPolling(10000) : stopRunHistoryPolling();

    return () => {
      stopRunHistoryPolling();
    };
  }, [
    runHistory,
    loadingRunHistory,
    jobGroupRunning,
    calledRunHistory,
    calledStatus,
    loadRunHistory,
    startRunHistoryPolling,
    stopRunHistoryPolling,
  ]);

  return loadingRunHistory ? (
    <WaveSpinner />
  ) : !loadingRunHistory && (!runHistory || runHistory.length === 0) ? (
    <EmptyListContainer>
      <h4>No run history available </h4>
    </EmptyListContainer>
  ) : (
    runHistory.length > 0 && (
      <RoundedContentWrapper
        margin="0 0 15px 0"
        maxWidth="1000px"
        height="auto"
        hideOverflow
      >
        <StatusTable style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Status</th>
              <th>Run By</th>
              <th>Date</th>
              <th className="centered">Details</th>
            </tr>
          </thead>
          <tbody>
            {runHistory.map((item, idx) => {
              const timestamp = formatISODateString(item.lastActiveTime, true);

              return (
                <tr key={idx}>
                  <td>{getStatusChiclet(item.state)}</td>
                  <td>
                    {item.userName && (
                      <div>
                        <Icon name="user outline" />
                        <span>{item.userName}</span>
                      </div>
                    )}
                  </td>
                  <td>
                    <div>
                      <Icon name="clock outline" />
                      <span>{timestamp}</span>
                    </div>
                  </td>
                  <td className="centered">
                    <DetailsBtn
                      onClick={() =>
                        history.push(`${baseURL}/status/${item.ID}`)
                      }
                    >
                      <Icon name="list alternate outline" />
                    </DetailsBtn>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </StatusTable>
      </RoundedContentWrapper>
    )
  );
}
