import { gql } from '@apollo/client';

import { FunctionChangesFragment } from './fragments';
import useEditFunction from './useEditFunction';

const DeleteWidgetColumn = gql`
  mutation DeleteWidgetColumn(
    $funcID: CommonID!
    $widgetID: ID!
    $columnID: ID!
  ) {
    deleteWidgetColumnV3(
      funcID: $funcID
      widgetID: $widgetID
      columnID: $columnID
    ) {
      ...FunctionChangesFragment
    }
  }

  ${FunctionChangesFragment}
`;

export default function useDeleteWidgetColumn() {
  const deleteColumn = useEditFunction(
    DeleteWidgetColumn,
    'deleteWidgetColumnV3'
  );
  return (widget, column) =>
    deleteColumn({
      variables: {
        funcID: widget.funcID,
        widgetID: widget.ID,
        columnID: column.ID,
      },
    });
}
