/**
 * reference: https://gist.github.com/vincentriemer/25e04d9b81c217b0ec98950eb06bc10c
 */

import React, { PureComponent } from 'react';
import T from 'prop-types';
import invariant from 'invariant';
import fp from 'lodash/fp';

import ResizeObserver from 'resize-observer-polyfill';

export default class ResizeObservable extends PureComponent {
  static defaultProps = {
    as: 'div',
    onResize: fp.noop,
  };

  static propTypes = {
    as: T.oneOf(['div']),
    children: T.oneOfType([T.func, T.node]).isRequired,
    onResize: T.func,
  };

  state = { width: 0, height: 0 };

  componentDidMount() {
    const { node } = this;
    invariant(node, `No DOM Node available to observe`);

    this.ro = new ResizeObserver(this.handleResize);
    this.ro.observe(node);
  }

  componentWillUnmount() {
    if (!this.ro) return;

    this.ro.disconnect();
    this.ro = null;
  }

  bindRef = el => (this.node = el);

  handleResize = (entries, observer) => {
    const { contentRect } = entries[0];
    const { width, height } = contentRect;
    this.setState({ width, height });
    this.props.onResize(contentRect);
  };

  render() {
    const { as: Tag, children, ...rest } = this.props;
    delete rest.onResize;

    const { width, height } = this.state;

    return (
      <Tag {...rest} ref={this.bindRef}>
        {typeof children === 'function'
          ? children({ width, height })
          : React.Children.only(children)}
      </Tag>
    );
  }
}
