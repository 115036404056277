import React, { useState, useMemo, Fragment, useRef, useEffect } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useParams,
} from 'react-router-dom';
import fp from 'lodash/fp';
import DocumentTitle from 'react-document-title';
import MainLayout from '<components>/MainLayout';
import Masthead from '<components>/Masthead';
import JobGroupSidebar from '../components/JobGroupSidebar';
import JobGroupOverview from '../JobGroupOverview';
import JobGroupEditor from '../JobGroupEditor';
import JobGroupHistory from '../JobGroupHistory';
import JobGroupStatus from '../JobGroupStatus';
import { ColumnWrapper } from '<src>/sections/jobs/styles';
import useFeatures from '<components>/Feature/useFeatures';
import ResizeableSplitView from '<src>/sections/flows/flow-testing/ResizeableSplitView';
import WaveSpinner from '<components>/WaveSpinner';

import {
  SidebarWrapper,
  SidebarHeader,
  SidebarContent,
  TitleField,
  StartDropdownWrapper,
  FlowContainer,
  RoundedContentWrapper,
} from '<src>/components/NumbrzVerticalEditor';
import { Dropdown } from 'semantic-ui-react';
import Button from '<src>/components/Button';
import useJobGroupState from '../api/useJobGroupState';
import useJobGroupRunState from '../api/useJobGroupRunState';
import {
  FlexRowContainer,
  PageContainer,
  PageMasthead,
  Separator,
} from '<src>/components/NumbrzPageComponents';
import { getStatusChiclet } from '../utils';
import ActionMenu from '<src>/components/ActionMenu';

const stopForStatuses = ['Complete', 'Failed', 'Canceled', 'CancelledByUser'];

function StartBlockDropdown({
  blocks = [],
  startBlockKey,
  setStartBlockKey,
  disabled,
}) {
  const options = blocks.map((b) => ({
    key: b.key,
    text: b.name,
    value: b.key,
  }));
  return options.length > 0 ? (
    <StartDropdownWrapper margin="0 20px 0 0">
      <span>Start at</span>
      <Dropdown
        disabled={disabled}
        value={startBlockKey ? startBlockKey : options[0].value}
        options={options}
        onChange={(e, data) => setStartBlockKey(data.value)}
        closeOnBlur
      />
    </StartDropdownWrapper>
  ) : null;
}

function JobGroupMasthead({
  baseURL,
  api,
  jobGroupState,
  jobGroupRunState,
  controlsDisabled,
  startBlockKey,
  setStartBlockKey,
  isRunning,
  cancelling,
  running,
  onCancel,
  handleRunJobGroup,
  sidebarVisible,
  setSidebarVisible,
}) {
  const { jobGroup = {} } = jobGroupState;
  const { jobGroupStatus = {}, loadingStatus } = jobGroupRunState;
  const blocks = jobGroup.blocks || [];
  const externalResultURL = jobGroup.externalResultURL;

  const isCompleted = jobGroupStatus.state === 'Completed';
  const isCancelled = jobGroupStatus.state === 'Cancelled';

  const SidebarToggleBtn = sidebarVisible
    ? Button.SidebarHideBtn
    : Button.SidebarShowBtn;

  return (
    <PageMasthead>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h5>
          <FlexRowContainer alignItems="center" justifyContent="flex-start">
            {!sidebarVisible && (
              <SidebarToggleBtn
                onClick={() => setSidebarVisible(!sidebarVisible)}
              />
            )}
            <TitleField
              disabled={!api.onChange}
              placeholder="Click to edit"
              singleClick
              value={jobGroup.name}
              onSubmit={(e) => api.onChange(e, { name: e.value })}
            />
            {externalResultURL && (
              <ActionMenu
                menuIconDark={true}
                menuIconName="angle down"
                menuDirection="right"
                options={[
                  {
                    value: 'results',
                    text: 'Open external results',
                    icon: 'chart bar outline',
                    isLink: true,
                    href: externalResultURL,
                    onSelect: () => {},
                  },
                ]}
              />
            )}
          </FlexRowContainer>
        </h5>
        <Switch>
          <Route path={`${baseURL}/configuration`}>
            {({ match, history }) => {
              return blocks.length > 0 ? (
                <Fragment>
                  <Separator />
                  <StartBlockDropdown
                    blocks={blocks}
                    disabled={controlsDisabled}
                    startBlockKey={startBlockKey}
                    setStartBlockKey={setStartBlockKey}
                  />
                </Fragment>
              ) : null;
            }}
          </Route>
          <Route path={`${baseURL}/status/:stateID?`}>
            {({ match, history }) => {
              return jobGroupStatus.state ? (
                <Fragment>
                  <Separator />
                  {getStatusChiclet(jobGroupStatus.state, isRunning)}
                </Fragment>
              ) : null;
            }}
          </Route>
        </Switch>
      </div>
      <div>
        {running &&
          !cancelling &&
          !(isCancelled || isCompleted) &&
          jobGroupStatus.ID && (
            <Button.Cancel
              size="small"
              onClick={() => onCancel(jobGroupStatus.ID)}
              disabled={cancelling}
            >
              {cancelling ? 'Cancelling...' : 'Cancel'}
            </Button.Cancel>
          )}
        {!running && !cancelling && jobGroup.ID && !loadingStatus && (
          <Button.Run
            size="small"
            onClick={handleRunJobGroup}
            disabled={running || !jobGroup.runnable}
          >
            {running ? 'Running...' : 'Run'}
          </Button.Run>
        )}
      </div>
    </PageMasthead>
  );
}

function JobGroupPageContent({
  jobGroupID,
  baseURL,
  jobGroupState,
  jobGroupRunState,
  isRunning,
  isCancelling,
  onCancel,
  startBlockKey,
  setStartBlockKey,
  projectNames,
  running,
  setRunning,
  runID,
}) {
  const { loading, error, jobGroup, api } = jobGroupState;

  if (error) {
    throw error;
  }

  return loading ? (
    <WaveSpinner />
  ) : (
    <Switch>
      <Route exact path={`${baseURL}/overview`}>
        {({ match }) => {
          return (
            <RoundedContentWrapper
              margin="0 0 15px 0"
              maxWidth="900px"
              height="auto"
              hideOverflow
            >
              <JobGroupOverview
                loading={loading}
                jobGroup={jobGroup}
                onChange={api.onChange}
              />
            </RoundedContentWrapper>
          );
        }}
      </Route>

      <Route path={`${baseURL}/configuration`}>
        {({ match, history }) => {
          return (
            <JobGroupEditor
              runID={runID}
              history={history}
              loading={loading}
              baseURL={baseURL}
              jobGroupID={jobGroupID}
              jobGroupState={jobGroupState}
              isRunning={isRunning}
              isCancelling={isCancelling}
              onCancel={onCancel}
              startBlockKey={startBlockKey}
              setStartBlockKey={setStartBlockKey}
              projectNames={projectNames}
            />
          );
        }}
      </Route>
      <Route path={`${baseURL}/status/:stateID?`}>
        {({ match, history }) => {
          return (
            <RoundedContentWrapper
              margin="0 0 15px 0"
              maxWidth="900px"
              height="auto"
              hideOverflow
            >
              <JobGroupStatus
                history={history}
                match={match}
                loading={loading}
                baseURL={baseURL}
                jobGroupID={jobGroupID}
                jobGroupState={jobGroupState}
                jobGroupRunState={jobGroupRunState}
                isRunning={isRunning}
                isCancelling={isCancelling}
                onCancel={onCancel}
                setStartBlockKey={setStartBlockKey}
                projectNames={projectNames}
                running={running}
                setRunning={setRunning}
                runID={runID}
              />
            </RoundedContentWrapper>
          );
        }}
      </Route>

      <Route path={`${baseURL}/run-history`}>
        {({ match, history }) => {
          return (
            <JobGroupHistory
              jobGroupID={jobGroupID}
              jobGroupRunState={jobGroupRunState}
              baseURL={baseURL}
              history={history}
              jobGroupRunning={running}
            />
          );
        }}
      </Route>
      {!loading && isRunning && (
        <Route>
          {() => (
            <Redirect
              to={{
                pathname: runID.current
                  ? `${baseURL}/status/${runID.current}`
                  : `${baseURL}/status`,
              }}
            />
          )}
        </Route>
      )}
      {!loading && !isRunning && (
        <Route>{() => <Redirect to={`${baseURL}/overview`} />}</Route>
      )}
    </Switch>
  );
}

export default function JobGroupPage() {
  const history = useHistory();

  const { jobGroupID } = useParams();
  const features = useFeatures();
  const baseURL = `/job-groups/${jobGroupID}`;
  const runID = useRef();
  const nameRef = useRef();
  const [sidebarVisible, setSidebarVisible] = useState(true);

  const [startBlockKey, setStartBlockKey] = useState();
  const jobGroupState = useJobGroupState(jobGroupID, history, runID);
  const jobGroupRunState = useJobGroupRunState(jobGroupID);
  const { api, loading, handleRunGroup, projectNames } = jobGroupState;
  const [running = false, setRunning] = useState();
  const [cancelling, setCancelling] = useState(false);
  const jobGroupName = loading ? '' : jobGroupState.jobGroup.name;
  const breadcrumbs = [
    { title: 'Job Groups', path: '/job-groups', type: 'root' },
  ];

  const {
    jobGroupStatus = {},
    loadJobGroupStatus,
    loadingStatus,
    startStatusPolling,
    stopStatusPolling,
    runHistory = [],
    loadRunHistory,
    loadingRunHistory,
    calledRunHistory,
    loadingActiveGroup,
    activeJobGroup,
  } = jobGroupRunState;

  const isGroupRunnable = useMemo(() => {
    if (
      loading ||
      running ||
      !fp.getOr(false, 'jobGroup.runnable', jobGroupState)
    )
      return false;

    return true;
  }, [jobGroupState, loading, running]);

  const isGroupActive = useMemo(() => {
    if (!jobGroupStatus.state) return false;
    return !['Complete', 'Failed', 'Canceled', 'CancelledByUser'].includes(
      jobGroupStatus.state
    );
  }, [jobGroupStatus]);

  const handleRunJobGroup = () => {
    setRunning(true);
    handleRunGroup(startBlockKey);
  };

  const handleCancelJob = async (stateID) => {
    setCancelling(true);
    await api.onCancel({}, { stateID });
    setRunning(false);
    setCancelling(false);
  };

  useEffect(() => {
    if (!calledRunHistory && !loadingRunHistory && runHistory.length === 0)
      loadRunHistory();
    if (!runID.current && !jobGroupStatus.state) {
      if (!loadingRunHistory && runHistory.length > 0) {
        runID.current = runHistory[0].ID;
      }
      // check active group array
      if (!loadingActiveGroup && activeJobGroup.length > 0) {
        runID.current = activeJobGroup[0].ID;
      }
      if (runID.current && !loadingStatus) loadJobGroupStatus(runID.current);
    }
  }, [
    loadRunHistory,
    activeJobGroup,
    loadingActiveGroup,
    loadJobGroupStatus,
    runHistory,
    loadingRunHistory,
    loadingStatus,
    calledRunHistory,
    jobGroupStatus.state,
  ]);

  useEffect(() => {
    if (stopForStatuses.includes(jobGroupStatus.state)) {
      setRunning(false);
      stopStatusPolling();
    } else {
      if (runID.current && isGroupActive) {
        setRunning(true);
        startStatusPolling(3000);
      }
    }

    return () => {
      if (stopForStatuses.includes(jobGroupStatus.state)) stopStatusPolling();
    };
  }, [
    isGroupActive,
    running,
    jobGroupStatus,
    loadingRunHistory,
    loadingStatus,
  ]);

  const SidebarToggleBtn = sidebarVisible
    ? Button.SidebarHideBtn
    : Button.SidebarShowBtn;

  return (
    <MainLayout
      navigation={false}
      overflowY="hidden"
      header={
        <DocumentTitle title={`${jobGroupName} - Numbrz`}>
          <Masthead
            title={jobGroupName}
            breadcrumbs={breadcrumbs}
            runBtnVisible={false}
            addShadow
          />
        </DocumentTitle>
      }
      main={
        <PageContainer>
          <ResizeableSplitView
            left={
              <Fragment>
                <SidebarWrapper visible={sidebarVisible}>
                  <SidebarHeader>
                    <span />
                    {sidebarVisible && (
                      <SidebarToggleBtn
                        title="Show navigation"
                        onClick={() => setSidebarVisible(!sidebarVisible)}
                      />
                    )}
                  </SidebarHeader>
                  <SidebarContent>
                    <JobGroupSidebar baseURL={baseURL} />
                  </SidebarContent>
                </SidebarWrapper>
                <FlowContainer>
                  <JobGroupMasthead
                    baseURL={baseURL}
                    api={api}
                    jobGroupState={jobGroupState}
                    jobGroupRunState={jobGroupRunState}
                    nameRef={nameRef}
                    controlsDisabled={!isGroupRunnable}
                    startBlockKey={startBlockKey}
                    setStartBlockKey={setStartBlockKey}
                    isRunning={running}
                    cancelling={cancelling}
                    running={running}
                    onCancel={handleCancelJob}
                    handleRunJobGroup={handleRunJobGroup}
                    sidebarVisible={sidebarVisible}
                    setSidebarVisible={setSidebarVisible}
                  />

                  <ColumnWrapper padding="3em 4em" height="100%">
                    {loading ? (
                      <WaveSpinner />
                    ) : (
                      <JobGroupPageContent
                        baseURL={`/job-groups/${jobGroupID}`}
                        jobGroupID={jobGroupID}
                        jobGroupState={jobGroupState}
                        jobGroupRunState={jobGroupRunState}
                        isRunning={running}
                        isCancelling={cancelling}
                        handleRunJobGroup={handleRunJobGroup}
                        onCancel={handleCancelJob}
                        projectNames={projectNames}
                        startBlockKey={startBlockKey}
                        setStartBlockKey={setStartBlockKey}
                        features={features}
                        running={running}
                        setRunning={setRunning}
                        runID={runID}
                      />
                    )}
                  </ColumnWrapper>
                </FlowContainer>
              </Fragment>
            }
            right={null}
          />
        </PageContainer>
      }
    />
  );
}
