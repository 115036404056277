import React, { useState, useEffect, useRef, Fragment } from 'react';
import styled from '@emotion/styled';

import { Form, Message, Icon } from 'semantic-ui-react';
import Button from '<components>/Button';
import Dialog from '<components>/Dialog';
import TextInput from '<components>/TextInput';
import { modelRunnerType } from '<src>/utils/data-types';
import UnderlinedHeader from '<src>/components/UnderlinedHeader';
import {
  FlexRowContainer,
  FlexColumnContainer,
} from '<src>/components/NumbrzPageComponents';
import { NumbrzTooltip } from '<components>/NumbrzButtons';
import NestedPickerPane from '<components>/NestedPickerPane_V2';
import { makeOptions } from '<components>/DataPicker/Pane';
import DataTablePane from '<components>/DataTablePane';

const StyledDialog = styled(Dialog)`
  width: 50%;
  .left {
    max-height: 350px;
    overflow: auto;
  }
  .right {
    max-height: 350px;
    overflow: auto;
  }
`;

const StyledTextInput = styled(TextInput)`
  margin-bottom: 1em;
  width: 100%;
`;

const StyledFooter = styled(Dialog.Footer)`
  border-top: none;
`;

const StyledInfoCSS = {
  margin: '0 0 0 5px',
  cursor: 'pointer',
  fontSize: '13px',
  color: '#777',
};

export default function AddJobTableDialog({
  objType,
  visible,
  onClose,
  onCreate,
  dataTables = [],
  dataTablesLoading,
}) {
  const [name, setName] = useState(`New ${objType}`);
  const [eraseData, setEraseData] = useState(false);
  const [newColumns, setNewColumns] = useState([]);

  const [useExistingID, setUseExistingID] = useState();
  const [creating, setCreating] = useState(false);
  const inputRef = useRef();

  useEffect(() => setName(`New ${objType}`), [objType]);
  useEffect(() => {
    if (!visible) {
      setCreating(false);
      setUseExistingID(null);
      setNewColumns([]);
      setName(`New ${objType}`);
    } else {
      setTimeout(() => {
        inputRef.current.focus();
        inputRef.current.select();
      }, 0);
    }
  }, [visible, objType]);

  useEffect(() => {
    if (!useExistingID) {
      return;
    }
    const dataTable = dataTables.find((dt) => dt.ID === useExistingID);
    if (!dataTable) {
      return;
    }

    setName(dataTable.name);

    const cols = [];
    dataTable.columns.forEach((col) => {
      cols.push({
        label: col.label,
        type: modelRunnerType(col.type),
        comment: col.comment,
      });
    });
    setNewColumns(cols);
  }, [useExistingID, dataTables]);

  const isValid = () => name && name.trim();

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (isValid()) return handleSubmit(e);
    }
  };

  const handleSubmit = async (e) => {
    if (!isValid()) return;

    if (isValid()) {
      setCreating(true);
      await onCreate(
        {
          name: name.trim(),
          schema: { elements: newColumns },
          ...(eraseData && {
            onExport: {
              beforeWrite: {
                delete: {},
              },
            },
          }),
        },
        e
      );
      onClose();
    }
  };

  return (
    <StyledDialog visible={!!visible} onRequestClose={onClose}>
      <Dialog.Header>
        <Dialog.Headline>{`Create a ${objType}`}</Dialog.Headline>
        <Button.DialogClose onClick={onClose} />
      </Dialog.Header>
      <Dialog.Body>
        <UnderlinedHeader margin="0 0 5px 0">NAME</UnderlinedHeader>
        <StyledTextInput
          ref={inputRef}
          placeholder={`Name (Required)`}
          name="name"
          onChange={({ target: { name, value } }) => setName(value)}
          disabled={creating}
          onKeyDown={handleKeyDown}
          value={name}
        />

        <UnderlinedHeader
          margin="0 0 5px 0"
          padding="0 0 3px 0"
          justifyContent="space-between"
        >
          <FlexRowContainer justifyContent="flex-start" alignItems="flex-end">
            TEMPLATE SOURCE
            <div style={{ marginLeft: '5px' }}>(Optional)</div>
            <NumbrzTooltip
              hideOnScroll
              position="top center"
              content={
                <span>
                  Create the table with the same name and columns as an existing
                  data source
                </span>
              }
              trigger={<Icon name="info circle" style={StyledInfoCSS} />}
            />
          </FlexRowContainer>
          {useExistingID && (
            <Button
              action="control"
              onClick={(e) => {
                e.preventDefault();
                setUseExistingID(null);
                setName(`New job table`);
              }}
              style={{ padding: '3px 6px' }}
            >
              Clear selection
            </Button>
          )}
        </UnderlinedHeader>

        <NestedPickerPane
          pickerMode="dataTables"
          dialog
          options={makeOptions(dataTables)}
          loading={dataTablesLoading}
          value={useExistingID}
          onChange={(selectedID) => setUseExistingID(selectedID)}
          renderListItem={(item, isSubItem) => <div>{item.name}</div>}
          renderItemDetail={(item) =>
            item ? (
              <FlexColumnContainer
                alignItems="flex-start"
                style={{ height: '100%', overflowY: 'auto' }}
              >
                <DataTablePane
                  readOnly
                  hideHeader
                  table={item.detail}
                  showTableType
                  projectID={null}
                />
              </FlexColumnContainer>
            ) : null
          }
          emptyMessage={
            <Message info style={{ minHeight: 'unset', fontSize: '13px' }}>
              {`Select a data source to use as a template for this ${objType}. The table will be created with the name and columns of the data source you select. These can be edited at any time.`}
            </Message>
          }
        />

        {objType === 'job table' && (
          <Fragment>
            <UnderlinedHeader margin="10px 0 5px 0" padding="0 0 3px 0">
              EXPORT DATA SETTINGS{' '}
              <div style={{ marginLeft: '5px' }}>(Optional)</div>
              <NumbrzTooltip
                hideOnScroll
                position="top center"
                content={
                  <span>Erase all existing data before writing results</span>
                }
                trigger={<Icon name="info circle" style={StyledInfoCSS} />}
              />
            </UnderlinedHeader>
            <Form.Checkbox
              checked={eraseData}
              label="Erase all data"
              onChange={() => setEraseData(!eraseData)}
            />
          </Fragment>
        )}
      </Dialog.Body>
      <StyledFooter>
        <Button
          id="create"
          onClick={handleSubmit}
          disabled={creating || !isValid()}
        >
          {creating ? 'Creating...' : `Create`}
        </Button>
      </StyledFooter>
    </StyledDialog>
  );
}
