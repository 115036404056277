/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from '@emotion/react';

import * as styles from './styles';
import DurationLabel from './DurationLabel';
import TimeSinceNow from './TimeSinceNow';
import { getStatusChiclet } from '<sections>/job-groups/utils';
import Link from '<src>/components/Link';
import { FlexRowContainer } from '<src>/components/NumbrzPageComponents';

export function jobProgressText(stageDetails) {
  if (stageDetails && stageDetails.length > 0) {
    const totalStages = stageDetails.length;
    const totalCompleted = stageDetails.filter((s) =>
      ['JobFailed', 'InputsFailed', 'Completed'].includes(s.status)
    ).length;

    if (totalCompleted === totalStages) return totalCompleted;
    if (totalCompleted > 0) return `${totalCompleted}/${totalStages}`;
  }

  return '0/0';
}

export default function JobProgress({
  status,
  started,
  ended,
  stageDetails,
  externalResultURL,
}) {
  const isRunning = status === 'Running';

  return (
    <styles.Summary>
      <div style={{ width: '100%' }}>
        <FlexRowContainer alignItems="center" justifyContent="space-between">
          <div>
            <span css={styles.summaryListLabel}>Status: </span>
            {getStatusChiclet(status, isRunning)}
          </div>
          {externalResultURL && (
            <Link.ExternalResults href={externalResultURL} />
          )}
        </FlexRowContainer>
      </div>
      <div>
        <span css={styles.summaryListLabel}>Duration: </span>
        <DurationLabel start={started} end={ended} />
      </div>
      <div>
        <span css={styles.summaryListLabel}>Started: </span>
        <TimeSinceNow>{started}</TimeSinceNow>
      </div>
      <div>
        <span css={styles.summaryListLabel}>Stages Completed: </span>
        {jobProgressText(stageDetails)}
      </div>
    </styles.Summary>
  );
}
