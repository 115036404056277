import React, { useEffect, useState } from 'react';

import Dialog from '<components>/Dialog';
import TextInput from '<components>/TextInput';
import Button from '<components>/Button';

export default function DeleteDialog({
  onConfirm,
  onClose,
  deleting,
  visible,
}) {
  const [verify, setVerify] = useState('');

  useEffect(() => {
    if (visible) {
      setVerify('');
    }
  }, [visible]);

  return (
    <Dialog visible={visible ? 1 : 0} onRequestClose={onClose}>
      <Dialog.Header>
        <Dialog.Headline>Delete Deployment</Dialog.Headline>
      </Dialog.Header>
      <Dialog.Body>
        <p>Are you sure you want to delete this deployment?</p>
        <p>Type "DELETE" below to confirm</p>
        <TextInput onChange={(e) => setVerify(e.target.value)} value={verify} />
      </Dialog.Body>
      <Dialog.Footer>
        <Button onClick={onClose}>Cancel</Button>
        <Button.Delete
          disabled={deleting || verify !== 'DELETE'}
          onClick={onConfirm}
        >
          Delete
        </Button.Delete>
      </Dialog.Footer>
    </Dialog>
  );
}
