import { ApolloLink } from '@apollo/client';

import createAuthLink from './auth';
import createErrorLink from './error';
import createSentryLink from './sentry';
import createNetworkLink from './network';
import createLogger from './logger';
import cleanTypename from './clean-typename';

export default function init({ cache, getToken, logout }) {
  const authLink = createAuthLink(getToken);
  const sentryLink = createSentryLink();
  const errorLink = createErrorLink(logout);
  const networkLink = createNetworkLink();
  const logLink = createLogger();

  let links = [
    logLink,
    cleanTypename,
    authLink,
    sentryLink,
    errorLink,
    networkLink,
  ];

  return ApolloLink.from(links);
}
