import React, { useCallback, useEffect, useState } from 'react';
import Dialog from '<components>/Dialog';
import Button from '<components>/Button';
import { StyledScrollbar } from '<src>/components/NumbrzPageComponents';

import styled from '@emotion/styled';
import * as colors from '<components>/colors';

import stepData, { stepsByType } from '../Step/stepData';

const StepList = styled('div')`
  ${StyledScrollbar}
  overflow-y: auto;
  max-height: 55vh;
  padding: 5px 15px 5px 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const StepListItem = styled('div')`
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  border-radius: 4px;
  color: ${colors.gray1b};
  border: 1px solid ${colors.gray3};
  background: ${(props) => (props.active ? colors.yellow7 : 'transparent')};
  h5 {
    margin-bottom: 0;
  }
  div {
    margin: 0;
    font-size: 12px;
  }
`;

const StyledHeadline = styled(Dialog.Headline)`
  padding-left: 10px;
`;

export default function AddStepDialog({
  selected: selectedProp,
  onRequestClose,
  atIndex,
  onAddStep,
  onSelect,
}) {
  const [selectedState, setSelectedState] = useState(null);
  useEffect(() => {
    setSelectedState(null);
  }, [atIndex]);

  const selected = selectedProp !== undefined ? selectedProp : selectedState;
  const setSelected = useCallback(
    (typename) => {
      if (onSelect) {
        onSelect(typename);
      }
      setSelectedState(typename);
    },
    [onSelect]
  );

  const onAdd = (e) =>
    onAddStep(e, {
      index: atIndex,
      type: selected,
      title: `New ${stepsByType[selected].title}`,
    });

  return (
    <Dialog
      onRequestClose={onRequestClose}
      visible={!!onAddStep && atIndex !== null}
    >
      <Dialog.Header>
        <StyledHeadline>Add step</StyledHeadline>
      </Dialog.Header>
      <Dialog.Body>
        <StepList>
          {stepData.map((step) => (
            <StepListItem
              key={step.typename}
              active={selected === step.typename}
              onClick={() => setSelected(step.typename)}
              onDoubleClick={onAdd}
            >
              <h5>{step.title}</h5>
              {step.doc}
            </StepListItem>
          ))}
        </StepList>
      </Dialog.Body>
      <Dialog.Footer>
        <Button onClick={onRequestClose}>Cancel</Button>
        <Button disabled={!selected} onClick={onAdd}>
          Add
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
}
