import React, { useCallback } from 'react';
import { Icon } from 'semantic-ui-react';
import Button from '<src>/components/Button';
import { Toolbar } from '../styles';
import { PageMasthead } from '<components>/NumbrzPageComponents';
import { publish } from '<components>/events';

export default function FlowTestMasthead({
  exitTestMode,
  testModeExpanded,
  setTestModeExpanded,
}) {
  const handleToggleExpand = useCallback(() => {
    publish(
      testModeExpanded ? 'exitFullScreenTestMode' : 'enterFullScreenTestMode'
    );

    setTestModeExpanded('testModeFullscreen', !testModeExpanded);
  }, [setTestModeExpanded, testModeExpanded]);

  return (
    <PageMasthead style={{ paddingLeft: '10px', paddingRight: '10px' }}>
      <Button.TestFlowBtn
        title="Test flow"
        onClick={exitTestMode}
        className="active"
      >
        Test Flow
      </Button.TestFlowBtn>
      <Toolbar>
        <Button
          icon={
            <Icon
              name={testModeExpanded ? 'compress' : 'expand'}
              style={{ fontWeight: 'bold', width: '20px' }}
              title={testModeExpanded ? 'Minimize' : 'Expand'}
            />
          }
          onClick={handleToggleExpand}
        />

        <Button
          onClick={exitTestMode}
          margin="0"
          icon={<Icon name="close" title="Close" />}
        />
      </Toolbar>
    </PageMasthead>
  );
}
