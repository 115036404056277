import React, { useContext, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useDrop } from 'react-dnd';

import { mergeRefs } from '<src>/utils/react';

import ActionMenu from '<components>/ActionMenu';
import * as colors from '<components>/colors';

import { WidgetAPI } from '../contexts';
import Card from '../Card';
import ConfigButton from '../ConfigButton';
import Editor from '../Editor';
import useExpanded from '../useExpanded';
import useSelectable from '../useSelectable';

import WidgetSettings from './Settings';
import WidgetBody from './Body';

const ignoreSsrWarning =
  '/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */';

const StyledBody = styled('div')`
  & > div {
    display: flex;
    flex-direction: column;
    align-content: center;
  }
`;

const StyledHeader = styled('h4')`
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-bottom: 0 !important;

  & > :first-child${ignoreSsrWarning} {
    margin-right: 14px;
  }

  & button {
    outline: none;
    min-width: 15px;
    width: fit-content;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 2px;
    margin: 0;
    text-align: center !important;

    i {
      margin: 0;
    }
  }
`;

const NameEditor = styled(Editor)`
  outline: none;
  transition: border-color 225ms ease-in-out;
  border-bottom: 2px solid transparent;
  width: fit-content;
  &.placeholder {
    font-style: italic;
    font-weight: normal;
  }
  &:focus {
    border-bottom-color: ${colors.blue2};
  }
  &:empty:after {
    content: '\\00a0';
  }
`;

function Actions({ api, widget }) {
  const options = [];
  if (api.cloneWidget) {
    options.push(
      ActionMenu.cloneOption({
        onSelect: () => api.cloneWidget(widget),
      })
    );
  }
  if (api.deleteWidget) {
    options.push(
      ActionMenu.deleteOption({
        onSelect: () => api.deleteWidget(widget),
      })
    );
  }
  if (options.length === 0) return null;
  return <ActionMenu as="button" options={options} />;
}

function SettingsButton({
  api,
  widget,
  func,
  expanded,
  forceExpanded,
  onExpand,
}) {
  const show = !forceExpanded && (api.updateCellType || func.Settings);

  if (!show) return null;

  return (
    <ConfigButton
      expanded={expanded}
      onClick={() => onExpand(!expanded)}
      title={expanded ? 'Hide configuration' : 'Show configuration'}
    />
  );
}

function useScrollOnMove(graphPosition = {}, active, ID) {
  const activeRef = useRef(active);
  const scrollRef = useRef();
  useEffect(() => {
    activeRef.current = active;
  }, [active, ID]);
  useEffect(() => {
    if (activeRef.current && scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [graphPosition.groupIdx, graphPosition.nodeIdx]);

  return scrollRef;
}

export default function Widget({
  widget,
  func,
  parent,
  startExpanded = false,
  forceExpanded = false,
  forceActive = false,
  hideType = false,
  graphPosition = {},
}) {
  const widthRef = useRef();
  const api = useContext(WidgetAPI);
  const item = {
    type: useSelectable.WIDGET,
    ID: widget.ID,
    correlationID: widget.correlationID,
  };
  const [selectionRef, , act] = useSelectable(item);
  const active = forceActive || act;

  const [{ isOver }, dropRef] = useDrop({
    accept: 'CELL',
    canDrop: () => false,
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const scrollRef = useScrollOnMove(graphPosition, act, widget.ID);

  const [exp, setExpanded] = useExpanded(widget.ID, startExpanded);
  const expanded = forceExpanded || exp;

  const Label = func.Label ? func.Label : () => null;
  const Body = func.Body ? func.Body : WidgetBody;

  return (
    <Card
      active={act}
      tabIndex={0}
      ref={mergeRefs([dropRef, selectionRef, scrollRef])}
    >
      <Card.Header>
        <StyledHeader>
          <div>
            <NameEditor
              value={widget.name}
              onSubmit={({ value }) =>
                api.updateWidget(widget, { name: value })
              }
              placeholder="Untitled"
            />
          </div>
          <SettingsButton
            widget={widget}
            func={func}
            api={api}
            expanded={expanded}
            forceExpanded={forceExpanded}
            onExpand={setExpanded}
          />
          <Actions api={api} widget={widget} />
        </StyledHeader>
        <div>
          <h6 style={{ margin: '0px' }}>
            <Label api={api} widget={widget} />
          </h6>
        </div>
      </Card.Header>
      <WidgetSettings
        api={api}
        func={func}
        widget={widget}
        expanded={expanded}
        parent={parent}
        widthRef={widthRef}
      />
      <StyledBody ref={widthRef}>
        <Body
          api={api}
          func={func}
          widget={widget}
          expanded={expanded}
          showNewRow={isOver}
          active={active}
        />
      </StyledBody>
    </Card>
  );
}
