import { useCallback, useState, useMemo } from 'react';
import { usePagedLazyQuery, useMutation } from '<src>/apollo/client';

import { GetPickerTables } from '<components>/DataPicker/connectors';
import { UpdateFlowTable } from './queries';

export default function useTableState(flow) {
  const [tableID, setTableID] = useState(null);
  const [called, setCalled] = useState(false);
  const [updateTable] = useMutation(UpdateFlowTable);
  const [fetchDataTables, { called: fetchCalled, loading, error, data }] =
    usePagedLazyQuery(GetPickerTables, {}, (data) => data.dataTables.cursor);

  if (error) {
    throw error;
  }

  const dataTables = useMemo(
    () => (!fetchCalled || loading ? [] : data.dataTables.tables),
    [fetchCalled, loading, data]
  );

  const table = useMemo(
    () => (flow && tableID ? flow.data.find((t) => t.ID === tableID) : null),
    [flow, tableID]
  );

  const loadTable = useCallback(
    (tableID) => {
      fetchDataTables();
      setTableID(tableID);
      setCalled(true);
    },
    [fetchDataTables]
  );
  const unloadTable = useCallback(() => {
    setTableID(null);
    setCalled(false);
  }, []);

  if (!flow) {
    return {};
  }

  const state = {
    called,
    loadTable,
    fetchDataTables,
    table,
    dataTables,
    dataTablesLoading: loading,
    unloadTable,
  };

  state.api = {
    onChange: (e, updates) =>
      updateTable({
        variables: {
          flowID: flow.ID,
          tableID,
          updates,
        },
      }),
  };

  return state;
}
