import React, { useState, useEffect, useRef } from 'react';
import { SplitView, SplitViewDivider, LeftPane } from './styles';
import RightPanel from './RightPanel';

export default function ResizeableSplitView({
  left,
  right,
  showDivider,
  minWidth,
  height,
  rightWidth,
  setRightWidth,
  rightPanelOpen,
  rightPanelExpanded,
}) {
  const [sepXPos, setSepXPos] = useState();
  const [dragging, setDragging] = useState(false);

  const splitViewRef = useRef();

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('touchmove', onTouchMove);
    document.addEventListener('mouseup', onMouseUp);
    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  });

  const onMouseDown = (e) => {
    setSepXPos(e.clientX);
    setDragging(true);
  };

  const onTouchStart = (e) => {
    setSepXPos(e.touches[0].clientX);
    setDragging(true);
  };

  const onMove = (clientX) => {
    if (dragging && rightWidth && sepXPos) {
      const newRightWidth = rightWidth + sepXPos - clientX;
      setSepXPos(clientX);

      if (newRightWidth < minWidth) {
        setRightWidth(minWidth);
        return;
      }

      if (splitViewRef.current) {
        const splitViewWidth = splitViewRef.current.clientWidth;
        if (newRightWidth > splitViewWidth - rightWidth) {
          setRightWidth(splitViewWidth - rightWidth);
          return;
        }
      }

      setRightWidth(newRightWidth);
    }
  };

  const onMouseMove = (e) => {
    if (dragging) e.preventDefault();
    onMove(e.clientX);
  };

  const onTouchMove = (e) => {
    onMove(e.touches[0].clientX);
  };

  const onMouseUp = () => {
    setSepXPos(undefined);
    setDragging(false);
  };

  return (
    <SplitView ref={splitViewRef} height={height}>
      <LeftPane style={{ display: rightPanelExpanded ? 'none' : 'flex' }}>
        {left}
      </LeftPane>
      {showDivider && !rightPanelExpanded && (
        <SplitViewDivider
          onMouseDown={onMouseDown}
          onTouchStart={onTouchStart}
          onTouchEnd={onMouseUp}
        />
      )}
      <RightPanel
        rightPanelOpen={rightPanelOpen}
        rightPanelExpanded={rightPanelExpanded}
        rightWidth={rightWidth}
        setRightWidth={setRightWidth}
        parentRef={splitViewRef}
      >
        {right}
      </RightPanel>
    </SplitView>
  );
}
