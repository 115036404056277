import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import fp from 'lodash/fp';
import { Link } from 'react-router-dom';
import { Message } from 'semantic-ui-react';

import Button from '<components>/Button';
import Dialog from '<components>/Dialog';

const quotaErrorMessages = {
  MaxSizeExceeded:
    'The number of stages in the flow associated with this job exceeds the limit provided by your current plan.',
  MaxActiveExceeded:
    'You have reached the maximum number of active jobs allowed by your current plan.',
};

const DialogContent = styled(Dialog.Body)`
  display: flex;
  flex-wrap: wrap;
  min-width: 300px;
  .message {
    width: 100%;
  }
`;

export default function RunJobErrorDialog({
  runResult,
  visible = false,
  onClose,
}) {
  const { errorMessage, errorCode } = fp.getOr({}, 'data.runJob', runResult);

  const isQuotaError = errorCode in quotaErrorMessages;

  useEffect(() => {
    if (visible && isQuotaError) {
      window.Intercom('trackEvent', 'quota_error', {
        url: window.location.href,
        error_code: errorCode,
        error_message: quotaErrorMessages[errorCode],
      });
    }
  }, [errorCode, isQuotaError, visible]);

  const message =
    quotaErrorMessages[errorCode] ||
    errorMessage ||
    'This job contains errors.';
  const content = isQuotaError ? (
    <div>
      <Message error>{message}</Message>
      <p>
        Review your subscription details and upgrade{' '}
        <Link to="/account/billing">here</Link>.
      </p>
    </div>
  ) : (
    <Message error>{message}</Message>
  );

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onClose();
    }
  };

  return (
    <Dialog visible={visible ? 1 : 0} onRequestClose={onClose}>
      <Dialog.Header>
        <Dialog.Headline>Error Running Job</Dialog.Headline>
        <Button.DialogClose onClick={onClose} />
      </Dialog.Header>
      <DialogContent>{content}</DialogContent>
      <Dialog.Footer>
        <Button
          //eslint-disable-next-line
          autoFocus
          onClick={onClose}
          onKeyDown={handleKeyDown}
        >
          Close
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
}
