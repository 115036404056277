import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { RoundedContentWrapper } from '<src>/components/NumbrzVerticalEditor';

import ExternalResultURL from '<components>/ExternalResultURL';
import Description from '<src>/components/Description';
import JobDataOverview from '../components/JobDataOverview';
import UnderlinedHeader from '<src>/components/UnderlinedHeader';
import { Icon } from 'semantic-ui-react';
import Link from '<components>/Link';
import { FlexColumnContainer } from '<src>/components/NumbrzPageComponents';

const JobOverview = ({
  job,
  flowID,
  flowName,
  flowLink,
  projectID,
  dataMappings,
  flowTables,
  errors,
  handleInputChange,
  loading,
}) => {
  const nameRef = useRef();
  const location = useLocation();
  const canEdit = !!handleInputChange;

  const dataURL = `/models/${projectID}/jobs/${job.ID}/configuration`;
  const jobIssues = job && job.issues.length;

  useEffect(() => {
    if (canEdit && location.search === '?new' && nameRef.current) {
      nameRef.current.edit(true);
    }
  }, [canEdit, location.search]);

  return (
    <RoundedContentWrapper margin="0 0 15px 0" padding="30px" hideOverflow>
      <Description
        content={job.comment}
        onChange={(comment) => handleInputChange('comment', comment)}
        placeholder="No description provided"
        noContainer
        editable
      />

      {flowLink ? (
        <FlexColumnContainer justifyContent="center" alignItems="flex-start">
          <UnderlinedHeader as="h5">
            <span>Flow</span>
          </UnderlinedHeader>
          <Link
            size="small"
            blue
            bold={false}
            internal={true}
            to={flowLink}
            title="View flow"
            style={{ padding: 0 }}
          >
            {flowName}
          </Link>
        </FlexColumnContainer>
      ) : null}

      <ExternalResultURL
        externalResultURL={job.externalResultURL}
        onChange={(e, { externalResultURL }) =>
          handleInputChange('externalResultURL', externalResultURL)
        }
      />

      <UnderlinedHeader
        as="h5"
        margin="10px 0 0 0"
        borderWidth="2px"
        padding="0 0 2px 0"
        justifyContent="space-between"
      >
        <span>Data</span>
        <Link
          internal={true}
          to={dataURL}
          title="Configure job data"
          icon={<Icon name="arrow right" />}
        >
          {jobIssues > 0 ? 'Fix issues' : ''}
        </Link>
      </UnderlinedHeader>

      <div style={{ marginTop: '15px' }}>{errors}</div>
      <JobDataOverview
        flowID={flowID}
        jobID={job.ID}
        projectID={projectID}
        dataMappings={dataMappings}
        flowTables={flowTables}
        loading={loading}
      />
    </RoundedContentWrapper>
  );
};

export default JobOverview;
