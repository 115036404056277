import { gql } from '@apollo/client';

import { useMutation } from '<src>/apollo/client';

import { FunctionChangesFragment } from './fragments';
import { applyFunctionChangesToCache } from './useEditFunction';

const AddWidget = gql`
  mutation AddWidget(
    $funcID: CommonID!
    $widget: WidgetV3Input!
    $cells: [CellV3Input!]!
  ) {
    addWidgetV3(funcID: $funcID, widget: $widget, cells: $cells) {
      ...FunctionChangesFragment
    }
  }

  ${FunctionChangesFragment}
`;

export default function useAddWidget() {
  const [addWidget] = useMutation(AddWidget, {
    update(cache, { data: { addWidgetV3: changes } }) {
      applyFunctionChangesToCache(cache, changes);
    },
  });

  return ({ funcID, widget, cells }) =>
    addWidget({
      variables: {
        funcID,
        widget,
        cells,
      },
    });
}
