import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import * as colors from '<components>/colors';

export const TextInputStyles = css`
  display: inline-block;
  margin: 0;
  width: 100%;
  flex: 1 0 auto;
  outline: 0;
  text-align: left;
  line-height: 1.25em;
  font-family: inherit;
  padding: 10px;
  background: ${colors.white};
  border: 1px solid ${colors.gray3};
  color: ${colors.black};
  border-radius: 0.28571429rem;
  transition: all 0.125s ease-in;
  font-size: 1em;

  ::placeholder {
    color: ${colors.gray3};
    opacity: 1;
  }

  &:focus {
    border-color: ${colors.blue2};
    background: ${colors.white};
    color: ${colors.black};
  }
`;

const StyledInput = styled('input')`
  ${TextInputStyles};
`;

const TextInput = React.forwardRef((props, ref) => {
  return <StyledInput {...props} ref={ref} />;
});

TextInput.defaultProps = {
  type: 'text',
};

export default TextInput;
