import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Link, useHistory, useLocation } from 'react-router-dom';

import * as colors from '<components>/colors';

function makeReturnToObj(returnTo, location) {
  if (!returnTo) {
    returnTo = { location: undefined, text: 'Go Back' };
  } else if (typeof returnTo === 'function') {
    returnTo = makeReturnToObj(returnTo(location), location);
  } else if (typeof returnTo === 'string') {
    returnTo = { location: { pathname: returnTo }, text: 'Go Back' };
  } else {
    returnTo = { ...returnTo };
  }

  if (!returnTo.location) {
    returnTo.location = location;
  }
  if (!returnTo.text) {
    returnTo.text = 'Go Back';
  }
  return returnTo;
}

function makeLocation(to, returnTo) {
  return (location) => {
    let loc = to;
    if (typeof loc === 'function') {
      loc = loc(location);
    }
    if (typeof loc === 'string') {
      loc = { pathname: loc };
    }
    return {
      ...loc,
      state: { ...loc.state, returnTo: makeReturnToObj(returnTo, location) },
    };
  };
}

export default function LinkAndReturnTo({ to, returnTo, ...props }) {
  return <Link {...props} to={makeLocation(to, returnTo)} />;
}

const ReturnContext = React.createContext([null, () => {}]);

function ReturnLink() {
  const location = useLocation();
  const returnTo = location.state && location.state.returnTo;

  if (returnTo) {
    return <Link to={returnTo.location}>{returnTo.text}</Link>;
  }

  return null;
}

function CustomReturnLink({ children, ...props }) {
  const [, setCustomReturnLink] = useContext(ReturnContext);
  useEffect(() => {
    setCustomReturnLink(true);
    return () => setCustomReturnLink(false);
  }, [setCustomReturnLink]);

  const location = useLocation();
  const returnTo = location.state && location.state.returnTo;

  if (!returnTo) return null;

  if (typeof children === 'function') {
    return children({ returnTo });
  }
  if (React.Children.count(children) > 0) {
    return children;
  }
  return <ReturnLink />;
}

LinkAndReturnTo.Context = React.createContext({
  CustomReturnLink,
  returnTo: null,
});

const ReturnToBar = styled('div')`
  display: flex;
  justify-content: center;
  padding: 4px 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  a {
    font-size: 1em;
    font-weight: normal !important;
    border: 1px solid ${colors.gray2};
    border-radius: 9px;
    padding: 3px 8px;
    color: ${colors.gray2};
    :hover {
      border: 1px solid ${colors.blue2};
      color: ${colors.blue2};
    }
  }
`;

export function ReturnToProvider({ children }) {
  const [customReturnLink, setCustomReturnLink] = useState(null);

  const location = useLocation();
  const returnTo = location.state && location.state.returnTo;

  return (
    <LinkAndReturnTo.Context.Provider value={{ CustomReturnLink, returnTo }}>
      <ReturnContext.Provider value={[customReturnLink, setCustomReturnLink]}>
        {children}
        {returnTo && !customReturnLink ? (
          <ReturnToBar>
            <ReturnLink />
          </ReturnToBar>
        ) : null}
      </ReturnContext.Provider>
    </LinkAndReturnTo.Context.Provider>
  );
}

export function useGoAndReturnTo() {
  const history = useHistory();
  return useCallback(
    (to, returnTo) => {
      const { state, ...loc } = makeLocation(to, returnTo)();
      history.push(loc, state);
    },
    [history]
  );
}
