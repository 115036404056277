import React from 'react';

import QBConnectButton from '<components>/QBConnectButton';

import { ConnectActions, ListPicker } from './styles';

export default function QuickbooksDeployment({
  authed = false,
  existingTablesets = [],
  isLastStep,
  tablesetDeployment,
  onDoOAuth,
  onUseExisting,
  userCreds = [],
}) {
  const existingAccounts = existingTablesets.filter(
    (ts) => ts.source.__typename === 'QuickbooksOnline'
  );

  let extraMessage;
  if (authed) {
    extraMessage = null;
  } else if (existingAccounts.length === 0) {
    extraMessage = 'Click below to add a QuickBooks Online connection.';
  } else if (existingAccounts.length === 1) {
    if (tablesetDeployment.useExisting) {
      extraMessage = `Click "${isLastStep ? 'Deploy' : 'Next'}" to use your
        existing QuickBooks connection or click "Connect to QuickBooks" to
        connect to a new account.`;
    } else {
      extraMessage = 'Click below to add a QuickBooks Online connection.';
    }
  } else {
    extraMessage = `Select an account to use from the list below, or click
      "Connect to QuickBooks" to connect to a new account.`;
  }

  return (
    <div>
      <p>This model uses data from QuickBooks Online. {extraMessage}</p>
      <ConnectActions>
        {!authed && existingAccounts.length > 1 ? (
          <ListPicker
            value={tablesetDeployment.useExisting}
            onChange={(e) => onUseExisting(tablesetDeployment, e.target.value)}
            size={Math.min(existingAccounts.length, 10)}
          >
            {existingAccounts.map((existing) => (
              <option key={existing.ID} value={existing.ID}>
                {existing.name}
              </option>
            ))}
          </ListPicker>
        ) : null}
        <QBConnectButton
          onClick={() => {
            onUseExisting(tablesetDeployment, null);
            onDoOAuth('quickbooks');
          }}
          connected={authed}
        />
      </ConnectActions>
    </div>
  );
}
