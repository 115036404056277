import React, { Fragment, useState, useCallback } from 'react';
import styled from '@emotion/styled';
import memoize from 'lodash/memoize';
import { sortBy } from '<src>/utils/text';

import * as colors from '<components>/colors';
import SearchBar from '<components>/SearchBar';
import { Accordion, Icon } from 'semantic-ui-react';
import {
  DataSourceHdr,
  FlexColumnContainer,
  StyledScrollbar,
  EmptyListContainer,
} from '../NumbrzPageComponents';
import { formatDataSourceLabel } from '<sections>/data/utils';
import { TextIconBtn } from '<src>/sections/jobs/styles';
import { SectionHdr } from '../DataPicker/styles';
import WaveSpinner from '../WaveSpinner';
import Button from '../Button';

const ListArea = styled('div')`
  overflow-y: auto;
  width: 100%;

  ${(props) => props.flexShrink && { flexShrink: props.flexShrink }};

  margin-bottom: 20px;
  ${StyledScrollbar};
`;

const StyledContent = styled('div')`
  display: flex;
  flex-direction: row;
  max-height: 400px;
  & > .left {
    position: relative;
    height: 100%;
    flex: 2;
    border-right: 1px solid ${colors.gray3};
  }

  & > .right {
    position: relative;
    height: 100%;
    flex: 3;
    overflow-y: auto;
    ${StyledScrollbar};
    padding: 10px;
  }

  & > .left > div {
    padding-right: 15px;
  }
  & > .right > div {
    padding-left: 12px;
  }
`;

export const StyledAccordion = styled(Accordion)`
  .title {
    display: flex;
    align-items: center;

    margin: 0 7px 0px 0px;
    ${(props) => props.selectmode === 'header' && { 'margin-left': '10px' }};
    padding: 0;
    h1 {
      width: 100%;
      font-size: 11px;
      margin: 0;
      padding: 0 !important;
      color: ${colors.gray1b};
      span {
        font-size: 11px;
        font-weight: normal;
      }
    }
    i {
      ${(props) =>
        props.selectmode === 'header' && { display: 'none !important' }};
      font-size: 1.1em !important;
      height: unset !important;
    }
  }
  .content.active {
    margin: 0 6px 5px 20px !important;
    padding: 0 !important;
  }
  .active.title h1 {
    border-bottom: 1px solid ${colors.gray1b};
  }
  .title:hover h1 {
    border-bottom: 1px solid ${colors.gray1b};
  }
`;

export const PanelItem = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  width: 100%;
  padding: 3px 3px 3px 10px;
  margin-bottom: 2px;
  border-radius: 4px;
  color: ${colors.gray1b};
  button {
    color: ${colors.gray1};
    padding-right: 7px;
    &.active {
      font-weight: bold;
    }
  }
  ${(props) =>
    props.active === 'true' && {
      backgroundColor: colors.active_blue,
    }}
  :hover {
    background-color: ${colors.blue9};
    cursor: pointer;
  }
`;

export const PanelTitle = styled('h1')`
  display: flex;
  align-items: center;
  i {
    margin-right: 5px;
  }
`;

export const ListContainer = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: flex-start;

  h5 {
    margin-bottom: 5px;
    margin-top: 10px;
    font-size: 12px;
    padding-left: 5px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
`;

const SearchBarContainer = styled('div')`
  width: 100%;
  margin-bottom: 10px;
`;

export const sortOptions = memoize((options, nameField) => {
  const parents = {};
  const topLevel = [];
  options.forEach((item) => {
    if (item.parent) {
      if (item.parent.value in parents) {
        parents[item.parent.value].options.push(item);
      } else {
        const parent = { ...item.parent, options: [item] };
        topLevel.push(parent);
        parents[parent.value] = parent;
      }
    } else {
      topLevel.push(item);
    }
  });

  sortBy(nameField, topLevel);
  topLevel.forEach((tl) => tl.options && sortBy(nameField, tl.options));
  return topLevel;
});

export const filterOptions = (options, nameField, searchPattern) => {
  if (!searchPattern) return options;

  return options.filter((item) => {
    if (item[nameField].match(searchPattern)) return true;

    if (item.parent) {
      return item.parent[nameField].match(searchPattern);
    }

    return false;
  });
};

function Item({ key, value, op, onChange, selectedItem, renderItemDetail }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <FlexColumnContainer alignItems="center">
      <PanelItem
        key={key}
        active={(value === op.value).toString()}
        onClick={() => onChange(op.value)}
      >
        {op.name}
        <Button
          action="control"
          onClick={() => setExpanded(!expanded)}
          className={expanded && 'active'}
          icon={<Icon name={expanded ? 'angle up' : 'angle down'} />}
          iconPosition="right"
        >
          Details
        </Button>
      </PanelItem>
      {value === op.value && expanded && renderItemDetail(selectedItem)}
    </FlexColumnContainer>
  );
}

export default function NestedPickerPane(props) {
  const [state, setState] = useState({
    searchTerm: '',
    searchPattern: null,
    activeItem: null,
  });
  const [showRec, setShowRec] = useState(true);

  const {
    dialog,
    options = [],
    recommendedOptions = [],
    value,
    nameField = 'name',
    renderItemDetail = (item) => item.name,
    selectMode,
    pickerMode,
    onChange,
    emptyMessage,
    loading = false,
  } = props;
  const { searchPattern, searchTerm } = state;
  const selectedItem = options.find((item) => item.value === value);

  const getPanels = useCallback(
    (options, pickerType) => {
      const panels = [];
      options.forEach((src, idx) => {
        panels.push({
          key: idx,
          title: {
            content: (
              <PanelTitle
                onClick={() =>
                  selectMode === 'header' ? onChange(src.value) : {}
                }
              >
                <DataSourceHdr>
                  {src.name}
                  <span>
                    {formatDataSourceLabel(src.detail.source.__typename)}
                  </span>
                </DataSourceHdr>
              </PanelTitle>
            ),
          },
          content: {
            content: (
              <Fragment>
                {src &&
                  src.options &&
                  src.options.map((op, idx) => {
                    return (
                      <Item
                        key={idx}
                        op={op}
                        value={value}
                        selectedItem={selectedItem}
                        onChange={onChange}
                        renderItemDetail={renderItemDetail}
                      />
                    );
                  })}
              </Fragment>
            ),
          },
        });
      });
      return panels;
    },
    [selectMode, value, onChange, selectedItem, renderItemDetail]
  );

  const filteredOptions = filterOptions(options, nameField, searchPattern);
  const filteredRecommended = filterOptions(
    recommendedOptions,
    nameField,
    searchPattern
  );
  const sorted = sortOptions(filteredOptions, nameField);

  const sortedRecommended = sortOptions(filteredRecommended, nameField);

  return (
    <StyledContent dialog={dialog}>
      <FlexColumnContainer
        width="100%"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {emptyMessage && emptyMessage}
        {loading ? (
          <WaveSpinner />
        ) : options.length === 0 ? (
          <EmptyListContainer>
            <h5>No items found</h5>
          </EmptyListContainer>
        ) : (
          <Fragment>
            <SearchBarContainer>
              <SearchBar
                value={searchTerm}
                onChange={(searchTerm, searchPattern) =>
                  setState({ ...state, searchTerm, searchPattern })
                }
              />
            </SearchBarContainer>
            <ListArea>
              {recommendedOptions.length > 0 && (
                <Fragment>
                  <SectionHdr>
                    Recommended sources
                    <TextIconBtn onClick={() => setShowRec(!showRec)}>
                      {showRec ? (
                        <Fragment>
                          Hide
                          <Icon name="angle up" />
                        </Fragment>
                      ) : (
                        <Fragment>
                          Show
                          <Icon name="angle down" />
                        </Fragment>
                      )}
                    </TextIconBtn>
                  </SectionHdr>

                  {showRec ? (
                    <StyledAccordion
                      selectmode={selectMode}
                      panels={getPanels(sortedRecommended, pickerMode)}
                      exclusive={true}
                    />
                  ) : null}
                </Fragment>
              )}

              {recommendedOptions.length > 0 && (
                <SectionHdr>All sources</SectionHdr>
              )}

              <StyledAccordion
                selectmode={selectMode}
                panels={getPanels(sorted, pickerMode)}
                exclusive={true}
              />
            </ListArea>
          </Fragment>
        )}
      </FlexColumnContainer>
    </StyledContent>
  );
}
