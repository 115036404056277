import React, { useCallback, useRef, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import styled from '@emotion/styled';

import * as colors from '<components>/colors';
import Button from '<src>/components/Button';
import { CompactDropdown } from '<components>/NumbrzPageComponents';

import FieldPicker from '../FieldPicker';

import { opOptions, isInputRequired } from './ops';
import ValuePicker from '../ValuePicker';

const OpDropdown = styled(CompactDropdown)`
  .text:not(.default) {
    font-weight: bold;
    font-size: ${({ value }) => (value.length > 3 ? '1em' : '1.2em')};
    width: ${({ value }) => (value.length <= 3 ? '1em' : undefined)};
  }
`;

function isValid(elementKey, op, input) {
  return elementKey && op && (input || !isInputRequired(op));
}

export default function AddCondition({
  elements,
  vElements = elements,
  onAdd: onAddProp,
  flatPicker = false,
  vFlatPicker = flatPicker,
}) {
  const [elementKey, setElementKey] = useState('');
  const fieldRef = useRef();
  const [op, setOp] = useState('');
  const opRef = useRef();
  const [input, setInput] = useState('');
  const inputRef = useRef();

  vElements = vElements.filter((e) => e.key !== elementKey);

  const onAdd = useCallback(
    (e, { elementKey, op, input }) => {
      if (isValid(elementKey, op, input)) {
        onAddProp(e, { elementKey, op, input });
        setElementKey(null);
        setOp('');
        setInput('');
        if (
          (e && e.type === 'keydown' && e.key === 'Enter') ||
          e.method === 'enterKey'
        ) {
          fieldRef.current.focus();
        }
      } else if (!elementKey) {
        fieldRef.current.focus();
      } else if (!op) {
        opRef.current.focus();
      } else if (!input) {
        inputRef.current.focus();
      }
    },
    [onAddProp]
  );

  return (
    <tr className="input">
      <td className="field">
        <FieldPicker.Dropdown
          ref={fieldRef}
          elements={elements}
          selected={elementKey}
          showTypeFilter={false}
          onChange={(e, { selected: elementKey }) => {
            setElementKey(elementKey);
            if (e.type === 'keydown' && e.key === 'Enter') {
              onAdd(e, { elementKey, op, input });
            }
          }}
          flat={flatPicker}
        />
      </td>
      <td colSpan={isInputRequired(op) ? '1' : '2'}>
        <OpDropdown
          ref={opRef}
          value={op}
          options={opOptions}
          placeholder="op"
          onChange={(e, { value: op }) => {
            setOp(op);
            if (e.type === 'keydown' && e.key === 'Enter') {
              onAdd(e, { elementKey, op, input });
            }
          }}
        />
      </td>
      {isInputRequired(op) ? (
        <td className="field">
          <ValuePicker
            ref={inputRef}
            value={input}
            elements={vElements}
            placeholder="Comparison Value"
            onChange={(e, { value: input }) => {
              setInput(input);
              if (
                (e.type === 'keydown' && e.key === 'Enter') ||
                e.method === 'enterKey'
              ) {
                onAdd(e, { elementKey, op, input });
              }
            }}
            flat={vFlatPicker}
          />
        </td>
      ) : null}
      <td className="btn">
        <Button
          icon={<Icon name="plus" title="Add Schema Element" />}
          disabled={!isValid(elementKey, op, input)}
          onClick={(e) => onAdd(e, { elementKey, op, input })}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onAdd(e, { elementKey, op, input });
            }
          }}
        />
      </td>
    </tr>
  );
}
