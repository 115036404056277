import React, { useCallback, useRef, useState } from 'react';

import ColumnTypePicker from '../ColumnTypePicker';
import Button from '<src>/components/Button';
import { ConfigField as ConfigFieldStyle } from '<src>/components/NumbrzVerticalEditor';

const ConfigField = ConfigFieldStyle.withComponent('input');

export default function AddColumn({ onAddColumn, columnTypeOptions }) {
  const [label, setLabel] = useState('');
  const [type, setType] = useState();
  const [comment, setComment] = useState('');
  const labelRef = useRef();
  const typeRef = useRef();
  const commentRef = useRef();
  const addRef = useRef();

  const addColumn = useCallback(
    async (e) => {
      if (!label || !type) {
        return;
      }

      await onAddColumn(e, { type, label, comment });
      setLabel('');
      setType(null);
      setComment('');
    },
    [type, label, comment, onAddColumn]
  );

  const onKeyDown = useCallback(
    async (e) => {
      if (e.key === 'Enter') {
        e.stopPropagation();
        if (!label) {
          labelRef.current.focus();
        } else if (!type) {
          typeRef.current.focus();
        } else {
          await addColumn(e);
          labelRef.current.focus();
        }
      }
    },
    [addColumn, label, type]
  );

  return (
    <tr>
      <td>
        <div>
          <ConfigField
            ref={labelRef}
            value={label}
            placeholder="New Column"
            onFocus={(e) => {
              e.target.placeholder = '';
            }}
            onBlur={(e) => {
              e.target.placeholder = 'New Column';
            }}
            onChange={(e) => setLabel(e.target.value)}
            onKeyDown={onKeyDown}
          />
        </div>
      </td>
      <td className="type">
        <ColumnTypePicker
          ref={typeRef}
          type={type}
          onChange={(e, { type }) => setType(type)}
          columnTypeOptions={columnTypeOptions}
          onClose={(e) => {
            if (e && e.type === 'keydown' && e.key === 'Enter') {
              commentRef.current.focus();
            }
          }}
        />
      </td>
      <td>
        <ConfigField
          ref={commentRef}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          onKeyDown={onKeyDown}
        />
      </td>
      <td className="btn">
        <Button.TableAddBtn
          ref={addRef}
          disabled={!type || !label}
          onClick={addColumn}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              addColumn(e);
            }
          }}
        />
      </td>
    </tr>
  );
}
