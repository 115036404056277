/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from '@emotion/react';
import fp from 'lodash/fp';

import DurationLabel from '<sections>/jobs/JobStatus/DurationLabel';
import TimeSinceNow from '<sections>/jobs/JobStatus/TimeSinceNow';
import * as styles from '<sections>/jobs/JobStatus/styles';
import {
  getStatusChiclet,
  getFormattedStatus,
} from '<sections>/job-groups/utils';
import { formatISODateString } from '<src>/utils/dates';

export function jobProgress(blocks) {
  if (blocks && blocks.length > 0) {
    const totalStages = blocks.length;
    const totalCompleted = blocks.filter((s) =>
      ['Failed', 'Complete'].includes(s.state)
    ).length;

    if (totalCompleted > 0) return (totalCompleted / totalStages) * 100;
  }

  return 0;
}

export function jobProgressText(blocks) {
  if (blocks && blocks.length > 0) {
    const totalStages = blocks.length;
    const totalCompleted = blocks.filter((s) =>
      ['Failed', 'Complete'].includes(s.state)
    ).length;

    if (totalCompleted === totalStages) return totalCompleted;
    if (totalCompleted > 0) return `${totalCompleted}/${totalStages}`;
  }

  return '0/0';
}

function getStatusEnd(transitions = []) {
  const endT = transitions.find((t) => t.type === 'GroupCompleted');
  if (endT) return endT.timestamp;

  return new Date().toLocaleString();
}

export default function JobGroupProgress({ runState, jobGroupState }) {
  const status = runState && runState.state;
  const transitions = runState ? runState.transitions : [];
  const blocks = fp.getOr([], 'blocks', runState);
  const started = fp.getOr(undefined, 'transitions[0].timestamp', runState);
  const ended = getStatusEnd(transitions);
  const isRunning = status === 'Running';
  const lastAction =
    transitions.length > 0 ? transitions[transitions.length - 1] : null;

  return runState ? (
    <styles.Summary>
      <div>
        <span css={styles.summaryListLabel}>Status: </span>
        {getStatusChiclet(status, isRunning)}
      </div>
      <div>
        <span css={styles.summaryListLabel}>Duration: </span>
        <DurationLabel start={started} end={ended} />
      </div>

      <div>
        <span css={styles.summaryListLabel}>Started: </span>
        <TimeSinceNow>{started}</TimeSinceNow>
      </div>
      <div>
        <span css={styles.summaryListLabel}>Stages Completed: </span>
        {jobProgressText(blocks)}
      </div>
      <div>
        <span css={styles.summaryListLabel}>Last Active: </span>
        {formatISODateString(runState.lastActiveTime, true)}
      </div>
      <div>
        <span css={styles.summaryListLabel}>Last Action: </span>

        {lastAction && lastAction.type
          ? `${getFormattedStatus(lastAction.type)} by ${lastAction.userName}`
          : 'N/A'}
      </div>
    </styles.Summary>
  ) : null;
}
