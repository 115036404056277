import React, { useState } from 'react';
import dayjs from 'dayjs';
import styled from '@emotion/styled';

import { inEms } from '<utils>/math';

import * as colors from '<components>/colors';
import Card from '<components>/Card';
import {
  CardList,
  CardListItem,
  EmptyListContainer,
  FlexRowContainer,
  ListContent,
} from '<components>/NumbrzPageComponents';
import { useRegisteredAccount } from '<components>/RegisterAccount';
import UnderlinedHeader from '<components>/UnderlinedHeader';
import DeleteDialog from './DeleteDialog';
import Button from '<components>/Button';
import Link from '<components>/Link';
import ActionMenu from '<components>/ActionMenu';

const CreationInfo = styled('div')`
  font-size: 0.8em;
  padding-top: 4px;
  color: ${colors.gray6b};
`;

const ReleaseSection = styled(Card)`
  height: fit-content;
  width: 100%;
  padding: 20px;
  margin-bottom: 8px;
`;

const StyledItem = styled(CardListItem)`
  min-height: 0;
  padding-bottom: ${inEms(12)}em;
`;

function DeployedAt({ now, deployment }) {
  return (
    <CreationInfo>Deployed {dayjs(now).to(deployment.createdAt)}</CreationInfo>
  );
}

function DeplItem({
  currentReleaseID,
  depl,
  upgrading,
  user,
  onDelete,
  onUpgrade,
  now,
}) {
  const isMember = !!user.membership.find((m) => m.account.ID === depl.ownerID);
  const canManage = !depl.fromCatalog && isMember;
  return (
    <StyledItem>
      <Card.Header>
        <FlexRowContainer
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          height="25px"
        >
          <Card.Headline style={{ margin: '0' }}>
            <div>{depl.owner.display}</div>
            <DeployedAt now={now} deployment={depl} />
          </Card.Headline>
          <ActionMenu
            options={[
              ActionMenu.deployOption({
                onSelect: (e) => onUpgrade(e, depl.ID),
                disabled: !canManage && !upgrading,
                exclude:
                  depl.fromCatalog || currentReleaseID === depl.sourceReleaseID,
                text: upgrading ? 'Updating' : 'Update',
              }),
              ActionMenu.deleteOption({
                onSelect: (e) => onDelete(e, depl.ID),
                disabled: !canManage,
                exclude: depl.fromCatalog,
                confirmSelect: false,
              }),
            ]}
          />
        </FlexRowContainer>
      </Card.Header>
    </StyledItem>
  );
}

function DeploymentList({
  title,
  deployments,
  currentReleaseID,
  upgradingID,
  onDelete,
  onUpgrade,
  user,
  now,
}) {
  if (deployments.length === 0) {
    return null;
  }
  return (
    <div>
      <UnderlinedHeader>{title}</UnderlinedHeader>
      <CardList>
        {deployments.map((depl) => (
          <DeplItem
            key={depl.ID}
            depl={depl}
            currentReleaseID={currentReleaseID}
            user={user}
            onDelete={onDelete}
            onUpgrade={onUpgrade}
            upgading={depl.ID === upgradingID}
            now={now}
          />
        ))}
      </CardList>
    </div>
  );
}

function DeployButton({ project, onDeploy }) {
  return (
    <Button.ListPageCreateBtn
      title={
        project?.catalog?.currentReleaseID
          ? 'Deploy this model'
          : 'Promote a release before deploying'
      }
      disabled={!project?.catalog?.currentReleaseID}
      onClick={onDeploy}
    >
      Deploy
      <span className="icon">+</span>
    </Button.ListPageCreateBtn>
  );
}

export default function Deployments({
  project,
  deployments,
  onDelete,
  onDeploy,
  onUpgrade,
  now,
}) {
  const [deletingID, setDeletingID] = useState(null);
  const [upgradingID, setUpgradingID] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const user = useRegisteredAccount();

  const onDeleteClicked = (e, ID) => setDeletingID(ID);

  const onUpgradeClicked = async (e, ID) => {
    setUpgradingID(ID);
    await onUpgrade(ID);
    setUpgradingID(false);
  };

  const doDelete = async () => {
    if (!deletingID) return;

    setDeleting(true);
    await onDelete(deletingID);
    setDeletingID(null);
    setDeleting(false);
  };

  return (
    <>
      <ListContent>
        <ReleaseSection>
          <UnderlinedHeader>CURRENT RELEASE</UnderlinedHeader>
          <FlexRowContainer justifyContent="space-between">
            <div>
              {project.catalog.currentRelease
                ? project.catalog.currentRelease.releaseTag
                : 'None'}
            </div>
            <Link internal to={`/svc-models/${project.ID}/releases`}>
              Manage Releases
            </Link>
          </FlexRowContainer>
        </ReleaseSection>
        {deployments.length === 0 ? (
          <EmptyListContainer>
            <h4>No Deployments</h4>
            <DeployButton project={project} onDeploy={onDeploy} />
          </EmptyListContainer>
        ) : (
          <>
            <DeploymentList
              title="MANAGED DEPLOYMENTS"
              deployments={deployments.filter((d) => !d.fromCatalog)}
              currentReleaseID={project?.catalog?.currentReleaseID}
              upgradingID={upgradingID}
              user={user}
              onDelete={onDeleteClicked}
              onUpgrade={onUpgradeClicked}
              now={now}
            />
            <DeploymentList
              title="CATALOG DEPLOYMENTS"
              deployments={deployments.filter((d) => d.fromCatalog)}
              currentReleaseID={project?.catalog?.currentReleaseID}
              user={user}
              now={now}
            />
            <DeleteDialog
              onClose={() => setDeletingID(null)}
              onConfirm={doDelete}
              visible={!!deletingID}
              deleting={deleting}
            />
            <DeployButton project={project} onDeploy={onDeploy} />
          </>
        )}
      </ListContent>
    </>
  );
}
