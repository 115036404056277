import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import AnimateHeight from 'react-animate-height';
import { Icon } from 'semantic-ui-react';

import Button from '<src>/components/Button';
import DeleteButtonWithConfirm from '<src>/components/DeleteButtonWithConfirm';
import ExpandBar from '<components>/ExpandBar';
import { NumbrzTooltip } from '<components>/NumbrzButtons';

import ValidationIssues from '../ValidationIssues';

import { stepsByType } from './stepData';
import { Subtitle, ConfigField } from '<components>/NumbrzVerticalEditor';

import { Header, Title, Body, Card, Toolbar } from './styles';

function StepMoveButton({ onClick, iconName, title }) {
  return (
    <NumbrzTooltip
      content={title}
      trigger={
        <Button
          onClick={onClick}
          size="large"
          icon={<Icon name={iconName} title={title} />}
        />
      }
    />
  );
}

const noop = () => {};

function GenericStep(
  {
    api = {},
    children,
    expanded: expandedProp,
    first,
    last,
    step,
    onExpand = noop,
  },
  parentRef
) {
  const [hasIssues, setHasIssues] = useState(step.issues.length > 0);
  const [expanded, setExpandedState] = useState(hasIssues);
  const onExpandRef = useRef(onExpand);

  const { onChange, onRemove, onMove } = api;
  const stepData = stepsByType[step.__typename];
  const typeLabel = stepData ? stepData.title : null;
  const showTypeLabel =
    typeLabel && step.title
      ? typeLabel.toLowerCase() !== step.title.toLowerCase()
      : true;
  const isOutput = step.__typename === 'OutputStepV2';

  useEffect(() => {
    onExpandRef.current = onExpand;
  }, [onExpand]);
  useEffect(() => {
    if (expandedProp !== undefined) {
      setExpandedState(expandedProp);
    }
  }, [expandedProp]);

  const setExpanded = useCallback(
    (exp) =>
      setExpandedState((prevExp) => {
        if (typeof exp === 'function') {
          exp = exp(prevExp);
        }
        setExpandedState(exp);
        onExpandRef.current(exp, step.key);
      }),
    [step.key]
  );

  const toggleExpand = useCallback(
    () => setExpanded((expanded) => !expanded),
    [setExpanded]
  );

  useEffect(() => {
    setHasIssues(step.issues.length > 0);
  }, [step.issues.length]);

  useEffect(() => {
    if (hasIssues) {
      setExpanded(true);
    }
  }, [hasIssues, setExpanded]);

  const [config, ...detail] = React.Children.toArray(children);
  return (
    <Card ref={parentRef} isOutput={isOutput}>
      <Header isOutput={isOutput}>
        <Title>
          <ConfigField
            singleClick
            placeholder={`${typeLabel} Step`}
            value={step.title}
            disabled={!onChange}
            onSubmit={(e) => onChange(e, { key: step.key, title: e.value })}
            margin="0"
            fontWeight="bold"
          />

          {typeLabel && showTypeLabel ? <Subtitle>{typeLabel}</Subtitle> : null}
        </Title>
        <Toolbar>
          {!!onMove ? (
            <>
              {!first ? (
                <StepMoveButton
                  title="Move Step Up"
                  iconName="arrow alternate circle up outline"
                  onClick={(e) => onMove(e, { key: step.key, dir: 'up' })}
                />
              ) : null}
              {!last ? (
                <StepMoveButton
                  title="Move Step Down"
                  iconName="arrow alternate circle down outline"
                  onClick={(e) => onMove(e, { key: step.key, dir: 'down' })}
                />
              ) : null}
            </>
          ) : null}
          {!!onRemove ? (
            <DeleteButtonWithConfirm
              onClick={(e) => onRemove(e, { key: step.key })}
            />
          ) : null}
        </Toolbar>
      </Header>
      <Body>
        <ValidationIssues
          issues={step.issues}
          header="This step has configuration errors"
        />
        {step.comment || onChange ? (
          <ConfigField
            singleClick
            placeholder="Add a comment"
            value={step.comment}
            disabled={!onChange}
            onSubmit={(e) => onChange(e, { key: step.key, comment: e.value })}
            margin="0 0 10px 0"
          />
        ) : null}
        {config ? config : null}
        {detail.length > 0 ? (
          <>
            <ExpandBar expanded={expanded} onClick={toggleExpand} />
            <AnimateHeight height={expanded ? 'auto' : 0}>
              {detail}
            </AnimateHeight>
          </>
        ) : null}
      </Body>
    </Card>
  );
}

// eslint-disable-next-line no-func-assign
GenericStep = forwardRef(GenericStep);
GenericStep.displayName = 'GenericStep';

export default GenericStep;
