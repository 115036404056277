import React from 'react';
import update from 'immutability-helper';

import Body from './Body';
import Settings from './Settings';
import { WidgetAPI } from '../../contexts';
import useJoinWidgetAPI from '../../gql/useJoinWidgetAPI';

export function EditorAPI({ children }) {
  const api = useJoinWidgetAPI();
  return <WidgetAPI.Provider value={api}>{children}</WidgetAPI.Provider>;
}

function makeSetupAPI(state, baseAPI, setWidget) {
  return {
    updateJoinConfig: (_, { delim, omitEmpty }) => {
      const updates = {};
      if (delim !== undefined) {
        updates.delim = { $set: delim };
      }
      if (omitEmpty !== undefined) {
        updates.omitEmpty = { $set: omitEmpty };
      }
      return setWidget((widget) =>
        update(widget, {
          joinConfig: updates,
        })
      );
    },
  };
}

function makeUIWidget(widget, cells) {
  return {
    ...widget,
    config: widget.joinConfig,
  };
}

export default {
  ID: 'BUILTIN:JOIN',
  builtin: true,
  aggregate: true,
  name: 'Join',
  type: 'Join',
  description: 'Combine multiple strings into a single value',
  Label: (widget) => 'Join Text',
  Settings,
  Body,
  makeSetupAPI,
  makeUIWidget,
  EditorAPI,
  initialSetupState: {
    cells: {
      row1C: { ID: 'row1C', value: null },
      row2C: { ID: 'row2C', value: null },
      resC: { ID: 'resC', value: null },
    },
    widget: {
      name: 'Join Text',
      type: 'Join',
      joinConfig: {
        delim: '',
        omitEmpty: false,
      },
      columns: [
        {
          ID: 'col1',
          cellIDs: ['row1C', 'row2C'],
          template: { ID: 'col1', label: 'Column 1' },
        },
      ],
      rows: [
        {
          ID: 'row1',
          cellIDs: ['row1C'],
          template: { ID: 'row1', label: 'Row 1' },
        },
        {
          ID: 'row2',
          cellIDs: ['row2C'],
          template: { ID: 'row2', label: 'Row 2' },
        },
        {
          ID: 'res',
          cellIDs: ['resC'],
          template: { ID: 'res', label: 'Result' },
        },
      ],
    },
  },
};
