import { useState, useMemo } from 'react';
import { includeCol } from '../utils';

export default function useStepResultState({
  flags,
  elements,
  stepLog,
  builtins = [],
  jobVars = [],
}) {
  const getDefaultFilters = useMemo(() => {
    let filters = {};
    flags.forEach((f) => {
      if (f === 'IncludedNoMatch') {
        filters[f] = false;
      } else {
        filters[f] = true;
      }
    });

    return filters;
  }, [flags]);

  const defaultFields = useMemo(() => {
    let fields = {};

    elements.forEach((c, idx) => {
      const isOutput = stepLog.key === c.source;
      if (isOutput) return false;

      if (
        includeCol(
          true,
          stepLog.toWrite,
          stepLog.inputElementKeys,
          builtins,
          idx,
          c,
          undefined
        )
      ) {
        fields[c.key] = true;
        if (stepLog.key === '') fields[c.key] = false;
        if (builtins.includes(c.key)) fields[c.key] = true;
        if (jobVars.includes(c.key)) fields[c.key] = true;
        if (stepLog.inputElementKeys.includes(c.key)) fields[c.key] = false;
        if (stepLog.toWrite.includes(idx)) fields[c.key] = false;
      }
    });

    return fields;
  }, [elements, stepLog, builtins, jobVars]);

  const [resultsExpanded, setResultsExpanded] = useState(false);
  // hide rows based on the following filters
  const [filters, setFilters] = useState(getDefaultFilters);
  const [flagDialogVisible, setFlagDialogVisible] = useState(false);

  // hide fields based on the following filters
  const [hideFields, setHideFields] = useState(defaultFields);
  const [fieldsDialogVisible, setFieldsDialogVisible] = useState(false);

  const state = {
    filters,
    setFilters,
    hideFields,
    setHideFields,
    defaultFields,
    flagDialogVisible,
    setFlagDialogVisible,
    fieldsDialogVisible,
    setFieldsDialogVisible,
    resultsExpanded,
    setResultsExpanded,
  };

  return state;
}
