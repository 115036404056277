import { gql } from '@apollo/client';

import { FunctionChangesFragment } from './fragments';
import useEditFunction, { optimisticFunctionChanges } from './useEditFunction';

const DeleteWidget = gql`
  mutation DeleteWidget($funcID: CommonID!, $widgetID: ID!) {
    deleteWidgetV3(funcID: $funcID, widgetID: $widgetID) {
      ...FunctionChangesFragment
    }
  }

  ${FunctionChangesFragment}
`;

export default function useDeleteWidget() {
  const deleteWidget = useEditFunction(DeleteWidget, 'deleteWidgetV3');
  return (widget, column) =>
    deleteWidget({
      variables: {
        funcID: widget.funcID,
        widgetID: widget.ID,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        deleteWidgetV3: optimisticFunctionChanges({
          funcID: widget.ID,
          widgetsDeleted: [widget.ID],
        }),
      },
    });
}
