import styled from '@emotion/styled';
import { css } from '@emotion/react';

import * as colors from '<components>/colors';
import Card from '<components>/Card';

const ignoreSsrWarning =
  '/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */';

export const styles = css`
  font-size: 12px;
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 7px 0 5px 0;
    font-weight: bold;
    white-space: nowrap;

    & > *:first-child${ignoreSsrWarning} {
      flex: 1;
    }
  }
  &
    h1:first-child${ignoreSsrWarning},
    &
    h2:first-child${ignoreSsrWarning},
    &
    h3:first-child${ignoreSsrWarning},
    &
    h4:first-child${ignoreSsrWarning},
    &
    h5:first-child${ignoreSsrWarning} {
    margin-top: 0;
  }

  & h1 {
    font-size: 18px;
  }
  & h2 {
    font-size: 14px;
  }
  & h3 {
    font-size: 13px;
    text-transform: uppercase;
    color: ${colors.gray2};
  }
  & h4 {
    font-size: 13px;
  }
  & h6 {
    font-size: 12px;
    color: ${colors.gray2};
  }

  & .ui.form,
  & .ui.checkbox {
    font-size: 12px;
  }
`;

const activeShadow = `${colors.blue2} 0px 0px 6px 0px`;
const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'active',
})`
  padding: 11px;
  border-radius: 9px;
  width: auto;
  height: auto;
  border: 1px solid ${colors.gray3};
  outline: none;
  transition: box-shadow 225ms ease-in-out;
  box-shadow: ${({ active }) => (active ? activeShadow : undefined)};
  ${styles}
`;

StyledCard.Header = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 0 -11px 6px -11px;
  padding: 0 11px 0 11px;
  border-bottom: 1px solid ${colors.gray4};
`;

StyledCard.Footer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  & button {
    margin-top: 2px;
  }
`;

export default StyledCard;
