/** @jsx jsx */
/** @jsxRuntime classic */

import styled from '@emotion/styled';
import { jsx, css, ClassNames } from '@emotion/react';
import { withRouter, NavLink } from 'react-router-dom';
import { NumbrzTooltip } from '<components>/NumbrzButtons';
import { ErrorChiclet } from '<components>/NumbrzPageComponents';

import { Icon } from 'semantic-ui-react';
import * as colors from '<components>/colors';

const StyledMenu = styled('div')`
  width: ${(props) => (props.layout === 'vertical' ? 'fit-content' : '100%')};
  background: ${(props) =>
    props.layout === 'vertical'
      ? 'transparent'
      : props.backgroundColor
      ? props.backgroundColor
      : colors.gray9};

  padding: ${(props) => (props.layout === 'vertical' ? '2em 2em 0 0' : '0')};
  ${(props) => props.leftPadding && { 'padding-left': props.leftPadding }};
  ${(props) =>
    props.colorStyle &&
    props.colorStyle === 'shadow' && {
      flex: '2',
      padding: '2.5em 3.5em 0 0.5em',
    }};
  ${(props) =>
    props.layout === 'horizontal' && {
      'box-shadow': 'inset 0px -2px 3px #161f2c;',
    }};
  z-index: 1;
`;

const StyledContent = styled('div')`
  flex: 0;
  display: flex;
  flex-direction: ${(props) =>
    props.layout === 'vertical' ? 'column' : 'row'};
  justify-content: left;
  align-items: ${(props) =>
    props.layout === 'vertical' ? 'flex-start' : 'center'};
  flex-wrap: wrap;
  padding: ${(props) => (props.layout === 'vertical' ? '0px 10px 0 5px' : '0')};
  margin: ${(props) => (props.layout === 'vertical' ? '0px 0 0 5px' : '0')};
  a {
    border-left: ${(props) =>
      props.layout === 'vertical' ? `2px solid ${colors.gray3}` : 'none'};
    display: flex;
    align-items: center;
    width: ${(props) => (props.layout === 'vertical' ? '100%' : 'fit-content')};

    position: relative;
    letter-spacing: 0.4px;
    font-size: 14px;

    margin: ${(props) => (props.layout === 'vertical' ? '0 0 0 40px' : '0')};
    padding: ${(props) =>
      props.layout === 'vertical' ? '4px 15px' : '12px 12px 13px 12px'};
    color: ${(props) =>
      props.layout === 'vertical' ? colors.gray1b : 'white'};

    i {
      font-size: 1.2em !important;
      margin-right: 5px;
      color: ${colors.gray1b};
    }

    :hover {
      ${(props) => props.layout === 'horizontal' && { background: '#2b3747' }}
    }

    ${(props) =>
      props.colorStyle &&
      props.colorStyle === 'shadow' && {
        background: 'white',
        'box-shadow':
          'rgba(60, 66, 87, 0.12) 0px 6px 13px 0px,rgba(0, 0, 0, 0.12) 0px 2px 5px 0px',
        'border-radius': '4px',
        'margin-bottom': '15px',
        'font-weight': 'bold',
        padding: '12px',
      }};

    position: relative;
    i.icons {
      margin: 0 5px 0 15px !important;
      .icon {
        left: 49.5%;
      }
      i {
        margin: 0 !important;
        height: unset !important;
        width: 1em !important;
      }
      i.circle {
        color: ${colors.red1c} !important;
        font-size: 1.3em;
      }
      i.exclamation {
        color: white;
        font-size: 0.7em !important;
      }
    }
  }
`;

const activeClassName = css`
  color: ${colors.blue1} !important;
  background: #2b3747;
`;

const verticalActiveClassName = css`
  font-weight: bold !important;
  border-left: 2px solid ${colors.gray1b} !important;
  background: ${colors.gray7};
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
`;

const verticalShadowActiveClassName = css`
  color: white !important;
  background: ${colors.gray1b} !important;
  i {
    color: white !important;
  }
`;

const Navigation = ({
  navigation,
  leftPadding,
  borderTop,
  borderBottom,
  layout = 'horizontal',
  colorStyle,
}) => (
  <StyledMenu
    borderTop={borderTop}
    borderBottom={borderBottom}
    leftPadding={leftPadding}
    layout={layout}
    colorStyle={colorStyle}
  >
    <StyledContent layout={layout} colorStyle={colorStyle}>
      {navigation.map(({ exact, path, to, title, isActive, issues }) => (
        <ClassNames key={path}>
          {({ css }) => (
            <NavLink
              exact={exact}
              to={to || path}
              activeClassName={css`
                ${layout === 'vertical'
                  ? colorStyle
                    ? verticalShadowActiveClassName
                    : verticalActiveClassName
                  : activeClassName}
              `}
              isActive={isActive}
            >
              <span>{title}</span>
              {issues && issues.hasIssues && (
                <NumbrzTooltip
                  position="top left"
                  content={issues.message}
                  trigger={
                    <ErrorChiclet visible="true">
                      <Icon name="circle" />
                      <Icon name="exclamation" title={issues.message} />
                    </ErrorChiclet>
                  }
                />
              )}
            </NavLink>
          )}
        </ClassNames>
      ))}
    </StyledContent>
  </StyledMenu>
);

const Menu = ({
  navigation,
  leftPadding,
  borderTop,
  borderBottom,
  backgroundColor,
  layout,
  colorStyle,
}) => {
  if (navigation === false) return null;
  return (
    <Navigation
      navigation={navigation}
      leftPadding={leftPadding}
      borderTop={borderTop}
      borderBottom={borderBottom}
      backgroundColor={backgroundColor}
      layout={layout}
      colorStyle={colorStyle}
    />
  );
};

export default withRouter(Menu);
