import React, { useEffect, useState } from 'react';
import { Message } from 'semantic-ui-react';

import { useMutation, useQuery } from '<src>/apollo/client';

import { GetTablesets } from '<sections>/data/queries';
import { DeployService, GetDeploymentSpec } from '../../queries';

import { useOAuth } from '<sections>/oauth';

import Dialog from '<components>/Dialog';
import { useRegisteredAccount } from '<components>/RegisterAccount';
import WaveSpinner from '<src>/components/WaveSpinner';
import Button from '<src>/components/Button';
import DeploymentWizard from '../DeploymentWizard';
import DeployProgress from './DeployProgress';
import { ContentWrapper } from '<src>/sections/flows/styles';

export default function DeployDialog({
  projectID,
  isManaged,
  visible,
  onRequestClose,
  skipAccounts,
}) {
  const [error, setError] = useState(null);
  const [deploying, setDeploying] = useState(false);
  const [newProject, setNewProject] = useState(null);
  const [ownerID, setOwnerID] = useState(null);
  const [name, setName] = useState(null);
  const user = useRegisteredAccount();

  const [deployService] = useMutation(DeployService);
  const { data: tsData } = useQuery(GetTablesets, {
    skip: !ownerID || !projectID,
    variables: { ownerID },
  });
  const {
    loading: deplLoading,
    data: deplData,
    called: deplCalled,
    error: fetchError,
  } = useQuery(GetDeploymentSpec, {
    skip: !visible || !projectID,
    variables: {
      input: {
        projectID: projectID,
        isManaged,
      },
    },
  });
  const [doOAuth] = useOAuth();

  useEffect(() => {
    if (fetchError) {
      setError(fetchError);
    }
  }, [fetchError]);

  useEffect(() => {
    if (!visible) {
      setOwnerID(skipAccounts ? user.primaryAccount.ID : null);
      setName(null);
      setDeploying(false);
    } else {
      window.Intercom('trackEvent', 'project_deploy_start', {
        sourceID: projectID,
        isManaged,
        type: 'Service',
      });
    }
  }, [isManaged, projectID, skipAccounts, user.primaryAccount.ID, visible]);

  useEffect(() => {
    if (deplData && deplData.deploymentSpec) {
      setName(deplData.deploymentSpec.defaultName);
    }
  }, [deplData]);

  const onDeploy = async ({ ownerID, tablesets }) => {
    setDeploying(true);
    const { data, error } = await deployService({
      variables: {
        input: {
          projectID,
          isManaged,
          name,
          ownerID,
          tablesets,
        },
      },
    });
    if (error) {
      setError(error);
    }
    const { deployService: newProject } = data;
    setNewProject(newProject);
  };

  const accounts = skipAccounts ? null : user.membership.map((m) => m.account);
  const tablesets = ownerID && tsData ? tsData.tablesets.tablesets : null;

  let body;

  if (error) {
    body = <Message error>{error.message}</Message>;
  } else if (deplLoading || !deplCalled) {
    body = (
      <ContentWrapper>
        <WaveSpinner />
      </ContentWrapper>
    );
  } else if (deploying) {
    const newProjectID = newProject?.ID;
    body = (
      <DeployProgress
        sourceID={projectID}
        projectID={newProjectID}
        isManaged={isManaged}
      />
    );
  } else if (!deplData.deploymentSpec) {
    body = null;
  } else {
    const { deploymentSpec } = deplData;
    body = (
      <DeploymentWizard
        accounts={accounts}
        existingTablesets={tablesets}
        name={name}
        deployIntro={deploymentSpec.deployIntro}
        ownerID={ownerID}
        tablesetDeployments={deploymentSpec.tablesets}
        onDeploy={onDeploy}
        onDoOAuth={doOAuth}
        onSetName={setName}
        onSetOwnerID={setOwnerID}
      />
    );
  }

  return (
    <Dialog
      visible={visible}
      onRequestClose={onRequestClose}
      style={{ maxWidth: 'initial', minWidth: '450px' }}
    >
      <Dialog.Header>
        <Dialog.Headline>Deploy Model</Dialog.Headline>
        <Button.DialogClose onClick={onRequestClose} />
      </Dialog.Header>
      <Dialog.Body>{body}</Dialog.Body>
    </Dialog>
  );
}
