import React from 'react';

import Description from '<src>/components/Description';

export default function FlowOverview({ flow, onChange }) {
  return (
    <Description
      content={flow.comment}
      editable={!!onChange}
      onChange={(comment) => onChange(null, { comment })}
      placeholder="No description provided"
      label="documentation"
    />
  );
}
