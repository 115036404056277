/** @jsx jsx */
/** @jsxRuntime classic */
import { Fragment, useEffect, useState, useMemo, useRef } from 'react';

import { jsx, css } from '@emotion/react';
import fp from 'lodash/fp';
import DocumentTitle from 'react-document-title';
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import DataPickerDialog from '<components>/DataPicker/Dialog';
import MainLayout from '<components>/MainLayout';
import JobSidebar from '../JobSidebar';
import Masthead from '<components>/Masthead';
import ActionMenu from '<src>/components/ActionMenu';
import {
  EmptyListContainer,
  PageContainer,
  PageMasthead,
  PageContent,
  ColumnWrapper,
  Separator,
  FlexRowContainer,
} from '<components>/NumbrzPageComponents';

import WaveSpinner from '<components>/WaveSpinner';
import RunJobErrorDialog from '../components/RunJobErrorDialog';
import JobOverview from '../JobOverview';

import JobData from '../JobConfiguration';

import JobVariables from '../JobVariables';
import JobStatus from '../JobStatus';
import RecentRuns from '../RunHistory';

import ResizeableSplitView from '<src>/sections/flows/flow-testing/ResizeableSplitView';
import {
  SidebarWrapper,
  SidebarHeader,
  SidebarContent,
  FlowContainer,
} from '<src>/components/NumbrzVerticalEditor';

import ErrorMessages from '<src>/components/ErrorMessages';
import useJobState from '../api/useJobState';

import { TitleField } from '<src>/sections/flows/styles';
import Button from '<src>/components/Button';
import { getStatusChiclet } from '<sections>/job-groups/utils';

function resolveIssues(job) {
  const issues = job.issues || [];
  return issues.map((issue) => {
    if (issue.code === 'NotFound') {
      return {
        ...issue,
        message: issue.message || 'This job references a non-existent entity.',
      };
    }

    return issue;
  });
}

function LoadFirstStatus({ jobState, handleRunJob, running }) {
  const {
    job,
    runHistory = [],
    loadRunHistory,
    calledRunHistory,
    loadingRunHistory,
  } = jobState;
  const match = useRouteMatch();

  useEffect(() => {
    if (runHistory.length === 0 && !loadingRunHistory && !calledRunHistory)
      loadRunHistory();
  }, [calledRunHistory, loadRunHistory, loadingRunHistory, runHistory.length]);

  if (!calledRunHistory || loadingRunHistory) {
    return <WaveSpinner />;
  }
  if (runHistory[0]) {
    return <Redirect to={`${match.url}/${runHistory[0].runID}`} />;
  }

  return (
    <EmptyListContainer>
      <h4>This job has not been run yet</h4>
      <Button.Run
        css={[
          css`
            font-size: 14px;
            text-transform: initial;
          `,
        ]}
        onClick={handleRunJob}
        disabled={running || !job.runnable}
      >
        {running ? 'Running job...' : 'Run it now'}
      </Button.Run>
    </EmptyListContainer>
  );
}

export default function JobPage() {
  const history = useHistory();
  const match = useRouteMatch();
  const { jobID, projectID } = useParams();
  const [running, setRunning] = useState();
  const [cancelling, setCancelling] = useState(false);
  const [runResult, setRunResult] = useState();
  const [sidebarVisible, setSidebarVisible] = useState(true);

  const [activeFlowTbl, setActiveFlowTbl] = useState(null);
  const [dataDialogVisible, setDataDialogVisible] = useState(false);

  const jobState = useJobState({ jobID, containerID: projectID });
  const {
    job,
    project,
    loadingJob,
    tables,
    loadingTables,
    runJob,
    cancelJob,
    updateJob,
    jobStatus = {},
    loadingStatus,
    updateJobDataMapping,
    runHistory,
    loadingRunHistory,
    startRunHistoryPolling,
    stopRunHistoryPolling,
    loadRunHistory,
    calledRunHistory,
    calledJobStatus,
  } = jobState;

  const { status, runID } = jobStatus;
  const { externalResultURL } = job;
  const isRunning = status === 'Running';

  const errors = useMemo(() => {
    return (
      <ErrorMessages
        issues={resolveIssues(job)}
        message="There are issues in this job:"
        noShadow={false}
        margin="0 0 10px 0"
      />
    );
  }, [job]);

  const nameRef = useRef();
  const flowData = fp.getOr([], 'interface.data', job);
  const flowTables = useMemo(
    () => (flowData || []).filter((fT) => fT.prefSource !== 'WorkingTable'),
    [flowData]
  );

  const srcProjectID =
    project.type === 'Service' ? project.service.sourceProjectID : project.ID;

  const isBundle = fp.getOr(false, 'interface.isBundle', job);
  const flowLink = !isBundle
    ? `/models/${srcProjectID}/flows/${job.flowID}/overview`
    : null;

  const datamap = fp.getOr([], 'datamap', job);
  const missingData = datamap.filter((dM) => !dM.datasetID);
  const dataWithIssues = datamap.filter(
    (dM) => dM.datasetID && dM.issues.length > 0
  );

  // don't allow users to map an external data source that has already been mapped
  // to the same job table
  const filteredDataTables = useMemo(() => {
    return tables.filter((t) => {
      let flag = true;
      if (activeFlowTbl) {
        const tablesUsed = datamap
          .filter((dM) => dM.tableID === activeFlowTbl.ID)
          .map((m) => m.datasetID);
        return !tablesUsed.includes(t.ID);
      }
      return flag;
    });
  }, [activeFlowTbl, datamap, tables]);

  const runFailed =
    !!runResult && !fp.getOr(false, 'data.runJob.success', runResult);

  const handleInputChange = async (fieldName, newValue) => {
    if (job[fieldName] !== newValue) {
      updateJob({
        variables: {
          input: { ID: job.ID, [fieldName]: newValue },
        },
      });
    }
  };

  const handleRunJob = async () => {
    const input = {
      jobID: job.ID,
    };
    setRunning(true);
    setCancelling(false);
    const res = await runJob({
      variables: { input },
    });
    setRunResult(res);

    if (fp.getOr(undefined, 'data.runJob.success', res)) {
      const url = `${match.url}/status/${res.data.runJob.runID}`;

      history.push(url);
    }
  };

  const handleCancelJob = async () => {
    setCancelling(true);
    setRunning(false);
    await cancelJob({ variables: { runID: runID } });
    setTimeout(() => setCancelling(false), 4000);
  };

  const onMapField = ({
    datasetID,
    fieldKey,
    columnID,
    userVal,
    dataMapping,
  }) => {
    if (dataMapping) {
      const newDataMapping = JSON.parse(JSON.stringify(dataMapping));
      const columnMapping = newDataMapping.columnMap.find(
        (cM) => cM.fieldKey === fieldKey
      );
      if (!columnMapping) {
        newDataMapping.columnMap.push({
          fieldKey,
          columnID,
          inputOverride: userVal,
        });
      }
      if (columnMapping) {
        if (columnID) {
          if (columnID === 'none') {
            newDataMapping.columnMap = newDataMapping.columnMap.filter(
              (m) => m.fieldKey !== columnMapping.fieldKey
            );
          } else {
            columnMapping.columnID = columnID;
            columnMapping.inputOverride = null;
          }
        }
        if (userVal) {
          columnMapping.inputOverride = userVal;
          columnMapping.columnID = null;
        }
      }

      updateMapping(newDataMapping);
    }
  };

  const onUnmapTable = (dataMapping) => {
    updateMapping(dataMapping, true);
  };

  const onMapTable = (dataTableID, flowTableID) => {
    if (activeFlowTbl || flowTableID) {
      const newMapping = {
        datasetID: dataTableID,
        tableID: flowTableID ? flowTableID : activeFlowTbl.ID,
      };
      updateMapping(newMapping, false, true);

      setDataDialogVisible(false);
      setActiveFlowTbl(null);
    }
  };

  const updateMapping = async (
    dataMapping,
    unmapDataset = false,
    addNew = false
  ) => {
    // pull out attributes not accepted as input
    const { issues, dataset, table, ...newMapping } = dataMapping;

    await updateJobDataMapping({
      variables: {
        jobID: job.ID,
        dataMapping: newMapping,
        unmapDataset,
        addNew,
      },
    });
  };

  const SidebarToggleBtn = sidebarVisible
    ? Button.SidebarHideBtn
    : Button.SidebarShowBtn;

  const body = () => {
    return (
      <PageContainer>
        <ResizeableSplitView
          left={
            <Fragment>
              <SidebarWrapper visible={sidebarVisible}>
                <SidebarHeader>
                  <span />
                  {sidebarVisible && (
                    <SidebarToggleBtn
                      title="Show navigation"
                      onClick={() => setSidebarVisible(!sidebarVisible)}
                    />
                  )}
                </SidebarHeader>
                <SidebarContent>
                  <JobSidebar
                    job={job}
                    project={project}
                    missingDataCount={missingData.length}
                    configIssueCount={dataWithIssues.length}
                    baseURL={match.url}
                  />
                </SidebarContent>
              </SidebarWrapper>

              <RunJobErrorDialog
                visible={running && runFailed}
                runResult={runResult}
                onClose={() => {
                  setRunning(false);
                  setRunResult(null);
                }}
              />
              <FlowContainer>
                <PageMasthead>
                  {loadingJob ? null : (
                    <Fragment>
                      <div style={{ display: 'flex' }}>
                        <h5>
                          <FlexRowContainer
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            {!sidebarVisible && (
                              <SidebarToggleBtn
                                onClick={() =>
                                  setSidebarVisible(!sidebarVisible)
                                }
                              />
                            )}
                            <TitleField
                              ref={nameRef}
                              placeholder="Click to edit"
                              singleClick
                              value={job.name}
                              onSubmit={(e) =>
                                handleInputChange('name', e.value)
                              }
                            />
                            <ActionMenu
                              menuIconDark={true}
                              menuIconName="angle down"
                              menuDirection="right"
                              options={[
                                {
                                  value: 'flow',
                                  icon: 'arrow right',
                                  isLink: true,
                                  to: flowLink,
                                  isInternal: true,
                                  text: 'View flow',
                                  onSelect: () => {},
                                  exclude: isBundle,
                                },
                                {
                                  value: 'results',
                                  text: 'Open external results',
                                  icon: 'chart bar outline',
                                  isLink: true,
                                  href: externalResultURL,
                                  onSelect: () => {},
                                  exclude: !externalResultURL,
                                },
                              ]}
                            />
                          </FlexRowContainer>
                        </h5>
                        <Switch>
                          <Route path={`${match.url}/status/:runID?`}>
                            <div style={{ display: 'flex' }}>
                              {status && (
                                <Fragment>
                                  <Separator />
                                  {getStatusChiclet(status, isRunning)}
                                </Fragment>
                              )}
                            </div>
                          </Route>
                        </Switch>
                      </div>

                      <div>
                        {running && (
                          <Button.Cancel
                            size="small"
                            onClick={handleCancelJob}
                            disabled={cancelling || !jobStatus.runID}
                          >
                            {cancelling ? 'Cancelling...' : 'Cancel'}
                          </Button.Cancel>
                        )}
                        {!running && !job.isBase && (
                          <Button.Run
                            size="small"
                            onClick={handleRunJob}
                            disabled={loadingStatus || running || !job.runnable}
                          >
                            {running ? 'Running...' : 'Run'}
                          </Button.Run>
                        )}
                      </div>
                    </Fragment>
                  )}
                </PageMasthead>
                <ColumnWrapper>
                  <PageContent style={{ maxWidth: '1000px' }}>
                    {loadingJob ? null : (
                      <Switch>
                        <Route exact path={match.url}>
                          {() => {
                            let page = 'overview';
                            if (dataWithIssues.length > 0) {
                              page = 'configuration';
                            }
                            return <Redirect to={`${match.url}/${page}`} />;
                          }}
                        </Route>

                        <Route path={`${match.url}/status/:runID`}>
                          <JobStatus
                            jobState={jobState}
                            running={running}
                            setRunning={setRunning}
                          />
                        </Route>
                        <Route path={`${match.url}/status`}>
                          <LoadFirstStatus
                            jobState={jobState}
                            handleRunJob={handleRunJob}
                            running={running}
                          />
                        </Route>
                        <Route path={`${match.url}/recent-runs`}>
                          <RecentRuns
                            baseURL={match.url}
                            jobID={jobID}
                            projectID={project.ID}
                            recentRuns={runHistory}
                            loading={loadingRunHistory}
                            startPolling={startRunHistoryPolling}
                            stopPolling={stopRunHistoryPolling}
                            loadRunHistory={loadRunHistory}
                            calledRunHistory={calledRunHistory}
                            calledJobStatus={calledJobStatus}
                            jobRunning={running}
                          />
                        </Route>

                        <Route path={`${match.url}/configuration`}>
                          <JobData
                            loadingJob={loadingJob}
                            dataMappings={job.datamap}
                            flowTables={flowTables}
                            onUnlinkTable={onUnmapTable}
                            onMapField={onMapField}
                            onSetFilter={updateMapping}
                            flowID={job.flowID}
                            flowName={job.interface.name}
                            flowLink={flowLink}
                            projectID={project.ID}
                            onShowPicker={(flowTable) => {
                              setActiveFlowTbl(flowTable);
                              setDataDialogVisible(true);
                            }}
                            onMapAnyTable={() => {
                              setActiveFlowTbl(null);
                              setDataDialogVisible(true);
                            }}
                            errors={errors}
                            loadingTables={loadingTables}
                            externalResultURL={externalResultURL}
                            isBundle={isBundle}
                          />
                        </Route>

                        <Route path={`${match.url}/overview`}>
                          <JobOverview
                            job={job}
                            flowID={job.flowID}
                            flowName={job.interface.name}
                            flowLink={flowLink}
                            projectID={project.ID}
                            dataMappings={job.datamap}
                            flowTables={flowTables}
                            errors={errors}
                            handleInputChange={handleInputChange}
                            loading={loadingTables || loadingJob}
                          />
                        </Route>
                        <Route path={`${match.url}/variables`}>
                          <JobVariables
                            jobID={job.ID}
                            flowID={job.flowID}
                            flowName={job.interface.name}
                            errors={errors}
                            flowVariables={job.interface.userEnv}
                            jobVariables={job.variables}
                          />
                        </Route>
                      </Switch>
                    )}
                  </PageContent>
                </ColumnWrapper>
              </FlowContainer>
            </Fragment>
          }
          right={null}
        />
      </PageContainer>
    );
  };

  let breadcrumbs;
  if (project) {
    let modelPath = '/models';
    let modelLabel = 'Models';
    let jobPath = '/jobs';
    let jobLabel = 'Jobs';
    if (project.type === 'Service') {
      modelLabel = 'Service Models';
      modelPath = '/svc-models';
      if (job.isBase && !job.serviceReleaseID) {
        jobPath = '/svc-jobs';
        jobLabel = 'Service Jobs';
      } else if (job.isBase && job.serviceReleaseID) {
        jobPath = '/prod-jobs';
        jobLabel = 'Production Jobs';
      } else {
        jobPath = '/tmpl-jobs';
        jobLabel = 'Template Jobs';
      }
    }
    breadcrumbs = [
      { title: modelLabel, path: modelPath, type: 'root' },
      { title: project.name, path: `${modelPath}/${project.ID}` },
      {
        title: jobLabel,
        path: `${modelPath}/${project.ID}${jobPath}`,
        type: project.type === 'Service' ? 'svc-model' : 'model',
        model: project,
      },
    ];
  } else {
    breadcrumbs = [{ title: 'Jobs', path: '/jobs' }];
  }

  const dpTitle = !activeFlowTbl
    ? 'Map model data'
    : `Map ${activeFlowTbl.name}`;

  return (
    <DocumentTitle title={`${job ? job.name : 'Job'} - Numbrz`}>
      <MainLayout
        overflowY="hidden"
        navigation={false}
        header={
          <Masthead
            title={`${job ? job.name : 'Detail'}`}
            breadcrumbs={breadcrumbs}
            runBtnVisible={false}
            addShadow
          />
        }
        main={
          loadingJob ? (
            <WaveSpinner />
          ) : (
            <Fragment>
              <PageContainer>{body()}</PageContainer>
              <DataPickerDialog
                title={dpTitle}
                visible={dataDialogVisible}
                onClose={() => {
                  setDataDialogVisible(false);
                  setActiveFlowTbl(null);
                }}
                onSelect={onMapTable}
                loading={loadingTables}
                tables={filteredDataTables}
                flowTables={flowTables}
                requireFlowTable={!activeFlowTbl}
                dataMappings={job.datamap}
                selectLabel={'Map'}
              />
            </Fragment>
          )
        }
      />
    </DocumentTitle>
  );
}
