import styled from '@emotion/styled';

import * as colors from '<components>/colors';

export const ConnectActions = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  button {
    margin-top: 10px;
  }
`;

export const ListPicker = styled('select')`
  border: none;
  outline: none;
  overflow-y: auto;
  font-size: 1.2em;

  & option {
    padding: 4px 6px;
    border-radius: 4px;
  }
  & option:hover {
    background-color: ${colors.gray4};
    cursor: pointer;
  }
  & option:checked {
    background-color: ${colors.gray3a};
  }
`;
