import React, { Fragment, useState } from 'react';
import fp from 'lodash/fp';
import orderBy from 'lodash/orderBy';

import RunJobErrorDialog from '<sections>/jobs/components/RunJobErrorDialog';
import NumbrzEntityList from '<components>/NumbrzEntityList';

export default function ProjectList({
  loading,
  projects = [],
  deleteProject,
  runJob,
}) {
  const [runningProjectID, setRunningProjectID] = useState();
  const [runResult, setRunResult] = useState();

  const onDeleteProject = (ID) => deleteProject({ variables: { ID } });

  const runFailed =
    !!runResult && !fp.getOr(false, 'data.runJob.success', runResult);

  return (
    <Fragment>
      <NumbrzEntityList
        loading={loading}
        items={orderBy(projects, ['name'], ['asc'])}
        entityType="model"
        runningItemID={runningProjectID}
        onRunItem={runJob}
        setRunResult={setRunResult}
        onDeleteItem={onDeleteProject}
      />

      <RunJobErrorDialog
        visible={runFailed}
        runResult={runResult}
        onClose={() => {
          setRunningProjectID(null);
          setRunResult(null);
        }}
      />
    </Fragment>
  );
}
