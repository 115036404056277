import { SwitchWidgetEditor } from './Body';
import {
  EditorAPI,
  initialSetupState,
  makeSetupAPI,
  makeUIWidget,
} from './api';

export default {
  ID: 'BUILTIN:SWITCH',
  type: 'Switch',
  builtin: true,
  name: 'Switch',
  description: `Select values based on a set of rules. A rule consists of
    one or more conditions, and an output value. The widget result is the
    output value of the first rule whose conditions all match.`,
  Label: (widget) => 'Switch',
  Body: SwitchWidgetEditor,
  EditorAPI,
  initialSetupState,
  makeSetupAPI,
  makeUIWidget,
};
