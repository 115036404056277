import React, { Fragment } from 'react';
import fp from 'lodash/fp';
import { Icon, Message } from 'semantic-ui-react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { LabelButton } from '<src>/components/NumbrzButtons';
import ActivePlan from '../components/ActivePlan';
import DeleteButton from '<components>/DeleteButtonWithConfirm';

import UpcomingInvoice from '../components/UpcomingInvoice';
import RecentInvoices from '../components/RecentInvoices';

import {
  StyledH2,
  PaymentMethodList,
  PaymentMethodListitem,
  CardInfo,
  StyledIconButton,
  CardToolbar,
  DefaulLbl,
  BillingWrapper,
  ColWrapper,
  Col,
  ColFooter,
  LatestInvoiceContainer,
} from '../styles';

function fetchCardIconName(cardBrand) {
  switch (cardBrand) {
    case 'visa' || 'Visa':
      return 'cc visa';
    case 'amex' || 'American Express':
      return 'cc amex';
    case 'discover' || 'Discover':
      return 'cc discover';
    default:
      return 'credit card outline';
  }
}

function CardPaymentMethods(
  paymentMethods,
  defaultPaymentMethod,
  customerID,
  openUpdatePaymentDialog,
  deletePaymentMethod,
  hideToolbar
) {
  return paymentMethods.map((p) => (
    <PaymentMethodListitem key={p.id}>
      <CardInfo>
        <Icon name={fetchCardIconName(p.card.brand)} size="large" />
        {`${p.card.brand.toUpperCase()} ending in ${p.card.last4}`}
        {p.id === defaultPaymentMethod && <DefaulLbl>Default</DefaulLbl>}
      </CardInfo>
      {!hideToolbar && (
        <CardToolbar>
          <StyledIconButton
            name="cog"
            onClick={() => openUpdatePaymentDialog(p)}
          />
          <DeleteButton
            onClick={async () =>
              await deletePaymentMethod({
                variables: {
                  input: {
                    paymentID: p.id,
                    customerID,
                  },
                },
              })
            }
          />
        </CardToolbar>
      )}
    </PaymentMethodListitem>
  ));
}

export default function BillingOverview({
  activeSubscription,
  activeSubscriptionPlan,
  primaryAccount,
  defaultPlan,
  activePlan,
  defaultPaymentMethod,
  stripeCustomerID,
  stripePlanCancelsAtEndOfCycle,
  stripePlanWillChangeAtEndOfCycle,
  stripePlanCancelDate,
  customerInformation,
  viewer,
  isAccountDeleted,
  isUserAdmin,
  products,
  productPrices,
  featureFlags,
  customerCardPaymentMethods,
}) {
  const match = useRouteMatch();
  const history = useHistory();
  const upcomingInvoice = fp.getOr(
    undefined,
    'upcomingInvoice',
    customerInformation
  );
  const recentInvoices = fp.getOr([], 'invoices', customerInformation);
  const referralPlanID = fp.getOr(undefined, 'params.plan_name', match);

  return isAccountDeleted ? (
    <Message error>
      Your billing account has been deleted. Please contact us for more
      information.
    </Message>
  ) : (
    <BillingWrapper>
      <ColWrapper>
        <Col>
          {((!referralPlanID && (activePlan || defaultPlan)) ||
            (referralPlanID && activePlan)) && (
            <ActivePlan
              isDefaultPlan={!activePlan}
              primaryAccount={primaryAccount}
              userAccountID={fp.getOr(undefined, 'account.ID', viewer)}
              stripeCustomerID={stripeCustomerID}
              activePlan={activePlan || defaultPlan}
              plans={products}
              prices={productPrices}
              subscription={activeSubscription}
              history={history}
              match={match}
              featureFlags={featureFlags}
              invoices={recentInvoices}
              stripePlanCancelsAtEndOfCycle={stripePlanCancelsAtEndOfCycle}
              stripePlanWillChangeAtEndOfCycle={
                stripePlanWillChangeAtEndOfCycle
              }
              stripePlanCancelDate={stripePlanCancelDate}
              activeSubscriptionPlan={activeSubscriptionPlan}
            />
          )}

          <ColFooter>
            {isUserAdmin && (
              <LabelButton
                onClick={() => {
                  const planPath =
                    primaryAccount.__typename === 'User'
                      ? 'personal-plans'
                      : 'team-plans';
                  history.push(`${match.url}/${planPath}`);
                }}
              >
                Change Plan
              </LabelButton>
            )}
          </ColFooter>
        </Col>
        {isUserAdmin && (
          <Col>
            <div>
              <StyledH2>
                <span>Payment Methods</span>
              </StyledH2>

              {customerCardPaymentMethods.length === 0 && (
                <p>No payment methods found.</p>
              )}
              {customerCardPaymentMethods.length > 0 && (
                <PaymentMethodList>
                  {CardPaymentMethods(
                    customerCardPaymentMethods.slice(0, 2),
                    defaultPaymentMethod,
                    customerInformation.id,
                    () => {},
                    () => {},
                    true
                  )}
                </PaymentMethodList>
              )}
            </div>
            <ColFooter>
              <LabelButton
                onClick={() => history.push(`${match.url}/payment-methods`)}
              >
                Manage
              </LabelButton>
            </ColFooter>
          </Col>
        )}
      </ColWrapper>
      {isUserAdmin && (
        <Fragment>
          <ColWrapper>
            <LatestInvoiceContainer>
              <h2>Upcoming Invoice</h2>
              {upcomingInvoice && upcomingInvoice.amount_due !== null ? (
                <UpcomingInvoice invoice={upcomingInvoice} />
              ) : (
                'No Upcoming Invoices.'
              )}
            </LatestInvoiceContainer>
          </ColWrapper>
          <ColWrapper>
            <LatestInvoiceContainer>
              <StyledH2>
                <span>Recent Invoices</span>
                <LabelButton
                  onClick={() => history.push(`${match.url}/invoices`)}
                >
                  View All
                </LabelButton>
              </StyledH2>
              {recentInvoices && recentInvoices.length > 0 ? (
                <RecentInvoices invoices={recentInvoices.slice(0, 3)} />
              ) : (
                'No invoices found.'
              )}
            </LatestInvoiceContainer>
          </ColWrapper>
        </Fragment>
      )}
    </BillingWrapper>
  );
}
