import useDeleteWidget from './useDeleteWidget';
import useUpdateWidget from './useUpdateWidget';
import useUpdateCellFormat from './useUpdateCellFormat';
import useUpdateCellLink from './useUpdateCellLink';
import useUpdateCellType from './useUpdateCellType';
import useUpdateCellValue from './useUpdateCellValue';
import useAddWidgetRow from './useAddWidgetRow';
import useDeleteWidgetRow from './useDeleteWidgetRow';
import useUpdateWidgetRow from './useUpdateWidgetRow';
import useAddWidgetColumn from './useAddWidgetColumn';
import useDeleteWidgetColumn from './useDeleteWidgetColumn';
import useUpdateWidgetColumn from './useUpdateWidgetColumn';

export default function useBaseWidgetAPI() {
  return {
    deleteWidget: useDeleteWidget(),
    updateWidget: useUpdateWidget(),
    updateCellFormat: useUpdateCellFormat(),
    updateCellLink: useUpdateCellLink(),
    updateCellType: useUpdateCellType(),
    updateCellValue: useUpdateCellValue(),
    addRow: useAddWidgetRow(),
    deleteRow: useDeleteWidgetRow(),
    updateRow: useUpdateWidgetRow(),
    addColumn: useAddWidgetColumn(),
    deleteColumn: useDeleteWidgetColumn(),
    updateColumn: useUpdateWidgetColumn(),
  };
}
