import { defaultDataIdFromObject, InMemoryCache } from '@apollo/client';
import keyBy from 'lodash/keyBy';

import { calcLabels } from '<sections>/flows/Step/AggregateStep/calcs';
import { stepsByType } from '<sections>/flows/Step/stepData';
import { sortBy } from '<src>/utils/text';

const fragmentTypes = require('./fragment-types.json');

const possibleTypes = fragmentTypes.__schema.types.reduce(
  (pt, type) => ({ ...pt, [type.name]: type.possibleTypes.map((t) => t.name) }),
  {}
);

export const dataIdFromObject = (obj) => {
  const { __typename, ID } = obj;

  if (__typename === 'DataColumn') {
    return null;
  }

  if (__typename === 'FieldDef' && obj.datasetID) {
    return `${__typename}:${ID}:${obj.datasetID}`;
  }

  if (__typename === 'Table' && obj.label) {
    return `${__typename}:${ID}:${obj.label}`;
  }

  if (__typename === 'CellV2' && obj.modelID) {
    return `${__typename}:${obj.modelID}:${ID}`;
  }

  if (__typename === 'CellSummary' && obj.cell && obj.cell.modelID) {
    return `${__typename}:${obj.cell.modelID}:${ID}`;
  }

  if (__typename && __typename.endsWith('StepV2') && obj.stageID && obj.key) {
    // Use interface name to allow cache lookup without knowing step type
    return `StepV2:${obj.stageID}:${obj.key}`;
  }

  if (__typename === 'StageElement') {
    return `${__typename}:${obj.stageID}:${obj.source}:${obj.key}`;
  }

  if (__typename === 'Element') {
    return `${__typename}:${obj.source}:${obj.key}`;
  }

  if (__typename && ID && obj.funcID) {
    return `${__typename}:${obj.funcID}:${ID}`;
  }

  if (__typename && ID) return `${__typename}:${ID}`;

  return defaultDataIdFromObject(obj);
};

function makeCellID(funcID, cellID) {
  return `CellV3:${funcID}:${cellID}`;
}

function makeCellRef(funcID, cellID, toReference, canRead) {
  const ref = toReference(makeCellID(funcID, cellID));
  if (!canRead(ref)) {
    return null;
  }
  return ref;
}

function makeTemplateID(funcID, rangeID) {
  return `CellTemplate:${funcID}:${rangeID}`;
}

function makeTemplateRef(funcID, templateID, toReference, canRead) {
  const ref = toReference(makeTemplateID(funcID, templateID));
  if (!canRead(ref)) {
    return null;
  }
  return ref;
}

function makeSourceStepRef(stageID, source, toReference, canRead) {
  const ref = toReference(`StepV2:${stageID}:${source}`);
  if (!canRead(ref)) {
    return null;
  }
  return ref;
}

function inputElementLabel(inputRef, prefix, elementKey, canRead, readField) {
  const tableRef = readField('table', inputRef);
  if (!tableRef || !canRead(tableRef)) {
    return 'Table not found';
  }

  const elements = readField('elements', readField('schema', tableRef));
  const elementRef = elements
    ? elements.find(
        (elRef) => `${prefix}:${readField('key', elRef)}` === elementKey
      )
    : undefined;
  if (!canRead(elementRef)) {
    return 'Column not found';
  }
  return readField('label', elementRef);
}

function aggrStepElementLabel(
  stepRef,
  elementKey,
  canRead,
  readField,
  toReference
) {
  const stepKey = readField('key', stepRef);
  const groupByKeys = readField('groupBy', stepRef);
  const inputRefsByKey = keyBy(
    readField('inputSchema', stepRef).elements,
    (eRef) => readField('key', eRef)
  );

  const calc = readField('aggregations', stepRef).find(
    (aggr) =>
      elementKey ===
      `${stepKey}:${aggr.op.toUpperCase()}:${aggr.fromElementKey}`
  );
  if (!calc) {
    // This should never happen
    return 'Field not found';
  }
  const opLabel = calcLabels[calc.op];
  const groupBy = groupByKeys
    .map((k) => readField('label', inputRefsByKey[k]))
    .join(', ');

  if (calc.op === 'Cnt' && !calc.fromElementKey) {
    return `${opLabel} by ${groupBy}`;
  }
  const sourceLabel = readField('label', inputRefsByKey[calc.fromElementKey]);
  return `${opLabel} of ${sourceLabel} by ${groupBy}`;
}

function combineStepElementLabel(stepRef, elementKey, canRead, readField) {
  const inputRef = readField('input', stepRef);
  const stepKey = readField('key', stepRef);
  return inputElementLabel(inputRef, stepKey, elementKey, canRead, readField);
}

function execStepElementLabel(stepRef, elementKey, canRead, readField) {
  const funcRef = readField('function', stepRef);
  if (!canRead(funcRef)) {
    return 'Function not found';
  }
  const stepKey = readField('key', stepRef);
  const outputRef = readField('outputs', funcRef).find(
    (oRef) => elementKey === `${stepKey}:${readField('ID', oRef)}`
  );
  if (!outputRef) {
    return 'Field not found';
  }
  return readField('label', outputRef);
}

export default () =>
  new InMemoryCache({
    possibleTypes,
    dataIdFromObject,
    typePolicies: {
      ///////////////////////////////////////////////////////////////
      // Top-level Queries
      Query: {
        fields: {
          dataTables: {
            keyArgs: ['input'],
            read(existing) {
              if (!existing) return existing;

              return {
                ...existing,
                tables: Object.values(existing.tables),
              };
            },
            merge(existing, incoming, { readField }) {
              const tables =
                existing && existing.cursor ? { ...existing.tables } : {};
              if (incoming) {
                incoming.tables.forEach((table) => {
                  tables[readField('ID', table)] = table;
                });
              }

              return {
                ...incoming,
                tables,
              };
            },
          },
        },
      },
      ///////////////////////////////////////////////////////////////
      // Data
      Tableset: {
        fields: {
          source: {
            merge(existing, incoming) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
      DataTable: {
        fields: {
          columns: {
            read(data, { readField }) {
              if (!data) return data;
              const sorted = [...data];
              sortBy((colRef) => readField('label', colRef), sorted);
              return sorted;
            },
          },
          source: {
            merge(existing, incoming) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
      ///////////////////////////////////////////////////////////////
      // Functions
      CellV3: {
        fields: {
          template: {
            read(_, { readField, toReference, canRead }) {
              const templateID = readField('layoutTemplateID');
              if (templateID) {
                const funcID = readField('funcID');
                return makeTemplateRef(
                  funcID,
                  templateID,
                  toReference,
                  canRead
                );
              }
              return null;
            },
          },
        },
      },
      CellRange: {
        fields: {
          cells: {
            read(_, { readField, toReference, canRead }) {
              const funcID = readField('funcID');
              const cellIDs = readField('cellIDs');
              return cellIDs
                .filter(
                  (cellID) =>
                    !!makeCellRef(funcID, cellID, toReference, canRead)
                )
                .map((cellID) => toReference(makeCellID(funcID, cellID)));
            },
          },
          normTemplate: {
            read(_, { readField, toReference, canRead }) {
              const template = readField('template');
              if (template) {
                return template;
              }
              const templateID = readField('templateID');
              if (templateID) {
                const funcID = readField('funcID');
                return makeTemplateRef(
                  funcID,
                  templateID,
                  toReference,
                  canRead
                );
              }
              return null;
            },
          },
        },
      },
      FunctionData: {
        fields: {
          columns: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      FunctionV3: {
        fields: {
          inputs: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          outputs: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          cells: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          data: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          widgets: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      LookupV3Config: {
        fields: {
          lookupTable(_, { readField, toReference, canRead }) {
            const funcID = readField('funcID');
            const tableID = readField('lookupTableID');
            const ref = toReference(`FunctionData:${funcID}:${tableID}`);
            if (!canRead(ref)) {
              return null;
            }
            return ref;
          },
        },
      },
      SwitchV3Config: {
        fields: {
          defaultOutput: {
            read(_, { readField, toReference, canRead }) {
              const funcID = readField('funcID');
              const cellID = readField('defaultOutputID');
              return makeCellRef(funcID, cellID, toReference, canRead);
            },
          },
        },
      },
      SwitchV3Cond: {
        fields: {
          testValue: {
            read(_, { readField, toReference, canRead }) {
              const funcID = readField('funcID');
              const cellID = readField('testValueID');
              return makeCellRef(funcID, cellID, toReference, canRead);
            },
          },
        },
      },
      SwitchV3Rule: {
        fields: {
          conditions: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          outputValue: {
            read(_, { readField, toReference, canRead }) {
              const funcID = readField('funcID');
              const cellID = readField('outputValueID');
              return makeCellRef(funcID, cellID, toReference, canRead);
            },
          },
        },
      },
      WidgetV3: {
        fields: {
          rows: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },

      ///////////////////////////////////////////////////////////////
      // Flows and Stages
      Flow: {
        fields: {
          data: {
            read(data, { readField }) {
              const sorted = [...data];
              sortBy((tableRef) => readField('name', tableRef), sorted);
              return sorted;
            },
          },
        },
      },

      StageElement: {
        fields: {
          sourceInfo: {
            read(_, { readField, toReference, canRead }) {
              const stageID = readField('stageID');
              const source = readField('source');
              if (source === '#userenv') {
                return { key: '#userenv', label: 'Job Variables' };
              }
              if (source === '#builtin') {
                return { key: '#builtin', label: 'Job Details' };
              }

              if (source === '#input') {
                const stageRef = toReference(`FlowStageV2:${stageID}`);
                const inputRef = readField('input', stageRef);
                const tableRef = readField('table', inputRef);
                if (!canRead(tableRef)) {
                  return { key: '#input', label: 'Stage Input' };
                }
                return {
                  key: '#input',
                  label: readField('name', tableRef),
                  subLabel: 'Stage Input',
                };
              }

              const stepRef = makeSourceStepRef(
                stageID,
                source,
                toReference,
                canRead
              );
              if (!stepRef) {
                return {
                  key: source,
                  label: 'Unknown',
                  subLabel: 'Step not found',
                };
              }
              return {
                key: source,
                label: readField('title', stepRef),
                subLabel: stepsByType[readField('__typename', stepRef)].title,
              };
            },
          },
          label: {
            read(existing, { readField, toReference, canRead }) {
              const key = readField('key');
              const stageID = readField('stageID');
              const stageRef = toReference(`FlowStageV2:${stageID}`);
              if (!canRead(stageRef)) {
                // Should never happen
                return null;
              }

              const source = readField('source');

              if (source === '#builtin' || source === '#userenv') {
                // TODO localize builtin field names
                return existing ? existing : 'Unnamed Field';
              }

              if (source === '#input') {
                const inputRef = readField('input', stageRef);
                return inputElementLabel(
                  inputRef,
                  'INPUT',
                  key,
                  canRead,
                  readField
                );
              }

              const sourceRef = makeSourceStepRef(
                stageID,
                source,
                toReference,
                canRead
              );
              if (!sourceRef) {
                return 'Step not found';
              }

              const stepType = readField('__typename', sourceRef);
              switch (stepType) {
                case 'AggregateStepV2':
                  return aggrStepElementLabel(
                    sourceRef,
                    key,
                    canRead,
                    readField,
                    toReference
                  );
                case 'CombineStepV2':
                  return combineStepElementLabel(
                    sourceRef,
                    key,
                    canRead,
                    readField
                  );
                case 'ExecStepV2':
                  return execStepElementLabel(
                    sourceRef,
                    key,
                    canRead,
                    readField
                  );
                default:
                  return existing ? existing : 'Unnamed Field';
              }
            },
          },
        },
      },
      GroupAndSortStepV2: {
        fields: {
          group: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          sort: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      InputSourceV2: {
        fields: {
          table: {
            read(existing, { readField, toReference, canRead }) {
              const tableID = readField('tableID');
              if (!tableID) return null;

              const tableRef = toReference(`FlowTableDef:${tableID}`);
              if (canRead(tableRef)) {
                return tableRef;
              }
              return null;
            },
          },
        },
      },
    },
  });
