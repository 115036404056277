import React from 'react';
import fp from 'lodash/fp';
import styled from '@emotion/styled';
import * as colors from '<components>/colors';
import { withRouter, NavLink } from 'react-router-dom';
import { FlexRowContainer } from '<components>/NumbrzPageComponents';

const TabBarWrapper = styled('div')`
  width: 100%;
  height: 49px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    margin-right: 10px;
    color: ${colors.gray1b};
    font-size: 14px;
    font-weight: 500;
    padding: 3px 10px;
    white-space: nowrap;
  }
  a.createTeam {
    color: ${colors.gray1b};
    font-weight: bold;
    background-color: ${colors.orange1};
    border-radius: 6px;
    padding: 5px 18px;
    margin-right: 0;
    &.active {
      background-color: ${colors.orange2};
      border-radius: 6px;
      color: ${colors.gray1b};
      :hover {
        background-color: ${colors.orange2};
      }
    }
    &:hover {
      background-color: ${colors.orange2};
    }
  }
  a.active {
    background-color: ${colors.blue1};
    color: white;
    border-radius: 50px;
  }
  padding: 10px 10px 8px 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${colors.orange1};

  ${(props) => props.additionalStyles && props.additionalStyles}
`;

function NavTabBar(props) {
  const options = fp.getOr([], 'navigation', props);
  const leftOpts = options.filter((o) =>
    !o.align ? true : o.align === 'left'
  );
  const rightOpts = options.filter((o) => o.align === 'right');

  return (
    <TabBarWrapper additionalStyles={props.additionalStyles}>
      <FlexRowContainer alignItems="center" justifyContent="flex-start">
        {leftOpts.map(({ exact, path, to, title, isActive }, idx) => (
          <NavLink key={idx} exact={exact} to={to || path} isActive={isActive}>
            <span>{title}</span>
          </NavLink>
        ))}
      </FlexRowContainer>
      <FlexRowContainer alignItems="center" justifyContent="flex-start">
        {rightOpts.map(
          ({ exact, path, to, title, isActive, className }, idx) => (
            <NavLink
              key={idx}
              exact={exact}
              to={to || path}
              isActive={isActive}
              className={className}
            >
              <span>{title}</span>
            </NavLink>
          )
        )}
      </FlexRowContainer>
    </TabBarWrapper>
  );
}

export default withRouter(NavTabBar);
