import {
  ConnectFunctionV3List,
  ConnectJobList,
  ConnectFlowList,
} from './connectors';

const FlowList = ConnectFlowList;
const FunctionV3List = ConnectFunctionV3List;
const JobList = ConnectJobList;

export { FunctionV3List, JobList, FlowList };
