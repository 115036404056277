import { useCallback, useState } from 'react';

import shortid from '<src>/utils/shortid';

export default function useAppearTrigger() {
  const [appearTrigger, setAppearTrigger] = useState({ id: 'NONE' });

  const onAppear = useCallback((action) => {
    const id = shortid();
    setAppearTrigger({
      id,
      action,
      done: () =>
        setAppearTrigger((trigger) => {
          if (trigger.id === id) {
            return { id: 'NONE' };
          }
          return trigger;
        }),
      setAction: (action) =>
        setAppearTrigger((trigger) => {
          if (trigger.id === id) {
            return { ...trigger, action };
          }
          return trigger;
        }),
    });
    return id;
  }, []);

  return [appearTrigger, onAppear];
}
