import React, { forwardRef } from 'react';
import { Dropdown, Ref } from 'semantic-ui-react';

const cellTypeOptions = [
  { text: 'String', value: 'String' },
  { text: 'Number', value: 'Number' },
  { text: 'Date', value: 'Date' },
  { text: 'Boolean', value: 'Bool' },
];

function CellType(
  { type, restrict, editable, onChange = () => {}, onConfirm = () => {} },
  parentRef
) {
  let options = cellTypeOptions;
  if (restrict && restrict.length > 0) {
    options = cellTypeOptions.filter((o) => restrict.includes(o.value));
  }
  return (
    <Ref innerRef={parentRef}>
      <Dropdown
        placeholder="Select Type"
        value={type}
        disabled={!editable}
        options={options}
        onChange={(e, data) => {
          if (data.value !== type) {
            onChange(data.value);
          }
        }}
        onClose={(e) => {
          if (e && e.type === 'keydown' && e.key === 'Enter') {
            onConfirm();
          }
        }}
      />
    </Ref>
  );
}
// eslint-disable-next-line no-func-assign
CellType = forwardRef(CellType);
CellType.displayName = 'CellType';

export default CellType;
