import Body from './Body';

export default {
  ID: 'BUILTIN:DATA',
  type: 'Data',
  builtin: true,
  name: 'Data',
  description: 'A table of values.',
  Label: (widget) => 'Data',
  Body,
  initialSetupState: {
    cells: {
      c1: { ID: 'c1', value: null },
      c2: { ID: 'c2', value: null },
      c3: { ID: 'c3', value: null },
      c4: { ID: 'c4', value: null },
    },
    widget: {
      name: 'Data',
      type: 'Data',
      columns: [
        {
          ID: 'col1',
          cellIDs: ['c1', 'c3'],
          template: {
            ID: 'col1',
            label: 'Column 1',
            dataType: 'Any',
          },
        },
        {
          ID: 'col2',
          cellIDs: ['c2', 'c4'],
          template: {
            ID: 'col2',
            label: 'Column 2',
            dataType: 'Any',
          },
        },
      ],
      rows: [
        { ID: 'row1', cellIDs: ['c1', 'c2'] },
        { ID: 'row2', cellIDs: ['c3', 'c4'] },
      ],
    },
  },
};
