import React, { useEffect, useMemo, useState } from 'react';
import fp from 'lodash/fp';
import { Dropdown } from 'semantic-ui-react';

import { useQuery } from '<src>/apollo/client';

import List from '<sections>/projects/pages/ProjectPage/components/lists/List';
import { GetProjectReleases } from '<sections>/projects/queries';

import WaveSpinner from '<components>/WaveSpinner';
import { FlexRowContainer } from '<components>/NumbrzPageComponents';

export default function ProductionJobs({ project }) {
  const { data, loading } = useQuery(GetProjectReleases, {
    variables: { projectID: project.ID },
  });
  const jobsByRelease = useMemo(
    () =>
      fp.groupBy(
        'serviceReleaseID',
        project.jobs.filter((job) => job.isBase && job.serviceReleaseID)
      ),
    [project.jobs]
  );
  const [selectedRelease, setSelectedRelease] = useState(null);

  const allReleases = !loading && data ? data.projectReleases : null;

  const prodReleases = useMemo(
    () => (allReleases ? allReleases.filter((r) => !!r.releasedBy) : null),
    [allReleases]
  );

  const options = useMemo(() => {
    if (!prodReleases) {
      return [];
    }
    return prodReleases
      .filter((r) => !!r.releasedBy)
      .map((r) => ({
        value: r.ID,
        text: r.releaseTag,
      }));
  }, [prodReleases]);

  useEffect(() => {
    if (prodReleases && prodReleases.length > 0 && !selectedRelease) {
      setSelectedRelease(prodReleases[0].ID);
    }
  }, [prodReleases, selectedRelease]);

  const jobs = jobsByRelease[selectedRelease] || [];

  if (loading) {
    return <WaveSpinner />;
  }

  return (
    <>
      {prodReleases.length > 1 ? (
        <FlexRowContainer
          width="100%"
          justifyContent="flex-start"
          margin="0 0 12px 0"
        >
          <b>Release:</b>&nbsp;
          <Dropdown
            options={options}
            value={selectedRelease}
            onChange={(e, { value }) => setSelectedRelease(value)}
          />
        </FlexRowContainer>
      ) : null}
      <List project={project} items={jobs} objType="job" />
    </>
  );
}
