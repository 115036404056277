import { gql } from '@apollo/client';

import { FunctionChangesFragment } from './fragments';
import useEditFunction from './useEditFunction';

const DeleteCellTemplate = gql`
  mutation DeleteCellTemplate($funcID: CommonID!, $templateID: ID!) {
    deleteCellTemplateV3(funcID: $funcID, templateID: $templateID) {
      ...FunctionChangesFragment
    }
  }
  ${FunctionChangesFragment}
`;

export default function useDeleteCellTemplate() {
  const deleteCellTemplate = useEditFunction(
    DeleteCellTemplate,
    'deleteCellTemplateV3'
  );

  return ({ funcID, templateID }) =>
    deleteCellTemplate({
      variables: {
        funcID,
        templateID,
      },
    });
}
