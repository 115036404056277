import { gql } from '@apollo/client';

import { useQuery } from '<src>/apollo/client';

import { Selection } from '../contexts';

import {
  CellFragment,
  CellTemplateFragment,
  FunctionDataFragment,
  WidgetFragment,
} from './fragments';

const GetFunction = gql`
  query GetFunction($ID: CommonID!) {
    functionV3(ID: $ID) {
      ID
      name
      description
      inputs {
        ...CellTemplateFragment
      }
      outputs {
        ...CellTemplateFragment
      }
      builtinTemplates {
        ...CellTemplateFragment
      }
      cells {
        ...CellFragment
      }
      data {
        ...FunctionDataFragment
      }
      widgets {
        ...WidgetFragment
      }
    }
  }
  ${CellFragment}
  ${CellTemplateFragment}
  ${FunctionDataFragment}
  ${WidgetFragment}
`;

function fromType(sourceType) {
  return sourceType === 'CellSource' ? Selection.CELL : Selection.CELL_TEMPLATE;
}
export default function useGetFunction(ID) {
  const result = useQuery(GetFunction, {
    variables: { ID },
    fetchPolicy: 'cache-first',
  });
  const { data, error, loading, refetch } = result;

  let func;
  if (data) {
    const widgetsByID = {};
    const cellsByID = {};
    const linksByTarget = {};
    const linksBySource = {};
    const lookupWidgetsByData = {};
    const links = [];

    data.functionV3.inputs.forEach((input) => {
      cellsByID[input.ID] = input;
    });
    data.functionV3.outputs.forEach((output) => {
      cellsByID[output.ID] = output;
      if (output.link) {
        const link = {
          from: output.link.sourceID,
          fromType: fromType(output.link.sourceType),
          to: output.ID,
          toType: Selection.CELL_TEMPLATE,
          status: output.status,
        };
        links.push(link);
        linksByTarget[link.to] = link.from;
        linksBySource[link.from] = linksBySource[link.from] || [];
        linksBySource[link.from].push(link.to);
      }
    });
    data.functionV3.cells.forEach((cell) => {
      cellsByID[cell.ID] = cell;
      if (cell.link) {
        const link = {
          from: cell.link.sourceID,
          fromType: fromType(cell.link.sourceType),
          to: cell.ID,
          toType: Selection.CELL,
          status: cell.status,
        };
        links.push(link);
        linksByTarget[link.to] = link.from;
        linksBySource[link.from] = linksBySource[link.from] || [];
        linksBySource[link.from].push(link.to);
      }
    });
    data.functionV3.widgets.forEach((widget) => {
      widgetsByID[widget.ID] = widget;
      if (widget.config.type === 'Lookup' && widget.config.lookupTableID) {
        lookupWidgetsByData[widget.config.lookupTableID] =
          lookupWidgetsByData[widget.config.lookupTableID] || [];
        lookupWidgetsByData[widget.config.lookupTableID].push(widget.ID);
      }
    });
    func = {
      ...data.functionV3,
      widgetsByID,
      cellsByID,
      linksBySource,
      linksByTarget,
      links,
      lookupWidgetsByData,
    };
  }

  return { error, func, loading, refetch };
}

export function mockGetFunction(func) {
  return {
    request: {
      query: GetFunction,
      variables: { ID: func.ID },
    },
    result: {
      data: {
        functionV3: func,
      },
    },
  };
}
