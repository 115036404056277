import React, { Fragment } from 'react';
import fp from 'lodash/fp';
import { Icon } from 'semantic-ui-react';
import Button from '<src>/components/Button';
import { JobsTable } from '<components>/NumbrzVerticalEditor';
import { getStatusChiclet } from '../../utils';
import { DetailsLink } from '<src>/sections/jobs/styles';
import Link from '<src>/components/Link';
import { FlexRowContainer } from '<src>/components/NumbrzPageComponents';

function JobList({
  jobs,
  projectNames,
  blockRunStatus = {},
  isRunning,
  viewMode,
  onDeleteJob,
}) {
  return (
    <JobsTable>
      <thead>
        <tr>
          <th>Model</th>
          <th>Job</th>
          {viewMode === 'editor' && <th style={{ width: '70px' }}>Runnable</th>}
          {viewMode === 'status' && (
            <th style={{ width: '110px' }}>Run Status</th>
          )}
          {viewMode === 'status' && <th>Duration</th>}
          {viewMode === 'status' && <th style={{ width: '60px' }}>Details</th>}
          {viewMode === 'editor' && <th style={{ width: '40px' }} />}
        </tr>
      </thead>
      <tbody>
        {jobs.map((j, idx) => {
          const jobStatus =
            blockRunStatus &&
            fp
              .getOr([], 'jobs', blockRunStatus)
              .find((jS) => jS.bindingID === j.ID);
          return (
            <tr key={idx}>
              <td>
                <Link internal to={`/models/${j.projectID}/overview`}>
                  {projectNames[j.projectID]}
                </Link>
              </td>
              <td>
                <FlexRowContainer
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Link
                    internal
                    to={`/models/${j.projectID}/jobs/${j.ID}/overview`}
                  >
                    {j.name}
                  </Link>
                  {j.externalResultURL && (
                    <Link.External
                      icon={<Icon name="chart bar" />}
                      iconPosition="left"
                      bold
                      href={j.externalResultURL}
                    >
                      External results
                    </Link.External>
                  )}
                </FlexRowContainer>
              </td>
              {viewMode === 'editor' && (
                <Fragment>
                  <td style={{ textAlign: 'center' }}>
                    <Icon
                      name={j.runnable ? 'check' : 'close'}
                      color={j.runnable ? 'green' : 'red'}
                    />
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <Button.TableDeleteBtn onClick={() => onDeleteJob(j.ID)} />
                  </td>
                </Fragment>
              )}

              {viewMode === 'status' && (
                <Fragment>
                  <td style={{ textAlign: 'center' }}>
                    {jobStatus && getStatusChiclet(jobStatus.status, isRunning)}
                  </td>
                  <td>{jobStatus && jobStatus.elapsed}</td>
                  <td style={{ textAlign: 'center' }}>
                    {jobStatus && jobStatus.runID && (
                      <DetailsLink
                        to={`/models/${jobStatus.containerID}/jobs/${j.ID}/status/${jobStatus.runID}`}
                        title="Details"
                        target="_blank"
                      >
                        <Icon name="list alternate outline" />
                      </DetailsLink>
                    )}
                  </td>
                </Fragment>
              )}
            </tr>
          );
        })}
      </tbody>
    </JobsTable>
  );
}

export default JobList;
