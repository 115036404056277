import { setContext } from '@apollo/client/link/context';

export default function createAuthLink(getToken) {
  return setContext(async () => {
    const token = await getToken();
    return {
      headers: token ? { authorization: `bearer ${token}` } : null,
    };
  });
}
