import React from 'react';
import { Icon } from 'semantic-ui-react';

import * as colors from '<components>/colors';
import Button from '<src>/components/Button';

import { ConfigTable } from '../../styles';
import { ConfigField } from '<src>/components/NumbrzVerticalEditor';
import ValueOrElement from '../../ValueOrElement';

import AddSeries from './AddSeries';
import FieldOp from './FieldOp';
import FieldValue from './FieldValue';

export default function SeriesList({
  elements,
  fields,
  onAddSeries,
  onRemoveSeries,
  onChangeSeriesLabel,
}) {
  return (
    <ConfigTable>
      <tbody>
        <tr>
          <th className="field">Name</th>
          <th className="type">Type</th>
          <th>Start Value</th>
          <th>Operation</th>
          <th>Amount</th>
          {onAddSeries ? <th className="btn" /> : null}
        </tr>
        {fields.map((field) => (
          <tr key={field.toElement.key}>
            <td>
              <ConfigField
                singleClick
                value={field.toElement.label}
                disabled={!onChangeSeriesLabel}
                onSubmit={(e) =>
                  onChangeSeriesLabel(e, {
                    key: field.toElement.key,
                    label: e.value,
                  })
                }
              />
            </td>
            <td>
              {field.__typename === 'NumberSequenceV2' ? 'Number' : 'Date'}
            </td>
            <td>
              <ValueOrElement value={field.startValue} elements={elements} />
            </td>
            <td>
              <FieldOp elements={elements} field={field} />
            </td>
            <td>
              <FieldValue field={field} elements={elements} />
            </td>
            {onRemoveSeries ? (
              <td className="btn">
                <Button.IconBtn
                  icon={<Icon name="trash alternate outline" />}
                  onClick={(e) => onRemoveSeries(e, field)}
                  baseColor="transparent"
                  contentColor={colors.gray2}
                  activeColor="transparent"
                  contrastColor="transparent"
                  bgHoverColor={colors.gray4}
                />
              </td>
            ) : null}
          </tr>
        ))}
        {onAddSeries ? (
          <AddSeries elements={elements} onAdd={onAddSeries} />
        ) : null}
      </tbody>
    </ConfigTable>
  );
}
