import React from 'react';
import styled from '@emotion/styled';

import * as colors from '<components>/colors';

const Container = styled('div')`
  position: absolute;
  top: -9px;
  left: 0;
  right: 0;
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  margin: 2px 0;
  padding: 0 20px;

  & > div {
    width: 80px;
    margin: 0 auto;
    background-color: white;
    box-shadow: 0 0 15px 15px white;
  }

  span {
    position: relative;
    display: inline-block;
    color: ${colors.green1};
    font-size: 0.9em;
    background-color: white;
    padding: 0 5px;
  }

  span:before,
  span:after {
    content: '';
    position: absolute;
    top: 40%;
    width: 20px;
    border-top: 3px dotted ${colors.green1};
    background-color: white;
  }

  span:before {
    right: 100%;
  }

  span:after {
    left: 100%;
  }
`;

export default function AndSeparator() {
  return (
    <Container>
      <div>
        <span>AND</span>
      </div>
    </Container>
  );
}
