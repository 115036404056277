import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { Icon } from 'semantic-ui-react';

import SidebarMenu from '<components>/NumbrzMenus/SidebarMenu';
import * as colors from '<components>/colors';

const SidebarContainer = styled('div')`
  height: 100%;
  min-width: 180px;
  flex: 1;
  display: flex;
  flex-direction: column;

  & > div:last-child {
    margin-bottom: 0;
  }
  .ui.vertical.menu {
    width: unset;
  }

  .menu {
    .item {
      display: flex !important;
      align-items: baseline;
    }
  }
`;

const CompletedMsg = styled('div')`
  color: ${colors.gray1b};
  font-weight: bold;
  margin: 0px 30px 0 10px;
  padding: 10px 0 0 10px;
  border-top: 1px dotted ${colors.yellow1b};
`;

export default function DeploymentSidebar({
  steps,
  activeStep,
  setActiveStep,
}) {
  const completableSteps = steps.filter((step) => !step.noop);
  const stepsCompleted = completableSteps.reduce(
    (c, step) => c + (step.complete ? 1 : 0),
    0
  );
  return (
    <SidebarContainer>
      <Fragment>
        <SidebarMenu
          options={steps.map((step) => ({
            ...step,
            additionalContent:
              !step.noop && step.complete ? (
                <Icon name="check" color="green" />
              ) : null,
          }))}
          onClick={(e, data) => setActiveStep(data.value)}
          activeItem={activeStep}
        />
        {steps.length > 1 && (
          <CompletedMsg>{`${stepsCompleted}/${completableSteps.length} Completed`}</CompletedMsg>
        )}
      </Fragment>
    </SidebarContainer>
  );
}
