import { gql } from '@apollo/client';

import { useUpdateCellLink, useUpdateCellValue } from '.';
import { FunctionChangesFragment } from './fragments';
import useEditFunction from './useEditFunction';
import useBaseWidgetAPI from './useBaseWidgetAPI';

const AddLookupColumn = gql`
  mutation AddLookupColumn(
    $funcID: CommonID!
    $widgetID: ID!
    $beforeID: ID
    $lookupColumnID: ID
    $correlationID: ID
  ) {
    addLookupColumnV3(
      funcID: $funcID
      widgetID: $widgetID
      beforeID: $beforeID
      lookupColumnID: $lookupColumnID
      correlationID: $correlationID
    ) {
      ...FunctionChangesFragment
    }
  }

  ${FunctionChangesFragment}
`;

const DeleteLookupColumn = gql`
  mutation DeleteLookupColumn(
    $funcID: CommonID!
    $widgetID: ID!
    $columnID: ID!
  ) {
    deleteLookupColumnV3(
      funcID: $funcID
      widgetID: $widgetID
      columnID: $columnID
    ) {
      ...FunctionChangesFragment
    }
  }

  ${FunctionChangesFragment}
`;

const UpdateLookupColumn = gql`
  mutation UpdateLookupColumn(
    $funcID: CommonID!
    $widgetID: ID!
    $columnID: ID!
    $lookupColumnID: ID
  ) {
    updateLookupColumnV3(
      funcID: $funcID
      widgetID: $widgetID
      columnID: $columnID
      lookupColumnID: $lookupColumnID
    ) {
      ...FunctionChangesFragment
    }
  }

  ${FunctionChangesFragment}
`;

const UpdateLookupMode = gql`
  mutation UpdateLookupMode(
    $funcID: CommonID!
    $widgetID: ID!
    $mode: LookupV3Mode!
  ) {
    updateLookupModeV3(funcID: $funcID, widgetID: $widgetID, mode: $mode) {
      ...FunctionChangesFragment
    }
  }

  ${FunctionChangesFragment}
`;

export default function useDataWidgetAPI() {
  const addColumn = useEditFunction(AddLookupColumn, 'addLookupColumnV3');
  const deleteColumn = useEditFunction(
    DeleteLookupColumn,
    'deleteLookupColumnV3'
  );
  const updateColumn = useEditFunction(
    UpdateLookupColumn,
    'updateLookupColumnV3'
  );
  const updateMode = useEditFunction(UpdateLookupMode, 'updateLookupModeV3');
  return {
    ...useBaseWidgetAPI(),
    updateCellLink: useUpdateCellLink(),
    updateCellValue: useUpdateCellValue(),
    addColumn: (widget, { beforeID, lookupColumnID, correlationID }) =>
      addColumn({
        variables: {
          funcID: widget.funcID,
          widgetID: widget.ID,
          beforeID,
          lookupColumnID,
          correlationID,
        },
      }),
    deleteColumn: (widget, column) =>
      deleteColumn({
        variables: {
          funcID: widget.funcID,
          widgetID: widget.ID,
          columnID: column.ID,
        },
      }),
    updateColumn: (widget, column, { lookupColumnID }) =>
      updateColumn({
        variables: {
          funcID: widget.funcID,
          widgetID: widget.ID,
          columnID: column.ID,
          lookupColumnID,
        },
      }),
    updateMode: (widget, { mode }) =>
      updateMode({
        variables: {
          funcID: widget.funcID,
          widgetID: widget.ID,
          mode,
        },
      }),
  };
}
