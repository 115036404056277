import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import CatalogListPage from './pages/CatalogListPage';
import CatalogProjectPage from './pages/CatalogProjectPage';

export default function CatalogSection() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/:slugOrID`}>
        <CatalogProjectPage />
      </Route>
      <Route>
        <CatalogListPage baseURL={match.url} />
      </Route>
    </Switch>
  );
}
