import React, { useRef, useEffect, useState } from 'react';
import * as styles from '../../styles';
import Button from '<components>/Button';
import ErrorChiclet from '<components>/ErrorChiclet';
import { Icon } from 'semantic-ui-react';

export default function ErrorPopup({ errors = [] }) {
  const menuRef = useRef();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const menuEl = visible ? menuRef.current : undefined;
    if (menuEl) menuEl.focus();
  }, [visible]);

  return (
    <styles.PopupWrapper>
      <ErrorChiclet
        actionable={true}
        centered={true}
        wide={true}
        onClick={(e) => {
          setVisible(true);
        }}
      >
        {errors.length}
        <Icon name="info circle" />
      </ErrorChiclet>

      {visible ? (
        <styles.PopupContent
          ref={menuRef}
          tabIndex="-1"
          onBlur={() => setVisible(false)}
        >
          <h5>
            <span>Errors</span>
            <Button
              size="small"
              action="control"
              icon={<Icon name="close" />}
              onClick={() => setVisible(false)}
            />
          </h5>
          <ul>
            {errors.map((message, idx) => {
              return <li key={idx}>{message}</li>;
            })}
          </ul>
        </styles.PopupContent>
      ) : null}
    </styles.PopupWrapper>
  );
}
