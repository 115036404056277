import { gql } from '@apollo/client';

import { FunctionChangesFragment } from './fragments';
import useEditFunction, { optimisticFunctionChanges } from './useEditFunction';
import useBaseWidgetAPI from './useBaseWidgetAPI';

const UpdateShowTotals = gql`
  mutation UpdateShowTotals(
    $funcID: CommonID!
    $widgetID: ID!
    $showTotals: Boolean!
  ) {
    updateShowTotalsV3(
      funcID: $funcID
      widgetID: $widgetID
      showTotals: $showTotals
    ) {
      ...FunctionChangesFragment
    }
  }
  ${FunctionChangesFragment}
`;

export default function useArithAPI() {
  const updateShowTotals = useEditFunction(
    UpdateShowTotals,
    'updateShowTotalsV3'
  );

  return {
    ...useBaseWidgetAPI(),
    updateShowTotals: (widget, showTotals) =>
      updateShowTotals({
        variables: { funcID: widget.funcID, widgetID: widget.ID, showTotals },
        optimisticResponse: {
          __typename: 'Mutation',
          updateShowTotalsV3: optimisticFunctionChanges({
            funcID: widget.funcID,
            widgetsChanged: [
              {
                __typename: 'WidgetV3',
                ...widget,
                config: {
                  ...widget.config,
                  showTotals: showTotals,
                },
              },
            ],
          }),
        },
      }),
  };
}
