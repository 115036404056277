import React, { PureComponent } from 'react';
import styled from '@emotion/styled';

import * as colors from '<components>/colors';
import Button from '<src>/components/Button';
import Dialog from '<components>/Dialog';

import Pane from './Pane';

const StyledDialog = styled(Dialog)`
  min-width: 600px;
  width: 65%;
`;

const StyledBody = styled(Dialog.Body)`
  background-color: ${colors.white};
`;

const ButtonBar = styled(Dialog.Footer)`
  & > button {
    margin-left: 0;
    margin-right: 10px;
  }
  & > button:last-child {
    margin-right: 0;
  }
  display: flex;
`;

const applyFilter = (tablesets = [], filterSettings) => {
  if (filterSettings.type === 'source')
    return tablesets.filter(
      (tb) => tb.source.__typename === filterSettings.value
    );

  return tablesets;
};

export default class TablesetPickerDialog extends PureComponent {
  static defaultProps = {
    initialSelection: null,
    onSelect: () => {},
  };

  state = {
    selectedID: null,
  };

  componentDidMount() {
    this.setState({ selectedID: this.props.initialSelection });
  }

  componentDidUpdate(prevProps) {
    if (!this.props.visible && prevProps.visible) {
      this.setState({
        selectedID: this.props.initialSelection,
      });
    }
  }

  render() {
    const {
      loading,
      tablesets,
      onClose,
      onSelect,
      visible,
      returnTableset = false,
      filterSettings,
    } = this.props;
    const { selectedID } = this.state;

    return (
      <StyledDialog visible={visible ? 1 : 0} onRequestClose={onClose}>
        <Dialog.Header>
          <Dialog.Headline>Select Data</Dialog.Headline>
        </Dialog.Header>
        <StyledBody>
          <Pane
            dialog
            loading={loading}
            tablesets={
              filterSettings
                ? applyFilter(tablesets, filterSettings)
                : tablesets
            }
            selectedID={selectedID}
            onChange={(selectedID) => this.setState({ selectedID })}
          />
        </StyledBody>
        <ButtonBar>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            disabled={!selectedID}
            onClick={() => {
              if (returnTableset) {
                const tableset = tablesets.find((t) => t.ID === selectedID);
                if (tableset) return onSelect(tableset);
              }
              return onSelect(selectedID);
            }}
          >
            Select
          </Button>
        </ButtonBar>
      </StyledDialog>
    );
  }
}
