import React, { useContext } from 'react';
import fp from 'lodash/fp';

import UserDetail from './UserDetail';
import Navigation from './Navigation';
import HelpUtilityContext from '<sections>/help-utility/HelpUtilityContext';

import { StyledMasthead, CenterContent } from '../styledcomponents';
import Button from '<src>/components/Button';

export default function Masthead(props) {
  const [helpVisible, setHelpVisible] = useContext(HelpUtilityContext);
  const {
    additionalDetails,
    breadcrumbs,
    onLogout,
    title,
    user,
    runBtnEntity = '',
    runBtnLabel = '',
    runBtnDisabled,
    runBtnCallback,
    runBtnVisible,
    featureFlags,
    ...rest
  } = props;

  const cleanedTitle = title && title.trim ? title.trim() : '';

  const onSwitchAccount = (ID) =>
    props
      .setPrimaryAccount({
        variables: { input: { memberID: props.user.ID, memberOf: ID } },
      })
      .then(() => {
        window.location = '/';
      });

  return (
    <StyledMasthead {...rest}>
      <Navigation
        title={cleanedTitle}
        additionalDetails={additionalDetails}
        breadcrumbs={breadcrumbs}
        activeAccount={fp.getOr(undefined, 'primaryAccount.display', user)}
      />

      <CenterContent>{props.children}</CenterContent>

      {runBtnVisible && (
        <Button.Run
          disabled={runBtnDisabled}
          onClick={runBtnCallback}
          title={
            runBtnDisabled
              ? `This ${runBtnEntity} is not fully configured`
              : `Run ${runBtnEntity}`
          }
        >
          {runBtnLabel}
        </Button.Run>
      )}

      <Button.MastheadHelpBtn
        className={helpVisible ? 'active' : ''}
        onClick={() => setHelpVisible(!helpVisible)}
      >
        Help
      </Button.MastheadHelpBtn>

      <UserDetail
        user={user}
        onLogout={onLogout}
        onSwitchAccount={onSwitchAccount}
      />
    </StyledMasthead>
  );
}
