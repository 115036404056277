import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import * as colors from '<components>/colors';
import { useAddSystemAlert } from '<components>/SystemAlerts';

const POLL_RATE = 5 * 60 * 1000; // check for updates every five minutes

const ReloadButton = styled('button')`
  width: fit-content;
  background-color: transparent;
  line-height: inherit;
  font-weight: 500;
  border: none;
  margin-left: 4px;
  padding: 0;
  color: rgb(33, 133, 208);
  cursor: pointer;
  outline: none;
  :hover {
    color: ${colors.blue1};
  }
`;

export default function useVersionCheck() {
  const addAlert = useAddSystemAlert();
  const [timerID, setTimerID] = useState(null);
  const [alerted, setAlerted] = useState(false);

  const checkVersion = useCallback(async () => {
    if (navigator && !navigator.onLine) {
      return;
    }
    try {
      const resp = await (await fetch('/version')).json();
      if (resp && resp.version !== __VERSION__) {
        setAlerted(true);
        addAlert({
          message: (
            <ReloadButton onClick={() => window.location.reload()}>
              A new version is available. Click to Reload.
            </ReloadButton>
          ),
        });
      }
    } catch {} // don't care about errors fetching the version; it'll try again
  }, [addAlert]);

  useEffect(() => {
    if (alerted || !__PROD__) {
      return;
    }

    if (!timerID) {
      checkVersion();
      const newID = setTimeout(() => setTimerID(null), POLL_RATE);
      setTimerID(newID);
    }
    return () => {
      clearTimeout(timerID);
    };
  }, [alerted, checkVersion, timerID]);
}
