import { useCallback } from 'react';
import { useLazyQuery, useQuery } from '<src>/apollo/client';
import { GetJobGroupState, JobGroupHistory, JobGroupActive } from '../queries';

export default function useJobGroupRunState(jobGroupID) {
  const { data: { jobGroupActive = [] } = {}, loading: loadingActiveGroup } =
    useQuery(JobGroupActive, {
      variables: { ID: jobGroupID },
    });
  const [
    fetchRunHistory,
    {
      data: { jobGroupHistory = [] } = {},
      loading: loadingRunHistory,
      startPolling: startRunHistoryPolling,
      stopPolling: stopRunHistoryPolling,
      called: calledRunHistory,
    },
  ] = useLazyQuery(JobGroupHistory);

  const loadRunHistory = useCallback(() => {
    fetchRunHistory({
      variables: { ID: jobGroupID, pageSize: 100 },
    });
  }, [fetchRunHistory, jobGroupID]);

  const [
    fetchJobGroupStatus,
    {
      data: { jobGroupState } = {},
      error: statusError,
      loading: loadingStatus,
      startPolling: startStatusPolling,
      stopPolling: stopStatusPolling,
      called: calledStatus,
      refetch,
    },
  ] = useLazyQuery(GetJobGroupState);

  const loadJobGroupStatus = useCallback(
    (runID) => {
      fetchJobGroupStatus({
        variables: { ID: jobGroupID, stateID: runID },
      });
    },
    [fetchJobGroupStatus, jobGroupID]
  );

  const state = {
    loadJobGroupStatus,
    jobGroupStatus: jobGroupState,
    loadingStatus,
    calledStatus,
    statusError,
    startStatusPolling,
    stopStatusPolling,
    refetchStatus: refetch,
    runHistory: jobGroupHistory,
    loadRunHistory,
    loadingRunHistory,
    startRunHistoryPolling,
    stopRunHistoryPolling,
    calledRunHistory,
    loadingActiveGroup,
    activeJobGroup: jobGroupActive,
  };

  return state;
}
