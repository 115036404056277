import React, { useEffect, useRef } from 'react';

import Cell from './Cell';

export default function ColumnSettings({
  appearTrigger = { id: 'NONE' },
  column,
  custom,
  disabled,
  expanded,
  isSeparated,
  onConfirm = () => {},
  onRemove = () => {},
  onSetLabel = () => {},
}) {
  const nameRef = useRef();

  useEffect(() => {
    if (
      appearTrigger.id === column.correlationID &&
      appearTrigger.action === 'edit'
    ) {
      nameRef.current.edit(true);
    }
  }, [appearTrigger, column]);
  return (
    <Cell
      as="th"
      ref={nameRef}
      isEditable={!disabled}
      isSeparated={isSeparated}
      cell={{
        ID: `${column.ID}.label`,
        funcID: column.funcID,
        widgetID: column.widgetID,
        dataType: 'String',
        value: column.label,
      }}
      onCancel={() => {
        if (appearTrigger.id === column.correlationID) {
          onRemove();
        }
        appearTrigger.done();
      }}
      onConfirm={onConfirm}
      onUpdate={(_, { value }) => onSetLabel(value)}
    />
  );
}
