import moment from 'moment';

export function formatForDisplay(val, type, isValid, source) {
  if (val === '') return val;
  if (type === 'DateTime' && source === 'backend')
    return moment.utc(parseInt(val, 10)).format('MM/DD/YYYY');
  if (type === 'DateTime' && isValid) {
    return moment.utc(val).format('MM/DD/YYYY');
  }

  return val;
}

export function isValid(val, element = {}, params = {}) {
  if (val === '') return true;

  if (element.type === 'DateTime') {
    const dateRegex = /^(\d{1,2})\/(\d{1,2})(\/(\d{2,4}))?$/;
    let match = dateRegex.exec(val);
    if (params.ms) {
      const dateStr = moment.utc(parseInt(val, 10)).format('MM/DD/YYYY');
      match = dateRegex.exec(dateStr);
    }

    return match ? true : false;
  }
  if (element.type === 'Number') {
    // eslint-disable-next-line
    const validator = /^[\+\-]?\d*\.?\d+(?:[Ee][\+\-]?\d+)?$/;
    return validator.test(val);
  }
  if (element.type === 'Boolean') {
    return ['true', 'false'].includes(val.toString().toLowerCase());
  }
  return true;
}

export function getFormattingError(type) {
  switch (type) {
    case 'DateTime':
      return 'Expected input type is a date (MM/DD/YY).';
    case 'Number':
      return 'Expected input type is a number.';
    case 'Boolean':
      return 'Expected input type is a boolean (true/false).';
    default:
      return null;
  }
}

export const includeCol = (
  isOutput = false,
  includeArray = [],
  inputArray = [],
  builtins = [],
  index,
  el = {},
  hideFields = {}
) => {
  if (hideFields[el.key]) return false;
  if (builtins.includes(el.key)) return true;
  if (isOutput) return true;

  //if (inputArray.length > 0) return inputArray.includes(el.key);
  if (includeArray.length === 0) return true;
  if (includeArray.length > 0) return includeArray.includes(index);

  return true;
};

export const getFlagLabel = (flag) => {
  switch (flag) {
    case 'ExcludedFiltered':
    case 'NoWriteFiltered':
      return 'Excluded';
    case 'NoWriteSuspended':
      return 'Suspended';
    case 'IncludedNoMatch':
      return 'Not Matched';
    default:
      return flag;
  }
};
