import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PageContent } from '<components>/NumbrzPageComponents';

import DataTablePane from '../DataTablePane';

import AuthPane from '../AuthPane';
import TableList from '../TableList';

export default function TablesPane(props) {
  const {
    loading,
    match,
    history,

    tables = [],
    tablesetID,

    tablesetState = {},
    setConnectDialogVisible,
  } = props;

  const { credentials, tableset, onTablesetFieldChange } = tablesetState;

  return (
    <PageContent>
      {loading ? null : (
        <Switch>
          <Route exact path={`${match.url}`}>
            {({ match }) => {
              return (
                <Fragment>
                  {tableset.needsAuth && match.path.includes('tables') && (
                    <AuthPane
                      credentials={credentials}
                      tableset={tableset}
                      onTablesetChange={onTablesetFieldChange}
                    />
                  )}
                  <TableList
                    tablesetState={tablesetState}
                    setConnectDialogVisible={setConnectDialogVisible}
                    baseURL={match.url}
                  />
                </Fragment>
              );
            }}
          </Route>

          <Route path={`${match.url}/:tableID`}>
            {({ match: innerMatch }) => {
              const selectedTable = tables.find(
                (table) =>
                  table.ID === decodeURIComponent(innerMatch.params.tableID)
              );
              if (selectedTable) {
                return (
                  <DataTablePane
                    tablesetID={tablesetID}
                    table={selectedTable}
                    isNew={props.location.search === '?new'}
                    history={history}
                    tablesetState={tablesetState}
                  />
                );
              }

              return <Redirect to={match.url} />;
            }}
          </Route>
        </Switch>
      )}
    </PageContent>
  );
}
