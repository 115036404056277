import React, { useContext, useEffect } from 'react';

import { useMutation } from '<src>/apollo/client';

import { SetPrimaryAccount } from '<sections>/account/queries';

import { RegisteredAccount } from '<components>/RegisterAccount';
import WaveSpinner from '<components>/WaveSpinner';

export default function SwitchPrimaryAccount({ accountID, goto = '/' }) {
  const user = useContext(RegisteredAccount);
  const [setPrimaryAccount] = useMutation(SetPrimaryAccount);
  useEffect(() => {
    if (user) {
      async function doSwitch() {
        await setPrimaryAccount({
          variables: { input: { memberID: user.ID, memberOf: accountID } },
        });

        window.location = goto;
      }
      doSwitch();
    }
  }, [accountID, goto, setPrimaryAccount, user]);

  return <WaveSpinner />;
}
