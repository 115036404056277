import React, { useState } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';

import * as colors from '<components>/colors';
import Button from '<src>/components/Button';

import { ConfigTable } from '../../styles';
import ElementLabel from '../../ElementLabel';
import FieldPicker from '../../FieldPicker';

const sortOptions = [
  { key: 'Ascending', value: 'Ascending', text: 'Ascending' },
  { key: 'Descending', value: 'Descending', text: 'Descending' },
];

function SortField({ field, elements, onChange, onRemove }) {
  const element = elements.find((e) => e.key === field.fromElementKey);
  return (
    <tr>
      <td>
        <ElementLabel elementKey={field.fromElementKey} element={element} />
      </td>
      <td>
        <Dropdown
          disabled={!onChange}
          placeholder="Select Calculation"
          selectOnBlur={false}
          selectOnNavigation={false}
          options={sortOptions}
          value={field.sortDir}
          onChange={(e, { value }) => onChange(e, field.fromElementKey, value)}
        />
      </td>
      {onRemove ? (
        <td className="btn">
          <Button.IconBtn
            icon={<Icon name="trash alternate outline" />}
            onClick={(e) => onRemove(e, field.fromElementKey)}
            baseColor="transparent"
            contentColor={colors.gray2}
            activeColor="transparent"
            contrastColor="transparent"
            bgHoverColor={colors.gray4}
          />
        </td>
      ) : null}
    </tr>
  );
}

function AddField({ addTitle = 'Add', elements, onAdd }) {
  const [selected, setSelected] = useState();
  const [sortDir, setSortDir] = useState('Ascending');
  return (
    <tr>
      <td>
        <FieldPicker.Dropdown
          elements={elements}
          selected={selected}
          showTypeFilter
          onChange={(e, { selected }) => setSelected(selected)}
        />
      </td>
      <td>
        <Dropdown
          placeholder="Select Calculation"
          selectOnBlur={false}
          selectOnNavigation={false}
          options={sortOptions}
          value={sortDir}
          onChange={(e, { value }) => setSortDir(value)}
        />
      </td>
      <td className="btn">
        <Button.IconBtn
          icon={<Icon name="plus" title={addTitle} />}
          disabled={!selected}
          onClick={async (e) => {
            await onAdd(e, selected, sortDir);
            setSelected(null);
            setSortDir('Ascending');
          }}
          baseColor="transparent"
          contentColor={colors.blue2}
          activeColor="transparent"
          contrastColor="transparent"
          bgHoverColor={colors.blue5}
        />
      </td>
    </tr>
  );
}

export default function SortFields({
  addTitle,
  elements,
  fields,
  onAdd,
  onRemove,
  onChange,
}) {
  return (
    <ConfigTable>
      <thead>
        <tr>
          <th>Field</th>
          <th>Sort Direction</th>
          {onRemove ? <th className="btn" /> : null}
        </tr>
      </thead>
      <tbody>
        {fields.map((field) => (
          <SortField
            key={field.fromElementKey}
            elements={elements}
            field={field}
            onRemove={onRemove}
            onChange={onChange}
          />
        ))}
        {onAdd ? (
          <AddField addTitle={addTitle} elements={elements} onAdd={onAdd} />
        ) : null}
      </tbody>
    </ConfigTable>
  );
}
