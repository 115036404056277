import React, { useMemo, Fragment } from 'react';
import DatamapTable from './DatamapTable';

export default function JobData({
  flowID,
  flowLink,
  projectID,
  dataMappings,
  flowTables = [],
  dataPickerFlowTable,
  onUnlinkTable,
  onMapField,
  onMapAnyTable,
  flowName,
  onShowPicker,
  errors,
  loadingTables,
  externalResultURL,
  isBundle,
}) {
  const jobTables = useMemo(
    () =>
      flowTables.filter(
        (t) => t.prefSource !== 'WorkingTable' && (t.isInput || t.isOutput)
      ),
    [flowTables]
  );

  const unmappedTables = useMemo(() => {
    return jobTables.filter((jT) => {
      const existingM = dataMappings.find((dM) => dM.tableID === jT.ID);
      if (existingM) return false;

      return true;
    });
  }, [dataMappings, jobTables]);

  return (
    <Fragment>
      {errors}

      <DatamapTable
        flowName={flowName}
        flowLink={flowLink}
        externalResultURL={externalResultURL}
        jobTables={jobTables}
        loadingTables={loadingTables}
        dataPickerFlowTable={dataPickerFlowTable}
        unmappedTables={unmappedTables}
        datamap={dataMappings}
        onSelectData={onShowPicker}
        onUnlinkTable={onUnlinkTable}
        onMapField={onMapField}
        onMapTable={onShowPicker}
        onMapAnyTable={onMapAnyTable}
        flowID={flowID}
        projectID={projectID}
        onShowPicker={onShowPicker}
        isBundle={isBundle}
      />
    </Fragment>
  );
}
