const testPublishableKey = 'pk_test_FCt8c29xiWNKiL04zZeBNOCn00EyU3LbAF';
const livePublishableKey = 'pk_live_x3NK4TPyL9UtvwcEtthN1dGD00Qa2Aj0zv';

const STRIPE_KEY_MAP = {
  'app.numbrz.com': livePublishableKey,
  'app.numbrz.org': livePublishableKey,
  'app.debug': livePublishableKey,
};

export default STRIPE_KEY_MAP[window.location.hostname] || testPublishableKey;
