import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';

import * as colors from '<components>/colors';
import SearchBar from '<components>/SearchBar';

import builtins from '../builtins';

const Pane = styled('div')`
  padding: 3px;
`;

const List = styled('ul')`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Item = styled('li')`
  border-radius: 12px;
  padding: 5px 10px;
  margin: 2px 0;
  background-color: ${({ 'aria-selected': selected }) =>
    selected ? colors.active_blue : 'transparent'};
  &:hover {
    cursor: pointer;
    background-color: ${colors.blue3};
  }
`;

export default function FunctionList({ selected, onSelect }) {
  const [filter, setFilter] = useState({ term: '', pattern: null });

  const sorted = useMemo(
    () =>
      [...Object.values(builtins)]
        .filter((f) => !f.wizardExclude)
        .sort((f1, f2) =>
          f1.name.localeCompare(f2.name, {
            ignorePunctuation: true,
            sensitivity: 'base',
          })
        ),
    []
  );
  const list = useMemo(
    () =>
      sorted.filter(
        (func) => !filter.pattern || func.name.match(filter.pattern)
      ),
    [filter.pattern, sorted]
  );

  return (
    <Pane>
      {list.length > 10 ? (
        <SearchBar
          value={filter.term}
          onChange={(term, pattern) => setFilter({ term, pattern })}
        />
      ) : null}
      <List>
        {list.map((func) => (
          <Item
            role="option"
            aria-selected={func.ID === selected.ID}
            key={func.ID}
            onClick={() => {
              if (func.ID !== selected.ID) {
                onSelect(func);
              }
            }}
          >
            {func.builtin ? <b>{func.name}</b> : func.name}
          </Item>
        ))}
      </List>
    </Pane>
  );
}
