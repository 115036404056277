import React, { useMemo, useState } from 'react';
import fp from 'lodash/fp';
import { useQuery } from '<src>/apollo/client';

import Dialog from '<components>/Dialog';
import Button from '<components>/Button';

import { ListFlows } from '../api/queries';
import WaveSpinner from '<src>/components/WaveSpinner';
import IncludeFlowList from './IncludeFlowList';

export default function IncludeFlowDialog({
  flowID,
  visible,
  onClose,
  onIncludeFlows,
  includedFlows = [],
}) {
  const [selectedFlows, setSelectedFlows] = useState(
    includedFlows.map((ref) => ref.flowID)
  );
  const { loading, data = {} } = useQuery(ListFlows, {
    variables: { formatVersion: 4 },
  });

  const flowsList = useMemo(() => {
    return loading
      ? []
      : fp.getOr([], 'listFlows', data).filter((f) => f.ID !== flowID);
  }, [data, flowID, loading]);

  return (
    <Dialog onRequestClose={onClose} visible={visible}>
      <Dialog.Header>
        <Dialog.Headline>Included flows</Dialog.Headline>
      </Dialog.Header>
      <Dialog.Body>
        {loading ? (
          <WaveSpinner />
        ) : (
          <IncludeFlowList
            flows={flowsList}
            includedFlows={selectedFlows}
            onChange={(selectedFlows) => setSelectedFlows(selectedFlows)}
          />
        )}
      </Dialog.Body>
      <Dialog.Footer>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={selectedFlows.length === 0}
          onClick={(e) => {
            onIncludeFlows(e, selectedFlows);
            onClose();
          }}
        >
          Save
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
}
