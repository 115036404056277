import React, { useState } from 'react';
import fp from 'lodash/fp';
import styled from '@emotion/styled';

import Dialog from '<components>/Dialog';
import Button from '<src>/components/Button';
import CardPaymentForm from '../CardPaymentForm';
import AddressForm from '../AddressForm';

import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from '../CardPaymentForm/utils';

const ignoreSsrWarning =
  '/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */';

const DialogContent = styled('div')`
  display: flex;
  flex-direction: column;
`;
const StyledH4 = styled('h4')``;

const StyledFooter = styled(Dialog.Footer)`
  button:first-child${ignoreSsrWarning} {
    margin-right: 5px;
  }
`;

export default function NewPaymentDialog(props) {
  const { payment } = props;
  const last4 = fp.getOr(undefined, 'card.last4', payment);
  const [name = fp.getOr('', 'billingDetails.name', payment), setName] =
    useState();
  const [cardNumber = '', setCardNumber] = useState();
  const [expDate, setExpDate] = useState();
  const [cvc, setCvc] = useState();

  const [
    address = fp.getOr({}, 'billingDetails.address', payment),
    setAddress,
  ] = useState();

  const handleAddPayment = async () => {
    // if mode prop is update, then update existing payment
    const callback =
      props.mode && props.mode === 'update'
        ? props.updatePaymentMethod
        : props.addPaymentMethod;

    await callback({
      variables: {
        input: {
          customerID: props.customerID,
          id: props.payment ? props.payment.id : null,
          type: 'card',
          card: {
            cardNumber: cardNumber || null,
            expMonth: expDate ? expDate.slice(0, 2) : null,
            expYear: expDate ? expDate.slice(3, 5) : null,
            cvc: cvc || null,
          },
          billingDetails: {
            name: name,
            address,
          },
        },
      },
    });

    props.onDialogClose();
  };

  const handleInputChange = (e) => {
    const {
      target: { name, value },
    } = e;

    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'cardNumber':
        setCardNumber(formatCreditCardNumber(value));
        break;
      case 'expDate':
        setExpDate(formatExpirationDate(value));
        break;
      case 'cvc':
        setCvc(formatCVC(value));
        break;

      default:
        break;
    }
  };

  const handleAddressChange = (e) => {
    const {
      target: { name, value },
    } = e;

    setAddress({ ...address, [name]: value });
  };

  return (
    <Dialog visible={props.visible} onRequestClose={props.onDialogClose}>
      <Dialog.Header>
        <Dialog.Headline>
          {props.mode && props.mode === 'update'
            ? 'Update payment method'
            : `Add a payment method`}
        </Dialog.Headline>
      </Dialog.Header>
      <Dialog.Body>
        <DialogContent>
          <StyledH4>
            {props.mode && props.mode === 'update' ? 'Update' : 'Add'} credit or
            debit card {last4 && `ending in ${last4}`}
          </StyledH4>
          <CardPaymentForm
            onChange={handleInputChange}
            name={name}
            cardNumber={cardNumber}
            expDate={expDate}
            cvc={cvc}
          />
          <StyledH4>Billing Address</StyledH4>
          <AddressForm
            onChange={handleAddressChange}
            addressLine1={address.addressLine1}
            addressLine2={address.addressLine2}
            city={address.city}
            state={address.state}
            zipCode={address.zipCode}
          />
        </DialogContent>
      </Dialog.Body>
      <StyledFooter>
        <Button onClick={() => props.onDialogClose()}>Cancel</Button>
        <Button onClick={handleAddPayment}>Save</Button>
      </StyledFooter>
    </Dialog>
  );
}
