import { gql } from '@apollo/client';

import { FunctionChangesFragment } from './fragments';
import useEditFunction from './useEditFunction';

const AddCellTemplate = gql`
  mutation AddCellTemplate(
    $funcID: CommonID!
    $section: TemplateSection!
    $beforeID: ID
    $correlationID: ID
    $label: String
    $comment: String
    $dataType: CellType
    $link: LinkSourceInput
  ) {
    addCellTemplateV3(
      funcID: $funcID
      section: $section
      beforeID: $beforeID
      correlationID: $correlationID
      label: $label
      comment: $comment
      dataType: $dataType
      link: $link
    ) {
      ...FunctionChangesFragment
    }
  }
  ${FunctionChangesFragment}
`;

export default function useAddCellTemplate() {
  const addCellTemplate = useEditFunction(AddCellTemplate, 'addCellTemplateV3');

  return ({
    funcID,
    section,
    beforeID,
    correlationID,
    label,
    comment,
    dataType,
    link,
  }) =>
    addCellTemplate({
      variables: {
        funcID,
        section,
        beforeID,
        correlationID,
        label,
        comment,
        dataType,
        link,
      },
    });
}
