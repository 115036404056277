import { useCallback, useState, useContext } from 'react';
import fp from 'lodash/fp';
import { UserPreferencesContext } from '<components>/UserPreferences';

export default function useFlowTestState(flowID, api = {}, tests = []) {
  const [runningTest, setRunningTest] = useState(false);
  const [testResults, setTestResults] = useState([]);
  const [testStatus, setTestStatus] = useState();
  const [testStageStatus, setTestStageStatus] = useState([]);
  const { onTestFlow } = api;

  const { flowSettings = {}, setFlowSetting } = useContext(
    UserPreferencesContext
  );

  const storedSettings = flowSettings[flowID];
  const activeTest = storedSettings
    ? JSON.parse(storedSettings.activeTest)
    : undefined;

  const runTest = useCallback(
    async (testCaseKey) => {
      setRunningTest(true);
      if (!activeTest || testCaseKey !== activeTest.key) {
        const newTest = tests.find((t) => t.key === testCaseKey);
        setFlowSetting(flowID, 'activeTest', JSON.stringify(newTest));
      }

      const res = await onTestFlow(null, { testCaseKey });

      res && setTestResults(res.stageLogs);
      setTestStatus(fp.getOr(undefined, 'finalStatus.errMsg', res));
      setTestStageStatus(fp.getOr([], 'finalStatus.stageDetails', res));

      setRunningTest(false);
    },
    [flowID, setFlowSetting, onTestFlow, activeTest, tests]
  );

  const state = {
    activeTest: activeTest || tests[0],
    setActiveTest: (test) => {
      setFlowSetting(flowID, 'activeTest', JSON.stringify(test));

      runTest(test.key);
    },
    runningTest,
    testResults,
    setTestResults,
    testStatus,
    testStageStatus,
    runTest,
  };

  return state;
}
