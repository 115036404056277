export function formatTimestampToString(timestamp, includeTime) {
  // Timestamps combing back from back-end are wrong, have to be modified
  const date = new Date(parseInt(timestamp.toString().slice(0, -3), 10) * 1000);
  return formatDateObj(date, includeTime);
}

export function formatISODateString(isoDateStr, includeTime) {
  const date = new Date(isoDateStr);
  return formatDateObj(date, includeTime);
}

export function formatDateObj(date, includeTime) {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const year = date.getFullYear();
  const month = months[date.getMonth()];
  const day = date.getDate();

  const formattedTime = date.toLocaleTimeString('en-US');

  return `${month} ${day}, ${year} ${includeTime ? formattedTime : ''}`;
}
