/*eslint import/namespace: ['error', { allowComputed: true }]*/
/** @jsx jsx */
/** @jsxRuntime classic */

import { PureComponent } from 'react';
import * as styles from './style';

import { jsx } from '@emotion/react';
import { Close } from '../Icons';
// eslint-disable-line import/namespace

export default class Alert extends PureComponent {
  timeout;
  interval;

  componentDidMount() {
    this.startTimers();
  }

  componentWillUnmount() {
    this.clearTimers();
  }

  startTimers(alert = this.props.alert) {
    const { ttl } = alert;

    if (ttl) this.timeout = setTimeout(this.remove, ttl);
  }

  clearTimers() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  remove = () => {
    this.props.remove(this.props.alert);
  };

  render() {
    const { message, level = 'info', dismissable } = this.props.alert;

    return (
      <div css={styles.container}>
        <div css={[styles.alert, styles[level]]}>
          <span>{message}</span>
          {dismissable ? (
            <button
              tabIndex="-1"
              title="Dismiss"
              css={styles.dismiss}
              onClick={this.remove}
            >
              <Close tabIndex="0" />
            </button>
          ) : undefined}
        </div>
      </div>
    );
  }
}
