/** @jsx jsx */
/** @jsxRuntime classic */

import { forwardRef } from 'react';
import styled from '@emotion/styled';
import { jsx, css } from '@emotion/react';
import * as colors from '<components>/colors';
import { Icon, Message, Dropdown } from 'semantic-ui-react';
import Card from '<components>/Card';
import FieldEditor from '<components>/FieldEditor';
import { NavLink } from 'react-router-dom';

export const Layout = styled('div')`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  overflow: hidden;
  p {
    margin-bottom: 10px;
  }
`;

export const SimpleErrorMessage = styled(Message)`
  color: ${colors.red1c} !important;
  background: white !important;
  border: ${colors.red1c} !important;
  margin: 10px 0;
`;

export const PageContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .message {
    min-height: min-content;
  }

  & [contenteditable] {
    -webkit-user-select: text;
    user-select: text;
  }
`;

export const PageContent = styled('div')`
  width: ${(props) => (props.width ? props.width : '100%')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '800px')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-bottom: 3em;
`;

export const ContentColumnLayout = styled('div')`
  width: 100%;
  display: flex;
`;

export const Content = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2em 4em 2em 3em;
  height: 100%;
  min-height: 400px;
  flex: 6;
`;

export const ListContent = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  li {
    list-style-type: none;
    height: fit-content;
  }
`;

export const ContentHeader = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 6px 40px 4px 40px;
  background-color: white;
  border-bottom: 1px solid ${colors.gray3};
  color: ${colors.gray1b};
  h3 {
    margin-left: 0px;
    margin-bottom: 0px !important;
    width: fit-content;
  }
  i,
  svg {
    color: white;
  }

  & > h3 {
    margin: 0;
  }

  h4 {
    margin: 0;
    font-size: 0.9em;
  }
`;

export const CardList = styled('div')`
  padding: 0;
  margin: 0;

  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
`;

export const CardListItem = styled(Card)`
  justify-content: space-between;
  position: relative;
  height: unset;
  width: unset;
  min-width: 220px;
  max-width: 572px;
  min-height: 90px;
`;

export const StyledScrollbar = css({
  '::-webkit-scrollbar': {
    width: '3px',
    height: '2px',
  },

  '::-webkit-scrollbar-thumb:hover': {
    background: colors.gray1b,
  },
});

export const DropdownWrapper = styled('div')`
  height: 38px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  background-color: white;
  border: 1px solid ${colors.gray1b};

  i {
    color: ${colors.gray1b};
    margin-top: -1px !important;
  }
  div.dropdown {
    i,
    div,
    button {
      color: ${colors.gray1b};
      font-weight: bold;
      font-size: 1.1em;
    }
    padding: 12px 10px 12px 15px;
    margin-right: 30px;
  }
`;

export const ErrorMsg = styled('div')`
  color: ${colors.red1c};
  margin-left: 10px;
`;

export const ErrorChiclet = styled(
  forwardRef((props, parentRef) => <Icon.Group {...props} />)
)`
  transform: scale(0);

  transition: tranform 0.3s ease-out;
  ${(props) =>
    props.visible === 'true' && {
      transform: 'scale(1)',
      transition: 'transform 0.2s ease-in',
    }}
`;

export const EditContainer = styled('div')`
  position: relative;
`;

export const DropdownWrapperWithLabel = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 12px;
  & div.label {
    margin: 0;
    border: 0 solid transparent;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 1em;
    padding: 0.78571429em 1em;
    width: fit-content;
  }
  & div.dropdown {
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-top-right-radius: 0.28571429rem;
    border-bottom-right-radius: 0.28571429rem;
    border-left-top-radius: 0;
    border-left-bottom-radius: 0;
    border-left-color: transparent;
    flex: 1;
    background-color: ${colors.white};
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: 400;
    line-height: 1;
    padding: 0.78571429em 1em;
  }
`;

export const NumbrzMessageContainer = styled(Message)`
  margin: 0 !important;
  margin-bottom: 6px !important;
  color: ${colors.red1c} !important;
  border: 1px solid ${colors.red1c} !important;
  background-color: transparent !important;
  box-shadow: none !important;
  div.header {
    color: ${colors.red1c} !important;
    font-size: 1em !important;
  }
  ul {
    margin-top: 10px;
    margin-bottom: 0;
    padding-left: 18px;
  }
`;

export const EmptyListContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: ${colors.gray2};
  h4 {
    font-size: 1.5em;
    font-weight: normal;
  }
`;

export const CompactDropdown = styled(Dropdown)`
  .text {
    margin-right: 5px;
  }
  .menu {
    margin-top: 0 !important;
  }
  &&& .item {
    padding: 3px 6px !important;
    span {
      font-size: 0.8rem !important;
    }
  }
`;

export const EditableField = styled(FieldEditor)`
  width: 100%;
  outline: none;
  border: none;
  transition: border-color 125ms ease-in-out;
  border-bottom: 1px solid
    ${({ underline }) => (underline ? colors.gray6b : 'transparent')};
  input& {
    padding: 1px 0;
  }
  &.placeholder,
  input&::placeholder {
    font-weight: normal;
    color: rgba(191, 191, 191, 0.87);
  }
  &:hover {
    cursor: text;
    border-bottom-color: ${({ disabled }) =>
      !disabled ? colors.blue4 : undefined};
  }
  &:focus {
    border-bottom-color: ${({ disabled }) =>
      !disabled ? colors.blue2 : undefined};
  }
  &:empty:after {
    content: '\\00a0';
  }
`;

export const EditableHeader = styled(EditableField)`
  color: ${colors.gray1b};

  font-weight: bold;
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin-bottom: 20px;
`;

export const StyledCardHeader = (props) => (
  <Card.Header
    {...props}
    css={css`
      position: relative;
      i.icons {
        position: absolute;
        top: -8px;
        right: -8px;
        margin: 0 !important;
        .icon {
          left: 49.5%;
        }
        i {
          margin: 0 !important;
          height: unset !important;
          width: 1em !important;
        }
        i.circle {
          color: ${colors.red1c};
          font-size: 1.3em;
        }
        i.exclamation {
          color: white;
          font-size: 0.7em;
        }
      }
    `}
  />
);

export const StyledHeader = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    justify-content: flex-start;
  }
  i.icons {
    position: absolute;
    top: -8px;
    right: -8px;
    margin: 0 !important;
    .icon {
      left: 49.5%;
    }
    i {
      margin: 0 !important;
      height: unset !important;
      width: 1em !important;
    }
    i.circle {
      color: ${colors.red1c};
      font-size: 1.3em;
    }
    i.exclamation {
      color: white;
      font-size: 0.7em;
    }
  }
`;

export const DataSourceHdr = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  a {
    margin-left: 0;
    padding: 0;
    width: unset;
    font-size: 1em;
  }
  span {
    font-size: 11px;
  }
`;

export const StyledCardDesc = styled('div')`
  color: ${colors.gray2};
  font-size: 12px;
  word-break: break-word;
`;

export const NumbrzNavLink = styled(NavLink)`
  ${(props) => props.fontWeight && { 'font-weight': props.fontWeight }};
  ${(props) => props.margin && { margin: props.margin }};
  color: ${colors.blue1};
`;

export const FlexColumnWrapper = styled('div')`
  height: ${(props) => (props.height ? props.height : 'auto')};
  width: ${(props) => (props.width ? props.width : 'auto')};
  min-width: ${(props) => props.minWidth && props.minWidth};

  display: flex;
  flex-direction: column;
  ${(props) => props.margin && props.margin};
  ${(props) => props.padding && props.padding};
  ${(props) => props.justifyContent && props.justifyContent};
  ${(props) => props.alignItems && props.alignItems};
  .warning {
    box-shadow: none !important;
  }
`;

export const FlexRowContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.justifyContent && { justifyContent: props.justifyContent }};
  ${(props) => props.alignItems && { alignItems: props.alignItems }};
  ${(props) => props.padding && { padding: props.padding }};
  ${(props) => props.margin && { margin: props.margin }};
  ${(props) => props.flexWrap && { flexWrap: 'wrap' }};
  ${(props) => props.width && { width: props.width }};
  ${(props) => props.height && { height: props.height }};
  ${(props) => props.maxWidth && { maxWidth: props.maxWidth }}
  ${StyledScrollbar}
`;

export const FlexColumnContainer = styled(FlexRowContainer)`
  display: flex;
  flex-direction: column;
`;

export const PageMasthead = styled('div')`
  width: 100%;
  height: 45px;
  min-height: 45px !important;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'space-between'};
  color: ${colors.gray1};
  background: white;
  border-bottom: 1px solid ${colors.gray_border};
  z-index: 1;
  padding: ${(props) => (props.padding ? props.padding : '0 20px 0 20px')};

  span {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 550;
    font-size: 11px;
  }
  a {
    span {
      text-transform: none;
      font-weight: 500;
    }
  }
  h5,
  h4 {
    display: flex;
    align-items: center;
    margin: 0;
  }
`;

export const Separator = styled('div')`
  width: 1px;
  height: ${(props) => (props.height ? props.height : '20px')};
  background: ${colors.gray_border};
  margin: ${(props) => (props.margin ? props.margin : '0 15px')};
`;

export const SubMasthead = styled('div')`
  min-height: 45px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-bottom: 1px solid ${colors.gray_border};
  background: ${colors.blue7};
`;

export const ColumnWrapper = styled('div')`
  width: 100%;
  height: 100%;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'unset')};
  min-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) =>
    props.padding ? props.padding : '40px 40px 100px 40px'};
  ${(props) => props.marginBottom && { 'margin-bottom': props.marginBottom }};

  overflow-y: auto;
  overflow-x: hidden;
  ${StyledScrollbar};
`;

export const ContentError = styled(Message)`
  ${(props) => props.wide && { width: '100%' }};
  ${(props) => props.margin && { margin: `${props.margin} !important` }};
  min-height: unset !important;
  border-radius: 8px !important;
  p {
    font-weight: bold;
  }
  ul {
    padding-left: 20px;
  }
`;

export const CardSubhdr = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 12px;
  padding-left: 1px;
  color: ${colors.gray2};
  text-transform: capitalize;
  letter-spacing: 0.5px;
  a {
    padding-left: 0;
  }
`;
