import React from 'react';
import styled from '@emotion/styled';

import ColumnTypePicker from '../../ColumnTypePicker';
import Button from '<src>/components/Button';
import { ConfigField } from '<src>/components/NumbrzVerticalEditor';

const ignoreSsrWarning =
  '/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */';

const Style = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > div:first-child${ignoreSsrWarning} {
    flex: 1;
  }
  & .dropdown {
    font-weight: normal;
  }
`;

export default function FieldHeader({
  element,
  onlyField,
  onChangeField,
  onDeleteField,
}) {
  return (
    <th style={{ minWidth: '160px', paddingRight: '2px' }}>
      <Style>
        <div>
          <ConfigField
            singleClick
            disabled={!onChangeField}
            value={element.label}
            onSubmit={(e) =>
              onChangeField(null, { key: element.key, label: e.value })
            }
          />
          <ColumnTypePicker
            disabled={!onChangeField}
            type={element.type}
            onChange={(e, { type }) =>
              onChangeField(e, { key: element.key, type })
            }
          />
        </div>
        {!!onDeleteField && !onlyField ? (
          <Button.TableDeleteBtn
            title="Delete Output Field"
            onClick={(e) => onDeleteField(e, { key: element.key })}
          />
        ) : null}
      </Style>
    </th>
  );
}
