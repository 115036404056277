import { useContext } from 'react';

import { Expanded, Selection } from './contexts';

const EMPTY = [];

export default function useActiveCellLinks(func, showAll) {
  const { expanded } = useContext(Expanded);
  const { selection } = useContext(Selection);

  let activeLinkVals = func.links;
  if (!showAll) {
    if (!selection) {
      return EMPTY;
    }
    if (
      selection.type !== Selection.CELL &&
      selection.type !== Selection.CELL_TEMPLATE
    ) {
      return EMPTY;
    }

    activeLinkVals = activeLinkVals.filter(
      ({ from, to }) => selection.ID === from || selection.ID === to
    );
  }

  if (activeLinkVals.length === 0) return EMPTY;

  return activeLinkVals.map((link) => {
    const target = {
      ID: link.to,
      type: link.toType,
    };
    const cell = func.cellsByID[link.to];
    if (cell.widgetID && cell.isConfig && !expanded.includes(cell.widgetID)) {
      target.ID = cell.widgetID;
      target.type = Selection.WIDGET;
    }

    return {
      ID: `${link.from}-${link.to}`,
      source: {
        ID: link.from,
        type: link.fromType,
      },
      target,
      status: link.status,
    };
  });
}
