import React from 'react';
import styled from '@emotion/styled';

import CenteredLayout from '<components>/CenteredLayout';

import * as colors from '<components>/colors';

const StyledMenu = styled('div')`
  width: 100%;
  background: ${(props) => (props.bgColor ? props.bgColor : 'white')};
  border-top: ${(props) =>
    props.borderTop ? `1px solid ${colors.gray3}` : 'none'};
  border-bottom: ${(props) =>
    props.borderBottom ? `1px solid ${colors.gray3}` : 'none'};
  padding-left: ${(props) => (props.leftPadding ? props.leftPadding : '0')};
`;

const StyledContent = styled(CenteredLayout)`
  flex: 0;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;

  div {
    cursor: pointer;
    position: relative;
    letter-spacing: 0.125px;
    font-size: ${(props) => (props.small ? '1em' : '1.15em')};
    font-weight: bold;
    display: block;
    margin-right: 12px;
    padding: 0;
    color: ${colors.gray2b};

    &:hover {
      color: ${colors.gray5b};
    }
  }
  div.active {
    color: ${colors.blue1} !important;
    outline: none;
  }
`;

const TabBarLogic = ({
  tabs,
  activeTab,
  onTabChange,
  light = false,
  small = false,
  bgColor,
  leftPadding,
}) => (
  <StyledMenu leftPadding={leftPadding} bgColor={bgColor} light={light}>
    <StyledContent small={small} unbounded>
      {tabs.map((tab) => (
        <div
          key={tab.name}
          className={tab.name === activeTab ? 'active' : ''}
          onClick={() => onTabChange(tab.name)}
          onKeyPress={() => {}}
          role="button"
          tabIndex={0}
        >
          {tab.label}
        </div>
      ))}
    </StyledContent>
  </StyledMenu>
);

const TabBar = (props) => <TabBarLogic {...props} />;
TabBar.Small = (props) => <TabBarLogic {...props} small />;
TabBar.Light = (props) => <TabBarLogic {...props} light />;
TabBar.Light.Small = (props) => <TabBarLogic {...props} light small />;

export default TabBar;
