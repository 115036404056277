import { gql } from '@apollo/client';
import update from 'immutability-helper';

import { FunctionChangesFragment } from './fragments';
import useEditFunction, { optimisticFunctionChanges } from './useEditFunction';
import useBaseWidgetAPI from './useBaseWidgetAPI';

const AddSwitchRule = gql`
  mutation AddSwitchRule(
    $funcID: CommonID!
    $widgetID: ID!
    $beforeID: ID
    $correlationID: ID
    $label: String
  ) {
    addSwitchRuleV3(
      funcID: $funcID
      widgetID: $widgetID
      beforeID: $beforeID
      correlationID: $correlationID
      label: $label
    ) {
      ...FunctionChangesFragment
    }
  }

  ${FunctionChangesFragment}
`;
const DeleteSwitchRule = gql`
  mutation DeleteSwitchRule($funcID: CommonID!, $widgetID: ID!, $ruleID: ID!) {
    deleteSwitchRuleV3(funcID: $funcID, widgetID: $widgetID, ruleID: $ruleID) {
      ...FunctionChangesFragment
    }
  }

  ${FunctionChangesFragment}
`;
const UpdateSwitchRule = gql`
  mutation UpdateSwitchRule(
    $funcID: CommonID!
    $widgetID: ID!
    $ruleID: ID!
    $label: String
  ) {
    updateSwitchRuleV3(
      funcID: $funcID
      widgetID: $widgetID
      ruleID: $ruleID
      label: $label
    ) {
      ...FunctionChangesFragment
    }
  }

  ${FunctionChangesFragment}
`;

const AddSwitchCondition = gql`
  mutation AddSwitchCondition(
    $funcID: CommonID!
    $widgetID: ID!
    $ruleID: ID!
    $beforeID: ID
    $correlationID: ID
    $inputID: ID
    $op: SwitchV3Op
  ) {
    addSwitchCondV3(
      funcID: $funcID
      widgetID: $widgetID
      ruleID: $ruleID
      beforeID: $beforeID
      correlationID: $correlationID
      inputID: $inputID
      op: $op
    ) {
      ...FunctionChangesFragment
    }
  }

  ${FunctionChangesFragment}
`;
const DeleteSwitchCondition = gql`
  mutation DeleteSwitchCondition(
    $funcID: CommonID!
    $widgetID: ID!
    $ruleID: ID!
    $condID: ID!
  ) {
    deleteSwitchCondV3(
      funcID: $funcID
      widgetID: $widgetID
      ruleID: $ruleID
      condID: $condID
    ) {
      ...FunctionChangesFragment
    }
  }

  ${FunctionChangesFragment}
`;
const UpdateSwitchCondition = gql`
  mutation UpdateSwitchCondition(
    $funcID: CommonID!
    $widgetID: ID!
    $ruleID: ID!
    $condID: ID!
    $inputID: ID
    $op: SwitchV3Op
  ) {
    updateSwitchCondV3(
      funcID: $funcID
      widgetID: $widgetID
      ruleID: $ruleID
      condID: $condID
      inputID: $inputID
      op: $op
    ) {
      ...FunctionChangesFragment
    }
  }

  ${FunctionChangesFragment}
`;

const AddSwitchInput = gql`
  mutation AddSwitchInput(
    $funcID: CommonID!
    $widgetID: ID!
    $beforeID: ID
    $correlationID: ID
    $label: String
    $comment: String
    $dataType: CellType
  ) {
    addSwitchInputV3(
      funcID: $funcID
      widgetID: $widgetID
      beforeID: $beforeID
      correlationID: $correlationID
      label: $label
      comment: $comment
      dataType: $dataType
    ) {
      ...FunctionChangesFragment
    }
  }
  ${FunctionChangesFragment}
`;
const DeleteSwitchInput = gql`
  mutation DeleteSwitchInput(
    $funcID: CommonID!
    $widgetID: ID!
    $inputID: ID!
  ) {
    deleteSwitchInputV3(
      funcID: $funcID
      widgetID: $widgetID
      inputID: $inputID
    ) {
      ...FunctionChangesFragment
    }
  }

  ${FunctionChangesFragment}
`;
const UpdateSwitchInput = gql`
  mutation UpdateSwitchOutput(
    $funcID: CommonID!
    $widgetID: ID!
    $inputID: ID!
    $label: String
    $comment: String
    $dataType: CellType
  ) {
    updateSwitchInputV3(
      funcID: $funcID
      widgetID: $widgetID
      inputID: $inputID
      label: $label
      comment: $comment
      dataType: $dataType
    ) {
      ...FunctionChangesFragment
    }
  }

  ${FunctionChangesFragment}
`;

const UpdateSwitchOutput = gql`
  mutation UpdateSwitchOutput(
    $funcID: CommonID!
    $widgetID: ID!
    $label: String
    $comment: String
    $dataType: CellType
  ) {
    updateSwitchOutputV3(
      funcID: $funcID
      widgetID: $widgetID
      label: $label
      comment: $comment
      dataType: $dataType
    ) {
      ...FunctionChangesFragment
    }
  }

  ${FunctionChangesFragment}
`;

const UpdateDefaultOutputLabel = gql`
  mutation UpdateDefaultOutputLabel(
    $funcID: CommonID!
    $widgetID: ID!
    $label: String
  ) {
    updateSwitchDefaultLabelV3(
      funcID: $funcID
      widgetID: $widgetID
      label: $label
    ) {
      ...FunctionChangesFragment
    }
  }

  ${FunctionChangesFragment}
`;

export default function useSwitchWidgetAPI() {
  const updateDefaultOutputLabel = useEditFunction(
    UpdateDefaultOutputLabel,
    'updateSwitchDefaultLabelV3'
  );
  const addRule = useEditFunction(AddSwitchRule, 'addSwitchRuleV3');
  const deleteRule = useEditFunction(DeleteSwitchRule, 'deleteSwitchRuleV3');
  const updateRule = useEditFunction(UpdateSwitchRule, 'updateSwitchRuleV3');
  const addCondition = useEditFunction(AddSwitchCondition, 'addSwitchCondV3');
  const deleteCondition = useEditFunction(
    DeleteSwitchCondition,
    'deleteSwitchCondV3'
  );
  const updateCondition = useEditFunction(
    UpdateSwitchCondition,
    'updateSwitchCondV3'
  );
  const addInput = useEditFunction(AddSwitchInput, 'addSwitchInputV3');
  const deleteInput = useEditFunction(DeleteSwitchInput, 'deleteSwitchInputV3');
  const updateInput = useEditFunction(UpdateSwitchInput, 'updateSwitchInputV3');
  const updateOutput = useEditFunction(
    UpdateSwitchOutput,
    'updateSwitchOutputV3'
  );
  return {
    ...useBaseWidgetAPI(),
    updateDefaultOutputLabel: (widget, label) => {
      widget = update(widget, {
        config: {
          defaultOutputLabel: { $set: label },
        },
      });
      return updateDefaultOutputLabel({
        variables: {
          funcID: widget.funcID,
          widgetID: widget.ID,
          label,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateSwitchDefaultLabelV3: optimisticFunctionChanges({
            funcID: widget.funcID,
            widgetsChanged: [widget],
          }),
        },
      });
    },
    addInput: (widget, { beforeID, correlationID, label, comment, dataType }) =>
      addInput({
        variables: {
          funcID: widget.funcID,
          widgetID: widget.ID,
          beforeID,
          correlationID,
          label,
          comment,
          dataType,
        },
      }),
    deleteInput: (widget, input) =>
      deleteInput({
        variables: {
          funcID: widget.funcID,
          widgetID: widget.ID,
          inputID: input.ID,
        },
      }),
    addRule: (widget, { beforeID, correlationID, label }) =>
      addRule({
        variables: {
          funcID: widget.funcID,
          widgetID: widget.ID,
          beforeID,
          correlationID,
          label,
        },
      }),
    deleteRule: (widget, { ruleID }) =>
      deleteRule({
        variables: {
          funcID: widget.funcID,
          widgetID: widget.ID,
          ruleID,
        },
      }),
    updateRule: (widget, { ruleID, label }) => {
      const ruleIdx = widget.config.rules.findIndex((r) => r.ID === ruleID);
      if (ruleIdx === -1) return;

      widget = update(widget, {
        config: {
          rules: {
            [ruleIdx]: { label: { $set: label } },
          },
        },
      });
      return updateRule({
        variables: {
          funcID: widget.funcID,
          widgetID: widget.ID,
          ruleID,
          label,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateSwitchRuleV3: optimisticFunctionChanges({
            funcID: widget.funcID,
            widgetsChanged: [widget],
          }),
        },
      });
    },
    addCondition: (widget, { ruleID, beforeID, correlationID, inputID, op }) =>
      addCondition({
        variables: {
          funcID: widget.funcID,
          widgetID: widget.ID,
          ruleID,
          beforeID,
          correlationID,
          inputID,
          op,
        },
      }),
    deleteCondition: (widget, { ruleID, condID }) =>
      deleteCondition({
        variables: {
          funcID: widget.funcID,
          widgetID: widget.ID,
          ruleID,
          condID,
        },
      }),
    updateCondition: (widget, { ruleID, condID, inputID, op }) =>
      updateCondition({
        variables: {
          funcID: widget.funcID,
          widgetID: widget.ID,
          ruleID,
          condID,
          inputID,
          op,
        },
      }),
    updateInput: (widget, input, { label, comment, dataType }) => {
      return updateInput({
        variables: {
          funcID: widget.funcID,
          widgetID: widget.ID,
          inputID: input.ID,
          label,
          comment,
          dataType,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateSwitchInputV3: optimisticFunctionChanges({
            funcID: widget.funcID,
            templatesChanged: [
              {
                __typename: 'CellTemplate',
                ...input,
                comment: comment !== undefined ? comment : input.comment,
                dataType: dataType ? dataType : input.dataType,
                label: label !== undefined ? label : input.label,
              },
            ],
          }),
        },
      });
    },
    updateOutput: (widget, { label, comment, dataType }) => {
      const output = widget.config.output;
      return updateOutput({
        variables: {
          funcID: widget.funcID,
          widgetID: widget.ID,
          label,
          comment,
          dataType,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateSwitchOutputV3: optimisticFunctionChanges({
            funcID: widget.funcID,
            templatesChanged: [
              {
                __typename: 'CellTemplate',
                ...output,
                comment: comment !== undefined ? comment : output.comment,
                dataType: dataType ? dataType : output.dataType,
                label: label !== undefined ? label : output.label,
              },
            ],
          }),
        },
      });
    },
  };
}
