import React, { PureComponent } from 'react';
import styled from '@emotion/styled';

import Dialog from '<components>/Dialog';
import Button from '<src>/components/Button';
import TextInput from '<src>/components/TextInput';

const StyledFooter = styled(Dialog.Footer)`
  button {
    margin-left: 10px;
  }
`;

export default class DeleteAccountDialog extends PureComponent {
  state = {
    verify: '',
  };

  render() {
    const { deleting, visible, onClose, onSubmit } = this.props;
    const { verify } = this.state;
    return (
      <Dialog visible={visible ? 1 : 0} onRequestClose={onClose}>
        <Dialog.Header>
          <Dialog.Headline>Delete Account</Dialog.Headline>
        </Dialog.Header>
        <Dialog.Body>
          <p>Are you sure you want to delete this account?</p>
          <p>Type "DELETE" below to confirm</p>
          <TextInput
            onChange={(e) => this.setState({ verify: e.target.value })}
          />
        </Dialog.Body>
        <StyledFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button disabled={deleting || verify !== 'DELETE'} onClick={onSubmit}>
            Delete
          </Button>
        </StyledFooter>
      </Dialog>
    );
  }
}
