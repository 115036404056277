import React, { useState, useCallback } from 'react';
import { useOAuth } from '<sections>/oauth';

import QuickBooksConnectBtn from '<components>/QBConnectButton';

export default function QuickBooksPane({
  children: renderBody,
  createCredential,
  credential,
}) {
  const [creating, setCreating] = useState(false);

  const createNewCredential = useCallback(
    async ({ code, scope, realmId: realmID, redirectURL } = {}) => {
      setCreating(true);
      await createCredential({
        variables: {
          input: {
            ...(!credential && { name: `Quickbooks Online` }),
            ...(credential && { ID: credential.ID }),
            provider: {
              providerName: 'quickbooks',
              providerParams: {
                realmID,
              },
              credName: 'oauthCode',
              credParams: {
                code,
                scope,
                redirectURL,
              },
            },
          },
        },
      });
      setCreating(false);
    },
    [createCredential, credential]
  );

  const [doOAuth, popupVisible] = useOAuth('quickbooks', createNewCredential);

  const body = (
    <>
      <QuickBooksConnectBtn onClick={doOAuth} disabled={creating} />
    </>
  );

  return renderBody(body, doOAuth, popupVisible || creating, doOAuth);
}
