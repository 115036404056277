import { useContext } from 'react';

import RegisterAccount, { RegisteredAccount } from './RegisterAccount';

export { RegisteredAccount } from './RegisterAccount';
export default RegisterAccount;

export function useRegisteredAccount() {
  return useContext(RegisteredAccount);
}
