import { useContext, useEffect } from 'react';

import { useAuth } from '<src>/auth';
import { RegisteredAccount } from '<components>/RegisterAccount';

const testAppID = 'v8zo6d1c';
const prodAppID = 'p26lejcc';
const appIDMap = {
  'alpha.numbrz.com': prodAppID,
  'app.numbrz.com': prodAppID,
  'app.numbrz.org': prodAppID,
  'alpha.debug': prodAppID,
  'app.debug': prodAppID,
};
const app_id = appIDMap[window.location.hostname] || testAppID;

(function () {
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    var d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function () {
      var s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = `https://widget.intercom.io/widget/${app_id}`;
      var x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    };
    if (document.readyState === 'complete') {
      l();
    } else if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
})();

export default function IntercomMessenger({ children }) {
  const { user: authUser } = useAuth();
  const user = useContext(RegisteredAccount);
  const {
    fullName,
    externalID,
    externalIDHash,
    primaryAccount = {},
  } = user || {};
  const { ID: primaryAccountID } = primaryAccount;

  useEffect(() => {
    if (!authUser) return;
    if (externalIDHash) {
      window.Intercom('boot', {
        app_id,
        email: authUser.email,
        name: fullName,
        user_id: externalID,
        user_hash: externalIDHash,
        hide_default_launcher: true,
        primary_account_id: primaryAccountID,
      });
    }
  }, [authUser, externalID, externalIDHash, fullName, primaryAccountID]);

  return children;
}
