import React from 'react';

import * as styles from '../../styles';
import CatalogStats from '../../CatalogStats';

export const modelTypeLabel = {
  DeploySource: 'Template',
  DeployService: 'Service',
};

export default function CatalogItem({ catalogProject }) {
  return (
    <styles.ListItem to={`/catalog/${catalogProject.ID}`}>
      <styles.ListItem.Header>
        <h2>{catalogProject.name}</h2>
        <h3>
          {catalogProject.isLibrary
            ? 'Library'
            : modelTypeLabel[catalogProject.type]}
        </h3>
        {!catalogProject.currentReleaseID && (
          <styles.CategoryChiclet>Coming Soon</styles.CategoryChiclet>
        )}
        {catalogProject.helpWanted && (
          <styles.CategoryChiclet>Help Wanted</styles.CategoryChiclet>
        )}
      </styles.ListItem.Header>
      <styles.ListItem.Body>
        <CatalogStats catalogProject={catalogProject} small center />
        <div className="tag-line">{catalogProject.tagLine}</div>
      </styles.ListItem.Body>
      <styles.ListItem.Footer justifyContent="flex-start">
        <div>{catalogProject.owner.display}</div>
        <styles.OrgIconCircle />
      </styles.ListItem.Footer>
    </styles.ListItem>
  );
}
