import React, { useContext } from 'react';
import { getDisplayName, hoistStatics } from 'recompose';

import { Context } from '.';

const withFeatureFlags = hoistStatics((BaseComponent) => {
  const name = getDisplayName(BaseComponent);

  function WithFeatureFlags(props) {
    const featureFlags = useContext(Context);
    return <BaseComponent {...props} featureFlags={featureFlags} />;
  }
  WithFeatureFlags.BaseComponent = BaseComponent;
  WithFeatureFlags.displayName = `withFeatureFlags(${name})`;
  return WithFeatureFlags;
});

export default withFeatureFlags;
