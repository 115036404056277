import React, { useMemo, Fragment } from 'react';

import TablesetMappings from './TablesetMappings';
import WaveSpinner from '<components>/WaveSpinner';
import { FlexRowContainer } from '<src>/components/NumbrzPageComponents';
import * as colors from '<components>/colors';

export default function JobDataOverview({
  flowID,
  jobID,
  projectID,
  dataMappings,
  flowTables,
  loading,
}) {
  const jobTables = useMemo(
    () => flowTables.filter((t) => t.prefSource !== 'WorkingTable'),
    [flowTables]
  );

  const tablesetMappings = useMemo(() => {
    const groupedMappings = dataMappings.reduce((grouped, mapping) => {
      if (mapping.dataset) {
        let val = mapping.dataset.tableset.name;
        if (!grouped[val]) grouped[val] = [];
        grouped[val].push(mapping);
      }

      return grouped;
    }, {});

    return Object.keys(groupedMappings).map((key, idx) => {
      const mappings = groupedMappings[key];

      let mappedJobTables = jobTables.filter((jT) => {
        const dM = mappings.find((dm) => dm.tableID === jT.ID);
        if (dM && dM.dataset) return true;

        return false;
      });
      mappings.forEach((m) => {});

      return mappedJobTables.length > 0 ? (
        <TablesetMappings
          key={idx}
          jobID={jobID}
          flowID={flowID}
          projectID={projectID}
          tablesetIdx={idx}
          numOfTablesets={Object.keys(groupedMappings).length}
          tablesetName={key}
          mappings={mappings}
          jobTables={mappedJobTables}
        />
      ) : null;
    });
  }, [dataMappings, jobTables, flowID, projectID, jobID]);

  if (loading) {
    return (
      <FlexRowContainer justifyContent="center" alignItems="center">
        <WaveSpinner />
      </FlexRowContainer>
    );
  }

  return (
    <Fragment>
      {tablesetMappings.length === 0 ? (
        <p style={{ margin: '0 15px 0 5px', color: colors.gray2 }}>
          No data mappings found.
        </p>
      ) : (
        tablesetMappings
      )}
    </Fragment>
  );
}
