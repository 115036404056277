import React, { useRef, useCallback, useState, Fragment } from 'react';
import fp from 'lodash/fp';
import FlowMasthead from '../FlowMasthead';
import {
  ColumnWrapper,
  FlexColumnContainer,
  FlexRowContainer,
  PageContent,
} from '<components>/NumbrzPageComponents';
import UnderlinedHeader from '<src>/components/UnderlinedHeader';
import { RoundedContentWrapper } from '<src>/components/NumbrzVerticalEditor';
import {
  TextIconBtn,
  InternalDataLink,
  MappingHdr,
  MappingSubhdr,
  JTMappingContainer,
} from '<src>/sections/jobs/styles';
import { GrayChiclet } from '<components>/NumbrzButtons';
import * as colors from '<components>/colors';

function StageRow({
  flowID,
  stage,
  flowStages = [],
  onIncludeStage,
  onRemoveStage,
  last,
}) {
  const [processing, setProcessing] = useState(false);
  const isStageIncluded = flowStages.includes(stage.stageID);

  const handleIncludeRemove = useCallback(
    (e, action) => {
      const callback = action === 'include' ? onIncludeStage : onRemoveStage;
      const payload =
        action === 'include' ? [e, stage.stageID] : [e, { ID: stage.stageID }];
      setProcessing(true);
      callback(...payload);
      setProcessing(false);
    },
    [onIncludeStage, onRemoveStage, stage.stageID]
  );

  const stageFlowURL = `/models/${stage.modelID}/flows/${stage.flowID}/overview`;
  const stageURL = `/models/${stage.modelID}/flows/${stage.flowID}/stages/${stage.stageID}`;

  return (
    <JTMappingContainer last={last}>
      <FlexRowContainer
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        padding="4px 1px 4px 3px"
      >
        <FlexColumnContainer alignItems="flex-start">
          <MappingHdr>
            <InternalDataLink bold fontSize="12px" to={stageURL}>
              {stage.stageName}
            </InternalDataLink>
          </MappingHdr>
          <MappingSubhdr>
            <InternalDataLink
              fontSize="11px"
              to={stageFlowURL}
              textColor={colors.gray2}
            >
              {stage.flowName}
            </InternalDataLink>
          </MappingSubhdr>
        </FlexColumnContainer>

        <FlexRowContainer alignItems="center">
          {isStageIncluded && (
            <Fragment>
              <GrayChiclet>Included</GrayChiclet>
              <TextIconBtn
                disabled={processing}
                onClick={(e) => handleIncludeRemove(e, 'remove')}
              >
                {processing ? `Excluding...` : `Exclude`}
              </TextIconBtn>
            </Fragment>
          )}
          {!isStageIncluded && (
            <TextIconBtn
              disabled={processing}
              onClick={(e) => handleIncludeRemove(e, 'include')}
            >
              {processing ? `Including...` : `Include`}
            </TextIconBtn>
          )}
        </FlexRowContainer>
      </FlexRowContainer>
    </JTMappingContainer>
  );
}

export default function IncludeFlowEditor({
  flowState,
  enterTestMode,
  testMode,
  testFeature,
  flowID,
}) {
  const { api, flow } = flowState;

  const refFlowStages = flow ? flow.includableStages : [];
  const flowStageIDs = flow.stagesV2.map((s) => s.ID);
  const includableStageIDs = refFlowStages.map((s) => s.stageID);
  const includedStageIDs = flowStageIDs.filter((sID) =>
    includableStageIDs.includes(sID)
  );

  const allIncluded = includedStageIDs.length === includableStageIDs.length;

  const nameRef = useRef();

  return (
    <Fragment>
      <FlowMasthead
        enterTestMode={enterTestMode}
        testMode={testMode}
        testFeature={testFeature}
        title={fp.getOr(undefined, '[0].flowName', refFlowStages)}
        onTitleChange={undefined}
        ref={nameRef}
        flowID={flowID}
      />
      <ColumnWrapper>
        <PageContent height="auto">
          <Fragment>
            <RoundedContentWrapper>
              <ColumnWrapper padding={'0 0 0 0'}>
                <UnderlinedHeader justifyContent="space-between">
                  STAGES
                  <FlexRowContainer>
                    {!allIncluded && (
                      <TextIconBtn onClick={api.onIncludeAllStages}>
                        Include all
                      </TextIconBtn>
                    )}
                    {includedStageIDs.length > 0 && (
                      <TextIconBtn onClick={api.onRemoveAllIncludedStages}>
                        Exclude all
                      </TextIconBtn>
                    )}
                  </FlexRowContainer>
                </UnderlinedHeader>
                {refFlowStages.map((rStage, idx) => (
                  <StageRow
                    key={idx}
                    last={idx === refFlowStages.length - 1}
                    flowID={flowID}
                    stage={rStage}
                    flowStages={flowStageIDs}
                    onIncludeStage={api.onIncludeStage}
                    onRemoveStage={api.onRemoveIncludedStage}
                  />
                ))}
              </ColumnWrapper>
            </RoundedContentWrapper>
          </Fragment>
        </PageContent>
      </ColumnWrapper>
    </Fragment>
  );
}
