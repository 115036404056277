import { useCallback, useEffect, useMemo, useState } from 'react';
import update from 'immutability-helper';

function mapRangeCells(range, cells) {
  return {
    ...range,
    normTemplate: range.template,
    cells: range.cellIDs.map((id) => cells[id]),
  };
}

function makeInitialState(func, linkSource) {
  const state = {
    func,
    widget: null,
    cells: null,
    uiWidget: null,
    ...func.initialSetupState,
  };

  if (state.widget && state.cells) {
    state.uiWidget = makeUIWidget(func, state.widget, state.cells);
    if (linkSource) {
      const targetID = state.widget.rows[0].cellIDs[0];
      state.cells = update(state.cells, {
        [targetID]: {
          dataType: { $set: linkSource.dataType },
          value: { $set: linkSource.value },
          link: {
            $set: {
              sourceID: linkSource.ID,
              sourceType: `${linkSource.sourceType}Source`,
            },
          },
        },
      });
    }
  }

  return state;
}

function makeUIWidget(func, widget, cells, parent) {
  if (!widget) return null;
  let uiWidget = {
    ...widget,
    rows: widget.rows.map((row) => mapRangeCells(row, cells)),
    columns: widget.columns.map((col) => mapRangeCells(col, cells)),
  };
  if (func.makeUIWidget) {
    uiWidget = func.makeUIWidget(uiWidget, cells, parent);
  }
  return uiWidget;
}

export default function useBuildWidget(pfunc, linkSource, parent) {
  const [state, setState] = useState(makeInitialState(pfunc, linkSource));

  useEffect(() => {
    setState(makeInitialState(pfunc, linkSource));
  }, [pfunc, linkSource]);

  const setWidget = useCallback(
    (widget) =>
      setState((state) => {
        if (typeof widget === 'function') {
          widget = widget(state.widget);
        }
        return update(state, { widget: { $set: widget } });
      }),
    []
  );
  const updateRangeLabel = useCallback(
    (prop, range, label) =>
      setWidget((widget) => {
        const rIdx = widget[prop].findIndex((r) => r.ID === range.ID);
        if (rIdx === -1) {
          return widget;
        }
        return update(widget, {
          [prop]: {
            [rIdx]: {
              template: {
                label: { $set: label },
              },
            },
          },
        });
      }),
    [setWidget]
  );
  const updateRow = useCallback(
    (_, range, { label }) => updateRangeLabel('rows', range, label),
    [updateRangeLabel]
  );
  const updateColumn = useCallback(
    (_, range, { label }) => updateRangeLabel('columns', range, label),
    [updateRangeLabel]
  );
  const updateCellValue = useCallback(
    ({ ID }, { dataType, value }) =>
      setState((state) => {
        if (!state.cells) return state;
        return update(state, {
          cells: {
            [ID]: {
              dataType: { $set: dataType },
              value: { $set: value },
              link: { $set: null },
            },
          },
        });
      }),
    []
  );
  const updateCellLink = useCallback(
    (target, source) =>
      setState((state) => {
        if (!state.cells) return state;
        const cellUpdates = {};
        Object.keys(state.cells)
          .filter((id) => !!state.cells[id].link)
          .forEach((id) => {
            cellUpdates[id] = {
              $unset: ['link'],
              dataType: { $set: 'Any' },
              value: { $set: null },
            };
          });
        cellUpdates[target.ID] = {
          link: {
            $set: {
              sourceID: source.ID,
              sourceType: `${source.sourceType}Source`,
            },
          },
          dataType: { $set: source.dataType },
          value: { $set: source.value },
        };

        return update(state, { cells: cellUpdates });
      }),
    []
  );
  const updateWidget = useCallback(
    (_, changes) =>
      setState((state) => {
        if (!state.widget) return state;
        return update(state, {
          widget: { $set: { ...state.widget, ...changes } },
        });
      }),
    []
  );

  useEffect(() => {
    if (!state.widget) return;

    setState((state) => {
      return {
        ...state,
        uiWidget: makeUIWidget(state.func, state.widget, state.cells, parent),
      };
    });
  }, [state.widget, state.cells, parent]);

  const api = useMemo(() => {
    const baseAPI = {
      updateCellLink,
      updateCellValue,
      updateWidget,
      updateRow,
      updateColumn,
    };
    if (state.func.makeSetupAPI) {
      return {
        ...baseAPI,
        ...state.func.makeSetupAPI(state, baseAPI, setWidget, parent),
      };
    }
    return baseAPI;
  }, [
    state,
    setWidget,
    updateCellLink,
    updateCellValue,
    updateColumn,
    updateRow,
    updateWidget,
    parent,
  ]);

  return {
    api,
    func: state.func,
    cells: state.cells,
    widget: state.widget,
    uiWidget: state.uiWidget,
    setWidget,
  };
}
