import React, { Fragment, useState } from 'react';

import { ConfigTable, ConfigurationWrapper } from '../styles';
import Button from '<src>/components/Button';
import { ConfigField } from '<src>/components/NumbrzVerticalEditor';
import ColumnTypePicker from '../ColumnTypePicker';
import AddVariableInput from './AddVariableInput';
import { formatCell, parseUserValue } from '<sections>/functions/Cell/util';
import { RoundChiclet, NumbrzTooltip } from '<components>/NumbrzButtons';
import * as colors from '<components>/colors';
import { FlexRowContainer } from '<src>/components/NumbrzPageComponents';
import { InternalDataLinkBtn } from '<src>/sections/jobs/styles';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import UnderlinedHeader from '<src>/components/UnderlinedHeader';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';

const columnTypeOptions = [
  { value: 'String', text: 'String' },
  { value: 'Number', text: 'Number' },
  { value: 'DateTime', text: 'Date' },
  { value: 'Boolean', text: 'Boolean' },
];

function getVarSourcePath({ modelID, flowID }) {
  return `/models/${modelID}/flows/${flowID}/variables`;
}

export default function UserVariablesEditor({
  userVariables = [],
  jobVariables = [],
  onAddUpdateUserVar,
  onRemoveUserVar,
  mode = 'flow',
  headerTitle,
  noContainer = false,
  expandable = false,
}) {
  const isJobEditor = mode === 'job';
  const [expanded, setExpanded] = useState(true);

  const onUpdateVarField = (uVar, field, value) => {
    const newVar = { ...uVar, toElement: { ...uVar.toElement } };
    if (field === 'value') {
      newVar.defaultVal = parseUserValue(value, { ms: true }).value;
    } else {
      newVar.toElement[field] = value;
    }
    onAddUpdateUserVar(null, newVar);
  };

  const WrapperComp = noContainer ? Fragment : ConfigurationWrapper;
  const ExpandComp = expanded
    ? ArrowDropUpRoundedIcon
    : ArrowDropDownRoundedIcon;
  const expandTitle = expandable ? (expanded ? 'Hide' : 'Show') : '';
  const expandHandler = expandable ? () => setExpanded(!expanded) : null;

  return (
    <WrapperComp>
      {headerTitle && (
        <UnderlinedHeader>
          <FlexRowContainer
            alignItems="center"
            justifyContent="flex-start"
            title={expandTitle}
            onClick={expandHandler}
            style={{ cursor: expandable ? 'pointer' : 'none' }}
          >
            {headerTitle}
            {expandable && <ExpandComp />}
          </FlexRowContainer>
        </UnderlinedHeader>
      )}
      {(userVariables.length > 0 || !isJobEditor) && expanded ? (
        <ConfigTable style={{ width: '100%' }}>
          <thead>
            <tr>
              <th className="field">Name</th>
              <th className="field">Value</th>
              <th className="type">Type</th>
              <th>Note</th>
              {!isJobEditor && <th className="btn" />}
            </tr>
          </thead>
          <tbody>
            {userVariables.map((uVar) => {
              const jobVar = jobVariables.find(
                (jV) => jV.key === uVar.toElement.key
              );
              const editorVal =
                isJobEditor && jobVar ? jobVar.val : uVar.defaultVal;
              return (
                <tr key={uVar.toElement.key}>
                  <td>
                    <FlexRowContainer alignItems="center">
                      <ConfigField
                        singleClick
                        value={uVar.toElement.label}
                        disabled={isJobEditor || uVar.isIncluded}
                        onSubmit={(e) =>
                          onUpdateVarField(uVar, 'label', e.value)
                        }
                      />
                      {uVar.isIncluded && !isJobEditor && (
                        <RoundChiclet backgroundColor={colors.gray3}>
                          Included
                        </RoundChiclet>
                      )}
                    </FlexRowContainer>
                  </td>
                  <td>
                    <ConfigField
                      singleClick
                      value={formatCell(
                        {
                          value: editorVal,
                          dataType: uVar.toElement.type,
                          format: {
                            number: { decimals: null },
                          },
                        },
                        { ms: true }
                      )}
                      disabled={!isJobEditor && uVar.isIncluded ? true : false}
                      onSubmit={(e) => onUpdateVarField(uVar, 'value', e.value)}
                    />
                  </td>
                  <td className="type">
                    <ColumnTypePicker
                      type={uVar.toElement.type}
                      columnTypeOptions={columnTypeOptions}
                      onChange={(e, { type }) =>
                        onUpdateVarField(uVar, 'type', type)
                      }
                      disabled={isJobEditor || uVar.isIncluded}
                    />
                  </td>
                  <td>
                    <ConfigField
                      singleClick
                      value={uVar.toElement.comment}
                      disabled={isJobEditor || uVar.isIncluded}
                      onSubmit={(e) =>
                        onUpdateVarField(uVar, 'comment', e.value)
                      }
                    />
                  </td>
                  {!isJobEditor && (
                    <td className="btn">
                      {!uVar.isIncluded && (
                        <Button.TableDeleteBtn
                          onClick={(e) =>
                            onRemoveUserVar(e, uVar.toElement.key)
                          }
                        />
                      )}
                      {uVar.isIncluded && (
                        <InternalDataLinkBtn
                          to={getVarSourcePath(uVar.includedFrom)}
                          margin="0 auto"
                          noText
                        >
                          <NumbrzTooltip
                            content={`Source: ${uVar.includedFrom.flowName}`}
                            trigger={<LaunchRoundedIcon />}
                          />
                        </InternalDataLinkBtn>
                      )}
                    </td>
                  )}
                </tr>
              );
            })}
            {!isJobEditor && (
              <AddVariableInput
                onAddVariable={onAddUpdateUserVar}
                variableTypeOptions={columnTypeOptions}
              />
            )}
          </tbody>
        </ConfigTable>
      ) : isJobEditor && userVariables.length === 0 ? (
        <p style={{ margin: '0 15px 0 5px', color: colors.gray2 }}>
          No variables found. Variables can be defined in the flow.
        </p>
      ) : null}
    </WrapperComp>
  );
}
