export const opOptions = [
  { key: 'EQ', value: 'EQ', text: '=' },
  { key: 'NE', value: 'NE', text: '!=' },
  { key: 'LT', value: 'LT', text: '<' },
  { key: 'LTE', value: 'LTE', text: '<=' },
  { key: 'GT', value: 'GT', text: '>' },
  { key: 'GTE', value: 'GTE', text: '>=' },
  { key: 'IsEmpty', value: 'IsEmpty', text: 'is empty' },
  { key: 'IsNotEmpty', value: 'IsNotEmpty', text: 'is not empty' },
];

export const opLabels = opOptions.reduce(
  (acc, op) => ({
    ...acc,
    [op.key]: op.text,
  }),
  {}
);

export function isInputRequired(op) {
  return op !== 'IsEmpty' && op !== 'IsNotEmpty';
}
