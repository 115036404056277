import React, { Fragment } from 'react';

import { isEmpty } from '<src>/utils/text';
import { StyledTextInput } from '<src>/sections/data/styles';

function isValidSheetURL(url) {
  if (isEmpty(url)) return false;
  if (url.startsWith(' ')) return false;
  return true;
}

export default function GoogleSpreadsheetPane({
  children: renderBody,
  onInputChange,
  connectorParams,
  inputDisabled = false,
}) {
  const body = (
    <Fragment>
      <StyledTextInput
        //eslint-disable-next-line
        autoFocus={true}
        placeholder="Google Spreadheet URL (Required)"
        name="spreadsheetURL"
        onChange={onInputChange}
        disabled={inputDisabled}
        value={connectorParams.spreadsheetURL}
      />
    </Fragment>
  );

  return renderBody(body, !isValidSheetURL(connectorParams.spreadsheetURL));
}
