import styled from '@emotion/styled';
import * as colors from '<components>/colors';
import { Accordion, Icon } from 'semantic-ui-react';
import { StyledScrollbar } from '<components>/NumbrzPageComponents';

const ignoreSsrWarning =
  '/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */';

export const CloseBtn = styled(Icon)`
  cursor: pointer;
  margin: 0 10px;
`;

export const MenuHeader = styled('h5')`
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px 0 !important;
  i {
    margin-right: 0;
  }
`;

export const StyledBody = styled('div')`
  margin: 2px 3px 3px 3px;
`;

export const HeaderContainer = styled('div')`
  display: flex;
  align-items: flex-start;
  color: ${colors.gray1b};
  font-weight: bold;
  border-bottom: 1px dotted ${colors.yellow1b};
  padding: 3px 5px;
  margin: 0 3px;
  &:empty {
    border-color: transparent;
    margin-bottom: 0;
  }

  span {
    font-size: 12px;
  }
  i {
    margin-left: 0;
    font-size: 0.9em !important;
  }
  .dropdown {
    margin-left: 5px;
    i {
      font-size: 1em !important;
    }
  }
  & > :first-child${ignoreSsrWarning} {
    flex: 1;
  }
`;

export const StyledAccordion = styled(Accordion)`
  max-height: 300px;
  min-width: 250px;
  max-width: 400px;
  overflow-y: auto;
  overflow-y: overlay;
  ${StyledScrollbar};
  ::-webkit-scrollbar {
    position: absolute;
  }

  .title {
    display: flex;
    align-items: center;
    color: ${colors.gray1b};
    padding: 2px 5px 2px 2px !important;
    margin-bottom: 2px;
    border-bottom: 1px solid transparent;
    font-weight: bold;
    i {
      font-size: 1.1em !important;
      height: unset !important;
    }
    i.hide {
      display: none;
    }
  }
  .title.active,
  .title:hover {
    border-color: ${colors.gray1b};
  }

  .content {
    padding: 0 !important;
  }
`;

export const PanelTitle = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  & > :not(:first-child${ignoreSsrWarning}) {
    color: ${colors.gray2};
    font-size: 0.9em;
    font-weight: 700;
  }
  margin-right: 10px;
`;

export const FieldContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colors.gray1b};
  cursor: pointer;
  padding: 2px 5px 2px 25px !important;
  transition: background-color 125ms ease-in-out;
  i {
    height: unset;
  }
  i.check {
    color: ${colors.green1};
  }

  &.add {
    justify-content: flex-start;
  }
  &.select-all {
    border-bottom: 1px solid ${colors.gray3};
  }
  &:hover {
    background-color: ${colors.gray4};
    border-radius: 4px;
  }
`;

export const FilterContainer = styled('span')`
  & > i {
    margin-right: 2px;
  }
`;

export const SelectAllContainer = styled('span')`
  display: inline-flex;
  flex-direction: row;
  & > :first-child${ignoreSsrWarning} {
    flex: 1;
    margin-right: 8px;
  }
`;
