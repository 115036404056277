import React, { forwardRef, useMemo } from 'react';
import styled from '@emotion/styled';

import TableDefDropdown from '../../TableDefDropdown';
import Step from '../Step';
import { stepsByType } from '../stepData';

import FieldMappings, { automap } from './FieldMappings';
import OutputSettings from './OutputSettings';
import Conditions from '../../Conditions';

const TablePicker = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > span {
    margin-right: 8px;
  }
`;

function useOutputAPI(onChange, onAddTableProp, elements, flow, step) {
  const { mappings } = step;
  return useMemo(() => {
    if (!onChange) {
      return {};
    }

    const selectTable = (e, table) =>
      onChange(e, {
        tableID: table.ID,
        mappings: automap(elements, mappings, table.schema.elements),
        suspended: false,
        beforeWrite: {},
      });

    const onAddTable = onAddTableProp
      ? async (e, table) => {
          const name =
            table.prefSource === 'WorkingTable'
              ? 'New Working Table'
              : 'New Output Table';
          const newTable = await onAddTableProp(e, { name, ...table });
          await selectTable(e, newTable);
          return newTable;
        }
      : null;
    const onSelectTable = (e, { value: tableID }) =>
      selectTable(
        e,
        flow.data.find((t) => t.ID === tableID)
      );
    const onChangeOutputSettings = (e, { suspended }) => {
      const changes = { suspended };

      return onChange(e, changes);
    };
    const onChangeConditions = (e, { conditions: filter }) =>
      onChange(e, { filter });

    return {
      onChangeConditions,
      onChangeOutputSettings,
      onAddTable,
      onSelectTable,
    };
  }, [elements, flow.data, mappings, onAddTableProp, onChange]);
}

function OutputStep(
  {
    api,
    baseURL,
    flow,
    stage,
    step,
    stageElements: elements = [],
    dataTables,
    dataTablesLoading,
    ...props
  },
  parentRef
) {
  const { onChangeOutput, onChangeTable } = api;
  const { key, mappings, tableID, filter } = step;

  const onChange = onChangeOutput
    ? (e, changes) => onChangeOutput(e, { key, ...changes })
    : null;

  const table = useMemo(
    () => flow.data.find((t) => t.ID === tableID),
    [tableID, flow.data]
  );

  const { onChangeOutputSettings, onAddTable, onSelectTable } = useOutputAPI(
    onChange,
    api.onAddTable,
    elements,
    flow,
    step
  );

  const onChangeConditions = (e, { conditions: filter }) =>
    onChange(e, { filter });

  const returnTo = {
    location: {
      pathname: `${baseURL}/stages/${stage.ID}`,
      hash: `#${step.key}`,
    },
  };

  return (
    <Step ref={parentRef} flow={flow} step={step} api={api} {...props}>
      <TablePicker>
        <span>Target:</span>
        <TableDefDropdown
          disabled={!onChange}
          baseURL={baseURL}
          returnTo={returnTo}
          tableDefs={flow.data}
          value={tableID}
          onAdd={onAddTable}
          onChange={onSelectTable}
          dataTables={dataTables}
          dataTablesLoading={dataTablesLoading}
        />
      </TablePicker>
      {table ? (
        <div>
          <h5>Fields</h5>
          <FieldMappings
            elements={elements}
            mappings={mappings}
            table={table}
            onChange={onChange}
            onChangeTable={onChangeTable}
          />
          {filter.length > 0 || onChange ? (
            <>
              <h5>Output Filters</h5>
              <Conditions
                elements={elements}
                conditions={filter}
                onChange={onChangeConditions}
              />
            </>
          ) : null}
          <h5>Skip</h5>
          <OutputSettings
            suspended={step.suspended}
            onChange={onChangeOutputSettings}
          />
        </div>
      ) : null}
    </Step>
  );
}

// eslint-disable-next-line no-func-assign
OutputStep = forwardRef(OutputStep);
OutputStep.displayName = 'OutputStep';
export default OutputStep;

// Avoid circular dependency
stepsByType.OutputStepV2.Component = OutputStep;
