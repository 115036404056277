import React from 'react';
import fp from 'lodash/fp';
import styled from '@emotion/styled';

import { useQuery, useMutation } from '<src>/apollo/client';

import * as colors from '<components>/colors';
import WaveSpinner from '<components>/WaveSpinner';
import { EditableField } from '<components>/NumbrzPageComponents';
import { BillingWrapper, Container, PageWrapper } from '../Billing/styles';
import UnderlinedHeader from '<src>/components/UnderlinedHeader';

import { GetViewer } from '<sections>/projects/queries';
import {
  GetCustomerInformation,
  UpdateStripeCustomerPersonalInfo,
  UpdateAccountDisplayName,
} from '../../queries';
import NavTabBar from '<components>/NavTabBar';

const InputContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  padding-top: 10px;
  span {
    display: inline-block;

    margin-right: 6%;
    color: ${colors.gray1};
    font-size: 0.9em;
  }
`;

export default function PersonalInfo(props) {
  const { data: { viewer } = {}, loading } = useQuery(GetViewer);
  const {
    data: { customerInformation } = {},
    loading: loadingCustomerInformation,
  } = useQuery(GetCustomerInformation);
  const [updateAccountDisplayName] = useMutation(UpdateAccountDisplayName);
  const [updateStripeCustomerPersonalInfo] = useMutation(
    UpdateStripeCustomerPersonalInfo
  );

  const accountType = fp.getOr(
    null,
    'account.primaryAccount.__typename',
    viewer
  );

  const stripeCustomerID = fp.getOr(
    null,
    'account.primaryAccount.stripeCustomerID',
    viewer
  );

  const stripeEmail = fp.getOr(undefined, 'email', customerInformation);

  const account = !loading && viewer ? viewer.account : undefined;

  const handleInputChange = async (fieldName, newValue) => {
    if (fieldName === 'name') {
      await updateAccountDisplayName({
        variables: {
          input: {
            id: fp.getOr(null, 'account.primaryAccount.ID', viewer),
            name: newValue,
            accountType,
          },
        },
      });
    }

    if (stripeCustomerID) {
      await updateStripeCustomerPersonalInfo({
        variables: {
          customerID: stripeCustomerID,
          ...(fieldName === 'name' && { newName: newValue }),
          ...(fieldName === 'email' && { newEmail: newValue }),
        },
      });
    }
  };

  if (loading || loadingCustomerInformation) return <WaveSpinner />;

  return (
    <PageWrapper>
      <NavTabBar
        navigation={[
          {
            path: `/account/billing/new-org`,
            title: 'Create Team',
            align: 'right',
            className: 'createTeam',
          },
        ]}
      />
      <BillingWrapper>
        <Container>
          <UnderlinedHeader>PROFILE</UnderlinedHeader>
          <InputContainer>
            <span>Name</span>
            <EditableField
              value={
                account.primaryAccount.display ||
                account.primaryAccount.fullName
              }
              onSubmit={(e) => handleInputChange('name', e.value)}
            />
          </InputContainer>
          {stripeEmail && (
            <InputContainer>
              <span>Billing Email</span>
              <EditableField
                value={stripeEmail}
                onChange={(e) => handleInputChange('email', e.value)}
              />
            </InputContainer>
          )}
        </Container>
      </BillingWrapper>
    </PageWrapper>
  );
}
