/** @jsx jsx */
/** @jsxRuntime classic */
import styled from '@emotion/styled';
import * as colors from '<components>/colors';
import Button from '<src>/components/Button';
import { jsx, css } from '@emotion/react';
import { StyledCard } from '<sections>/jobs/styles';
import { Icon } from 'semantic-ui-react';
import { Tag } from '<components>/NumbrzButtons';
import { FlowTestTable, ConfigField } from '<components>/NumbrzVerticalEditor';
import {
  StyledScrollbar,
  PageMasthead,
} from '<components>/NumbrzPageComponents';
import Link from '<src>/components/Link';

export const FlowTestHeader = styled(PageMasthead)`
  justify-content: space-between;
  align-items: center;

  h5 {
    margin: 0;
  }
  div {
    display: flex;
    align-items: center;
  }
`;

export const FlowTestHeaderSeparator = styled('div')`
  width: 1px;
  height: 45px;
  background: ${colors.gray_border};
  margin-right: 10px;
`;

export const Nav = styled('div')``;

export const StageDropdownWrapper = styled('div')`
  width: fit-content;
  display: flex;

  align-items: flex-start;
  justify-content: space-between;
  border-radius: 5px;
  padding: 0 10px 0 10px;
  .dropdown {
    display: flex;
    & > .text {
      color: ${colors.black} !important;
    }

    a {
      color: ${colors.gray1b} !important;
      padding: 0.78571429rem 1.14285714rem !important;
      width: 100%;
      display: block;
      white-space: break-spaces;
      word-break: break-word;
    }
    .menu {
      min-width: 250px;
      .item {
        padding: 0 !important;
      }
    }
    .icon {
      line-height: unset !important;
    }
  }

  button.dropdown {
    min-width: 1.25em;
    width: fit-content;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    margin-left: 4px;
    text-align: center !important;

    i {
      margin: 0;
    }
  }
`;

export const TestDropdownWrapper = styled('div')`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  padding-bottom: 5px;
  background: white;

  .dropdown {
    display: flex;
    align-items: center;
    & > .text {
      color: ${colors.black} !important;
    }
    .menu {
      min-width: 250px;
      .item {
        white-space: break-spaces;
        word-break: break-word;
      }
    }
    .icon {
      line-height: unset !important;
    }
  }

  button.dropdown {
    font-size: 1.2em;
    min-width: 1.25em;
    width: fit-content;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    margin-left: 4px;
    text-align: center !important;

    i {
      margin: 0;
    }
  }

  background: white;

  padding: 0 10px;
`;

export const DropdownLabel = styled('span')`
  margin-right: 10px;
  font-weight: bold;
  font-size: 14px;
  ${(props) => props.large === 'true' && { fontSize: '1.7em' }};
`;

export const TestInputCard = styled(StyledCard)`
  margin-bottom: 20px;
  overflow-x: auto;
  padding: 20px;
  ${StyledScrollbar};
`;

export const TablePlusBtn = (props) => (
  <Button {...props} icon={<Icon name="plus" title="Add Schema Element" />} />
);

export const TestContent = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 40px 35px;
  margin-right: 5px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  ${StyledScrollbar};
`;
export const SelectTestWrapper = styled('div')`
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${colors.gray1};
  button {
    margin-left: 10px;
    min-width: 40px;
  }
  div.dropdown_wrapper {
    display: flex;
    align-items: center;

    justify-content: space-between;
  }
  padding: 10px 20px 10px 8px;
  border: 1px solid ${colors.gray3};
  border-radius: 8px;
`;

export const DropdownSeparator = styled('div')`
  height: 1px;
  width: 100%;
  background: ${colors.gray3};
  margin: 8px auto;
`;

export const TestResultContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0px 0 20px 0;
  overflow-y: auto;
`;

export const Toolbar = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CreateTestBtn = (props) => (
  <Button
    {...props}
    baseColor={colors.blue1}
    activeColor={colors.blue2}
    css={css`
      font-size: 11px;
      pointer-events: auto !important;
      border-radius: 4px;
      padding: 4px 8px;
      border: none;
      height: unset;
      margin-left: 10px;
    `}
  />
);

export const ResultContent = styled('div')`
  padding: 10px 15px;
`;

export const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 20px 0 0 0;

  border: ${(props) => {
    const color = props.isInput
      ? colors.blue6
      : props.isOutput
        ? colors.green3
        : colors.gray_border;
    return `1px solid ${color}`;
  }};
  background: white;
  border-radius: 8px;
  h4 {
    margin: 0 0 0 5px;
  }
`;

export const ResultContainer = styled('div')`
  width: 100%;
  max-height: ${(props) => (props.expanded ? 'unset' : '200px')};
  display: flex;
  overflow-x: auto;
  justify-content: flex-start;
  ${(props) => props.margin && { margin: props.margin }};
  ${StyledScrollbar};
`;

export const StyledTable = styled(FlowTestTable)`
  width: 100%;
  table-layout: unset;
  th {
    background: white !important;
  }
  td {
    background: white !important;
  }
  ${StyledScrollbar};
`;

export const CellContent = styled('div')`
  display: flex;
  justify-content: ${(props) =>
    props.alignRight === 'true' ? 'flex-end' : 'flex-start'};
  align-items: center;
  i {
    font-size: 1.4em;
    cursor: pointer;
  }
`;

export const StyledCell = styled('td')`
  ${(props) =>
    props.isOutput && {
      backgroundColor: `${colors.green4} !important;`,
    }}
  ${(props) =>
    props.isInput && {
      backgroundColor: `${colors.blue7} !important`,
    }}
`;

export const LogItemFlags = styled('div')`
  display: flex;
  justify-content: space-around;
`;

export const StyledTag = styled(Tag)`
  margin: 0 5px;
`;

export const TestResultHdr = styled('div')`
  display: flex;
  margin: 10px 0;
  justify-content: center;
`;

export const NoResultWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  padding: 20px;
  margin: 20px auto 20px auto;
  border-radius: 8px;
  border: 1px solid ${colors.gray_border};
  background: ${colors.yellow7};
  color: ${colors.gray1b};
  font-size: 13px;
  button {
    margin-top: 10px;
  }
`;

export const CellInput = styled(ConfigField)`
  margin-bottom: 0;
  margin-right: 5px;
`;

export const TestResultTable = styled(FlowTestTable)`
  width: auto;
  thead {
    position: sticky;
    inset-block-start: 0;
    z-index: 1;
    top: 0;
    border-top: 1px solid ${colors.gray3};
    border-bottom: 1px solid ${colors.gray3};
    box-shadow: 0px 1px 2px ${colors.gray3};
  }
  td {
    border: none;
  }
  td.rowCnt {
    min-width: 30px;
    text-align: center;
    color: ${colors.gray1a};
  }
  th {
    border-top: 1px solid ${colors.gray3};
  }
  td,
  th {
    border-bottom: 1px solid ${colors.gray3};
    border-right: 1px solid ${colors.gray3};
    border-left: none;
  }
  border-spacing: 0px;
  border-collapse: separate;
  margin: 0 0 10px 0;
  border-left: 1px solid ${colors.gray3};
`;

export const StepHdrToolbar = styled('div')`
  padding: 5px 10px 5px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.gray_border};
  background: ${colors.gray5};
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  ${(props) =>
    props.isInput && {
      background: colors.blue7,
      borderBottom: `1px solid ${colors.blue6}`,
    }};
  ${(props) =>
    props.isOutput && {
      background: colors.green4,
      borderBottom: `1px solid ${colors.green3}`,
    }};

  a {
    margin-bottom: 0;
    color: ${colors.gray1};
    font-size: 13px;
  }
`;

export const ResultDataToolbar = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 10px 0;
  position: relative;
  button {
    height: unset;
  }
`;

export const TableToolbar = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 5px 5px 0;
`;

export const StepToolbarBtn = (props) => (
  <Button.IconBtn
    {...props}
    baseColor="transparent"
    contentColor={colors.gray2}
    activeColor="transparent"
    contrastColor="transparent"
    css={css`
      font-size: 12px;
      min-width: unset;
      text-transform: none;
      border: none;
      i {
        font-size: 12px;
        color: #999;
        :hover {
          color: ${colors.gray2b};
        }
      }
    `}
  />
);

export const ResultsToolbarBtn = ({ highlightActive, smallIcon, ...props }) => (
  <StepToolbarBtn
    {...props}
    css={css`
      display: flex;
      align-items: center;
      padding: 3px 6px;
      margin-right: 10px;
      color: ${colors.gray1d};
      ${highlightActive && `border: 1px solid ${colors.gray_border}`};
      :hover,
      :focus {
        color: ${colors.gray1d};
        background: ${props.activeColor || colors.gray_border};
        ${highlightActive && `border: 1px solid ${colors.gray_border}`};
      }
      ${highlightActive && `background: ${props.activeColor};`}
      i {
        font-size: ${smallIcon ? '9px' : '11px'};

        color: ${colors.gray1d};
      }
      i:hover {
        color: ${colors.gray1d};
      }
    `}
  />
);

export const StepToolBarMenu = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: absolute;
  top: 25px;
  left: 0px;
  min-width: 250px;
  min-height: 120px;

  z-index: 999;
  background: white;
  border: 2px solid ${colors.gray3};
  border-radius: 4px;
  color: ${colors.gray1d};
  h5 {
    font-size: 13px;
    letter-spacing: 0.3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid ${colors.gray3};
    margin-bottom: 10px;
    padding-bottom: 10px;
    color: ${colors.gray2};
    button {
      height: unset;
      padding: 0;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      padding-left: 5px;
      display: flex;
      align-items: center;
    }
  }

  .ui.toggle.checkbox {
    width: unset;
    label {
      padding-top: 0;
      padding-right: 3rem;
    }
    label:before {
      width: 22px;
      height: 11.5px;
      margin-top: 5px;
      background-color: ${colors.gray2} !important;
    }
    label:after {
      margin-top: 5px;
      left: 3px;
      width: 8px;
      height: 8px;
      top: 1.5px;
    }
    input {
      width: unset;
      height: unset;
    }
    input:checked ~ label:after {
      left: 11px;
    }
  }
`;

export const StepToolBarFooter = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  margin-top: 10px;
  button {
    color: ${colors.gray1d};
    background: ${colors.gray4};
    padding: 4px 15px;
    :hover {
      color: ${colors.gray1};
    }
  }
`;

export const StepDropdownWrapper = styled('div')`
  position: relative;
  display: block;
`;

export const InputLink = styled(Link)`
  font-size: 12px !important;
  line-height: initial;
  margin-left: 10px;
  padding: 2px 8px;
`;
