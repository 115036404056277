import React from 'react';
import fp from 'lodash/fp';
import { DataLinkErrChiclet } from '../styles';

export default function DataIssues({ table }) {
  const issues = fp.getOr([], 'state.issues', table);
  const tablesetID = fp.getOr(undefined, 'tableset.ID', table);
  const tablesetOverviewURL = tablesetID
    ? `/data/${tablesetID}/overview`
    : null;

  if (issues.length > 0) {
    for (const idx in issues) {
      if (issues[idx].code === 'TablesetNeedsAuth') {
        return (
          <DataLinkErrChiclet to={tablesetOverviewURL}>
            Fix Auth
          </DataLinkErrChiclet>
        );
      }
    }
  }
}
