import { gql } from '@apollo/client';

import { FunctionChangesFragment } from './fragments';
import useEditFunction from './useEditFunction';

const UpdateCellLink = gql`
  mutation UpdateCellLink(
    $funcID: CommonID!
    $cellID: ID!
    $sourceID: ID
    $sourceType: LinkSourceType
  ) {
    updateCellLinkV3(
      funcID: $funcID
      cellID: $cellID
      sourceID: $sourceID
      sourceType: $sourceType
    ) {
      ...FunctionChangesFragment
    }
  }
  ${FunctionChangesFragment}
`;

export default function useUpdateCellLink() {
  const updateCellLink = useEditFunction(UpdateCellLink, 'updateCellLinkV3');

  return (cell, { sourceID, sourceType }) =>
    updateCellLink({
      variables: {
        funcID: cell.funcID,
        cellID: cell.ID,
        sourceID,
        sourceType,
      },
    });
}
