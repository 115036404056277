import React, { Fragment } from 'react';
import moment from 'moment';

import {
  PlanStatusLabel,
  StatusDescription,
  PlanDescription,
  ActivePlanHeader,
} from '../../styles';

import { Icon } from 'semantic-ui-react';

const ActivePlan = ({
  isDefaultPlan,
  primaryAccount,
  userAccountID,
  stripeCustomerID,
  activePlan,
  plans,
  prices,
  subscription,
  history,
  match,
  featureFlags,
  invoices,
  stripePlanCancelsAtEndOfCycle,
  stripePlanWillChangeAtEndOfCycle,
  stripePlanCancelDate,
  activeSubscriptionPlan,
}) => {
  const statusDescription = (status, linkToInvoice) => {
    switch (status) {
      case 'incomplete':
        return (
          <span>
            {
              'Payment failed when the subscription was created. A successful payment needs to be made within 23 hours to activate the subscription. Contact us for more information'
            }
            {linkToInvoice && (
              <Fragment>
                {` or `}
                <a rel="noreferrer" href={linkToInvoice} target="_blank">
                  Complete Invoice
                </a>
              </Fragment>
            )}
          </span>
        );

      case 'incomplete_expired':
        return `The initial payment on the subscription failed and no successful payment was made within 23 hours of creating the subscription. Click "Cancel Subscription" below to proceed or contact us for more information.`;

      default:
        return null;
    }
  };

  const subscriptionHasErrors = subscription
    ? subscription.status !== 'active'
    : false;
  let linkToBrokenInvoice = undefined;
  const invoiceWithError =
    invoices && invoices.length > 0
      ? invoices.find((i) => i.status === 'open')
      : undefined;
  if (subscriptionHasErrors && invoiceWithError) {
    linkToBrokenInvoice = invoiceWithError.hosted_invoice_url;
  }

  // if activePlan will change will change, we need to show current activePlan
  // according to what's in Numbrz database
  // until we automatically  make these changes
  let currentActivePlan = activeSubscriptionPlan;

  if (stripePlanWillChangeAtEndOfCycle) {
    const plan = plans.find((p) => p.id === primaryAccount.stripeProductID);
    if (plan) currentActivePlan = activePlan;
  }

  return (
    <Fragment>
      <ActivePlanHeader>
        <div>
          <span>
            {currentActivePlan && currentActivePlan.name
              ? currentActivePlan.name
              : activePlan.name}
          </span>
          <PlanStatusLabel
            status={subscription ? subscription.status : 'active'}
          >
            {subscription ? subscription.status : 'active'}
          </PlanStatusLabel>
        </div>

        {stripePlanCancelsAtEndOfCycle && (
          <PlanStatusLabel status="neutral">
            <Icon name="clock" />
            Cancels {moment.unix(stripePlanCancelDate).format('MMM DD')}
          </PlanStatusLabel>
        )}
        {stripePlanWillChangeAtEndOfCycle && !stripePlanCancelsAtEndOfCycle && (
          <PlanStatusLabel status="neutral">
            <Icon name="clock" />
            {`${activeSubscriptionPlan.name} - Active ${moment
              .unix(subscription.current_period_end)
              .format('MMM DD')}`}
          </PlanStatusLabel>
        )}
      </ActivePlanHeader>

      <PlanDescription>{activePlan.metadata.description}</PlanDescription>
      {subscription && subscription.status !== 'active' && (
        <StatusDescription>
          {statusDescription(subscription.status, linkToBrokenInvoice)}
        </StatusDescription>
      )}
    </Fragment>
  );
};

export default ActivePlan;
