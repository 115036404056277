import React from 'react';
import { getDisplayName, hoistStatics } from 'recompose';

import { useAuth } from '<src>/auth';

const withLogout = (BaseComponent) => {
  const name = getDisplayName(BaseComponent);

  function WithLogout(props) {
    const { logout } = useAuth();
    return <BaseComponent {...props} onLogout={logout} />;
  }

  WithLogout.BaseComponent = BaseComponent;
  WithLogout.displayName = `withLogout(${name})`;

  return WithLogout;
};

export default hoistStatics(withLogout);
