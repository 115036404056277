import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
  Fragment,
} from 'react';
import styled from '@emotion/styled';
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';
import { Icon } from 'semantic-ui-react';
import { NavLink, useLocation, useHistory } from 'react-router-dom';

import * as colors from '<components>/colors';

import {
  ErrorChiclet,
  FlexRowContainer,
  StyledScrollbar,
} from '<components>/NumbrzPageComponents';
import { NumbrzTooltip, GrayChiclet } from '<components>/NumbrzButtons';
import ActionMenu from '<components>/ActionMenu';
import { UserPreferencesContext } from '<components>/UserPreferences';
import Link from '<src>/components/Link';

const List = styled('div')`
  padding: 0 10px 100px 0;
  font-size: 16px;
  margin: 0px 0 10px 0px;
  direction: ltr;
`;

const ListContainer = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  ${StyledScrollbar};

  display: flex;
  flex-direction: column;

  margin: 5px 0 0 0;
  padding: 10px 5px 0 0;
  min-height: 300px;
`;

const Item = styled('div')`
  width: 100%;
  min-height: 48px;
  border-radius: 4px;
  padding: 7px 5px 7px 13px;

  margin-bottom: 15px;
  color: ${colors.gray1};
  border: 1px solid ${colors.gray_border};
  background-color: white;

  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  position: relative;

  & button {
    outline: none;
    border: none;
    background: transparent;

    i {
      color: ${colors.gray1};
      font-size: 0.8em;
      margin-right: 0;
    }
    span {
      font-size: 12px;
      letter-spacing: 0.5px;
    }
  }

  .active & {
    border: none;
    background-color: ${colors.blue7};
    button {
    }
  }

  i.icons {
    position: absolute;
    top: -8px;
    right: -8px;
    margin: 0 !important;
    z-index: 90;
    .icon {
      left: 49.5%;
    }
    i {
      margin: 0 !important;
      height: unset !important;
      width: 1em !important;
    }
    i.circle {
      color: ${colors.red1c};
      font-size: 1.2em;
    }
    i.exclamation {
      color: white;
      font-size: 0.6em;
    }
  }
`;

const Title = styled('div')`
  display: flex;

  align-items: center;
  justify-content: space-around;
  h4 {
    font-size: 12px;
    letter-spacing: 0.3px;
  }
  h4,
  h5 {
    margin: 0;
    padding: 0;
  }
  h5 {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: normal;
    color: ${colors.gray2};
    margin-top: 3px;
  }
`;

export const AddButtonContainer = styled('div')`
  display: flex;
  justify-content: center;
  position: sticky;
  z-index: 100;

  bottom: 0;
`;

export const NavItemChiclet = styled(GrayChiclet)`
  a {
    font-size: 12px;
    line-height: unset;
    i {
      font-size: 11px;
      margin-left: 2px;
    }
  }
`;

export const ItemTitle = styled('h4')`
  display: flex;
  flex-direction: column;
`;

export const SrcLinkBtn = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: normal;
  color: ${colors.gray1};
  :hover {
    color: ${colors.blue1};
    i {
      color: ${colors.blue1};
    }
  }
  i {
    font-size: 9px;
    margin-left: 5px;
    color: #4a4a4a;
    height: unset;
    margin: 0px 0 0 3px;
    padding-top: 1px;
  }
`;

const getIncludedPath = (item) => {
  const { ID, includedFrom, __typename: itemType, prefSource } = item;
  switch (itemType) {
    case 'FlowTableDef':
      return `/models/${includedFrom.modelID}/flows/${includedFrom.flowID}/${prefSource === 'WorkingTable' ? 'working-tables' : 'job-tables'}/${ID}/columns`;
    case 'FlowStageV2':
      return `/models/${includedFrom.modelID}/flows/${includedFrom.flowID}/stages/${ID}`;
    default:
      return null;
  }
};

export const IncludedSrcLink = ({ item, children }) => {
  const urlPath = getIncludedPath(item);
  if (urlPath)
    return urlPath ? (
      <Link to={urlPath} fontWeight="normal" size="small">
        <NumbrzTooltip
          content={`Source: ${item.includedFrom.flowName}`}
          trigger={
            <FlexRowContainer alignItems="center">
              {children}
              <Icon name="arrow right" />
            </FlexRowContainer>
          }
        />
      </Link>
    ) : (
      children
    );
};

const defaultRenderItem = (item, idx) => {
  return <ItemTitle>{item.name || item.flowName}</ItemTitle>;
};

export default function FlowItemList({
  baseURL,
  items: propItems = [],
  label,
  renderItem = defaultRenderItem,
  onCloneItem,
  onMoveItem,
  onRemoveItem: baseRemoveItem,
  onRemoveIncludedStage,
  setTestResults,
  setFlowSetting = () => {},
  flowSettings = {},
  activeItemLabel,
  flowID,
  api,
}) {
  const history = useHistory();
  const location = useLocation();
  const [items, setItems] = useState(propItems);

  const { cleanupFlowTest } = useContext(UserPreferencesContext);

  const activeItemPath = flowSettings[activeItemLabel]
    ? `${baseURL}/${flowSettings[activeItemLabel]}`
    : null;

  useEffect(() => {
    setItems(propItems);

    if (
      activeItemLabel &&
      flowSettings &&
      !flowSettings[activeItemLabel] &&
      propItems.length > 0
    ) {
      if (
        activeItemLabel &&
        activeItemPath &&
        location.pathname !== activeItemPath &&
        location.search !== '?new'
      )
        setFlowSetting(flowID, activeItemLabel, propItems[0].ID);
      history.push(activeItemPath);
    }
  }, [propItems, activeItemPath, activeItemLabel, flowSettings, flowID]);

  const onRemoveItem = useMemo(() => {
    if (!baseRemoveItem) {
      return null;
    }
    return async (e, item) => {
      const callback = item.isIncluded ? onRemoveIncludedStage : baseRemoveItem;

      await callback(e, item);
      cleanupFlowTest(flowID, item.ID, setTestResults);

      if (location.pathname === `${baseURL}/${item.ID}`) {
        const navTo = items.find((i) => i.ID !== item.ID);

        const newPath = !navTo ? baseURL : `${baseURL}/${navTo.ID}`;
        history.replace(newPath);
      }
    };
  }, [
    baseRemoveItem,
    baseURL,
    history,
    items,
    location.pathname,
    cleanupFlowTest,
    setTestResults,
    flowID,
    onRemoveIncludedStage,
  ]);

  const onDragEnd = useCallback(
    ({ destination, source }) => {
      if (!onMoveItem) {
        return;
      }

      if (!destination) {
        return;
      }

      if (destination.droppableId !== source.droppableId) {
        return;
      }

      if (destination.index === source.index) {
        return;
      }

      const { index: destIdx } = destination;
      const { index: srcIdx } = source;

      setItems((items) => {
        const newItems = [...items];
        newItems.splice(destIdx, 0, newItems.splice(srcIdx, 1)[0]);

        onMoveItem(null, { item: items[srcIdx], destIdx, srcIdx, newItems });

        return newItems;
      });
    },
    [onMoveItem]
  );

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={'flow-item-list'} isDropDisabled={!onMoveItem}>
          {(provided, snapshot) => (
            <ListContainer>
              <List ref={provided.innerRef} {...provided.droppableProps}>
                {items.map((item, idx) => (
                  <Draggable
                    key={item.ID}
                    draggableId={item.ID}
                    index={idx}
                    isDragDisabled={!onMoveItem}
                  >
                    {(provided, snapshot) => (
                      <NavLink
                        to={`${baseURL}/${item.ID}`}
                        title={onMoveItem && 'drag to reorder'}
                        onClick={(e) => {
                          setFlowSetting(flowID, activeItemLabel, item.ID);
                        }}
                      >
                        <Item
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          provided={provided}
                          snapshot={snapshot}
                        >
                          <NumbrzTooltip
                            content={`${label} contains issues`}
                            trigger={
                              <ErrorChiclet
                                visible={!item.valid ? 'true' : 'false'}
                              >
                                <Icon name="circle" />
                                <Icon
                                  name="exclamation"
                                  title="This item contains issues"
                                />
                              </ErrorChiclet>
                            }
                          />
                          <Title>{renderItem(item, idx)}</Title>

                          <FlexRowContainer>
                            {item.isIncluded && (
                              <NavItemChiclet margin="0 0 0 5px">
                                <IncludedSrcLink item={item}>
                                  Included
                                </IncludedSrcLink>
                              </NavItemChiclet>
                            )}

                            {onRemoveItem || onCloneItem ? (
                              <ActionMenu
                                onClick={(e) => e.preventDefault()}
                                options={[
                                  ...(onRemoveItem
                                    ? [
                                        ActionMenu.deleteOption({
                                          onSelect: (e) =>
                                            onRemoveItem(e, item),
                                        }),
                                      ]
                                    : []),
                                  ...(onCloneItem
                                    ? [
                                        ActionMenu.cloneOption({
                                          onSelect: (e) => onCloneItem(e, item),
                                        }),
                                      ]
                                    : []),
                                ]}
                              />
                            ) : null}
                          </FlexRowContainer>
                        </Item>
                      </NavLink>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            </ListContainer>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}
