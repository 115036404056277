import React, { useMemo } from 'react';

import { ConfigTable } from '../../styles';
import ElementLabel from '../../ElementLabel';
import FieldPicker from '../../FieldPicker';

export default function MappingList({
  elements,
  inputMappings,
  func,
  onChange,
}) {
  const mappedElementKeys = useMemo(
    () =>
      inputMappings.reduce(
        (acc, m) => ({ ...acc, [m.toFuncInput]: m.fromElementKey }),
        {}
      ),
    [inputMappings]
  );
  const onChangeMapping = useMemo(() => {
    if (!onChange) {
      return null;
    }
    return (e, { fromElementKey, toFuncInput }) => {
      if (!onChange) return;

      const newMappings = [...inputMappings];
      const mappingIdx = inputMappings.findIndex(
        (m) => m.toFuncInput === toFuncInput
      );
      if (!fromElementKey) {
        if (mappingIdx !== -1) {
          newMappings.splice(mappingIdx, 1);
        }
      } else if (mappingIdx !== -1) {
        newMappings[mappingIdx] = { fromElementKey, toFuncInput };
      } else {
        newMappings.push({ fromElementKey, toFuncInput });
      }
      onChange(e, { inputMappings: newMappings });
    };
  }, [inputMappings, onChange]);

  return (
    <ConfigTable>
      <thead>
        <tr>
          <th>Stage Field</th>
          <th>Function Input</th>
        </tr>
      </thead>
      <tbody>
        {func.inputs.map((input) => {
          const selectedKey = mappedElementKeys[input.ID];
          const selectedElt = elements.find((e) => e.key === selectedKey);
          return (
            <tr key={input.ID}>
              <td className={selectedKey && !selectedElt ? 'error' : undefined}>
                <ElementLabel.Source element={selectedElt} />
                {!!onChangeMapping ? (
                  <FieldPicker.Dropdown
                    showTypeFilter
                    initialTypeFilter={input.dataType}
                    elements={elements}
                    selected={selectedKey}
                    disabled={!onChange}
                    onChange={(e, { selected }) =>
                      onChangeMapping(e, {
                        fromElementKey: selected,
                        toFuncInput: input.ID,
                      })
                    }
                  />
                ) : (
                  <ElementLabel.Text
                    elementKey={selectedKey}
                    element={selectedElt}
                  />
                )}
              </td>
              <td>{input.label}</td>
            </tr>
          );
        })}
      </tbody>
    </ConfigTable>
  );
}
