import React from 'react';
import styled from '@emotion/styled';
import { Icon } from 'semantic-ui-react';
import * as colors from '<components>/colors';

const StyledButton = styled('button')`
  min-width: 15px;
  width: fit-content;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  outline: none;
  margin: 0;
  margin-left: 4px;
  color: ${colors.gray2};
  :hover {
    color: ${colors.gray1};
  }
  & i {
    margin: 0;
  }
`;

export default function ConfigButton({
  expanded,
  onClick,
  title,
  'data-id': dataID,
  'data-type': dataType,
}) {
  return (
    <StyledButton
      data-id={dataID}
      data-type={dataType}
      role="button"
      title={title}
      onClick={onClick}
    >
      <Icon name={expanded ? 'compress' : 'expand'} />
    </StyledButton>
  );
}
