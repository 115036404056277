import React, {
  useState,
  useMemo,
  Fragment,
  useEffect,
  useContext,
} from 'react';

import ReorderRoundedIcon from '@mui/icons-material/ReorderRounded';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import {
  ListContent,
  FlexColumnContainer,
  FlexRowContainer,
  EmptyListContainer,
} from '<components>/NumbrzPageComponents';
import {
  SearchBar,
  SearchBarWrapper,
} from '<sections>/catalog/components/styles';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Toolbar, ListTypeButton } from './styles';

import List from './List';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { UserPreferencesContext } from '<components>/UserPreferences';
import WaveSpinner from '<components>/WaveSpinner';

function applySearchAndFilters(items = [], searchPattern) {
  let newItems = [...items];

  if (searchPattern) {
    newItems = [...items].filter(({ name }) => searchPattern.test(name));
  }
  return newItems;
}

function getSearchBarLbl(entityType) {
  if (entityType === 'data' || entityType === 'data-table') return 'data';
  return entityType ? `${entityType.split('-').join(' ')}s` : '';
}

const sanitizeSearchTerm = (term) => {
  return term && term.trim ? term.trim() : '';
};

export default function NumbrzEntityList({
  loading,
  searchTerm: searchTermProp,
  searchPattern: searchPatternProp,
  entityType,
  items = [],
  onRunItem,
  onCloneItem,
  onDeleteItem,
  itemsRunning = [],
  setRunResult,
}) {
  const match = useRouteMatch();

  const { listDisplayPreferences = {}, changeListDisplayPreference } =
    useContext(UserPreferencesContext);

  const [searchTerm, setSearchTerm] = useState(searchTermProp);
  const [searchPattern, setSearchPattern] = useState(searchPatternProp);

  const [focused, setFocused] = useState(false);
  const [listType, setListType] = useState(
    listDisplayPreferences[entityType] || 'card'
  );
  const history = useHistory();

  useEffect(() => {
    if (searchTerm) {
      setSearchPattern(new RegExp(sanitizeSearchTerm(searchTerm), 'ig'));
    }
    if (searchTerm === '') {
      setSearchPattern(undefined);
    }
  }, [searchTerm]);

  const listItems = useMemo(
    () => applySearchAndFilters(items, searchPattern),
    [items, searchPattern]
  );

  return (
    <FlexColumnContainer
      justifyContent="center"
      alignItems="flex-start"
      width="100%"
    >
      {!loading && items.length === 0 ? (
        <EmptyListContainer>
          <h4>No items found</h4>
        </EmptyListContainer>
      ) : loading ? (
        <WaveSpinner />
      ) : (
        <Fragment>
          <SearchBarWrapper className={focused && 'active'}>
            <FlexRowContainer
              alignItems="center"
              justifyContent="flex-start"
              width="100%"
            >
              <SearchRoundedIcon />
              <SearchBar
                type="search"
                placeholder={`Search ${getSearchBarLbl(entityType)}`}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
              />
            </FlexRowContainer>
          </SearchBarWrapper>
          <Toolbar>
            <ListTypeButton
              onClick={() => {
                setListType('row');
                changeListDisplayPreference(entityType, 'row');
              }}
              icon={<ReorderRoundedIcon />}
              className={listType === 'row' && 'active'}
            />
            <ListTypeButton
              onClick={() => {
                setListType('card');
                changeListDisplayPreference(entityType, 'card');
              }}
              icon={<GridViewRoundedIcon />}
              className={listType === 'card' && 'active'}
            />
          </Toolbar>
          <ListContent>
            <List
              displayType={listType}
              items={listItems}
              baseURL={match.url}
              searchTerm={searchTerm}
              searchPattern={searchPattern}
              onRunItem={onRunItem}
              setRunResult={setRunResult}
              onCloneItem={onCloneItem}
              onDeleteItem={onDeleteItem}
              entityType={entityType}
              history={history}
              itemsRunning={itemsRunning}
            />
          </ListContent>
        </Fragment>
      )}
    </FlexColumnContainer>
  );
}
