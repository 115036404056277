import gql from 'graphql-tag';

const InvoiceFragment = gql`
  fragment InvoiceFragment on StripeInvoice {
    total
    status
    due_date
    currency
    created
    description
    period_start
    period_end
    amount_due
    amount_paid
    amount_remaining
    hosted_invoice_url
  }
`;

export const CreateStripeCustomer = gql`
  mutation CreateStripeCustomer($ID: CommonID, $email: String) {
    createStripeCustomer(ID: $ID, email: $email) {
      name
      email
      id
    }
  }
`;

export const ChangeStripeCustomerNumbrzAccount = gql`
  mutation ChangeStripeCustomerNumbrzAccount($customerID: String!) {
    changeStripeCustomerNumbrzAccount(customerID: $customerID) {
      success
    }
  }
`;

export const GetCustomerCardPaymentMethods = gql`
  query GetCustomerCardPaymentMethods {
    customerCardPaymentMethods {
      id
      billingDetails {
        address {
          addressLine1
          addressLine2
          zipCode
          state
          city
          country
        }
        name
      }
      card {
        brand
        expMonth
        expYear
        last4
      }
    }
  }
`;

export const GetCustomerInformation = gql`
  query GetCustomerInformation {
    customerInformation {
      name
      email
      id
      defaultPayment
      deleted
      invoiceSettings {
        defaultPaymentMethod
      }
      upcomingInvoice {
        ...InvoiceFragment
      }
      invoices {
        ...InvoiceFragment
      }
      subscriptions {
        id
        status
        cancel_at_period_end
        current_period_end
        metadata {
          templateID
          projectID
        }
        items {
          id
          created
          plan {
            id
            nickname
            amount
            interval
            product
            currency
          }
          price {
            id
            active
            product
            recurring
            metadata
            currency
            unit_amount
            unit_amount_decimal
          }
        }
      }
    }
  }
  ${InvoiceFragment}
`;

export const CreateCustomerSubscription = gql`
  mutation CreateCustomerSubscription($input: CustomerSubscriptionInput!) {
    createCustomerSubscription(input: $input) {
      subscriptionId
      clientSecret
    }
  }
`;

export const UpdateCustomerSubscription = gql`
  mutation UpdateCustomerSubscription(
    $subscriptionID: String!
    $priceID: String!
    $paymentMethodID: String
    $updateMethod: String!
  ) {
    updateCustomerSubscription(
      subscriptionID: $subscriptionID
      priceID: $priceID
      paymentMethodID: $paymentMethodID
      updateMethod: $updateMethod
    ) {
      name
      email
      id
      defaultPayment
      invoiceSettings {
        defaultPaymentMethod
      }
      subscriptions {
        id
        metadata {
          templateID
          projectID
        }
        items {
          id
          created
          plan {
            id
            nickname
            amount
            interval
            product
            currency
          }
        }
      }
    }
  }
`;

export const CreateOrgAndAddMember = gql`
  mutation CreateOrgAndAddMember($input: CreateOrgAndAddMemberInput!) {
    createOrgAndAddMember(input: $input)
  }
`;

export const CancelCustomerSubscription = gql`
  mutation CancelCustomerSubscription(
    $subscriptionID: String!
    $userAccountID: CommonID!
    $stripeCustomerID: String
  ) {
    cancelCustomerSubscription(
      subscriptionID: $subscriptionID
      userAccountID: $userAccountID
      stripeCustomerID: $stripeCustomerID
    ) {
      success
    }
  }
`;

export const GetProducts = gql`
  query GetProducts {
    products {
      id
      name
      description
      metadata
    }
  }
`;

export const GetProductsAndPrices = gql`
  query GetProductsAndPrices {
    products {
      id
      name
      description
      metadata
    }
    productPrices {
      id
      active
      product
      recurring
      metadata
      currency
      unit_amount
      unit_amount_decimal
    }
  }
`;

export const GetPlans = gql`
  query GetPlans {
    plans {
      id
      nickname
      product
      currency
      amount
      interval
    }
  }
`;

export const AddPaymentMethod = gql`
  mutation AddPaymentMethod($input: PaymentMethodInput!) {
    addPaymentMethod(input: $input) {
      id
      billingDetails {
        address {
          addressLine1
          addressLine2
          zipCode
          state
          city
          country
        }
        name
      }
      card {
        brand
        expMonth
        expYear
        last4
      }
      error
    }
  }
`;

export const DeletePaymentMethod = gql`
  mutation DeletePaymentMethod($input: UpdatePaymentMethodInput!) {
    deletePaymentMethod(input: $input) {
      paymentID
    }
  }
`;

export const UpdatePaymentMethod = gql`
  mutation UpdatePaymentMethod($input: PaymentMethodInput!) {
    updatePaymentMethod(input: $input) {
      id
      billingDetails {
        address {
          addressLine1
          addressLine2
          zipCode
          state
          city
          country
        }
        name
      }
      card {
        brand
        expMonth
        expYear
        last4
      }
      error
    }
  }
`;

export const SetDefaultPaymentMethod = gql`
  mutation SetDefaultPaymentMethod($input: UpdatePaymentMethodInput!) {
    setDefaultPaymentMethod(input: $input) {
      name
      email
      id
      defaultPayment
      invoiceSettings {
        defaultPaymentMethod
      }
    }
  }
`;

export const SetPrimaryAccount = gql`
  mutation SetPrimaryAccount($input: UpdateMemberOfInput!) {
    setPrimaryAccount(input: $input) {
      success
    }
  }
`;

export const GET_ORG = gql`
  query GetOrg($ID: CommonID!) {
    account(ID: $ID) {
      ID
      ... on Org {
        name
        display
        members {
          ID
          account {
            ID
            name
            display
          }
          roles
        }
      }
    }
  }
`;

export const GET_ORG_INVITATIONS = gql`
  query GetOrgInvitations($ID: CommonID!) {
    orgInvitations(ID: $ID) {
      ID
      memberOf
      email
      name
      display
    }
  }
`;

export const INVITE_MEMBER = gql`
  mutation InviteMember($input: InviteMemberInput!) {
    inviteMember(input: $input) {
      ID
      memberOf
      email
      name
      display
    }
  }
`;

export const REMOVE_MEMBER = gql`
  mutation RemoveMember($input: UpdateMemberOfInput) {
    removeMemberOf(input: $input) {
      memberID
    }
  }
`;

export const UpdateAccountDisplayName = gql`
  mutation UpdateAccountDisplayName($input: UpdateAccountDisplayNameInput!) {
    updateAccountDisplayName(input: $input) {
      error {
        message
      }
      newName
    }
  }
`;

export const UpdateStripeCustomerPersonalInfo = gql`
  mutation updateStripeCustomerPersonalInfo(
    $customerID: String!
    $newName: String
    $newEmail: String
  ) {
    updateStripeCustomerPersonalInfo(
      customerID: $customerID
      newName: $newName
      newEmail: $newEmail
    ) {
      name
      email
    }
  }
`;

export const CredentialFragment = gql`
  fragment CredentialFragment on Credential {
    ID
    name
    comment
    tags
    providerName
    providerParams {
      realmID
      companyName
    }
    credName
    credParams {
      username
      password
      data
      tokenType
      accessToken
      expiry
    }
    status {
      needsAuth
      source
      comment
    }
    userName
  }
`;

export const GetCredentials = gql`
  query ListCredentials(
    $ownerID: CommonID
    $provider: AuthProvider
    $type: AuthType
    $tags: [String!]
  ) {
    listCredentials(
      ownerID: $ownerID
      provider: $provider
      type: $type
      tags: $tags
    ) {
      ID
      name
      comment
      tags
      providerName
      providerParams {
        realmID
        companyName
      }
      status {
        needsAuth
        source
        comment
      }
      userName
      type
    }
  }
`;

export const GetCredential = gql`
  query GetCredential($ID: CommonID!) {
    credential(ID: $ID) {
      ...CredentialFragment
    }
  }
  ${CredentialFragment}
`;

export const CreateCredential = gql`
  mutation CreateCredential($input: CreateCredentialInput!) {
    createCredential(input: $input) {
      ...CredentialFragment
    }
  }
  ${CredentialFragment}
`;

export const ReauthorizeCredential = gql`
  mutation ReauthorizeCredential($input: ReauthorizeCredentialInput!) {
    reauthorizeCredential(input: $input) {
      ...CredentialFragment
    }
  }
  ${CredentialFragment}
`;

export const RemoveCredential = gql`
  mutation RemoveCredential($ID: CommonID!) {
    removeCredential(ID: $ID) {
      ID
      error {
        message
      }
    }
  }
`;

export const UpdateCredential = gql`
  mutation updateCredential($input: UpdateCredentialInput!) {
    updateCredential(input: $input) {
      ID
      name
      comment
      tags
      providerName
      providerParams {
        realmID
        companyName
      }
      status {
        needsAuth
        source
        comment
      }
      userName
    }
  }
`;
