import React, { PureComponent } from 'react';
import T from 'prop-types';
import styled from '@emotion/styled';

/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx, css, keyframes } from '@emotion/react';

import escapeRegExp from 'lodash/escapeRegExp';

import * as colors from '<components>/colors';
import { TextInputStyles } from '<components>/TextInput';
import { Search } from '<components>/Icons';
import Button from '<src>/components/Button';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }`;

const Container = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  opacity: 0;
  animation: ${fadeIn} 140ms ease-in-out forwards;
`;

const StyledSearch = styled(Search)`
  position: absolute;
  left: 8px;
  top: 3px;
  svg {
    height: 22px;
    width: 22px;
    display: ${({ visible }) => (visible ? 'inherit' : 'none')};
    fill: ${colors.gray4b};
  }

  color: ${(props) => (props.iconcolor ? props.iconcolor : colors.gray4b)};

  opacity: 0.5;
  transition: all 220ms ease-in;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? 'initial' : 'none')};
  ${(props) => props.iconcolor && { svg: { fill: `${props.iconcolor}` } }};
  ${(props) => props.darkmode && { svg: { fill: 'white' } }};
`;

const StyledClose = (props) => (
  <Button.Small
    {...props}
    css={css`
      cursor: pointer;
      position: absolute;
      left: 6px;
      top: 50%;
      color: '#F7F8FB';
      border: none;
      transform: translateY(-50%) scale(1);
      transition: all 220ms ease-in;

      :hover {
        color: '#F7F8FB';

        ${props.darkmode && {
          background: 'transparent',
          border: 'none',
          svg: { fill: 'white' },
        }};
      }
      svg {
        height: 14px;
        width: 14px;
        position: relative;
        top: 0px;
      }
      ${props.darkmode && {
        background: 'transparent',
        border: 'none',
        svg: { fill: 'white' },
      }};
    `}
  />
);

const StyledInput = styled('input')`
  ${TextInputStyles}
  padding-left: 36px;
  height: 30px;

  &:focus + ${StyledSearch} {
    opacity: 0.8;
  }

  ${(props) =>
    props.darkmode === 'true' && {
      background: 'transparent',
      border: `1px solid ${colors.gray4b}`,
      color: 'white',
      ':focus': {
        background: colors.gray2b,
        color: 'white',
      },
      paddingTop: '3px !important',
      paddingBottom: '3px !important',
      lineHeight: 'unset !important',
    }};
`;

export default class SearchBar extends PureComponent {
  static propTypes = {
    onChange: T.func.isRequired,
    value: T.string,
  };

  static defaultProps = {
    value: '',
  };

  textInput = React.createRef();

  componentDidMount() {
    if (this.textInput && this.props.value) this.textInput.current.focus();
  }

  handleChange = (e) => {
    const term = e.target.value;
    const trimmedTerm = term && term.trim ? term.trim() : '';
    let pattern;
    try {
      pattern = new RegExp(escapeRegExp(trimmedTerm), 'ig');
    } catch (_) {
      pattern = null;
    }
    this.props.onChange(e.target.value, pattern);
  };

  handleClickClear = (e) => {
    this.props.onChange('', null);
    this.textInput.current.focus();
  };

  render() {
    return (
      <Container>
        <StyledInput
          {...this.props}
          ref={this.textInput}
          type="search"
          onChange={this.handleChange}
          darkmode={this.props.darkMode ? 'true' : 'false'}
        />
        {this.props.value ? (
          <StyledClose
            icon={<CloseRoundedIcon />}
            label="clear"
            darkmode={this.props.darkMode ? 'true' : 'false'}
            onClick={this.handleClickClear}
          />
        ) : null}

        <StyledSearch
          visible={!this.props.value ? 1 : 0}
          iconcolor={this.props.searchiconcolor}
          darkmode={this.props.darkMode ? 1 : 0}
        />
      </Container>
    );
  }
}
