import styled from '@emotion/styled';
import * as colors from '<components>/colors';
import TextInput from '<components>/TextInput';

export const DropdownWrapper = styled('div')`
  padding: 7px 10px;
  margin-bottom: 0;
  margin-top: 1em;
  .text {
    display: flex;
    align-items: end;
  }
  border-radius: 4px;
  border: 1px solid ${colors.gray_border};
`;

export const StyledTextInput = styled(TextInput)`
  width: 100%;
  padding: 7px 10px;
  border: 1px solid ${colors.gray_border};
`;
