import React, { useCallback } from 'react';
import fp from 'lodash/fp';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';

import { useMutation } from '<src>/apollo/client';

import AddNameDialog from '<components>/AddNameDialog';

const CreateFunction = gql`
  mutation CreateFunction($func: FunctionV3Input!) {
    createFuncV3(func: $func) {
      ID
      name
      description
    }
  }
`;

export default function AddFunctionDialog({
  projectID,
  visible,
  onDialogClose,
}) {
  const history = useHistory();
  const [createFunction] = useMutation(CreateFunction);

  const doCreate = useCallback(
    async (name) => {
      const res = await createFunction({
        variables: { func: { name: name.trim(), projectID } },
      });

      const func = fp.getOr(undefined, 'data.createFuncV3', res);
      if (func) {
        if (func) history.push(`/models/${projectID}/functions/${func.ID}`);
      }
    },
    [createFunction, history, projectID]
  );

  return (
    <AddNameDialog
      objType="Function"
      visible={visible}
      onClose={onDialogClose}
      onCreate={doCreate}
    />
  );
}
