import React, { Fragment } from 'react';
import { Icon } from 'semantic-ui-react';

const accountName = (account) => (
  <Fragment>
    <Icon name={account.__typename === 'User' ? 'user' : 'users'} />
    {account.display}
  </Fragment>
);

// eslint-disable-next-line import/prefer-default-export
export { accountName };
