import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import useMeasure from '../useMeasure';

function sizeAttr(size) {
  if (size !== undefined) {
    return `${size}px`;
  }
  return undefined;
}

function setSize(style, { dim, expanded, max, min, phase }) {
  if (phase === 'expand') {
    // set the size to the expanded size as part of an animation
    style.setProperty(dim, sizeAttr(max));
  } else if (phase === 'collapse') {
    // set the size to the collapsed size as part of an animation
    style.setProperty(dim, sizeAttr(min));
  } else if (!expanded) {
    // we're not in an animation, and we're collapsed; use the collapsed size
    style.setProperty(dim, sizeAttr(min));
  } else {
    // we're not in an animation, and we're expanded; let the component take its
    // natural size
    style.removeProperty(dim);
  }
}

function setOpacity(style, { expanded, phase }) {
  let value = expanded ? '1' : '0';
  if (phase === 'expand') {
    value = '1';
  } else if (phase === 'collapse') {
    value = '0';
  }
  style.setProperty('opacity', value);
}

function setOverflow(style, { phase }) {
  if (phase) {
    style.setProperty('overflow', 'hidden');
  } else {
    style.removeProperty('overflow');
  }
}

function setVisibility(style, { expanded, phase }) {
  style.setProperty('visibility', expanded || phase ? 'visible' : 'hidden');
}

function setTransistion(style, { enable, speed }) {
  if (enable) {
    style.setProperty(
      'transition',
      `opacity ${speed}s ease-in-out, height ${speed}s ease-in-out, width ${speed}s ease-in-out`
    );
  } else {
    style.removeProperty('transition');
  }
}

function updateSizes(ref, heightRef, widthRef) {
  if (heightRef && heightRef.current) {
    ref.current.height = heightRef.current.getBoundingClientRect().height;
  }
  if (widthRef && widthRef.current) {
    ref.current.width = widthRef.current.getBoundingClientRect().width;
  }
}

function setAutoHeight(node) {
  node.style.setProperty('height', 'auto', 'important');
  node.style.setProperty('width', 'auto', 'important');
  return node;
}

export default function AnimatedResize({
  children,
  className,
  direction = 'both',
  expanded,
  speed = 0.25,
  heightRef,
  widthRef,
}) {
  const doHeight = direction === 'height' || direction === 'both';
  const doWidth = direction === 'width' || direction === 'both';

  const [{ height, width }, ref, refreshSize] = useMeasure(setAutoHeight);
  const [previous, setPrevious] = useState(expanded);
  const [phase, setPhase] = useState(null); // 'expand' | 'collapse' | null

  const sizeRef = useRef({ height, width });
  const minSizeRef = useRef({ height: 0, width: 0 });

  useEffect(() => {
    sizeRef.current = { height, width };
    updateSizes(minSizeRef, heightRef, widthRef);
  }, [height, width, heightRef, widthRef]);

  const updateStyle = useCallback(
    (expanded, phase) => {
      const { height, width } = sizeRef.current;
      const style = ref.current.style;

      if (doHeight) {
        setSize(style, {
          dim: 'height',
          expanded,
          phase,
          max: height,
          min: minSizeRef.current.height,
        });
      }
      if (doWidth) {
        setSize(style, {
          dim: 'width',
          expanded,
          phase,
          max: width,
          min: minSizeRef.current.width,
        });
      }

      setOpacity(style, { expanded, phase });
      setOverflow(style, { phase });
      setVisibility(style, { expanded, phase });
    },
    [ref, doHeight, doWidth]
  );

  useEffect(() => {
    updateStyle(previous, phase);
  }, [previous, phase, updateStyle]);

  useLayoutEffect(() => {
    if (expanded === previous) return;
    if (!expanded) {
      // We're collapsing; update our current expanded size so we know what to
      // transistion to next time
      refreshSize();
    } else {
      // We're expanding; update our minimum sizes so we know what to transistion to next time
      updateSizes(minSizeRef, heightRef, widthRef);
    }

    const start = expanded ? 'collapse' : 'expand';
    setPhase(start);
    setPrevious(expanded);
  }, [expanded, previous, refreshSize, heightRef, widthRef]);

  useLayoutEffect(() => {
    if (!phase) return;

    let timeoutID;
    const finish = expanded ? 'expand' : 'collapse';
    if (finish !== phase) {
      timeoutID = setTimeout(() => {
        setTransistion(ref.current.style, { enable: true, speed });
        setTimeout(() => setPhase(finish), 0);
      }, 25);
    } else {
      timeoutID = setTimeout(() => {
        setTransistion(ref.current.style, { enable: false, speed });
        setPhase(null);
      }, 1000 * speed);
    }
    return () => clearTimeout(timeoutID);
  }, [expanded, phase, ref, speed]);

  if (typeof children === 'function') {
    children = children({ expanded });
  }

  return (
    <div ref={ref} className={className}>
      {children}
    </div>
  );
}
