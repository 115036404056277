import React, { Fragment, useState, useMemo } from 'react';
import fp from 'lodash/fp';
import { useLocation } from 'react-router-dom';

import { HashScroll } from 'react-hash-scroll';

import UsageChiclets from '../components/UsageChiclets';
import { getTableUsage } from '../utils';
import { FlexRowContainer } from '<components>/NumbrzPageComponents';
import { RoundedContentWrapper } from '<src>/components/NumbrzVerticalEditor';

import AddLinkOutlinedIcon from '@mui/icons-material/AddLinkOutlined';
import Link from '<src>/components/Link';
import FilterMenu from './FilterDialog';
import SortMenu from './SortDialog';
import SearchBar from '<components>/SearchBar';
import Row from './Row';

import {
  TextIconBtn,
  HdrToolbar,
  DataHeader,
  HeaderLbl,
  SearchBarContainer,
} from '../styles';
import { StatusContainer } from '../JobStatus/styles';
import WaveSpinner from '<src>/components/WaveSpinner';

function createRowData({ mapping, jobTable }) {
  return {
    mapping,
    dataSrc: mapping || null,
    dataTable: mapping ? mapping.dataset : null,
    jobTable: jobTable,
  };
}

function applyFiltersSortAndSearch(rows = [], filters, sort, search = {}) {
  let modifiedRows = [...rows];

  // apply sorting
  if (sort.inputsFirst) {
    const inputs = rows.filter((row) => {
      const usageTypes = getTableUsage(row.jobTable);
      const isInput = usageTypes.inputs > 0;
      return isInput;
    });
    const outputs = rows.filter((row) => {
      const usageTypes = getTableUsage(row.jobTable);
      const isOutput = usageTypes.outputs > 0;
      return isOutput;
    });
    const unlabeled = rows.filter((row) => {
      const usageTypes = getTableUsage(row.jobTable);
      const isNeither = usageTypes.outputs === 0 && usageTypes.inputs === 0;
      return isNeither;
    });
    modifiedRows = [...inputs, ...outputs, ...unlabeled];
  }
  // apply visibility
  if (filters.visibility !== 'all')
    modifiedRows = rows.filter(
      (row) =>
        (row.jobTable &&
          row.jobTable.isInput &&
          filters.visibility === 'inputs') ||
        (row.jobTable &&
          row.jobTable.isOutput &&
          filters.visibility === 'outputs')
    );
  // apply tableset filtering
  if (filters.tableset)
    modifiedRows = modifiedRows.filter(
      (row) =>
        fp.getOr(undefined, 'dataSrc.dataset.tableset.ID', row) ===
        filters.tableset
    );
  // apply jobTable filtering
  if (filters.jobTable)
    modifiedRows = modifiedRows.filter(
      (row) => fp.getOr(undefined, 'jobTable.ID', row) === filters.jobTable
    );
  if (search && search.searchPattern) {
    modifiedRows = modifiedRows.filter((row) =>
      fp.getOr('', 'dataTable.name', row).match(search.searchPattern)
    );
  }
  return modifiedRows;
}

function compareFunx(a, b) {
  if (a.jobTable && b.jobTable) {
    if (a.jobTable.name < b.jobTable.name) return -1;
    if (a.jobTable.name > b.jobTable.name) return 1;
  }

  return 0;
}

export default function DatamapTable({
  jobTables,
  unmappedTables,
  datamap,
  onSelectData,
  flowID,
  flowLink,
  flowName,
  projectID,
  onMapField,
  onMapAnyTable,
  onUnlinkTable,
  loadingTables,
  externalResultURL,
  isBundle,
}) {
  const location = useLocation();

  const [sortDialogVisible, setSortDialogVisible] = useState(false);
  const [filterDialogVisible, setFilterDialogVisible] = useState(false);
  const [sort, setSort] = useState({
    inputsFirst: true,
  });
  const [filters, setFilters] = useState({
    visibility: 'all',
    tableset: null,
    jobTable: null,
  });

  const [search, setSearch] = useState(null);

  const rows = useMemo(() => {
    let newRows = [];
    datamap.forEach((dM) => {
      const jobTable = jobTables.find((t) => t.ID === dM.tableID);
      newRows.push(
        createRowData({
          mapping: dM,
          jobTable,
        })
      );
    });
    //sort rows alphabetically based on data source
    newRows.sort(compareFunx);

    unmappedTables.forEach((t) => {
      newRows.push(createRowData({ jobTable: t, mapping: null }));
    });

    return newRows;
  }, [datamap, unmappedTables, jobTables]);

  const visibleRows = useMemo(() => {
    return applyFiltersSortAndSearch(rows, filters, sort, search);
  }, [rows, filters, sort, search]);

  // data opts
  const dataOpts = useMemo(() => {
    const flag = {};
    const unique = [];
    let sources = rows.map((r, idx) => ({
      key: idx,
      text: fp.getOr(null, 'dataSrc.dataset.tableset.name', r),
      value: fp.getOr(null, 'dataSrc.dataset.tableset.ID', r),
    }));
    sources.forEach((src) => {
      if (!flag[src.value] && src.value) {
        flag[src.value] = true;
        unique.push(src);
      }
    });
    return unique;
  }, [rows]);

  // job table opts
  const jobTableOpts = useMemo(() => {
    const flag = {};
    const unique = [];
    let sources = rows.map((r, idx) => ({
      key: idx,
      text: () => (
        <Fragment>
          {fp.getOr(null, 'jobTable.name', r)}
          <UsageChiclets
            usageTypes={getTableUsage(r.jobTable)}
            showInputs={true}
            showOutputs={true}
            mappingRequired={r.jobTable.required}
            mapping={r.mapping}
          />
        </Fragment>
      ),
      value: fp.getOr(null, 'jobTable.ID', r),
    }));
    sources.forEach((src) => {
      if (!flag[src.value] && src.value) {
        flag[src.value] = true;
        unique.push(src);
      }
    });
    return unique;
  }, [rows]);

  if (loadingTables) {
    return <WaveSpinner />;
  }

  return (
    <RoundedContentWrapper minHeight="300px">
      <StatusContainer>
        {flowLink || externalResultURL ? (
          <FlexRowContainer
            alignItems="center"
            justifyContent="space-between"
            style={{ marginBottom: '20px', padding: '0 5px' }}
          >
            {flowLink ? (
              <FlexRowContainer
                alignItems="baseline"
                justifyContent="flex-start"
              >
                <span style={{ fontSize: '11px', fontWeight: 600 }}>FLOW:</span>
                <Link internal to={flowLink}>
                  {flowName}
                </Link>
              </FlexRowContainer>
            ) : null}

            {externalResultURL && (
              <Link.ExternalResults href={externalResultURL} />
            )}
          </FlexRowContainer>
        ) : null}
        <DataHeader>
          <FlexRowContainer alignItems="flex-start" justifyContent="flex-start">
            <HeaderLbl>Data</HeaderLbl>
            <SearchBarContainer>
              <SearchBar
                value={search && search.searchTerm}
                onChange={(searchTerm, searchPattern) =>
                  setSearch({ searchTerm, searchPattern })
                }
              />
            </SearchBarContainer>
          </FlexRowContainer>

          <HdrToolbar>
            <SortMenu
              visible={sortDialogVisible}
              setVisible={setSortDialogVisible}
              sort={sort}
              setSort={setSort}
            />
            <FilterMenu
              visible={filterDialogVisible}
              setVisible={setFilterDialogVisible}
              filters={filters}
              setFilters={setFilters}
              dataOpts={dataOpts}
              jobTableOpts={jobTableOpts}
            />
            <TextIconBtn
              iconSize="22px"
              padding="2px 8px 2px 6px"
              onClick={() => onMapAnyTable()}
            >
              <AddLinkOutlinedIcon />
              Map data
            </TextIconBtn>
          </HdrToolbar>
        </DataHeader>
        {visibleRows.map((row, idx) => (
          <HashScroll
            key={idx}
            hash={fp.getOr('', 'mapping.tableID', row)}
            position="center"
          >
            <Row
              key={idx}
              row={row}
              flowID={flowID}
              projectID={projectID}
              onMapField={onMapField}
              onMapTable={onSelectData}
              onUnlinkTable={onUnlinkTable}
              last={idx === rows.length - 1}
              expanded={
                location.hash &&
                location.hash.replace('#', '') ===
                  fp.getOr(undefined, 'mapping.tableID', row)
              }
              isBundle={isBundle}
            />
          </HashScroll>
        ))}
      </StatusContainer>
    </RoundedContentWrapper>
  );
}
