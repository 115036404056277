import { ApolloLink } from '@apollo/client';

function omitDeep(obj, keyToOmit) {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (key !== keyToOmit) {
      const val = obj[key];
      if (Array.isArray(val)) newObj[key] = omitDeepArrayWalk(val, keyToOmit);
      else if (typeof val === 'object' && val !== null)
        newObj[key] = omitDeep(val, keyToOmit);
      else newObj[key] = val;
    }
  });
  return newObj;
}

function omitDeepArrayWalk(arr, keyToOmit) {
  return arr.map((val) => {
    if (Array.isArray(val)) return omitDeepArrayWalk(val, keyToOmit);
    else if (typeof val === 'object') return omitDeep(val, keyToOmit);
    return val;
  });
}

export default new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, '__typename');
  }
  return forward(operation);
});
