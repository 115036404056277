import { useQuery, useMutation } from '<src>/apollo/client';
import {
  GetFlow,
  UpdateFlow,
  AddFlowTable,
  RemoveFlowTable,
  AddStage,
  RemoveStage,
  RemoveIncludedStage,
  AddTestCase,
  RemoveTestCase,
  UpdateTestCase,
  CloneTestCase,
  TestFlow,
  AddOrUpdateFlowUserVar,
  RemoveFlowUserVar,
  UpdateFlowIncludeRefs,
  IncludeStage,
  IncludeAllStages,
  RemoveIncludedStages,
} from './queries';

export default function useFlowState(flowID) {
  const { loading, error, data } = useQuery(GetFlow, {
    variables: { flowID },
    fetchPolicy: 'network-only',
  });

  const [updateFlow] = useMutation(UpdateFlow);
  const [addFlowTable] = useMutation(AddFlowTable);
  const [removeFlowTable] = useMutation(RemoveFlowTable);
  const [addStage] = useMutation(AddStage);
  const [includeStage] = useMutation(IncludeStage);
  const [includeAllStages] = useMutation(IncludeAllStages);

  const [removeStage] = useMutation(RemoveStage);
  const [removeIncludedStage] = useMutation(RemoveIncludedStage);
  const [removeIncludedStages] = useMutation(RemoveIncludedStages);

  const [addTest] = useMutation(AddTestCase);
  const [removeTest] = useMutation(RemoveTestCase);
  const [updateTest] = useMutation(UpdateTestCase);
  const [cloneTest] = useMutation(CloneTestCase);
  const [testFlowCase] = useMutation(TestFlow);
  const [addOrUpdateFlowUserVar] = useMutation(AddOrUpdateFlowUserVar);
  const [removeFlowUserVar] = useMutation(RemoveFlowUserVar);
  const [updateFlowIncludeRefs] = useMutation(UpdateFlowIncludeRefs);

  if (error) {
    throw error;
  }
  if (loading) {
    return { loading };
  }

  const { flow, issues, environment } = data.flow;
  const state = { flow, issues, environment, error, loading };
  state.api = {
    onChange: (e, { comment, name }) =>
      updateFlow({
        variables: { input: { ID: flow.ID, comment, name } },
      }),
    onAddStage: async (name) => {
      const {
        data: { addStageV2 },
      } = await addStage({
        variables: { flowID: flow.ID, input: { name } },
      });

      if (addStageV2) {
        const stage =
          addStageV2.flow.stagesV2[addStageV2.flow.stagesV2.length - 1];
        return stage;
      }
      return null;
    },
    onIncludeStage: async (e, includeStageID) => {
      const {
        data: { includeStageV2 },
      } = await includeStage({
        variables: { flowID: flow.ID, includeStageID },
      });

      if (includeStageV2) {
        const stage =
          includeStageV2.flow.stagesV2[includeStageV2.flow.stagesV2.length - 1];
        return stage;
      }
      return null;
    },
    onIncludeAllStages: async (e) =>
      includeAllStages({
        variables: { flowID: flow.ID },
      }),

    onRemoveStage: (e, { ID }) =>
      removeStage({ variables: { flowID: flow.ID, ID } }),
    onRemoveIncludedStage: (e, { ID }) =>
      removeIncludedStage({ variables: { flowID: flow.ID, ID } }),
    onRemoveAllIncludedStages: (e) =>
      removeIncludedStages({ variables: { flowID: flow.ID } }),

    onMoveStage: (e, { newItems }) =>
      updateFlow({
        variables: {
          input: {
            ID: flow.ID,
            stages: newItems.map((s) => ({ stageID: s.ID })),
          },
        },
      }),
    onAddFlowIncludeRefs: (e, newRefs = []) => {
      updateFlowIncludeRefs({
        variables: {
          flowID: flow.ID,
          includeFlowRefs: [...newRefs],
        },
      });
    },
    onRemoveFlowIncludeRef: (e, { flowID }) => {
      const newRefs =
        flow.includes && flow.includes.length > 0 ? [...flow.includes] : [];
      const refIdx = newRefs.findIndex((r) => r.flowID === flowID);

      if (refIdx !== -1) {
        updateFlowIncludeRefs({
          variables: {
            flowID: flow.ID,
            includeFlowRefs: newRefs
              .filter((r) => r.flowID !== flowID)
              .map((r) => r.flowID),
          },
        });
      }
    },

    onAddUpdateUserVar: (e, userVar) =>
      addOrUpdateFlowUserVar({
        variables: {
          flowID,
          userVar: {
            defaultVal: userVar.defaultVal,
            toElement: userVar.toElement,
          },
        },
      }),
    onRemoveUserVar: (e, userVarKey) =>
      removeFlowUserVar({
        variables: { flowID, userVarKey },
      }),
    onAddTable: async (e, table) => {
      const {
        data: { addFlowTableDef },
        error,
      } = await addFlowTable({
        variables: { flowID, table },
      });
      if (error) {
        throw error;
      }
      const saved =
        addFlowTableDef.flow.data[addFlowTableDef.flow.data.length - 1];
      return saved;
    },
    onRemoveTable: (e, table) =>
      removeFlowTable({ variables: { flowID, tableID: table.ID } }),
    onAddTestCase: async (name) => {
      const {
        data: { addTestCase },
      } = await addTest({
        variables: {
          input: { flowID: flow.ID, name, inputs: [] },
        },
      });

      if (addTestCase) return addTestCase.test;
      return null;
    },
    onRemoveTestCase: (e, { ID }) =>
      removeTest({
        variables: { flowID: flow.ID, testCaseKey: ID },
      }),
    onUpdateTestCase: async (e, input) => {
      const {
        data: { updateTestCase },
      } = await updateTest({
        variables: {
          input: { flowID: flow.ID, ...input },
        },
      });
      if (updateTestCase) return updateTestCase;
      return null;
    },
    onCloneTestCase: async (e, { key }) => {
      const {
        data: { cloneTestCase },
      } = await cloneTest({
        variables: {
          flowID: flow.ID,
          testCaseKey: key,
        },
      });

      if (cloneTestCase) return cloneTestCase.test;
      return null;
    },
    onTestFlow: async (e, { testCaseKey }) => {
      const {
        data: { testFlow },
      } = await testFlowCase({
        variables: { flowID: flow.ID, testCaseKey },
      });
      if (testFlow) return testFlow;
      return null;
    },
  };

  return state;
}
