import { useCallback, useMemo } from 'react';
import {
  useQuery,
  useLazyQuery,
  useMutation,
  usePagedQuery,
} from '<src>/apollo/client';
import { RecentRuns } from '<src>/sections/projects/queries';
import { GetPickerTables } from '<components>/DataPicker/connectors';
import {
  UpdateJobDataMapping,
  GetJob,
  UpdateJob,
  RunJob,
  GetJobStatus,
  CancelJob,
} from '../queries';

function tableFormatFunc(data = { datatables: [] }) {
  const { dataTables, project } = data;

  let tables = [];
  if (dataTables) {
    tables = tables.concat(
      dataTables.tables.filter((t) => t.type !== 'Detached')
    );
  }
  if (project) {
    tables = tables.concat(project.workingTables);
  }

  return tables;
}

export default function useJobState({ jobID, containerID }) {
  const [
    fetchRunHistory,
    {
      data: { recentRuns } = {},
      error: runHistoryError,
      loading: loadingRunHistory,
      startPolling: startRunHistoryPolling,
      stopPolling: stopRunHistoryPolling,
      called,
    },
  ] = useLazyQuery(RecentRuns);

  const [
    fetchJobStatus,
    {
      data: { jobStatus } = {},
      loading: loadingStatus,
      startPolling: startStatusPolling,
      stopPolling: stopStatusPolling,
      called: calledJobStatus,
    },
  ] = useLazyQuery(GetJobStatus);

  // const { data: { project: projectData } = {}, loading: loadingProjectData } =
  //   useQuery(GetProjectJobs, {
  //     variables: { ID: containerID },
  //   });

  const loadRunHistory = useCallback(() => {
    fetchRunHistory({
      variables: { input: { containerID, jobID } },
    });
  }, [fetchRunHistory, jobID, containerID]);

  const loadJobStatus = useCallback(
    (runID) => {
      fetchJobStatus({
        variables: { runID },
      });
    },
    [fetchJobStatus]
  );

  const { data: tableData, loading: loadingTables } = usePagedQuery(
    GetPickerTables,
    undefined,
    (data) => data.dataTables.cursor
  );

  const { loading: loadingJob, data: jobData } = useQuery(GetJob, {
    variables: {
      ID: jobID,
      projectID: containerID,
    },
  });
  const project = loadingJob ? {} : jobData.project;
  // const jobs = loadingProjectData ? [] : projectData.jobs;

  const [updateJobDataMapping] = useMutation(UpdateJobDataMapping, {
    update(cache, { data: { updateJobDataMapping } }) {
      cache.writeQuery({
        query: GetJob,
        variables: {
          jobID: jobID,
          projectID: containerID,
        },
        data: {
          job: updateJobDataMapping,
          project,
        },
      });
    },
  });

  const [runJob] = useMutation(RunJob);
  const [cancelJob] = useMutation(CancelJob);
  const [updateJob] = useMutation(UpdateJob);

  const tables = useMemo(
    () => (!loadingTables ? tableFormatFunc(tableData) : []),
    [tableData, loadingTables]
  );

  const job = useMemo(() => {
    if (loadingJob) {
      return {};
    }
    const job = jobData.job;
    if (loadingTables) {
      return job;
    }

    const datamap = job.datamap.map((dm) => {
      if (!dm.datasetID) return dm;

      const dataset = tables.find((t) => t.ID === dm.datasetID);
      return {
        ...dm,
        dataset,
      };
    });

    return {
      ...job,
      datamap,
    };
  }, [loadingJob, loadingTables, jobData, tables]);

  const state = {
    runHistory: recentRuns,
    loadingRunHistory,
    runHistoryError,
    loadRunHistory,
    calledRunHistory: called,
    startRunHistoryPolling,
    stopRunHistoryPolling,
    job,
    project,
    loadingJob,
    tables,
    loadingTables,
    runJob,
    cancelJob,
    updateJob,
    updateJobDataMapping,
    loadJobStatus,
    calledJobStatus,
    jobStatus,
    loadingStatus,
    startStatusPolling,
    stopStatusPolling,
  };

  return state;
}
