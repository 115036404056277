import React from 'react';
import fp from 'lodash/fp';
import { BillingWrapper, TopContainer } from '../styles';

import RecentInvoices from '../components/RecentInvoices';

export default function Invoices({ customerInformation }) {
  const recentInvoices = fp.getOr([], 'invoices', customerInformation);

  return (
    <BillingWrapper>
      <TopContainer>
        {recentInvoices && recentInvoices.length > 0 ? (
          <RecentInvoices invoices={recentInvoices} />
        ) : (
          'No invoices found.'
        )}
      </TopContainer>
    </BillingWrapper>
  );
}
