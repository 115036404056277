import React from 'react';
import styled from '@emotion/styled';
import { Icon } from 'semantic-ui-react';

import * as colors from '<components>/colors';

import TagInput from './TagInput';

export const List = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
`;
export const TagItem = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  background: ${colors.green2};
  border-radius: 45px;
  padding: 5px 10px;
  margin: 10px;
  i {
    margin: 1px 0 0 5px;
    height: unset;
  }
  i:hover {
    cursor: pointer;
  }
`;

export default function TagList({
  tags = [],
  addTag,
  removeTag,
  readOnly = false,
}) {
  return (
    <div>
      {!readOnly && <TagInput addTag={addTag} />}
      <List>
        {tags.map((tag, idx) => (
          <TagItem key={idx}>
            <span>{tag}</span>
            {!readOnly && <Icon name="close" onClick={() => removeTag(tag)} />}
          </TagItem>
        ))}
      </List>
    </div>
  );
}
