const dateMacros = [
  {
    key: 'Custom',
    text: 'Custom',
    value: 'custom',
  },
  {
    key: 'Today',
    text: 'Today',
    value: 'Today',
  },
  {
    key: 'Yesterday',
    text: 'Yesterday',
    value: 'Yesterday',
  },
  {
    key: 'ThisWeek',
    text: 'This Week',
    value: 'ThisWeek',
  },
  {
    key: 'LastWeek',
    text: 'Last Week',
    value: 'LastWeek',
  },
  {
    key: 'ThisWeektodate',
    text: 'This Week To Date',
    value: 'ThisWeektodate',
  },
  {
    key: 'LastWeektodate',
    text: 'Last Week To Date',
    value: 'LastWeektodate',
  },
  {
    key: 'NextWeek',
    text: 'Next Week',
    value: 'NextWeek',
  },
  {
    key: 'Next4Weeks',
    text: 'Next 4 Weeks',
    value: 'Next4Weeks',
  },
  {
    key: 'ThisMonth',
    text: 'This Month',
    value: 'ThisMonth',
  },
  {
    key: 'LastMonth',
    text: 'Last Month',
    value: 'LastMonth',
  },
  {
    key: 'ThisMonthtodate',
    text: 'This Month To Date',
    value: 'ThisMonthtodate',
  },
  {
    key: 'LastMonthtodate',
    text: 'Last Month To Date',
    value: 'LastMonthtodate',
  },
  {
    key: 'NextMonth',
    text: 'Next Month',
    value: 'NextMonth',
  },
  {
    key: 'ThisFiscalQuarter',
    text: 'This Fiscal Quarter',
    value: 'ThisFiscalQuarter',
  },
  {
    key: 'LastFiscalQuarter',
    text: 'Last Fiscal Quarter',
    value: 'LastFiscalQuarter',
  },
  {
    key: 'ThisFiscalQuartertodate',
    text: 'This Fiscal Quarter To Date',
    value: 'ThisFiscalQuartertodate',
  },
  {
    key: 'LastFiscalQuartertodate',
    text: 'Last Fiscal Quarter To Date',
    value: 'LastFiscalQuartertodate',
  },
  {
    key: 'NextFiscalQuarter',
    text: 'Next Fiscal Quarter',
    value: 'NextFiscalQuarter',
  },
  {
    key: 'ThisFiscalYear',
    text: 'This Fiscal Year',
    value: 'ThisFiscalYear',
  },
  {
    key: 'LastFiscalYear',
    text: 'Last Fiscal Year',
    value: 'LastFiscalYear',
  },
  {
    key: 'ThisFiscalYeartodate',
    text: 'This Fiscal Year To Date',
    value: 'ThisFiscalYeartodate',
  },
  {
    key: 'LastFiscalYeartodate',
    text: 'Last Fiscal Year To Date',
    value: 'LastFiscalYeartodate',
  },
  {
    key: 'NextFiscalYear',
    text: 'Next Fiscal Year',
    value: 'NextFiscalYear',
  },
  {
    key: 'ThisCalendarQuarter',
    text: 'This Calendar Quarter',
    value: 'ThisCalendarQuarter',
  },
  {
    key: 'LastCalendarQuarter',
    text: 'Last Calendar Quarter',
    value: 'LastCalendarQuarter',
  },
  {
    key: 'ThisCalendarQuartertodate',
    text: 'This Calendar Quarter To Date',
    value: 'ThisCalendarQuartertodate',
  },
  {
    key: 'LastCalendarQuartertodate',
    text: 'Last Calendar Quarter To Date',
    value: 'LastCalendarQuartertodate',
  },
  {
    key: 'NextCalendarQuarter',
    text: 'Next Calendar Quarter',
    value: 'NextCalendarQuarter',
  },
  {
    key: 'ThisCalendarYear',
    text: 'This Calendar Year',
    value: 'ThisCalendarYear',
  },
  {
    key: 'LastCalendarYear',
    text: 'Last Calendar Year',
    value: 'LastCalendarYear',
  },
  {
    key: 'ThisCalendarYeartodate',
    text: 'This Calendar Year To Date',
    value: 'ThisCalendarYeartodate',
  },
  {
    key: 'LastCalendarYeartodate',
    text: 'Last Calendar Year To Date',
    value: 'LastCalendarYeartodate',
  },
  {
    key: 'NextCalendarYear',
    text: 'Next Calendar Year',
    value: 'NextCalendarYear',
  },
];

export default dateMacros;
