import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { Input } from 'semantic-ui-react';

import { isEmpty } from '<src>/utils/text';

const StyledInput = styled(Input)`
  width: 100%;
  & div.label {
    width: 25%;
  }
  margin-bottom: 6px;
  &:last-child {
    margin-bottom: 0;
  }
`;

function isInputValid(connectorParams) {
  const { project, database, schema, warehouse } = connectorParams;

  return (
    !isEmpty(project) &&
    !isEmpty(database) &&
    !isEmpty(schema) &&
    !isEmpty(warehouse)
  );
}

export default function SnowflakeSpreadsheetPane({
  children: renderBody,
  onInputChange,
  connectorParams,
  inputDisabled = false,
}) {
  const body = (
    <Fragment>
      <StyledInput
        size="small"
        label="Project"
        name="project"
        placeholder="Enter Project (required)"
        value={connectorParams.project}
        onChange={onInputChange}
        disabled={inputDisabled}
      />
      <StyledInput
        size="small"
        label="Database"
        name="database"
        placeholder="Enter Database (required)"
        value={connectorParams.database}
        onChange={onInputChange}
        disabled={inputDisabled}
      />
      <StyledInput
        size="small"
        label="Schema"
        name="schema"
        placeholder="Enter Schema (required)"
        value={connectorParams.schema}
        onChange={onInputChange}
        disabled={inputDisabled}
      />
      <StyledInput
        size="small"
        label="Warehouse"
        name="warehouse"
        placeholder="Enter Warehouse (required)"
        value={connectorParams.warehouse}
        onChange={onInputChange}
        disabled={inputDisabled}
      />
      <StyledInput
        size="small"
        label="Region"
        name="region"
        placeholder="Enter AWS Region"
        value={onInputChange.region}
        onChange={onInputChange}
        disabled={inputDisabled}
      />
    </Fragment>
  );

  return renderBody(body, !isInputValid(connectorParams));
}
