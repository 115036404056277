import React, { Fragment, useState } from 'react';
import { Form, List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import fp from 'lodash/fp';
import styled from '@emotion/styled';

import { useQuery, useMutation } from '<src>/apollo/client';

import * as colors from '<components>/colors';

import Button from '<src>/components/Button';
import Card from '<components>/Card';
import DeleteButtonWithConfirm from '<components>/DeleteButtonWithConfirm';

import StripeProductDropdown from '../../components/StripeProductDropdown';
import { accountName } from '../util';

import AddMemberDialog from './AddMemberDialog';
import ChangePrimaryAccountDialog from './ChangePrimaryAccountDialog';
import DeleteAccountDialog from './DeleteAccountDialog';
import AccountModels from './AccountModels';

import {
  SetAccountToStripeProduct,
  ClearStripeCustomer,
  GetAccount,
  GetAccounts,
  AddMemberOf,
  RemoveMemberOf,
  AddAdmin,
  RemoveAdmin,
  SetPrimaryAccount,
  DeleteAccount,
  SetFeature,
} from '<sections>/numbrz-admin/queries';

const ignoreSsrWarning =
  '/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */';

const StyledHeader = styled('h2')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1em 1em 0.5em 1em;
`;

const StyledList = styled(List)`
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 12px;
  padding: 0 4px !important;
`;

const StyledListContent = styled(List.Content)`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 35px;
  border-bottom: 1px dotted ${colors.gray3};
  padding-bottom: 5px;
  a {
    padding-top: 5px;
  }
  & > :first-child${ignoreSsrWarning} {
    flex 1;
  }
`;

const StyledItemTools = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledPrimaryAccount = styled('div')`
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const UserContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled('div')`
  width: 60%;
  max-width: 800px;
  margin: 0 auto;
`;

const StyledCard = styled(Card)`
  width: 100%;
  height: fit-content;
  padding: 1em 2em;
  margin-bottom: 1em;
  margin-top: 0.5em;
  h4 {
    font-size: 0.9em;
    margin-bottom: 0px;
    padding-bottom: 5px;
  }
  border-radius: 3px;
  box-shadow:
    rgb(60 66 87 / 12%) 0px 2px 4px 0px,
    rgb(0 0 0 / 12%) 0px 2px 3px 0px;
`;

const PrimaryAccount = ({ of, onChangePrimary }) => {
  const name = accountName(of.primaryAccount);

  return (
    <StyledCard>
      <h4>Primary Account</h4>
      <StyledPrimaryAccount>
        {of.ID !== of.primaryAccount.ID ? (
          <Link to={`/numbrz-admin/accounts/${of.primaryAccount.ID}`}>
            {name}
          </Link>
        ) : (
          <span>{name}</span>
        )}
        <Button.Small onClick={onChangePrimary}>Change</Button.Small>
      </StyledPrimaryAccount>
    </StyledCard>
  );
};

function FeatureSettings({ accountID, features, setFeature }) {
  return (
    <StyledCard>
      <h4>Features</h4>
      <StyledList>
        {features.map(({ name, accountSetting, defaultSetting }) => {
          let setting = defaultSetting;
          if (accountSetting !== null) {
            setting = accountSetting;
          }
          return (
            <List.Item key={name}>
              <StyledListContent>
                <div>{name}</div>
                <div>
                  <Form.Checkbox
                    checked={setting}
                    label="Enabled"
                    onChange={() =>
                      setFeature({
                        feature: name,
                        setting: !setting,
                      })
                    }
                  />
                </div>
              </StyledListContent>
            </List.Item>
          );
        })}
      </StyledList>
    </StyledCard>
  );
}

const MemberList = ({
  parent,
  members,
  onAddMember,
  onRemoveMember,
  onSetAdmin,
}) => {
  return (
    <Fragment>
      <StyledList>
        {members
          .filter(({ account }) => account.ID !== parent.ID)
          .map(({ account, roles }) => {
            const isAdmin = roles.includes('Administrator');
            return (
              <List.Item key={account.ID}>
                <StyledListContent>
                  <Link to={`/numbrz-admin/accounts/${account.ID}`}>
                    {accountName(account)}
                  </Link>
                  <StyledItemTools>
                    <Form.Checkbox
                      label="Admin"
                      checked={isAdmin}
                      onChange={() =>
                        onSetAdmin({ selected: account.ID, isAdmin: !isAdmin })
                      }
                    />
                    {account.ID !== parent.ID &&
                      parent.primaryAccount &&
                      account.ID !== parent.primaryAccount.ID && (
                        <DeleteButtonWithConfirm
                          onClick={() =>
                            onRemoveMember({ selected: account.ID })
                          }
                        />
                      )}
                  </StyledItemTools>
                </StyledListContent>
              </List.Item>
            );
          })}
      </StyledList>
      <Button.Small onClick={onAddMember}>Add</Button.Small>
    </Fragment>
  );
};

const User = ({
  user,
  onAddMember,
  onRemoveMember,
  onSetAdmin,
  onChangePrimary,
  onLinkProduct,
  onSetFeature,
  stripeProductID,
  clearStripeCustomer,
}) => {
  const [deletingStripe, setDeletingStripe] = useState(false);
  return (
    <UserContainer>
      <StyledCard>
        <h4>Auth0 ID</h4>
        <p>{user.externalID}</p>
      </StyledCard>
      <FeatureSettings features={user.features} setFeature={onSetFeature} />

      <PrimaryAccount of={user} onChangePrimary={onChangePrimary} />

      <StyledCard>
        <h4>Member Of</h4>
        <MemberList
          parent={user}
          members={user.membership}
          onAddMember={onAddMember}
          onRemoveMember={onRemoveMember}
          onSetAdmin={onSetAdmin}
        />
      </StyledCard>

      <StyledCard>
        <h4>Stripe Plan</h4>
        <StripeProductDropdown
          value={stripeProductID}
          onChange={(productID) => onLinkProduct(user.ID, productID)}
        />
        <h4>Stripe Information</h4>
        {user.stripeCustomerID && !deletingStripe && (
          <Button
            onClick={async () => {
              setDeletingStripe(true);
              const res = await clearStripeCustomer({
                variables: { accountID: user.ID },
              });
              if (
                !fp.getOr(undefined, 'data.clearStripeCustomer.success', res)
              ) {
                setDeletingStripe(false);
              }
            }}
          >
            {deletingStripe ? 'Deleting...' : 'Delete Stripe Customer'}
          </Button>
        )}
      </StyledCard>

      <StyledCard>
        <AccountModels accountID={user.ID} />
      </StyledCard>
    </UserContainer>
  );
};

const Org = ({
  org,
  onAddMember,
  onRemoveMember,
  onSetAdmin,
  onLinkProduct,
  onSetFeature,
  stripeProductID,
  clearStripeCustomer,
}) => {
  const [deletingStripe, setDeletingStripe] = useState(false);
  return (
    <div>
      <StyledCard>
        <h4>Name</h4>
        <p>{org.name}</p>
      </StyledCard>
      <StyledCard>
        <h4>Display Name</h4>
        <p>{org.display}</p>
      </StyledCard>
      <FeatureSettings features={org.features} setFeature={onSetFeature} />
      <StyledCard>
        <h4>Members</h4>
        <MemberList
          parent={org}
          members={org.members}
          onAddMember={onAddMember}
          onRemoveMember={onRemoveMember}
          onSetAdmin={onSetAdmin}
        />
      </StyledCard>
      <StyledCard>
        <h4>Stripe Plan</h4>
        <StripeProductDropdown
          value={stripeProductID}
          onChange={(productID) => onLinkProduct(org.ID, productID)}
        />
        {org.stripeCustomerID && !deletingStripe && (
          <Button
            onClick={async () => {
              setDeletingStripe(true);
              const res = await clearStripeCustomer({
                variables: { accountID: org.ID },
              });
              if (
                !fp.getOr(undefined, 'data.clearStripeCustomer.success', res)
              ) {
                setDeletingStripe(false);
              }
            }}
          >
            {deletingStripe ? 'Deleting...' : 'Delete Stripe Customer'}
          </Button>
        )}
      </StyledCard>
      <StyledCard>
        <AccountModels accountID={org.ID} />
      </StyledCard>
    </div>
  );
};

export default function AccountAdmin({ match, history }) {
  const {
    data: { account } = {},
    loading,
    refetch,
  } = useQuery(GetAccount, {
    variables: { ID: match.params.accountID },
  });
  const { data: { accounts: accountsData } = {}, loading: loadingAccounts } =
    useQuery(GetAccounts);
  const accounts = loadingAccounts ? [] : accountsData.accounts;
  const [clearStripeCustomer] = useMutation(ClearStripeCustomer);
  const [addMemberOf] = useMutation(AddMemberOf);
  const [removeMemberOf] = useMutation(RemoveMemberOf);
  const [addAdmin] = useMutation(AddAdmin);
  const [removeAdmin] = useMutation(RemoveAdmin);
  const [setPrimaryAccount] = useMutation(SetPrimaryAccount);
  const [deleteAccount] = useMutation(DeleteAccount);
  const [setAccountToStripeProduct] = useMutation(SetAccountToStripeProduct);
  const [setFeature] = useMutation(SetFeature);

  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const [adding, setAdding] = useState(false);
  const [changePrimaryVisible, setChangePrimaryVisible] = useState(false);
  const [changingPrimary, setChangingPrimary] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [deletingAccount, setDeletingAccount] = useState(false);

  const showAddMember = () => setAddDialogVisible(true);
  const hideAddMember = () => setAddDialogVisible(false);
  const showChangePrimary = () => setChangePrimaryVisible(true);
  const hideChangePrimary = () => setChangePrimaryVisible(false);
  const showDeleteAccount = () => setDeleteDialogVisible(true);
  const hideDeleteAccount = () => setDeleteDialogVisible(false);

  const onAddMember = async ({ selected }) => {
    const input =
      account.__typename === 'User'
        ? { memberID: account.ID, memberOf: selected }
        : { memberID: selected, memberOf: account.ID };

    setAdding(true);
    await addMemberOf({ variables: { input } });
    setAdding(false);
    setAddDialogVisible(false);
    refetch();
  };

  const onSetAdmin = async ({ selected, isAdmin }) => {
    const input =
      account.__typename === 'User'
        ? { memberID: account.ID, memberOf: selected }
        : { memberID: selected, memberOf: account.ID };

    if (isAdmin) {
      await addAdmin({ variables: { input } });
    } else {
      await removeAdmin({ variables: { input } });
    }
    refetch();
  };

  const onRemoveMember = async ({ selected }) => {
    const input =
      account.__typename === 'User'
        ? { memberID: account.ID, memberOf: selected }
        : { memberID: selected, memberOf: account.ID };

    await removeMemberOf({
      variables: { input },
    });
    refetch();
  };

  const onChangePrimary = async ({ selected }) => {
    const input = { memberID: account.ID, memberOf: selected };

    setChangingPrimary(true);
    await setPrimaryAccount({
      variables: { input },
    });
    setChangingPrimary(false);
    setChangePrimaryVisible(false);
    refetch();
  };

  const onSetFeature = ({ feature, setting }) => {
    setFeature({
      variables: { accountID: account.ID, feature, setting },
    });
  };

  const onDeleteAccount = async () => {
    setDeletingAccount(true);
    await deleteAccount({ variables: { ID: account.ID } });
    hideDeleteAccount();
    history.push('/numbrz-admin/accounts');
  };

  const onLinkProduct = async (id, stripeProductID) => {
    await setAccountToStripeProduct({
      variables: { input: { id, stripeProductID } },
    });
  };

  return loading || loadingAccounts || !account ? null : (
    <ContentWrapper>
      <div>
        <StyledHeader>
          <div>{accountName(account)}</div>
          <Button
            contentColor="white"
            baseColor={colors.red3}
            activeColor={colors.red4}
            onClick={showDeleteAccount}
            fontSize="12px"
          >
            Delete
          </Button>
        </StyledHeader>
        {account.__typename === 'User' ? (
          <User
            user={account}
            onAddMember={showAddMember}
            onRemoveMember={onRemoveMember}
            onSetAdmin={onSetAdmin}
            onChangePrimary={showChangePrimary}
            onLinkProduct={onLinkProduct}
            onSetFeature={onSetFeature}
            clearStripeCustomer={clearStripeCustomer}
            stripeProductID={account.stripeProductID}
          />
        ) : (
          <Org
            org={account}
            onAddMember={showAddMember}
            onRemoveMember={onRemoveMember}
            onSetAdmin={onSetAdmin}
            onLinkProduct={onLinkProduct}
            onSetFeature={onSetFeature}
            stripeProductID={account.stripeProductID}
            clearStripeCustomer={clearStripeCustomer}
          />
        )}
      </div>
      <AddMemberDialog
        onClose={hideAddMember}
        onSubmit={onAddMember}
        adding={adding}
        visible={addDialogVisible}
        target={account}
        accounts={accounts}
      />
      {account.__typename === 'User' && (
        <ChangePrimaryAccountDialog
          onClose={hideChangePrimary}
          onSubmit={onChangePrimary}
          adding={changingPrimary}
          visible={changePrimaryVisible}
          user={account}
        />
      )}
      <DeleteAccountDialog
        onClose={hideDeleteAccount}
        visible={deleteDialogVisible}
        onSubmit={onDeleteAccount}
        deleting={deletingAccount}
      />
    </ContentWrapper>
  );
}
