import React, { useState, Fragment } from 'react';
import fp from 'lodash/fp';
import { Icon, Message } from 'semantic-ui-react';
import { TitleField } from '<src>/sections/flows/styles';
import TagInput from '<components>/TagList/TagInput';
import { List } from '<components>/TagList';
import DeleteButtonWithConfirm from '<src>/components/DeleteButtonWithConfirm';
import { GrayChiclet } from '<src>/components/NumbrzButtons';
import Button from '<components>/Button';
import { UserContainer, Tag } from '../Billing/styles';

import {
  FlexColumnWrapper,
  FlexRowContainer,
} from '<src>/components/NumbrzPageComponents';

export function getAuthTypeLbl(type) {
  switch (type) {
    case 'Oauth2':
      return 'OAuth';
    case 'ServiceAcct':
      return 'Service Account';
    case 'Password':
      return 'Username/Password';
    default:
      return 'Any Type';
  }
}

export default function User({
  cred,
  onUpdate,
  onRemove,
  setActiveCred,
  setDialogVisible,
  readOnly = false,
  showCredPicker,
  onShowCredPicker,
}) {
  const [saving, setSaving] = useState(false);

  const handleInputUpdate = async (field, value) => {
    setSaving(true);
    const payload = {
      ID: cred.ID,
      [field]: value,
    };

    await onUpdate({ variables: { input: payload } });
    setSaving(false);
  };
  const handleAddTag = async (tagLabel) => {
    setSaving(true);
    const newTags = [...cred.tags, tagLabel];
    const payload = {
      ID: cred.ID,
      tags: newTags,
    };

    await onUpdate({ variables: { input: payload } });
    setSaving(false);
  };
  const handleRemoveTag = async (tag) => {
    setSaving(true);
    const newTags = cred.tags.filter((c) => c !== tag);
    const payload = {
      ID: cred.ID,
      tags: newTags,
    };

    await onUpdate({ variables: { input: payload } });
    setSaving(false);
  };
  const userInfo = cred.userName
    ? cred.userName
    : fp.getOr(undefined, 'providerParams.companyName', cred)
      ? cred.providerParams.companyName
      : undefined;
  return (
    <UserContainer>
      <FlexColumnWrapper margin="0 0 0 10px" width="100%">
        <FlexRowContainer alignItems="center" justifyContent="space-between">
          <FlexRowContainer alignItems="center">
            {userInfo ? (
              <Fragment>
                <Icon name="user" />
                {userInfo}
              </Fragment>
            ) : (
              <TitleField
                placeholder="Add label"
                name="name"
                singleClick
                onSubmit={(e) => handleInputUpdate('name', e.value)}
                disabled={saving || readOnly}
                value={cred.name}
              />
            )}
            <GrayChiclet marginLeft="10px">
              {getAuthTypeLbl(cred.type)}
            </GrayChiclet>
            {!readOnly && (
              <Button.Run
                size="small"
                onClick={() => {
                  setActiveCred(cred);
                  setDialogVisible(true);
                }}
              >
                Reauthorize
              </Button.Run>
            )}
          </FlexRowContainer>

          {showCredPicker && onShowCredPicker && (
            <Button
              size="small"
              action="create"
              onClick={onShowCredPicker}
              margin={'0 5px 0 0'}
            >
              Change user
            </Button>
          )}

          {!readOnly && (
            <DeleteButtonWithConfirm onClick={() => onRemove(cred.ID)}>
              Delete
            </DeleteButtonWithConfirm>
          )}
        </FlexRowContainer>
        {fp.getOr(false, 'status.needsAuth', cred) && !readOnly && (
          <Message error>
            This data no longer has access to its external source. Please
            reauthorize this user to fix this error.
          </Message>
        )}

        {readOnly ? (
          cred.comment
        ) : (
          <TitleField
            placeholder="Add description"
            name="comment"
            singleClick
            onSubmit={(e) => handleInputUpdate('comment', e.value)}
            disabled={saving}
            value={cred.comment}
          />
        )}
        {cred.tags.length > 0 && (
          <List>
            {cred.tags.map((tag, idx) => (
              <Tag key={idx}>
                <span>{tag}</span>
                <Icon name="close" onClick={() => handleRemoveTag(tag)} />
              </Tag>
            ))}
          </List>
        )}
        {!readOnly && (
          <TagInput
            addTag={handleAddTag}
            additionalStyles={{
              padding: '5px 5px 5px 10px',
              marginTop: '10px',
              width: 'fit-content',
              button: { height: 'unset', minWidth: 'unset' },
            }}
          />
        )}
      </FlexColumnWrapper>
    </UserContainer>
  );
}
