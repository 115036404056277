import React, { useRef, useCallback, useMemo, Fragment } from 'react';
import fp from 'lodash/fp';

import ValidationIssues from '../../ValidationIssues';
import TestDataInput from '../TestDataInput';
import FlowMasthead from '../../FlowMasthead';
import { ColumnWrapper, PageContent } from '<components>/NumbrzPageComponents';
import Description from '<src>/components/Description';
import UserVariablesEditor from '<sections>/flows/UserVariablesEditor';
import { ConfigurationWrapper } from '../../styles';

export default function TestEditor({
  baseURL,
  flowState,
  testCaseKey,
  enterTestMode,
  testMode,
  testFeature,
  flowID,
}) {
  const { api, flow } = flowState;
  const { onUpdateTestCase } = api;
  const test = fp.getOr([], 'tests', flow).find((tC) => tC.key === testCaseKey);

  const flowData = useMemo(
    () =>
      fp
        .getOr([], 'data', flow)
        .filter((t) => t.requiresData && t.prefSource !== 'WorkingTable'),
    [flow]
  );

  const nameRef = useRef();

  const handleUpdateInput = useCallback(
    async (inputPayload) => {
      const newInputs = [...test.input];
      const inputIdx = newInputs.findIndex(
        (tI) => tI.tableDefID === inputPayload.tableDefID
      );
      if (inputIdx < 0) {
        newInputs.push(inputPayload);
      } else {
        newInputs[inputIdx] = inputPayload;
      }

      await onUpdateTestCase(null, {
        testCaseKey: test.key,
        addOrReplaceInputs: newInputs,
      });
    },
    [test, onUpdateTestCase]
  );

  const handleUpdateVars = useCallback(
    async (e, userVar) => {
      const newVars = [...test.variables];

      const varIdx = newVars.findIndex((v) => v.key === userVar.key);

      if (varIdx < 0) {
        newVars.push({ key: userVar.toElement.key, val: userVar.defaultVal });
      } else {
        newVars[varIdx] = {
          key: userVar.toElement.key,
          val: userVar.defaultVal,
        };
      }

      await onUpdateTestCase(null, {
        testCaseKey: test.key,
        addOrReplaceVariables: newVars,
      });
    },
    [test, onUpdateTestCase]
  );

  const handleUpdateTest = useCallback(
    async (attr, newValue) => {
      const payload = {
        testCaseKey: test.key,
        [attr]: newValue,
      };
      await onUpdateTestCase(null, payload);
    },
    [onUpdateTestCase, test]
  );

  return (
    <Fragment>
      <FlowMasthead
        enterTestMode={enterTestMode}
        testMode={testMode}
        testFeature={testFeature}
        title={test ? test.name : undefined}
        onTitleChange={(e) => handleUpdateTest('name', e.value)}
        ref={nameRef}
        flowID={flowID}
      />
      <ColumnWrapper maxWidth="unset">
        <PageContent width="100%">
          <ConfigurationWrapper maxWidth={testMode ? '750px' : '1000px'}>
            {test ? (
              <Fragment>
                <Description
                  content={test.comment}
                  editable={!!onUpdateTestCase}
                  onChange={(comment) => handleUpdateTest('comment', comment)}
                  placeholder="Add description"
                  noContainer
                />

                <ValidationIssues
                  issues={test.warnings}
                  header="This test contains errors"
                  margin="0 0 20px 0"
                  wide
                />

                <UserVariablesEditor
                  flowID={flowID}
                  userVariables={flow.userEnv}
                  jobVariables={test.variables || []}
                  onAddUpdateUserVar={handleUpdateVars}
                  onRemoveUserVar={() => {}}
                  noContainer
                  headerTitle="JOB VARIABLES"
                  mode="job"
                  expandable
                />

                {flowData.map((table, idx) => (
                  <TestDataInput
                    key={idx}
                    baseURL={baseURL}
                    flowID={flow.ID}
                    table={table}
                    updateInput={handleUpdateInput}
                    testInput={
                      test.input
                        ? test.input.find((tI) => tI.tableDefID === table.ID)
                        : undefined
                    }
                  />
                ))}
              </Fragment>
            ) : null}
          </ConfigurationWrapper>
        </PageContent>
      </ColumnWrapper>
    </Fragment>
  );
}
