import { gql } from '@apollo/client';

import { FunctionChangesFragment } from './fragments';
import useEditFunction, { optimisticFunctionChanges } from './useEditFunction';

const UpdateDefaultFormat = gql`
  mutation UpdateDefaultFormat(
    $funcID: CommonID!
    $templateID: ID!
    $defaultFormat: CellFormatInput!
  ) {
    updateDefaultFormatV3(
      funcID: $funcID
      templateID: $templateID
      defaultFormat: $defaultFormat
    ) {
      ...FunctionChangesFragment
    }
  }
  ${FunctionChangesFragment}
`;

export default function useUpdateDefaultFormat() {
  const updateDefaultFormat = useEditFunction(
    UpdateDefaultFormat,
    'updateDefaultFormatV3'
  );

  return (template, defaultFormat) => {
    return updateDefaultFormat({
      variables: {
        funcID: template.funcID,
        templateID: template.ID,
        defaultFormat,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateDefaultFormatV3: optimisticFunctionChanges({
          funcID: template.funcID,
          templatesChanged: [
            {
              __typename: 'CellTemplate',
              ...template,
              defaultFormat:
                defaultFormat !== undefined
                  ? { ...defaultFormat, __typename: 'CellFormatOption' }
                  : template.defaultFormat,
            },
          ],
        }),
      },
    });
  };
}
