import React, { useState } from 'react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { Message } from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown';

import ActionMenu from '<components>/ActionMenu';
import * as colors from '<components>/colors';
import Card from '<components>/Card';
import Button from '<components>/Button';
import { OutputChiclet } from '<components>/NumbrzButtons';
import {
  FlexRowContainer,
  PageContent,
} from '<components>/NumbrzPageComponents';
import UnderlinedHeader from '<components>/UnderlinedHeader';

import AddReleaseDialog from '../AddReleaseDialog';

const ReleaseBody = styled(Card.Body)`
  padding: 0 15px;
`;

const ReleaseItem = styled(Card)`
  height: fit-content;
  width: 100%;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledHeadline = styled(Card.Headline)`
  font-size: 18px;
`;

const CreationInfo = styled('div')`
  font-size: 0.8em;
  margin-top: -14px;
  color: ${colors.gray6b};
`;

function Current() {
  return (
    <OutputChiclet style={{ marginLeft: '9px' }}>Current Release</OutputChiclet>
  );
}

function ReleasedAt({ now, release }) {
  if (!release.releasedBy) {
    return <CreationInfo>Not released</CreationInfo>;
  }
  return (
    <CreationInfo>
      Released {dayjs(now).to(release.releasedAt)} by{' '}
      {release.releasedBy.display}
    </CreationInfo>
  );
}

function ReleaseTag({ release, currentReleaseID }) {
  return (
    <>
      {release.releaseTag}
      {release.ID === currentReleaseID ? <Current /> : null}
    </>
  );
}

export default function Releases({
  project,
  releases,
  sourceVersions,
  releaseTagValid,
  onCreate,
  onPublishPreview,
  onPromote,
  onCheckReleaseTag,
  now, // for stable testing
}) {
  const [dialogVisible, setDialogVisible] = useState(false);
  return (
    <PageContent maxWidth="650px">
      <UnderlinedHeader>RELEASES</UnderlinedHeader>
      {releases.length === 0 ? (
        <Message info>This model has no releases</Message>
      ) : (
        <>
          {releases.map((release) => (
            <ReleaseItem key={release.ID}>
              <Card.Header>
                <FlexRowContainer
                  alignItems="baseline"
                  justifyContent="space-between"
                  width="100%"
                >
                  <StyledHeadline>
                    <ReleaseTag
                      release={release}
                      currentReleaseID={project?.catalog?.currentReleaseID}
                    />
                  </StyledHeadline>
                  <ActionMenu
                    options={[
                      ActionMenu.deployOption({
                        onSelect: () => onPromote(release.ID),
                        text: 'Promote to Production',
                        exclude:
                          !onPromote ||
                          project?.catalog?.currentReleaseID === release.ID,
                      }),
                    ]}
                  />
                </FlexRowContainer>
                <ReleasedAt now={now} release={release} />
              </Card.Header>
              <ReleaseBody>
                <ReactMarkdown>{release.releaseNotes}</ReactMarkdown>
              </ReleaseBody>
              <Card.Footer />
            </ReleaseItem>
          ))}
        </>
      )}
      {onCreate ? (
        <>
          <Button.ListPageCreateBtn
            onClick={() => setDialogVisible(true)}
            title="New Resource"
          >
            Create
            <span className="icon">+</span>
          </Button.ListPageCreateBtn>
          <AddReleaseDialog
            visible={dialogVisible}
            onClose={() => setDialogVisible(false)}
            project={project}
            onCreate={onCreate}
            onPublishPreview={onPublishPreview}
            releaseTagValid={releaseTagValid}
            onCheckReleaseTag={onCheckReleaseTag}
          />
        </>
      ) : null}
    </PageContent>
  );
}
