import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import uniqBy from 'lodash/uniqBy';
import fp from 'lodash/fp';
import { ContentError } from '<components>/NumbrzPageComponents';

const elementLabel = {
  stepSchema: 'stage field',
  tableDef: 'column',
};

function IssueMessage({ issue }) {
  switch (issue.code) {
    case 'ElementKeyMissing':
      return `One or more target ${elementLabel[issue.args[0]]}(s) not set`;
    case 'ElementKeyUndefined':
      return issue.class === 'TableDefWarning'
        ? issue.message
        : `This step references one or more non-existent ${
            elementLabel[issue.args[0]]
          }(s)`;
    case 'InvalidInput':
      return (
        <span>
          {issue.message} (<Link to="#INPUT">Jump to input</Link>)
        </span>
      );
    case 'StepNotRunnable':
      return (
        <span>
          {issue.message} (<Link to={`#${issue.args[0]}`}>Jump to step</Link>)
        </span>
      );
    default:
      return issue.message;
  }
}

function useDedupIssues(issues) {
  return useMemo(
    () =>
      uniqBy(
        issues,
        (issue) =>
          `${issue.class}:${issue.code}:${fp.getOr(
            undefined,
            'args[0]',
            issue
          )}:${issue.message}`
      ),
    [issues]
  );
}

export default function ValidationIssues({
  issues,
  issueClass,
  header = 'This item has errors',
  margin,
  wide,
}) {
  if (issueClass) {
    issues = issues.filter((i) => i.class === issueClass);
  }
  issues = useDedupIssues(issues);

  if (!issues || issues.length === 0) {
    return null;
  }

  if (issues.length === 1) {
    return (
      <ContentError error wide={wide} margin={margin}>
        <IssueMessage issue={issues[0]} />
      </ContentError>
    );
  }

  return (
    <ContentError error wide={wide} margin={margin}>
      <p>{header}</p>
      <ul>
        {issues.map((issue, idx) => (
          <li key={idx}>
            <IssueMessage issue={issue} />
          </li>
        ))}
      </ul>
    </ContentError>
  );
}
