import React from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '<src>/apollo/client';

import FunctionPage from '<sections>/functions/FunctionPage';

import { GetProjectName } from '../../queries';

export default function ProjectFunctionPage() {
  const { projectID, functionID } = useParams();
  const { data: { project } = {} } = useQuery(GetProjectName, {
    variables: { ID: projectID },
  });

  return <FunctionPage ID={functionID} project={project} />;
}
