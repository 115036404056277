import React from 'react';
import Table from '<components>/Table';
import { Icon } from 'semantic-ui-react';
import moment from 'moment';

import { InvoiceTable, InvoiceHeaderRow, InvoiceRow } from '../../styles';

export default function RecentInvoices({ invoices }) {
  return (
    <InvoiceTable>
      <Table.Head>
        <InvoiceHeaderRow>
          <Table.Header>Invoice Date</Table.Header>
          <Table.Header>Billing Period</Table.Header>
          <Table.Header className="centered">Amount Due (USD)</Table.Header>
          <Table.Header className="centered">Status</Table.Header>

          <Table.Header className="centered">View</Table.Header>
        </InvoiceHeaderRow>
      </Table.Head>
      <Table.Body>
        {invoices.map((inv, idx) => (
          <InvoiceRow key={idx}>
            <Table.Cell>
              {moment.utc(inv.created * 1000).format('MMM DD, YYYY')}
            </Table.Cell>
            <Table.Cell>
              {`${moment
                .utc(inv.period_start * 1000)
                .format('MMM DD, YYYY')} - ${moment
                .utc(inv.period_end * 1000)
                .format('MMM DD, YYYY')}`}
            </Table.Cell>
            <Table.Cell className="centered">
              ${inv.amount_due / 100}
            </Table.Cell>
            <Table.Cell className="centered">
              <span className={`${inv.status !== 'paid' ? 'error' : null}`}>
                {inv.status}
              </span>
            </Table.Cell>

            <Table.Cell className="centered">
              <a rel="noreferrer" href={inv.hosted_invoice_url} target="_blank">
                <Icon name="file alternate outline" />
              </a>
            </Table.Cell>
          </InvoiceRow>
        ))}
      </Table.Body>
    </InvoiceTable>
  );
}
