import { gql } from '@apollo/client';

import { FunctionChangesFragment } from './fragments';
import useEditFunction from './useEditFunction';

const AddWidgetColumn = gql`
  mutation AddWidgetColumn(
    $funcID: CommonID!
    $widgetID: ID!
    $beforeID: ID
    $label: String
    $comment: String
    $dataType: CellType
    $correlationID: ID
  ) {
    addWidgetColumnV3(
      funcID: $funcID
      widgetID: $widgetID
      beforeID: $beforeID
      label: $label
      comment: $comment
      dataType: $dataType
      correlationID: $correlationID
    ) {
      ...FunctionChangesFragment
    }
  }

  ${FunctionChangesFragment}
`;

export default function useAddWidgetColumn() {
  const addColumn = useEditFunction(AddWidgetColumn, 'addWidgetColumnV3');
  return (widget, { beforeID, correlationID, sourceID, sourceType }) =>
    addColumn({
      variables: {
        funcID: widget.funcID,
        widgetID: widget.ID,
        beforeID,
        correlationID,
        sourceID,
        sourceType,
      },
    });
}
