import React, { useState, useRef } from 'react';
import Dialog from '<components>/Dialog';
import TextInput from '<components>/TextInput';
import { Form, Radio } from 'semantic-ui-react';
import Button from '<components>/Button';
import { SettingsSeparator } from '<components>/NumbrzVerticalEditor';

import styled from '@emotion/styled';
import * as colors from '<components>/colors';

const StyledContent = styled(Dialog.Body)({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  minWidth: '400px',
  p: {
    margin: '0px 5px 10px 5px',
  },
});

const StyledForm = styled(Form)`
  padding: 10px 10px 10px 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid ${colors.gray3};
  .field {
    margin: 0 !important;
    padding: 2px 5px !important;
  }
`;

const StyledSeparator = styled(SettingsSeparator)`
  margin: 4px auto;
  padding: 0;
  border-bottom: 1px dotted ${colors.gray3};
`;

export default function ReviewDialog({
  visible,
  onDialogClose,
  onSubmit,
  blockName,
  reviewComment = '',
}) {
  const [comment, setComment] = useState('');
  const [reviewState, setReviewState] = useState();

  const textInput = useRef();

  const isValid = () => {
    if (!reviewState) return false;
    return true;
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (isValid()) {
        onSubmit(reviewState, comment);
        setComment('');
      }
    }
  };

  return (
    <Dialog visible={visible ? 1 : 0} onRequestClose={onDialogClose}>
      <Dialog.Header>
        <Dialog.Headline>{`Review ${blockName}`}</Dialog.Headline>
        <Button.DialogClose onClick={onDialogClose} />
      </Dialog.Header>

      <StyledContent>
        <p>{reviewComment}</p>
        <StyledForm>
          <Form.Field>
            <Radio
              label="Approve"
              name="reviewState"
              value="Approved"
              checked={reviewState === 'Approved'}
              onChange={(e, { value }) => setReviewState(value)}
            />
          </Form.Field>
          <StyledSeparator />
          <Form.Field>
            <Radio
              label="Reject"
              name="reviewState"
              value="NotApproved"
              checked={reviewState === 'NotApproved'}
              onChange={(e, { value }) => setReviewState(value)}
            />
          </Form.Field>
        </StyledForm>

        <TextInput
          ref={textInput}
          name="comment"
          onChange={(e) => setComment(e.target.value)}
          value={comment}
          onKeyDown={handleKeyDown}
          placeholder="Leave a comment"
        />
      </StyledContent>

      <Dialog.Footer>
        <Button onClick={onDialogClose}>Cancel</Button>
        <Button
          onClick={() => {
            onSubmit(reviewState, comment);
            setComment('');
          }}
          disabled={!isValid()}
        >
          Review
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
}
