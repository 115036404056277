import React, { useRef, useEffect, useMemo, Fragment } from 'react';
import {
  StepDropdownWrapper,
  StepToolbarBtn,
} from '<src>/sections/flows/flow-testing/styles';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import {
  TextIconBtn,
  PopupMenu,
  FilterBtnGroup,
  DataSrcDropdown,
  MenuSectionHdr,
  MenuHdr,
} from '../styles';
import { Icon } from 'semantic-ui-react';
import Button from '@mui/material/Button';

/* Filter Opts:
    visibility: all | inputs | outputs
    data source (tableset): tablesetID
    job table (jobTable) : jobTable.ID
*/

export default function FilterMenu({
  visible,
  setVisible,
  filters,
  setFilters,
  dataOpts = [],
  jobTableOpts = [],
}) {
  const menuRef = useRef();

  const handleToggleFilter = (e, filter, value) => {
    e.preventDefault();
    setFilters({ ...filters, [filter]: value });
  };

  useEffect(() => {
    const menuEl = visible ? menuRef.current : undefined;
    if (menuEl) menuEl.focus();
  }, [visible]);

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') setVisible(false);
  };

  const filterLbl = useMemo(() => {
    let cnt = 0;
    if (filters.visibility !== 'all') cnt = 1;
    if (filters.tableset) cnt++;
    if (filters.jobTable) cnt++;

    return `${cnt > 0 ? cnt : ''} ${cnt === 1 ? 'Filter' : 'Filters'}`;
  }, [filters]);

  return (
    <StepDropdownWrapper>
      <TextIconBtn
        iconSize="19px"
        iconMargin="0 4px 1px 0"
        padding="2px 8px 2px 6px"
        onClick={() => setVisible(!visible)}
        onMouseDown={(e) => e.preventDefault()}
        expanded={visible}
      >
        <FilterListRoundedIcon />
        {filterLbl}
      </TextIconBtn>

      <PopupMenu
        ref={menuRef}
        tabIndex="-1"
        onBlur={(e) => {
          setVisible(false);
        }}
        visible={visible}
        onKeyDown={handleKeyDown}
      >
        <MenuHdr>
          <span>Filters</span>
          <StepToolbarBtn onClick={() => setVisible(false)}>
            <Icon name="close" />
          </StepToolbarBtn>
        </MenuHdr>
        <MenuSectionHdr>Visibility</MenuSectionHdr>

        <FilterBtnGroup
          disableElevation
          variant="contained"
          aria-label="outlined primary button group"
        >
          <Button
            className={filters.visibility === 'all' && 'active'}
            onClick={(e) => {
              handleToggleFilter(e, 'visibility', 'all');
            }}
            onMouseDown={(e) => e.preventDefault()}
          >
            All
          </Button>
          <Button
            className={filters.visibility === 'inputs' && 'active'}
            onClick={(e) => {
              handleToggleFilter(e, 'visibility', 'inputs');
            }}
            onMouseDown={(e) => e.preventDefault()}
          >
            Inputs
          </Button>
          <Button
            className={filters.visibility === 'outputs' && 'active'}
            onClick={(e) => handleToggleFilter(e, 'visibility', 'outputs')}
            onMouseDown={(e) => e.preventDefault()}
          >
            Outputs
          </Button>
        </FilterBtnGroup>
        {dataOpts.length > 0 && (
          <Fragment>
            <MenuSectionHdr>Data source</MenuSectionHdr>
            <DataSrcDropdown
              onMouseDown={(e) => e.preventDefault()}
              options={[
                { key: 'none', text: 'All', value: undefined },
                ...dataOpts,
              ]}
              placeholder="Select"
              value={filters.tableset || undefined}
              onChange={(e, { value }) => {
                e.preventDefault();
                e.stopPropagation();
                handleToggleFilter(e, 'tableset', value);
              }}
            />
          </Fragment>
        )}
        {jobTableOpts.length > 0 && (
          <Fragment>
            <MenuSectionHdr>Job table</MenuSectionHdr>
            <DataSrcDropdown
              onMouseDown={(e) => e.preventDefault()}
              options={[
                { key: 'none', text: 'All', value: undefined },
                ...jobTableOpts,
              ]}
              placeholder="Select"
              value={filters.jobTable || undefined}
              onChange={(e, { value }) => {
                e.preventDefault();
                e.stopPropagation();
                handleToggleFilter(e, 'jobTable', value);
              }}
            />
          </Fragment>
        )}
      </PopupMenu>
    </StepDropdownWrapper>
  );
}
