import React from 'react';
import gql from 'graphql-tag';

import { useMutation, useQuery } from '<src>/apollo/client';

import WaveSpinner from '<components>/WaveSpinner';

import { GetServiceJobDetail } from '../../../queries';
import { SetDeployKeys } from '<sections>/data/queries';

import ProjectDeploySettings from './DeploySettings';

export default function DeploySettings({ project }) {
  const { data, loading } = useQuery(GetServiceJobDetail, {
    variables: { projectID: project.ID },
  });
  const [setDeployKeys] = useMutation(SetDeployKeys);

  const onSetDeployKeys = ({ tables = [], tablesets = [] }) =>
    setDeployKeys({
      variables: { tables, tablesets },
      update: (cache, { error }) => {
        if (error) {
          return;
        }
        tables.forEach(({ ID, deployKey }) =>
          cache.writeFragment({
            id: `DataTable:${ID}`,
            data: {
              deployKey: deployKey || '',
            },
            fragment: gql`
              fragment TableUpdateFrag on DataTable {
                deployKey
              }
            `,
          })
        );
        tablesets.forEach(({ ID, deployKey }) =>
          cache.writeFragment({
            id: `Tableset:${ID}`,
            data: {
              deployKey: deployKey || '',
            },
            fragment: gql`
              fragment TSUpdateFrag on Tableset {
                deployKey
              }
            `,
          })
        );
      },
    });

  if (loading) {
    return <WaveSpinner />;
  }

  return (
    <ProjectDeploySettings jobs={data.jobs} onSetDeployKeys={onSetDeployKeys} />
  );
}
