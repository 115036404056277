import React, { Fragment, useState, useRef, useEffect } from 'react';

import ReactMarkdown from 'react-markdown';
import styled from '@emotion/styled';
import { Message } from 'semantic-ui-react';

import * as colors from '<components>/colors';
import Button from '<src>/components/Button';
import TabBar from '<components>/TabBar';
import UnderlinedHeader from '<components>/UnderlinedHeader';
import { StyledScrollbar } from '../NumbrzPageComponents';

const StyledContent = styled('div')`
  width: 100%;
  max-height: 400px;
  overflow: auto;
  word-break: break-word;
  margin: 0;
  padding: 0 5px 0 0;
  font-size: 12px;
  ${StyledScrollbar};
`;

const StyledPlaceholder = styled('p')`
  font-style: italic;
  color: ${colors.gray2};
`;

const StyledTextArea = styled('textarea')`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  width: 100%;
  min-height: 60px;
  resize: vertical;
  border: 1px solid ${colors.gray3};
  border-radius: 5px;
  padding: 5px;
  margin: 0;
  &:focus {
    outline: none;
    box-shadow: 0 0 1px 2px ${colors.blue3};
  }
`;

const StyledPreviewArea = styled('div')`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  min-height: ${(props) => `calc(0.86lh * ${props.rows})`};
`;

const StyledFooter = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0;
  & > button {
    margin-right: 5px;
  }
  & > button:last-child {
    margin-right: 0;
  }
`;

const StyledReactMarkdown = styled(ReactMarkdown)`
  height: fit-content;
  overflow-y: auto;
  overflow-wrap: break-word;
  color: ${colors.gray1};
  ${(props) => props.maxHeight && { maxHeight: props.maxHeight }};
`;

const editorTabs = [
  {
    name: 'edit',
    label: 'Edit',
  },
  {
    name: 'preview',
    label: 'Preview',
  },
];

export function MarkdownEditor({ content, onChange, rows = 12 }) {
  const [preview, setPreview] = useState(false);
  const textInput = useRef();

  useEffect(() => {
    if (!preview) {
      textInput.current.focus();
    }
  }, [preview]);

  return (
    <>
      <TabBar.Light.Small
        tabs={editorTabs}
        activeTab={preview ? 'preview' : 'edit'}
        onTabChange={(mode) => setPreview(mode === 'preview')}
        bgColor="transparent"
      />
      <StyledContent>
        <StyledTextArea
          visible={!preview}
          ref={textInput}
          onChange={onChange}
          value={content}
          rows={rows}
        />
        <StyledPreviewArea visible={preview} rows={rows}>
          <ReactMarkdown>{content}</ReactMarkdown>
        </StyledPreviewArea>
      </StyledContent>
    </>
  );
}

export default function EditableMarkdown({
  content: contentProp,
  onSave = () => {},
  label = 'description',
  editable = true,
  rows = 12,
  placeholder,
  message,
  hideIfEmpty,
  maxHeight,
}) {
  const [mode, setMode] = useState('view');
  const [content, setContent] = useState(contentProp);

  useEffect(() => {
    setContent(contentProp);
  }, [contentProp]);

  const saveEdits = async () => {
    await onSave(content);
    setMode('view');
  };

  const cancelEditing = () => {
    setContent(contentProp);
    setMode('view');
  };

  const viewer = () => {
    const trimmedContent = content && content.trim ? content.trim() : content;

    if (!editable && !trimmedContent && hideIfEmpty) {
      return <StyledContent />;
    }

    return (
      <Fragment>
        <UnderlinedHeader justifyContent="space-between">
          <span>{label}</span>
          {editable && (
            <UnderlinedHeader.Controls>
              <Button.EditBtn onClick={() => setMode('edit')} />
            </UnderlinedHeader.Controls>
          )}
        </UnderlinedHeader>
        <StyledContent>
          {message ? <Message info>{message}</Message> : null}
          {trimmedContent ? (
            <StyledReactMarkdown
              collapsed={false}
              className="open"
              maxHeight={maxHeight}
            >
              {content}
            </StyledReactMarkdown>
          ) : (
            <StyledPlaceholder>{placeholder}</StyledPlaceholder>
          )}
        </StyledContent>
      </Fragment>
    );
  };

  if (mode === 'view') {
    return viewer();
  }
  return (
    <>
      <MarkdownEditor
        content={content}
        onChange={(event) => setContent(event.target.value)}
        label={label}
      />
      <StyledFooter>
        <Button size="small" onClick={cancelEditing}>
          Cancel
        </Button>
        <Button size="small" onClick={saveEdits}>
          Update {label}
        </Button>
      </StyledFooter>
    </>
  );
}
