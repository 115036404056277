import styled from '@emotion/styled';
import * as colors from '<components>/colors';

const ErrorChiclet = styled('div')`
  display: ${(props) =>
    props.visible && props.visible === 'false' ? 'none' : 'flex'};
  width: fit-content;
  white-space: nowrap;
  min-width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
  font-size: 0.85em;
  background: ${(props) => (props.sidebar ? 'transparent' : colors.red1c)};
  border-radius: 40px;

  padding: ${(props) => (props.wide ? '0 0px 0 8px' : '0px 5px')};
  color: ${(props) => (props.sidebar ? colors.gray1 : 'white')};
  text-align: center;
  ${(props) => props.actionable && { cursor: 'pointer' }};
  i {
    font-size: 14px;
    margin-left: 4px;
    ${(props) => props.sidebar && { marginRight: '0px' }};
    height: 19px;
  }
  &.exclamation {
    height: ${(props) => (props.sidebar ? '17px' : '20px')};
    width: ${(props) => (props.sidebar ? '17px' : '20px')};
    border-radius: 45px;

    padding: 0;
    i {
      font-size: 11px;
    }
  }
`;

export default ErrorChiclet;
