import React, { useState, useMemo, useCallback, Fragment } from 'react';
import { Accordion, Checkbox, Icon } from 'semantic-ui-react';

import {
  StyledBody,
  HeaderContainer,
  StyledAccordion,
  PanelTitle,
  FieldContainer,
  SelectAllContainer,
} from '<sections>/flows/FieldPicker/styles';

import styled from '@emotion/styled';

const StyledHeaderContainer = styled(HeaderContainer)`
  margin: 0 3px 10px 3px;
`;

const StyledContent = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

function FieldGroup({
  expanded: expandedProp,
  flat,
  flows,
  sourceInfo = {},
  multiSelect,
  selected = [],
  onChange,
}) {
  const [expanded, setExpanded] = useState(expandedProp);

  const onSelectElement = useCallback(
    (event, { flow, checked }) => {
      if (!multiSelect) {
        if (selected !== flow.ID) {
          onChange(event, { selected: flow.ID });
        }
        return;
      }

      const currentChecked = selected.includes(flow.ID);

      if (checked && !currentChecked) {
        onChange(event, { selected: [...selected, flow.ID] });
      } else if (!checked && currentChecked)
        onChange(event, {
          selected: selected.filter((f) => f !== flow.ID),
        });
    },
    [multiSelect, selected, onChange]
  );

  return (
    <div>
      {!flat ? (
        <Accordion.Title
          active={expanded}
          onClick={(event) => {
            event.stopPropagation();
            setExpanded(!expanded);
          }}
        >
          <Icon name="dropdown" />
          <PanelTitle>{sourceInfo}</PanelTitle>
          {multiSelect ? (
            <SelectAll flows={flows} selected={selected} onChange={onChange} />
          ) : null}
        </Accordion.Title>
      ) : null}
      <Accordion.Content active={expanded || flat}>
        {flows.map((flow, idx) => {
          const checked = multiSelect
            ? selected.includes(flow.ID)
            : selected === flow.ID;
          return (
            <FieldContainer
              className="item"
              key={idx}
              checked={checked}
              multiSelect={multiSelect}
              onClick={(event) =>
                onSelectElement(event, { checked: !checked, flow })
              }
            >
              <span>{flow.name}</span>
              <SelectElement
                checked={checked}
                multiSelect={multiSelect}
                onChange={(e, { checked }) =>
                  onSelectElement(e, { checked, flow })
                }
              />
            </FieldContainer>
          );
        })}
      </Accordion.Content>
    </div>
  );
}

function SelectAll({ flows, selected = [], onChange }) {
  const flowIDs = useMemo(() => flows.map((f) => f.ID), [flows]);

  const selection = useMemo(
    () => flowIDs.filter((f) => selected.includes(f)),
    [flowIDs, selected]
  );

  const checked = selection.length > 0;
  const indeterminate = checked && selection.length < flows.length;

  const onSelectAll = useCallback(
    (e, { checked }) => {
      e.stopPropagation();
      const newSelected = selected.filter((j) => !flowIDs.includes(j));
      if (checked) {
        newSelected.push(...flowIDs);
      }
      onChange(e, { selected: newSelected });
    },
    [flowIDs, selected, onChange]
  );

  return (
    <Checkbox
      checked={checked}
      indeterminate={indeterminate}
      onChange={onSelectAll}
    />
  );
}

function SelectElement({ checked, onChange, multiSelect }) {
  if (multiSelect) {
    return <Checkbox checked={checked} onChange={onChange} />;
  }
  if (checked) {
    return <Icon name="check" />;
  }
  return null;
}

export default function IncludeFlowList({
  includedFlows,
  onSelectJob,
  onUnselectJob,
  onChange,
  flows,
}) {
  const groupedFlows = useMemo(() => {
    const groups = {};

    flows.forEach((f) => {
      const sourceKey = f.modelName;
      if (!groups[sourceKey]) {
        groups[sourceKey] = [f];
      } else {
        groups[sourceKey].push(f);
      }
    });
    return groups;
  }, [flows]);

  return (
    <StyledContent>
      {flows.length === 0 ? (
        <p>No flows found.</p>
      ) : (
        <Fragment>
          <StyledHeaderContainer>
            <SelectAllContainer>
              <span>Select All </span>
              <SelectAll
                flows={flows}
                selected={includedFlows}
                onChange={(e, data) => onChange(data.selected)}
              />
            </SelectAllContainer>
          </StyledHeaderContainer>
          <StyledAccordion exclusive={false}>
            <StyledBody>
              {Object.entries(groupedFlows).map(([key, flows]) => {
                return (
                  <FieldGroup
                    key={key}
                    sourceInfo={key}
                    flat={false}
                    flows={flows}
                    multiSelect={true}
                    selected={includedFlows}
                    onChange={(e, data) => onChange(data.selected)}
                    expanded={Object.entries(groupedFlows).length === 1}
                  />
                );
              })}
            </StyledBody>
          </StyledAccordion>
        </Fragment>
      )}
    </StyledContent>
  );
}
