// These resets are meant to be eventually
// applied to the entire site

// eslint-disable-next-line import/prefer-default-export
export const button = {
  border: 'none',
  margin: '0',
  padding: '0',
  width: 'auto',
  overflow: 'visible',
  background: 'transparent',
  color: 'inherit',
  font: 'inherit',
  textAlign: 'inherit',
  lineHeight: 'normal',
  fontSmoothing: 'inherit',
  appearance: 'none',
};
