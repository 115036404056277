import React, { useState, useRef } from 'react';
import TypeAheadDropDown from './styles';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { FlexRowContainer } from '<components>/NumbrzPageComponents';

export default function TypeAheadSearch({
  items,
  onItemClick,
  placeholder = 'Search',
}) {
  const [focused, setFocused] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [text, setText] = useState('');

  const inputEl = useRef();

  const onTextChange = (e) => {
    let suggestions = [];
    const value = e.target.value;
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, `i`);
      suggestions = items.sort().filter((v) => regex.test(v));
    }
    setSuggestions(suggestions);
    setText(value);
  };

  const suggestionSelected = (value) => {
    if (onItemClick) {
      onItemClick(value);
      setText('');
    } else {
      setText(value);
    }
    setSuggestions([]);
  };

  const renderSuggestions = () => {
    if (suggestions.length === 0) {
      return null;
    }
    return (
      <ul>
        {suggestions.map((s, idx) => (
          // eslint-disable-next-line
          <li
            key={idx}
            onClick={(e) => suggestionSelected(s)}
            onMouseDown={(e) => e.preventDefault()}
          >
            {s}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <TypeAheadDropDown className={focused && 'active'}>
      <FlexRowContainer
        alignItems="center"
        justifyContent="flex-start"
        width="100%"
      >
        <SearchRoundedIcon />
        <input
          tabIndex={0}
          ref={inputEl}
          onChange={onTextChange}
          placeholder={placeholder}
          value={text}
          type="text"
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      </FlexRowContainer>

      {renderSuggestions()}
    </TypeAheadDropDown>
  );
}
