import React from 'react';

import { NumbrzTooltip, FieldSrcTooltipBtn } from '<components>/NumbrzButtons';

export function ElementSource({ element }) {
  if (!element) return null;

  return element.sourceInfo ? (
    <NumbrzTooltip
      hideOnScroll
      on="click"
      position="top center"
      content={<span>Source: {element.sourceInfo.label}</span>}
      trigger={<FieldSrcTooltipBtn name="info circle" />}
    />
  ) : null;
}

function ElementText({ elementKey, element }) {
  if (element) {
    return <span className="label">{element.label}</span>;
  }
  if (!elementKey) {
    return <em>not set</em>;
  }
  return <em>field not found</em>;
}

export default function ElementLabel({ className, elementKey, element }) {
  return (
    <span className={className}>
      <ElementSource element={element} />
      <ElementText elementKey={elementKey} element={element} />
    </span>
  );
}

ElementLabel.Source = ElementSource;
ElementLabel.Text = ElementText;
