import { useEffect, useRef, useState } from 'react';

function base64urlencodearray(arr) {
  return btoa(arr).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

function generateRandomString() {
  var array = new Uint8Array(32);
  window.crypto.getRandomValues(array);
  return base64urlencodearray(array);
}

const clientConfig = {
  google: {
    authURLBase:
      'https://accounts.google.com/o/oauth2/auth?prompt=consent&access_type=offline',
    clientID: ((loc) => {
      switch (loc) {
        case 'app.numbrz.com':
        case 'app.numbrz.org':
        case 'app.debug':
          // intentional passthrough; prod domains
          return '128063494759-1aiudi0ocios56p8guaqe6uv21480ooc.apps.googleusercontent.com';
        default:
          // all other domains are development
          return '904520279177-hncn1bfu7bc9bfe4kjadjuv3gse0fblk.apps.googleusercontent.com';
      }
    })(window.location.hostname),
    scope: 'https://www.googleapis.com/auth/spreadsheets profile email',
    callbackPath: 'google-callback',
  },
  microsoft: {
    authURLBase:
      'https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?',
    clientID: ((loc) => {
      switch (loc) {
        case 'app.numbrz.com':
        case 'app.numbrz.org':
        case 'app.debug':
          // intentional passthrough; prod domains
          return '008be65a-c92c-4c79-b5fe-ce10f964049d';
        default:
          // all other domains are development
          return '7a32b050-9887-4277-b18d-02280eeea459';
      }
    })(window.location.hostname),
    scope: 'offline_access files.readwrite.all User.Read',
    callbackPath: 'callback',
  },
  dropbox: {
    authURLBase:
      'https://www.dropbox.com/oauth2/authorize?token_access_type=offline',
    clientID: ((loc) => {
      switch (loc) {
        case 'app.numbrz.com':
        case 'app.numbrz.org':
        case 'app.debug':
          // intentional passthrough; prod domains
          return 'joxz48rnxc3q9vk';
        default:
          // all other domains are development
          return '9t41exd5dzro91f';
      }
    })(window.location.hostname),
    scope:
      'files.metadata.read files.content.read files.content.write account_info.read',
    callbackPath: 'dropbox-callback',
  },
  quickbooks: {
    authURLBase: 'https://appcenter.intuit.com/connect/oauth2?',
    clientID: ((loc) => {
      switch (loc) {
        case 'app.numbrz.com':
        case 'app.numbrz.org':
        case 'app.debug':
          // intentional passthrough; prod domains
          return 'BBmxs4sWGZVxRIFKQosi3xQ43PvDMmjkl620LmcPSFEIKsFMB8';
        default:
          // all other domains are development
          return 'BB203dCYAEcY7YSTLwW7fNpSW6n1X1ttLAaBXyhKyQluSfYGNt';
      }
    })(window.location.hostname),
    scope: `com.intuit.quickbooks.accounting`,
    callbackPath: 'qb-callback',
  },
};

export default function useOAuth(serviceTypeOrOnAuth, onAuth) {
  if (typeof serviceTypeOrOnAuth === 'function') {
    onAuth = serviceTypeOrOnAuth;
    serviceTypeOrOnAuth = undefined;
  }
  const [serviceType = serviceTypeOrOnAuth, setServiceType] = useState();
  const [popupVisible, setPopupVisible] = useState(false);
  const [authed, setAuthed] = useState(false);
  const [extraCallback, setExtraCallback] = useState();
  const onAuthRef = useRef(() => {});
  useEffect(() => {
    onAuthRef.current = (resp) => {
      if (onAuth) {
        onAuth(resp);
      }
      if (extraCallback) {
        extraCallback(resp);
      }
    };
  }, [extraCallback, onAuth]);

  useEffect(() => {
    if (popupVisible) {
      if (!serviceType) {
        // no serviceType means the hook is disabled
        setPopupVisible(false);
        return;
      }

      const width = 600;
      const height = 700;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2.5;

      const nonce = generateRandomString();
      const relayState = { nonce };

      const relayStateStr = JSON.stringify(relayState);
      sessionStorage.setItem('oauth.relay_state', relayStateStr);

      const { authURLBase, clientID, scope, callbackPath } =
        clientConfig[serviceType];

      const redirectURL = `${window.location.origin}/oauth/${callbackPath}`;

      const listener = (e) => {
        if (e.key === 'oauth.response' && e.newValue) {
          const response = JSON.parse(e.newValue);
          onAuthRef.current({ ...response, scope, redirectURL, serviceType });
          setPopupVisible(false);
          setAuthed(true);
          setExtraCallback(undefined);
        }
      };
      window.addEventListener('storage', listener);

      const scopeEnc = encodeURIComponent(scope);
      const authURL = `${authURLBase}&client_id=${clientID}&scope=${scopeEnc}&state=${nonce}&redirect_uri=${redirectURL}&response_type=code`;
      const popupWindow = window.open(
        authURL,
        'Authorization',
        `width=${width},height=${height},left=${left},top=${top}`
      );

      return () => {
        setPopupVisible(false);
        popupWindow && popupWindow.close();

        window.removeEventListener('storage', listener);
      };
    }

    return;
  }, [popupVisible, serviceType]);

  if (serviceType && !clientConfig[serviceType]) {
    throw new TypeError(`Unknown OAuth provider ${serviceType}`);
  }

  return [
    (serviceType, extraCallback) => {
      setPopupVisible(true);
      setAuthed(false);

      if (typeof serviceType === 'function') {
        extraCallback = serviceType;
        serviceType = undefined;
      } else if (typeof serviceType === 'string') {
        setServiceType(serviceType);
      }
      if (typeof extraCallback === 'function') {
        setExtraCallback(() => extraCallback);
      }
    },
    popupVisible,
    authed,
  ];
}
