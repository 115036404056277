function initialSetupState(type, name) {
  const templates =
    type === 'DateCompose'
      ? [
          {
            ID: 'BUILTIN:DATE_COMPOSE:YR',
            section: 'Input',
            label: 'Year',
            dataType: 'Number',
          },
          {
            ID: 'BUILTIN:DATE_COMPOSE:MO',
            section: 'Input',
            label: 'Month',
            dataType: 'Number',
          },
          {
            ID: 'BUILTIN:DATE_COMPOSE:DY',
            section: 'Input',
            label: 'Day',
            dataType: 'Number',
          },
          {
            ID: 'BUILTIN:DATE_COMPOSE:DT',
            section: 'Output',
            label: 'Date',
            dataType: 'Date',
          },
        ]
      : [
          {
            ID: 'BUILTIN:DATE_EXPAND:DT',
            section: 'Input',
            label: 'Date',
            dataType: 'Date',
          },
          {
            ID: 'BUILTIN:DATE_EXPAND:YR',
            section: 'Output',
            label: 'Year',
            dataType: 'Number',
          },
          {
            ID: 'BUILTIN:DATE_EXPAND:MO',
            section: 'Output',
            label: 'Month',
            dataType: 'Number',
          },
          {
            ID: 'BUILTIN:DATE_EXPAND:DY',
            section: 'Output',
            label: 'Day',
            dataType: 'Number',
          },
        ];

  return {
    cells: {
      c1: { ID: 'c1', value: null },
      c2: { ID: 'c2', value: null },
      c3: { ID: 'c3', value: null },
      c4: { ID: 'c4', value: null },
    },
    widget: {
      type,
      name,
      columns: templates.map((t, i) => ({
        ID: `col${i + 1}`,
        templateID: t.ID,
        template: t,
        cellIDs: [`c${i + 1}`],
      })),
      rows: [
        {
          ID: 'row1',
          cellIDs: ['c1', 'c2', 'c3', 'c4'],
          template: { ID: 'row1', label: 'Row 1' },
        },
      ],
    },
  };
}

export const dateCompose = {
  ID: 'BUILTIN:DATE_COMPOSE',
  type: 'DateCompose',
  builtin: true,
  name: 'Compose Date',
  description: 'Generate a date from a year, month, and day.',
  Label: (widget) => 'Compose Date',
  initialSetupState: initialSetupState('DateCompose', 'Compose Date'),
};

export const dateExpand = {
  ID: 'BUILTIN:DATE_EXPAND',
  type: 'DateExpand',
  builtin: true,
  name: 'Parse Date',
  description: 'Extract the year, month and day from a date.',
  Label: (widget) => 'Parse Date',
  initialSetupState: initialSetupState('DateExpand', 'Parse Date'),
};
