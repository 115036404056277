/** @jsx jsx */
/** @jsxRuntime classic */

import styled from '@emotion/styled';
import { jsx, css } from '@emotion/react';
import * as colors from '<components>/colors';

import { Icon, Popup } from 'semantic-ui-react';
import Button from '<src>/components/Button';

const ignoreSsrWarning =
  '/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */';

// eslint-disable-next-line
export const IconRunButton = styled(Icon)`
  font-size: 22px !important;
  margin: 0 6px 0 0 !important;
  padding: 1px 2px 2px 2px;
  color: ${colors.gray2};
  cursor: pointer;
  :hover {
    color: ${colors.blue1};
  }
`;

export const LabelBtnStyles = (props) => css`
  width: fit-content;
  background-color: white;
  font-size: 11px;
  letter-spacing: 0.5px;
  font-family: Lato, 'Helvetica';
  margin: 0;
  padding: 3px 5px;
  box-sizing: border-box;
  border: 1px solid transparent;
  box-shadow: none;
  border-radius: 4px;
  color: ${colors.gray2b};
  border: 1px solid ${colors.gray2b};
  cursor: pointer;
  outline: none;
  :hover {
    color: white;
    background-color: ${colors.gray2b};
  }
  .disabled {
    cursor: not-allowed;
  }
  ${props.negative &&
  `
  color: ${colors.red1c};
  border-color: ${colors.red1c};
  :hover {
    color: white;
    background-color: ${colors.red1c};
  }
  `}
`;

const LabelRunButtonStyles = (props) => css`
  &.disabled {
    border: 1px solid ${colors.gray3};
    background-color: white;
    color: ${colors.gray3};
    :hover {
      border: 1px solid ${colors.gray3};
      background-color: white;
      color: ${colors.gray3};
      cursor: not-allowed;
    }
  }
  color: ${props.isRunning === 'true' ? colors.blue2 : colors.green2};
  border: ${props.isRunning === 'true'
    ? `1px solid ${colors.blue2}`
    : `1px solid ${colors.green2}`};
  :hover {
    color: white;
    background-color: ${props.isRunning === 'true'
      ? colors.blue2
      : colors.green2};
  }
`;

export const LabelButton = styled('button')`
  ${LabelBtnStyles}
`;

export const LabelRunButton = styled('button')`
  ${LabelBtnStyles}
  ${LabelRunButtonStyles}
`;

export const TestDialogBtn = styled('button')`
  ${LabelRunButtonStyles}
  color: ${colors.gray2};
  border: none;
  border-radius: 4px;
  margin-right: 5px;
  padding: 2px 5px;

  :hover {
    color: ${colors.gray1b};
    border: 1px solid ${colors.gray1b};
    background: transparent;
  }
`;

export const ClearTestBtn = styled('button')`
  ${LabelRunButtonStyles}
  color: ${colors.blue3};
  border-color: ${colors.blue3};
  border-radius: 4px;
  margin-right: 10px;
  padding: 2px 5px;
  :hover {
    color: white;
    background: ${colors.blue3};
  }
`;

export const LinkButton = styled('button')`
  width: fit-content;
  background-color: transparent;
  font-size: 13px;
  text-transform: uppercase;
  line-height: inherit;
  letter-spacing: 1px;
  font-weight: 500;
  font-family: Lato, 'Helvetica';
  border: none;
  margin: 0;
  padding: 0;
  color: ${colors.blue2};
  cursor: pointer;
  outline: none;
  :hover {
    color: ${colors.blue1};
  }
`;

export const CollapseLabelBtn = styled(Icon)`
  font-size: 20px !important;
  margin: 0 0 0 5px !important;
  padding: 0;
  color: ${colors.gray1};
  cursor: pointer;
  color: ${(props) => (props.input === 'true' ? colors.blue2 : colors.gray2)};
  line-height: normal !important;
`;

const Warning = styled(Icon)`
  color: ${colors.gray2};
  font-size: 17px !important;
  :hover {
    color: ${colors.red3};
  }
`;

export const WarningIcon = ({ title }) => (
  <Warning name="exclamation" title={title} />
);

export const WarningPopup = (props) => (
  <Popup
    trigger={<Warning name="exclamation circle" />}
    position="top left"
    content={props.content}
    style={PopupStyle}
    basic
  />
);

export const StyledLink = styled('a')`
  color: ${colors.gray2};
  height: 34px;
  width: 34px;
  border-radius: 9px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  i {
    padding-left: 2px;
    font-size: 18px;
    margin: 0;
  }
  :hover {
    color: ${colors.gray2};
    background-color: ${colors.gray4};
  }
`;

export const HrefButton = styled('a')`
  font-size: 1em;
  font-weight: normal !important;
  border: 1px solid ${colors.gray2};
  border-radius: 9px;
  padding: 3px 8px;
  color: ${colors.gray2};
  :hover {
    border: 1px solid ${colors.blue2};
    color: ${colors.blue2};
  }
`;

const BackButton = (props) => (
  <Button {...props} baseColor="white" activeColor={colors.gray2b} />
);

export const FlowNewButton = (props) => (
  <BackButton
    {...props}
    css={css`
      margin: 0 10px;
      border-color: ${colors.gray2b};
      color: ${colors.gray1b};
      border-radius: 4px;
      padding: 10px 18px;
    `}
  />
);

export const FlowIconButtonWithTooltip = ({ onClick, iconName, title }) => {
  return (
    <NumbrzTooltip
      content={title}
      trigger={
        <Button
          onClick={onClick}
          icon={<Icon name={iconName} title={title} />}
        />
      }
    />
  );
};

export const EditorAddButton = ({ title, ...props }) => {
  return (
    <Button
      icon={<Icon name="plus" title={title} />}
      baseColor="transparent"
      borderColor={colors.gray3}
      fontSize="13px"
      css={css`
        background: white;
        border-color: ${colors.gray3};
        color: ${colors.gray2b};
        padding: 9px 12px;
        :hover {
          color: ${colors.gray2b};
        }
        i {
          width: 1em;
        }
      `}
      {...props}
    />
  );
};

export const GreenActnBtn = styled(Button)``;

export const ListPageAddButton = ({ title, ...props }) => {
  return (
    <Button
      icon={<Icon name="plus" title={title} />}
      baseColor="transparent"
      borderColor={colors.gray2}
      css={css`
        font-size: 12px;
        background: white;

        color: ${colors.gray2b};
        padding: 9px 12px;
        position: absolute;
        bottom: 30px;
        right: 40px;
        :hover {
          background: ${colors.yellow4};
          color: ${colors.gray2b};
        }
        i {
          width: 1em;
        }
      `}
      {...props}
    />
  );
};

export const FunctionAddButton = ({ title, ...props }) => {
  return (
    <Button
      icon={<Icon name="plus" title={title} />}
      baseColor="transparent"
      css={css`
        background: white;
        border-color: ${colors.gray3};
        color: ${colors.gray2b};
        padding: 9px 12px;

        font-size: 11px;
        :hover {
          background: ${colors.gray2b};
          color: white;
        }
      `}
      {...props}
    />
  );
};

export const ActiveStatusButton = (props) => (
  <Button
    {...props}
    baseColor={colors.green1}
    activeColor={colors.green1}
    disabledStyles={{
      background: colors.green1,
      color: 'white',
    }}
  />
);

export const InvertedButton = (props) => (
  <Button
    {...props}
    css={css`
      :focus {
        border-color: transparent;
        background-color: transparent;
        color: ${colors.gray2};
      }
      :hover {
        border-color: ${colors.gray1b};
        color: ${colors.gray1b};
        background-color: white;
      }
    `}
  />
);

export const PopupStyle = {
  color: colors.gray1,
  backgroundColor: colors.gray4,
  border: 'none',
  zIndex: 10000000,
};

export const NumbrzTooltip = (props) => (
  <Popup
    {...props}
    on={['hover', 'click']}
    hideOnScroll
    header={props.header ? props.header : null}
    trigger={props.trigger}
    wide={props.wide ? 'very' : null}
    position={props.position ? props.position : 'top right'}
    content={props.content}
    style={PopupStyle}
    pinned
    basic
  />
);

export const CollapseButton = ({ onClick, collapsed = false, label }) => (
  <NumbrzTooltip
    trigger={
      <Button
        onClick={onClick}
        icon={<Icon name={collapsed ? 'expand' : 'compress'} />}
      />
    }
    content={
      collapsed ? `Expand ${label || 'Step'}` : `Collapse ${label || 'Step'}`
    }
  />
);

export const IconButton = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  height: 34px !important;
  width: 34px !important;
  font-size: 20px !important;
  color: ${colors.gray2};
  padding: 8px 0 0 1px;
  margin: 0 !important;
  :hover {
    cursor: pointer;
    background-color: ${colors.gray4};
  }
`;

export const MastheadIconBtn = (props) => (
  <Button
    {...props}
    size="large"
    css={css`
      min-width: unset;
      color: ${colors.gray2b};
      svg {
        font-size: 20px;
        :hover {
          color: ${colors.gray2b};
        }
      }

      ${props.margin && `margin: ${props.margin}`}
    `}
  />
);

export const FieldSrcTooltipBtn = (props) => (
  <Icon
    {...props}
    css={css`
      display: inline-block;
      cursor: pointer;
      font-size: 1.3em !important;
      margin-left: 6px !important;
      &:first-child${ignoreSsrWarning} {
        margin-left: 0px !important;
      }
      color: ${colors.gray1a};
    `}
  />
);

export const MapBtn = styled(Button)`
  font-size: 0.7em;
  min-width: unset;
  padding: 4px 8px !important;
`;

const StyledTextAnimation = styled('div')`
  @keyframes flickerAnimation {
    0% {
      color: ${(props) =>
        props.fadeOutColor ? props.fadeOutColor : colors.gray2};
    }
    50% {
      color: ${(props) =>
        props.fadeInColor ? props.fadeInColor : colors.gray4};
    }
    100% {
      color: ${(props) =>
        props.fadeOutColor ? props.fadeOutColor : colors.gray2};
    }
  }
  @-o-keyframes flickerAnimation {
    0% {
      color: ${(props) =>
        props.fadeOutColor ? props.fadeOutColor : colors.gray2};
    }
    50% {
      color: ${(props) =>
        props.fadeInColor ? props.fadeInColor : colors.gray4};
    }
    100% {
      color: ${(props) =>
        props.fadeOutColor ? props.fadeOutColor : colors.gray2};
    }
  }
  @-moz-keyframes flickerAnimation {
    0% {
      color: ${(props) =>
        props.fadeOutColor ? props.fadeOutColor : colors.gray2};
    }
    50% {
      color: ${(props) =>
        props.fadeInColor ? props.fadeInColor : colors.gray4};
    }
    100% {
      color: ${(props) =>
        props.fadeOutColor ? props.fadeOutColor : colors.gray2};
    }
  }
  @-webkit-keyframes flickerAnimation {
    0% {
      color: ${(props) =>
        props.fadeOutColor ? props.fadeOutColor : colors.gray2};
    }
    50% {
      color: ${(props) =>
        props.fadeInColor ? props.fadeInColor : colors.gray4};
    }
    100% {
      color: ${(props) =>
        props.fadeOutColor ? props.fadeOutColor : colors.gray2};
    }
  }
  -webkit-animation: flickerAnimation 1.5s infinite;
  -moz-animation: flickerAnimation 1.5s infinite;
  -o-animation: flickerAnimation 1.5s infinite;
  animation: flickerAnimation 1.5s infinite;
  color: ${(props) => (props.textColor ? props.textColor : colors.gray1)};
  text-transform: uppercase;
  padding-right: 20px;
`;

export const FadeInOutTextAnimation = ({ text, fadeInColor, fadeOutColor }) => (
  <StyledTextAnimation fadeInColor={fadeInColor} fadeOutColor={fadeOutColor}>
    {text}
  </StyledTextAnimation>
);

export const Tag = styled('span')`
  font-size: 0.9em;
  padding: 0px 8px;
  border-radius: 50px;
  color: ${colors.gray1};
  border: 1px solid ${colors.yellow1b};
  ${(props) => props.erasedTag === 'true' && `margin-right: 10px`};
  ${(props) =>
    !props.static === 'true' &&
    `:hover {
    color: white;
    background: ${colors.yellow1b};
    cursor: pointer;
  }`}
  height: fit-content;
  white-space: nowrap;
`;

export const RoundChiclet = styled('span')`
  height: fit-content;
  min-height: 20px;
  white-space: nowrap;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  text-transform: none !important;
  letter-spacing: 0.5px;
  padding: 0px 8px;
  border-radius: 50px;
  font-weight: normal !important;
  color: ${(props) => (props.textColor ? props.textColor : colors.gray1)};

  background: ${(props) =>
    `${props.backgroundColor ? props.backgroundColor : 'transparent'}`};
  border: ${(props) => (props.border ? props.border : 'none')};
  display: inline-flex;
  align-items: center;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0px')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '5px')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};

  ${(props) => props.margin && { margin: props.margin }};
  div.loader {
    margin-left: 7px !important;
  }
`;

export const SquareChiclet = styled('span')`
  display: flex;
  align-items: center;
  background: ${colors.gray2};
  color: white;
  border-radius: 50px;
  padding: 3px 8px 0px 8px;
`;

export const InputChiclet = (props) => (
  <RoundChiclet
    {...props}
    backgroundColor={colors.blue3}
    textColor={colors.gray2b}
  />
);

export const OutputChiclet = (props) => (
  <RoundChiclet
    {...props}
    backgroundColor={colors.green3}
    borderColor="transparent"
    textColor={colors.green1b}
  />
);

export const GrayChiclet = (props) => (
  <RoundChiclet {...props} backgroundColor={colors.gray7} />
);

export const ErrorChiclet = (props) => (
  <RoundChiclet {...props} backgroundColor={colors.red1c} textColor="white" />
);

export const ReviewChiclet = (props) => (
  <RoundChiclet
    {...props}
    backgroundColor={colors.yellow1b}
    textColor={colors.orange1b}
    borderColor="transparent"
    marginTop="2px"
  />
);
