import React, { forwardRef, useMemo, useState } from 'react';

import { compare } from '<src>/utils/text';

import AddJobTableDialog from '../AddJobTableDialog';
import SectionedDropdown from '<components>/SectionedDropdown';
import { GrayChiclet, RoundChiclet } from '<components>/NumbrzButtons';
import * as colors from '<components>/colors';
import { useGoAndReturnTo } from '<src>/components/LinkAndReturnTo';
import { FlexRowContainer } from '<src>/components/NumbrzPageComponents';

const toOpt = (baseURL) => (td) => ({
  ...td,
  value: td.ID,
  text: td.name,
  triggerText: (
    <FlexRowContainer alignItems="center">
      {td.name}{' '}
      <RoundChiclet
        backgroundColor={colors.blue7}
        textColor={colors.gray2b}
        marginLeft="8px"
      >
        {td.prefSource === 'WorkingTable' ? 'Working table' : 'Job table'}
      </RoundChiclet>
      {td.isIncluded && <GrayChiclet>Included</GrayChiclet>}
    </FlexRowContainer>
  ),
  location: baseURL
    ? `${baseURL}/${
        td.prefSource === 'WorkingTable' ? 'working-tables' : 'job-tables'
      }/${td.ID}/columns`
    : undefined,
});

function TableDefDropdown(
  {
    baseURL,
    placeholder = 'Select Table',
    returnTo,
    tableDefs,
    value,
    onAdd: onAddProp,
    onChange,
    rootOptions,
    dataTables,
    dataTablesLoading,
    ...props
  },
  parentRef
) {
  const goAndReturnTo = useGoAndReturnTo();
  const [{ dialogVisible, prefSource }, setAddState] = useState({
    dialogVisible: false,
    prefSource: null,
  });
  const toOptResolved = useMemo(() => toOpt(baseURL), [baseURL]);
  const sorted = useMemo(
    () => [...tableDefs].sort((t1, t2) => compare(t1.name, t2.name)),
    [tableDefs]
  );
  const jobTableOpts = useMemo(
    () =>
      sorted.filter((t) => t.prefSource !== 'WorkingTable').map(toOptResolved),
    [baseURL, sorted]
  );
  const workingTableOpts = useMemo(
    () =>
      sorted.filter((t) => t.prefSource === 'WorkingTable').map(toOptResolved),
    [baseURL, sorted]
  );
  const onAdd = useMemo(() => {
    if (!onAddProp) return null;
    return (e, { value }) => {
      setAddState({
        prefSource: value === 'WorkingTable' ? 'WorkingTable' : null,
        dialogVisible: true,
      });
    };
  }, [onAddProp]);

  const doAdd = useMemo(() => {
    if (!onAddProp) return null;
    return async (tablePayload, e) => {
      const table = await onAddProp(e, { prefSource, ...tablePayload });
      const opt = toOptResolved(table);
      goAndReturnTo(opt.location, returnTo);
    };
  }, [goAndReturnTo, onAddProp, prefSource, returnTo, toOptResolved]);

  const sections = [
    {
      value: 'JobTable',
      text: 'Job Tables',
      onAdd,
      addLabel: 'Add a job table...',
      options: jobTableOpts,
    },
    {
      value: 'WorkingTable',
      text: 'Working Tables',
      onAdd,
      addLabel: 'Add a working table...',
      options: workingTableOpts,
    },
  ];

  return (
    <>
      <SectionedDropdown
        ref={parentRef}
        placeholder={placeholder}
        returnTo={returnTo}
        sections={sections}
        value={value}
        onChange={onChange}
        rootOptions={rootOptions}
        {...props}
      />
      <AddJobTableDialog
        objType={prefSource === 'WorkingTable' ? 'working table' : 'job table'}
        onClose={() => setAddState({ dialogVisible: false, prefSource: null })}
        onCreate={doAdd}
        visible={dialogVisible}
        dataTables={dataTables}
        dataTablesLoading={dataTablesLoading}
      />
    </>
  );
}

// eslint-disable-next-line no-func-assign
TableDefDropdown = forwardRef(TableDefDropdown);
TableDefDropdown.displayName = 'TableDefDropdown';
export default TableDefDropdown;
