import React from 'react';
import fp from 'lodash/fp';

import { Dropdown } from 'semantic-ui-react';
import { useQuery, useMutation } from '<src>/apollo/client';
import { GetViewer } from '<sections>/projects/queries';
import { SetPrimaryAccount } from '<sections>/account/queries';

import styled from '@emotion/styled';
import * as colors from '<components>/colors';

const Switcher = styled(Dropdown)`
  margin: 40px 0 5px 55px;
  border-bottom: 1px solid ${colors.orange1};
  padding: 3px 0px 13px 5px;
`;

export default function AccountSwitcher() {
  const { data: { viewer } = {} } = useQuery(GetViewer);
  const [setPrimaryAccount] = useMutation(SetPrimaryAccount);

  const onSwitchAccount = (memberOfID, userID) => {
    return setPrimaryAccount({
      variables: { input: { memberID: userID, memberOf: memberOfID } },
    }).then(() => {
      window.location = '/account';
    });
  };

  const accountOps = fp
    .getOr([], 'account.membership', viewer)
    .map(({ account }) => ({
      key: account.ID,
      text: account.display,
      value: account.ID,
    }));

  return (
    <Switcher
      options={accountOps}
      value={fp.getOr(null, 'account.primaryAccount.ID', viewer)}
      placeholder="Select Account"
      onChange={(e, data) => onSwitchAccount(data.value, viewer.account.ID)}
    />
  );
}
