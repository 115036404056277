import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { CardForm } from '../styles';
import * as colors from '<components>/colors';

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      padding: '15px',
      iconColor: colors.gray1b,
      color: colors.gray1b,
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: colors.gray1b,
      },
      '::placeholder': {
        color: colors.gray3,
      },
    },
    invalid: {
      iconColor: colors.gray1b,
      color: colors.red1c,
    },
  },
};

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardForm>
      <CardElement options={CARD_OPTIONS} onChange={onChange} />
    </CardForm>
  </div>
);

export default CardField;
