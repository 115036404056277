import { useCallback, useEffect, useState } from 'react';

import { useLazyQuery } from '<src>/apollo/client';
import { useDebounce } from '<utils>/hooks';

import { ReleaseTagAvailable } from '../../queries';

export default function useCheckReleaseTag(projectID) {
  const [releaseTagInner, setReleaseTag] = useState('');
  const [tagAvailable, setTagAvailable] = useState(null);

  const releaseTag = useDebounce(releaseTagInner, 250);

  const [checkReleaseTag] = useLazyQuery(ReleaseTagAvailable);

  useEffect(() => {
    setTagAvailable(null);
  }, [releaseTagInner]);

  useEffect(() => {
    const doCheck = async () => {
      if (projectID && releaseTag) {
        const { data } = await checkReleaseTag({
          variables: { projectID, releaseTag },
        });
        setTagAvailable(!!data?.releaseTagAvailable);
      }
    };
    doCheck();
  }, [checkReleaseTag, releaseTag, projectID]);

  const onCheckReleaseTag = useCallback(
    (releaseTag) => setReleaseTag(releaseTag),
    []
  );

  return [tagAvailable, onCheckReleaseTag];
}
