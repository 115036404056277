import React, { useContext, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { Dropdown, Ref } from 'semantic-ui-react';

import { Plus, Trash } from '<components>/Icons';

import { Selection } from '../../contexts';
import Cell, { StyledHeaderCell } from '../../Cell';
import Table from '../../Table';

import useAppearTrigger from '../../useAppearTrigger';
import { isFirstOutput } from '../../Widget/util';
import RangeLabel from '../../Widget/RangeLabel';

export const DropdownCell = styled(StyledHeaderCell)`
  & .ui.dropdown .menu > .item {
    font-size: inherit;
  }
`;

const ColumnPicker = styled(Dropdown)`
  width: 100%;
`;

function ColumnControl({ api, widget, colIdx, setAppearTrigger }) {
  if (colIdx === 0) {
    return <Table.ColumnControl />;
  }
  const columns = widget.config.columns;
  const column = columns[colIdx];
  const actions = [];
  if (api.deleteColumn && columns.length > 2) {
    actions.push(
      <Table.Action key="delete">
        <Trash onClick={() => api.deleteColumn(widget, column)} />
      </Table.Action>
    );
  }
  if (api.addColumn) {
    actions.push(
      <Table.Action
        key="add"
        onClick={() => {
          const beforeID = columns[colIdx + 1] ? columns[colIdx + 1].ID : null;
          api.addColumn(widget, {
            beforeID,
            correlationID: setAppearTrigger('edit'),
          });
        }}
      >
        <Plus />
      </Table.Action>
    );
  }
  return (
    <Table.ColumnControl draggable={columns.length > 2} tabIndex={0}>
      {actions}
    </Table.ColumnControl>
  );
}

function RowControl({ api, widget, rowIdx, setAppearTrigger }) {
  const actions = [];
  if (api.deleteRow && widget.rows.length > 1) {
    actions.push(
      <Table.Action key="delete">
        <Trash onClick={() => api.deleteRow(widget, widget.rows[rowIdx])} />
      </Table.Action>
    );
  }
  if (api.addRow) {
    actions.push(
      <Table.Action
        key="add"
        onClick={() => {
          const beforeID = widget.rows[rowIdx + 1]
            ? widget.rows[rowIdx + 1].ID
            : null;
          api.addRow(widget, {
            beforeID,
            correlationID: setAppearTrigger('edit'),
          });
        }}
      >
        <Plus />
      </Table.Action>
    );
  }
  const draggable = api.moveRow && widget.rows.length > 1;

  return (
    <Table.RowControl draggable={draggable} tabIndex={0}>
      {actions}
    </Table.RowControl>
  );
}

function LookupColumn({ widget, api, appearTrigger, column, options }) {
  const ref = useRef();
  useEffect(() => {
    if (appearTrigger.id === column.correlationID) {
      setTimeout(() => ref.current.focus());
    }
  }, [appearTrigger, column]);
  return (
    <DropdownCell>
      <Ref innerRef={ref}>
        <ColumnPicker
          value={column.lookupColumnID}
          options={options}
          selectOnNavigation={false}
          disabled={!api.updateColumn}
          onChange={(e, data) => {
            api.updateColumn(widget, column, { lookupColumnID: data.value });
          }}
        />
      </Ref>
    </DropdownCell>
  );
}

function Header({ api, widget, expanded, onLink, onUpdate }) {
  const [appearTrigger, setAppearTrigger] = useAppearTrigger();
  const options = widget.config.lookupTable
    ? widget.config.lookupTable.columns.map((col) => ({
        text: col.label,
        value: col.ID,
      }))
    : [];
  return (
    <>
      <Table.Columns>
        <Table.ControlColumn />
        {widget.config.columns.map((col) => (
          <Table.Column key={col.ID} />
        ))}
      </Table.Columns>
      <Table.Head>
        <Table.ControlRow>
          <Table.ColumnControl />
          <Table.ColumnControl />
          {widget.config.columns.map((column, colIdx) => (
            <ColumnControl
              key={column.ID}
              api={api}
              widget={widget}
              colIdx={colIdx}
              setAppearTrigger={setAppearTrigger}
            />
          ))}
        </Table.ControlRow>
        <Table.Row>
          <Table.RowControl />
          <StyledHeaderCell />
          {widget.config.columns.map((column, colIdx) => (
            <LookupColumn
              key={column.ID}
              api={api}
              appearTrigger={appearTrigger}
              widget={widget}
              column={column}
              options={options}
            />
          ))}
        </Table.Row>
      </Table.Head>
    </>
  );
}

export function BodyCell({ widget, cell, colIdx, onLink, onUpdate }) {
  const isInput = colIdx === 0;
  return (
    <Cell
      cell={cell}
      isSeparated={isFirstOutput(widget, colIdx)}
      isInput={isInput}
      isOutput={!isInput}
      isEditable={isInput}
      isSource={!isInput}
      isTarget={isInput}
      onLink={onLink}
      onUpdate={onUpdate}
    />
  );
}

export default function WidgetBody({
  api,
  active,
  widget,
  dispatch,
  expanded,
  showNewRow,
}) {
  const [appearTrigger, setAppearTrigger] = useAppearTrigger();
  const { select } = useContext(Selection);

  if (!widget.config.lookupTableID) return null;

  return (
    <Table active={active}>
      <Header
        api={api}
        widget={widget}
        onLink={api.updateCellLink}
        onUpdate={api.updateCellValue}
      />
      <Table.Body>
        {widget.rows.map((row, rowIdx) => (
          <Table.Row key={row.ID} ord={rowIdx}>
            <RowControl
              api={api}
              widget={widget}
              rowIdx={rowIdx}
              setAppearTrigger={setAppearTrigger}
            />
            <RangeLabel
              widget={widget}
              range={row}
              select={select}
              appearTrigger={appearTrigger}
              onUpdate={api.updateRow}
            />
            {row.cells.map((cell, colIdx) => (
              <BodyCell
                key={cell.ID}
                widget={widget}
                cell={cell}
                colIdx={colIdx}
                onLink={api.updateCellLink}
                onUpdate={api.updateCellValue}
              />
            ))}
          </Table.Row>
        ))}
        {/* <NewRow
          key="newRow"
          widget={widget}
          show={showNewRow}
          onAdd={(from, toIdx) =>
            dispatch({
              type: 'add-row',
              after: widget.rows.length - 1,
              from,
              toIdx,
            })
          }
        /> */}
      </Table.Body>
    </Table>
  );
}
