import React from 'react';
import { useQuery, useMutation } from '<src>/apollo/client';

import { RunJob, CloneJob } from '<sections>/jobs/queries';

import AddFunctionDialog from '../AddFunctionDialog';
import AddJobDialog from '../AddJobDialog';
import AddFlowDialog from '../AddFlowDialog';
import List from './List';

import {
  GetProjectFunctionsV3,
  CloneFunction,
  DeleteFuncV3, // TODO move this after retiring v1/2 functions
  GetProjectJobs,
  DeleteJob,
  GetProjectFlows,
  DeleteFlow,
  CloneFlow,
} from '../../../../queries';

export const ConnectFlowList = ({ projectID, history, objType }) => {
  const {
    data: { project } = {},
    loading,
    refetch,
  } = useQuery(GetProjectFlows, {
    variables: { ID: projectID },
    fetchPolicy: 'network-only',
  });

  const items = loading ? [] : project.flows;

  const [cloneFlow] = useMutation(CloneFlow);
  const [removeItem] = useMutation(DeleteFlow);

  const onRemoveItem = (resourceID) =>
    removeItem({
      variables: { flowID: resourceID },
      update: (cache, { data }) => {
        const { project } = cache.readQuery({
          query: GetProjectFlows,
          variables: { ID: projectID },
        });
        const newProject = {
          ...project,
          flows: project.flows.filter((f) => f.ID !== resourceID),
        };
        cache.writeQuery({
          query: GetProjectFlows,
          variables: { ID: projectID },
          data: { project: newProject },
        });
      },
    });

  const onCloneItem = (itemID) =>
    cloneFlow({
      variables: {
        input: {
          flowID: itemID,
        },
      },
      update: (cache, { data: { cloneFlow } }) => {
        const { project } = cache.readQuery({
          query: GetProjectFlows,
          variables: { ID: projectID },
        });
        const newProject = {
          ...project,
          flows: [...project.flows, cloneFlow],
        };
        cache.writeQuery({
          query: GetProjectFlows,
          variables: { ID: projectID },
          data: { project: newProject },
        });
      },
    });

  return (
    <List
      {...{
        history,
        loading,
        projectID,
        project,
        refetch,
        items,
        cloneItem: onCloneItem,
        removeItem: onRemoveItem,
        AddDialog: AddFlowDialog,
        objType,
      }}
    />
  );
};

export const ConnectFunctionV3List = ({ projectID, objType }) => {
  const {
    data: { project } = {},
    loading,
    refetch,
  } = useQuery(GetProjectFunctionsV3, {
    variables: { ID: projectID },
  });

  const items = loading ? [] : project.functionsV3;

  const [cloneFunc] = useMutation(CloneFunction);
  const [deleteFunc] = useMutation(DeleteFuncV3);

  const onCloneItem = (itemID, targetProjectID) =>
    cloneFunc({
      variables: { input: { funcID: itemID, projectID: targetProjectID } },
      update: (cache, { data }) => {
        if (!data.error) {
          if (projectID === targetProjectID) {
            const { project } = cache.readQuery({
              query: GetProjectFunctionsV3,
              variables: { ID: projectID },
            });
            const newProject = {
              ...project,
              functionsV3: [...project.functionsV3, data.cloneFuncV3],
            };
            cache.writeQuery({
              query: GetProjectFunctionsV3,
              variables: { ID: projectID },
              data: { project: newProject },
            });
          }
        }
      },
    });
  const onRemoveItem = (resourceID) =>
    deleteFunc({
      variables: { ID: resourceID },
      update: (cache, { data }) => {
        if (data.deleteFuncV3.success) {
          const { project } = cache.readQuery({
            query: GetProjectFunctionsV3,
            variables: { ID: projectID },
          });
          const newProject = {
            ...project,
            functionsV3: project.functionsV3.filter((f) => f.ID !== resourceID),
          };
          cache.writeQuery({
            query: GetProjectFunctionsV3,
            variables: { ID: projectID },
            data: { project: newProject },
          });
        }
      },
    });

  return (
    <List
      {...{
        projectID,
        loading,
        items,
        refetch,
        project,
        cloneItem: onCloneItem,
        removeItem: onRemoveItem,
        AddDialog: AddFunctionDialog,
        readOnly: project && project.readOnly,
        objType,
      }}
    />
  );
};

export const ConnectJobList = ({ projectID, history, objType }) => {
  const {
    data: { project } = {},
    loading,
    refetch,
  } = useQuery(GetProjectJobs, {
    variables: { ID: projectID },
    fetchPolicy: 'network-only',
  });
  const items = loading ? [] : project.jobs;

  const [cloneJob] = useMutation(CloneJob);
  const [deleteJob] = useMutation(DeleteJob);
  const [runJob] = useMutation(RunJob);

  const onCloneItem = (itemID) =>
    cloneJob({
      variables: { jobID: itemID },
      update: (cache, { data: { cloneJob } }) => {
        const { project } = cache.readQuery({
          query: GetProjectJobs,
          variables: { ID: projectID },
        });
        const newProject = {
          ...project,
          jobs: [...project.jobs, cloneJob],
        };
        cache.writeQuery({
          query: GetProjectJobs,
          variables: { ID: projectID },
          data: { project: newProject },
        });
      },
    });
  const onRemoveItem = (itemID) =>
    deleteJob({
      variables: { jobID: itemID },
      update: (cache, { data }) => {
        const { project } = cache.readQuery({
          query: GetProjectJobs,
          variables: { ID: projectID },
        });
        const newProject = {
          ...project,
          jobs: project.jobs.filter((j) => j.ID !== itemID),
        };
        cache.writeQuery({
          query: GetProjectJobs,
          variables: { ID: projectID },
          data: { project: newProject },
        });
      },
    });

  return (
    <List
      {...{
        history,
        projectID,
        loading,
        items,
        refetch,
        project,
        cloneItem: onCloneItem,
        removeItem: onRemoveItem,
        runJob,
        AddDialog: AddJobDialog,
        objType,
      }}
    />
  );
};
