import React, { useCallback, useRef, useState } from 'react';

import ColumnTypePicker from '../ColumnTypePicker';
import Button from '<src>/components/Button';
import { ConfigField as ConfigFieldStyle } from '<src>/components/NumbrzVerticalEditor';
import { parseUserValue } from '<sections>/functions/Cell/util';

const ConfigField = ConfigFieldStyle.withComponent('input');

export default function AddVariable({ onAddVariable, variableTypeOptions }) {
  const [value, setValue] = useState('');
  const [label, setLabel] = useState('');
  const [type, setType] = useState();
  const [comment, setComment] = useState('');
  const labelRef = useRef();
  const valueRef = useRef();
  const typeRef = useRef();
  const commentRef = useRef();
  const addRef = useRef();

  const addVariable = useCallback(
    async (e) => {
      if (!value || !label || !type) {
        return;
      }
      await onAddVariable(e, {
        defaultVal: parseUserValue(value, { ms: true }).value,
        toElement: { type, label, comment },
      });
      setValue('');
      setLabel('');
      setType(null);
      setComment('');
    },
    [value, type, label, comment, onAddVariable]
  );

  const onKeyDown = useCallback(
    async (e) => {
      if (e.key === 'Enter') {
        e.stopPropagation();
        if (!label) {
          labelRef.current.focus();
        } else if (!value) {
          valueRef.current.focus();
        } else if (!type) {
          typeRef.current.focus();
        } else {
          await addVariable(e);
          labelRef.current.focus();
        }
      }
    },
    [addVariable, value, label, type]
  );

  return (
    <tr>
      <td>
        <div>
          <ConfigField
            ref={labelRef}
            value={label}
            placeholder="New Variable"
            onFocus={(e) => {
              e.target.placeholder = '';
            }}
            onBlur={(e) => {
              e.target.placeholder = 'New Variable';
            }}
            onChange={(e) => setLabel(e.target.value)}
            onKeyDown={onKeyDown}
          />
        </div>
      </td>
      <td>
        <div>
          <ConfigField
            ref={valueRef}
            value={value}
            placeholder="Value"
            onFocus={(e) => {
              e.target.placeholder = '';
            }}
            onBlur={(e) => {
              e.target.placeholder = 'Value';
            }}
            onChange={(e) => {
              setValue(e.target.value);
              let dataType = parseUserValue(e.target.value).dataType;
              if (dataType === 'Bool') dataType = 'Boolean';
              if (dataType === 'Date') dataType = 'DateTime';
              setType(dataType);
            }}
            onKeyDown={onKeyDown}
          />
        </div>
      </td>
      <td className="type">
        <ColumnTypePicker
          ref={typeRef}
          type={type}
          onChange={(e, { type }) => setType(type)}
          columnTypeOptions={variableTypeOptions}
          onClose={(e) => {
            if (e && e.type === 'keydown' && e.key === 'Enter') {
              commentRef.current.focus();
            }
          }}
        />
      </td>
      <td>
        <ConfigField
          ref={commentRef}
          value={comment}
          placeholder="Note"
          onChange={(e) => setComment(e.target.value)}
          onKeyDown={onKeyDown}
        />
      </td>
      <td className="btn">
        <Button.TableAddBtn
          ref={addRef}
          disabled={!type || !label}
          onClick={(e) => {
            if (e.type === 'click') addVariable(e);
          }}
          onKeyDown={onKeyDown}
        />
      </td>
    </tr>
  );
}
