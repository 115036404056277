import { gql } from '@apollo/client';

import { FunctionChangesFragment } from './fragments';
import useEditFunction from './useEditFunction';

const DeleteWidgetRow = gql`
  mutation DeleteWidgetRow($funcID: CommonID!, $widgetID: ID!, $rowID: ID!) {
    deleteWidgetRowV3(funcID: $funcID, widgetID: $widgetID, rowID: $rowID) {
      ...FunctionChangesFragment
    }
  }

  ${FunctionChangesFragment}
`;

export default function useAddWidgetRow() {
  const deleteRow = useEditFunction(DeleteWidgetRow, 'deleteWidgetRowV3');
  return (widget, row) =>
    deleteRow({
      variables: {
        funcID: widget.funcID,
        widgetID: widget.ID,
        rowID: row.ID,
      },
    });
}
