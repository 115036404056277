import React from 'react';

import Button from '<components>/Button';

import Dialog from '<components>/Dialog';
import moment from 'moment';

import styled from '@emotion/styled';

const StyledDialog = styled(Dialog)`
  min-width: 300px;
  width: 40%;
`;

const ChangePlanDialog = ({
  visible,
  onClose,
  onCancelPlan,
  mode,
  billingCycleEndDate,
}) => {
  return (
    <StyledDialog onRequestClose={onClose} visible={visible ? 1 : 0}>
      <Dialog.Header>
        <Dialog.Headline>Confirm Subscription Cancellation</Dialog.Headline>
        <Button.DialogClose onClick={() => onClose()} />
      </Dialog.Header>
      <Dialog.Body>
        <p>
          {mode && mode === 'update'
            ? `This action will remove your failed subscription and create a new one. You will not be charged for the failed subscription. If you would like to proceed click "Confirm" below.`
            : `After you cancel, your subscription will remain active until ${moment
                .unix(billingCycleEndDate)
                .format(
                  'MMM DD'
                )}. After your subscription end date, your account will remain active with limited features. Personal accounts will default to our 'Personal Small' plan. Team accounts will default to our 'Team - Build Only' plan. If you would like to delete your account and all its content, please contact `}
          <a href="mailto:support@numbrz.com">support@numbrz.com</a>.
        </p>
        <Button
          style={{ marginTop: '20px' }}
          onClick={() => {
            onClose();
            onCancelPlan(mode);
          }}
        >
          {mode && mode === 'update' ? 'Confirm' : 'Cancel Plan'}
        </Button>
      </Dialog.Body>
    </StyledDialog>
  );
};

export default ChangePlanDialog;
