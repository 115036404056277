import React, { Fragment } from 'react';

import { StyledTextInput } from '<src>/sections/data/styles';

export default function NumbrzDatabasePane({
  children: renderBody,
  tablesetName,
  setTablesetName,
  inputDisabled,
}) {
  return renderBody(
    <Fragment>
      <StyledTextInput
        placeholder="Numbrz Database Name"
        name="name"
        onChange={(e) => {
          e.stopPropagation();
          setTablesetName(e.target.value);
        }}
        disabled={inputDisabled}
        value={tablesetName}
      />
    </Fragment>,
    false
  );
}
