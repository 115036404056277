import React, { Fragment } from 'react';
import fp from 'lodash/fp';
import orderBy from 'lodash/orderBy';

import Button from '<src>/components/Button';
import WaveSpinner from '<src>/components/WaveSpinner';
import { tableLabel } from '../util';
import NumbrzEntityList from '<components>/NumbrzEntityList';

export default function TableList({
  baseURL,
  tablesetState,
  setConnectDialogVisible,
}) {
  const {
    tables = [],
    loadingTables,
    tableAPI,
    handleAddNumbrzTable,
    tableset,
  } = tablesetState;
  const { onDelete } = tableAPI;
  const { source: tablesetSource } = tableset;

  const tablesHeader = tableLabel(tablesetSource, {
    plural: true,
    titleCase: false,
  });

  return loadingTables ? (
    <WaveSpinner />
  ) : (
    <Fragment>
      <NumbrzEntityList
        items={orderBy(tables, ['name'], ['asc'])}
        entityType="data-table"
        onDeleteItem={(e, item) => {
          return onDelete({
            variables: {
              ID: item.ID,
              source: fp.getOr('', 'source.__typename', item),
            },
          });
        }}
      />
      <Button.ListPageCreateBtn
        onClick={
          tablesetSource.type === 'nbz'
            ? handleAddNumbrzTable
            : () => setConnectDialogVisible(true)
        }
        title="Add a table"
      >
        {tablesetSource.type === 'nbz'
          ? `Create a table`
          : `Connect ${tablesHeader}`}
        <span className="icon">+</span>
      </Button.ListPageCreateBtn>
    </Fragment>
  );
}
