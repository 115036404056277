import React, { useState, useCallback } from 'react';
import styled from '@emotion/styled';
import { Input, Message } from 'semantic-ui-react';

import { isEmpty } from '<src>/utils/text';

const StyledInput = styled(Input)`
  width: 100%;
  & div.label {
    width: 25%;
  }
  margin-bottom: 6px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export default function SnowflakeSpreadsheetPane({
  onTablesetCreated,
  children: renderBody,
  createCredential,
  credential,
}) {
  const [state, setState] = useState({
    username: '',
    password: '',
    error: null,
    connecting: false,
  });

  const { connecting, username, password } = state;

  const createNewCredential = useCallback(async () => {
    // if credential exists, then we are reauthorizing
    setState({ ...state, connecting: true });
    await createCredential({
      variables: {
        input: {
          ...(!credential && { name: `Snowflake` }),
          ...(credential && { ID: credential.ID }),
          provider: {
            providerName: 'snowflake',
            credName: 'password',
            credParams: {
              username,
              password,
            },
          },
        },
      },
    });
    setState({ ...state, connecting: false });
  }, [createCredential, username, password, state, credential]);

  const handleChange = (event) => {
    const {
      target: { name, value },
    } = event;

    setState({ ...state, [name]: value });
  };

  const valid = () => {
    return !isEmpty(username) && !isEmpty(password);
  };

  return renderBody(
    <div>
      <Message info>
        Contact your Snowflake administrator for these values.
      </Message>

      <StyledInput
        size="small"
        label="User Name"
        name="username"
        placeholder="Enter User Name (required)"
        value={username}
        onChange={handleChange}
      />
      <StyledInput
        size="small"
        label="Password"
        name="password"
        placeholder="Enter Password (required)"
        value={password}
        type="password"
        onChange={handleChange}
      />
    </div>,
    valid() ? createNewCredential : undefined,
    connecting
  );
}
