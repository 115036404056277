/** @jsx jsx */
/** @jsxRuntime classic */

import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { jsx } from '@emotion/react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import shortid from '<src>/utils/shortid';

import Alert from './Alert';

import * as styles from './style';

const AddAlertCtx = createContext(() => {});

export function useAddSystemAlert() {
  return useContext(AddAlertCtx);
}

export default function SystemAlerts({
  alerts: alertsProp,
  onDismiss = () => {},
  children,
}) {
  const [alerts, setAlerts] = useState(alertsProp || []);

  useEffect(() => {
    if (alertsProp !== undefined) {
      setAlerts(alertsProp);
    }
  }, [alertsProp]);

  const addAlert = (alert) => {
    if (typeof alert === 'string') {
      alert = { message: alert };
    }
    if (typeof alert !== 'object') {
      throw new TypeError('SystemAlert must be string or object');
    }

    alert = { ID: shortid(), level: 'info', ...alert };
    setAlerts((alerts) => [alert, ...alerts]);
  };

  const removeAlert = useCallback(
    ({ ID }) => {
      if (alertsProp !== undefined) {
        onDismiss({ ID });
      } else {
        setAlerts((alerts) => alerts.filter((a) => a.ID !== ID));
      }
    },
    [alertsProp, onDismiss]
  );
  return (
    <AddAlertCtx.Provider value={addAlert}>
      <TransitionGroup css={styles.alerts}>
        {alerts.length > 0 ? (
          <CSSTransition
            classNames={{
              appear: styles.animated.toString(),
              enter: styles.animated.toString(),
              exit: styles.animated.toString(),
            }}
            timeout={240}
          >
            <Alert alert={alerts[0]} remove={removeAlert} />
          </CSSTransition>
        ) : null}
      </TransitionGroup>
      {children}
    </AddAlertCtx.Provider>
  );
}
