import React, { useCallback, useState } from 'react';
import { useDrop } from 'react-dnd';

import { Selection } from './contexts';
import useAppearTrigger from './useAppearTrigger';

const noneItem = { type: Selection.NONE };

function itemsEqual(item1 = noneItem, item2 = noneItem) {
  return item1.ID === item2.ID && item1.type === item2.type;
}
function isSelectableItem(item) {
  return item && item.type && item.ID;
}

function itemFromElement(el) {
  if (el) {
    const { id: ID, type, correlationid: correlationID } = el.dataset;
    if (type) {
      return { type, ID, correlationID, target: el };
    }
  }
  return { ...noneItem, target: el };
}

function addParentItems(item, activeItems) {
  if (item.type !== Selection.NONE && !activeItems[item.type]) {
    activeItems[item.type] = item;
  }
  if (item.target) {
    addParentItems(itemFromElement(item.target.parentElement), activeItems);
  }
}

export default function SelectionManager(props) {
  const [appearTrigger, setAppearTrigger] = useAppearTrigger();
  const [{ selection, activeItems }, setState] = useState({
    selection: { ...noneItem },
    activeItems: {},
  });
  const select = useCallback(
    (newItem) =>
      setState((state) => {
        if (
          newItem.type !== Selection.NONE &&
          itemsEqual(newItem, state.selection)
        ) {
          return state;
        }
        if (newItem.type !== Selection.NONE && !newItem.target) {
          newItem = {
            ...newItem,
            target: document.querySelector(
              `[data-id="${newItem.ID}"][data-type="${newItem.type}"]`
            ),
          };
        }
        const newActiveItems = {};
        addParentItems(newItem, newActiveItems);
        const change =
          Object.keys(newActiveItems).length === 0 ||
          !Object.keys(newActiveItems).every((type) =>
            itemsEqual(newActiveItems[type], state.activeItems[type])
          );
        if (change) {
          return { selection: newItem, activeItems: newActiveItems };
        }

        return state;
      }),
    []
  );
  const [, dropRef] = useDrop({
    accept: Selection.CELL,
    drop: (_, monitor) => {
      if (monitor.didDrop()) {
        const targetItem = monitor.getDropResult();
        if (isSelectableItem(targetItem)) {
          select(targetItem);
        }
      }
    },
  });

  return (
    <Selection.Provider
      value={{
        selection,
        activeItems,
        select,
        appearTrigger,
        setAppearTrigger,
      }}
    >
      {/* Watch for bubbled-up mouseup events */}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        ref={dropRef}
        {...props}
        onMouseUp={(e) => {
          const { target } = e;
          const item = itemFromElement(target);
          select(item);
        }}
      />
    </Selection.Provider>
  );
}
