import { css } from '@emotion/react';

export const alerts = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 102;
`;

export const container = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  transition: transform 240ms ease-in;
`;

export const alert = css`
  border: 0 1px 1px 1px solid;
  background: white;
  padding: 3px 8px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  color: rgb(10, 10, 10);
  border-color: rgb(10, 10, 10);
  white-space: nowrap;
`;

export const dismiss = css`
  display: inline-block;
  background: none;
  margin: 0 0 0 6px;
  padding: 0 0 0 6px;
  border: none;
  border-left: 1px solid rgba(10, 10, 10, 0.5);
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 120ms ease-in;

  &:hover,
  &:focus {
    opacity: 1;
  }
`;

/*
----------------------------------------
  Success
----------------------------------------
*/
export const success = css`
  background: rgb(229, 249, 231);
  color: rgb(30, 188, 48);
  border-color: rgb(30, 188, 48);

  & .dismissable {
    border-color: rgba(30, 188, 48, 0.5);
    & path {
      fill: rgb(30, 188, 48);
    }
  }
`;

/*
----------------------------------------
  Info
----------------------------------------
*/

export const info = css`
  background: rgb(223, 240, 255);
  color: rgb(33, 133, 208);
  border-color: rgb(33, 133, 208);

  & .dismissable {
    border-color: rgba(33, 133, 208, 0.5),

    & path: {
      fill: rgb(33, 133, 208),
    }
  }
`;

/*
----------------------------------------
  Warning
----------------------------------------
*/

export const warning = css`
  background: rgb(255, 237, 222);
  color: rgb(242, 113, 28);
  border-color: rgb(242, 113, 28);

  & .dismissable {
    bordercolor: rgba(242, 113, 28, 0.5);
    & path {
      fill: rgb(242, 113, 28);
    }
  }
`;

/*
----------------------------------------
  Error
----------------------------------------
*/
export const error = css`
  background: rgb(255, 232, 230);
  color: rgb(219, 40, 40);
  border-color: rgb(219, 40, 40);

  & .dismissable {
    border-color: rgba(219, 40, 40, 0.5);

    & path {
      fill: rgb(219, 40, 40);
    }
  }
`;

/*
----------------------------------------
  Animations
----------------------------------------
*/
export const animated = css`
  transform: translateY(calc(-100% - 3px));
`;
