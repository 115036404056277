/** @jsx jsx */
/** @jsxRuntime classic */
import ErrorPopup from './components/ErrorPopup/ErrorPopup';
import { jsx } from '@emotion/react';
import { useState } from 'react';
import * as styles from './styles';

const defaultStepLabels = {
  AggregateStep: 'Aggregate Step',
  DataV2OutputStep: 'Output Step',
  ExecFormulaStep: 'Execution Step',
  FillDataStep: 'Fill Data Step',
  BufferOutputStep: 'Working Table Output Step',
};

export default function StepDetails({ stepStatus, stageURL, idx }) {
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);

  if (!stepStatus) {
    // This should probably never happen
    return (
      <tr>
        <td>{`Step ${idx}`}</td>
        <td>N/A</td>
        <td>N/A</td>
        <td>N/A</td>
        <td>N/A</td>
      </tr>
    );
  }

  const isOutputStep =
    stepStatus.stepType === 'DataV2OutputStep' ||
    stepStatus.stepType === 'BufferOutputStep';

  let title = `Step ${idx}`;
  if (stepStatus.title) {
    title = stepStatus.title;
  } else if (stepStatus.stepType in defaultStepLabels) {
    title = defaultStepLabels[stepStatus.stepType];
  }

  return (
    <tr>
      <td>
        {stageURL ? (
          <styles.StyledLink
            to={`${stageURL}#${stepStatus.key}`}
            target="_blank"
            rel="noopener noreferrer"
            title="Navigate to flow step"
          >
            {title}
          </styles.StyledLink>
        ) : (
          <span>{title}</span>
        )}
      </td>
      <td>{stepStatus.inputCount}</td>
      <td>{stepStatus.outputCount}</td>
      <td>
        {isOutputStep &&
          stepStatus.processedCount !== undefined &&
          stepStatus.processedCount}
      </td>

      <td className="centered">
        {stepStatus.failures.length ? (
          <ErrorPopup
            errors={stepStatus.failures}
            setVisible={setErrorDialogVisible}
            visible={errorDialogVisible}
          />
        ) : null}
      </td>
    </tr>
  );
}
