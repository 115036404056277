import React from 'react';

import {
  SectionHdr,
  Separator,
  TwoColSection,
  ContentHdr,
} from '<sections>/onboarding/styles';

import WelcomeScreen, { HelpReminder } from '../components/WelcomeScreen';
import OnboardingVideos from '../videos';

export default function DataWelcomeScreen({ onHide, onVideoClick }) {
  const videos = OnboardingVideos.data;

  return (
    <WelcomeScreen onHide={onHide} sectionLabel={'data'}>
      <SectionHdr>
        <span className="underline">Data</span> Overview
      </SectionHdr>
      {videos
        .filter((v) => v.section === 'overview')
        .map((v, idx) => (
          <TwoColSection key={idx} onClick={(e) => onVideoClick(v)}>
            <div className="col">{v.title}</div>
            <div className="video">{v.thumbnail}</div>
          </TwoColSection>
        ))}

      <Separator />
      <HelpReminder />
      <Separator />
      <ContentHdr>
        <span className="underline">How to</span> Connect Data
      </ContentHdr>
      {videos
        .filter((v) => v.section === 'build')
        .map((v, idx) => (
          <TwoColSection key={idx} onClick={(e) => onVideoClick(v)}>
            <div className="col">{v.title}</div>
            <div className="video">{v.thumbnail}</div>
          </TwoColSection>
        ))}

      <Separator />
      <ContentHdr>
        <span className="underline">Key</span> Concepts
      </ContentHdr>
      {videos
        .filter((v) => v.section === 'concepts')
        .map((v, idx) => (
          <TwoColSection key={idx} onClick={(e) => onVideoClick(v)}>
            <div className="col">{v.title}</div>
            <div className="video">{v.thumbnail}</div>
          </TwoColSection>
        ))}
    </WelcomeScreen>
  );
}
