import React, { useState, Fragment, useCallback } from 'react';
import { useQuery, useMutation } from '<src>/apollo/client';
import fp from 'lodash/fp';
import { TextIconBtn } from '<src>/sections/jobs/styles';
import IntegrationDialog from '../Integrations/IntegrationDialog';
import User from './User';
import {
  PageWrapper,
  UnderlineHeader,
  AppTab,
  AppTabMenu,
  EmptyMsg,
} from '../Billing/styles';
import { RoundedContentWrapper } from '<components>/NumbrzVerticalEditor';

import {
  GetCredentials,
  RemoveCredential,
  UpdateCredential,
} from '../../queries';
import { groupBy } from '<src>/utils/arrays';
import UnderlinedHeader from '<components>/UnderlinedHeader';
import { FlexColumnContainer } from '<src>/components/NumbrzPageComponents';

export default function Integrations() {
  const [dialogVisible, setDialogVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('google');
  const [activeCred, setActiveCred] = useState();

  const { data, loading } = useQuery(GetCredentials, { variables: {} });
  const [updateCredential] = useMutation(UpdateCredential);

  const [removeCredential] = useMutation(RemoveCredential, {
    update: (cache, { data: { removeCredential } }) => {
      const { listCredentials } = cache.readQuery({
        query: GetCredentials,
      });
      const { ID } = removeCredential;

      cache.writeQuery({
        query: GetCredentials,
        data: {
          listCredentials: listCredentials.filter((c) => c.ID !== ID),
        },
      });
    },
  });

  const credentials = loading ? [] : data.listCredentials;
  const groupedCreds = groupBy(credentials, 'providerName');

  const tabs = [
    { key: 'google', label: 'Google' },
    { key: 'microsoft', label: 'Microsoft' },
    { key: 'dropbox', label: 'DropBox' },
    { key: 'quickbooks', label: 'QuickBooks Online' },
    { key: 'snowflake', label: 'Snowflake' },
  ];

  const handleRemoveCred = useCallback(
    async (ID) => {
      await removeCredential({ variables: { ID } });
    },
    [removeCredential]
  );

  const users = fp.getOr([], activeTab, groupedCreds);

  return (
    <Fragment>
      <PageWrapper maxWidth="900px" style={{ paddintTop: '18.5px' }}>
        <UnderlineHeader style={{ paddingTop: '8.5px', height: '49px' }}>
          <AppTabMenu
            value={activeTab}
            onChange={(e, value) => setActiveTab(value)}
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabs.map((t) => (
              <AppTab value={t.key} label={t.label} />
            ))}
          </AppTabMenu>
        </UnderlineHeader>
        <RoundedContentWrapper
          margin="0 0 0 30px"
          width="unset"
          minWidth="700px"
        >
          <FlexColumnContainer alignItems="flex-start" width="100%">
            <UnderlinedHeader justifyContent="space-between">
              <span>USERS</span>
              <TextIconBtn
                onClick={() => {
                  setActiveCred(undefined);
                  setActiveTab(activeTab);
                  setDialogVisible(true);
                }}
              >
                Add new
              </TextIconBtn>
            </UnderlinedHeader>
            {users.length === 0 && <EmptyMsg>No users found</EmptyMsg>}
            {users.map((cred) => {
              return (
                <User
                  cred={cred}
                  onRemove={handleRemoveCred}
                  setActiveCred={setActiveCred}
                  setDialogVisible={setDialogVisible}
                  onUpdate={updateCredential}
                />
              );
            })}
          </FlexColumnContainer>
        </RoundedContentWrapper>
      </PageWrapper>
      <IntegrationDialog
        credential={activeCred}
        setActiveCred={setActiveCred}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        visible={dialogVisible}
        setDialogVisible={setDialogVisible}
        onDialogClose={() => setDialogVisible(false)}
      />
    </Fragment>
  );
}
