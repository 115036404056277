import React, { useContext } from 'react';
import styled from '@emotion/styled';

import { Plus, Trash } from '<components>/Icons';

import { Selection } from '../contexts';

import AnimatedResize from '../AnimatedResize';
import Card from '../Card';
import Cell from '../Cell';
import CellSettingsPane from '../CellSettings';
import ColumnSettings from '../ColumnSettings';
import ConfigButton from '../ConfigButton';
import Table from '../Table';

import useAppearTrigger from '../useAppearTrigger';
import useExpanded from '../useExpanded';
import useGetCachedItem from '../gql/useGetCachedItem';
import useSelectable from '../useSelectable';
import {
  useAddCellTemplate,
  useDeleteCellTemplate,
  useUpdateCellTemplate,
  useUpdateDefaultFormat,
  useUpdateDefaultValue,
  useUpdateValueSource,
} from '../gql';
import UnderlinedHeader from '<src>/components/UnderlinedHeader';

const labels = {
  inputs: 'Inputs',
  outputs: 'Outputs',
  params: 'Parameters',
};

const Body = styled('div')`
  & > div {
    display: flex;
    flex-direction: column;
    align-content: center;
  }
`;

function ParamSettings({
  funcID,
  section,
  updateCellTemplate,
  updateDefaultFormat,
}) {
  const { activeItems } = useContext(Selection);

  const item =
    activeItems.PARAMS && activeItems.PARAMS.ID === section
      ? activeItems.CELL_TEMPLATE
      : null;
  const param = useGetCachedItem(funcID, item);
  let cell = null;
  if (param) {
    let coerceTo = 'Any';
    if (param.restrictTypes && param.restrictTypes.length > 0) {
      coerceTo = param.restrictTypes[0];
    }
    cell = {
      ...param,
      value: param.defaultValue,
      coerceTo,
      format: param.defaultFormat,
    };
  }

  return (
    <div>
      <UnderlinedHeader uppercase>Cell Settings</UnderlinedHeader>
      <CellSettingsPane
        cell={cell}
        editable
        setType={updateCellTemplate}
        setFormat={updateDefaultFormat}
      />
    </div>
  );
}

export default function ParamSet({ section, params, forceActive, funcID }) {
  const item = {
    type: useSelectable.PARAMS,
    ID: section,
  };

  const [selectionRef, , act] = useSelectable(item);
  const active = forceActive || act;
  const [appearTrigger] = useAppearTrigger();
  const [expanded, setExpanded] = useExpanded(section);
  const updateCellTemplate = useUpdateCellTemplate();
  const updateDefaultFormat = useUpdateDefaultFormat();
  const updateDefaultValue = useUpdateDefaultValue();
  const updateValueSource = useUpdateValueSource();
  const addCellTemplate = useAddCellTemplate();
  const deleteCellTemplate = useDeleteCellTemplate();

  const isInput = section !== 'outputs';

  const { select } = useContext(Selection);

  return (
    <Card ref={selectionRef} active={act}>
      <Card.Header>
        <h4>
          <span>{labels[section]}</span>
          <ConfigButton
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
          />
        </h4>
      </Card.Header>
      <AnimatedResize expanded={expanded}>
        <ParamSettings
          section={section}
          funcID={funcID}
          updateCellTemplate={updateCellTemplate}
          updateDefaultFormat={updateDefaultFormat}
        />
      </AnimatedResize>
      <Body>
        <Table active={active}>
          <Table.Columns>
            <Table.ControlColumn />
            <Table.Column />
            <Table.Column />
          </Table.Columns>
          <Table.Body>
            {params.map((param, idx) => (
              <Table.Row key={param.ID}>
                <Table.RowControl draggable={params.length > 1} tabIndex={0}>
                  {params.length > 1 ? (
                    <Table.Action
                      onClick={() =>
                        deleteCellTemplate({
                          funcID: param.funcID,
                          templateID: param.ID,
                        })
                      }
                    >
                      <Trash />
                    </Table.Action>
                  ) : null}
                  <Table.Action
                    onClick={() => {
                      const beforeID = params[idx + 1]
                        ? params[idx + 1].ID
                        : null;
                      addCellTemplate({
                        funcID: param.funcID,
                        section: param.section,
                        beforeID,
                      });
                    }}
                  >
                    <Plus />
                  </Table.Action>
                </Table.RowControl>
                <ColumnSettings
                  appearTrigger={appearTrigger}
                  column={param}
                  custom
                  expanded={expanded}
                  onConfirm={() => {
                    if (isInput) {
                      select({
                        type: useSelectable.CELL_TEMPLATE,
                        id: param.ID,
                      });
                      // } else {
                      //   if (cIdx === params.length - 1) {
                      //     addCellTemplate({
                      //       funcID: param.funcID,
                      //       type: param.type,
                      //       correlationID: setAppearTrigger('edit'),
                      //     });
                      //   }
                    }
                  }}
                  onRemove={() =>
                    deleteCellTemplate({
                      funcID: param.funcID,
                      templateID: param.ID,
                    })
                  }
                  onSetLabel={(label) => updateCellTemplate(param, { label })}
                />
                <Cell
                  cell={{
                    ID: param.ID,
                    funcID: param.funcID,
                    dataType: param.dataType,
                    value: param.defaultValue,
                    format: param.defaultFormat,
                    type: useSelectable.CELL_TEMPLATE,
                    status: param.status,
                  }}
                  sourceType={param.section}
                  isEditable
                  isInput={isInput}
                  isOutput={!isInput}
                  isSource={isInput}
                  isTarget={!isInput}
                  onLink={(_, { sourceID, sourceType }) => {
                    updateValueSource(param, {
                      sourceID,
                      sourceType,
                    });
                  }}
                  onUpdate={(_, defaultValue) => {
                    updateDefaultValue(param, defaultValue);
                  }}
                  // onConfirm={() => {
                  //   if (cIdx === params.length - 1) {
                  //     select({ type: 'NONE' });
                  //     const correlationID = onAppear('edit');
                  //     addCellTemplate({
                  //       funcID: param.funcID,
                  //       type: param.type,
                  //       correlationID,
                  //     });
                  //   }
                  // }}
                />
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Body>
    </Card>
  );
}
