import React, { Fragment, useCallback, useState } from 'react';
import { useOAuth } from '<sections>/oauth';
import { Tab } from 'semantic-ui-react';
import EditableMarkdown from '<components>/EditableMarkdown';
import GooglePreFlightDialog from '<sections>/account/pages/Integrations/GooglePreFlightDialog';

function defaultTab(credential = {}, connectorName) {
  if (connectorName === 'bigquery') return 1;
  switch (credential.type) {
    case 'Oauth2':
      return 0;
    case 'ServiceAcct':
      return 1;
    default:
      return 0;
  }
}

export default function GoogleSpreadsheetPane({
  children: renderBody,
  createCredential,
  credential,
  connectorName = 'gsheets',
}) {
  const [preflightDialogVisible, setPreflightDialogVisible] = useState(false);

  const [creating, setCreating] = useState(false);
  const [serviceAcct, setServiceAcct] = useState();
  const [activeTab, setActiveTab] = useState(
    defaultTab(credential, connectorName)
  );

  const tabPanes = [
    {
      menuItem: 'OAuth',
      render: () => (
        <Tab.Pane>Authenticate user using Oauth provider.</Tab.Pane>
      ),
    },
    {
      menuItem: 'Service Account',
      render: () => (
        <Tab.Pane>
          <EditableMarkdown
            maxHeight="300px"
            label={''}
            content={serviceAcct}
            editable={true}
            onSave={(data) => setServiceAcct(data)}
            placeholder={'Enter service account information (*Required)'}
          />
        </Tab.Pane>
      ),
    },
  ];

  const createNewCredential = useCallback(
    async ({ code, scope, redirectURL } = {}) => {
      setCreating(true);
      // Determine auth type to submit
      const credPayload =
        serviceAcct && activeTab === 1
          ? {
              credName: 'svcAccount',
              credParams: {
                data: serviceAcct,
              },
            }
          : {
              credName: 'oauthCode',
              credParams: {
                code,
                scope,
                redirectURL,
              },
            };

      await createCredential({
        variables: {
          input: {
            ...(!credential && { name: `Google` }),
            ...(credential && { ID: credential.ID }),
            provider: {
              providerName: 'google',
              ...credPayload,
            },
          },
        },
      });
      setCreating(false);
      setServiceAcct(undefined);
    },
    [createCredential, credential, activeTab, serviceAcct]
  );

  const [doOAuth, popupVisible] = useOAuth('google', createNewCredential);

  const body = (
    <Fragment>
      {['google', 'gsheets'].includes(connectorName) || !connectorName ? (
        <Tab
          panes={tabPanes}
          activeIndex={activeTab}
          onTabChange={(e, { activeIndex }) => setActiveTab(activeIndex)}
        />
      ) : (
        <EditableMarkdown
          maxHeight="300px"
          label={''}
          content={serviceAcct}
          editable={true}
          onSave={(data) => setServiceAcct(data)}
          placeholder={'Enter service account information (*Required)'}
        />
      )}

      <GooglePreFlightDialog
        visible={preflightDialogVisible}
        onClose={() => setPreflightDialogVisible(false)}
        onSubmit={() => {
          setPreflightDialogVisible(false);
          serviceAcct && activeTab === 1 ? createNewCredential() : doOAuth();
        }}
      />
    </Fragment>
  );

  return renderBody(
    body,
    ['gsheets', 'google'].includes(connectorName) || activeTab === 0
      ? () => setPreflightDialogVisible(true)
      : createNewCredential,
    popupVisible || creating,
    !serviceAcct && activeTab === 1
  );
}
