import React from 'react';
import { VideoDialog, VideoDialogBody } from '../styles';
import Dialog from '<components>/Dialog';
import Button from '<components>/Button';

export default function VideoPopup({ video, visible, onClose }) {
  return (
    <VideoDialog visible={visible} onRequestClose={onClose}>
      <Dialog.Header>
        <Dialog.Headline>{video && video.title}</Dialog.Headline>
        <Button.DialogClose onClick={onClose} />
      </Dialog.Header>
      <VideoDialogBody tabIndex="0">{video && video.video}</VideoDialogBody>
    </VideoDialog>
  );
}
