import React, { Component } from 'react';
import T from 'prop-types';
import styled from '@emotion/styled';

import * as colors from '<components>/colors';

const StyledAvatar = styled('div')`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 32px;
  height: 32px;
  line-height: 30px;
  border: 1px solid ${colors.blue1};
  color: white;
  border-radius: 50%;
  font-size: 11px;
  font-weight: bold;
`;

export default class Avatar extends Component {
  static propTypes = {
    className: T.string,
    userName: T.string,
  };

  get initials() {
    const userNameRegExp = /[!@#$%^&*)(+=.<>{}[]:;'"|~`_-]/g;
    return this.props.userName
      .replace(userNameRegExp, '')
      .split(' ')
      .map((n) => n[0])
      .join('');
  }

  render() {
    const { userName } = this.props;

    return (
      <StyledAvatar {...this.props}>
        {userName ? this.initials : '--'}
      </StyledAvatar>
    );
  }
}
