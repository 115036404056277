import React from 'react';

import { useQuery } from '<src>/apollo/client';

import WaveSpinner from '<components>/WaveSpinner';

import { GetProjectReleases } from '../../../../queries';

import Releases from '../../../../components/Releases';

export default function ProjectReleases({ project }) {
  const { data, loading } = useQuery(GetProjectReleases, {
    variables: { projectID: project.ID },
  });

  if (loading) {
    return <WaveSpinner />;
  }

  return <Releases project={project} releases={data.projectReleases} />;
}
