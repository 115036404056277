import React from 'react';
import { Menu } from 'semantic-ui-react';

import * as colors from '<components>/colors';
import styled from '@emotion/styled';
import { StyledScrollbar } from '<components>/NumbrzPageComponents';

export const StyledMenu = styled(Menu)`
  &.ui.secondary.vertical.pointing.menu {
    border-right: none;
    width: 100%;
    padding-right: 10px;
    overflow-y: auto;
    ${(props) => props.maxHeight && { maxHeight: props.maxHeight }};
    ${(props) => props.minHeight && { minHeight: props.minHeight }};
    ${(props) => props.padding && { padding: props.padding }};
    ${(props) => props.height && { height: props.height }};

    border-left-color: transparent;
    margin: 10px 0px 0px 0;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-right: none;
      border-radius: 4px !important;
      margin: 0 0 0 0px;
      padding: 3px 11px 3px 15px;

      font-size: 13px;
      line-height: unset;
      letter-spacing: 0.4px;

      ${(props) =>
        props.isItemList && {
          border: `1px solid ${colors.gray_border}`,
          padding: '7px 5px 7px 13px',
          margin: '0 0 10px 0',
          minHeight: '25px',
          fontWeight: 'bold',
          fontSize: '12px',
        }}

      color: ${colors.gray1};
    }

    .item.active,
    a.active .item {
      border: none;
      font-weight: 550;
      background: ${colors.active_blue};
    }

    ${StyledScrollbar}
  }
`;

export const SidebarSeparator = styled('div')`
  width: 15rem;
  border-bottom: 1px solid ${colors.gray_border};
`;

export default function SidebarMenu({
  options = [],
  onClick,
  activeItem,
  maxHeight,
  minHeight,
  padding,
  isItemList,
}) {
  return (
    <StyledMenu
      pointing
      secondary
      vertical
      maxHeight={maxHeight}
      minHeight={minHeight}
      padding={padding}
      isItemList={isItemList}
    >
      {options.map((op, idx) => (
        <Menu.Item
          key={idx}
          value={op.value}
          active={activeItem === op.value}
          onClick={onClick}
        >
          {op.text}
          {op.additionalContent}
        </Menu.Item>
      ))}
    </StyledMenu>
  );
}
