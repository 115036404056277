import React, { useState, useCallback } from 'react';
import styled from '@emotion/styled';

import { Icon, Dropdown } from 'semantic-ui-react';
import Button from '../Button';
import Link from '<components>/Link';
import * as colors from '<components>/colors';

const StyledDropdown = styled(Dropdown)`
  button {
    margin-top: 2px;
  }
  a:hover {
    span {
      color: ${colors.blue1} !important;
    }
  }
  i {
    color: ${(props) => props.menuIconDark && colors.gray1};
  }
  .menu {
    z-index: 100 !important;
  }
`;

const ignoreSsrWarning =
  '/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */';

const ifConfirm =
  (style) =>
  ({ confirming }) =>
    confirming ? style : undefined;

const StyledDropdownItem = styled(Dropdown.Item, {
  shouldForwardProp: (prop) => prop !== 'confirming',
})`
  ${ignoreSsrWarning}&:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  &:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  background-color: ${ifConfirm(`${colors.red1b} !important`)};
  color: ${ifConfirm('white !important')};
  & i {
    color: ${colors.gray1b} !important;
    color: ${ifConfirm('white !important')};
  }
  & button {
    i.icon {
      color: ${colors.gray1b} !important;
    }
  }
  transition: color 200ms ease-in;
  transition: background-color 200ms ease-in;
  span {
    text-transform: none !important;
    font-size: 0.9em;
  }
  ${(props) => props.bold && { fontWeight: 'bold' }}
`;

export default function ActionMenu({
  defaultOpen = false,
  open: openProp,
  menuDirection = 'left',
  options = [],
  menuIconName = 'ellipsis vertical',
  menuIconDark = false,
  ...dropdownProps
}) {
  const [openState, setOpen] = useState(defaultOpen);
  const [selected, select] = useState(0);
  const [confirming, setConfirming] = useState(null);

  options = options.filter((option) => option && !option.exclude);
  if (options.length === 0) return <span />;

  const closeMenu = () => {
    setOpen(false);
    select(0);
    setConfirming(null);
  };

  const clickHandler = (optionIdx) => (e) => {
    const option = options[optionIdx];
    e.preventDefault();
    e.stopPropagation();

    if (!option.confirmSelect || confirming === option.value) {
      option.onSelect && option.onSelect(e);
      closeMenu();
    } else if (option.confirmSelect) {
      select(optionIdx);
      setConfirming(option.value);
    }
  };

  const open = openProp !== undefined ? openProp : openState;

  return (
    <StyledDropdown
      as="div"
      direction={menuDirection}
      icon={<Button icon={<Icon name={menuIconName} />} />}
      menuIconDark={menuIconDark}
      {...dropdownProps}
      open={open}
      onOpen={(e) => {
        e && e.preventDefault();
        e && e.stopPropagation();
        setOpen(true);
        select(0);
        setConfirming(null);
      }}
      onClose={(e, d) => {
        closeMenu();
      }}
      onKeyDown={(e) => {
        switch (e.key) {
          case 'ArrowDown':
            select((prevSelect) => (prevSelect + 1) % options.length);
            setConfirming(null);
            break;
          case 'ArrowUp':
            select((prevSelect) => (prevSelect - 1) % options.length);
            setConfirming(null);
            break;
          case 'Enter':
            clickHandler(selected)(e);
            break;
          default:
            break;
        }
      }}
    >
      <Dropdown.Menu>
        {options.map((option, idx) => (
          <StyledDropdownItem
            key={option.value}
            icon={option.icon}
            as={option.isLink && Link}
            internal={option.isLink && option.isInternal}
            to={option.isLink && option.to}
            href={option.isLink && option.href}
            target={option.isLink && !option.isInternal && '_blank'}
            confirming={confirming === option.value}
            disabled={option.disabled}
            text={
              confirming === option.value &&
              options[selected].value === option.value
                ? option.confirmText || 'Confirm'
                : option.text
            }
            onClick={option.isLink ? () => {} : clickHandler(idx)}
            selected={options[selected].value === option.value}
          />
        ))}
      </Dropdown.Menu>
    </StyledDropdown>
  );
}

ActionMenu.cloneOption = (option) => ({
  value: 'clone',
  text: 'Clone',
  icon: 'clone outline',
  ...option,
});
ActionMenu.deleteOption = (option) => ({
  value: 'delete',
  text: option.label ? option.label : 'Delete',
  icon: 'trash alternate outline',
  confirmSelect: true,
  ...option,
});
ActionMenu.deployOption = (option) => ({
  value: 'deploy',
  text: 'Deploy',
  icon: 'share square outline',
  ...option,
});
ActionMenu.copyOption = (option) => ({
  value: 'copy',
  text: 'Copy',
  icon: 'copy outline',
  ...option,
});
ActionMenu.syncOption = (option) => ({
  value: 'refresh',
  text: 'Sync',
  icon: 'sync alternate',
  ...option,
});
