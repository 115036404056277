import { useCallback, useEffect, useRef, useState } from 'react';

function copyStyle(source, target) {
  const style = window.getComputedStyle(source);
  Array.from(style).forEach((key) =>
    target.style.setProperty(
      key,
      style.getPropertyValue(key),
      style.getPropertyPriority(key)
    )
  );
}

function measureDomNode(node, enhanceForMeasure = (node) => node) {
  const container = document.createElement('div');
  container.style.display = 'inline-block';
  container.style.position = 'absolute';
  container.style.visibility = 'hidden';
  container.style['z-index'] = '-1';

  const clonedNode = node.cloneNode(true);
  copyStyle(node, clonedNode);
  const content = enhanceForMeasure(clonedNode);

  container.appendChild(content);

  document.body.appendChild(container);

  const height = container.clientHeight;
  const width = container.clientWidth;

  container.parentNode.removeChild(container);
  return { height, width };
}

function refreshSize(ref, setSize, enhanceForMeasure) {
  if (ref.current) {
    setSize(measureDomNode(ref.current, enhanceForMeasure));
  }
}

export default function useMeasure(enhanceForMeasure) {
  const ref = useRef();
  const [size, setSize] = useState({ height: undefined, width: undefined });

  useEffect(() => {
    refreshSize(ref, setSize, enhanceForMeasure);
  }, [size.height, size.width, enhanceForMeasure]);

  const refresh = useCallback(() => {
    refreshSize(ref, setSize, enhanceForMeasure);
  }, [enhanceForMeasure]);

  return [size, ref, refresh];
}
