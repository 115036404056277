/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx } from '@emotion/react';
import { PureComponent } from 'react';

import withDefaultProps from '<components>/hoc/defaultProps';
import withFilteredProps from '<components>/hoc/filteredProps';
import ResizeObserverable from '<components>/ResizeObservable';

import Body from './Body';
import Head from './Head';
import Row from './Row';
import Header from './Header';
import Cell, { FormattedCell } from './Cell';
import Control from './Control';

import styledTable from './styles';

@withDefaultProps({
  active: false,
})
@withFilteredProps(['active', 'wide'])
class Table extends PureComponent {
  static Head = Head;
  static Body = Body;
  static Row = Row;
  static Cell = Cell;
  static FormattedCell = FormattedCell;
  static Header = Header;
  static Control = Control;

  bindRef = (el) => (this.node = el);

  handleResize = ({ width, height }) => {
    this.node.style.setProperty('--tableWidth', `${width}px`);
    this.node.style.setProperty('--tableHeight', `${height}px`);
  };

  render() {
    const styles = {
      ...styledTable.base,
      ...(this.props.active && styledTable.active),
    };

    return (
      <ResizeObserverable
        onResize={this.handleResize}
        style={this.props.wide && { width: '100%' }}
      >
        <table ref={this.bindRef} {...this.props.filteredProps} css={styles} />
      </ResizeObserverable>
    );
  }
}

export default Table;
