/** @jsx jsx */
/** @jsxRuntime classic */

import { forwardRef, useEffect, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { jsx, css } from '@emotion/react';
import { useDrag, useDrop } from 'react-dnd';

import { mergeRefs } from '<src>/utils/react';

import * as colors from '<components>/colors';

import Editor from '../Editor';
import Table from '../Table';
import useSelectable from '../useSelectable';

import { formatCell, parseUserValue } from './util';

function useSource(cell, sourceType, enable) {
  const item = cell
    ? {
        ID: cell.ID,
        widgetID: cell.widgetID,
        type: useSelectable.CELL,
        sourceType,
        value: cell.value,
        dataType: cell.dataType,
      }
    : { type: 'NONE' };
  return useDrag({
    type: item.type,
    item,
    canDrag: () => cell && enable,
    collect: (monitor) => ({
      isDragging: cell && enable && !!monitor.isDragging(),
    }),
  });
}

function useTarget(cell, onAddLink, enable) {
  return useDrop({
    accept: useSelectable.CELL,
    canDrop: (item) => (cell && enable ? item.ID !== cell.ID : false),
    drop: (item) => {
      if (enable) {
        onAddLink(cell, {
          sourceID: item.ID,
          sourceType: `${item.sourceType}Source`,
          value: item.value,
          dataType: item.dataType,
        });
        return {
          ID: cell.ID,
          type: useSelectable.CELL,
        };
      }
    },
    collect: (monitor) => ({
      canDrop: !!monitor.canDrop(),
      isOver: !!monitor.canDrop() && !!monitor.isOver(),
    }),
  });
}

const StyledCell = styled(Table.Data)`
  & > * {
    min-width: 3em;
    padding: 3px 9px;
    outline: none;
  }
  & > *:empty:after {
    content: '\\00a0';
  }
`;

const StyledHeaderCell = StyledCell.withComponent('th');

const editorStyle = css`
  outline: none;
  &[contenteditable='true'] {
    background-color: ${colors.white};
  }
`;

function styleValue(cell) {
  let align;
  switch (cell.dataType) {
    case 'Number':
    case 'Date':
      align = 'right';
      break;
    case 'Bool':
      align = 'center';
      break;
    default:
      align = 'left';
  }
  return css`
    ${editorStyle}

    text-align: ${align};
  `;
}

function Cell(
  {
    as = 'td',
    cell,
    appearTrigger = { id: 'NONE' },
    sourceType = 'Cell',
    firstOutput,
    isEditable,
    isInput,
    isOutput,
    isSource,
    isTarget,
    parseValue = parseUserValue,
    onConfirm = () => {},
    onCancel = () => {},
    onLink = () => {},
    onUpdate = () => {},
    ...props
  },
  parentRef
) {
  const [selectionRef, selected] = useSelectable({
    type: cell.type || useSelectable.CELL,
    ID: cell.ID,
    correlationID: cell.correlationID,
  });
  const [{ isDragging }, dragRef] = useSource(cell, sourceType, isSource);
  const [{ isOver }, dropRef] = useTarget(cell, onLink, isTarget);
  const editorRef = useRef();

  const Tag = useMemo(() => StyledCell.withComponent(as), [as]);

  useEffect(() => {
    if (appearTrigger.id === cell.correlationID) {
      switch (appearTrigger.action) {
        case 'edit':
          editorRef.current.edit(true);
          break;
        case 'focus':
          editorRef.current.focus();
          break;
        default:
          break;
      }
      appearTrigger.done();
    }
  }, [appearTrigger, cell.correlationID]);

  return (
    <Tag
      ref={mergeRefs([dragRef, dropRef])}
      isDragging={isDragging}
      isOver={isOver}
      isInput={isInput}
      isOutput={isOutput}
      isError={cell.status ? cell.status !== 'OK' : false}
      isSeparated={firstOutput}
      selected={selected}
      {...props}
    >
      <Editor
        ref={mergeRefs([editorRef, parentRef, selectionRef])}
        css={styleValue(cell)}
        disabled={!isEditable}
        value={formatCell(cell)}
        onSubmit={({ value }) => {
          onUpdate(cell, parseValue(value));
        }}
        onEditing={({ editing, method }) => {
          if (editing) return;
          if (method === 'enterKey') {
            onConfirm(cell.ID);
          } else if (method === 'escapeKey') {
            onCancel(cell.ID);
          }
        }}
      />
    </Tag>
  );
}
// eslint-disable-next-line no-func-assign
Cell = forwardRef(Cell);
Cell.displayName = 'Cell';
export default Cell;

export { StyledCell, StyledHeaderCell, editorStyle };
