import * as colors from '<components>/colors';

export * from '<components>/colors';

// export const borderColor = gray3;
export const borderColor = 'rgba(0,0,0,0.07)';
export const textColor = colors.gray1;
export const transitionSpeed = '225ms';
export const transitionTimingFunction = 'ease-in-out';
export const border = `1px solid ${borderColor}`;

export const cellPadding = [5, 9];
export const cellPrimaryBackgroundColor = '#fff';
export const cellAlternateBackgroundColor = colors.gray4;
export const cellHeaderBackgroundColor = colors.gray3;

export const controlSize = 16;
export const controlHandleSize = 1;
export const controlHandleBorder = `1px solid ${colors.gray2}`;
export const controlHandleDimensions = [4, 8];
export const controlLineWidth = 2;

export const dotTargetSize = 16;
export const dotOffset = 3;
export const dotSize = 6;
export const dotRingOffset = 3;
export const dotRingWidth = 1;
export const dotColor = colors.gray3;

export const actionsArrowSize = 6;
export const actionsArrowOffset = 5;

export const resetButton = {
  border: 'none',
  margin: '0',
  padding: '0',
  width: 'auto',
  overflow: 'visible',
  background: 'transparent',
  color: 'inherit',
  font: 'inherit',
  textAlign: 'inherit',
  lineHeight: 'normal',
  fontSmoothing: 'inherit',
  appearance: 'none',
};

export const cellBorder = {
  borderTop: border,
  borderRight: 0,
  borderLeft: border,
  borderBottom: 0,
  'th&:last-child, td&:last-child': {
    borderRight: border,
  },
  'tbody tr:last-child &': {
    borderBottom: border,
  },
};
