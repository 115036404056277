import React from 'react';
import { withRouter } from 'react-router';
import { Switch, Route, Redirect } from 'react-router-dom';

import useFeatures from '../Feature/useFeatures';

import AccountSection from '<sections>/account';
import DataSection from '<sections>/data';
import NumbrzAdminSection from '<sections>/numbrz-admin';
import ProjectSection from '<sections>/projects';
import ServiceSection from '<sections>/service-projects';
import JobGroupsSection from '<sections>/job-groups';
import PublicCatalog from '<sections>/catalog';

import ErrorPage from '<src>/pages/ErrorPage';

import NotFound from './NotFound';
import SwitchPrimaryAccount from './SwitchAccount';

function Routes() {
  const featureFlags = useFeatures();

  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/models" />} />
      <Route
        path="/switch/:accountID"
        render={({ match, location }) => {
          const goto = location.pathname.slice(match.url.length) || '/';
          const { accountID } = match.params;
          return <SwitchPrimaryAccount accountID={accountID} goto={goto} />;
        }}
      />

      <Route path="/catalog">
        <PublicCatalog />
      </Route>
      <Route path="/error">
        <ErrorPage />
      </Route>
      <Route path="/models">
        <ProjectSection />
      </Route>
      {featureFlags.ServiceModels ? (
        <Route path="/svc-models">
          <ServiceSection />
        </Route>
      ) : null}
      <Route path="/account">
        <AccountSection />
      </Route>
      <Route path="/data">
        <DataSection />
      </Route>
      <Route path="/job-groups">
        <JobGroupsSection />
      </Route>
      {featureFlags.NumbrzAdmin ? (
        // TODO this is moving to the admin app
        <Route path="/numbrz-admin">
          {(props) => {
            return <NumbrzAdminSection {...props} />;
          }}
        </Route>
      ) : null}

      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}

export default withRouter(Routes);
