const notNumberRegex = /[^-+e.0-9\s]/;
export function parseNumberValue(value, dataType) {
  if (notNumberRegex.test(value)) {
    return { value: '###', dataType, error: true };
  }
  const nVal = Number.parseFloat(value);
  if (Number.isNaN(nVal)) {
    return { value: '###', dataType, error: true };
  }
  return { value: nVal, dataType: dataType || 'Number' };
}
export function formatNumber(num, fmt) {
  if (num === null) return null;
  fmt = { ...fmt };
  if (!fmt.number) {
    fmt.number = { type: 'Locale', decimals: null };
  }
  const nf = new Intl.NumberFormat(undefined, {
    style: fmt.number.type === 'Percent' ? 'percent' : 'decimal',
    useGrouping: fmt.number.type !== 'Plain',
    minimumFractionDigits:
      fmt.number.decimals === null ? 0 : fmt.number.decimals,
    maximumFractionDigits:
      fmt.number.decimals === null ? 7 : fmt.number.decimals,
  });
  return nf.format(num);
}

const epoch = Date.UTC(1899, 11, 30);
const dateRegex = /^(\d{1,2})\/(\d{1,2})(\/(\d{2,4}))?$/;
function msToDays(ms) {
  return Math.round(ms) / (1000 * 60 * 60 * 24);
}
export function parseDateValue(value, dataType, params) {
  const match = dateRegex.exec(value);
  if (match) {
    const [, month, day] = match;
    let [, , , , year = new Date().getFullYear()] = match;

    year = parseFloat(year);
    if (year <= 29) year += 2000;
    if (year > 29 && year < 100) year += 1900;

    const ms = Date.UTC(year, month - 1, day);
    const raw = msToDays(ms - epoch);
    const returnVal = params && params.ms ? ms : raw;
    return { value: returnVal, dataType: dataType || 'Date' };
  }
  if (dataType === 'Date') {
    return parseNumberValue(value, dataType);
  }
  return { value: '###', dataType, error: true };
}
export function formatDate(value, params) {
  if (value === null) return null;

  const date =
    params && params.ms
      ? new Date(parseInt(value, 10))
      : new Date(epoch + value * 1000 * 60 * 60 * 24);
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate()
  ).toLocaleDateString('en-US', {
    month: 'numeric',
    year: 'numeric',
    day: 'numeric',
  });
}

export function formatBool(val) {
  if (val) {
    return 'TRUE';
  }
  return 'FALSE';
}

export function parseBoolValue(value, dataType) {
  if (value.toLowerCase() === 'false') {
    return { value: false, dataType: dataType || 'Bool' };
  }
  if (value.toLowerCase() === 'true') {
    return { value: true, dataType: dataType || 'Bool' };
  }
  return { value: '###', dataType, error: true };
}

export function parseUserValue(value, params) {
  if (value === null) return { value: null };
  let v = parseDateValue(value, null, params);
  if (!v.error) return v;
  v = parseNumberValue(value);
  if (!v.error) return v;
  v = parseBoolValue(value);
  if (!v.error) return v;
  return { value, dataType: 'String' };
}

export function formatCell(cell, params) {
  let val = cell.value;
  if (['Date', 'DateTime'].includes(cell.dataType)) {
    val = formatDate(cell.value, params);
  } else if (cell.dataType === 'Number' && cell.value !== null) {
    val = formatNumber(cell.value, cell.format);
  } else if (cell.dataType === 'Bool' && cell.value !== null) {
    val = formatBool(cell.value);
  }
  if (val === null) {
    return '';
  }
  return `${val}`;
}
