/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx } from '@emotion/react';
import { hoistStatics, mapProps, compose } from 'recompose';

import withFilteredProps from '<components>/hoc/filteredProps';

import styles from '../styles';
import Cell from './Cell';

const StyledCell = (props) => {
  const cellStyles = {
    ...(props.linkSource && styles.cell.linkSource),
    ...(!props.active &&
      props.inactiveLinkSource &&
      styles.cell.inactiveLinkSource),
    ...(props.linkTarget && styles.cell.linkTarget),
    ...(!props.active &&
      props.inactiveLinkTarget &&
      styles.cell.inactiveLinkTarget),
    ...((props.variable || props.isCalculated) && styles.cell.variable),
    ...((props.result || props.isResult) && styles.cell.result),
    ...(props.resultRow && styles.cell.resultRow),
    ...(props.resultColumn && styles.cell.resultColumn),
    ...(props.isHeader && styles.cell.headerCell),
  };

  return <Cell {...props} css={cellStyles} />;
};

export default hoistStatics(
  compose(
    withFilteredProps([
      'controlMode',
      'resultRow',
      'resultColumn',
      'isHeader',
      'isCalculated',
      'isResult',
      'linkSource',
      'inactiveLinkSource',
      'linkTarget',
      'inactiveLinkTarget',
      'variable',
      'result',
      'type',
    ]),
    mapProps(({ filteredProps }) => ({ ...filteredProps }))
  )
)(StyledCell);
