import React from 'react';
import { Form } from 'semantic-ui-react';

export default function ArithmeticSettings({ api, widget }) {
  const { showTotals } = widget.config;

  return (
    <Form>
      <Form.Checkbox
        checked={showTotals}
        onChange={() => {
          api.updateShowTotals(widget, !showTotals);
        }}
        label="Show row totals"
      />
    </Form>
  );
}
