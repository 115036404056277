import React, { useState, Fragment } from 'react';
import fp from 'lodash/fp';
import { Form, Checkbox } from 'semantic-ui-react';
import { useRouteMatch } from 'react-router-dom';

import {
  BillingWrapper,
  CancelButton,
  NewOrgMessage,
  SettingsWrapper,
  HorizontalCheckboxGroup,
  PlanPriceSubHdr,
} from '../styles';

import ChangePlanDialog from '../components/ChangePlanDialog';
import CancelSubscriptionDialog from '../components/CancelSubscriptionDialog';
import PaidPlanWrapper from '../components/PaidPlanWrapper';

function planPriceLabel(prices, plan) {
  const planPrice = prices.find((p) => p.product === plan);

  if (planPrice) {
    if (planPrice.unit_amount === 0)
      return <h2 style={{ marginBottom: '10px' }}>Free</h2>;
    const interval = planPrice.recurring.interval;
    const monthlyPrice =
      interval === 'month'
        ? planPrice.unit_amount / 100
        : planPrice.unit_amount / (100 * 12);

    if (interval === 'month')
      return (
        <h2 style={{ marginBottom: '10px' }}>{`$${monthlyPrice.toFixed(
          0
        )}/month`}</h2>
      );
    if (interval === 'year')
      return (
        <Fragment>
          <h2>{`$${monthlyPrice.toFixed(0)}/month`}</h2>
          <PlanPriceSubHdr>billed annually</PlanPriceSubHdr>
        </Fragment>
      );
  }
  return null;
}

function getShowPlan(path) {
  switch (path) {
    case '/account/billing/personal-plans':
      return 'Personal';
    case '/account/billing/team-plans':
      return 'Team';
    case '/account/billing/new-org':
      return 'Team';
    default:
      return undefined;
  }
}

function PaymentIntervalSettings({ value, onChange }) {
  return (
    <SettingsWrapper>
      <HorizontalCheckboxGroup>
        <Form.Field>
          <Checkbox
            radio
            label="Pay monthly"
            name="pay-interval"
            value="month"
            checked={value === 'month'}
            onChange={(e, data) => onChange(data.value)}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            radio
            label="Pay yearly (and save)"
            name="pay-interval"
            value="year"
            checked={value === 'year'}
            onChange={(e, data) => onChange(data.value)}
          />
        </Form.Field>
      </HorizontalCheckboxGroup>
    </SettingsWrapper>
  );
}

function GetCurrentPaymentInterval(subscription) {
  if (!subscription) return 'month';
  return fp.getOr('year', 'items[0].price.recurring.interval', subscription);
}

export default function BillingPlans({
  viewer,
  featureFlags,
  activeSubscription,
  activeSubscriptionPlan,
  activePlanID,
  primaryAccount,
  defaultPlanID,
  defaultPlan,
  activePlan,
  defaultPaymentMethod,
  stripeCustomerID,
  stripePlanCancelsAtEndOfCycle,
  stripePlanWillChangeAtEndOfCycle,
  stripePlanCancelDate,
  products,
  productPrices,
  updateCustomerSubscription,
  setAccountToStripeProduct,
  onPlanChangeNavigate,
  cancelCustomerSubscription,
  customerCardPaymentMethods,
  createStripeSubscription,
  changeStripeCustomerNumbrzAccount,
  createStripeCustomer,
  createOrgAndAddMember,
}) {
  const match = useRouteMatch();
  const history = useRouteMatch();
  const [changePlanDialogVisible, setChangePlanDialogVisible] = useState(false);
  const [cancelPlanDialogVisible, setCancelPlanDialogVisible] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [newPlan, setNewPlan] = useState(null);
  const [paymentInterval, setPaymentInterval] = useState(
    GetCurrentPaymentInterval(activeSubscription)
  );

  const showPlanType = getShowPlan(match.path);
  const createNewOrg = match.path === '/account/billing/new-org';

  const packages = products.reduce((result, currVal) => {
    (result[currVal.metadata.groupName] =
      result[currVal.metadata.groupName] || []).push(currVal);

    return result;
  }, {});

  const plan = activePlan || defaultPlan;

  // if activePlan will change will change, we need to show current activePlan
  // according to what's in Numbrz database
  // until we automatically  make these changes
  let currentActivePlan =
    activeSubscriptionPlan && activeSubscriptionPlan.id
      ? activeSubscriptionPlan
      : plan;

  if (stripePlanWillChangeAtEndOfCycle) {
    const p = products.find((p) => p.id === primaryAccount.stripeProductID);
    if (p) currentActivePlan = activePlan;
  }

  const monthlyPrices = productPrices.filter(
    (p) => p.recurring.interval === 'month'
  );
  const yearlyPrices = productPrices.filter(
    (p) => p.recurring.interval === 'year'
  );

  const isCurrentPlan = (activePlanID, plan) => {
    // Don't show this when users are creating new orgs
    if (createNewOrg) return false;
    return (
      plan.id === activePlanID || (!activePlanID && plan.metadata.isDefault)
    );
  };

  const updateSubscriptionMethod = (newPlanID) => {
    /*
    activePlan(Numbrz Quota Service) & activeSubscription(Stripe)
    may be different, Stripe's info always takes precedence
    */
    const currentPlan = activeSubscriptionPlan
      ? activeSubscriptionPlan
      : activePlan;
    const newPlan = products.find((p) => p.id === newPlanID);
    const newPlanRank = fp.getOr(0, 'metadata.planRank', newPlan);
    const activePlanRank = fp.getOr(0, 'metadata.planRank', currentPlan);

    if (newPlanRank > activePlanRank) return 'upgrade';
    if (newPlanRank < activePlanRank) return 'downgrade';
  };

  const handlePlanChange = async (planID, price, paymentMethodID) => {
    setProcessing(true);
    if (activeSubscription) {
      const planPrice = price
        ? price
        : productPrices.find((p) => p.product === planID);
      if (planPrice) {
        // see if there is default payment method, if not, tell user to select one
        const updateMethod = updateSubscriptionMethod(planID);

        await updateCustomerSubscription({
          variables: {
            subscriptionID: activeSubscription.id,
            priceID: planPrice.id,
            paymentMethodID,
            updateMethod,
          },
        });

        // update product id for the primary account (use new org if needed)
        if (updateMethod === 'upgrade') {
          await setAccountToStripeProduct({
            variables: {
              input: {
                id: primaryAccount.ID,
                stripeProductID: planID,
              },
            },
          });
        }
      }
    }

    onPlanChangeNavigate();
  };

  const handleCreateInvoice = (planID) => {
    history.push(`${match.path}${planID}`);
  };

  const needNewInvoice = () => {
    if (!activePlan || !activeSubscription || createNewOrg) return true;
    if (activePlan) {
      // upgrading from Personal to Team plan
      if (
        showPlanType === 'Team' &&
        !primaryAccount.stripeProductID &&
        primaryAccount.__typename === 'User'
      )
        return true;
    }
    return false;
  };

  const handleCancelPlan = async (mode) => {
    setProcessing(true);
    await cancelCustomerSubscription({
      variables: {
        subscriptionID: activeSubscription.id,
        userAccountID: primaryAccount.ID,
        stripeCustomerID,
      },
    });

    onPlanChangeNavigate();
  };

  return (
    <Fragment>
      <BillingWrapper>
        {createNewOrg && <h2>Select a plan for your new team</h2>}
        <PaymentIntervalSettings
          value={paymentInterval}
          onChange={(value) => setPaymentInterval(value)}
        />
        {showPlanType === 'Personal' && (
          <PaidPlanWrapper
            createNewOrg={createNewOrg}
            groupName="Personal"
            plans={packages['Personal']}
            planPriceLabel={planPriceLabel}
            productPrices={
              paymentInterval === 'month' ? monthlyPrices : yearlyPrices
            }
            paymentInterval={paymentInterval}
            isCurrentPlan={isCurrentPlan}
            activePlanID={activePlanID}
            defaultPlanID={defaultPlanID}
            setNewPlan={setNewPlan}
            setChangePlanDialogVisible={setChangePlanDialogVisible}
            stripePlanCancelsAtEndOfCycle={stripePlanCancelsAtEndOfCycle}
            stripePlanCancelDate={stripePlanCancelDate}
            stripePlanWillChangeAtEndOfCycle={stripePlanWillChangeAtEndOfCycle}
            stripePlanWillChangeDate={
              activeSubscription && activeSubscription.current_period_end
            }
            activeSubscriptionPlan={activeSubscriptionPlan}
            currentActivePlan={currentActivePlan}
          />
        )}

        {showPlanType === 'Team' && (
          <Fragment>
            <PaidPlanWrapper
              createNewOrg={createNewOrg}
              groupName="Team"
              plans={packages['Team']}
              planPriceLabel={planPriceLabel}
              productPrices={
                paymentInterval === 'month' ? monthlyPrices : yearlyPrices
              }
              paymentInterval={paymentInterval}
              isCurrentPlan={isCurrentPlan}
              activePlanID={activePlanID}
              defaultPlanID={defaultPlanID}
              setNewPlan={setNewPlan}
              setChangePlanDialogVisible={setChangePlanDialogVisible}
              stripePlanCancelsAtEndOfCycle={stripePlanCancelsAtEndOfCycle}
              stripePlanCancelDate={stripePlanCancelDate}
              stripePlanWillChangeAtEndOfCycle={
                stripePlanWillChangeAtEndOfCycle
              }
              stripePlanWillChangeDate={
                activeSubscription && activeSubscription.current_period_end
              }
              activeSubscriptionPlan={activeSubscriptionPlan}
              currentActivePlan={currentActivePlan}
            />
            {packages['Enterprise'] && (
              <PaidPlanWrapper
                createNewOrg={createNewOrg}
                groupName={'Enterprise'}
                plans={packages['Enterprise']}
                planPriceLabel={planPriceLabel}
                productPrices={
                  paymentInterval === 'month' ? monthlyPrices : yearlyPrices
                }
                paymentInterval={paymentInterval}
                isCurrentPlan={isCurrentPlan}
                activePlanID={activePlanID}
                defaultPlanID={defaultPlanID}
                setNewPlan={setNewPlan}
                setChangePlanDialogVisible={setChangePlanDialogVisible}
                stripePlanCancelsAtEndOfCycle={stripePlanCancelsAtEndOfCycle}
                stripePlanCancelDate={stripePlanCancelDate}
                stripePlanWillChangeAtEndOfCycle={
                  stripePlanWillChangeAtEndOfCycle
                }
                stripePlanWillChangeDate={
                  activeSubscription && activeSubscription.current_period_end
                }
                activeSubscriptionPlan={activeSubscriptionPlan}
                currentActivePlan={currentActivePlan}
              />
            )}
          </Fragment>
        )}

        {activeSubscription &&
          activePlanID &&
          activePlanID !== defaultPlanID &&
          !createNewOrg &&
          !stripePlanCancelsAtEndOfCycle && (
            <CancelButton
              contentColor="white"
              disabled={processing}
              onClick={() => setCancelPlanDialogVisible(true)}
            >
              {processing
                ? 'Cancelling Subscription...'
                : 'Cancel Current Subscription'}
            </CancelButton>
          )}

        {primaryAccount.__typename === 'User' &&
          match.url !== '/account/billing/team-plans' &&
          fp.getOr([], 'account.memberOf', viewer).length === 1 &&
          !createNewOrg && (
            <NewOrgMessage>
              Outgrown your personal account or wish to collaborate with more
              members? Check out our{' '}
              {
                <button
                  onClick={() => {
                    history.replace(`new-org`);
                  }}
                >
                  team plans
                </button>
              }
              {'!'}
            </NewOrgMessage>
          )}
      </BillingWrapper>
      <ChangePlanDialog
        user={fp.getOr({}, 'account', viewer)}
        visible={changePlanDialogVisible}
        onClose={() => setChangePlanDialogVisible(false)}
        activePlan={plan}
        newPlan={newPlan}
        subscriptionUpdateMethod={updateSubscriptionMethod(
          newPlan && newPlan.id ? newPlan.id : newPlan
        )}
        plans={products}
        prices={productPrices}
        paymentInterval={paymentInterval}
        onPlanChange={handlePlanChange}
        onPlanChangeNavigate={onPlanChangeNavigate}
        onCreateInvoice={handleCreateInvoice}
        needNewInvoice={needNewInvoice()}
        featureFlags={featureFlags}
        stripeCustomer={stripeCustomerID}
        primaryAccount={primaryAccount}
        createOrgAndAddMember={createOrgAndAddMember}
        createStripeCustomer={createStripeCustomer}
        changeStripeCustomerNumbrzAccount={changeStripeCustomerNumbrzAccount}
        createStripeSubscription={createStripeSubscription}
        setAccountToStripeProduct={setAccountToStripeProduct}
        history={history}
        customerCardPaymentMethods={customerCardPaymentMethods}
        defaultPaymentMethod={defaultPaymentMethod}
        isOrgSetup={showPlanType === 'Team'}
        createNewOrg={createNewOrg}
      />
      <CancelSubscriptionDialog
        mode={
          activeSubscription && activeSubscription.status === 'incomplete'
            ? 'update'
            : null
        }
        visible={cancelPlanDialogVisible}
        onClose={() => setCancelPlanDialogVisible(false)}
        onCancelPlan={handleCancelPlan}
        billingCycleEndDate={
          activeSubscription && activeSubscription.current_period_end
        }
      />
    </Fragment>
  );
}
