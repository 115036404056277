import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '<src>/apollo/client';

export const DataPickerFragment = gql`
  fragment DataPickerFragment on DataTable {
    ID
    name
    description
    usage
    sourceURL
    features {
      where
      sort
      aggregate
      select
      insert
      update
      delete
      truncate
      link
      create
      alter
      drop
    }
    tableset {
      ID
      name
      source {
        __typename
      }
    }
    source {
      __typename
    }
    type
    columns {
      ID
      externalID
      label
      type
      comment
      description
    }
    state {
      ready
      issues {
        code
        desc
      }
    }
  }
`;

export const GetPickerTables = gql`
  query GetPickerTables($projectID: CommonID, $cursor: String) {
    dataTables(
      input: { projectID: $projectID, usageTypes: [UserData] }
      cursor: $cursor
    ) {
      cursor
      tables {
        ...DataPickerFragment
      }
    }
  }

  ${DataPickerFragment}
`;

export const UserData = (props) => {
  const { data: { project, dataTables } = {}, loading } = useQuery(
    GetPickerTables,
    {
      variables: { projectID: props.projectID },
    }
  );
  let tables = [];
  if (dataTables) {
    tables = tables.concat(
      dataTables.tables.filter((t) => t.type !== 'Detached')
    );
  }
  if (project) {
    tables = tables.concat(project.workingTables);
  }
  const handleOnSelect = (selectedID) =>
    props.onSelect(tables.find((t) => t.ID === selectedID));

  return (
    <props.Comp
      {...props}
      loading={loading}
      tables={tables}
      onSelect={handleOnSelect}
    />
  );
};
