import React, { useMemo } from 'react';
import fp from 'lodash/fp';

import useActiveResultCellLinks from '../useActiveResultLinks';
import LinkLines from '<src>/sections/functions/LinkLines';
import StepResultOutput from '../StepResultOutput';

const getFlags = (logs) => {
  let flags = [];
  logs.forEach((log) => {
    if (log.flags && log.flags.length > 0) {
      log.flags.forEach((f) => {
        if (!flags.includes(f)) flags.push(f);
      });
    }
  });
  return flags;
};

export default function StageResultOutput({
  baseURL,
  flowID,
  stageResult = { stepLogs: [] },
  activeStage,
  stageElements = [],
  jobVariables = [],
  flowTables,
}) {
  const activeCellLinks = useActiveResultCellLinks();

  const stepResultOutput = useMemo(() => {
    return stageResult
      ? stageResult.stepLogs.map((log, stepLogIdx) => {
          const logItems = fp.getOr([], 'logItems', log);
          const flags = getFlags(logItems);
          const stepKey = log.key ? log.key : 'INPUT';
          const step = log.key
            ? activeStage.steps.find((s) => s.key === log.key)
            : activeStage.input;

          return (
            <StepResultOutput
              key={stepLogIdx}
              stageID={stageResult.stageID}
              stepKey={stepKey}
              flags={flags}
              stageElements={stageElements}
              jobVariables={jobVariables}
              step={step}
              stepLog={log}
              flowID={flowID}
              baseURL={baseURL}
              stageStepLogs={stageResult.stepLogs}
              flowTables={flowTables}
            />
          );
        })
      : null;
  }, [
    baseURL,
    flowID,
    stageElements,
    stageResult,
    activeStage,
    flowTables,
    jobVariables,
  ]);

  return stageResult ? (
    <LinkLines links={activeCellLinks} slackY={0} yAdjustment={20}>
      {stepResultOutput}
    </LinkLines>
  ) : null;
}
