import React, { forwardRef, useMemo } from 'react';

import Conditions from '../../Conditions';
import { stepsByType } from '../stepData';
import Step from '../Step';

function FilterStep(
  { step, api, stageElements: elements = [], ...props },
  parentRef
) {
  const { onChangeFilter } = api;

  const onChange = useMemo(
    () =>
      onChangeFilter
        ? (e, changes) => onChangeFilter(e, { key: step.key, ...changes })
        : null,
    [onChangeFilter, step.key]
  );

  const onChangeConditions = useMemo(() => {
    if (!onChange) {
      return null;
    }
    return (e, { conditions: or }) => onChange(e, { or });
  }, [onChange]);

  return (
    <Step ref={parentRef} step={step} api={api} {...props}>
      <div />
      <div>
        <h5>Filters</h5>
        <Conditions
          elements={elements}
          conditions={step.or}
          onChange={onChangeConditions}
        />
      </div>
    </Step>
  );
}

// eslint-disable-next-line no-func-assign
FilterStep = forwardRef(FilterStep);
FilterStep.displayName = 'FilterStep';

// Avoid circular dependency
stepsByType.FilterStepV2.Component = FilterStep;
export default FilterStep;
