import styled from '@emotion/styled';

import { StyledCell } from '../../Cell';

export const ValueCell = styled(StyledCell)`
  text-align: right;
`;

export const DropdownCell = styled(StyledCell)`
  &.error {
    color: #9f3a38;
    background-color: #fff6f6 !important;
  }

  & .ui.dropdown .menu > .item {
    font-size: inherit;
  }
`;
