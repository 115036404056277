// eslint-disable-next-line import/no-extraneous-dependencies
import { onError } from '@apollo/client/link/error';

const isUnauthorizedError = (error) =>
  error && error.statusCode ? error.statusCode === 401 : false;

export default function createErrorLink(logout) {
  function handleError({ graphQLErrors, networkError, response = {} }) {
    if (networkError && isUnauthorizedError(networkError)) {
      logout();
    }
    if (graphQLErrors && response.data === null) {
      // This works around an (apparent) bug in Apollo
      response.data = {};
    }
  }

  return onError(handleError);
}
