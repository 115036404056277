import React from 'react';

import ElementLabel from '../ElementLabel';
import { fieldPrefix } from '../ValuePicker';

export default function FieldAmount({ value = '', elements }) {
  if (value.startsWith(fieldPrefix)) {
    const elementKey = value.substring(fieldPrefix.length);
    const element = elements.find((e) => e.key === elementKey);
    return <ElementLabel elementKey={elementKey} element={element} />;
  }
  return <span className="value">{value}</span>;
}
