/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx, css } from '@emotion/react';

import { useEffect, useState } from 'react';
import { Message } from 'semantic-ui-react';

import { modelRunnerType } from '<src>/utils/data-types';
import { sortBy } from '<src>/utils/text';

import * as colors from '<components>/colors';
import NestedPickerPane from '<components>/NestedPickerPane_V2';
import Dialog from '<components>/Dialog';
import Button from '<src>/components/Button';
import DataTablePane from '<components>/DataTablePane';
import { FlexColumnContainer } from '<src>/components/NumbrzPageComponents';
import { makeOptions } from '<components>/DataPicker/Pane';

const StyledDialog = (props) => (
  <Dialog
    {...props}
    css={css`
      width: 50%;
    `}
  />
);

const StyledBody = (props) => (
  <Dialog.Body
    {...props}
    css={css`
      display: flex;
      flex-direction: column;
      background-color: ${colors.white};
    `}
  />
);

function tableType(table) {
  return table.prefSource === 'WorkingTable' ? 'working table' : 'job table';
}

function InfoMessage({ table }) {
  const type = tableType(table);
  return (
    <Message info>
      Select a data source to use as a template for this {type}. The <b>name</b>{' '}
      and <b>column definitions</b> of the {type} will be updated to match the
      data source you select.
    </Message>
  );
}

function DeleteColMessage({ columns, table }) {
  const type = tableType(table);
  return (
    <Message warning>
      This operation will remove columns from the {type} definition. This may
      result in validation errors in this flow. The following columns will be
      removed:
      <ul>
        {columns.map((col) => (
          <li key={col.key}>
            {col.label} ({col.type})
          </li>
        ))}
      </ul>
    </Message>
  );
}

export default function CopyColumnsDialog({
  loading,
  dataTables,
  table,
  visible,
  onClose,
  onChange,
}) {
  const [name, setName] = useState(null);
  const [selectedID, setSelectedID] = useState(null);
  const [newColumns, setNewColumns] = useState([]);
  const [deleteColumns, setDeleteColumns] = useState([]);

  useEffect(() => {
    if (!visible) {
      setSelectedID(null);
    }
  }, [visible]);

  useEffect(() => {
    if (!table || !selectedID) {
      return;
    }
    const dataTable = dataTables.find((dt) => dt.ID === selectedID);
    if (!dataTable) {
      return;
    }

    setName(dataTable.name);

    const keyByLabel = table.schema.elements.reduce(
      (acc, e) => ({ ...acc, [e.label]: e.key }),
      {}
    );
    const elementsToRemove = table.schema.elements.reduce(
      (acc, e) => ({ ...acc, [e.key]: e }),
      {}
    );
    const cols = [];
    dataTable.columns.forEach((col) => {
      const key = keyByLabel[col.label];
      if (key) {
        delete elementsToRemove[key];
      }
      cols.push({
        key,
        label: col.label,
        type: modelRunnerType(col.type),
        comment: col.comment,
      });
    });
    setNewColumns(cols);
    const deleteColumns = Object.values(elementsToRemove);
    sortBy('label', deleteColumns);
    setDeleteColumns(deleteColumns);
  }, [table, selectedID, dataTables]);

  return (
    <StyledDialog visible={visible ? 1 : 0} onRequestClose={onClose}>
      <Dialog.Header>
        <Dialog.Headline>Copy table columns</Dialog.Headline>
        <Button.DialogClose onClick={onClose} />
      </Dialog.Header>
      <StyledBody>
        <InfoMessage table={table} />

        <NestedPickerPane
          pickerMode="dataTables"
          dialog
          options={makeOptions(dataTables)}
          loading={loading}
          value={selectedID}
          onChange={(selectedID) => setSelectedID(selectedID)}
          renderListItem={(item, isSubItem) => <div>{item.name}</div>}
          renderItemDetail={(item) =>
            item ? (
              <FlexColumnContainer
                alignItems="flex-start"
                style={{ height: '100%', overflowY: 'auto' }}
              >
                <DataTablePane
                  readOnly
                  hideHeader
                  table={item.detail}
                  showTableType
                  projectID={null}
                />
              </FlexColumnContainer>
            ) : null
          }
        />
      </StyledBody>
      {deleteColumns.length > 0 ? (
        <DeleteColMessage table={table} columns={deleteColumns} />
      ) : null}
      <Dialog.Footer>
        <Button
          disabled={!selectedID}
          onClick={async (e) => {
            await onChange(e, {
              name,
              schema: { ...table.schema, elements: newColumns },
            });
            onClose();
          }}
        >
          Copy columns
        </Button>
      </Dialog.Footer>
    </StyledDialog>
  );
}
