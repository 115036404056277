// THIS IS A GIANT HACK
// WE NEED A REAL COLOR
// LIBRARY

export const gray1 = '#4a4a4a';
export const gray1a = '#777';
export const gray2 = '#999';
export const gray2a = '#ccc';
export const gray3 = '#dbdbdc';
export const gray3a = '#c7c7c7';
export const gray4 = '#E8E8E8';
export const gray4a = '#EEEFF2';
export const gray5 = '#fafafa';
export const gray6 = '#fefefe';
export const gray7 = '#f2f2f2';
export const gray7b = '#f1f5fa';
export const gray8 = '#CDCDCD';
export const gray9 = '#2f3c4c';
export const gray10 = '#F7F8FB';
export const gray11 = '#F6F8F9';
export const gray_border = '#e0e0e0';
// new color scheme
export const gray1b = '#212E3F';
export const gray2b = '#415464';
export const gray2c = '#384c60';
export const gray3c = '#d9e0e2';
export const gray4b = '#3d4f58';
export const gray5b = '#88989a';
export const gray6b = '#A0AAB2';
export const grayDropdownHov = '#F2F2F2';

export const gray1c = '#212e3f';
export const gray1d = '#666';
export const gray1e = '#444';

export const blue1 = '#46a5e1';
export const blue2 = '#0d89d7';
export const blue3 = '#87cefa';
export const blue3b = '#c1dced';
export const blue4 = '#d1ecfd';
export const blue5 = '#f6f9fb';
export const blue6 = '#b1dbf5';
export const blue7 = '#d2f2fb';
export const blue8 = '#edeff2';
export const blue9 = '#f6f8fB';

export const yellow1 = '#ffefd5';
export const yellow1b = '#ffbf2e';
export const yellow2 = '#F8FFDA';
export const yellow3 = '#f9d37c';
export const yellow3b = '#f6dea7';
export const yellow4 = '#fef5d3';
export const yellow5 = '#f9f2e3';
export const yellow6 = '#faf5ea';
export const yellow7 = '#FFFAF3';
export const yellow8 = '#FEF3EE';
export const yellow_active = '#fdb800';

export const orange1 = '#ffbf00';
export const orange2 = '#FDCA2F';
export const orange3 = '#ed8b21';
export const orange1b = '#c04f2e';

export const red1 = '#9F3A38';
export const red1b = '#ce4f4f';
export const red1c = '#f75e5e';
export const red1d = '#ffc6c5';
export const red2 = '#cb1849';
export const red2b = '#f89797';
export const red3 = '#c85877';
export const red4 = '#a82d2b';
export const red5 = '#FFF6F6';

export const green1 = '#50B28B';
export const green2 = '#52CA8D';
export const green3 = '#D3EFD3';
export const green4 = '#E3FFE3';
export const green5 = '#35C27F';

export const green1b = '#375a48';

export const black = '#333';
export const white = '#fff';

// third party
export const gSheetGreen = '#34A853';
export const qbGreen = '#2ca01c';
export const excelGreen = '#107C41';
export const snowflakeBlue = '#29B5E8';

// Action color scheme
// Tip: On hover the color gets darker

// Blue
export const create_blue = '#46a5e1';
export const create_blue_hover = '#0d89d7';
export const active_blue = '#d2f2fb';

// Green
export const run_green = '#52CA8D';
export const run_green_hover = '#50B28B';

// Red
export const cancel_red = '#f75e5e';
export const cancel_red_hover = '#ce4f4f';

// Yellow
export const control_yellow = '#fef5d3';
export const control_yellow_hover = '#fef5d3';
