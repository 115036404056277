import React, { Fragment, useMemo, useState } from 'react';
import { AuthWrapper } from '../styles';
import { RoundedContentWrapper } from '<src>/components/NumbrzVerticalEditor';
import { Message } from 'semantic-ui-react';
import CredentialsDialog from '../CredentialsDialog';
import User from '<sections>/account/pages/Integrations/User';
import Link from '<src>/components/Link';
import Button from '<components>/Button';
import { FlexRowContainer } from '<src>/components/NumbrzPageComponents';
import UnderlinedHeader from '<src>/components/UnderlinedHeader';

function getCredType(source) {
  switch (source) {
    case 'gsheets':
    case 'bigquery':
      return 'google';
    case 'msexcel':
      return 'microsoft';
    case 'snowflake':
      return 'snowflake';
    case 'dropbox':
      return 'dropbox';
    case 'quickbooks':
      return 'quickbooks';
    case 'files':
      return 'files';
    default:
      return undefined;
  }
}

export default function AuthPane({ credentials, tableset, onTablesetChange }) {
  const [dialogVisible, setDialogVisible] = useState(false);

  const credID = tableset.credentialID;
  const credType = getCredType(tableset.connector.name);

  const sourceCreds = useMemo(() => {
    if (credType === 'nbz' || !credType) return [];
    return credentials.filter((c) => c.providerName === credType);
  }, [credType, credentials]);

  const tablesetCred = useMemo(() => {
    if (!credID) return null;
    return credentials.find((c) => c.ID === credID);
  }, [credentials, credID]);

  const errors = tableset.state.issues;

  return (
    <Fragment>
      <RoundedContentWrapper>
        <UnderlinedHeader justifyContent="space-between">
          AUTHENTICATION
          <Link
            size="small"
            blue
            internal
            bold={false}
            to="/account/integrations"
          >
            Manage users
          </Link>
        </UnderlinedHeader>

        {errors.length > 0 &&
          errors.map((err) => {
            if (err.code === 'TablesetNeedsAuth') {
              return (
                <Message error>
                  This data no longer has access to its external source. Please
                  reauthorize the current user or authenticate it using a
                  different user.
                  <FlexRowContainer
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Link blue internal to="/account/integrations">
                      Reauthorize user
                    </Link>

                    {sourceCreds.length > 0 && (
                      <Button
                        size="small"
                        action="create"
                        onClick={() => setDialogVisible(true)}
                      >
                        Change user
                      </Button>
                    )}
                  </FlexRowContainer>
                </Message>
              );
            }
            if (err.code === 'CredentialNotFound') {
              return (
                <AuthWrapper>
                  <Message error style={{ marginTop: '10px', width: '100%' }}>
                    This data has no access to its external source. Please
                    change the user to proceed or create a new user.
                    <FlexRowContainer
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      {sourceCreds.length > 0 ? (
                        <Button
                          size="small"
                          action="create"
                          onClick={() => setDialogVisible(true)}
                        >
                          Change user
                        </Button>
                      ) : (
                        <Link blue internal to="/account/integrations">
                          Create new user
                        </Link>
                      )}
                    </FlexRowContainer>
                  </Message>
                </AuthWrapper>
              );
            }
            return null;
          })}
        {tablesetCred && (
          <User
            cred={tablesetCred}
            readOnly
            showCredPicker={sourceCreds.length > 0}
            onShowCredPicker={() => setDialogVisible(true)}
          />
        )}
      </RoundedContentWrapper>
      <CredentialsDialog
        visible={dialogVisible}
        onDialogClose={() => setDialogVisible(false)}
        creds={sourceCreds}
        activeCred={tablesetCred}
        onCredChange={onTablesetChange}
      />
    </Fragment>
  );
}
