import React from 'react';
import fp from 'lodash/fp';
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import styled from '@emotion/styled';

import { useQuery } from '<src>/apollo/client';

import MainLayout, { Menu } from '<components>/MainLayout';
import Masthead from '<components>/Masthead';
import NotFound from '<components>/Routes/NotFound';

import PersonalInfo from './pages/PersonalInfo';
import Billing from './pages/Billing';
import Integrations from './pages/Integrations';
import MemberInvitation from './pages/MemberInvitation';
import Members from './pages/Members';
import WaveSpinner from '<src>/components/WaveSpinner';
import AccountSwitcher from './components/AccountSwitcher';
import { GetViewer } from '<sections>/projects/queries';
import getFeatures from '<src>/components/Feature/getFeatures.gql';
import {
  PageContainer,
  Content,
  ContentColumnLayout,
} from '<components>/NumbrzPageComponents';

const MenuWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const StyledContent = styled(Content)`
  padding-left: 0;
  width: 100%;
`;

const makeNavItems = (match, featureFlags, primaryAccount, isUserAdmin) => [
  ...(isUserAdmin
    ? [
        {
          path: `${match.url}/profile`,
          title: 'Profile',
        },
      ]
    : []),

  ...(primaryAccount['__typename'] === 'Org'
    ? [
        {
          path: `${match.url}/billing`,
          title: 'Billing',
        },
      ]
    : []),
  {
    path: `${match.url}/integrations`,
    title: 'Integrations',
  },

  ...(primaryAccount['__typename'] === 'Org'
    ? [
        {
          path: `${match.url}/members`,
          title: 'Members',
        },
      ]
    : []),
];

const BreadcrumbTitle = ({ rootURL, title, extraBreadcrumbs = [] }) => (
  <DocumentTitle title={`${title} - Numbrz`}>
    <Masthead
      title={title}
      breadcrumbs={[{ title: 'Account', path: rootURL }, ...extraBreadcrumbs]}
    />
  </DocumentTitle>
);

export default function NumbrzAccountSection() {
  const match = useRouteMatch();
  const history = useHistory();
  const { data: { viewer } = {}, loading } = useQuery(GetViewer);
  const { data: featureFlagsData, loading: loadingFeatures } = useQuery(
    getFeatures,
    {
      fetchPolicy: 'cache-first',
    }
  );

  const featureFlags = fp.getOr([], 'viewer.featureFlags', featureFlagsData);

  const primaryAccount = fp.getOr(undefined, 'account.primaryAccount', viewer);

  const activePlanID = fp.getOr(
    undefined,
    'account.primaryAccount.stripeProductID',
    viewer
  );
  let isUserAdmin = false;

  // check to see if current user is an admin of the primaryAccount
  // if not then provide the user with limited functionality
  if (primaryAccount) {
    const userMembership = viewer.account.membership.find(
      (m) => m.account.ID === primaryAccount.ID
    );
    if (userMembership && userMembership.roles.includes('Administrator'))
      isUserAdmin = true;
  }

  const header = () => {
    return (
      <Switch>
        <Route path={`${match.url}/profile`}>
          <BreadcrumbTitle rootURL={match.url} title="Profile" />
        </Route>
        <Route path={`${match.url}/integrations`}>
          <BreadcrumbTitle rootURL={match.url} title="Integrations" />
        </Route>
        <Route path={`${match.url}/security`}>
          <BreadcrumbTitle rootURL={match.url} title="Security" />
        </Route>
        <Route path={`${match.url}/billing`}>
          <BreadcrumbTitle rootURL={match.url} title="Billing" />
        </Route>
        <Route path={`${match.url}/members`}>
          <BreadcrumbTitle rootURL={match.url} title="Members" />
        </Route>
        <Route exact path={`${match.url}/accept-invite/:token`}>
          <BreadcrumbTitle rootURL={match.url} title="Member Invitation" />
        </Route>
        <Route>
          <BreadcrumbTitle rootURL={match.url} title="Account" />
        </Route>
      </Switch>
    );
  };

  const body = () => {
    return loading ? null : (
      <PageContainer>
        <ContentColumnLayout>
          <MenuWrapper>
            <AccountSwitcher history={history} />
            <Menu
              navigation={makeNavItems(
                match,
                featureFlags,
                primaryAccount,
                isUserAdmin
              )}
              layout="vertical"
            />
          </MenuWrapper>
          <StyledContent>
            <Switch>
              <Route exact path={`${match.url}/accept-invite/:token`}>
                <MemberInvitation />
              </Route>
              <Route path={`${match.url}/profile`}>
                <PersonalInfo />
              </Route>
              <Route path={`${match.url}/members`}>
                <Members
                  viewer={viewer}
                  isUserAdmin={isUserAdmin}
                  orgID={primaryAccount.ID}
                  activePlanID={activePlanID}
                />
              </Route>

              <Route path={`${match.url}/billing`}>
                <Billing isUserAdmin={isUserAdmin} />
              </Route>

              <Route path={`${match.url}/integrations/`}>
                <Integrations isUserAdmin={isUserAdmin} />
              </Route>
              <Route path={match.url}>
                <Redirect
                  to={
                    primaryAccount['__typename'] === 'Org'
                      ? `${match.url}/billing`
                      : `${match.url}/profile`
                  }
                />
              </Route>

              <Route component={NotFound} />
            </Switch>
          </StyledContent>
        </ContentColumnLayout>
      </PageContainer>
    );
  };

  if (loadingFeatures) return <WaveSpinner />;

  return <MainLayout hideMenu header={header()} main={body()} />;
}
