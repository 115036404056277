import React from 'react';
import Pane from './Pane';
import Dialog from './Dialog';
import Connected from './connector';

const ConnectedPane = (props) => <Connected {...props} Comp={Pane} />;
const ConnectedDialog = (props) => <Connected {...props} Comp={Dialog} />;

ConnectedPane.Dialog = ConnectedDialog;
export default ConnectedPane;
