import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { CompactDropdown } from '<components>/NumbrzPageComponents';

import { ConfigTable } from '../../styles';
import Button from '<src>/components/Button';
import ElementLabel from '../../ElementLabel';
import FieldPicker from '../../FieldPicker';

import { calcLabels, calcOptions } from './calcs';

const CalcSep = styled('div')`
  font-style: italic;
  text-align: center;
`;

function AddCalc({ elements, onAddCalc }) {
  const [calc, setCalc] = useState();
  const [field, setField] = useState();

  const onAdd = useCallback(
    (e, { calc, field }) => {
      onAddCalc(e, { calc, field });
      setCalc(null);
      setField(null);
    },
    [onAddCalc]
  );

  const onChangeCalc = useCallback((e, { value }) => setCalc(value), []);
  const onChangeField = useCallback(
    (e, { selected }) => setField(selected),
    []
  );
  return (
    <tr>
      <td className="field" colSpan="3">
        <CompactDropdown
          value={calc}
          placeholder="Calculation"
          options={calcOptions}
          onChange={onChangeCalc}
        />
      </td>
      <td>
        <CalcSep>of</CalcSep>
      </td>
      <td className="field">
        <FieldPicker.Dropdown
          elements={elements}
          selected={field}
          showTypeFilter={false}
          onChange={onChangeField}
          restrictTypes={['Number', 'Date', 'DateTime']}
        />
      </td>
      <td className="btn">
        <Button.TableAddBtn
          title="Add Calculate By"
          disabled={!field && calc !== 'Cnt'}
          onClick={(e) => onAdd(e, { calc, field: field || '' })}
          baseColor="transparent"
        />
      </td>
    </tr>
  );
}
export default function CalcList({ elements, calcs, onAddCalc, onRemoveCalc }) {
  return (
    <ConfigTable className="list">
      <tbody>
        {calcs.map((calc, idx) => (
          <tr key={idx}>
            <td className="field" colSpan="3">
              {calcLabels[calc.op]}
            </td>
            <td>
              <CalcSep>of</CalcSep>
            </td>
            <td className="field">
              {calc.op === 'Cnt' && !calc.fromElementKey ? (
                <em>Records</em>
              ) : (
                <ElementLabel
                  elementKey={calc.fromElementKey}
                  element={elements.find((e) => e.key === calc.fromElementKey)}
                />
              )}
            </td>
            {onRemoveCalc ? (
              <td className="btn">
                <Button.TableDeleteBtn onClick={(e) => onRemoveCalc(e, calc)} />
              </td>
            ) : null}
          </tr>
        ))}
        {onAddCalc ? (
          <AddCalc elements={elements} onAddCalc={onAddCalc} />
        ) : null}
      </tbody>
    </ConfigTable>
  );
}
