/** @jsx jsx */
/** @jsxRuntime classic */

import { PureComponent } from 'react';
import { jsx } from '@emotion/react';
import T from 'prop-types';
import classnames from 'classnames';
import get from 'lodash/get';

import withClassName from '<components>/hoc/className';
import withDefaultProps from '<components>/hoc/defaultProps';
import withExtraProps from '<components>/hoc/extraProps';
import withFilteredProps from '<components>/hoc/filteredProps';

import { getEmptyImage } from 'react-dnd-html5-backend';

import ControlAction from './Action';
import ControlActions from './Actions';
import ControlDivider from './Divider';
import ControlCorner from './Corner';

import styles from '../styles';

@withExtraProps((props) => ({
  focusable: props.focusable || props.draggable,
  getItem: props.getDragItem,
}))
@withDefaultProps({
  insertOrder({ dragItem, position }) {
    const dragItemPosition = get(dragItem, 'position');
    return position < dragItemPosition ? 'before' : 'after';
  },
  as: 'th',
  root: document.body,
  vertical: false,
  horizontal: false,
  draggable: false,
  droppable: false,
  focusable: false,
})
@withClassName((props) => {
  const insertOrder = props.insertOrder(props);

  return classnames({
    [styles.control.base]: true,
    [styles.control.vertical]: props.vertical,
    [styles.control.horizontal]: props.horizontal,
    [styles.control.draggable]: props.draggable,
    [styles.control.dropHovered.base]: props.isOver && props.canDrop,
    [styles.control.dropHovered[insertOrder]]: true,
  });
})
@withFilteredProps([
  'children',
  'draggable',
  'droppable',
  'focusable',
  'horizontal',
  'vertical',
  'position',
  'rulePosition',
  'insertOrder',
  'onBeginDrag',
  'canDrop',
  'connectDragPreview',
  'connectDragSource',
  'connectDropTarget',
  'dragItem',
  'getDragItem',
  'getItem',
  'isDragging',
  'itemType',
  'isOver',
  'isOverCurrent',
  'onDrop',
])
export default class Control extends PureComponent {
  static Action = ControlAction;
  static Actions = ControlActions;
  static Divider = ControlDivider;
  static Corner = ControlCorner;

  static propTypes = {
    connectDropTarget: T.func,
    connectDragSource: T.func,
    draggable: T.bool,
    droppable: T.bool,
    focusable: T.bool,
    getItem: T.func, // TODO: required if draggable
    onDrop: T.func, // TODO: required if droppable
    root: T.instanceOf(Element),
  };

  componentDidMount() {
    const { connectDragPreview } = this.props;
    connectDragPreview(getEmptyImage(), { captureDraggingState: true });
  }

  bindRef = (el) => (this.el = el);

  getDimensions = () => {
    const { horizontal } = this.props;

    const compStyle = getComputedStyle(this.el);

    const width = horizontal
      ? compStyle.getPropertyValue('width')
      : compStyle.getPropertyValue('--tableWidth');

    const height = horizontal
      ? compStyle.getPropertyValue('--tableHeight')
      : compStyle.getPropertyValue('height');

    return { width, height };
  };

  render() {
    const {
      as: Tag,
      children,
      filteredProps,
      focusable,
      vertical,
      horizontal,
      isOver,
      canDrop,
    } = this.props;

    const insertOrder = this.props.insertOrder(this.props);

    const controlStyles = {
      ...styles.control.base,
      ...(vertical && styles.control.vertical),
      ...(horizontal && styles.control.horizontal),
      ...(isOver && canDrop && styles.control.dropHovered.base),
      ...styles.control.dropHovered[insertOrder],
    };

    return (
      <Tag
        {...filteredProps}
        ref={this.bindRef}
        {...(focusable && { role: 'button', tabIndex: -1 })}
        css={controlStyles}
      >
        <div css={styles.control.target.base}>
          <div css={styles.control.target.handle} />
          <div css={styles.control.target.insertLine} />
        </div>

        {children}
      </Tag>
    );
  }
}
