import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import styled from '@emotion/styled';
import Button from '<components>/Button';
import { Message } from 'semantic-ui-react';
import CredentialPickerPane from '../../TablesetList/CredentialPickerPane';

import Dialog from '<components>/Dialog';

const DialogFooter = styled(Dialog.Footer)`
  & > button {
    margin-left: 0;
    margin-right: 10px;
  }
  & > button:last-child {
    margin-right: 0;
  }
  display: flex;
`;

const StyledDialog = styled(Dialog)`
  min-width: 450px;
`;

const StyledDialogBody = styled(Dialog.Body)`
  padding-top: 10px;
`;

export default function CredentialsDialog({
  visible,
  onDialogClose,
  activeCred,
  creds = [],
  onCredChange,
}) {
  const [selectedCred, setSelectedCred] = useState(activeCred);
  const [errors, setErrors] = useState();

  const handleCredChange = async (data) => {
    setErrors(null);
    const res = await onCredChange('credentialID', data.ID);
    if (res?.errors?.length > 0) {
      setErrors(res?.errors);
    } else {
      onDialogClose();
    }
  };

  return (
    <StyledDialog
      visible={visible ? 1 : 0}
      onRequestClose={() => {
        setErrors(null);
        onDialogClose();
      }}
    >
      <Dialog.Header>
        <Dialog.Headline>Change user</Dialog.Headline>
        <Button.DialogClose onClick={onDialogClose} />
      </Dialog.Header>
      <StyledDialogBody>
        {creds.length === 0 && (
          <p>
            No users found. <Link to="/account/integrations">Manage Users</Link>
          </p>
        )}
        {errors?.length > 0 && <Message error>{errors[0].message}</Message>}
        <CredentialPickerPane
          credentials={creds}
          selectedItem={selectedCred}
          setActiveCred={(cred) => {
            setSelectedCred(cred);
            setErrors(null);
          }}
          hideHdr
        />
      </StyledDialogBody>
      <DialogFooter>
        <Button
          disabled={!selectedCred}
          onClick={() => handleCredChange(selectedCred)}
        >
          Authorize
        </Button>
      </DialogFooter>
    </StyledDialog>
  );
}
