import React from 'react';

import { useMutation, useQuery } from '<src>/apollo/client';

import WaveSpinner from '<components>/WaveSpinner';

import Releases from '<sections>/projects/components/Releases';

import {
  GetProjectReleases,
  CreateProjectRelease,
} from '<sections>/projects/queries';

import { GetServiceProject, PromoteRelease } from '../../../queries';

export default function ProjectReleases({
  project,
  releaseTagValid,
  onCheckReleaseTag,
  updateProject,
}) {
  const variables = { projectID: project.ID };
  const { data, loading } = useQuery(GetProjectReleases, {
    variables,
  });
  const [createRelease] = useMutation(CreateProjectRelease);
  const [promoteRelease] = useMutation(PromoteRelease);

  if (loading) {
    return <WaveSpinner />;
  }

  const onPublishPreview = async () =>
    await updateProject({
      variables: {
        input: { projectID: project.ID, catalog: { preview: true } },
      },
    });

  const onCreateRelease = async (input) => {
    const resp = await createRelease({
      variables: { input: { ...input, projectID: project.ID } },
      update: (cache, { data: { createProjectRelease: newRelease } }) => {
        if (!newRelease) {
          return;
        }
        const { projectReleases } = cache.readQuery({
          query: GetProjectReleases,
          variables,
        });
        cache.writeQuery({
          query: GetProjectReleases,
          variables,
          data: { projectReleases: [newRelease, ...projectReleases] },
        });
        if (input.promote) {
          const variables = { ID: project.ID };
          const { project: cachedProject } = cache.readQuery({
            query: GetServiceProject,
            variables,
          });
          cache.writeQuery({
            query: GetServiceProject,
            variables,
            data: {
              project: {
                ...cachedProject,
                catalog: {
                  ...cachedProject.catalog,
                  currentReleaseID: newRelease.ID,
                  currentRelease: {
                    __typename: newRelease.__typename,
                    releaseTag: newRelease.releaseTag,
                    releasedAt: newRelease.releasedAt,
                  },
                },
              },
            },
          });
        }
      },
    });
    return resp;
  };

  const onPromoteRelease = (releaseID) =>
    promoteRelease({
      variables: { input: { releaseID, projectID: project.ID } },
    });

  return (
    <Releases
      project={project}
      releases={data.projectReleases}
      onCreate={onCreateRelease}
      onPublishPreview={onPublishPreview}
      onPromote={onPromoteRelease}
      releaseTagValid={releaseTagValid}
      onCheckReleaseTag={onCheckReleaseTag}
    />
  );
}
