import React, { useState, Fragment, useContext } from 'react';
import DocumentTitle from 'react-document-title';
import { useHistory } from 'react-router-dom';

import { useMutation, useQuery } from '<src>/apollo/client';

import { RunJob } from '<sections>/jobs/queries';
import MainLayout from '<components>/MainLayout';
import Sidebar from '<components>/MainLayout/Sidebar';
import Masthead from '<components>/Masthead';
import AddNameDialog from '<components>/AddNameDialog';

import { GetProjects, CreateProject, DeleteProject } from '../../queries';

import ProjectList from './List';
import {
  ColumnWrapper,
  PageContainer,
} from '<src>/components/NumbrzPageComponents';
import ResizeableSplitView from '<src>/sections/flows/flow-testing/ResizeableSplitView';
import { FlowContainer } from '<src>/components/NumbrzVerticalEditor';
import Button from '<components>/Button';
import ModelWelcomeScreen from '<sections>/onboarding/welcome-screens/ModelsWelcomeScreen';
import { UserPreferencesContext } from '<components>/UserPreferences';
import VideoPopup from '<sections>/onboarding/components/VideoPopup';

const labels = {
  Standard: {
    objType: 'Model',
    addLabel: 'a model',
    noWelcome: false,
  },
  Service: {
    objType: 'Service Model',
    addLabel: 'a service model',
    noWelcome: true,
  },
};

export default function ProjectListPage({
  baseURL,
  type,
  AddProjectDialog = AddNameDialog,
}) {
  const types = type ? [type] : undefined;
  type = type ? type : 'Standard';
  const history = useHistory();
  const variables = { types };
  const { onboardingPrefs, showHideWelcomeScreen } = useContext(
    UserPreferencesContext
  );
  const [videoPopupVisible, setVideoPopupVisible] = useState(false);
  const [activeVideo, setActiveVideo] = useState(null);

  const { welcomeScreens } = onboardingPrefs;
  const showWelcomeScreen = welcomeScreens.model;

  const { data: { projects = [] } = {}, loading } = useQuery(GetProjects, {
    variables,
  });
  const [createProject] = useMutation(CreateProject);
  const [deleteProject] = useMutation(DeleteProject, {
    update: (cache, { data: { removeProject: ID } }) => {
      const { projects = [] } = cache.readQuery({
        query: GetProjects,
        variables,
      });
      const newProjects = projects.filter((p) => p.ID !== ID);

      cache.writeQuery({
        query: GetProjects,
        variables,
        data: { projects: newProjects },
      });
    },
  });
  const [runJob] = useMutation(RunJob);

  const [createDialogVisible, setCreateDialogVisible] = useState(false);

  const { objType } = labels[type] ? labels[type] : labels.Standard;

  const onCreateProject = async (name, e, extraVars = {}) => {
    const { data } = await createProject({
      variables: {
        input: { name, type, ...extraVars },
      },
    });
    const projectID = data.createProject.ID;
    history.push(`${baseURL}/${projectID}`);
  };

  const welcomeScreen = () => (
    <Fragment>
      <ModelWelcomeScreen
        onHide={() => showHideWelcomeScreen('model', 'hide')}
        onVideoClick={(video) => {
          setActiveVideo(video);
          setVideoPopupVisible(true);
        }}
      />
      <VideoPopup
        onClose={() => {
          setVideoPopupVisible(false);
          setActiveVideo(null);
        }}
        visible={videoPopupVisible}
        video={activeVideo}
      />
    </Fragment>
  );

  const body = () => (
    <PageContainer>
      <ResizeableSplitView
        left={
          <Fragment>
            <Sidebar hideMasthead />
            <FlowContainer>
              <ColumnWrapper>
                {showWelcomeScreen && !loading && projects.length === 0 ? (
                  welcomeScreen()
                ) : (
                  <ProjectList
                    baseURL={baseURL}
                    history={history}
                    loading={loading}
                    projects={projects}
                    deleteProject={deleteProject}
                    runJob={runJob}
                  />
                )}

                <Button.ListPageCreateBtn
                  onClick={() => setCreateDialogVisible(true)}
                >
                  Create a model
                  <span className="icon">+</span>
                </Button.ListPageCreateBtn>
                <AddProjectDialog
                  objType={objType}
                  onClose={() => setCreateDialogVisible(false)}
                  onCreate={onCreateProject}
                  visible={createDialogVisible}
                />
              </ColumnWrapper>
            </FlowContainer>
          </Fragment>
        }
        right={null}
      />
    </PageContainer>
  );

  return (
    <DocumentTitle title="Model List - Numbrz">
      <MainLayout
        overflowY="hidden"
        navigation={false}
        header={<Masthead />}
        main={body()}
      />
    </DocumentTitle>
  );
}
