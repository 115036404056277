import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import WaveSpinner from '<src>/components/WaveSpinner';
import Status from './Status';

const stopForStatuses = ['JobFailed', 'InputsFailed', 'Completed', 'Cancelled'];

export default function JobStatus({ jobState, running, setRunning }) {
  const history = useHistory();
  const { runID } = useParams();
  const [polling, setPolling] = useState(false);

  const {
    jobStatus = {},
    loadRunHistory,
    loadingRunHistory,
    loadJobStatus,
    loadingStatus,
    startStatusPolling,
    stopStatusPolling,
    startRunHistoryPolling,
    stopRunHistoryPolling,
    project,
    job = {},
  } = jobState;

  const { status } = jobStatus;

  useEffect(() => {
    if (!loadingStatus) {
      loadJobStatus(runID);
    }
    if (!loadingRunHistory) {
      loadRunHistory(runID);
    }
  }, [runID]); // TODO figure out why we can't list all deps here

  useEffect(() => {
    if (!status) return;
    if (polling && stopForStatuses.includes(status)) {
      setRunning(false);
      setPolling(false);
      stopStatusPolling();
      stopRunHistoryPolling();
    } else if (!polling && !stopForStatuses.includes(status)) {
      setPolling(true);
      setRunning(true);
      startStatusPolling(3000);
      startRunHistoryPolling(3000);
    }
  }, [status, polling]); // TODO figure out why we can't list all deps here

  return loadingStatus ? (
    <WaveSpinner />
  ) : (
    <Status
      {...jobStatus}
      running={running}
      project={project}
      loading={loadingStatus}
      error={undefined}
      projectID={project.ID}
      history={history}
      startPolling={startStatusPolling}
      stopPolling={stopStatusPolling}
      externalResultURL={job.externalResultURL}
    />
  );
}
