import React from 'react';
import styled from '@emotion/styled';
import * as colors from '<components>/colors';

import { Dropdown, Label } from 'semantic-ui-react';
import {
  Separator,
  StyledScrollbar,
} from '<src>/components/NumbrzPageComponents';

export const MastheadToolbar = styled('div')`
  display: flex;
  align-items: center;
  margin-right: 5px;
  .react-datepicker__input-container {
    span {
      display: none;
    }
  }
`;

export const DateRangeWrapper = styled('div')`
  display: flex;
  margin-right: 10px;
  .react-datepicker-wrapper {
    margin: 12px 0;
    width: 100%;
  }
`;

export const StyledLabel = styled(Label)`
  margin-left: 5px;
  padding: 3px 8px !important;

  display: flex !important;
  align-items: center;
  border-radius: 2px;
  background: transparent !important;

  .dropdown {
    border: 1px solid ${colors.gray_border};
    border-radius: 4px;
    i {
      border: none;
    }
  }

  .react-datepicker-wrapper {
    margin: 0;
    input {
      height: 20px;
      border: 1px solid ${colors.gray_border};
      border-radius: 4px;
      max-width: 85px;
      padding-left: 10px;
    }
  }
  span {
    padding-right: 5px;
    text-transform: none;
    font-weight: 500;
    font-size: 12px;
  }
`;

export const StyledDropdown = styled(Dropdown)`
  background: white;
  border: none !important;
  height: 20px;
  border-radius: 4px;
  width: 100%;
  max-width: 250px;
  display: flex !important;
  align-items: center;
  padding-left: 10px;
  margin-left: -30px;

  .menu {
    max-height: 150px;
    overflow: auto;
    ${StyledScrollbar}
    .item {
      line-height: 5px;
    }
  }
`;

export const ConfirmText = styled('span')`
  transition:
    width 300ms ease-in-out,
    opacity 300ms ease-in-out;

  width: 0;
  opacity: 0;
  overflow-x: hidden;

  font-weight: 500 !important;
  &.expanded {
    width: 65px;
    opacity: 100%;
  }
`;

export const DataSeparator = () => <Separator margin="0 5px" />;

export const AuthWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
