import React from 'react';
import { createRoot } from 'react-dom/client';
import 'semantic-ui-css/semantic.css';
import * as Sentry from '@sentry/react';

import { sessionID } from './errors';
import App from './App';

// eslint-disable-next-line import/no-unassigned-import
require('./assets/numbrz-logo-116.png'); // force inclusion in bundle

let dsn = 'https://61489da7373848ea820ce09fe8d3f181@sentry.io/1400467';
let environment;
switch (window.location.hostname) {
  case 'app.numbrz.com':
    environment = 'production';
    break;
  case 'staging.numbrz.com':
    environment = 'staging';
    break;
  default:
    environment = 'local';
    dsn = undefined;
    window.logErrorsToConsole = true;
    break;
}
Sentry.init({
  dsn,
  environment,
  release: __VERSION__,
  integrations: [
    Sentry.replayIntegration(),
    Sentry.browserTracingIntegration(),
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 0.1,
  beforeSend: (event) => {
    const { exception } = event;
    if (exception) {
      const { values } = exception;
      if (
        values &&
        values.length &&
        values.find((e) => e.value === 'ResizeObserver loop limit exceeded')
      ) {
        // This error is harmless and happens outside of any code we control
        // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
        return null;
      }
    }
    return event;
  },
  initialScope: {
    tags: { sessionID: sessionID },
  },
});

const container = document.getElementById('app');
const root = createRoot(container);

const ProfiledApp = Sentry.withProfiler(App);

root.render(<ProfiledApp />);
