import React, { useState, useEffect, useRef, useMemo, Fragment } from 'react';
import fp from 'lodash/fp';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import DocumentTitle from 'react-document-title';

import { useQuery, useMutation } from '<src>/apollo/client';

import MainLayout from '<components>/MainLayout';
import SidebarNavMenu from '<components>/NumbrzMenus/SidebarNavMenu';
import Masthead from '<components>/Masthead';
import RunJobErrorDialog from '<sections>/jobs/components/RunJobErrorDialog';
import useFeatures from '<components>/Feature/useFeatures';
import Button from '<components>/Button';

import {
  PageContainer,
  PageMasthead,
  ColumnWrapper,
  PageContent,
  FlexRowContainer,
} from '<components>/NumbrzPageComponents';
import {
  SidebarWrapper,
  SidebarHeader,
  SidebarContent,
  FlowContainer,
  RoundedContentWrapper,
} from '<components>/NumbrzVerticalEditor';
import ActionMenu from '<components>/ActionMenu';

import ResizeableSplitView from '<sections>/flows/flow-testing/ResizeableSplitView';
import { TitleField } from '<sections>/flows/styles';
import { RunJob } from '<sections>/jobs/queries';

import {
  UpdateProjectInfo,
  GetProjectJobs,
  CreateProjectRelease,
  UpgradeProject,
} from '../../queries';
import CatalogSettings from '../../components/CatalogSettings';

import { FunctionV3List, JobList, FlowList } from './components/lists';
import ProjectOverview from './components/ProjectOverview';
import RecentRuns from './components/RecentRuns';
import Releases from './components/Releases';

const Title = ({ title, projectName, projectID }) => (
  <DocumentTitle title={`${projectName} - Numbrz`}>
    <Masthead
      title={title}
      breadcrumbs={[
        {
          title: 'Models',
          path: `/`,
          type: 'root',
        },
        {
          title: projectName,
          path: `/models/${projectID}`,
        },
      ]}
      runBtnVisible={false}
      addShadow
    />
  </DocumentTitle>
);

function ProjectMasthead({
  handleInputChange,
  projectName,
  onRun,
  runBtnDisabled,
  externalResultURL,
  sidebarVisible,
  setSidebarVisible,
}) {
  const nameRef = useRef();
  const location = useLocation();

  const canEdit = !!handleInputChange;

  const SidebarToggleBtn = sidebarVisible
    ? Button.SidebarHideBtn
    : Button.SidebarShowBtn;

  useEffect(() => {
    if (canEdit && location.search === '?new' && nameRef.current) {
      nameRef.current.edit(true);
    }
  }, [canEdit, location.search]);

  return (
    <PageMasthead>
      <h5>
        <FlexRowContainer alignItems="center" justifyContent="flex-start">
          {!sidebarVisible && (
            <SidebarToggleBtn
              onClick={() => setSidebarVisible(!sidebarVisible)}
            />
          )}

          <TitleField
            ref={nameRef}
            placeholder="Click to edit"
            singleClick
            value={projectName}
            onSubmit={(e) => handleInputChange('name', e.value)}
          />
          <ActionMenu
            menuIconDark={true}
            menuIconName="angle down"
            menuDirection="right"
            options={[
              {
                value: 'run_job',
                icon: 'play',
                text: 'Run primary job',
                onSelect: onRun,
                disabled: runBtnDisabled,
              },
              ...(externalResultURL
                ? [
                    {
                      value: 'results',
                      text: 'Open external results',
                      icon: 'chart bar outline',
                      isLink: true,
                      href: externalResultURL,
                      onSelect: () => {},
                    },
                  ]
                : []),
            ]}
          />
        </FlexRowContainer>
      </h5>
    </PageMasthead>
  );
}

export default function ProjectPage({
  project,
  releaseTagValid,
  onCheckReleaseTag,
}) {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [runningJob, setRunningJob] = useState(false);
  const [runResult, setRunResult] = useState();
  const isNew = location.search === '?new';
  const features = useFeatures();

  const externalResultURL = fp.getOr(
    null,
    'primaryJobBinding.externalResultURL',
    project
  );

  const { data: { project: projectJobsData } = {}, loading: jobsLoading } =
    useQuery(GetProjectJobs, {
      variables: { ID: match.params.projectID },
      fetchPolicy: 'network-only',
    });

  const jobs = jobsLoading ? [] : projectJobsData.jobs;

  const [runJob] = useMutation(RunJob);
  const [updateProjectInfo] = useMutation(UpdateProjectInfo);
  const [createRelease] = useMutation(CreateProjectRelease);
  const [upgradeProject] = useMutation(UpgradeProject);

  useEffect(() => {
    if (location.search) {
      history.replace(location.pathname);
    }
  }, [history, location]);

  const handleInputChange = (fieldName, value) => {
    updateProjectInfo({
      variables: {
        input: {
          projectID: project.ID,
          [fieldName]: value,
        },
      },
    });
  };

  const handleUpgrade = () => upgradeProject({ variables: { ID: project.ID } });

  const handleRunProject = async () => {
    setRunningJob(true);

    const res = await runJob({
      variables: { input: { jobID: project.primaryJobID } },
    });
    setRunResult(res);

    if (fp.getOr(false, 'data.runJob.success', res)) {
      const url = `/models/${project.ID}/jobs/${project.primaryJobID}/status/${res.data.runJob.runID}`;

      history.push(url);
    }
  };

  const isDeployed = !!project.deployment;
  const options = [
    {
      text: 'Overview',
      key: 'overview',
      to: `${match.url}/overview`,
    },
    ...(project.type === 'Standard'
      ? [
          {
            text: 'Functions',
            key: 'functions',
            to: `${match.url}/functions`,
          },
          {
            text: 'Flows',
            key: 'flows',
            to: `${match.url}/flows`,
          },
        ]
      : []),
    {
      text: 'Jobs',
      key: 'jobs',
      to: `${match.url}/jobs`,
    },
    {
      to: `${match.url}/recent-runs`,
      text: 'Recent Runs',
      key: 'recent-runs',
    },
    ...(!isDeployed && features.PublishModel
      ? [{ to: `${match.url}/share`, text: 'Share', key: 'share' }]
      : []),
    ...(!isDeployed &&
    features.PublishModel &&
    project.catalog?.publishToCatalog
      ? [{ to: `${match.url}/releases`, text: 'Releases', key: 'releases' }]
      : []),
  ];

  const header = () => {
    const projectName = project.name ? project.name : 'Unnamed Project';

    const routeProps = {
      projectName,
      projectID: match.params.projectID,
      runBtnVisible: false,
    };

    return (
      <Switch>
        {options.map((opt) => (
          <Route key={opt.key} path={opt.to}>
            <Title title={opt.text} {...routeProps} />
          </Route>
        ))}
      </Switch>
    );
  };

  const runBtnDisabled = useMemo(() => {
    const { primaryJobBinding = {} } = project;

    if (primaryJobBinding && primaryJobBinding.runnable) {
      return false;
    }

    return true;
  }, [project]);

  const errorDialog = () => {
    const runFailed =
      !!runResult && !fp.getOr(false, 'data.runJob.success', runResult);
    return (
      <RunJobErrorDialog
        visible={runningJob && runFailed}
        runResult={runResult}
        onClose={() => {
          setRunningJob(false);
          setRunResult(null);
        }}
      />
    );
  };

  const SidebarToggleBtn = sidebarVisible
    ? Button.SidebarHideBtn
    : Button.SidebarShowBtn;

  const body = () => {
    return (
      <PageContainer>
        <ResizeableSplitView
          left={
            <Fragment>
              <SidebarWrapper visible={sidebarVisible}>
                <SidebarHeader>
                  <span />
                  {sidebarVisible && (
                    <SidebarToggleBtn
                      title="Show navigation"
                      onClick={() => setSidebarVisible(!sidebarVisible)}
                    />
                  )}
                </SidebarHeader>
                <SidebarContent>
                  <SidebarNavMenu options={options} />
                </SidebarContent>
              </SidebarWrapper>
              <FlowContainer>
                <ProjectMasthead
                  handleInputChange={handleInputChange}
                  projectName={project.name}
                  onRun={handleRunProject}
                  runBtnDisabled={runBtnDisabled || runningJob}
                  externalResultURL={externalResultURL}
                  sidebarVisible={sidebarVisible}
                  setSidebarVisible={setSidebarVisible}
                />
                <ColumnWrapper>
                  <Switch>
                    <Route path={`${match.url}/overview`}>
                      <PageContent>
                        <ProjectOverview
                          isNew={isNew}
                          project={project}
                          jobs={jobs}
                          updateProjectInfo={updateProjectInfo}
                          upgradeProject={handleUpgrade}
                          handleInputChange={handleInputChange}
                          handleRunProject={handleRunProject}
                        />
                      </PageContent>
                    </Route>
                    <Route path={`${match.url}/functions`}>
                      <FunctionV3List
                        projectID={match.params.projectID}
                        objType="function"
                      />
                    </Route>
                    <Route path={`${match.url}/jobs`}>
                      <JobList
                        projectID={match.params.projectID}
                        objType="job"
                      />
                    </Route>
                    <Route path={`${match.url}/flows`}>
                      <FlowList
                        projectID={match.params.projectID}
                        objType="flow"
                      />
                    </Route>
                    {!isDeployed && features.PublishModel ? (
                      <Route path={`${match.url}/releases`}>
                        <Releases project={project} />
                      </Route>
                    ) : null}
                    <Route path={`${match.url}/recent-runs`}>
                      <RecentRuns project={project} />
                    </Route>
                    {!isDeployed && features.PublishModel ? (
                      <Route path={`${match.url}/share`}>
                        <PageContent>
                          <RoundedContentWrapper>
                            <CatalogSettings
                              project={project}
                              updateProject={updateProjectInfo}
                              createRelease={createRelease}
                              releaseTagValid={releaseTagValid}
                              onCheckReleaseTag={onCheckReleaseTag}
                            />
                          </RoundedContentWrapper>
                        </PageContent>
                      </Route>
                    ) : null}
                    <Route>
                      <Redirect to={`${match.url}/overview`} />
                    </Route>
                  </Switch>
                </ColumnWrapper>
              </FlowContainer>
            </Fragment>
          }
          right={null}
        />

        {errorDialog()}
      </PageContainer>
    );
  };

  return (
    <MainLayout
      overflowY="hidden"
      navigation={false}
      header={header()}
      main={body()}
    />
  );
}
