import React from 'react';
import Table from '<components>/Table';
import moment from 'moment';
import styled from '@emotion/styled';

import {
  InvoiceTable,
  InvoiceHeaderRow,
  InvoiceUpcomingRow,
} from '../../styles';

const LongHeader = styled(Table.Header)`
  column-count: 6;
`;

export default function UpcomingInvoice({ invoice }) {
  return (
    <InvoiceTable>
      <Table.Head>
        <InvoiceHeaderRow>
          <Table.Header>Invoice Date</Table.Header>
          <Table.Header>Billing Period</Table.Header>
          <Table.Header className="centered">Amount Due (USD)</Table.Header>
          <LongHeader />
        </InvoiceHeaderRow>
      </Table.Head>
      <Table.Body>
        <InvoiceUpcomingRow>
          <Table.Cell>
            {moment.utc(invoice.created * 1000).format('MMM DD, YYYY')}
          </Table.Cell>
          <Table.Cell>
            {`${moment
              .utc(invoice.period_start * 1000)
              .format('MMM DD, YYYY')} - ${moment
              .utc(invoice.period_end * 1000)
              .format('MMM DD, YYYY')}`}
          </Table.Cell>
          <Table.Cell className="centered">
            ${invoice.amount_due / 100}
          </Table.Cell>
        </InvoiceUpcomingRow>
      </Table.Body>
    </InvoiceTable>
  );
}
