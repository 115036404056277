import React, { forwardRef } from 'react';
import { Dropdown, Ref } from 'semantic-ui-react';

const defaultOptions = [
  { value: 'String', text: 'String' },
  { value: 'Number', text: 'Number' },
  { value: 'DateTime', text: 'Date' },
  { value: 'Boolean', text: 'Boolean' },
];

function ColumnTypePicker(
  { type, onChange, columnTypeOptions = defaultOptions, ...props },
  parentRef
) {
  return (
    <Ref innerRef={parentRef}>
      <Dropdown
        closeOnBlur
        selectOnBlur={false}
        placeholder="Select Type"
        {...props}
        value={type}
        options={columnTypeOptions}
        onChange={(e, { value }) => onChange(e, { type: value })}
      />
    </Ref>
  );
}

// eslint-disable-next-line no-func-assign
ColumnTypePicker = forwardRef(ColumnTypePicker);
ColumnTypePicker.displayName = 'ColumnTypePicker';

export default ColumnTypePicker;
