import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';

import { Plus, Trash } from '<components>/Icons';

import Cell, { StyledCell } from '../../Cell';
import Table from '../../Table';

import Condition from './Condition';

const RuleNameCell = forwardRef(
  ({ widget, rule, onCancel, onConfirm, onUpdate, ord }, parentRef) => {
    return (
      <Cell
        ref={parentRef}
        as="th"
        rowSpan={rule.conditions.length + 1}
        cell={{
          ID: rule.ID,
          funcID: widget.funcID,
          widgetID: widget.ID,
          dataType: 'String',
          value: rule.label,
        }}
        isEditable
        onCancel={onCancel}
        onConfirm={onConfirm}
        onUpdate={onUpdate}
        ord={ord}
      />
    );
  }
);

const RuleOutputCell = forwardRef(
  ({ widget, rule, onLink, onConfirm, onUpdate, ord }, parentRef) => {
    return (
      <Cell
        ref={parentRef}
        isEditable
        isTarget
        cell={rule.outputValue}
        onLink={onLink}
        onConfirm={onConfirm}
        onUpdate={onUpdate}
      />
    );
  }
);

function RuleResultCells({ widget, ruleIdx, numInputs }) {
  return widget.rows.map((row, idx) => {
    const cell = row.cells[ruleIdx + numInputs];
    return (
      <Cell
        rowSpan={widget.config.rules[ruleIdx].conditions.length + 1}
        key={cell.ID}
        cell={cell}
        firstOutput={idx === 0}
        isSource
        isOutput
        ord={ruleIdx + numInputs}
      />
    );
  });
}

function CondControl({ api, widget, rule, cond, condIdx, setAppearTrigger }) {
  const actions = [];
  if (api.deleteCondition && rule.conditions.length > 1) {
    actions.push(
      <Table.Action
        key="delete"
        onClick={() =>
          api.deleteCondition(widget, {
            ruleID: rule.ID,
            condID: cond.ID,
          })
        }
      >
        <Trash />
      </Table.Action>
    );
  }
  if (api.addCondition) {
    actions.push(
      <Table.Action
        key="add"
        onClick={() => {
          const beforeID = rule.conditions[condIdx + 1]
            ? rule.conditions[condIdx + 1].ID
            : null;
          api.addCondition(widget, {
            ruleID: rule.ID,
            beforeID,
            correlationID: setAppearTrigger('edit'),
          });
        }}
      >
        <Plus />
      </Table.Action>
    );
  }
  const draggable = api.moveConditions && rule.conditions.length > 1;
  return <Table.DataControl draggable={draggable}>{actions}</Table.DataControl>;
}

function RuleControl({
  api,
  appearTrigger,
  numInputs,
  rule,
  ruleIdx,
  setAppearTrigger,
  widget,
}) {
  const actions = [];
  if (api.deleteRule && widget.config.rules.length > 1) {
    actions.push(
      <Table.Action
        key="delete"
        onClick={() =>
          api.deleteRule(widget, {
            ruleID: rule.ID,
          })
        }
      >
        <Trash />
      </Table.Action>
    );
  }
  if (api.addRule) {
    actions.push(
      <Table.Action
        key="add"
        onClick={(e) => {
          const idx = ruleIdx + (e.altKey ? 0 : 1);
          const beforeID = widget.config.rules[idx]
            ? widget.config.rules[idx].ID
            : null;
          api.addRule(widget, {
            beforeID,
            correlationID: setAppearTrigger('edit'),
          });
        }}
      >
        <Plus />
      </Table.Action>
    );
  }
  const draggable = api.moveRule && widget.configl.rules.length > 1;
  return (
    <Table.RowControl
      ord={ruleIdx + numInputs}
      draggable={draggable}
      rowSpan={rule.conditions.length + 1}
      tabIndex={actions.length > 0 ? 0 : undefined}
    >
      {actions}
    </Table.RowControl>
  );
}

function Rule(
  {
    api,
    appearTrigger,
    setAppearTrigger,
    rule,
    ruleIdx,
    rowIdx,
    numInputs,
    widget,
    inputOpts,
    onRemoveCondition,
    onSetConditionInput,
    onSetConditionOp,
  },
  parentRef
) {
  const firstCondRef = useRef();
  const nameRef = useRef();
  useImperativeHandle(parentRef, () => ({
    edit: () => setTimeout(nameRef.current.edit(true)),
  }));
  useEffect(() => {
    if (appearTrigger.id === rule.correlationID) {
      nameRef.current.edit(true);
    }
  }, [appearTrigger, rule]);
  return (
    <>
      {rule.conditions.map((cond, condIdx) => (
        <Table.Row key={condIdx} ord={rowIdx + condIdx}>
          {condIdx === 0 ? (
            <>
              <RuleControl
                api={api}
                widget={widget}
                rule={rule}
                appearTrigger={appearTrigger}
                setAppearTrigger={setAppearTrigger}
                ruleIdx={ruleIdx}
                numInputs={numInputs}
              />
              <RuleNameCell
                ord={ruleIdx + numInputs}
                ref={nameRef}
                widget={widget}
                rule={rule}
                ruleIdx={ruleIdx}
                onRemove={api.removeRule}
                onConfirm={() => {
                  firstCondRef.current.edit();
                }}
                onUpdate={(labelCell, { value: label }) => {
                  if (appearTrigger.id === rule.correlationID) {
                    appearTrigger.done();
                  }
                  api.updateRule(widget, {
                    ruleID: labelCell.ID,
                    label,
                  });
                }}
                onCancel={() => {
                  if (appearTrigger.id === rule.correlationID) {
                    appearTrigger.done();
                    api.removeRule({
                      widgetID: widget.ID,
                      ruleID: rule.ID,
                    });
                  }
                }}
                onBlur={() => {
                  if (appearTrigger.id === rule.correlationID) {
                    appearTrigger.done();
                  }
                }}
              />
              <CondControl
                api={api}
                setAppearTrigger={setAppearTrigger}
                widget={widget}
                rule={rule}
                cond={cond}
                condIdx={condIdx}
              />
              <StyledCell>
                <em>{ruleIdx === 0 ? 'If' : 'Else if'}</em>
              </StyledCell>
            </>
          ) : (
            <>
              <CondControl
                api={api}
                setAppearTrigger={setAppearTrigger}
                widget={widget}
                rule={rule}
                cond={cond}
                condIdx={condIdx}
              />
              <StyledCell>
                <em>And</em>
              </StyledCell>
            </>
          )}
          <Condition
            api={api}
            ref={condIdx === 0 ? firstCondRef : undefined}
            appearTrigger={appearTrigger}
            setAppearTrigger={setAppearTrigger}
            widget={widget}
            inputOpts={inputOpts}
            cond={cond}
            rule={rule}
            condIdx={condIdx}
            ruleIdx={ruleIdx}
            onRemove={onRemoveCondition}
            onSetInput={onSetConditionInput}
            onSetOp={onSetConditionOp}
          />
          {condIdx === 0 ? (
            <RuleResultCells
              widget={widget}
              ruleIdx={ruleIdx}
              numInputs={numInputs}
            />
          ) : (
            <td style={{ display: 'none' }} />
          )}
        </Table.Row>
      ))}
      <Table.Row ord={rowIdx + rule.conditions.length}>
        <StyledCell colSpan={4}>
          <em>Then Output</em>
        </StyledCell>
        <RuleOutputCell
          setAppearTrigger={setAppearTrigger}
          widget={widget}
          rule={rule}
          ruleIdx={ruleIdx}
          // onConfirm={() =>
          //   api.addRule({
          //     widgetID: widget.ID,
          //     afterRuleID: rule.ID,
          //     correlationID: setAppearTrigger('edit'),
          //   })
          // }
          onLink={api.updateCellLink}
          onUpdate={api.updateCellValue}
        />
        <td style={{ display: 'none' }} />
      </Table.Row>
    </>
  );
}
// eslint-disable-next-line no-func-assign
Rule = forwardRef(Rule);
Rule.displayName = 'Rule';
export default Rule;
