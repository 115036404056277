import React, { Fragment } from 'react';
import styled from '@emotion/styled';

import ReactMarkdown from 'react-markdown';

import * as colors from '<components>/colors';
import UnderlinedHeader from '<components>/UnderlinedHeader';

const StyledHeader = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > h3 {
    margin: 0px;
    margin-bottom: 2px !important;
  }

  & > h4 {
    margin: 0px;
    font-size: 14px;
    color: ${colors.gray2};
  }
`;

const TableList = styled('ul')`
  padding-inline-start: 26px;
  margin: 0;
  font-size: 14px;
`;

const TypeLabel = ({ tableset }) => {
  const { __typename: type } = tableset.source;
  if (type === 'GoogleSpreadsheet') {
    return <h4>Google Spreadsheet</h4>;
  }
  if (type === 'SnowflakeDatabase') {
    return <h4>Snowflake Database</h4>;
  }

  return null;
};

const TablesHeader = ({ tableset }) => {
  const { __typename: type } = tableset.source;

  if (type === 'GoogleSpreadsheet') {
    return <UnderlinedHeader>Connected Sheets</UnderlinedHeader>;
  }
  if (type === 'SnowflakeDatabase') {
    return <UnderlinedHeader>Connected Tables</UnderlinedHeader>;
  }

  return <UnderlinedHeader>Tables</UnderlinedHeader>;
};

export default function TablesetDetail({ tableset }) {
  const { comment, name } = tableset;

  return (
    <Fragment>
      <StyledHeader>
        <h3>{name}</h3>
        <TypeLabel tableset={tableset} />
      </StyledHeader>
      {comment && (
        <Fragment>
          <UnderlinedHeader>Description</UnderlinedHeader>
          <ReactMarkdown>{comment}</ReactMarkdown>
        </Fragment>
      )}
      {tableset.tables.length > 0 && (
        <Fragment>
          <TablesHeader tableset={tableset} />
          <TableList>
            {tableset.tables.map((table) => (
              <li key={table.ID}>{table.name}</li>
            ))}
          </TableList>
        </Fragment>
      )}
    </Fragment>
  );
}
