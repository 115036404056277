import React from 'react';
import styled from '@emotion/styled';
import { Dropdown, Form } from 'semantic-ui-react';

import AnimatedResize from '../AnimatedResize';
import CellType from '../CellType';
import { parseUserValue } from '../Cell/util';

const Pane = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;

  & > div {
    margin-bottom: 2px;
  }

  & .field {
    margin-top: 4px;
  }
`;

const decimalOpts = [
  { text: 'Automatic', value: 'auto' },
  { text: '0', value: 0 },
  { text: '1', value: 1 },
  { text: '2', value: 2 },
  { text: '3', value: 3 },
  { text: '4', value: 4 },
];

function NumberFormat({ cell, setFormat }) {
  const dataType = cell && cell.dataType;
  const format = cell && cell.format && cell.format.number;

  const disabled = dataType !== 'Number';
  let decimals;
  decimals = format && format.decimals;
  if (decimals === undefined || decimals === null) {
    decimals = 'auto';
  }
  let isPercent = false;
  if (format && format.type === 'Percent') {
    isPercent = true;
  }
  return (
    <>
      <h4>Decimal places</h4>
      <Dropdown
        disabled={disabled}
        value={decimals}
        options={decimalOpts}
        onChange={(e, { value }) =>
          setFormat(cell, {
            number: {
              ...cell.format.number,
              decimals: value === 'auto' ? null : value,
            },
          })
        }
      />
      <Form.Checkbox
        disabled={disabled}
        checked={isPercent}
        label="Percent"
        onChange={() =>
          setFormat(cell, {
            number: {
              ...cell.format.number,
              type: !isPercent ? 'Percent' : 'Locale',
            },
          })
        }
      />
    </>
  );
}

export default function CellSettings({ cell, editable, setType, setFormat }) {
  let dataType;
  let restrict;
  if (cell) {
    dataType = cell.coerceTo;
    restrict = cell.template && cell.template.restrictTypes;
    if (!dataType || dataType === 'Any') {
      dataType = cell.dataType;
      if (restrict && restrict.length > 0 && !restrict.includes(dataType)) {
        dataType = restrict[0];
      }
    }
  }
  return (
    <Pane>
      <div>
        <h4 style={{ fontSize: '12px' }}>Data Type</h4>
        {!cell ? (
          <div>No cell selected</div>
        ) : (
          <CellType
            onChange={(coerceTo) => {
              let value, dataType;
              if (
                !cell.link &&
                cell.dataType === 'String' &&
                coerceTo !== 'String'
              ) {
                const converted = parseUserValue(cell.value);
                value = converted.value;
                dataType = converted.dataType;
              }
              setType(cell, {
                coerceTo,
                value,
                dataType,
              });
            }}
            type={dataType}
            restrict={restrict}
            editable={editable}
          />
        )}
      </div>
      <AnimatedResize expanded={!!cell && cell.dataType === 'Number'}>
        <NumberFormat cell={cell} setFormat={setFormat} />
      </AnimatedResize>
    </Pane>
  );
}
