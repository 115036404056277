import React from 'react';
import styled from '@emotion/styled';
import { Icon } from 'semantic-ui-react';

import DRIVE_LOGO from '<src>/assets/logo_drive_2020q4_color_2x_web_64dp.png';

import * as colors from '<components>/colors';
import Button from '<src>/components/Button';

const ConnectedButton = styled(Button)`
  & > span {
    margin-right: 6px;
  }
`;

const ConnectButton = styled('button')`
  display: inline-flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  border: 1px solid ${colors.gSheetGreen};
  background-color: white;
  border-radius: 4px;
  padding: 0;

  :hover {
    background-color: ${colors.gray4};
  }

  & > div {
    background-color: ${colors.gSheetGreen};
    font-weight: bold;
    color: white;
    padding: auto 6px;
    display: inline-flex;
    align-items: center;
    padding: 0 8px;
  }
  &:hover > div {
    color: ${colors.gray4};
  }

  & > img {
    margin: 3px;
    height: 36px;
    width: 36px;
  }
`;

export default function GoogleConnectButton({ onClick, disabled, connected }) {
  if (connected) {
    return (
      <ConnectedButton disabled basic>
        <span>Connected</span>
        <Icon name="check" color="green" />
      </ConnectedButton>
    );
  }
  return (
    <ConnectButton onClick={onClick} disabled={disabled}>
      <img src={DRIVE_LOGO} alt="Google Drive" />
      <div>Connect to Google Drive</div>
    </ConnectButton>
  );
}
