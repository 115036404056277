export function columnType(widget, colIdx) {
  if (widget.columns[colIdx].normTemplate) {
    return widget.columns[colIdx].normTemplate.section;
  }
  return 'Setting';
}

export function rowType(widget, colIdx) {
  if (widget.rows[colIdx].normTemplate) {
    return widget.rows[colIdx].normTemplate.section;
  }
  return 'Setting';
}

export function cellType(widget, func, colIdx, rowIdx) {
  if (func.aggregate) {
    return rowType(widget, rowIdx);
  }
  return columnType(widget, colIdx);
}

export function isFirstOutput(widget, idx) {
  return (
    idx > 0 &&
    columnType(widget, idx) === 'Output' &&
    columnType(widget, idx - 1) !== 'Output'
  );
}
