import React, { Fragment, useState } from 'react';
import { List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { useQuery, useMutation } from '<src>/apollo/client';

import Button from '<src>/components/Button';

import { accountName } from '../util';
import { GetAccounts, CreateOrg } from '../../queries';

import AddOrgDialog from './AddOrgDialog';

export default function ManageAccounts({ searchTerm }) {
  const {
    data: { accounts: accountsData } = {},
    loading,
    refetch,
  } = useQuery(GetAccounts);
  const accounts = loading ? [] : accountsData.accounts;

  const [createOrg] = useMutation(CreateOrg, {
    errorPolicy: 'all',
  });

  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const [orgCreating, setOrgCreating] = useState(false);
  const [createErrors, setCreateErrors] = useState();
  const searchTermClean = (searchTerm || '').trim().toLowerCase();

  const onAddOrg = async (input) => {
    setOrgCreating(true);
    const { errors } = await createOrg({ variables: { input } });
    if (errors && errors.length > 0) {
      setOrgCreating(false);
      setCreateErrors(errors);
    } else {
      setOrgCreating(false);
      setAddDialogVisible(false);
      setCreateErrors(null);
      refetch();
    }
  };

  return (
    <Fragment>
      <div>
        <h2>Accounts</h2>
        <Button.Small onClick={() => setAddDialogVisible(true)}>
          Add Org...
        </Button.Small>
        <List verticalAlign="middle">
          {accounts.map((account) => {
            const name = account.display;
            if (!(name || '').toLowerCase().includes(searchTermClean))
              return null;

            return (
              <List.Item key={account.ID}>
                <List.Content>
                  <Link to={`/numbrz-admin/accounts/${account.ID}`}>
                    {accountName(account)}
                  </Link>
                </List.Content>
              </List.Item>
            );
          })}
        </List>
      </div>
      <AddOrgDialog
        creating={orgCreating}
        visible={addDialogVisible}
        onClose={() => setAddDialogVisible(false)}
        onSubmit={onAddOrg}
        errors={createErrors}
      />
    </Fragment>
  );
}
