import { isValid as isValidDate, parseISO as parseDate } from 'date-fns';

import React from 'react';
import T from 'prop-types';
import TimeAgo from 'react-timeago';

import { getCurrentLocale } from '<src>/utils/navigator';
import withFilteredProps from '<components>/hoc/filteredProps';

function formatter(value, unit, suffix, now, nextFormatter) {
  if (unit === 'second' && value < 60) return '~1 minute ago';
  return `~${nextFormatter()}`;
}

const TimeSinceNow = (props) => {
  const date = isValidDate(parseDate(props.children))
    ? parseDate(props.children)
    : null;

  if (!date || date.getTime() === 0) {
    return '~1 minute ago';
  }

  return <TimeAgo date={date} formatter={formatter} />;
};
TimeSinceNow.propTypes = {
  children: T.oneOfType([T.instanceOf(Date), T.number, T.string]),
  titleFormat: T.string,
};

TimeSinceNow.defaultProps = {
  locale: getCurrentLocale(),
  titleFormat: 'YYYY-MM-DDTHH:mm:ssZ',
};

export default withFilteredProps(['locale', 'titleFormat'])(TimeSinceNow);
