/** @jsx jsx */
/** @jsxRuntime classic */

import { Fragment, useCallback, useEffect, useState, useMemo } from 'react';
import fp from 'lodash/fp';

import { jsx } from '@emotion/react';

import { FlowDescription } from '<src>/components/NumbrzVerticalEditor';

import JobGroupProgress from './JobGroupProgress';
import { Icon } from 'semantic-ui-react';
import { HashScroll } from 'react-hash-scroll';
import BlockStatus from '../components/BlockStatus';
import ReviewDialog from '../components/ReviewDialog';
import * as styles from '<sections>/jobs/JobStatus/styles';

const stopForStatuses = ['Complete', 'Failed', 'Canceled', 'CancelledByUser'];

const empty = [];

export default function JobGroupStatus(props) {
  const {
    match,
    jobGroupState = {},
    jobGroupRunState = {},
    baseURL,
    isRunning,
    projectNames,
    running,
    setRunning,
    runID,
  } = props;

  const {
    jobGroupStatus = {},
    loadJobGroupStatus,
    loadingStatus,
    stopStatusPolling,
    loadingRunHistory,
  } = jobGroupRunState;
  const { jobGroup = {} } = jobGroupState;

  const blocks = jobGroup.blocks || empty;

  const [activeReviewBlock, setActiveReviewBlock] = useState();
  const [reviewDialogVisible, setReviewDialogVisible] = useState(false);
  const [expandedBlocks, setExpandedBlocks] = useState(
    blocks.reduce((acc, block) => ({ ...acc, [block.key]: false }), {})
  );
  const [statusExpanded, setStatusExpanded] = useState(true);

  const { block: blockAPI = {} } = jobGroupState.api;

  const isGroupActive = useMemo(() => {
    if (!jobGroupStatus.state) return false;
    return !['Complete', 'Failed', 'Canceled', 'CancelledByUser'].includes(
      jobGroupStatus.state
    );
  }, [jobGroupStatus]);

  useEffect(() => {
    if (!loadingRunHistory && !loadingStatus) {
      if (
        fp.getOr(undefined, 'params.stateID', match) &&
        runID.current !== fp.getOr(undefined, 'params.stateID', match)
      ) {
        runID.current = fp.getOr(undefined, 'params.stateID', match);
        loadJobGroupStatus(runID.current);
      }
    }

    if (stopForStatuses.includes(jobGroupStatus.state)) {
      setRunning(false);
      stopStatusPolling();
    } else {
      if (runID.current && isGroupActive) {
        setRunning(true);
        // startStatusPolling(3000);
      }
    }

    return () => {
      if (stopForStatuses.includes(jobGroupStatus.state)) stopStatusPolling();
    };
  }, [
    runID,
    isGroupActive,
    running,
    jobGroupStatus,
    loadingRunHistory,
    match,
    loadingStatus,
  ]);

  const handleReviewBlock = useCallback((block) => {
    setActiveReviewBlock(block);
    setReviewDialogVisible(true);
  }, []);

  const onReviewBlock = useCallback(
    async (reviewState, reviewComment) => {
      setReviewDialogVisible(false);
      await blockAPI.onReview(
        {},
        {
          stateID: jobGroupStatus.ID,
          blockKey: activeReviewBlock.key,
          status: reviewState,
          comment: reviewComment,
        }
      );

      setActiveReviewBlock(null);
    },
    [activeReviewBlock, blockAPI, jobGroupStatus]
  );

  const onExpand = useCallback(
    (exp, key) =>
      setExpandedBlocks((expBlocks) => ({ ...expBlocks, [key]: exp })),
    []
  );

  const getBlockStatus = useCallback(
    (blockKey) => {
      if (
        !jobGroupStatus ||
        fp.getOr([], 'blocks', jobGroupStatus).length === 0
      )
        return null;
      const blockState = fp
        .getOr([], 'blocks', jobGroupStatus)
        .find((bS) => bS.key === blockKey);
      return blockState;
    },
    [jobGroupStatus]
  );

  const blockStatuses = useMemo(() => {
    return blocks.map((block, idx) => {
      const blockRunStatus = getBlockStatus(block.key);
      return (
        <HashScroll key={block.key} hash={block.key} position="start">
          <BlockStatus
            blockIdx={idx + 1}
            block={block}
            baseURL={baseURL}
            expanded={expandedBlocks[block.key]}
            first={idx === 0}
            last={idx === blocks.length - 1}
            jobGroupID={jobGroup.ID}
            onExpand={onExpand}
            projectNames={projectNames}
            isRunning={isRunning}
            blockRunStatus={blockRunStatus}
            onReviewBlock={handleReviewBlock}
            disabled={true}
            viewMode="status"
          />
        </HashScroll>
      );
    });
  }, [blocks, expandedBlocks, isRunning, getBlockStatus]);

  return !jobGroupStatus.state ? (
    <FlowDescription>
      <p>Run the job group to get started.</p>
    </FlowDescription>
  ) : (
    <Fragment>
      <JobGroupProgress
        jobGroupState={jobGroupState}
        runState={jobGroupStatus}
      />
      <styles.StatusContainer>
        <styles.StatusHeader>
          <styles.SectionHdrBtn onClick={() => setStatusExpanded((e) => !e)}>
            Blocks
            <Icon
              name={statusExpanded ? 'caret up' : 'caret down'}
              title={statusExpanded ? 'Hide' : 'Show'}
            />
          </styles.SectionHdrBtn>
        </styles.StatusHeader>

        {statusExpanded && blockStatuses}
      </styles.StatusContainer>

      <Fragment>
        <ReviewDialog
          reviewComment={activeReviewBlock && activeReviewBlock.reviewComment}
          visible={reviewDialogVisible}
          onDialogClose={() => setReviewDialogVisible(false)}
          onSubmit={onReviewBlock}
          blockName={activeReviewBlock ? activeReviewBlock.name : ''}
        />
      </Fragment>
    </Fragment>
  );
}
