import { useEffect, useState } from 'react';

export default function useEditorState(
  value,
  {
    onCancel = () => {},
    onChange = () => {},
    onEditing = () => {},
    onSubmit = () => {},
    startEditing = false,
    startFocussed = false,
    startSelected = false,
  } = {}
) {
  const [current, setCurrent] = useState(value);
  const [editing, setEditing] = useState(startEditing);
  const [select, setSelect] = useState(startSelected);
  const [focus, setFocus] = useState(startFocussed);
  useEffect(() => {
    if (!editing && value !== current) {
      setCurrent(value);
    }
  }, [value, editing, current]);

  return {
    editing,
    focus,
    select,

    onChange: (event) => {
      setCurrent(event.value);
      onChange(event);
    },
    onSubmit: (event, v) => {
      if (v === undefined) {
        v = current;
      } else {
        onChange({ ...event, value: v });
      }
      if (v !== value) {
        onSubmit({ ...event, value: v });
      }
    },
    onCancel: (event) => {
      if (editing) {
        if (current !== value) {
          setCurrent(value);
          onChange({ ...event, value });
        }
        onCancel(event);
      }
    },
    onEditing: (event) => {
      setFocus(false);
      setSelect(event.editing && event.select);
      setEditing(event.editing);
      onEditing(event);
    },
  };
}
