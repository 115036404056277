import React from 'react';

import ProjectListPage from '<sections>/projects/pages/ProjectListPage';
import AddServiceProjectDialog from './AddServiceProjectDialog';

export default function ServiceProjectListPage({ baseURL }) {
  return (
    <ProjectListPage
      baseURL={baseURL}
      type="Service"
      AddProjectDialog={AddServiceProjectDialog}
    />
  );
}
