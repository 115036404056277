import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import JobPage from '<sections>/jobs/JobPage';

import ProjectList from './pages/ProjectListPage';
import ProjectPage from './pages/ProjectPage';
import ProjectFunctionPage from './pages/ProjectFunctionPage';
import ProjectFlowPage from './pages/ProjectFlowPage';

function ProjectSection() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/:projectID/functions/:functionID`}>
        <ProjectFunctionPage />
      </Route>

      <Route path={`${match.url}/:projectID/jobs/:jobID`}>
        <JobPage />
      </Route>
      <Route path={`${match.url}/:projectID/flows/:flowID`}>
        {({ match, history }) => {
          return (
            <ProjectFlowPage
              match={match}
              history={history}
              projectID={match.params.projectID}
              flowID={match.params.flowID}
            />
          );
        }}
      </Route>
      <Route path={`${match.url}/:projectID`}>
        <ProjectPage />
      </Route>
      <Route>
        <ProjectList baseURL={match.url} />
      </Route>
    </Switch>
  );
}

export default ProjectSection;
