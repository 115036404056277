import styled from '@emotion/styled';

import * as colors from '<components>/colors';
import { ConfigTable } from '../styles';

const ignoreSsrWarning =
  '/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */';

export const CondTable = styled(ConfigTable)`
  width: ${(props) => (props.wide ? '100%' : 'auto')};
  td,
  th {
    border-right-color: transparent;
    border-left-color: transparent;
  }
  td:first-child${ignoreSsrWarning}, th:first-child${ignoreSsrWarning} {
    border-left-color: ${colors.gray3};
  }
  td:last-child,
  th:last-child {
    border-right-color: ${colors.gray3};
  }
  td.field {
    width: 40%;
  }

  span {
    margin-right: 15px;
  }
  span:last-child {
    margin-right: 0;
  }
`;

export const Op = styled('span', {
  shouldForwardProp: (prop) => prop !== 'op',
})`
  font-weight: bold;
  font-size: ${({ op }) => (op.length > 3 ? '1em' : '1.2em')};
`;
