import React from 'react';
import styled from '@emotion/styled';

import useWidgetOrder from '../useWidgetOrder';

import Widget from '../Widget';

const styleProps = ['activeWidgetType'];
const StyledPane = styled('div', {
  shouldForwardProp: (prop) => !styleProps.includes(prop),
})`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  & > div {
    margin: 6px 6px;
  }
`;

export default function WidgetsPane({ func }) {
  const widgetOrder = useWidgetOrder(func);

  return (
    <StyledPane>
      {widgetOrder.map((group, groupIdx) =>
        Object.values(group.nodes).map((nodeID, nodeIdx) => {
          const widget = func.widgetsByID[nodeID];
          if (widget) {
            return (
              <Widget
                key={nodeID}
                widget={widget}
                parent={func}
                graphPosition={{ groupIdx, nodeIdx }}
                forceActive
              />
            );
          }
          return null;
        })
      )}
    </StyledPane>
  );
}
