/** @jsx jsx */
/** @jsxRuntime classic */

import { PureComponent } from 'react';
import { findDOMNode } from 'react-dom';
import T from 'prop-types';
import { jsx } from '@emotion/react';

import withDefaultProps from '<components>/hoc/defaultProps';
import withFilteredProps from '<components>/hoc/filteredProps';
import withProps from '<components>/hoc/extraProps';

import styles from '../styles';

@withProps((props) => ({
  focusable: props.focusable || props.editable,
  getItem: props.getDragItem,
}))
@withDefaultProps({
  as: 'td',
  active: false,
  draggable: false,
  droppable: false,
  focusable: false,
})
@withFilteredProps([
  'active',
  'canDrop',
  'connectDragPreview',
  'connectDragSource',
  'connectDropTarget',
  'draggable',
  'droppable',
  'getDragItem',
  'getItem',
  'dragItem',
  'isDragging',
  'isOver',
  'isOverCurrent',
  'onDrop',
  'itemType',
  'rowID',
  'columnID',
])
export default class TableCell extends PureComponent {
  static propTypes = {
    active: T.bool,
    as: T.oneOf(['td', 'th']).isRequired,
    connectDropTarget: T.func,
    connectDragSource: T.func,
    draggable: T.bool,
    droppable: T.bool,
    getDragItem: T.func, // TODO: required if draggable
    focusable: T.bool,
    onDrop: T.func, // TODO: required if droppable
  };

  componentDidMount() {
    const { draggable, droppable } = this.props;

    if (!draggable && !droppable) return;

    const node = findDOMNode(this.node);

    if (draggable) this.props.connectDragSource(node);
    if (droppable) this.props.connectDropTarget(node);
  }

  bindRef = (el) => {
    this.node = el;
  };

  render() {
    const { filteredProps } = this.props;

    const cellStyles = {
      ...styles.cell.base,
      ...(this.props.active && styles.cell.active),
      ...(this.props.editable && styles.cell.editable),
      ...(this.props.draggable && styles.cell.draggable),
      ...(this.props.isOver && this.props.canDrop && styles.cell.dropHovered),
    };

    return <td {...filteredProps} ref={this.bindRef} css={cellStyles} />;
  }
}
