import React, { useRef, useEffect, useState } from 'react';
import { StepDropdownWrapper } from '<src>/sections/flows/flow-testing/styles';
import {
  TextIconBtn,
  PopupMenu,
  PopupListItem,
  InternalDataLink,
} from '../../styles';

import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';

export default function MoreInfoMenu({ dataMapping, tableURL, dataURL }) {
  const [visible, setVisible] = useState(false);
  const menuRef = useRef();

  useEffect(() => {
    const menuEl = visible ? menuRef.current : undefined;
    if (menuEl) menuEl.focus();
  }, [visible]);

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') setVisible(false);
  };

  return (
    <StepDropdownWrapper>
      <TextIconBtn
        iconSize="16px"
        iconMargin="0 1px "
        padding="4px 0px "
        onClick={() => setVisible(!visible)}
        onMouseDown={(e) => e.preventDefault()}
        expanded={visible}
      >
        <MoreVertRoundedIcon />
      </TextIconBtn>

      <PopupMenu
        padding="0"
        minHeight="0"
        minWidth="120px"
        ref={menuRef}
        tabIndex="-1"
        onBlur={(e) => {
          setVisible(false);
        }}
        visible={visible}
        onKeyDown={handleKeyDown}
      >
        <ul>
          <PopupListItem>
            <InternalDataLink
              bold
              to={tableURL}
              onMouseDown={(e) => e.preventDefault()}
            >
              <ArrowForwardRoundedIcon />
              Model data
            </InternalDataLink>
          </PopupListItem>
          <PopupListItem>
            <InternalDataLink
              bold
              to={dataURL}
              onMouseDown={(e) => e.preventDefault()}
            >
              <LinkRoundedIcon />
              Data mapping
            </InternalDataLink>
          </PopupListItem>
        </ul>
      </PopupMenu>
    </StepDropdownWrapper>
  );
}
