import React from 'react';
import update from 'immutability-helper';

import { WidgetAPI } from '../../contexts';
import useLookupWidgetAPI from '../../gql/useLookupWidgetAPI';

export function EditorAPI({ children }) {
  const api = useLookupWidgetAPI();
  return <WidgetAPI.Provider value={api}>{children}</WidgetAPI.Provider>;
}

export const initialSetupState = {
  cells: {
    cell1: { ID: 'cell1', value: null },
    cell2: { ID: 'cell2', value: null },
  },
  widget: {
    name: 'Lookup',
    type: 'Lookup',
    columns: [
      { ID: 'col1', cellIDs: ['cell1'] },
      { ID: 'col2', cellIDs: ['cell2'] },
    ],
    rows: [
      {
        ID: 'row1',
        template: { ID: 'row1', label: 'Row 1' },
        cellIDs: ['cell1', 'cell2'],
      },
    ],
    lookupConfig: {
      lookupTableID: null,
      columns: [
        { ID: 'col1', lookupColumnID: null, mode: 'Closest' },
        { ID: 'col2', lookupColumnID: null, mode: 'Result' },
      ],
    },
  },
};

export function makeSetupAPI(state, baseAPI, setWidget, parent) {
  return {
    updateColumn: (_, column, { lookupColumnID }) =>
      setWidget((widget) => {
        const colIdx = widget.lookupConfig.columns.findIndex(
          (col) => col.ID === column.ID
        );
        if (colIdx === -1) return widget;
        return update(widget, {
          lookupConfig: {
            columns: {
              [colIdx]: {
                lookupColumnID: { $set: lookupColumnID },
              },
            },
          },
        });
      }),
    updateMode: (_, { mode }) =>
      setWidget((widget) =>
        update(widget, {
          lookupConfig: {
            columns: {
              0: {
                mode: { $set: mode },
              },
            },
          },
        })
      ),
    setLookupTable: (_, lookupTableID) =>
      setWidget((widget) => {
        const table = parent.data.find((t) => t.ID === lookupTableID);
        if (!table) return widget;

        const colID = (idx) =>
          table.columns[idx] ? table.columns[idx].ID : null;

        return update(widget, {
          lookupConfig: {
            lookupTableID: { $set: lookupTableID },
            columns: {
              $set: [
                {
                  ID: 'col1',
                  mode: 'Closest',
                  lookupColumnID: colID(0),
                },
                {
                  ID: 'col2',
                  mode: 'Result',
                  lookupColumnID: colID(1),
                },
              ],
            },
          },
        });
      }),
  };
}

export function makeUIWidget(widget, cells, parent) {
  const lookupTable =
    widget.lookupConfig.lookupTableID &&
    parent.data.find((table) => table.ID === widget.lookupConfig.lookupTableID);
  const config = update(widget.lookupConfig, {
    type: { $set: 'Lookup' },
    lookupTable: { $set: lookupTable },
  });
  return {
    ...widget,
    config,
  };
}

export function validate(widget, cells, parent) {
  if (!widget.lookupConfig.lookupTableID) {
    if (parent.data.length === 0) {
      return {
        error: { message: 'Create a data widget first to use for lookup data' },
      };
    }
    return {
      error: { message: 'Select a data widget to use for lookup data.' },
    };
  }
  if (!widget.lookupConfig.columns[0]?.lookupColumnID) {
    return {
      error: { message: 'Select lookup column' },
    };
  }
  if (!widget.lookupConfig.columns[1]?.lookupColumnID) {
    return {
      error: { message: 'Select result column' },
    };
  }
  return {};
}
