import React, { useState, useCallback, useMemo } from 'react';
import { CellContent, CellInput } from '../styles';
import { formatCell, parseUserValue } from '<sections>/functions/Cell/util';
import { NumbrzTooltip } from '<src>/components/NumbrzButtons';
import { getFormattingError, isValid } from '../utils';
import { Icon } from 'semantic-ui-react';

function getFieldValue(valueObj) {
  return valueObj ? valueObj : null;
}

export default function TestInputCell({ value, rowIdx, col, onUpdateCell }) {
  const [cellValue = getFieldValue(value), setCellValue] = useState();

  const typeMatch = useMemo(() => {
    return cellValue ? isValid(cellValue.value, col, { ms: true }) : true;
  }, [cellValue, col]);

  const handleUpdateCell = useCallback(
    async (rowIdx, col, value) => {
      setCellValue(parseUserValue(value, { ms: true }));
      await onUpdateCell(rowIdx, col, value);
    },
    [onUpdateCell]
  );

  return (
    <td className={!typeMatch && 'warning'}>
      <CellContent>
        <CellInput
          singleClick
          value={cellValue ? formatCell(cellValue, { ms: true }) : null}
          onSubmit={(e) => handleUpdateCell(rowIdx, col, e.value)}
        />
        {!typeMatch && (
          <NumbrzTooltip
            position="top center"
            header={''}
            trigger={<Icon name="warning circle" />}
            wide="very"
            content={getFormattingError(col.type)}
          />
        )}
      </CellContent>
    </td>
  );
}
