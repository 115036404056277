import React, { PureComponent } from 'react';
import { getDisplayName, hoistStatics } from 'recompose';

export default (filteredProps = []) => {
  const withFilteredProps = BaseComponent => {
    const name = getDisplayName(BaseComponent);

    class WithFilteredProps extends PureComponent {
      render() {
        const { ...nextProps } = this.props;
        filteredProps.forEach(key => delete nextProps[key]);
        return <BaseComponent {...this.props} filteredProps={nextProps} />;
      }
    }

    WithFilteredProps.BaseComponent = BaseComponent;
    WithFilteredProps.displayName = `withFilteredProps(${name})`;

    return WithFilteredProps;
  };

  return hoistStatics(withFilteredProps);
};
