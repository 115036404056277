import React, { PureComponent } from 'react';
import styled from '@emotion/styled';
import { List } from 'semantic-ui-react';

import Dialog from '<components>/Dialog';
import Button from '<src>/components/Button';
import TextInput from '<src>/components/TextInput';
import * as colors from '<components>/colors';

import { accountName } from '../util';

const StyledBody = styled(Dialog.Body)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  h4 {
    font-weight: bold;
    font-size: 14px;
    color: ${colors.gray1};
  }
`;

const StyledFooter = styled(Dialog.Footer)`
  button {
    margin-left: 10px;
  }
`;

const StyledList = styled(List)`
  max-height: 240px;
  overflow-y: auto;
`;

const StyledTextInput = styled(TextInput)`
  width: 100%;
`;

export default class AddMemberDialog extends PureComponent {
  state = {
    selected: undefined,
    filter: undefined,
  };

  onSelect = (account) => this.setState({ selected: account.ID });

  onUpdateFilter = (e) => this.setState({ filter: e.target.value });

  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible) {
      this.setState({ selected: undefined, filter: undefined });
    }
  }

  render() {
    const { visible, adding, onClose, onSubmit, target, accounts } = this.props;
    if (!target) return null;
    let headline;
    let currentMembers;
    let choices;

    if (target.__typename === 'User') {
      headline = 'Add to Org';
      currentMembers = target.membership;
      choices = accounts.filter(
        (account) =>
          account.ID !== target.ID &&
          !currentMembers.find(
            ({ account: member }) => member.ID === account.ID
          )
      );
    } else {
      headline = 'Add User';
      currentMembers = target.members;
      choices = accounts.filter(
        (account) =>
          account.__typename !== target.__typename &&
          !currentMembers.find(
            ({ account: member }) => member.ID === account.ID
          )
      );
    }

    if (this.state.filter) {
      choices = choices.filter((account) => {
        const name = account.display.toLowerCase();
        return name.includes(this.state.filter.toLowerCase());
      });
    }

    return (
      <Dialog visible={visible ? 1 : 0} onRequestClose={onClose}>
        <Dialog.Header>
          <Dialog.Headline>{headline}</Dialog.Headline>
        </Dialog.Header>
        <StyledBody>
          <StyledTextInput
            name="filter"
            placeholder="Filter"
            onChange={this.onUpdateFilter}
          />
          <StyledList selection>
            {choices.map((choice) => (
              <List.Item
                key={choice.ID}
                active={this.state.selected === choice.ID}
                onClick={() => this.onSelect(choice)}
                onDoubleClick={() => onSubmit({ selected: choice.ID })}
              >
                {accountName(choice)}
              </List.Item>
            ))}
          </StyledList>
        </StyledBody>
        <StyledFooter>
          <Button disabled={adding} onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={adding || !this.state.selected}
            onClick={() => onSubmit(this.state)}
          >
            {adding ? 'Adding...' : 'Add'}
          </Button>
        </StyledFooter>
      </Dialog>
    );
  }
}
