// Shamelessly lifted from https://www.telerik.com/blogs/how-to-create-custom-debounce-hook-react
import { useEffect, useRef, useState } from 'react';

export default (value, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(null);
  const timerRef = useRef();

  useEffect(() => {
    timerRef.current = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [value, delay]);

  return debouncedValue;
};
