import React, { PureComponent } from 'react';
import styled from '@emotion/styled';
import { List } from 'semantic-ui-react';

import Dialog from '<components>/Dialog';
import Button from '<src>/components/Button';
import TextInput from '<src>/components/TextInput';
import * as colors from '<components>/colors';

import { accountName } from '../util';

const StyledBody = styled(Dialog.Body)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  h4 {
    font-weight: bold;
    font-size: 14px;
    color: ${colors.gray1};
  }
`;

const StyledFooter = styled(Dialog.Footer)`
  button {
    margin-left: 10px;
  }
`;

const StyledList = styled(List)`
  max-height: 240px;
  overflow-y: auto;
`;

const StyledTextInput = styled(TextInput)`
  width: 100%;
`;

export default class ChangePrimaryAccountDialog extends PureComponent {
  state = {
    selected: undefined,
    filter: undefined,
  };

  onSelect = (account) => this.setState({ selected: account.ID });

  onUpdateFilter = (e) => this.setState({ filter: e.target.value });

  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible) {
      this.setState({ selected: undefined, filter: undefined });
    }
  }

  render() {
    const { visible, changing, onClose, onSubmit, user } = this.props;

    let choices = user.membership.filter(
      ({ account }) => account.ID !== user.primaryAccount.ID
    );

    if (this.state.filter) {
      choices = choices.filter(({ account }) => {
        const name = account.display.toLowerCase();
        return name.includes(this.state.filter.toLowerCase());
      });
    }

    return (
      <Dialog visible={visible ? 1 : 0} onRequestClose={onClose}>
        <Dialog.Header>
          <Dialog.Headline>Change Primary Account</Dialog.Headline>
        </Dialog.Header>
        <StyledBody>
          {choices.length > 6 && (
            <StyledTextInput
              name="filter"
              placeholder="Filter"
              onChange={this.onUpdateFilter}
            />
          )}
          <StyledList selection>
            {choices.map(({ account }) => (
              <List.Item
                key={account.ID}
                active={this.state.selected === account.ID}
                onClick={() => this.onSelect(account)}
                onDoubleClick={() => onSubmit({ selected: account.ID })}
              >
                {accountName(account)}
              </List.Item>
            ))}
          </StyledList>
        </StyledBody>
        <StyledFooter>
          <Button disabled={changing} onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={changing || !this.state.selected}
            onClick={() => onSubmit(this.state)}
          >
            {changing ? 'Changing...' : 'Change'}
          </Button>
        </StyledFooter>
      </Dialog>
    );
  }
}
