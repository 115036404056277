import { useMutation } from '<src>/apollo/client';

export function optimisticFunctionChanges({
  funcID,
  cellsAdded = [],
  cellsChanged = [],
  cellsDeleted = [],
  dataAdded = [],
  dataChanged = [],
  dataDeleted = [],
  funcChanged = [],
  templatesChanged = [],
  widgetsAdded = [],
  widgetsChanged = [],
  widgetsDeleted = [],
}) {
  return {
    __typename: 'FunctionChanges',
    funcID,
    cellsAdded,
    cellsChanged,
    cellsDeleted,
    dataAdded,
    dataChanged,
    dataDeleted,
    funcChanged,
    templatesChanged,
    widgetsAdded,
    widgetsChanged,
    widgetsDeleted,
  };
}

export function applyFunctionChangesToCache(cache, changes) {
  cache.modify({
    id: `FunctionV3:${changes.funcID}`,
    fields: {
      cells(currentCells, { readField }) {
        if (changes.cellsAdded.length > 0) {
          currentCells = [
            ...currentCells,
            ...changes.cellsAdded.map((newCell) => ({
              __ref: cache.identify(newCell),
            })),
          ];
        }
        if (changes.cellsDeleted.length > 0) {
          currentCells = currentCells.filter(
            (c) => !changes.cellsDeleted.includes(readField('ID', c))
          );
        }
        return currentCells;
      },
      data(currentData, { readField }) {
        if (changes.dataAdded.length > 0) {
          currentData = [
            ...currentData,
            ...changes.dataAdded.map((newData) => ({
              __ref: cache.identify(newData),
            })),
          ];
        }
        if (changes.dataDeleted.length > 0) {
          currentData = currentData.filter(
            (d) => !changes.dataDeleted.includes(readField('ID', d))
          );
        }
        return currentData;
      },
      widgets(currentWidgets, { readField }) {
        if (changes.widgetsAdded.length > 0) {
          currentWidgets = [
            ...currentWidgets,
            ...changes.widgetsAdded.map((newCell) => ({
              __ref: cache.identify(newCell),
            })),
          ];
        }
        if (changes.widgetsDeleted.length > 0) {
          currentWidgets = currentWidgets.filter(
            (w) => !changes.widgetsDeleted.includes(readField('ID', w))
          );
        }
        return currentWidgets;
      },
    },
  });
}

export default function useEditFunction(query, resultField) {
  const [editFunction] = useMutation(query, {
    update(cache, { data: { [resultField]: changes } }) {
      applyFunctionChangesToCache(cache, changes);
    },
  });
  return editFunction;
}
