import React from 'react';
import fp from 'lodash/fp';

import { getTableUsage } from '../../utils';
import {
  InternalDataLink,
  HdrToolbar,
  MappingHeader,
  StyledRouterLink,
  LinkContainer,
  DataNameContainer,
  ExternalIconLink,
  TableCounterLbl,
} from '../../styles';
import UsageChiclets from '../../components/UsageChiclets';
import { ErrorChiclet } from '<components>/NumbrzButtons';

import LaunchIcon from '@mui/icons-material/Launch';
import MoreInfoMenu from './MoreInfoMenu';
import DataIssues from '../DataIssues';

const DataTableName = StyledRouterLink.withComponent('span');

function DataTableLink({ dataMapping, table }) {
  const tableset = fp.getOr(undefined, 'dataset.tableset', dataMapping);
  if (!table) {
    return <DataTableName>{dataMapping.dataset.name}</DataTableName>;
  }
  const localTableURL = tableset
    ? `/data/${tableset.ID}/tables/${table.ID}`
    : `/datasets/${table.ID}`;
  return (
    <InternalDataLink
      bold
      to={localTableURL}
      margin="0 3px 0 5px"
      title="Internal Data Source"
    >
      {dataMapping.dataset.name}
    </InternalDataLink>
  );
}

export default function FlowTableMappings({
  index,
  mapping = {},
  jobTable,
  flowID,
  jobID,
  projectID,
  isLast,
  isInput = false,
  isOutput = false,
}) {
  const table = mapping.dataset;

  const shareURL = fp.getOr(undefined, 'tableset.source.shareURL', table);
  const sourceType = fp.getOr(undefined, 'source.__typename', table);

  const jobTableUrl = flowID
    ? `/models/${projectID}/flows/${flowID}/job-tables/${jobTable.ID}`
    : undefined;
  const dataMappingURL = {
    pathname: flowID
      ? `/models/${projectID}/jobs/${jobID}/configuration`
      : undefined,
    hash: mapping.tableID,
  };

  const usageTypes = getTableUsage(jobTable);

  return (
    <MappingHeader isLast={isLast} isInput={isInput} isOutput={isOutput}>
      <LinkContainer>
        <DataNameContainer>
          <TableCounterLbl isInput={isInput} isOutput={isOutput}>
            {index + 1}
          </TableCounterLbl>
          <DataTableLink dataMapping={mapping} table={table} />
          {sourceType === 'ExcelSheet' ? (
            shareURL ? (
              <ExternalIconLink
                target="_blank"
                rel="noreferrer"
                href={shareURL}
                title="External source"
              >
                <LaunchIcon />
              </ExternalIconLink>
            ) : (
              <span />
            )
          ) : table && table.sourceURL ? (
            <ExternalIconLink
              target="_blank"
              rel="noreferrer"
              href={table.sourceURL}
              title="External source"
            >
              <LaunchIcon />
            </ExternalIconLink>
          ) : (
            <span />
          )}
        </DataNameContainer>
      </LinkContainer>
      <HdrToolbar>
        {!table.state.ready && <DataIssues table={table} />}
        {fp.getOr([], 'issues', mapping).length > 0 && (
          <ErrorChiclet marginRight="0" marginLeft="5px">{`${
            mapping.issues.length
          } ${mapping.issues.length === 1 ? 'Issue' : 'Issues'}`}</ErrorChiclet>
        )}
        <UsageChiclets
          mapping={mapping}
          usageTypes={usageTypes}
          showInputs={true}
          showOutputs={true}
          hideType
        />

        <MoreInfoMenu
          dataMapping={mapping}
          tableURL={jobTableUrl}
          dataURL={dataMappingURL}
        />
      </HdrToolbar>
    </MappingHeader>
  );
}
