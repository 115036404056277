/** @jsx jsx */
/** @jsxRuntime classic */

import { PureComponent } from 'react';
import { jsx, css, keyframes } from '@emotion/react';

import * as colors from '<components>/colors';
import { FlexRowContainer } from '../NumbrzPageComponents';

const ignoreSsrWarning =
  '/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */';

const waveAnimation = keyframes({
  '0%': { transform: ' scaleY(0.4)', opacity: 0.4, visibility: 'visible' },
  '40%': { transform: 'scaleY(1)', visibility: 'visible' },
  '50%': {
    transform: 'scaleY(1)',
    opacity: 1,
    backgroundColor: colors.blue3,
    visibility: 'visible',
  },
  '80%': { transform: ' scaleY(0.4)', visibility: 'visible' },
  '100%': { transform: ' scaleY(0.4)', opacity: 0.4, visibility: 'visible' },
});

const Wave = ({ center, ...rest }) => (
  <div
    {...rest}
    css={css({
      height: 40,
    })}
  />
);

const Bar = ({ duration, delay, distance, count, ...rest }) => (
  <div
    {...rest}
    css={css({
      backgroundColor: '#999',
      height: '100%',
      width: '6px',
      display: 'inline-block',
      margin: '0 2px',
      opacity: 0,
      animation: `${waveAnimation} ${duration}s infinite ease-in-out`,
      visibility: 'hidden',
      animationDelay: `${delay}s`,

      ...new Array(count).fill().reduce((styles, _, index) => {
        styles[`&:nth-child${ignoreSsrWarning}(${index + 1})`] = {
          animationDelay: `${delay + distance * index}s`,
        };
        return styles;
      }, {}),
    })}
  />
);

export default class WaveSpinner extends PureComponent {
  static defaultProps = {
    count: 5,
    duration: 1,
    delay: 0.4,
    distance: 0.2,
    center: 1,
  };

  render() {
    return (
      <FlexRowContainer
        alignItems="center"
        justifyContent="center"
        style={{ height: '60px' }}
        width="100%"
      >
        <Wave title="loading..." {...this.props}>
          {Array(this.props.count)
            .fill()
            .map((_, i) => (
              <Bar key={i} {...this.props} />
            ))}
        </Wave>
      </FlexRowContainer>
    );
  }
}
