import React from 'react';
import styled from '@emotion/styled';
import { Icon } from 'semantic-ui-react';
import * as colors from '<components>/colors';

import QB_BTN_DEF from '<src>/assets/C2QB_green_btn_med_default.svg';
import QB_BTN_HOVER from '<src>/assets/C2QB_green_btn_med_hover.svg';
import QB_LOGO from '<src>/assets/qb-logo-horizontal-preferred.png';

import Button from '<src>/components/Button';

const QBButton = styled('button')`
  width: 223px;
  height: 36px;
  border: none;
  background: url(${QB_BTN_DEF}) no-repeat;
  :hover {
    cursor: pointer;
    background: url(${QB_BTN_HOVER}) no-repeat;
  }
`;

const QBLogo = styled('img')`
  width: 40%;
  margin: 20px;
`;

const QBConnectWrapper = styled('div')`
  display: flex;
  align-items: center;

  padding-right: 5px;
  button {
    margin-top: 5px;
  }
`;

const ConnectedButton = styled(Button)`
  & > span {
    margin-right: 6px;
  }
`;

export default function QBConnectButton({ onClick, disabled, connected }) {
  return (
    <QBConnectWrapper>
      <QBLogo src={QB_LOGO} />
      {connected ? (
        <ConnectedButton borderColor={colors.gray_border} disabled>
          <span>Connected</span>
          <Icon name="check" color="green" />
        </ConnectedButton>
      ) : (
        <QBButton onClick={onClick} disabled={disabled} />
      )}
    </QBConnectWrapper>
  );
}
