import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Callback from './Callback';

export { default as useOAuth } from './useOAuth';

export default function OAuthSection() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/callback`}>
        {(props) => <Callback {...props} serviceType="microsoft" />}
      </Route>
      <Route path={`${match.url}/google-callback`}>
        {(props) => <Callback {...props} serviceType="google" />}
      </Route>
      <Route path={`${match.url}/dropbox-callback`}>
        {(props) => <Callback {...props} serviceType="dropbox" />}
      </Route>
      <Route path={`${match.url}/qb-callback`}>
        {(props) => <Callback {...props} serviceType="quickbooks" />}
      </Route>
    </Switch>
  );
}
