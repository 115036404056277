import gql from 'graphql-tag';

import {
  ElementFragment,
  IssueFragment,
  SchemaFragment,
  FlowTableDefFragment,
} from '../flows/api/fragments';

export const DataMappingFragment = gql`
  fragment DataMappingFragment on DataMapping {
    datasetID
    tableID
    columnMap {
      columnID
      fieldKey
      inputOverride {
        dataType
        value
      }
    }
    issues {
      ...IssueFragment
    }
  }

  ${IssueFragment}
`;

export const JobItemFragment = gql`
  fragment JobItemFragment on Job {
    ID
    isBase
    noDeploy
    serviceReleaseID
    projectID
    name
    comment
    runnable
    externalResultURL
  }
`;

export const JobFragment = gql`
  fragment JobFragment on Job {
    ID
    projectID
    name
    comment
    externalResultURL
    isBase
    noDeploy
    serviceReleaseID
    runnable
    variables {
      key
      val
    }
    flowID
    issues {
      ...IssueFragment
    }
    interface {
      name
      isBundle
      userEnv {
        defaultVal
        toElement {
          ...ElementFragment
        }
      }
      data {
        ...FlowTableDefFragment
      }
    }
    datamap {
      ...DataMappingFragment
    }
  }
  ${DataMappingFragment}
  ${SchemaFragment}
  ${ElementFragment}
  ${FlowTableDefFragment}
`;

export const CancelJob = gql`
  mutation CancelJob($runID: ID!) {
    cancelJob(runID: $runID)
  }
`;

export const RunJob = gql`
  mutation RunJob($input: RunJobInput!) {
    runJob(input: $input) {
      success
      errorMessage
      errorCode
      runID
    }
  }
`;

export const GetJobStatus = gql`
  query GetJobStatus($runID: ID!) {
    jobStatus(runID: $runID) {
      runID
      flowID
      jobID
      isBundle
      name
      comment
      externalResultURL
      started
      ended
      elapsed
      status
      timing {
        call
        service
        total
        max
        min
        count
      }
      extData {
        operation
        tableID
        tableName
        tableSrc
        records
        code
        elapsedMillis
        errMsg
      }
      errMsg
      stageDetails {
        configID
        jobName
        bindingName
        status
        runID
        elapsed
        started
        ended
        errMsg
        steps {
          key
          title
          stepType
          inputCount
          outputCount
          processedCount
          failCount
          failures
        }
      }
    }
  }
`;

export const GetJob = gql`
  query GetJob($ID: CommonID!, $projectID: CommonID!) {
    job(ID: $ID) {
      ...JobFragment
    }
    project(ID: $projectID) {
      ID
      name
      type
      service {
        sourceProjectID
      }
    }
  }
  ${JobFragment}
`;

export const CreateJob = gql`
  mutation CreateJob($input: CreateJobInput!) {
    createJob(input: $input) {
      ...JobItemFragment
    }
  }
  ${JobItemFragment}
`;

export const AutomapJobData = gql`
  mutation AutomapJobData($jobID: CommonID!, $tablesetID: CommonID!) {
    automapJobData(jobID: $jobID, tablesetID: $tablesetID) {
      ...JobFragment
    }
  }

  ${JobFragment}
`;

export const UpdateJobDataMapping = gql`
  mutation UpdateJobDataMapping(
    $jobID: CommonID!
    $dataMapping: DataMappingInput!
    $unmapDataset: Boolean
    $addNew: Boolean
  ) {
    updateJobDataMapping(
      jobID: $jobID
      dataMapping: $dataMapping
      unmapDataset: $unmapDataset
      addNew: $addNew
    ) {
      ...JobFragment
    }
  }
  ${JobFragment}
`;

export const UpdateJob = gql`
  mutation UpdateJob($input: UpdateJobInput!) {
    updateJob(input: $input) {
      ...JobFragment
    }
  }

  ${JobFragment}
`;

export const CloneJob = gql`
  mutation CloneJob($jobID: CommonID!) {
    cloneJob(jobID: $jobID) {
      ...JobFragment
    }
  }

  ${JobFragment}
`;
