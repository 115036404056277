import React from 'react';
import DocumentTitle from 'react-document-title';
import MainLayout from '<components>/MainLayout';
import Masthead from '<components>/Masthead';
import { LabelButton } from '<src>/components/NumbrzButtons';
import styled from '@emotion/styled';
import QB_Logo from '<src>/assets/qb-logo-horizontal-preferred.png';

const Wrapper = styled('div')`
  margin: 20% auto;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    text-align: center;
  }
  button {
    margin: 0 10px;
    padding: 3px 6px;
  }
`;
const QB_LOGO = styled('img')`
  width: 40%;
`;
export default function QBDisconnectedPage(props) {
  return (
    <DocumentTitle title="QuickBooks Online - Source Disconnected">
      <MainLayout
        header={<Masthead />}
        main={
          <Wrapper>
            <QB_LOGO src={QB_Logo} />
            <p>
              You disconnected QuickBooks Online. If you wish to reconnect,
              please locate the data source from
              {
                <LabelButton onClick={() => props.history.push('/data')}>
                  Data
                </LabelButton>
              }
              tab and click 'Reconnect' inside the Overview page.
            </p>
          </Wrapper>
        }
      />
    </DocumentTitle>
  );
}
