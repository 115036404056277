import React from 'react';
import styled from '@emotion/styled';

import * as colors from '<components>/colors';

const Container = styled('div')`
  display: block;
  width: 95%;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  margin: -3px auto;

  span {
    position: relative;
    display: inline-block;
    color: ${colors.orange1};
    font-size: 0.9em;
    padding: 0 5px;
  }

  span:before,
  span:after {
    content: '';
    position: absolute;
    top: 45%;
    width: 9999px;
    border-top: 3px dotted ${colors.orange1};
  }

  span:before {
    right: 100%;
  }

  span:after {
    left: 100%;
    margin-left: 5px;
  }
`;

export default function OrSeparator() {
  return (
    <Container>
      <span>OR</span>
    </Container>
  );
}
