import React from 'react';
import styled from '@emotion/styled';
import * as colors from '<components>/colors';
import { List } from '<components>/TagList';
import { Tag } from '<sections>/account/pages/Billing/styles';
import { getAuthTypeLbl } from '<sections>/account/pages/Integrations/User';
import { GrayChiclet } from '<src>/components/NumbrzButtons';
import { FlexRowContainer } from '<components>/NumbrzPageComponents';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

const AccountWrapper = styled('div')`
  width: 100%;
  display: flex;
  padding: 4px 0;
  border-bottom: 1px solid ${colors.gray_border};
`;

const AccountContent = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 10px 5px 13px 5px;
  :hover {
    cursor: ${(props) => (props.selected ? 'default' : 'pointer')};
    background-color: ${colors.gray11};
    border-radius: 8px;
  }
  ${(props) =>
    props.selected &&
    `
        background-color: ${colors.gray11};
        border-radius: 8px;
    `}
`;

const UserName = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  svg {
    margin-right: 5px;
  }
`;

const CredComment = styled('span')`
  font-size: 11px;
  margin-left: 26px;
`;

export function NewUserAccount({ onClick, btnLbl = 'Use another account' }) {
  return (
    <AccountWrapper>
      <AccountContent onClick={onClick}>
        <UserName>
          <FlexRowContainer alignItems="center">
            <AccountCircleOutlinedIcon />
            <span
              style={{
                fontSize: '15px',
                fontWeight: 'bold',
                letterSpacing: '0.7px',
              }}
            >
              {btnLbl}
            </span>
          </FlexRowContainer>
        </UserName>
      </AccountContent>
    </AccountWrapper>
  );
}

export default function CredentialItem({ cred, selected = false, onSelect }) {
  const userName = cred.userName || cred.providerParams?.companyName;

  return (
    <AccountWrapper>
      <AccountContent selected={selected} onClick={() => onSelect(cred)}>
        <UserName>
          <FlexRowContainer alignItems="center">
            <AccountCircleRoundedIcon />
            {userName}
          </FlexRowContainer>
          <GrayChiclet marginLeft="10px">
            {getAuthTypeLbl(cred.type)}
          </GrayChiclet>
        </UserName>
        {cred.comment && <CredComment>{cred.comment}</CredComment>}
        {cred.tags?.length > 0 && (
          <List style={{ marginLeft: '20px' }}>
            {cred.tags.map((tag, idx) => (
              <Tag key={idx}>
                <span>{tag}</span>
              </Tag>
            ))}
          </List>
        )}
      </AccountContent>
    </AccountWrapper>
  );
}
