import React from 'react';
import EditableMarkdown from '<components>/EditableMarkdown';
import { FlowDescription } from '<components>/NumbrzVerticalEditor';

export default function Description({
  onChange,
  content,
  placeholder,
  label,
  editable,
  noContainer = false,
  margin,
}) {
  return !editable && content === '' ? null : (
    <FlowDescription noContainer={noContainer} margin={margin}>
      <EditableMarkdown
        hideIfEmpty
        label={label || 'description'}
        content={content}
        editable={editable && onChange}
        onSave={onChange}
        placeholder={placeholder || 'No description provided'}
      />
    </FlowDescription>
  );
}
