import React from 'react';
import DocumentTitle from 'react-document-title';

import MainLayout from '<components>/MainLayout';
import Masthead from '<components>/Masthead';
import WaveSpinner from '<components>/WaveSpinner';

import Function from '../Function';
import * as colors from '<components>/colors';

import { useGetFunction } from '../gql';

export default function FunctionPage({ ID, project }) {
  const { func, loading, refetch } = useGetFunction(ID);

  let breadcrumbs;
  if (project) {
    breadcrumbs = [
      { title: 'Models', path: '/models', type: 'root' },
      { title: project.name, path: `/models/${project.ID}` },
      {
        title: 'Functions',
        path: `/models/${project.ID}/functions`,
        type: 'model',
        model: project,
      },
    ];
  } else {
    breadcrumbs = [{ title: 'Functions', path: '/functions' }];
  }

  return (
    <DocumentTitle title={`${func ? func.name : 'Function'} - Numbrz`}>
      <MainLayout
        navigation={false}
        header={
          <Masthead
            title={func ? func.name : ''}
            breadcrumbs={breadcrumbs}
            addShadow
          />
        }
        main={
          !func || loading ? (
            <WaveSpinner />
          ) : (
            <Function func={func} refetch={refetch} />
          )
        }
        bgColor={colors.gray11}
      />
    </DocumentTitle>
  );
}
