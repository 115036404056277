/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from '@emotion/react';
import { useState } from 'react';
import { Message, Icon } from 'semantic-ui-react';

import { ConfigTable } from '<src>/sections/flows/styles';

import * as styles from './styles';
import Button from '<src>/components/Button';
import DurationLabel from './DurationLabel';
import StepDetails from './StepDetails';
import { getStatusChiclet } from '<sections>/job-groups/utils';

function getStageURL(project, flowID, stageID) {
  return project && project.ID
    ? `/models/${project.ID}/flows/${flowID}/stages/${stageID}`
    : undefined;
}

function StageErrorMessage(errMsg, status) {
  if (status === 'JobFailed' && errMsg) {
    return (
      <div>
        <Message error>{errMsg}</Message>
      </div>
    );
  }

  return null;
}

export default function StageStatus({
  project,
  flowID,
  stageStatus,
  idx,
  last,
}) {
  const [expanded, setExpanded] = useState(stageStatus.errMsg || false);
  const { status } = stageStatus;
  const stageURL = stageStatus.configID
    ? getStageURL(project, flowID, stageStatus.configID)
    : null;

  const isRunning = status === 'Running';

  return (
    <styles.StageContainer last={last}>
      <styles.StageSummaryContainer collapsed={!expanded}>
        <styles.StageSummaryToolbar>
          <div>
            <styles.StageHdr>
              {stageURL ? (
                <styles.StyledLink
                  className="large"
                  to={stageURL}
                  rel="noopener noreferrer"
                  title="View flow"
                >
                  {stageStatus.jobName}
                </styles.StyledLink>
              ) : (
                <styles.StyledH3>{stageStatus.jobName}</styles.StyledH3>
              )}

              {getStatusChiclet(status, isRunning)}
            </styles.StageHdr>

            <styles.BlockSubheader>
              {`STAGE ${idx + 1}`} |{' DURATION: '}
              <DurationLabel
                start={stageStatus.started}
                end={stageStatus.ended}
              />
            </styles.BlockSubheader>
          </div>
        </styles.StageSummaryToolbar>
        <styles.StatusToolbar>
          <Button
            action="control"
            size="small"
            margin="0 1px 0 0"
            onClick={() => setExpanded((e) => !e)}
            className={expanded && 'active'}
            icon={<Icon name={expanded ? 'angle up' : 'angle down'} />}
            iconPosition="right"
          >
            Details
          </Button>
        </styles.StatusToolbar>
      </styles.StageSummaryContainer>
      <StageErrorMessage errMsg={stageStatus.errMsg} status={status} />
      <styles.StepList collapsed={!expanded}>
        <ConfigTable>
          <thead>
            <th>Step</th>
            <th>Inputs processed</th>
            <th>Outputs generated</th>
            <th>Records written</th>
            <th>Errors</th>
          </thead>
          <tbody>
            {stageStatus.steps.map((stepStatus, idx) => (
              <StepDetails
                key={stepStatus.key}
                idx={idx}
                stepStatus={stepStatus}
                stageURL={stageURL}
              />
            ))}
          </tbody>
        </ConfigTable>
      </styles.StepList>
    </styles.StageContainer>
  );
}
