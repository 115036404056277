import React, { PureComponent } from 'react';
import styled from '@emotion/styled';

import * as colors from '<components>/colors';

const Match = styled('div')`
  display: flex;
  flex-direction: row;
  padding: 3px 3px 3px 6px;
  margin-top: 3px;
  width: 100%;
  border: 1px solid transparent;
  transition: border-color 200ms 25ms ease-in-out,
    background-color 200ms 25ms ease-in-out;

  &:hover {
    background-color: ${(props) => (props.checked ? null : colors.gray5)};
    border: 1px solid
      ${(props) => (props.checked ? 'transparent' : colors.gray3)};
    border-radius: 3px;
  }
`;

const InputDiv = styled('div')`
  display: flex;
  align-items: center;
`;

const LabelDiv = styled('div')`
  padding-left: 10px;
`;

const Name = styled('div')`
  color: ${colors.gray1};
  font-size: 13px;
  font-weight: bold;
`;

const Desc = styled('div')`
  color: ${colors.gray1};
  font-size: 12px;
`;

export default class DetailRadio extends PureComponent {
  static defaultProps = {
    onChange: () => {},
  };

  render() {
    const {
      name,
      id,
      value,
      selectedValue,
      label,
      children,
      onChange,
      disabled,
    } = this.props;
    const checked = value === selectedValue;

    return (
      <Match checked={checked}>
        <InputDiv>
          <input
            type="radio"
            name={name}
            value={value}
            id={id || value}
            checked={checked}
            onChange={(event) => onChange(event.target.value)}
            disabled={disabled ? disabled : false}
          />
        </InputDiv>
        <LabelDiv>
          <label htmlFor={id || value}>
            <Name>{label}</Name>
            <Desc>{children}</Desc>
          </label>
        </LabelDiv>
      </Match>
    );
  }
}
