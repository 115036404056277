import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Progress } from 'semantic-ui-react';

import { useQuery } from '<src>/apollo/client';
import { PollProjectDeployment } from '../../queries';
import { reportError } from '<src>/errors';

const CompletedStatuses = ['Deployed', 'Failed'];
const Messages = {
  Init: 'Configuring Model',
  DeployingData: 'Configuring Data',
  DeployingContent: 'Configuring Flows',
  DeployingJobs: 'Configuring Jobs',
  Deployed: 'Deployment complete',
  Failed: 'Deployment failed',
};

function mapped(mapping) {
  return !!mapping.entityID;
}

export default function DeployProgress({ projectID, sourceID, isManaged }) {
  const history = useHistory();
  const { data, error, startPolling, stopPolling } = useQuery(
    PollProjectDeployment,
    {
      variables: { ID: projectID },
      skip: !projectID,
    }
  );

  const project = data ? data.project : null;

  useEffect(() => {
    if (projectID) {
      startPolling(2000);
    }
  }, [projectID, startPolling]);

  const ownerID = project?.ownerID;
  const state = project?.deployment?.state;
  const status = state?.status || 'Init';
  const err = error || state?.err;

  useEffect(() => {
    if (!ownerID) {
      return;
    }
    if (err || CompletedStatuses.includes(status)) {
      stopPolling();
    }
    if (status === 'Deployed') {
      window.Intercom('trackEvent', 'project_deploy_success', {
        sourceID,
        isManaged,
        type: 'Service',
      });

      const url = `/switch/${ownerID}/models/${projectID}`;
      setTimeout(() => history.push(url), 700);
    }
  }, [
    err,
    history,
    isManaged,
    ownerID,
    projectID,
    sourceID,
    status,
    stopPolling,
  ]);

  useEffect(() => {
    if (err) {
      reportError(new TypeError(`Deployment failed: ${err.message}`), {
        displayed: true,
      });
    }
  }, [err]);

  let cur, total, progress;
  if (project) {
    progress = 'ratio';
    const { tables, flows, jobs } = project.deployment;
    total = tables.length + flows.length + jobs.length;
    cur =
      tables.filter(mapped).length +
      flows.filter(mapped).length +
      jobs.filter(mapped).length;
  }

  return (
    <Progress value={cur} total={total} error={!!err} progress={progress}>
      {Messages[status]}
    </Progress>
  );
}
