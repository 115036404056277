import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import update from 'immutability-helper';

import Dialog from '<components>/Dialog';
import Button from '<src>/components/Button';
import { WidgetAPI } from '../contexts';

import FunctionDetail from './FunctionDetail';
import FunctionList from './FunctionList';
import useBuildWidget from './useBuildWidget';

const ignoreSsrWarning =
  '/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */';

const StyledDialog = styled(Dialog)`
  min-width: 620px;
  min-height: 530px;
  width: fit-content;
`;

const DialogBody = styled(Dialog.Body)`
  display: flex;
  flex-direction: row;
  & > :first-child${ignoreSsrWarning} {
    flex: 2;
  }
  & > :last-child {
    flex: 5;
  }
`;

const Empty = [];
const None = { ID: 'NONE' };

function validate({ func, widget, cells }, parent) {
  if (!widget) return { invalid: true };
  if (func.validate) return func.validate(widget, cells, parent);
  return { invalid: false };
}

export default function AddWidgetDialog({
  from,
  funcs = Empty,
  onClose,
  addWidget,
  visible,
  initialFunc = None,
  parent,
  setAppearTrigger,
}) {
  const [func, setFunc] = useState(initialFunc);
  const buildState = useBuildWidget(func, from, parent);

  useEffect(() => {
    setFunc(initialFunc);
  }, [visible, initialFunc]);

  const cells = buildState.cells ? Object.values(buildState.cells) : [];

  const doAddWidget = () => {
    const correlationID = setAppearTrigger('select');
    const linkedIdx = cells.findIndex((cell) => !!cell.link);
    let finalCells = cells;
    let finalWidget = buildState.widget;
    if (linkedIdx !== -1) {
      finalCells = update(cells, {
        [linkedIdx]: {
          correlationID: { $set: correlationID },
        },
      });
    } else {
      finalWidget = update(buildState.widget, {
        correlationID: { $set: correlationID },
      });
    }

    addWidget(finalWidget, finalCells);
  };

  const { invalid, error } = validate(buildState, parent);

  const disabled = func.ID === 'NONE' || !!invalid || !!error;

  return (
    <StyledDialog visible={visible ? 1 : 0} onRequestClose={onClose}>
      <Dialog.Header>
        <Dialog.Headline>Add widget</Dialog.Headline>
        <Button.DialogClose onClick={onClose} />
      </Dialog.Header>
      <DialogBody>
        <FunctionList
          funcs={funcs}
          selected={func}
          onSelect={(func) => setFunc(func)}
        />
        <WidgetAPI.Provider value={buildState.api}>
          <FunctionDetail
            key={func.ID}
            func={buildState.func}
            widget={buildState.uiWidget}
            parent={parent}
            cells={cells}
            from={from}
            onSubmit={doAddWidget}
            validationError={error}
          />
        </WidgetAPI.Provider>
      </DialogBody>
      <Dialog.Footer>
        <Button disabled={disabled} onClick={doAddWidget}>
          Add
        </Button>
      </Dialog.Footer>
    </StyledDialog>
  );
}
