import React from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';

import { useQuery } from '<src>/apollo/client';

import WaveSpinner from '<components>/WaveSpinner';

import { GetProject } from '../../queries';
import useCheckReleaseTag from '../../components/Releases/useCheckReleaseTag';

import ProjectPage from './ProjectPage';

export default function LoadProjectPage() {
  const match = useRouteMatch();
  const { projectID } = match.params;

  const { data: { project = {} } = {}, loading } = useQuery(GetProject, {
    variables: { ID: projectID },
  });

  const [releaseTagValid, onCheckReleaseTag] = useCheckReleaseTag(projectID);

  if (loading) {
    return <WaveSpinner />;
  }

  if (project.type === 'Service') {
    return <Redirect to={`/svc-models/${projectID}`} />;
  }

  return (
    <ProjectPage
      project={project}
      releaseTagValid={releaseTagValid}
      onCheckReleaseTag={onCheckReleaseTag}
    />
  );
}
