let currentLocale;

export function getCurrentLocale() {
  if (currentLocale !== undefined) return currentLocale;

  const { language, languages, userLanguage } = window.navigator;

  if (languages) currentLocale = languages[0];
  else currentLocale = language || userLanguage;

  return currentLocale;
}
