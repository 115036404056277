import React, { useState } from 'react';
import fp from 'lodash/fp';
import { Icon } from 'semantic-ui-react';
import classnames from 'classnames';

import { NumbrzTooltip } from '<components>/NumbrzButtons';

import { typesMatch } from '<src>/utils/data-types';

import { ConfigTable } from '<sections>/flows/styles';
import MappingDropdown from './MappingDropdown';
import {
  FlexColumnContainer,
  FlexRowContainer,
} from '<src>/components/NumbrzPageComponents';
import UserValInput from './UserValInput';
import { parseUserValue } from '<sections>/functions/Cell/util';
import { MapTableCell } from '../styles';

const isTypeMatched = (e, dataTableEl, userVal) => {
  if (e && userVal) {
    return typesMatch(e.type, userVal.dataType);
  } else if (e && dataTableEl) return typesMatch(e.type, dataTableEl.type);
  return false;
};

export default function JobTableMappings({
  dataMapping = {},
  flowTable = {},
  dataTable,
  dataTableLink,
  flowTableLink,
  onFieldChange,
  syncCols,
  syncing,
}) {
  const [open, setOpen] = useState(false);
  const [activeEl, setActiveEl] = useState(null);

  const elements = fp.getOr([], 'schema.elements', flowTable);

  const { columnMap = [] } = dataMapping;
  const mappedCols = columnMap.map((cM) => cM.columnID);

  const onKeyDown = (userVal, fieldKey) => {
    const parsedVal = parseUserValue(userVal, { ms: true });

    onFieldChange({
      dataMapping,
      datasetID: dataTable.ID,
      fieldKey,
      columnID: null,
      userVal: parsedVal,
    });
  };

  const FieldMappings = () => {
    if (!dataTable) {
      return null;
    }
    const columnOpts = [
      { value: 'none', text: 'None' },
      { value: '-', text: 'Ignore column' },
      ...fp.getOr([], 'columns', dataTable).map((c) => ({
        value: c.ID,
        text: c.label,
        disabled: mappedCols.includes(c.ID),
      })),
    ];

    const matrix = [
      ...elements.map((el, idx) => {
        const field = dataMapping.columnMap.find(
          (cM) => cM.fieldKey === el.key
        );
        let typeMatches = true;
        let DataTableEl = {};
        if (field) {
          DataTableEl = dataTable.columns.find((c) => c.ID === field.columnID);
          // Type match not required if column is ignored by the job config
          if (field.columnID === '-') {
            typeMatches = true;
          } else {
            typeMatches = isTypeMatched(el, DataTableEl, field.inputOverride);
          }
        }

        const classNames = classnames(
          'compact',
          field && (field.columnID || field.inputOverride) ? null : 'error',
          !typeMatches ? 'warning' : null,
          'input'
        );

        return [
          <td>{el.label}</td>,
          <MapTableCell className={classNames}>
            <FlexRowContainer
              alignItems="center"
              justifyContent="space-between"
            >
              <FlexRowContainer alignItems="center" justifyContent="flex-start">
                <MappingDropdown
                  visible={activeEl === el.key && open}
                  setVisible={(visible) => {
                    setActiveEl(visible ? el.key : null);
                    setOpen(visible);
                  }}
                  opts={columnOpts}
                  value={field && field.columnID}
                  placeholder="Select column"
                  onChange={(value) =>
                    onFieldChange({
                      dataMapping,
                      datasetID: dataTable.ID,
                      fieldKey: el.key,
                      columnID: value,
                    })
                  }
                  syncCols={syncCols}
                  syncing={syncing}
                />
                {flowTable.isInput && (
                  <UserValInput
                    value={field}
                    onKeyDown={(userVal) => onKeyDown(userVal, el.key)}
                  />
                )}
              </FlexRowContainer>

              {!typeMatches && (
                <NumbrzTooltip
                  position="top center"
                  header={'Type Mismatch'}
                  trigger={<Icon name="warning circle" />}
                  wide="very"
                  content={`Flow field ${fp.getOr(
                    undefined,
                    'label',
                    el
                  )}'s type (${fp.getOr(
                    undefined,
                    'type',
                    el
                  )}) does not match the data column ${fp.getOr(
                    undefined,
                    'label',
                    DataTableEl
                  )}'s type (${fp.getOr(
                    undefined,
                    'type',
                    DataTableEl
                  )}). This might cause some issues during execution.`}
                />
              )}
            </FlexRowContainer>
          </MapTableCell>,
        ];
      }),
    ];

    return (
      <ConfigTable>
        <thead>
          <tr>
            <th>
              <FlexColumnContainer
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <span>Model data</span> {flowTableLink}
              </FlexColumnContainer>
            </th>

            <th>
              <FlexColumnContainer
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <span>My data</span> <span>{dataTableLink}</span>
              </FlexColumnContainer>
            </th>
          </tr>
        </thead>
        <tbody>
          {matrix.map(([field, column], idx) => {
            return (
              <tr key={idx}>
                {field}
                {column}
              </tr>
            );
          })}
        </tbody>
      </ConfigTable>
    );
  };

  return <FieldMappings />;
}
