import React, { useState, useEffect, useCallback, Fragment } from 'react';

import {
  JobGroupCard,
  BlockHeader,
  Title,
  Toolbar,
  JobGroupBlockContent,
  ReviewSettings,
  SettingsWrapper,
  SettingsSeparator,
  ReviewButton,
  StyledLoader,
  BlockFooter,
  CommentMarkdown,
  BlockSubheaderInput,
  FlowDescription,
  Subtitle,
  ConfigField,
} from '<components>/NumbrzVerticalEditor';
import { Checkbox, Dropdown } from 'semantic-ui-react';
import DeleteButtonWithConfirm from '<src>/components/DeleteButtonWithConfirm';
import { ReviewChiclet } from '<components>/NumbrzButtons';
import Button from '<src>/components/Button';
import ExpandBar from '<components>/ExpandBar';
import { getStatusChiclet } from '../../utils';
import JobList from '../JobList';

const noop = () => {};

function ReviewControls({
  jobGroupID,
  blockKey,
  reviewFlag: revFlagProp,
  reviewTimeout: revTimeoutProp,
  reviewComment,
  onUpdate,
  disabled,
}) {
  const [reviewFlag = revFlagProp, setReviewFlag] = useState();
  const [reviewTimeout = revTimeoutProp, setReviewTimeout] = useState();
  const [saving, setSaving] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const timeoutOps = [
    {
      key: '10min',
      value: 600,
      text: '10 min',
    },
    {
      key: '1h',
      value: 3600,
      text: '1 hour',
    },
    {
      key: '4h',
      value: 1400,
      text: '4 hours',
    },
    {
      key: '8h',
      value: 28800,
      text: '8 hours',
    },
    {
      key: '1d',
      value: 86400,
      text: '1 day',
    },
    {
      key: '1w',
      value: 604800,
      text: '1 week',
    },
  ];

  const handleSave = async (reviewFlag, timeout, comment) => {
    setSaving(true);
    await onUpdate(
      {},
      {
        jobGroupID,
        blockKey,
        block: {
          requireReview: reviewFlag,
          reviewTimeoutSecs: timeout && reviewFlag ? timeout : 86400,
          reviewComment: reviewFlag ? comment : null,
        },
      }
    );
    setSaving(false);
  };

  return (
    <SettingsWrapper>
      <ReviewSettings>
        <div>
          <Checkbox
            label="Review required"
            checked={reviewFlag}
            onChange={(e, { checked }) => {
              setReviewFlag(checked);
              handleSave(checked, reviewTimeout, reviewComment);
            }}
            disabled={disabled}
          />

          {reviewFlag && reviewTimeout ? (
            <Fragment>
              <span className="label">Timeout - </span>
              <Dropdown
                closeOnBlur
                placeholder="Review Timeout"
                value={reviewTimeout}
                options={timeoutOps}
                onChange={(e, data) => {
                  setReviewTimeout(data.value);
                  handleSave(reviewFlag, data.value, reviewComment);
                }}
                disabled={disabled}
              />
            </Fragment>
          ) : null}
        </div>
        {saving && <StyledLoader />}
        {reviewFlag && (
          <ExpandBar
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
            label="Instructions"
          />
        )}
      </ReviewSettings>

      {reviewFlag && expanded && (
        <Fragment>
          <SettingsSeparator />
          <CommentMarkdown
            hideIfEmpty
            label="Review instructions"
            content={reviewComment}
            editable={!(saving || disabled)}
            onSave={(comment) => handleSave(reviewFlag, reviewTimeout, comment)}
            placeholder="Add instructions"
          />
        </Fragment>
      )}
    </SettingsWrapper>
  );
}

export default function Block({
  blockIdx,
  api,
  block,
  jobGroupID,
  expanded,
  onExpand = noop,
  setActiveBlock,
  projectNames,
  blockRunStatus,
  first,
  onReviewBlock,
  isRunning,
  disabled,
  viewMode,
}) {
  const { onRemove, onUpdate } = api;
  const blockRunState = blockRunStatus ? blockRunStatus.state : undefined;

  useEffect(() => {
    if (
      blockRunState &&
      ['Active', 'BlockFailed', 'WaitingReview'].includes(blockRunState)
    )
      onExpand(true, block.key);
    if (blockRunState && ['Complete'].includes(blockRunState))
      onExpand(false, block.key);
  }, [blockRunState, onExpand, block.key]);

  const handleDeleteJob = useCallback(
    async (jobID) => {
      const payload = {
        jobGroupID,
        blockKey: block.key,
        block: {
          jobs: block.jobs.filter((j) => j.ID !== jobID).map((j) => j.ID),
        },
      };
      await onUpdate(null, payload);
    },
    [onUpdate, block.jobs, block.key, jobGroupID]
  );

  return (
    <JobGroupCard viewMode={viewMode} isRunning={isRunning} first={first}>
      <BlockHeader>
        <Title>
          <ConfigField
            singleClick
            placeholder={`Job group block`}
            value={block.name}
            disabled={disabled}
            onSubmit={(e) =>
              onUpdate(e, {
                jobGroupID,
                blockKey: block.key,
                block: { name: e.value },
              })
            }
            margin="5px 0 0 0"
            fontWeight="bold"
          />

          <Subtitle>
            <span>{`Block ${blockIdx}`}</span>
          </Subtitle>
        </Title>
        <Toolbar>
          {viewMode === 'status' && getStatusChiclet(blockRunState)}
          {viewMode === 'editor' && block.requireReview && (
            <ReviewChiclet>Review required</ReviewChiclet>
          )}
          {!disabled && viewMode === 'editor' ? (
            <DeleteButtonWithConfirm
              onClick={(e) => onRemove(e, { jobGroupID, blockKey: block.key })}
            />
          ) : null}
        </Toolbar>
      </BlockHeader>

      <JobGroupBlockContent>
        <FlowDescription style={{ width: '100%', margin: '0px 0 15px 0' }}>
          <BlockSubheaderInput
            singleClick
            placeholder="Add description"
            value={block.comment}
            disabled={disabled}
            onSubmit={(e) =>
              onUpdate(e, {
                jobGroupID,
                blockKey: block.key,
                block: { comment: e.value },
              })
            }
          />
        </FlowDescription>

        {block.jobs.length > 0 && (
          <Fragment>
            <JobList
              jobs={block.jobs || []}
              projectNames={projectNames}
              blockRunStatus={blockRunStatus}
              isRunning={isRunning}
              viewMode={viewMode}
              onDeleteJob={handleDeleteJob}
            />
          </Fragment>
        )}
        {viewMode === 'editor' && !isRunning && (
          <Button onClick={setActiveBlock}>Add jobs</Button>
        )}

        {viewMode === 'status' &&
          isRunning &&
          blockRunState === 'WaitingReview' && (
            <ReviewButton
              style={{ marginTop: '20px' }}
              onClick={() => onReviewBlock(block)}
            >
              Review
            </ReviewButton>
          )}
      </JobGroupBlockContent>
      <BlockFooter>
        <ReviewControls
          jobGroupID={jobGroupID}
          blockKey={block.key}
          reviewFlag={block.requireReview}
          reviewTimeout={block.reviewTimeoutSecs}
          reviewComment={block.reviewComment}
          onUpdate={onUpdate}
          disabled={disabled}
        />
      </BlockFooter>
    </JobGroupCard>
  );
}
