import React, { Fragment, forwardRef, useMemo, useState } from 'react';

import styled from '@emotion/styled';
import { Dropdown, Input, Ref } from 'semantic-ui-react';

import * as colors from '<components>/colors';
import {
  FlexRowContainer,
  StyledScrollbar,
} from '<components>/NumbrzPageComponents';
import { GrayChiclet } from '../NumbrzButtons';
import { PlusTextIcon } from '<src>/sections/flows/styles';
import Link from '<components>/Link';

const ignoreSsrWarning =
  '/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */';

const StyledInput = styled(Input)`
  ${(props) => props.inputHeight && { height: props.inputHeight }};
  .icon {
    top: 30%;
    line-height: 1;
  }
`;

const StyledDropdown = styled(Dropdown)`
  ${(props) =>
    props.inputHeight &&
    `
      display: flex !important;
      height: ${props.inputHeight} !important;
      min-height: unset !important;
      align-items: center !important;
      i {
        top: unset !important;
      }
    `};
`;

const filterOption = (search) => (o) =>
  o.text.toLowerCase().includes(search.toLowerCase());

const StyledItem = styled(Dropdown.Item)`
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  font-size: 13px !important;
  padding: 6px 5px 6px 18px !important;
  & i {
    margin-right: 3px !important;
  }
`;

const SelectedStyle = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  & > div:first-child${ignoreSsrWarning} {
    flex: 1;
    font-weight: bold;
  }
`;

// Overrides for semantic-ui dropdown styles when disabled
const Disabled = styled('div')`
  cursor: initial !important;
  padding-right: 1em !important;
  &:hover {
    border-color: rgba(34, 36, 38, 0.15) !important;
  }
  & a {
    cursor: pointer;
  }
`;

export const SectionHder = styled(Dropdown.Header)`
  border-bottom: 1px solid ${colors.gray_border};
  margin: 10px 15px 0 15px !important;
  padding: 0 0 3px 0 !important;
`;

function SelectedItem({ option, returnTo }) {
  return (
    <SelectedStyle>
      {option.location ? (
        <Link
          internal={true}
          withReturn={true}
          to={option.location}
          returnTo={returnTo}
        >
          {option.triggerText || option.text}
        </Link>
      ) : (
        <div>{option.triggerText || option.text}</div>
      )}
    </SelectedStyle>
  );
}

const ScrollbarMenu = styled(Dropdown.Menu)`
  ${StyledScrollbar};
`;

function SectionedDropdown(
  {
    disabled,
    itemLabel = 'Item',
    placeholder,
    open: openProp,
    returnTo,
    rootOptions = [],
    onAdd,
    addLabel = `Add ${itemLabel}`,
    searchable = true,
    sections = [],
    value,
    onChange,
    alwaysShowTriggerText = false,
    inputHeight,
  },
  parentRef
) {
  const [openState, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [searching, setSearching] = useState(false);

  const open = openProp !== undefined ? openProp : openState;

  const rootSelection = useMemo(
    () => rootOptions.find((o) => o.value === value),
    [rootOptions, value]
  );
  const sectionSelection = useMemo(() => {
    let selected;
    sections.forEach((s) => {
      if (!selected) {
        s.options.forEach((o) => {
          if (!selected && o.value === value) {
            selected = o;
          }
        });
      }
    });
    return selected;
  }, [sections, value]);
  const selected = rootSelection || sectionSelection;

  const rootOptionsFiltered = useMemo(
    () => (searchable ? rootOptions.filter(filterOption(search)) : rootOptions),
    [rootOptions, search, searchable]
  );
  const sectionsFiltered = useMemo(
    () =>
      sections.map((s) => ({
        ...s,
        options: searchable
          ? s.options.filter(filterOption(search))
          : s.options,
      })),
    [search, searchable, sections]
  );

  if (disabled) {
    return (
      <Disabled className="ui fluid dropdown selection">
        {selected ? (
          <SelectedItem option={selected} returnTo={returnTo} />
        ) : (
          `No ${itemLabel} Selected`
        )}
      </Disabled>
    );
  }

  return (
    <Ref innerRef={parentRef}>
      <StyledDropdown
        placeholder={placeholder || `Select ${itemLabel}`}
        disabled={disabled}
        fluid
        closeOnEscape
        className="selection"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(searching)}
        trigger={
          selected ? (
            <SelectedItem option={selected} returnTo={returnTo} />
          ) : undefined
        }
        inputHeight={inputHeight}
      >
        <Dropdown.Menu style={{ maxHeight: 'initial' }}>
          {searchable ? (
            <StyledInput
              icon="search"
              name="search"
              iconPosition="left"
              className="search"
              focus={searching}
              value={search}
              onChange={(e, { value }) => setSearch(value)}
              onFocus={() => setSearching(true)}
              onBlur={() => setSearching(false)}
              onKeyDown={(e) => e.stopPropagation()}
            />
          ) : null}
          <ScrollbarMenu scrolling>
            {rootOptionsFiltered.map((option) => (
              <StyledItem
                key={option.value}
                value={option.value}
                text={
                  alwaysShowTriggerText ? (
                    option.triggerText
                  ) : option.isIncluded ? (
                    <FlexRowContainer
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      {option.text}
                      <GrayChiclet margin="0 0 0 10px">Included</GrayChiclet>
                    </FlexRowContainer>
                  ) : (
                    option.text
                  )
                }
                active={option.value === value}
                onClick={(e) => onChange(e, option)}
              />
            ))}
            {onAdd ? (
              <StyledItem
                value="addRoot"
                text={
                  <Fragment>
                    <PlusTextIcon>+</PlusTextIcon>
                    <span>{addLabel || 'Add Item...'}</span>
                  </Fragment>
                }
                onClick={onAdd}
              />
            ) : null}
            {sectionsFiltered.map((section) =>
              section.options.length === 0 && !section.onAdd ? null : (
                <Fragment key={section.value}>
                  <SectionHder content={section.text} />
                  {section.options.map((option) => (
                    <StyledItem
                      key={option.value}
                      value={option.value}
                      text={
                        alwaysShowTriggerText ? (
                          option.triggerText
                        ) : option.isIncluded ? (
                          <FlexRowContainer
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            {option.text}
                            <GrayChiclet margin="0 0 0 10px">
                              Included
                            </GrayChiclet>
                          </FlexRowContainer>
                        ) : (
                          option.text
                        )
                      }
                      active={option.value === value}
                      onClick={(e) => onChange(e, option)}
                    />
                  ))}
                  {section.onAdd ? (
                    <StyledItem
                      value={`add${section.value}`}
                      text={
                        <Fragment>
                          <PlusTextIcon>+</PlusTextIcon>
                          <span>{section.addLabel || 'Add Item...'}</span>
                        </Fragment>
                      }
                      onClick={(e) => section.onAdd(e, section)}
                    />
                  ) : null}
                </Fragment>
              )
            )}
          </ScrollbarMenu>
        </Dropdown.Menu>
      </StyledDropdown>
    </Ref>
  );
}

// eslint-disable-next-line no-func-assign
SectionedDropdown = forwardRef(SectionedDropdown);
SectionedDropdown.displayName = 'SectionedDropdown';

export default SectionedDropdown;
