import { useMemo } from 'react';
import keyBy from 'lodash/keyBy';

import shortid from '<src>/utils/shortid';

export function useFieldAPI(fromKeys, toElements, onChange) {
  return useMemo(() => {
    if (!onChange) {
      return [null, null, null];
    }
    const onAddField = (e) => {
      const field = { label: 'New Field', key: shortid() };
      onChange(e, {
        toElements: [...toElements, field],
        fromKeys: fromKeys.map((row) => [...row, '']),
      });
    };
    const onChangeField = (e, { key, ...changes }) =>
      onChange(e, {
        toElements: toElements.map((el) =>
          el.key === key ? { ...el, ...changes } : el
        ),
      });
    const onDeleteField = (e, { key }) => {
      const colIdx = toElements.findIndex((el) => el.key === key);
      if (colIdx !== -1) {
        onChange(e, {
          toElements: toElements
            .slice(0, colIdx)
            .concat(toElements.slice(colIdx + 1)),
          fromKeys: fromKeys.map((row) =>
            row.slice(0, colIdx).concat(row.slice(colIdx + 1))
          ),
        });
      }
    };
    return [onAddField, onChangeField, onDeleteField];
  }, [fromKeys, onChange, toElements]);
}

export function useRowAPI(elements, fromKeys, toElements, onChange) {
  return useMemo(() => {
    if (!onChange) {
      return [null, null, null];
    }
    const elementsByKey = keyBy(elements, (e) => e.key);

    const onAddRow = (e, { row }) =>
      onChange(e, {
        fromKeys: [...fromKeys, row],
        toElements: toElements.map((e, ci) => {
          if (e.type && e.type !== 'Unspecified') return e;
          if (!elementsByKey[row[ci]]) return e;
          return { ...e, type: elementsByKey[row[ci]].type };
        }),
      });
    const onDeleteRow = (e, { rowIdx }) =>
      onChange(e, {
        fromKeys: fromKeys.slice(0, rowIdx).concat(fromKeys.slice(rowIdx + 1)),
      });
    const onSetCellKey = (e, { colIdx, rowIdx, key }) => {
      onChange(e, {
        fromKeys: fromKeys.map((row, ri) =>
          rowIdx === ri ? row.map((k, ci) => (ci === colIdx ? key : k)) : row
        ),
        toElements: toElements.map((e, ci) => {
          if (ci !== colIdx) return e;
          if (e.type && e.type !== 'Unspecified') return e;
          return { ...e, type: elementsByKey[key].type };
        }),
      });
    };
    return [onAddRow, onDeleteRow, onSetCellKey];
  }, [elements, fromKeys, onChange, toElements]);
}
