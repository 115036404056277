import { useContext, useEffect, useRef } from 'react';

import { Selection } from './contexts';

function itemsEqual(item, selected) {
  if (!selected) return false;
  if (item.type === Selection.NONE) return false;
  return item.ID === selected.ID && item.type === selected.type;
}

export default function useSelectable(item = ({ type: Selection.NONE } = {})) {
  const { ID, type, correlationID } = item;
  const ref = useRef();
  const { selection, activeItems, select, appearTrigger } =
    useContext(Selection);
  const selected = itemsEqual(item, selection);
  const active = itemsEqual(item, activeItems[item.type]);

  useEffect(() => {
    const target = ref.current;
    const l = () => select(item);
    target.addEventListener('focus', l);
    return () => target.removeEventListener('focus', l);
  }, [select, item]);
  useEffect(() => {
    if (type !== Selection.NONE) {
      ref.current.dataset.id = ID;
      ref.current.dataset.type = type;
    }
  }, [ID, type]);
  useEffect(() => {
    const target = ref.current;
    if (selected && target && target.focus) {
      target.focus();
      return () => {
        target.blur && target.blur();
      };
    }
  }, [selected]);
  useEffect(() => {
    if (
      correlationID &&
      correlationID === appearTrigger.id &&
      appearTrigger.action === 'select'
    ) {
      select({ ID, type, correlationID });
      appearTrigger.done();
    }
  }, [ID, type, correlationID, appearTrigger, select]);

  return [ref, selected, active];
}

useSelectable.CELL = Selection.CELL;
useSelectable.CELL_TEMPLATE = Selection.CELL_TEMPLATE;
useSelectable.PARAMS = Selection.PARAMS;
useSelectable.WIDGET = Selection.WIDGET;
