import React, {
  forwardRef,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { Accordion, Checkbox, Dropdown, Icon, Ref } from 'semantic-ui-react';

import { CompactDropdown } from '<components>/NumbrzPageComponents';
import Dialog from '<components>/Dialog';

import Button from '<src>/components/Button';

import { typesMatch } from '<src>/utils/data-types';

import {
  StyledBody,
  HeaderContainer,
  StyledAccordion,
  PanelTitle,
  FieldContainer,
  FilterContainer,
  SelectAllContainer,
} from './styles';

const filterOptions = [
  {
    text: 'All Types',
    value: null,
  },
  {
    text: 'String',
    value: 'String',
  },
  {
    text: 'Number',
    value: 'Number',
  },
  {
    text: 'Date',
    value: 'DateTime',
  },
  {
    text: 'Boolean',
    value: 'Boolean',
  },
];

function SelectNone({ label = 'None', selected, onClick }) {
  return (
    <Accordion.Content>
      <FieldContainer className="item" onClick={onClick}>
        <span>{label}</span>
        {!selected ? <Icon name="check" /> : null}
      </FieldContainer>
    </Accordion.Content>
  );
}

function AddField({ label = 'Add Field', onClick }) {
  return (
    <Accordion.Content>
      <FieldContainer className="item add" onClick={onClick}>
        <Icon name="plus" />
        <span>{label}</span>
      </FieldContainer>
    </Accordion.Content>
  );
}

function SelectAll({ elements, selected, onChange }) {
  const elementKeys = useMemo(() => elements.map((e) => e.key), [elements]);
  const selection = useMemo(
    () => elementKeys.filter((e) => selected.includes(e)),
    [elementKeys, selected]
  );

  const checked = selection.length > 0;
  const indeterminate = checked && selection.length < elements.length;

  const onSelectAll = useCallback(
    (e, { checked }) => {
      e.stopPropagation();
      const newSelected = selected.filter((e) => !elementKeys.includes(e));
      if (checked) {
        newSelected.push(...elementKeys);
      }
      onChange(e, { selected: newSelected });
    },
    [elementKeys, selected, onChange]
  );

  return (
    <Checkbox
      checked={checked}
      indeterminate={indeterminate}
      onChange={onSelectAll}
    />
  );
}

function SelectElement({ checked, onChange, multiSelect }) {
  if (multiSelect) {
    return <Checkbox checked={checked} onChange={onChange} />;
  }
  if (checked) {
    return <Icon name="check" />;
  }
  return null;
}

function FieldGroup({
  expanded: expandedProp,
  flat,
  elements,
  sourceInfo = {},
  multiSelect,
  selected,
  onChange,
}) {
  const [expandedState, setExpanded] = useState(false);

  const onSelectElement = useCallback(
    (event, { element, checked }) => {
      if (!multiSelect) {
        if (selected !== element.key) {
          onChange(event, { selected: element.key });
        }
        return;
      }

      const currentChecked = selected.includes(element.key);

      if (checked && !currentChecked) {
        onChange(event, { selected: [...selected, element.key] });
      } else if (!checked && currentChecked)
        onChange(event, {
          selected: selected.filter((e) => e !== element.key),
        });
    },
    [multiSelect, selected, onChange]
  );

  const expanded = expandedProp !== undefined ? expandedProp : expandedState;

  return (
    <div>
      {!flat ? (
        <Accordion.Title
          active={expanded}
          onClick={(event) => {
            event.stopPropagation();
            setExpanded((e) => !e);
          }}
        >
          <Icon name="dropdown" />
          <PanelTitle>
            <div>{sourceInfo.label}</div>
            <div>{sourceInfo.subLabel}</div>
          </PanelTitle>
          {multiSelect ? (
            <SelectAll
              elements={elements}
              selected={selected}
              onChange={onChange}
            />
          ) : null}
        </Accordion.Title>
      ) : null}
      <Accordion.Content active={expanded || flat}>
        {elements.map((element) => {
          const checked = multiSelect
            ? selected.includes(element.key)
            : selected === element.key;
          return (
            <FieldContainer
              className="item"
              key={element.key}
              checked={checked}
              multiSelect={multiSelect}
              onClick={(event) =>
                onSelectElement(event, { checked: !checked, element })
              }
            >
              <span>{element.label}</span>
              <SelectElement
                checked={checked}
                multiSelect={multiSelect}
                onChange={(e, { checked }) =>
                  onSelectElement(e, { checked, element })
                }
              />
            </FieldContainer>
          );
        })}
      </Accordion.Content>
    </div>
  );
}

export default function FieldPicker({
  addFieldLabel = 'Add Field',
  elements,
  expanded,
  flat,
  initialTypeFilter,
  multiSelect,
  noneLabel,
  restrictTypes,
  selected: selectedProp,
  showTypeFilter,
  onAddField,
  onChange,
}) {
  const [typeFilter, setTypeFilter] = useState(initialTypeFilter);

  const filterOpts = useMemo(
    () =>
      restrictTypes
        ? filterOptions.filter(
            (o) => o.value === null || restrictTypes.includes(o.value)
          )
        : filterOptions,
    [restrictTypes]
  );

  const filteredElts = useMemo(
    () =>
      elements.filter(
        (e) =>
          filterOpts.findIndex((o) => typesMatch(o.value, e.type)) !== -1 &&
          (!typeFilter || typesMatch(typeFilter, e.type))
      ),
    [elements, typeFilter, filterOpts]
  );

  const groupedElts = useMemo(() => {
    const groups = {};
    filteredElts.forEach((e) => {
      const sourceKey = e.sourceInfo ? e.sourceInfo.key : e.source;
      if (!groups[sourceKey]) {
        groups[sourceKey] = [e];
      } else {
        groups[sourceKey].push(e);
      }
    });
    return groups;
  }, [filteredElts]);

  const selected = useMemo(() => {
    const isArray = Array.isArray(selectedProp);
    if (multiSelect) {
      if (!selectedProp) return [];
      if (!isArray) return [selectedProp];
      return selectedProp;
    }
    if (isArray) {
      return selectedProp[0];
    }
    return selectedProp;
  }, [selectedProp, multiSelect]);

  return (
    <div>
      {showTypeFilter || multiSelect ? (
        <HeaderContainer>
          {showTypeFilter ? (
            <FilterContainer>
              <Icon name="filter" />
              <CompactDropdown
                placeholder="Filter Type"
                value={typeFilter}
                options={filterOptions}
                onChange={(e, data) => setTypeFilter(data.value)}
                position="top left"
                closeOnBlur
                inline
                openOnFocus={false}
              />
            </FilterContainer>
          ) : null}
          {multiSelect ? (
            <SelectAllContainer>
              <span>Select All </span>
              <SelectAll
                elements={elements}
                selected={selected}
                onChange={onChange}
              />
            </SelectAllContainer>
          ) : null}
        </HeaderContainer>
      ) : null}
      <StyledAccordion exclusive={false}>
        <StyledBody>
          {!multiSelect ? (
            <SelectNone
              label={noneLabel}
              selected={selected}
              onClick={(e) => onChange(e, { selected: null })}
            />
          ) : null}
          {Object.entries(groupedElts).map(([key, elements]) => (
            <FieldGroup
              key={key}
              expanded={expanded}
              sourceInfo={elements[0].sourceInfo}
              flat={flat}
              elements={elements}
              multiSelect={multiSelect}
              selected={selected}
              onChange={onChange}
            />
          ))}
          {onAddField ? (
            <AddField label={addFieldLabel} onClick={onAddField} />
          ) : null}
        </StyledBody>
      </StyledAccordion>
    </div>
  );
}

FieldPicker.Dropdown = function FieldPickerDropdown(
  {
    className,
    disabled,
    elements,
    open: openProp,
    selected: selectedProp,
    style,
    ...props
  },
  parentRef
) {
  const [openState, setOpen] = useState(false);
  const open = openProp !== undefined ? openProp : openState;
  const selected = Array.isArray(selectedProp) ? selectedProp[0] : selectedProp;

  const selectedElt = useMemo(
    () => elements.find((e) => e.key === selected),
    [elements, selected]
  );

  return (
    <Ref innerRef={parentRef}>
      <Dropdown
        className={className}
        style={style}
        closeOnEscape
        disabled={disabled}
        placeholder="Select Field"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        trigger={selectedElt ? selectedElt.label : undefined}
      >
        <Dropdown.Menu>
          <FieldPicker
            elements={elements}
            selected={selected}
            {...props}
            multiSelect={false}
          />
        </Dropdown.Menu>
      </Dropdown>
    </Ref>
  );
};
FieldPicker.Dropdown = forwardRef(FieldPicker.Dropdown);
FieldPicker.Dropdown.displayName = 'FieldPickerDropdown';

FieldPicker.Dialog = function FieldPickerDialog({
  header = 'Select Fields',
  elements,
  visible,
  onRequestClose,
  onSelect,
  selectLabel = 'Select',
  ...props
}) {
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    if (!visible) {
      setSelected([]);
    }
  }, [visible]);

  return (
    <Dialog onRequestClose={onRequestClose} visible={visible ? 1 : 0}>
      <Dialog.Header>
        <Dialog.Headline>{header}</Dialog.Headline>
      </Dialog.Header>
      <Dialog.Body>
        <FieldPicker
          elements={elements}
          selected={selected}
          onChange={(e, { selected }) => setSelected(selected)}
          {...props}
          multiSelect
        />
      </Dialog.Body>
      <Dialog.Footer>
        <Button onClick={onRequestClose}>Cancel</Button>
        <Button
          disabled={selected.length === 0}
          onClick={(e) => onSelect(e, { selected })}
        >
          {selectLabel}
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};
