import React, { useState } from 'react';

const defaultSettings = {
  testMode: false,
  testModeFullscreen: false,
  testSidebarWidth: 500,
};

const onboardingSettings = {
  welcomeScreens: {
    model: true,
    data: true,
    function: true,
    flow: true,
    job: true,
  },
};

const listDisplayPreferences = {
  model: 'card',
  data: 'card',
  'data-table': 'card',
  function: 'card',
  flow: 'card',
  job: 'card',
  'job-group': 'card',
  'job-table': 'card',
  'working-table': 'card',
  test: 'card',
};

function getDefaults(settingsType) {
  switch (settingsType) {
    case 'userPreferences':
      return defaultSettings;
    case 'onboardingPreferences':
      return onboardingSettings;
    case 'listDisplayPreferences':
      return listDisplayPreferences;
    default:
      return {};
  }
}

function getDefaultPreferences(settingsLabel) {
  let userPreferences;
  let savedSettings = localStorage.getItem(settingsLabel);
  if (!savedSettings) {
    const defaultPrefs = getDefaults(settingsLabel);
    userPreferences = defaultPrefs;
    localStorage.setItem(settingsLabel, JSON.stringify(defaultPrefs));
  } else {
    userPreferences = JSON.parse(savedSettings);
  }
  return userPreferences;
}

export const UserPreferencesContext = React.createContext({
  userPreferences: {},
  setUserPreference: () => {},
  setUserPrefereances: () => {},
});

export function UserPreferencesProvider(props) {
  const setUserPreferences = (newPreferences) => {
    localStorage.setItem(
      'userPreferences',
      JSON.stringify({ ...preferences, ...newPreferences })
    );
    setPreferences({
      ...preferences,
      ...newPreferences,
    });
  };

  const setUserPreference = (name, value) => {
    localStorage.setItem(
      'userPreferences',
      JSON.stringify({ ...preferences, [name]: value })
    );
    setPreferences({
      ...preferences,
      [name]: value,
    });
  };

  const setFlowSetting = (flowID, name, value) => {
    const settings = flowSettings[flowID];

    if ((settings && settings[name] !== value) || !settings) {
      const newSettings = settings
        ? { [flowID]: { ...settings, [name]: value } }
        : { [flowID]: { activeStage: null, activeTest: null } };
      localStorage.setItem(
        'flowSettings',
        JSON.stringify({ ...flowSettings, ...newSettings })
      );
      setFlowSettings({ ...flowSettings, ...newSettings });
    }
  };

  const cleanupFlowTest = (flowID, testKey, setTestResults) => {
    const settings = flowSettings[flowID];
    const storedTest =
      settings && settings.activeTest
        ? JSON.parse(settings.activeTest)
        : undefined;
    if (storedTest && storedTest.key === testKey) {
      setFlowSetting(flowID, 'activeTest', null);
      setTestResults([]);
    }
  };

  const showHideWelcomeScreen = (screenLabel, action = 'hide') => {
    const newSettings = {
      ...onboardingPreferences,
      welcomeScreens: {
        ...onboardingPreferences.welcomeScreens,
        [screenLabel]: action === 'hide' ? false : true,
      },
    };
    localStorage.setItem('onboardingPreferences', JSON.stringify(newSettings));
    setOnboardingPreferences(newSettings);
  };

  const changeListDisplayPreference = (entityType, newListType) => {
    const newSettings = {
      ...listDisplayPreferences,
      [entityType]: newListType,
    };
    localStorage.setItem('listDisplayPreferences', JSON.stringify(newSettings));
    setListDisplayPreferences(newSettings);
  };

  const [preferences, setPreferences] = useState(
    getDefaultPreferences('userPreferences')
  );
  const [flowSettings, setFlowSettings] = useState(
    getDefaultPreferences('flowSettings')
  );

  const [onboardingPreferences = {}, setOnboardingPreferences] = useState(
    getDefaultPreferences('onboardingPreferences')
  );

  const [listDisplayPreferences, setListDisplayPreferences] = useState(
    getDefaultPreferences('listDisplayPreferences')
  );

  return (
    <UserPreferencesContext.Provider
      {...props}
      value={{
        userPreferences: preferences,
        flowSettings: flowSettings,
        onboardingPrefs: onboardingPreferences,
        listDisplayPreferences,
        changeListDisplayPreference,
        showHideWelcomeScreen,
        setFlowSetting,
        setUserPreference,
        setUserPreferences,
        cleanupFlowTest,
      }}
    />
  );
}
