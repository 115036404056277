import styled from '@emotion/styled';
import * as colors from '<components>/colors';

export const SplitView = styled('div')`
  height: ${(props) => (props.height ? props.height : '100%')};
  width: 100%;
  display: flex;
  align-items: flex-start;
`;

export const SplitViewDivider = styled('div')`
  width: 2px;
  height: 100%;
  z-index: 2;
  box-sizing: border-box;
  background: transparent;
  border-left: 1px solid ${colors.gray3};

  cursor: ew-resize;
  i {
    color: ${colors.gray3};
    margin: 0;
    font-size: 0.8em;
  }
  :hover {
    margin-left: -2px;
    margin-right: -2px;
    width: 6px;
    transition: background 0.1s ease-in;
    background: ${colors.blue1};
  }
`;

export const LeftPane = styled('div')`
  flex: 1;
  height: 100%;
  width: 100%;
`;

export const RightPane = styled('div')`
  height: 100%;
`;
